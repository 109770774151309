define("iris/components/beta/filters/tab/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hi0vxFIr",
    "block": "{\"symbols\":[\"@filters\",\"@onSave\",\"@onDelete\",\"&attrs\",\"@q\",\"@onClick\",\"&default\"],\"statements\":[[7,\"button\",false],[12,\"class\",[29,[\"p-3 relative px-6 items-center rounded-t border-b flex min-w-0\\n    \",[28,\"if\",[[23,0,[\"isActive\"]],\"bg-black border-scarlet-500 text-white\",\"\\n        bg-gray-300 border-gray-300\\n        dark:border-gray-600 dark:bg-gray-600 dark:text-gray-200\\n      \"],null],\"\\n    \"]]],[13,4],[12,\"type\",\"button\"],[3,\"on\",[\"dblclick\",[28,\"action\",[[23,0,[]],\"handleDoubleClick\"],null]]],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],[23,6,[]],[23,1,[]],[23,5,[]]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",false],[12,\"class\",\"hover:text-steel-200 absolute right-0 -top-2\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,3,[]],[23,1,[]]],null]]],[8],[0,\"\\n      \"],[1,[28,\"fa-icon\",[\"times\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",false],[12,\"class\",\"pr-4 hover:text-steel-200\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,2,[]],[23,1,[]]],null]]],[8],[0,\"\\n      \"],[1,[28,\"fa-icon\",[\"floppy-o\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[14,7],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/beta/filters/tab/template.hbs"
    }
  });

  _exports.default = _default;
});