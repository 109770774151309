define("iris/helpers/is-type-of", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isTypeOf = isTypeOf;

  function isTypeOf(_ref
  /*, hash*/
  ) {
    let [thing, type] = _ref;
    return Ember.typeOf(thing) === type;
  }

  var _default = Ember.Helper.helper(isTypeOf);

  _exports.default = _default;
});