define("iris/templates/beta/cmdb/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t6GkVGmA",
    "block": "{\"symbols\":[],\"statements\":[[5,\"cmdb-visualisation2/grid\",[],[[\"@defaultWhere\"],[[28,\"hash\",null,[[\"id\"],[[28,\"hash\",null,[[\"inq\"],[[28,\"array\",[\"TAK000014227\",\"TAK000014228\",\"TAK000014229\"],null]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/beta/cmdb/index.hbs"
    }
  });

  _exports.default = _default;
});