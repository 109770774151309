define("iris/abilities/menu", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    sessionAccount: Ember.inject.service(),
    canRead: Ember.computed('sessionAccount.menus', function () {
      let actionNames = this.get('sessionAccount.menus');
      let account = this.get('sessionAccount.account');
      if (!actionNames) return false;
      let menu = this.get('menu');
      let res = Ember.A();
      res = actionNames.filter(a => {
        //Admin
        if (account.get('admin')) {
          return true;
        }

        return a.get('systemName') === menu;
      });
      if (res.length) return true;else return false;
    })
  });

  _exports.default = _default;
});