define("iris/components/tandm/info/time-line/item/summary/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TandmTimelineItemSummary extends Ember.Component {}

  _exports.default = TandmTimelineItemSummary;
});