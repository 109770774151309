define("iris/templates/components/buttons/add-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3qfpMY6J",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"iris-button\",null,[[\"icon\",\"action\",\"disabled\",\"title\",\"type\",\"refresh\",\"value\",\"text\"],[[24,[\"icon\"]],[24,[\"action\"]],[24,[\"_disabled\"]],[24,[\"title\"]],[24,[\"type\"]],[24,[\"refresh\"]],[24,[\"value\"]],[24,[\"_text\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/buttons/add-button.hbs"
    }
  });

  _exports.default = _default;
});