define("iris/authenticators/custom", ["exports", "ember-simple-auth/authenticators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),

    restore(data) {
      return Ember.RSVP.resolve(data);
    },

    authenticate(username, password) {
      let data = {
        email: username,
        password: password
      };
      return this.get('ajax').call('POST', 'account', '', 'login', data);
    },

    invalidate() {
      let access_token = this.get('session.data.authenticated.id');
      this.get('ajax').call('POST', 'account', '', 'logout?access_token=' + access_token);
      return Ember.RSVP.resolve();
    }

  });

  _exports.default = _default;
});