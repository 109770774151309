define("iris/components/vat-type", ["exports", "iris/utils/vat-types"], function (_exports, _vatTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    vat: Ember.computed('vatType', function () {
      const id = this.get('vatType');
      return _vatTypes.vatTypes.find(vt => vt.id === id);
    })
  });

  _exports.default = _default;
});