define("iris/transforms/json", ["exports", "ember-data/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class JSONTransform extends _transform.default {
    deserialize(serialized) {
      try {
        return typeof serialized === 'string' ? JSON.parse(serialized) : undefined;
      } catch (err) {
        console.error(err, serialized);
      }
    }

    serialize(deserialized) {
      return JSON.stringify(deserialized);
    }

  }

  _exports.default = JSONTransform;
});