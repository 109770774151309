define("iris/components/beta/filters/edit/value-input/component", ["exports", "ember-concurrency", "iris/lib/filters", "iris/lib/filters/api", "iris/lib/filters/data", "iris/utils/ember-concurrency"], function (_exports, _emberConcurrency, _filters, _api, _data, _emberConcurrency2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    api: Ember.inject.service(),
    // `filter.optionsQuery` needs this
    filterValue: Ember.computed('filter.{inputType,value}', function () {
      // eslint-disable-next-line ember/no-get
      let {
        value,
        inputType
      } = Ember.getProperties(this.filter, ['value', 'inputType']);

      if (inputType === 'date' && typeof value === 'string' && !!value) {
        return new Date(value);
      }

      return value;
    }),
    options: Ember.computed('filter.options', 'optionsQueryTask', function () {
      // eslint-disable-next-line ember/no-get
      let {
        options,
        optionsResourceAction
      } = Ember.getProperties(this.filter, ['options', 'optionsResourceAction']);

      if (Array.isArray(options)) {
        return options;
      }

      if (optionsResourceAction === 'findAll') {
        // eslint-disable-next-line ember/no-get
        return this.get('optionsQueryTask').perform();
      }

      return null;
    }),
    optionsQueryTask: (0, _emberConcurrency.task)(function* (value) {
      let {
        attributeName,
        optionsResourceName,
        optionsResourceAction,
        optionsNameKey,
        optionsQuery,
        createOptions // eslint-disable-next-line ember/no-get

      } = Ember.getProperties(this.filter, ['attributeName', 'optionsResourceName', 'optionsResourceAction', 'optionsNameKey', 'optionsQuery', 'createOptions']);
      let resourceName = optionsResourceName !== null && optionsResourceName !== void 0 ? optionsResourceName : attributeName.replace(/Id$/, '');
      let nameKey = optionsNameKey !== null && optionsNameKey !== void 0 ? optionsNameKey : _data.DEFAULT_OPTIONS_NAME_KEY;

      let optionsQueryObjectForValue = value => (0, _api.makeOptionsQueryObject)(value, this.filter, this.filters);

      let queryOptions = optionsQueryParams => this.api.query(resourceName, optionsQueryParams).then(async records => createOptions ? createOptions(records, nameKey, _filters.mapSelectOptionRecords) : (0, _filters.mapSelectOptionRecords)(records, nameKey));

      try {
        if (optionsQuery || optionsResourceAction === 'query') {
          yield (0, _emberConcurrency2.timeout)(500);
          let optionsQueryParams = optionsQueryObjectForValue(value);

          if (optionsQuery) {
            return yield optionsQuery.apply(this, [value, {
              resourceName,
              defaultQueryParams: optionsQueryParams,
              filter: this.filter,
              filters: this.filters,
              api: this.api
            }]);
          } else {
            return yield queryOptions(optionsQueryParams);
          }
        } else {
          let optionsQueryParams = optionsQueryObjectForValue();
          return yield queryOptions(optionsQueryParams);
        }
      } catch (error) {
        console.error(error);
      }
    }).restartable(),
    actions: {
      updateValue(event) {
        this.set('filter.value', event.target.value);
      },

      selectFilterValueOption(option) {
        this.set('filter.value', {
          id: option.id,
          name: option.name
        });
      },

      selectFilterValueMultipleOptions(options) {
        this.set('filter.value', options);
      }

    }
  });

  _exports.default = _default;
});