define("iris/routes/settings/date-types/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      if (params.id == 'new') {
        return this.store.createRecord('dateType');
      }

      return this.store.findRecord('dateType', params.id).catch(function () {
        return {};
      });
    },

    actions: {
      willTransition() {
        let record = this.controller.get('model');
        record.rollback();
      }

    }
  });

  _exports.default = _default;
});