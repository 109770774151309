define("iris/components/change-requests/index/component", ["exports", "iris/lib/filters", "iris/lib/filters/column", "iris/lib/filters/storage", "iris/lib/filters/types", "iris/lib/table"], function (_exports, _filters, _column, _storage, _types, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    modals: Ember.inject.service(),
    router: Ember.inject.service(),
    storage: (0, _storage.useStorage)(),
    columns: (0, _column.columns)(function () {
      return [{
        name: 'id',
        displayName: this.intl.t('operation.change-request.id'),
        width: _column.ColumnWidth.S,
        fixed: true,
        route: 'operation.change-requests.edit',
        routeParams: (0, _table.rowValues)('id')
      }, {
        name: 'name',
        displayName: this.intl.t('operation.change-request.name'),
        width: _column.ColumnWidth.SM,
        fixed: true,
        route: 'operation.change-requests.edit',
        routeParams: (0, _table.rowValues)('id')
      }, {
        name: 'changeRequestStateId',
        displayName: this.intl.t('operation.change-request.state'),
        valuePath: 'state.name',
        type: _types.AttributeType.BelongsTo,
        sortable: false,
        width: _column.ColumnWidth.S
      }, {
        name: 'projectId',
        displayName: this.intl.t('operation.change-request.file'),
        valuePath: 'project.name',
        type: _types.AttributeType.BelongsTo,
        sortable: false,
        width: _column.ColumnWidth.L,
        route: 'operation.operational-file.edit',
        routeParams: (0, _table.rowValues)('project.id')
      }, {
        name: 'operationServiceLevelId',
        displayName: this.intl.t('operation.change-request.operationServiceLevel'),
        valuePath: 'operationServiceLevel.name',
        type: _types.AttributeType.BelongsTo,
        sortable: false,
        width: _column.ColumnWidth.M
      }, {
        name: 'from',
        displayName: this.intl.t('operation.change-request.from'),
        type: _types.AttributeType.Date,
        width: _column.ColumnWidth.S
      }, {
        name: 'to',
        displayName: this.intl.t('operation.change-request.to'),
        type: _types.AttributeType.Date,
        width: _column.ColumnWidth.S
      }, {
        name: 'priorityName',
        displayName: this.intl.t('operation.change-request.priorityName'),
        valuePath: 'priority.name',
        type: _types.AttributeType.BelongsTo,
        sortable: false,
        width: _column.ColumnWidth.S
      }, {
        name: 'successful',
        displayName: this.intl.t('operation.change-request.successful'),
        type: _types.AttributeType.Boolean,
        width: _column.ColumnWidth.S
      }];
    }),
    rowActions: (0, _table.rowActions)(function () {
      const {
        intl
      } = this;
      return [{
        name: this.intl.t('operation.change-request.clone'),
        icon: 'clone',

        async action(changeRequest) {
          let shouldClone = await this.modals.open('beta/modals/confirmation', {
            title: this.intl.t('operation.change-request.clone'),
            body: this.intl.t('operation.change-request.clone-info')
          });

          if (shouldClone) {
            try {
              let clonedChangeRequest = await this.api.memberAction('changeRequest', 'clone', changeRequest.id, {
                method: 'POST'
              });
              this.flashMessages.info(this.intl.t('operation.change-request.new-cr-title'), {
                title: this.intl.t('general.clone.success'),
                icon: 'copy'
              });
              this.router.transitionTo('operation.change-requests.edit', clonedChangeRequest.id);
            } catch (error) {
              this.flashMessages.danger(this.intl.t('general.clone.error'), {
                title: this.intl.t('general.error'),
                icon: 'warning',
                sticky: true
              });
            }
          }
        }

      }, {
        name: this.intl.t('general.deletion'),
        icon: 'trash',
        style: 'danger',
        disabled: Ember.computed('row.state.id', function () {
          if (this.row.belongsTo('state').id() !== 'SYS_01') {
            return intl.t('operation.change-request.only-draft-can-be-deleted');
          }

          return false;
        }),

        async action(changeRequest) {
          let shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

          if (shouldDelete) {
            try {
              await changeRequest.destroyRecord(true);
              this.flashMessages.info(intl.t('general.delete.success-title'), {
                title: intl.t('general.delete.success'),
                icon: 'times'
              });
              this.storage.refresh();
            } catch (error) {
              this.flashMessages.danger(intl.t('general.delete.error'));
            }
          }
        }

      }];
    }),
    fetchParams: Ember.computed('columns', 'storage.params', function () {
      let {
        filter
      } = (0, _filters.makeQueryObject)(this.columns, this.storage.params);
      return {
        filter: { ...filter,
          include: ['state', 'project', 'operationServiceLevel', 'priority']
        }
      };
    })
  });

  _exports.default = _default;
});