define("iris/classes/filter/condition-builder", ["exports", "iris/classes/filter/filter"], function (_exports, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildConditions = void 0;

  const buildConditions = filters => {
    return filters.map(f => {
      let rawValueProducers = [_filter.Operator.Equal, _filter.Operator.Null, _filter.Operator.Boolean];
      let value = rawValueProducers.includes(f.operator) ? f.value : {
        [f.operator]: f.value
      };
      return {
        ea: {
          [f.property]: value
        }
      };
    });
  };

  _exports.buildConditions = buildConditions;
});