define("iris/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WGZFOMCJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"style\",\"height: 100vh;width:100%\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"bounce-loader\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"bounce1\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"bounce2\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"bounce3\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/index.hbs"
    }
  });

  _exports.default = _default;
});