define("iris/controllers/operation/portal-dashboard/new-incident", ["exports", "iris/utils/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    loading: Ember.inject.service(),
    uploadDocuments: Ember.inject.service(),
    actions: {
      async save(ticket) {
        await this.loading.run(async () => {
          const {
            model,
            validations
          } = await (0, _model.validateRecord)(ticket);

          if (!validations.isValid) {
            console.error('Record is invalid', validations);
            model.setValidated();
            return;
          } else {
            model.setValidated();
            await model.save(true);
          }

          await this.uploadDocuments.uploadPendingFiles(ticket);
          this.router.transitionTo('operation.portal-dashboard');
        });
      }

    }
  });

  _exports.default = _default;
});