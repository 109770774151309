define("iris/models/service-level", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    solvingTime: (0, _emberCpValidations.validator)('number', {
      gt: 0
    }),
    reactionTime: (0, _emberCpValidations.validator)('number', {
      gt: 0
    }),
    startTime: (0, _emberCpValidations.validator)('number', {
      gt: 0
    }),
    servicePeriods: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('length', {
      min: 1,
      messageKey: 'errors.has-many'
    })]
  });

  var _default = _model.default.extend(Validations, {
    changeTracker: {
      trackHasMany: true,
      enableIsDirty: true,
      auto: true
    },
    name: (0, _attr.default)('string'),
    days: (0, _attr.default)('string', {
      defaultValue: '00'
    }),
    hours: (0, _attr.default)('string', {
      defaultValue: '00'
    }),
    minutes: (0, _attr.default)('string', {
      defaultValue: '00'
    }),
    solvingTime: (0, _attr.default)('number'),
    reactionTime: (0, _attr.default)('number'),
    startTime: (0, _attr.default)('number'),
    reactionNormal: (0, _attr.default)('number', {
      defaultValue: 50
    }),
    reactionWarning: (0, _attr.default)('number', {
      defaultValue: 70
    }),
    reactionCritical: (0, _attr.default)('number', {
      defaultValue: 90
    }),
    reactionOverdue: (0, _attr.default)('number', {
      defaultValue: 100
    }),
    startNormal: (0, _attr.default)('number', {
      defaultValue: 50
    }),
    startWarning: (0, _attr.default)('number', {
      defaultValue: 70
    }),
    startCritical: (0, _attr.default)('number', {
      defaultValue: 90
    }),
    startOverdue: (0, _attr.default)('number', {
      defaultValue: 100
    }),
    solvingNormal: (0, _attr.default)('number', {
      defaultValue: 50
    }),
    solvingWarning: (0, _attr.default)('number', {
      defaultValue: 70
    }),
    solvingCritical: (0, _attr.default)('number', {
      defaultValue: 90
    }),
    solvingOverdue: (0, _attr.default)('number', {
      defaultValue: 100
    }),
    vip: (0, _attr.default)('boolean'),
    repairService: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    solvingGroup: (0, _relationships.belongsTo)('solvingGroup'),
    serviceArea: (0, _relationships.belongsTo)('serviceArea'),
    type: (0, _relationships.belongsTo)('ticketType'),
    servicePeriods: (0, _relationships.hasMany)('servicePeriodFile'),
    priority: (0, _relationships.belongsTo)('priority'),
    operationServiceLevel: (0, _relationships.belongsTo)('operationServiceLevel'),
    ticketCommunications: (0, _relationships.hasMany)('ticketCommunication'),
    fileDateType: Ember.computed.alias('servicePeriod.fileDateType'),
    isChange: Ember.computed.equal('type.id', 'SYS_16')
  });

  _exports.default = _default;
});