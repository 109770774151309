define("iris/components/modals/completion-certificate/component", ["exports", "iris/utils/converter"], function (_exports, _converter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    loading: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    modals: Ember.inject.service(),
    currentLanguage: Ember.computed.alias('sessionAccount.account.language'),
    model: Ember.computed.alias('data.model'),
    tagName: '',
    alreadyRead: false,
    actions: {
      async generateCompletionCertificatePDF(activeProposal) {
        const flashMessages = this.get('flashMessages');
        const languageCode = this.get('currentLanguage.languageCode');
        let invoice; // Make invoice
        // Since NAV endpoint is not exposed for PMs, the invoice created on the backend

        try {
          // check if already exists preInvoice for this proposal
          if (activeProposal.preInvoice) {
            let confirm = await this.modals.open('beta/modals/confirmation', {
              title: this.intl.t('general.warning'),
              body: this.intl.t('projects.completion-certificate-warning')
            });

            if (!confirm) {
              throw new Error('err');
            } else {
              invoice = await this.ajax.call('POST', 'file-outgoing-proposal', '', 'create-invoice', {
                data: activeProposal
              }); // Generate Completion Certificate

              const result = await this.get('ajax').call('POST', 'file-outgoing-proposal', '', 'pdf', {
                data: activeProposal,
                languageCode
              });
              await this.loading.run(async () => {
                saveAs(_converter.default.b64toBlob(result.$data, 'application/pdf'), `TIG${moment().format('YYYY/MM/DD')}.pdf`);
              });
            }
          } else {
            invoice = await this.ajax.call('POST', 'file-outgoing-proposal', '', 'create-invoice', {
              data: activeProposal
            }); // Generate Completion Certificate

            const result = await this.get('ajax').call('POST', 'file-outgoing-proposal', '', 'pdf', {
              data: activeProposal,
              languageCode
            });
            await this.loading.run(async () => {
              saveAs(_converter.default.b64toBlob(result.$data, 'application/pdf'), `TIG${moment().format('YYYY/MM/DD')}.pdf`);
            });
          }

          flashMessages.success(this.intl.t('preInvoiceGenerationSuccess', {
            subject: invoice.subject,
            id: invoice.id
          }), {
            title: this.intl.t('invoice.preInvoice'),
            icon: 'check',
            sticky: true
          });
        } catch (e) {
          if (e.errors && e.errors.length) {
            e.errors.forEach(err => {
              let text = err.detail || err.title;
              flashMessages.danger(text, {
                title: this.intl.t('general.generation.error'),
                icon: 'times',
                sticky: true
              });
            });
          } else {
            console.error(e);
          }
        }

        this.close(invoice);
      }

    }
  });

  _exports.default = _default;
});