define("iris/helpers/week-days", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.weekDays = weekDays;

  function weekDays(params
  /*, hash*/
  ) {
    let weekDay = parseInt(params[0]);
    let value = '';

    switch (weekDay) {
      case 0:
        value = 'H';
        break;

      case 1:
        value = 'K';
        break;

      case 2:
        value = 'Sze';
        break;

      case 3:
        value = 'Cs';
        break;

      case 4:
        value = 'P';
        break;

      case 5:
        value = 'Szo';
        break;

      case 6:
        value = 'V';
        break;
    }

    return value;
  }

  var _default = Ember.Helper.helper(weekDays);

  _exports.default = _default;
});