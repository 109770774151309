define("iris/routes/settings/date-type-definitions/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DateTypeDefinitionEditRoute extends Ember.Route {
    async model(params) {
      if (params.id === 'new') {
        return await this.store.createRecord('dateTypeDefinition');
      }

      return await this.store.findRecord('dateTypeDefinition', params.id);
    }

  }

  _exports.default = DateTypeDefinitionEditRoute;
});