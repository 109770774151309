define("iris/components/attachments-control-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    uploadDocuments: Ember.inject.service(),
    intl: Ember.inject.service(),
    loading: Ember.inject.service(),

    init() {
      const documents = this.get('model').hasMany('documents');
      documents.reload();
      const name = this.name || 'pu';
      this.set('name', name);

      this._super();
    },

    listOfFiles: Ember.computed.alias('model.documents'),
    //The uploaded files
    _listOfFilesDocumentTypes: Ember.computed.mapBy('listOfFiles', 'documentTypes'),
    filteredDocumentList: Ember.computed('selectedDocumentType', 'listOfFiles.[]', '_listOfFilesDocumentTypes.@each.id', function () {
      const documentTypeId = this.get('selectedDocumentType.id');
      return this.get('listOfFiles').filter(document => {
        return document.belongsTo('documentType').id() === documentTypeId;
      });
    }),
    uploadTitle: Ember.computed('uploader.files', function () {
      return this.intl.t('attachments.uploadDocument'); //Current filenames in queue

      /*
      const uploadService = this.get('uploader');
      const files = uploadService.get('files');
      const filesOfQueue = files.filter((file) => file.queue.name === this.name);
      return filesOfQueue.length
        ? filesOfQueue
            .map((file) => {
              return file.file.name;
            })
            .join()
        : 'Mellékletek feltöltése';*/
    }),
    selectedDocumentType: Ember.computed.reads('documentTypes.firstObject'),
    documentTypesReq: Ember.computed(function () {
      return this.get('store').findAll('documentType');
    }),
    documentTypes: Ember.computed('documentTypesReq.[]', 'only', function () {
      let types = this.get('documentTypesReq');
      let only = this.get('only');
      let res = [];

      if (only) {
        only = typeof only === 'string' ? [only] : only;

        for (const type of only) {
          let found = types.toArray().find(t => t.get('shortName') === type);

          if (found) {
            res.push(found);
          }
        }
      }

      return res;
    }),
    selectedRecords: Ember.A([]),
    multiple: false,
    disableUpload: false,
    saveAfterFileAdded: false,
    base64: null,
    documentName: '',
    //services
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    uploader: Ember.inject.service(),
    actions: {
      async onFileAdded(file) {
        const extensions = this.get('selectedDocumentType.extensions').split(' ');
        let fileExtension = file.file.name.slice(file.file.name.lastIndexOf('.'));
        const match = extensions.find(ext => {
          return fileExtension.toLowerCase().includes(ext.toLowerCase());
        });

        if (!match) {
          const flashMessages = Ember.get(this, 'flashMessages');
          flashMessages.danger(this.intl.t('attachments.wrongExtension', {
            extensions: extensions.join(),
            documentType: this.get('selectedDocumentType.name')
          }), {
            title: this.intl.t('attachments.wrongMime'),
            icon: 'times',
            sticky: true
          });
          return file.destroy();
        }

        file.file.documentTypeId = this.get('selectedDocumentType.id');
        file.file.modelName = this.get('model.modelName');

        if (this.get('model.id')) {
          // Upload files
          // Loopback model name
          const lbModelName = Ember.String.classify(this.get('model').constructor.modelName);
          await this.loading.run(async () => {
            await this.get('uploadDocuments').uploadFile(this.get('model'), file);
            const documents = this.get('model').hasMany('documents');
            documents.reload();
          });
        }
      },

      setDocumentType(documentType) {
        this.set('selectedDocumentType', documentType);
      },

      removeFile(file) {
        file.destroy();
      }

    }
  });

  _exports.default = _default;
});