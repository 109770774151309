define("iris/models/service-area", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    title: (0, _attr.default)('string'),
    availability: (0, _attr.default)('number'),
    solvingGroups: (0, _relationships.hasMany)('solvingGroup'),
    serviceAreaSolvingGroupAssocs: (0, _relationships.hasMany)('serviceAreaSolvingGroupAssoc'),
    partner: (0, _relationships.belongsTo)('partner')
  });

  _exports.default = _default;
});