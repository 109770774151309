define("iris/components/aiops/interface-sync/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    loading: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    differencies: [],

    async init() {
      this._super(...arguments);

      const differencies = await this.get('ajax').call('GET', 'aiopsInterface', '', 'differencies/' + this.model.id);
      this.set('differencies', differencies === null || differencies === void 0 ? void 0 : differencies.result);
    },

    actions: {
      async syncronize(model) {
        await this.loading.run(async () => {
          await this.get('ajax').call('GET', 'aiopsInterface', '', 'syncronize/' + this.model.id);
          this.set('differencies', []);
          model.set('syncDate', new Date());
          await model.save(true);
        });
        this.flashMessages.info(this.intl.t('general.sync.success'), {
          title: this.intl.t('general.sync.success-title'),
          icon: 'floppy-o'
        });
        return;
      }

    }
  });

  _exports.default = _default;
});