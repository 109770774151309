define("iris/components/tree-node", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // other domain logic
    classNames: ['dd-item'],
    tagName: 'li',
    displayedChildren: function () {
      return this.get("node.children").filter(function (node) {
        return node.get("isTopChild");
      });
    }.property("node.{children,topChild}"),
    actions: {
      expand(action) {
        this.get('openNodes').addObject(action);
      },

      collapse(action) {
        this.get('openNodes').removeObject(action);
      },

      onChange(action, checked) {
        this.get('onCheck')(action, checked);
      }

    }
  });

  _exports.default = _default;
});