define("iris/components/character-counter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    show: Ember.computed('string.length', 'min', function () {
      if (typeof this.string !== 'string') {
        return false;
      }

      return this.string.length < this.min;
    }),
    remaining: Ember.computed('string.length', 'min', function () {
      if (typeof this.string !== 'string') {
        return 0;
      }

      return this.min - this.string.length;
    })
  });

  _exports.default = _default;
});