define("iris/helpers/block-params", ["exports", "@salsify/ember-block-slots/helpers/block-params"], function (_exports, _blockParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "blockParams", {
    enumerable: true,
    get: function () {
      return _blockParams.blockParams;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _blockParams.default;
    }
  });
});