define("iris/components/forms/custom-attributes", ["exports", "iris/utils/properties"], function (_exports, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    large: true,
    valueChange: (0, _properties.observerOnce)('values.@each.value', function () {
      var _this$model;

      (_this$model = this.model) === null || _this$model === void 0 ? void 0 : _this$model.set('isDirtyTemp', new Date());
    }).on('init'),
    intl: Ember.inject.service(),
    actions: {
      copyToClipboard(value) {
        navigator.clipboard.writeText(value);
        this.flashMessages.success(this.intl.t('forms.copied-to-clipboard'), {
          title: this.intl.t('forms.copy-success'),
          icon: 'check'
        });
      }

    }
  });

  _exports.default = _default;
});