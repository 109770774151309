define("iris/models/service-level-template", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true
    })
  });

  var _default = _model.default.extend(Validations, {
    changeTracker: {
      trackHasMany: true,
      enableIsDirty: true,
      auto: true
    },
    isDirtyTemp: (0, _attr.default)('date'),
    name: (0, _attr.default)('string'),
    solvingTime: (0, _attr.default)('number'),
    reactionTime: (0, _attr.default)('number'),
    startTime: (0, _attr.default)('number'),
    vip: (0, _attr.default)('boolean'),
    reactionNormal: (0, _attr.default)('number', {
      defaultValue: 50
    }),
    reactionWarning: (0, _attr.default)('number', {
      defaultValue: 70
    }),
    reactionCritical: (0, _attr.default)('number', {
      defaultValue: 90
    }),
    reactionOverdue: (0, _attr.default)('number', {
      defaultValue: 100
    }),
    startNormal: (0, _attr.default)('number', {
      defaultValue: 50
    }),
    startWarning: (0, _attr.default)('number', {
      defaultValue: 70
    }),
    startCritical: (0, _attr.default)('number', {
      defaultValue: 90
    }),
    startOverdue: (0, _attr.default)('number', {
      defaultValue: 100
    }),
    solvingNormal: (0, _attr.default)('number', {
      defaultValue: 50
    }),
    solvingWarning: (0, _attr.default)('number', {
      defaultValue: 70
    }),
    solvingCritical: (0, _attr.default)('number', {
      defaultValue: 90
    }),
    solvingOverdue: (0, _attr.default)('number', {
      defaultValue: 100
    }),
    solvingGroup: (0, _relationships.belongsTo)('solvingGroup'),
    serviceArea: (0, _relationships.belongsTo)('serviceArea'),
    type: (0, _relationships.belongsTo)('ticketType'),
    priority: (0, _relationships.belongsTo)('priority'),
    ticketCommunications: (0, _relationships.hasMany)('ticketCommunication')
  });

  _exports.default = _default;
});