define("iris/components/forms/availability-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    computedUsers: Ember.computed('model.serviceArea', function () {
      let serviceArea = this.get('model.serviceArea');

      if (serviceArea) {
        return serviceArea.get('solvingGroups').then(solvingGroups => {
          return this.collectUsers(solvingGroups);
        });
      } else {
        return null;
      }
    }),

    collectUsers(solvingGroups) {
      let res = Ember.A();
      solvingGroups.forEach(async solvingGroup => {
        let accounts = await solvingGroup.get('accounts');
        res.addObjects(accounts);
      });
      return res;
    }

  });

  _exports.default = _default;
});