define("iris/components/forms/scheduled-task/component", ["exports", "iris/components/issue-report/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ScheduledTask extends _component.default {
    backToList() {
      this.router.transitionTo('operation.scheduled-tasks.index');
    }

  }

  _exports.default = ScheduledTask;
});