define("iris/routes/operation/duties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    api: Ember.inject.service(),

    model() {
      return this.api.memberAction('duty', 'get-solver-groups', null, {
        method: 'GET'
      });
    }

  });

  _exports.default = _default;
});