define("iris/templates/settings/date-types/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Xh98Kfe",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"isLoaded\"]]],null,{\"statements\":[[0,\"  \"],[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@frame\",\"@needClose\"],[[23,0,[\"model\"]],\"settings.date-types\",true,true]],{\"statements\":[[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\",\"@proportion\"],[[23,0,[\"model\"]],\"name\",\"2-6\"]]],[0,\"\\n    \"],[5,\"form-group\",[],[[\"@property\",\"@label\",\"@proportion\"],[\"excludes\",[28,\"t\",[\"forms.detract\"],null],\"2-6\"]],{\"statements\":[[0,\"\\n      \"],[5,\"input/select/lazy\",[],[[\"@modelName\",\"@selected\",\"@searchField\",\"@onchange\",\"@multiple\"],[\"dateTypeDefinition\",[23,0,[\"model\",\"excludes\"]],\"name\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"model\",\"excludes\"]]],null]],null],true]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"form-group\",[],[[\"@property\",\"@label\",\"@proportion\"],[\"includes\",[28,\"t\",[\"forms.add\"],null],\"2-6\"]],{\"statements\":[[0,\"\\n      \"],[5,\"input/select/lazy\",[],[[\"@modelName\",\"@selected\",\"@searchField\",\"@onchange\",\"@multiple\"],[\"dateTypeDefinition\",[23,0,[\"model\",\"includes\"]],\"name\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"model\",\"includes\"]]],null]],null],true]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/date-types/edit.hbs"
    }
  });

  _exports.default = _default;
});