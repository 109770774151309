define("iris/routes/settings/acl/actions", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model() {
      let store = this.store;
      return Ember.RSVP.hash({
        actions: store.query('action', {
          filter: {
            include: ['children', 'parent'],
            where: {
              menu: 0
            },
            order: 'rank'
          }
        }),
        competencies: store.query('competency', {
          filter: {
            where: {
              builtIn: {
                lt: 2
              }
            }
          }
        })
      });
    },

    afterModel(model) {
      let promises = [];
      model.actions.forEach(action => {
        promises.push(action.get('children'));
      });
      return Ember.RSVP.all(promises);
    }

  });

  _exports.default = _default;
});