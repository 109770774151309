define("iris/components/iris-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9Mjhf1iN",
    "block": "{\"symbols\":[\"@type\",\"@size\",\"@id\",\"@autofocus\",\"@class\",\"@form\",\"@formaction\",\"@formenctype\",\"@formmethod\",\"@formnovalidate\",\"@formtarget\",\"@name\",\"@reset\",\"@title\",\"&attrs\",\"&default\"],\"statements\":[[5,\"bs-button\",[[12,\"autofocus\",[23,4,[]]],[12,\"class\",[28,\"concat\",[[23,5,[]],[28,\"if\",[[23,0,[\"block\"]],\" btn-block\"],null]],null]],[12,\"form\",[23,6,[]]],[12,\"formaction\",[23,7,[]]],[12,\"formenctype\",[23,8,[]]],[12,\"formmethod\",[23,9,[]]],[12,\"formnovalidate\",[23,10,[]]],[12,\"formtarget\",[23,11,[]]],[12,\"name\",[23,12,[]]],[12,\"reset\",[23,13,[]]],[12,\"title\",[23,14,[]]],[13,15]],[[\"@click\",\"@disabled\",\"@type\",\"@size\",\"@iconActive\",\"@iconInactive\",\"@value\",\"@active\",\"@buttonType\",\"@defaultText\",\"@loadingText\",\"@toggle\",\"@textState\",\"@reset\",\"@id\"],[[28,\"action\",[[23,0,[]],\"click\"],null],[23,0,[\"_disabled\"]],[23,1,[]],[23,2,[]],[23,0,[\"_iconActive\"]],[23,0,[\"_iconInactive\"]],[23,0,[\"value\"]],[23,0,[\"active\"]],[23,0,[\"buttonType\"]],[28,\"or\",[[23,0,[\"defaultText\"]],[23,0,[\"text\"]]],null],[28,\"or\",[[23,0,[\"loadingText\"]],[23,0,[\"text\"]]],null],[23,0,[\"toggle\"]],[23,0,[\"textState\"]],[23,0,[\"reset\"]],[23,3,[]]]],{\"statements\":[[0,\"\\n  \"],[14,16],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/iris-button/template.hbs"
    }
  });

  _exports.default = _default;
});