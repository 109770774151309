define("iris/components/forms/work-log-new", ["exports", "ember-concurrency", "iris/lib/modals", "moment", "iris/helpers/work-log-editable"], function (_exports, _emberConcurrency, _modals, _moment, _workLogEditable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      this.loadLocationsTask.perform();
      this.model.set('user', this.sessionAccount.account);
    },

    highlightClass: 'font-bold',
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    modals: Ember.inject.service(),
    productResult: false,
    hours: Ember.computed(function () {
      let res = Ember.A();
      let hour = null;

      for (let i = 0; i < 20; i++) {
        hour = i * 0.25;
        res.pushObject(hour);
      }

      return res;
    }),
    setEnd: Ember.observer('model.publicSpentTime', 'model.spentTime', 'model.startsAt', function () {
      let publicSpentTime = this.get('model.publicSpentTime');
      let spentTime = this.get('model.spentTime');
      let all = publicSpentTime + spentTime;
      let start = this.get('model.startsAt');
      this.set('model.endsAt', (0, _moment.default)(start).add(all, 'h').toDate());
    }),
    maxDate: (0, _moment.default)().endOf('day'),
    minDate: Ember.computed('dayLimit', function () {
      const dayLimit = this.dayLimit;

      if (!dayLimit) {
        return null;
      } else {
        return (0, _moment.default)().startOf('day').subtract(dayLimit, 'day');
      }
    }),
    dayLimit: Ember.computed.reads('model.resolvedTicket.resolvedFile.registrationDayLimit'),

    matcher(ticket, term) {
      term = term.toLowerCase();
      return `${Ember.get(ticket, 'primeContractor')} ${ticket.subject} ${Ember.get(ticket, 'fileName')} ${Ember.get(ticket, 'productResult')}`.toLowerCase().indexOf(term);
    },

    getDefaultWorkPlace: (addresses, fileOwnerPartnerId) => {
      const defaultWorkPlaces = addresses.filter(address => address.defaultWorking);

      if (defaultWorkPlaces.length) {
        const fileOwnerPartnerDefaultWorkPlaces = defaultWorkPlaces.filter(address => address.belongsTo('clientPartner').id() === fileOwnerPartnerId);
        return fileOwnerPartnerDefaultWorkPlaces.length ? fileOwnerPartnerDefaultWorkPlaces.get('firstObject') : defaultWorkPlaces.get('firstObject');
      } else {
        return null;
      }
    },
    loadLocationsTask: (0, _emberConcurrency.task)(function* () {
      const file = yield this.model.get('ticket.file');

      if (!file) {
        return;
      }

      const clientPartnerId = file.belongsTo('clientPartner').id();
      const primeContractorId = file.belongsTo('primeContractor').id();
      const fileOwnerPartnerId = file.belongsTo('fileOwnerPartner').id();
      const addresses = yield this.store.query('address', {
        filter: {
          where: {
            partnerId: {
              inq: [clientPartnerId, primeContractorId, fileOwnerPartnerId]
            },
            possibility: 1
          },
          include: ['clientPartner']
        }
      });

      if (!this.model.belongsTo('location').id() && addresses.length) {
        const defaultWorkPlace = this.getDefaultWorkPlace(addresses, fileOwnerPartnerId);
        this.model.set('location', defaultWorkPlace);
      }

      this.set('locations', addresses);
    }),
    disabled: Ember.computed('model', function () {
      return this.model.id ? !((0, _workLogEditable.workLogEditable)(this.model.createDate) && this.sessionAccount.account.get('id') === this.model.user.get('id') && !this.model.disableEditing) : false;
    }),
    actions: {
      setTime() {
        if (this.model.spentTime === null) {
          this.model.set('spentTime', 0);
        } else if (this.model.publicSpentTime === null) {
          this.model.set('publicSpentTime', 0);
        }
      },

      setSpentTime(hour) {
        let start = this.get('model.startsAt');
        let end = (0, _moment.default)(start).add(hour, 'h');
        this.set('model.endsAt', end.toDate());
        this.set('model.spentTime', hour);
      },

      selectTicket(selected) {
        this.set('selectedTicket', selected);
        let id = selected.id;
        this.store.find('ticket', id).then(ticket => {
          this.model.set('ticket', ticket);
          this.set('productResult', ticket.get('productResult'));
          this.loadLocationsTask.perform();
        });
      },

      uploadFile() {
        const self = this;
        this.modals.open('modals/file-upload-promise', {
          model: self.model,
          only: 'Bejl'
        }, {
          className: _modals.ModalLayoutClassName.L
        });
      }

    }
  });

  _exports.default = _default;
});