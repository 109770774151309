define("iris/components/modals/import-incoming-invoice/modals/import-modal/addresses-carousel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ImportIncomingInvoiceAddPartnerAddressesCarousel = (_dec = Ember.computed('currentSlide'), _dec2 = Ember._action, _dec3 = Ember._action, (_class = class ImportIncomingInvoiceAddPartnerAddressesCarousel extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "currentSlide", 0);
    }

    get indicator() {
      return `${this.currentSlide + 1} / ${this.addresses.length}`;
    }

    swipeLeft() {
      this.set('currentSlide', this.currentSlide - 1);
    }

    swipeRight() {
      this.set('currentSlide', this.currentSlide + 1);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "indicator", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "indicator"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "swipeLeft", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "swipeLeft"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "swipeRight", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "swipeRight"), _class.prototype)), _class));
  _exports.default = ImportIncomingInvoiceAddPartnerAddressesCarousel;
});