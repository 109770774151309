define("iris/components/sl-time-td", ["exports", "moment", "ember-data/model"], function (_exports, _moment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    String
  } = Ember;

  var _default = Ember.Component.extend({
    tagName: '',
    ticketIsEmberData: Ember.computed('ticket', function () {
      return this.get('ticket') instanceof _model.default;
    }),
    deadline: Ember.computed('phase', 'ticket', 'ticket.{reactionDeadline,startDeadline,solveDeadline}', function () {
      const phase = this.get('phase');
      return this.get(`ticket.${phase}Deadline`);
    }),
    violation: Ember.computed('phase', 'ticket', 'ticket.{violationReaction,violationStart,violationSolve}', function () {
      const phase = String.classify(this.get('phase'));
      return this.get(`ticket.violation${phase}`);
    }),
    diff: Ember.computed('deadline', 'clock.minute', function () {
      return Math.abs((0, _moment.default)().diff(this.get('deadline'), 'm'));
    }),
    mappedPhase: Ember.computed('phase', function () {
      const phase = this.get('phase');
      return phase === 'solve' ? 'solving' : phase;
    }),
    serviceLevel: Ember.computed.alias('ticket.serviceLevel'),
    warning: Ember.computed('mappedPhase', 'serviceLevel.id', 'ticketIsEmberData', function () {
      const phase = this.get('mappedPhase');
      const str = this.get('ticketIsEmberData') ? `serviceLevel.${phase}Warning` : `ticket.${phase}Warning`;
      return this.get(str);
    }),
    critical: Ember.computed('mappedPhase', 'serviceLevel.id', 'ticketIsEmberData', function () {
      const phase = this.get('mappedPhase');
      const str = this.get('ticketIsEmberData') ? `serviceLevel.${phase}Critical` : `ticket.${phase}Critical`;
      return this.get(str);
    }),
    isViolated: Ember.computed('clock.minute', function () {
      return (0, _moment.default)().diff(this.get('deadline'), 'm') > 0;
    }),
    original: Ember.computed('phase', 'ticket.{originalReaction,originalStart,originalSolve}', function () {
      const phase = String.classify(this.get('phase'));
      return this.get(`ticket.original${phase}`);
    }),
    classColor: Ember.computed('isViolated', 'warning', 'critical', 'diff', 'original', function () {
      const warning = this.get('warning');
      const critical = this.get('critical');
      const original = this.get('original');
      const diff = this.get('diff');

      if (this.get('isViolated')) {
        return 'text-danger';
      }

      const limit = (1 - diff / original) * 100; // watch out for order!!

      if (limit > critical) {
        return 'text-danger';
      }

      if (limit > warning) {
        return 'text-warning';
      }
    })
  });

  _exports.default = _default;
});