define("iris/helpers/get-query-param", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // See also: app/helpers/update-query-param
  var _default = Ember.Helper.extend({
    queryParams: Ember.inject.service(),

    compute(_ref) {
      let [param] = _ref;
      return Ember.get(this.queryParams, `params.${param}`);
    },

    // I see no other option other than using an observer in this case. Anyway,
    // this doesn't seem to increase the number of times the helper computes or
    // the app renders. If an issue is discovered later, just replace this helper
    // with getting the param value through the injected `queryParams` service
    // inside components. In this case the template must reference to
    // `{{this.queryParams.params.page}}` instead of `{{get-query-param 'page'}}`
    //
    // eslint-disable-next-line ember/no-observers
    _queryParamsDidChange: Ember.observer('queryParams.params', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});