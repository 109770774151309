define("iris/templates/components/forms/proposal-item-import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rvE/1DFR",
    "block": "{\"symbols\":[\"queue\",\"dropzone\"],\"statements\":[[4,\"bs-form\",null,[[\"model\"],[[24,[\"model\"]]]],{\"statements\":[[4,\"form-group\",null,[[\"label\"],[[28,\"t\",[\"forms.uploaded-file\"],null]]],{\"statements\":[[0,\"      \"],[1,[28,\"bs-input\",null,[[\"name\",\"value\",\"disabled\"],[\"file-name\",[24,[\"model\",\"uploadedFile\",\"name\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"pl-uploader\",null,[[\"class\",\"multiple\",\"name\",\"for\",\"extensions\",\"onfileadd\"],[\"top20\",false,\"pu\",\"item-import\",\"xlsx xml\",[28,\"action\",[[23,0,[]],\"importItem\"],null]]],{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"id\",\"item-import\"],[8],[0,\"\\n          \"],[7,\"div\",true],[11,\"id\",[29,[[23,2,[\"id\"]]]]],[10,\"class\",\"dropzone dz-clickable\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"dz-default dz-message text-center\"],[8],[0,\"\\n              \"],[7,\"div\",true],[10,\"class\",\"dz-icon icon-wrap icon-circle icon-wrap-md\"],[8],[0,\"\\n                \"],[7,\"i\",true],[10,\"class\",\"fa fa-cloud-upload fa-3x\"],[8],[9],[0,\"\\n              \"],[9],[0,\"\\n              \"],[7,\"div\",true],[8],[1,[28,\"t\",[\"forms.upload-items\"],null],false],[9],[0,\"\\n            \"],[9],[0,\"\\n\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/proposal-item-import.hbs"
    }
  });

  _exports.default = _default;
});