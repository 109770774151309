define("iris/models/sla-time", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-copyable", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberDataCopyable, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({});

  var _default = _model.default.extend(_emberDataCopyable.default, Validations, {
    reactionTimeStart: (0, _attr.default)('date'),
    reactionTimeEnd: (0, _attr.default)('date'),
    reactionTimePassed: (0, _attr.default)('number'),
    startTimeStart: (0, _attr.default)('date'),
    startTimeEnd: (0, _attr.default)('date'),
    startTimePassed: (0, _attr.default)('number'),
    solvingTimeStart: (0, _attr.default)('date'),
    solvingTimeEnd: (0, _attr.default)('date'),
    solvingTimePassed: (0, _attr.default)('number'),
    actual: (0, _attr.default)('boolean'),
    ticketId: (0, _attr.default)('string'),
    ticket: (0, _relationships.belongsTo)('ticket')
  });

  _exports.default = _default;
});