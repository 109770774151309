define("iris/templates/settings/operations/operation-service-level-type-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pt0XqQjm",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\"],[\"operation-service-level\",\"settings.operations.operation-service-level-type-manager.edit\",[28,\"array\",[\"name\",\"operationServiceLevelType\"],null]]]],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/operations/operation-service-level-type-manager.hbs"
    }
  });

  _exports.default = _default;
});