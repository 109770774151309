define("iris/templates/cmdb/entity-types/entities/entity/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BbtbXiu6",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"attachments-control-component\",null,[[\"model\",\"multiple\",\"only\"],[[24,[\"model\"]],true,\"CMDB\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/entity-types/entities/entity/documents.hbs"
    }
  });

  _exports.default = _default;
});