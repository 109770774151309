define("iris/components/buttons/save-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    type: 'primary',
    _disabled: Ember.computed('disabled', 'value.isSaving', function () {
      const disabled = this.get('disabled');
      return disabled === undefined ? this.get('value.isSaving') : disabled;
    })
  });

  _exports.default = _default;
});