define("iris/components/date-range-picker", ["exports", "ember-cli-daterangepicker/components/date-range-picker"], function (_exports, _dateRangePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dateRangePicker.default.extend({
    classNames: ['date-range-picker']
  });

  _exports.default = _default;
});