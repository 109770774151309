define("iris/components/issue-report/repair-form/component", ["exports", "ember-concurrency", "iris/utils/unwrap-proxy", "iris/utils/converter"], function (_exports, _emberConcurrency, _unwrapProxy, _converter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IssueReportRepairForm = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('model.id'), _dec4 = Ember.computed('ticket.state.id', 'changeRepairEngineerTask.isRunning'), _dec5 = Ember._action, _dec6 = Ember._action, (_class = class IssueReportRepairForm extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "disabled", _descriptor3, this);
    }

    get disabledEngineerSelect() {
      const ticketStateId = this.ticket.belongsTo('state').id();
      const ticketStateIds = ['SYS_07', 'SYS_10'];
      return this.changeRepairEngineerTask.isRunning || ticketStateIds.includes(ticketStateId);
    }

    *generatePDFTask(id) {
      // model is a proxy, we have to unwrap it before save
      const repairForm = (0, _unwrapProxy.unwrapProxy)(this.model);
      const res = yield repairForm.save();
      const response = yield this.api.memberAction('repairForm', 'pdf', id);
      saveAs(_converter.default.b64toBlob(response.$data, 'application/pdf'), `${this.intl.t('work-log.modelName')}-${moment().format('YYYY/MM/DD')}.pdf`);
    }

    *changeRepairEngineerTask(engineer) {
      const {
        flashMessages,
        intl,
        model
      } = this;
      const currentEngineer = yield model.get('repairEngineer');
      model.set('repairEngineer', engineer); // new form should not have auto save on engineer change

      if (model.get('isNew')) {
        return;
      } // model is a proxy, we have to unwrap it before save


      const repairForm = (0, _unwrapProxy.unwrapProxy)(model);
      const res = yield repairForm.save();

      if (res === false) {
        model.set('repairEngineer', currentEngineer);
        return;
      }

      flashMessages.info(intl.t('general.save.success'), {
        title: intl.t('general.save.success-title'),
        icon: 'info'
      });
    }

    async generatePDF(id) {
      await this.generatePDFTask.perform(id);
    }

    async changeRepairEngineer(engineer) {
      await this.changeRepairEngineerTask.perform(engineer);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "disabledEngineerSelect", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "disabledEngineerSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generatePDFTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "generatePDFTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeRepairEngineerTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "changeRepairEngineerTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generatePDF", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "generatePDF"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeRepairEngineer", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "changeRepairEngineer"), _class.prototype)), _class));
  _exports.default = IssueReportRepairForm;
});