define("iris/components/change-requests/edit/tasks/component", ["exports", "iris/lib/modals", "iris/utils/model"], function (_exports, _modals, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    disableButton: Ember.inject.service(),
    modals: Ember.inject.service(),
    intl: Ember.inject.service(),
    tagName: '',

    async init() {
      this._super(); // get accounts


      const accounts = Ember.A();
      const project = await this.model.get('project');
      const fileSolverGroups = await project.get('fileSolverGroups');

      for (const fileSolverGroup of fileSolverGroups.toArray()) {
        const solvingGroup = await fileSolverGroup.get('solvingGroup');
        const accs = await solvingGroup.get('accounts');
        accounts.addObjects(accs.toArray());
      }

      if (!this.isDestroyed && !this.isDestroying) {
        this.set('accounts', accounts);
        this.createRecord();
      }
    },

    createRecord() {
      const task = this.store.createRecord('change-request-task', {
        changeRequest: this.model
      });
      this.set('task', task);
    },

    actions: {
      async addTask() {
        this.set('disableButton.disabled', true);
        const {
          model,
          validations
        } = await (0, _model.validateRecord)(this.task);

        if (validations.get('isValid')) {
          const result = await this.task.save();

          if (result) {
            this.flashMessages.info(this.intl.t('general.save.success'), {
              title: this.intl.t('general.save.success-title'),
              icon: 'times'
            });
            this.createRecord();
          }
        } else {
          model.setValidated();
        }

        this.set('disableButton.disabled', false);
      },

      async selectAffectedEntities() {
        await this.modals.open('change-requests/edit/tasks/affected-entities/modal', {
          changeRequest: this.model
        }, {
          className: _modals.ModalLayoutClassName.Grow
        });
      }

    }
  });

  _exports.default = _default;
});