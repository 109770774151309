define("iris/templates/crm/contracts/new/description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "InOOj+Wc",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"bs-textarea\",null,[[\"class\",\"value\",\"rows\"],[\"border border-gray-500\",[24,[\"model\",\"description\"]],\"7\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/crm/contracts/new/description.hbs"
    }
  });

  _exports.default = _default;
});