define("iris/helpers/string-from-char-code", ["exports", "ember-string-fns/helpers/string-from-char-code"], function (_exports, _stringFromCharCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _stringFromCharCode.default;
    }
  });
  Object.defineProperty(_exports, "stringFromCharCode", {
    enumerable: true,
    get: function () {
      return _stringFromCharCode.stringFromCharCode;
    }
  });
});