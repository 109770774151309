define("iris/helpers/moment-time", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.momentTime = momentTime;

  /*
    {{moment-time 2.5}} // 02:30
    {{moment-time 120 unit='m'}} // 02:00
    {{moment-time 120 unit='m' format='H:mm'}} // 2:00
  
  */
  function momentTime(params, _ref) {
    let {
      unit,
      format
    } = _ref;
    let value = Math.abs(params[0]);
    let u = unit || 'h';
    let f = format || 'HH:mm';
    return _moment.default.duration(value, u).format(f, {
      trim: false
    });
  }

  var _default = Ember.Helper.helper(momentTime);

  _exports.default = _default;
});