define("iris/templates/components/settings/belongs-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vChuDIsC",
    "block": "{\"symbols\":[\"option\",\"@label\"],\"statements\":[[4,\"form-group\",null,[[\"property\",\"class\",\"label\"],[\"ticketCodeFormat\",\"no-gutter\",[23,2,[]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[1,[28,\"bs-input\",null,[[\"disabled\",\"value\"],[true,[24,[\"settingValue\"]]]]],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n\"],[4,\"iris-power-select\",null,[[\"options\",\"selected\",\"onchange\"],[[28,\"sort-by\",[\"longName\",[24,[\"options\"]]],null],[24,[\"selected\"]],[28,\"action\",[[23,0,[]],\"change\"],null]]],{\"statements\":[[0,\"      \"],[1,[23,1,[\"longName\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/settings/belongs-to.hbs"
    }
  });

  _exports.default = _default;
});