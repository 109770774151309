define("iris/routes/finances/pipelines/contracted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function (params) {
      var store = this.store;
      return Ember.RSVP.hash({
        fileBusinessTypes: store.query('fileBusinessType', {
          filter: {
            order: 'name'
          }
        })
      });
    }
  });

  _exports.default = _default;
});