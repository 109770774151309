define("iris/routes/reports/inventories/contacts/items/moves", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function () {
      let store = this.store;
      let inventoryContactId = this.paramsFor('reports.inventories.contacts.items').inventory_contact_id;
      return Ember.RSVP.hash({
        moves: store.query('inventoryContactMove', {
          filter: {
            where: {
              inventoryContactId: inventoryContactId
            }
          }
        })
      });
    },
    columns: Ember.computed(function () {
      return [{
        name: this.intl.t('entity-move.date'),
        property: 'date',
        search: true
      }, {
        name: this.intl.t('entity-move.owner'),
        property: 'userName',
        search: true
      }, {
        name: this.intl.t('entity-move.entity'),
        property: 'entity.name',
        search: true
      }, {
        name: this.intl.t('entity-move.source'),
        property: 'source.name',
        search: true
      }, {
        name: this.intl.t('entity-move.destination'),
        property: 'destination.name',
        search: true
      }];
    }),
    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set('columns', this.get('columns'));
    }
  });

  _exports.default = _default;
});