define("iris/components/form/field/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S4aUlER4",
    "block": "{\"symbols\":[\"&attrs\",\"@errorMessage\"],\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[13,1],[8],[0,\"\\n    \"],[1,[28,\"fa-icon\",[\"warning\"],[[\"class\"],[\"mr-2\"]]],false],[0,\"\\n    \"],[1,[23,2,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/form/field/error/template.hbs"
    }
  });

  _exports.default = _default;
});