define("iris/models/file-proposal-item", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-copyable", "ember-cp-validations", "bankers-rounding"], function (_exports, _model, _attr, _relationships, _emberDataCopyable, _emberCpValidations, _bankersRounding) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 100
    })],
    description: (0, _emberCpValidations.validator)('length', {
      max: 500
    }),
    qty: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('number-own')],
    price: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('number-own')],
    uom: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('belongs-to')],
    vat: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('belongs-to')],
    currency: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  var _default = _model.default.extend(_emberDataCopyable.default, Validations, {
    name: (0, _attr.default)('string'),
    partNumber: (0, _attr.default)('string'),
    description: (0, _attr.default)('string', {
      formWidget: 'textarea'
    }),
    price: (0, _attr.default)('number', {
      defaultValue: 0,
      formWidget: 'mask'
    }),
    amount: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    amountUntaxed: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    defaultAmount: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    defaultAmountUntaxed: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    qty: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    exchangeRate: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    exchangeRateContract: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    exchangeRateDate: (0, _attr.default)('date', {
      defaultValue: () => new Date()
    }),
    arrivalDate: (0, _attr.default)('date', {
      defaultValue: () => new Date()
    }),
    currency: (0, _relationships.belongsTo)('currency'),
    proposal: (0, _relationships.belongsTo)('fileProposal'),
    productResult: (0, _relationships.belongsTo)('fileProductResult'),
    vat: (0, _relationships.belongsTo)('vat'),
    uom: (0, _relationships.belongsTo)('uom'),
    isValid: Ember.computed.alias('proposal.valid'),
    isPaid: Ember.computed.alias('proposal.paid'),

    /**
     * Sets amountUntaxed, amount, defaultAmount on file proposal item,
     * based on its exchangeRate, price, qty
     *
     * @param {*} precision // number of the default currency's rounding digits
     */
    async recalculate(precision) {
      const {
        exchangeRate,
        price,
        qty
      } = this;
      const vat = await this.get('vat');
      const {
        vatValue
      } = vat;
      const currency = await this.get('currency');
      const roundDigits = currency.round; // net

      const amountUntaxed = (0, _bankersRounding.default)(price * qty, roundDigits); // gross

      const amount = (0, _bankersRounding.default)(amountUntaxed * (1 + vatValue), roundDigits); // net in the region's default currency

      const defaultAmountUntaxed = exchangeRate === 1 ? amountUntaxed : (0, _bankersRounding.default)(amountUntaxed * exchangeRate, precision); // gross in the region's default currency

      const defaultAmount = exchangeRate === 1 ? amount : (0, _bankersRounding.default)(amount * exchangeRate, precision);
      this.setProperties({
        amountUntaxed,
        amount,
        defaultAmountUntaxed,
        defaultAmount
      });
    }

  });

  _exports.default = _default;
});