define("iris/components/entity-attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sortProps: ['orderIndex'],
    sortedColumns: Ember.computed.sort('columns', 'sortProps'),
    sortedValues: Ember.computed('values', 'sortedColumns', function () {
      let left = Ember.A();
      let right = Ember.A();
      let sortedColumns = this.get('sortedColumns');
      let values = this.get('values');
      let l = Math.ceil(sortedColumns.length / 2);
      let i = 0;
      sortedColumns.forEach(col => {
        let value = null;
        values.forEach(val => {
          if (val.get('entityAttribute.id') === col.id) {
            value = val;
          }
        });

        if (i < l) {
          left.pushObject(value);
        } else {
          right.pushObject(value);
        }

        i++;
      });
      return {
        'left': left,
        'right': right
      };
    }),
    relationObjects: Ember.computed('columns', function () {
      let res = Ember.A();
      let columns = this.get('columns');
      columns.forEach(col => {
        res[col.get('related.id')] = col.get('related.entities'); //options
      });
      return res;
    })
  });

  _exports.default = _default;
});