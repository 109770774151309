define("iris/lib/table/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RowActionPlacement = void 0;
  _exports.rowActions = rowActions;
  _exports.rowValues = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * Defines the config for row actions.
   *
   * Apart from the `action` function, which has the context of the object
   * `rowActions` is defined on when executed, all other CPs (`disabled`,
   * `routeParams, or custom ones) have the internal `RowActions` component as
   * context, which has a `row` property to be able to set up dependent keys and
   * access row data. The `action` function also receives the `row` object as the
   * first argument.
   *
   * - `icon` (required) is passed to `{{fa-icon this.icon}}`, which means it has
   *   to be a `font-awesome@4.7.0`-compatible icon name.
   * - The `routeParams` CP (required when `route` is present) must return a
   *   string or an array of strings, as this value is passed as `<LinkTo
   *   @models={{this.routeParams}}>`.
   * - The `style` property can only have exactly 1 value when defined:
   *   `'danger'`. This is because row actions that have a `route` property
   *   instead of `action` already have their own style to be able to distinguish
   *   them from regular actions.
   * - `disabled` should be a CP that returns either a falsy value, (`undefined`
   *   or `false` does the job all the same), or must return a string that
   *   describes why the action is disabled, to help users better.
   *
   * Decorator Example:
   *
   * ```js
   * @rowActions
   * get rowActions() {
   *   return [
   *     {
   *       name: 'Vizualizáció',
   *       icon: 'eye',
   *       route: 'cmdb.visualisation',
   *       routeParams: computed.alias('row.id'),
   *     },
   *     {
   *       name: 'Törlés',
   *       icon: 'trash',
   *       style: 'danger',
   *       disabled: computed.not('row.isDeletable'),
   *       action(row) {
   *         this.someService.destroyRecordById(row.id);
   *       },
   *     },
   *   ];
   * })
   * ```
   *
   * Classic Example:
   *
   * ```js
   * rowActions: rowActions(function () {
   *   return [
   *     {
   *       name: 'Vizualizáció',
   *       icon: 'eye',
   *       route: 'cmdb.visualisation',
   *       routeParams: computed.alias('row.id'),
   *     },
   *     {
   *       name: 'Törlés',
   *       icon: 'trash',
   *       style: 'danger',
   *       disabled: computed.not('row.isDeletable'),
   *       action(row) {
   *         this.someService.destroyRecordById(row.id);
   *       },
   *     },
   *   ];
   * })
   * ```
   */
  function rowActions(fn) {
    // Unless wrapped to a CP, the action's context wouldn't be the component
    // where `rowActions` are declared.
    return Ember.computed(function () {
      return createRowActionsConfig(this, fn);
    });
  }

  let RowActionPlacement; // `actionContext` is the component where the `rowActions` are defined.

  _exports.RowActionPlacement = RowActionPlacement;

  (function (RowActionPlacement) {
    RowActionPlacement["Default"] = "default";
    RowActionPlacement["Rest"] = "rest";
  })(RowActionPlacement || (_exports.RowActionPlacement = RowActionPlacement = {}));

  const createRowActionsConfig = (actionContext, getter) => {
    // Call the config with the host object's context. Without doing this, the
    // dependent key for the `disabled` property wouldn't work.
    return getter.call(actionContext).map(config => {
      var _class, _temp, _config$placement;

      return _temp = _class = class ConfigClass extends Ember.Object.extend(config) {
        // Bind the action to action's context, to be able to access services &
        // properties on the object where the row actions are defined.
        action() {
          var _config$action;

          for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          (_config$action = config.action) === null || _config$action === void 0 ? void 0 : _config$action.apply(actionContext, args);
        }

      }, _defineProperty(_class, "placement", (_config$placement = config.placement) !== null && _config$placement !== void 0 ? _config$placement : RowActionPlacement.Default), _temp;
    });
  };
  /**
   * **WARNING!** `rowValues()` returns a function, NOT a computed property, which
   * means it must be called before using it on an Ember class, such as
   * `randomProp: rowValues('entityTypeId', 'id')()`. **However, this behavior is
   * handled when used within column definitions**, so the below is perfectly
   * valid *within a the `columns()` util's individual members*:
   *
   * ```js
   * columns: columns('customAttributes', function () {
   *   return [
   *     {
   *       name: 'name',
   *       route: 'cmdb.entity-types.entities.entity.attributes',
   *       routeParams: rowValues('entityTypeId', 'id'),
   *     },
   *   ];
   * }),
   * ```
   *
   * This is because `computed` became a decorator which is much harder to deal
   * with. The whole point of the util is to define `rowValues` on one object to
   * have it configured, and copy it to where it'll be actually used.
   *
   * The above is basically a shorthand for:
   * ```js
   * routeParams: computed('row.entityTypeId', 'row.id', function () {
   *   return [row.entityTypeId, row.id];
   * }),
   * ```
   *
   * Supports property paths, like `rowValues('project.id')`
   */


  const rowValues = function () {
    for (var _len2 = arguments.length, attrs = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      attrs[_key2] = arguments[_key2];
    }

    return function () {
      let dependentKeys = attrs.map(path => `row.${path}`);
      return Ember.computed.apply(this, [...dependentKeys, function () {
        return attrs.reduce((acc, attr) => {
          acc.push(Ember.get(this.row, attr));
          return acc;
        }, []);
      }]);
    };
  };

  _exports.rowValues = rowValues;
});