define("iris/templates/settings/cron-jobs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fya5Tbfu",
    "block": "{\"symbols\":[],\"statements\":[[5,\"tables/cron-jobs\",[],[[],[]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/cron-jobs.hbs"
    }
  });

  _exports.default = _default;
});