define("iris/utils/properties/computed-task", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedTask;
  const setDecorator = Ember._setClassicDecorator || Ember._setComputedDecorator;
  /**
   * Creates a computed property that'll return the result of the associated task
   * (also created under the hood), which'll be executed when the dependent keys
   * change. Note that the task won't run, unless the value is consumed!
   *
   * Example:
   *
   * ```js
   * funnyName: computedTask('name', function * () {
   *    return yield fetch(`/api/funny-name/${this.name}`);
   * })
   * ```
   *
   * In this example, we have access to:
   *
   * - `funnyName` with the result of the fetch action
   * - `funnyNameTask`, on which we can acces the status of the task (error,
   *   isLoading, etc.)
   *
   * Refer to tests for more details. Original implementation:
   * https://www.barelyknown.com/posts/async-computed-properties-in-ember
   */

  function computedTask() {
    for (var _len = arguments.length, args = new Array(_len), _key2 = 0; _key2 < _len; _key2++) {
      args[_key2] = arguments[_key2];
    }

    if (isDecoratorOptions(args[0])) {
      throw new Error('Using computedTask as a decorator is not supported yet.');
    } else {
      let {
        dependentKeys,
        generatorFn
      } = parseArgs(args);

      let cp = function (proto, key) {
        return Ember.computed(taskCP(key, dependentKeys, generatorFn))(...arguments);
      };

      setDecorator(cp);
      return cp;
    }
  }

  const ComputedTaskProperty = function () {
    for (var _len2 = arguments.length, args = new Array(_len2), _key3 = 0; _key3 < _len2; _key3++) {
      args[_key3] = arguments[_key3];
    }

    let {
      dependentKeys,
      generatorFn
    } = parseArgs(args);
    return new Ember.ComputedProperty(taskCP(null, dependentKeys, generatorFn));
  };

  ComputedTaskProperty.prototype = Object.create(Ember.ComputedProperty.prototype);

  function taskCP(key, dependentKeys, generatorFn) {
    return function (_key) {
      let propertyName = key !== null && key !== void 0 ? key : _key;
      let taskPropertyName = `${propertyName}Task`;
      let didInitKeyName = `didCtInit-${propertyName}`;
      let didInit = this.get(didInitKeyName);
      let valueKeyName = `${taskPropertyName}.lastSuccessful.value`;

      if (!didInit) {
        Ember.defineProperty(this, taskPropertyName, (0, _emberConcurrency.task)(generatorFn).restartable()); // eslint-disable-next-line ember/no-observers

        this.addObserver(valueKeyName, () => {
          this.notifyPropertyChange(propertyName);
        });
        this.get(taskPropertyName).perform();
        let expandedKeys = [];
        dependentKeys.forEach(dk => {
          Ember.expandProperties(dk, propName => {
            expandedKeys.push(propName);
          });
        });
        expandedKeys.forEach(ek => {
          // eslint-disable-next-line ember/no-observers
          this.addObserver(ek, () => {
            this.get(taskPropertyName).perform();
          });
        }); // eslint-disable-next-line ember/no-side-effects

        this.set(didInitKeyName, true);
      }

      return this.get(valueKeyName);
    };
  }

  const parseArgs = args => ({
    dependentKeys: args.slice(0, -1),
    generatorFn: args[args.length - 1]
  });

  function isDecoratorOptions(possibleOptions) {
    if (!possibleOptions) {
      return false;
    }

    if (typeof possibleOptions === 'function') {
      return false;
    }

    if (typeof possibleOptions === 'object' && 'perform' in possibleOptions && typeof possibleOptions.perform === 'function') {
      return false;
    }

    return Object.getPrototypeOf(possibleOptions) === Object.prototype;
  }
});