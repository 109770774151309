define("iris/mixins/is-validated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    setValidated: function (visited) {
      this.set('didValidate', true);
      visited = visited ? visited : [];

      var _this = this,
          rel;

      this.eachRelationship((name, descriptor) => {
        if (visited.contains(name)) {
          return false;
        }

        visited.push(name);

        if (descriptor.kind === 'belongsTo') {
          rel = _this.belongsTo(descriptor.key);
          var value = rel ? rel.value() : null;

          if (value) {
            value.setValidated(visited);
          }
        }

        if (descriptor.kind === 'hasMany') {
          rel = _this.hasMany(descriptor.key);
          var value = rel ? rel.value() : null;

          if (value) {
            value.invoke('setValidated', visited);
          }
        }
      });
    },

    unsetValidated(visited) {
      this.set('didValidate', false);
      visited = visited ? visited : [];

      var _this = this,
          rel;

      this.eachRelationship((name, descriptor) => {
        if (visited.contains(name)) {
          return false;
        }

        visited.push(name);

        if (descriptor.kind === 'belongsTo') {
          rel = _this.belongsTo(descriptor.key);
          var value = rel ? rel.value() : null;

          if (value) {
            value.setValidated(visited);
          }
        }

        if (descriptor.kind === 'hasMany') {
          rel = _this.hasMany(descriptor.key);
          var value = rel ? rel.value() : null;

          if (value) {
            value.invoke('setValidated', visited);
          }
        }
      });
    }

  });

  _exports.default = _default;
});