define("iris/validators/presence", ["exports", "ember-cp-validations/validators/presence"], function (_exports, _presence) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _presence.default.extend({
    value(model, attribute) {
      let isBelongsTo = false;

      if (typeof model.eachRelationship === 'function') {
        model.eachRelationship((name, _ref) => {
          let {
            kind
          } = _ref;

          if (name === attribute && kind === 'belongsTo') {
            isBelongsTo = true;
          }
        });
      }

      if (isBelongsTo) {
        var _relationship$id, _relationship$value;

        let relationship = model.belongsTo(attribute); // If a `belongsTo` relationship has an ID, or is a new record (which, by
        // definition, is "loaded"), it passes the presence validator.
        //
        // Note that only `undefined` or `null` are accepted as falsy values here,
        // as `false` is treated as a valid boolean value.

        return ((_relationship$id = relationship.id()) !== null && _relationship$id !== void 0 ? _relationship$id : (_relationship$value = relationship.value()) === null || _relationship$value === void 0 ? void 0 : _relationship$value.isNew) || null;
      }

      return this._super(model, attribute);
    }

  });

  _exports.default = _default;
});