define("iris/templates/components/bs-modal-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ozoxq0Jy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"closeButton\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"close\"],[12,\"aria-label\",\"Close\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"×\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1,[[23,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"h4\",true],[10,\"class\",\"modal-title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/bs-modal-header.hbs"
    }
  });

  _exports.default = _default;
});