define("iris/components/shift-changes/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShiftChanges = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class ShiftChanges extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "value", 0);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "modals", _descriptor2, this);
    }

    async addShiftBegin() {
      const {
        flashMessages,
        intl,
        model: solvingGroup,
        value: shiftBeginTime
      } = this;
      const shiftChanges = await solvingGroup.get('shiftChanges');
      const alreadyExists = shiftChanges.find(sc => sc.shiftBeginTime === shiftBeginTime);

      if (alreadyExists) {
        return;
      }

      const shiftChange = this.store.createRecord('shiftChange', {
        shiftBeginTime,
        solvingGroup
      });
      shiftChanges.addObject(shiftChange);
      this.set('value', 0); // if solving group is not new, we should save the shift change immediately

      if (solvingGroup.id) {
        try {
          await shiftChange.save();
          flashMessages.info(intl.t('general.save.success'), {
            title: intl.t('general.save.success-title'),
            icon: 'floppy-o'
          });
        } catch (error) {
          flashMessages.danger(intl.t('general.save.error'));
        }
      }
    }

    async deleteShiftChange(shiftChange) {
      const {
        flashMessages,
        intl,
        modals
      } = this;
      const confirmed = await modals.open('beta/modals/confirmation', {
        title: intl.t('general.deletion'),
        body: intl.t('modals.delete-confirmation')
      });

      if (!confirmed) {
        return;
      }

      try {
        const shiftChanges = await this.model.get('shiftChanges');
        shiftChanges.removeObject(shiftChange);
        await shiftChange.destroyRecord(true);
        flashMessages.info(this.intl.t('general.delete.success'), {
          title: this.intl.t('general.delete.success-title'),
          icon: 'times'
        });
      } catch (error) {
        flashMessages.danger(intl.t('general.delete.error'), {
          title: intl.t('general.delete.error'),
          icon: 'warning'
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addShiftBegin", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "addShiftBegin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteShiftChange", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "deleteShiftChange"), _class.prototype)), _class));
  _exports.default = ShiftChanges;
});