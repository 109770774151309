define("iris/components/forms/ticket-form", ["exports", "iris/lib/modals"], function (_exports, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modals: Ember.inject.service(),
    actions: {
      uploadFile() {
        const self = this;
        this.modals.open('modals/file-upload-promise', {
          model: self.model,
          only: 'Bejl'
        }, {
          className: _modals.ModalLayoutClassName['L']
        });
      }

    }
  });

  _exports.default = _default;
});