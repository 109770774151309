define("iris/models/tax-number-pattern-example", ["exports", "@ember-data/model", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    example: (0, _emberCpValidations.validator)('pattern-example')
  });

  var _default = _model.default.extend(Validations, {
    example: (0, _model.attr)('string'),
    comment: (0, _model.attr)('string'),
    taxNumberPattern: (0, _relationships.belongsTo)('taxNumberPattern')
  });

  _exports.default = _default;
});