define("iris/components/beta/table/status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8EBb886v",
    "block": "{\"symbols\":[\"@error\",\"@isDisabled\",\"@isLoading\",\"&attrs\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"\\n    absolute inset-0 z-10 rounded-sm\\n    flex items-center justify-center bg-white dark:bg-black\\n    \",[28,\"if\",[[28,\"or\",[[23,3,[]],[23,2,[]]],null],\"bg-opacity-40 dark:bg-opacity-40\"],null],\"\\n    \",[28,\"if\",[[23,1,[]],\"bg-opacity-80 dark:bg-opacity-60\"],null],\"\\n  \"]]],[13,4],[8],[0,\"\\n\"],[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"spin-spinner\",null,[[\"lines\",\"length\",\"width\",\"scale\",\"color\"],[13,20,10,0.2,[28,\"if\",[[23,0,[\"theme\",\"dark\"]],\"#fff\",\"#000\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[10,\"class\",\"\\n      px-3 py-2 rounded\\n      bg-red-500 bg-opacity-10 dark:bg-red-900 dark:bg-opacity-40\\n      text-red-600 border-2 border-red-600\\n    \"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"tables.request-error\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/beta/table/status/template.hbs"
    }
  });

  _exports.default = _default;
});