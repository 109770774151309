define("iris/templates/components/modals/delete-confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fCY6/liw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-tether\",null,[[\"to\",\"target\",\"targetModifier\",\"attachment\",\"tetherClass\",\"overlayClass\"],[\"confirmation-modal\",\"document.body\",\"visible\",\"middle center\",\"modal-dialog confirm\",\"modal-backdrop\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n        \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[1,[28,\"t\",[\"general.deletion\"],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n        \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"modals.delete-confirmation\"],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n        \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"primary\",[22,\"confirm\"],[28,\"t\",[\"general.confirms\"],null]]]],[0,\"\\n        \"],[1,[28,\"iris-button\",null,[[\"type\",\"action\",\"text\"],[\"default\",[24,[\"cancel\"]],[28,\"t\",[\"general.back\"],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/modals/delete-confirmation.hbs"
    }
  });

  _exports.default = _default;
});