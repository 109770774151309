define("iris/routes/cmdb/procedures", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    setupController(controller, model) {
      this._super(controller, model);

      let tabs = [{
        name: this.intl.t('entity-procedure.procedures'),
        route: 'cmdb.procedures.overview'
      }, {
        name: this.intl.t('entity-procedure.changes'),
        route: 'cmdb.procedures.changes'
      }];
      this.controller.set('tabs', tabs);
    },

    actions: {
      solverGroupApplyToAll(entityProcedures, model) {
        entityProcedures.forEach(element => {
          element.set('solvingGroup', model.get('solvingGroup'));
        });
      },

      notificationApplyToAll(entityProcedures, model) {
        entityProcedures.forEach(element => {
          element.set('description', model.get('description'));
          element.set('priorityWarning', model.get('priorityCritical'));
          element.set('priorityCritical', model.get('priorityCritical'));
          element.set('notificationWarning', model.get('notificationCritical'));
          element.set('notificationCritical', model.get('notificationCritical'));
        });
      },

      notificationGroupApplyToAll(entityProcedures, model) {
        entityProcedures.forEach(element => {
          element.set('notificationGroups', model.get('notificationGroups'));
          element.set('dontSaveChange', 1);
          element.save(true);
          element.set('dontSaveChange', 0);
        });
        model.set('isDirtyTemp', new Date());
      }

    }
  });

  _exports.default = _default;
});