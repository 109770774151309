define("iris/components/change-requests/edit/persons-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    modals: Ember.inject.service(),
    intl: Ember.inject.service(),
    columns: Ember.computed('type', 'disabled', function () {
      const columns = Ember.A([{
        name: this.intl.t('models.changeRequest.name')
      }, {
        name: this.intl.t('models.changeRequest.email')
      }, {
        name: this.intl.t('models.changeRequest.source')
      }, {
        name: ''
      }]);

      if (this.type === 1) {
        columns.insertAt(3, {
          name: this.intl.t('models.changeRequest.respond')
        });
      }

      if (this.disabled) {
        columns.popObject();
      }

      return columns;
    }),
    notifications: Ember.computed.filter('model.[]', function (notification) {
      return notification.id && notification.type === Number(this.type);
    }),
    actions: {
      async delete(changeRequestNotification) {
        let shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

        if (shouldDelete) {
          try {
            await changeRequestNotification.destroyRecord(true);
            this.flashMessages.info(this.intl.t('general.delete.success-title'), {
              title: this.intl.t('general.delete.success'),
              icon: 'times'
            });
          } catch (error) {
            this.flashMessages.danger(this.intl.t('general.delete.error'));
          }
        }
      }

    }
  });

  _exports.default = _default;
});