define("iris/components/show-region-element/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X9jRoSq0",
    "block": "{\"symbols\":[\"&default\",\"@key\"],\"statements\":[[4,\"if\",[[28,\"compute\",[[28,\"action\",[[23,0,[]],[23,0,[\"regionElement\",\"show\"]],[23,2,[]]],null]],null]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/show-region-element/template.hbs"
    }
  });

  _exports.default = _default;
});