define("iris/mixins/protected-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    beforeModel(model) {
      this.get('ajax').call('GET', 'Actions', '', 'canRoute', {
        route: this.get('routeName')
      }).then(c => {
        if (!c) {
          this.transitionTo('/forbidden');
        }
      });
    }

  });

  _exports.default = _default;
});