define("iris/models/aiops-service-level", ["exports", "iris/models/aiops-base", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _aiopsBase, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    operationServiceLevel: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _aiopsBase.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    reactionTime: (0, _attr.default)('number'),
    solvingTime: (0, _attr.default)('number'),
    operationServiceLevel: (0, _relationships.belongsTo)('aiopsOperationServiceLevel'),
    translations: Ember.A()
  });

  _exports.default = _default;
});