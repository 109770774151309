define("iris/models/entity-move", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    date: (0, _attr.default)('date'),
    type: (0, _attr.default)('string'),
    destination: (0, _relationships.belongsTo)('contact'),
    source: (0, _relationships.belongsTo)('contact'),
    entity: (0, _relationships.belongsTo)('entity'),
    owner: (0, _relationships.belongsTo)('account')
  });

  _exports.default = _default;
});