define("iris/components/xwiki-component", ["exports", "iris/lib/modals", "iris/config/environment"], function (_exports, _modals, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    modals: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function () {
      this._super();

      if (this.get('disableInit') !== true) {
        this.initWiki();
      }
    },

    async initWiki() {
      var _disableXWiki$get;

      let disableXWiki = await this.get('store').query('regionalSetting', {
        filter: {
          where: {
            settingKey: 'disableXWiki'
          }
        }
      });

      if ((disableXWiki === null || disableXWiki === void 0 ? void 0 : (_disableXWiki$get = disableXWiki.get('firstObject')) === null || _disableXWiki$get === void 0 ? void 0 : _disableXWiki$get.settingValue) == 'true') {
        return;
      }

      this.set('loading', true);
      const rows = [];

      for (const tag of this.tags) {
        const response = await $.ajax({
          type: 'GET',
          url: _environment.default.wiki.url + '/bin/view/Main/Tags?do=viewTag&tag=' + tag
        });
        let ar = response.split('<ul class="xlist">');

        if (ar.length < 2) {
          continue;
        }

        let result = ar[1].split('</ul>');

        if (result.length === 0) {
          continue;
        }

        let html = $(result[0]);

        if (typeof html.html() !== 'undefined') {
          html.find('a').each(function (index) {
            rows.push({
              path: decodeURIComponent($(this).attr('href').replace(`/xwiki/bin/view/`, '')),
              url: _environment.default.wiki.url.substr(0, _environment.default.wiki.url.lastIndexOf('/')) + $(this).attr('href'),
              name: $(this).text(),
              tag
            });
          });
        }
      }

      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      this.set('rows', rows);
      this.set('loading', false);
    },

    uniqueRows: Ember.computed('rows.[]', function () {
      let arr = this.get('rows');
      let key = 'tag';
      let result = [];
      let map = new Map();

      for (let obj of arr) {
        if (!map.has(obj.name)) {
          obj.tags = [obj[key]];
          delete obj[key];
          map.set(obj.name, obj);
        } else {
          let storedObj = map.get(obj.name);
          storedObj.tags.push(obj[key]);
        }
      }

      map.forEach(val => result.push(val));
      return result;
    }),
    spaces: Ember.A(),
    rows: Ember.A(),
    loading: false,
    columns: Ember.computed(function () {
      return [{
        name: this.intl.t('components-main.xwiki.page-name'),
        property: 'name',
        search: true
      }, {
        name: this.intl.t('components-main.xwiki.route'),
        property: 'path',
        search: true
      }, {
        name: this.intl.t('components-main.xwiki.tags'),
        property: 'tag',
        search: true
      }];
    }),
    tagObserver: Ember.observer('tags', function () {
      this.initWiki();
      return true;
    }),
    actions: {
      selectPath: async function (space, spaces) {
        const selectedSpace = await this.modals.open('modals/xwiki-path-tree', {
          space,
          spaces
        }, {
          className: _modals.ModalLayoutClassName.L
        });
        this.set('path', selectedSpace);
      },
      openCreateWikiPage: function () {
        let _this = this;

        this.toggleProperty('create');

        if (this.get('create')) {
          //getSpaces
          $.ajax({
            type: 'GET',
            url: _environment.default.wiki.url + '/bin/get/Main/WebHome?xpage=getdocuments&childrenOf=' + _environment.default.wiki.rootSpace + '&queryFilters=unique,hidden&offset=1&limit=15&sort=doc.location&dir=asc',
            success: function (response) {
              if (_this.isDestroying || _this.isDestroyed) {
                return;
              }

              let spaces = Ember.A();
              response.rows.forEach(space => {
                spaces.push(space.doc_space);
              });

              _this.set('spaces', spaces);
            }
          });
        }
      },
      createWikiPage: function () {
        let _this = this;

        let description = ''; // Validation

        if (!this.get('pageName')) {
          description += `${this.intl.t('components-main.xwiki.error.page-name')}<br />`;
        }

        if (!this.get('path')) {
          description += `${this.intl.t('components-main.xwiki.error.path')}<br />`;
        }

        if (!this.get('selectedTags') || this.get('selectedTags').length == 0) {
          description += `${this.intl.t('components-main.xwiki.error.tag')}<br />`;
        }

        if (description) {
          this.flashMessages.danger(description, {
            title: this.intl.t('components-main.xwiki.error.error-title'),
            icon: 'warning',
            sticky: true
          });
          return;
        }

        let spaces = this.get('path').split('.');
        let pageName = this.get('pageName');
        let tags = this.get('selectedTags');
        let spacesStr = '',
            spacesStr2 = '';
        spaces.forEach(s => {
          spacesStr += '/spaces/' + s;
          spacesStr2 += s + '/';
        });
        let XMLData = `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
      <page xmlns="http://www.xwiki.org">
        <title>${pageName}</title>
        <content>...</content>
      </page>`;
        let TAGData = `className=TagClass&property#tags=${tags.join()}`;
        $.ajax({
          type: 'PUT',
          contentType: 'application/atom+xml',
          url: _environment.default.wiki.url + '/rest/wikis/xwiki' + spacesStr + '/pages/' + pageName,
          data: XMLData,
          success: function (response) {
            if (_this.isDestroying || _this.isDestroyed) {
              return;
            }

            $.ajax({
              type: 'POST',
              contentType: 'application/x-www-form-urlencoded',
              url: _environment.default.wiki.url + '/rest/wikis/xwiki' + spacesStr + '/pages/' + pageName + '/objects',
              data: TAGData,
              success: function (response) {
                window.open(_environment.default.wiki.url + '/bin/view/' + spacesStr2 + pageName);

                _this.toggleProperty('create');

                _this.set('pageName', null);

                _this.set('selectedTags', null);

                _this.set('path', null);

                _this.initWiki();
              }
            });
          }
        });
      },
      openUrl: function (url) {
        window.open(url);
      }
    }
  });

  _exports.default = _default;
});