define("iris/services/upload-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    avtive: false,
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    uploader: Ember.inject.service(),

    async uploadFile(model, file) {
      const accessToken = this.get('session.data.authenticated.id');
      const uploader = this.get('uploader');
      const modelName = model.get('modelName');
      const owner = Ember.getOwner(this);
      const adapter = owner.lookup('adapter:application');
      const baseUrl = adapter.buildURL();
      const store = this.get('store');
      const documents = await model.get('documents');
      const apiModelName = Ember.String.classify(model.constructor.modelName);

      if (file.file.modelName !== modelName) {
        return;
      }

      try {
        const url = `${baseUrl}/documents/upload?access_token=${accessToken}`;
        const res = await file.upload(url, {
          data: {
            sourceId: model.id,
            sourceType: apiModelName,
            documentTypeId: file.file.documentTypeId
          }
        });

        for (const json of res.body) {
          // backend should push the catched error into the response array
          // with error key and the filename
          if (json.error) {
            this.flashMessages.danger(this.intl.t('general.upload-error', {
              fileName: json.fileName
            }), {
              title: this.intl.t('general.error'),
              icon: 'warning',
              sticky: true
            });
            continue;
          }

          store.pushPayload(json);
          const document = store.peekRecord('document', json.data.id);
          documents.reload();
        }
      } catch (e) {
        console.error(e);
      }
    },

    async uploadPendingFiles(model) {
      let queueName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const accessToken = this.get('session.data.authenticated.id');
      const uploader = this.get('uploader');
      let files = uploader.get('files');

      if (queueName) {
        files = files.filter(f => f.queue.name === queueName);
      }

      const modelName = model.get('modelName');
      const owner = Ember.getOwner(this);
      const adapter = owner.lookup('adapter:application');
      const baseUrl = adapter.buildURL();
      const store = this.get('store');
      const documents = await model.get('documents');
      const apiModelName = Ember.String.classify(model.constructor.modelName);

      for (const file of files) {
        if (file.file.modelName !== modelName) {
          continue;
        }

        try {
          const url = `${baseUrl}/documents/upload?access_token=${accessToken}`;
          const res = await file.upload(url, {
            data: {
              sourceId: model.id,
              sourceType: apiModelName,
              documentTypeId: file.file.documentTypeId
            }
          });

          for (const json of res.body) {
            // backend should push the catched error into the response array
            // with error key and the filename
            if (json.error) {
              this.flashMessages.danger(this.intl.t('general.upload-error', {
                fileName: json.fileName
              }), {
                title: this.intl.t('general.error'),
                icon: 'warning',
                sticky: true
              });
              continue;
            }

            store.pushPayload(json);
            const document = store.peekRecord('document', json.data.id);
            documents.reload();
          }
        } catch (e) {
          console.error(e);
        }
      }
    }

  });

  _exports.default = _default;
});