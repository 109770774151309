define("iris/components/comment-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    currentComment: '',
    currentTarget: '',
    currentTargetId: '',
    currentPrivate: false,
    disabled: false,
    deleteConfirmation: false,
    commentToDelete: null,
    currentUser: Ember.computed.reads('sessionAccount.account'),

    /*
    commentToggleVisible: computed('comments.length', function () {
      return this.get('comments.length') > 5;
    }),
    */
    commentToggleVisible: false,
    commentToggle: false,
    openComment: false,
    comments: Ember.computed('currentTarget', 'currentTargetId', function () {
      let filter = {
        filter: {
          where: {
            and: [{
              sourceType: this.get('currentTarget')
            }]
          },
          order: 'createDate asc'
        }
      };

      if (this.get('currentTargetId') !== '') {
        filter.filter.where.and.push({
          sourceId: this.get('currentTargetId')
        });
      }

      if (!this.get('allowPrivate')) {
        filter.filter.where.and.push({
          private: 0
        });
      }

      return this.get('store').query('comment', filter);
    }),
    toggledComments: Ember.computed.reads('comments'),
    actions: {
      reloadData() {
        this.notifyPropertyChange('comments');
      },

      toggleCommentToggle() {
        this.set('commentToggle', !this.get('commentToggle'));
      },

      openCommentAction() {
        this.set('openComment', true);

        let _this = this;

        Ember.run.later(function () {
          _this.$('.trumbowyg-box').css('min-height', '100px');

          _this.$('.trumbowyg-editor').css('min-height', '100px');

          _this.$('.trumbowyg-editor').focus();

          const d = _this.$('#portal-issue-report-form');

          d.scrollTop(d.prop('scrollHeight'));
        }, 200);
      },

      closeComment() {
        this.set('openComment', false);
      },

      async sendComment() {
        if (!this.get('currentComment')) {
          return;
        }

        let commentRecord = await this.get('store').createRecord('comment', {
          userId: this.get('currentUser.id'),
          value: this.get('currentComment'),
          sourceType: this.get('currentTarget'),
          sourceId: this.get('currentTargetId'),
          user: this.get('currentUser'),
          private: this.get('currentPrivate')
        });
        const res = await commentRecord.save();

        if (!res) {
          return;
        }

        this.notifyPropertyChange('comments');
        this.afterAddComment && this.afterAddComment();
        this.set('currentComment', null);
        this.set('currentPrivate', false);
        this.set('openComment', false);
      },

      deleteComment(comment) {
        this.set('commentToDelete', comment);
        this.set('deleteConfirmation', true);
      },

      confirmDelete: function () {
        let commentToDelete = this.get('commentToDelete');
        const this2 = this;
        commentToDelete.destroyRecord().then(function () {
          this2.notifyPropertyChange('comments');
          this2.set('deleteConfirmation', false);
        });
      },
      cancelDelete: function () {
        this.set('commentToDelete', null);
        this.set('deleteConfirmation', false);
      },

      sendMailAboutComment(comment) {}

    }
  });

  _exports.default = _default;
});