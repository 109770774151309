define("iris/components/beta/table/pagination/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    count: null,
    // @
    storage: null,
    // @, Storage
    actions: {
      updateStorageParam(param, value) {
        if (param === 'limit') {
          // Reset page when limit is changed to avoid (possibly) non-existent
          // pages
          this.storage.update('limit', value);
          this.storage.update('page', 1);
        } else {
          this.storage.update(param, value);
        }
      }

    }
  });

  _exports.default = _default;
});