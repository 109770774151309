define("iris/routes/unauth/contact-registration", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: (0, _emberCpValidations.validator)('format', {
      type: 'email',
      regex: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      messageKey: 'unauth.emailRegex'
    }),
    password: (0, _emberCpValidations.validator)('format', {
      regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,18}$/,
      messageKey: 'unauth.passwordRegex'
    }),
    passwordConfirmation: (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      messageKey: 'unauth.passwordNotMatch'
    })
  });
  let registration = Ember.Object.extend(Validations, {
    email: null,
    password: null,
    passwordConfirmation: null
  });

  var _default = Ember.Route.extend({
    model() {
      return registration.create(Ember.getOwner(this).ownerInjection());
    }

  });

  _exports.default = _default;
});