define("iris/templates/cmdb/entity-types/entities/new-entity/attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YL+gxxw8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"entity\",\"entityValues\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h4\",true],[10,\"class\",\"h4\"],[8],[1,[28,\"t\",[\"cmdb.entity.device-type-fields\"],null],false],[9],[0,\"\\n  \"],[1,[28,\"entity-attributes\",null,[[\"values\",\"columns\",\"codeStorePath\"],[[24,[\"model\",\"entity\",\"entityValues\"]],[24,[\"model\",\"columns\"]],\"cmdb.entity-types.entities.new-entity.attributes.code-store\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"model\",\"entity\",\"customValues\"]]],null,{\"statements\":[[0,\"  \"],[7,\"hr\",true],[10,\"class\",\"--porto-hr\"],[8],[9],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"h4\"],[8],[1,[28,\"t\",[\"cmdb.attributes.region-fields\"],null],false],[9],[0,\"\\n  \"],[1,[28,\"forms/custom-attributes\",null,[[\"values\"],[[24,[\"model\",\"entity\",\"customValues\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"br\",true],[8],[9],[0,\"\\n\"],[1,[28,\"forms/entity-attribute-custom\",null,[[\"attributes\"],[[24,[\"model\",\"entity\",\"customAttributes\"]]]]],false],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/entity-types/entities/new-entity/attributes.hbs"
    }
  });

  _exports.default = _default;
});