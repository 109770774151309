define("iris/models/contract-action-competency-assoc", ["exports", "ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    contract: (0, _relationships.belongsTo)('contract'),
    action: (0, _relationships.belongsTo)('action'),
    competency: (0, _relationships.belongsTo)('competency')
  });

  _exports.default = _default;
});