define("iris/utils/issue-report/ticket", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setServiceLevel = _exports.setDatesFromServiceLevel = _exports.getResolvers = _exports.getFilteredServiceLevels = _exports.getEntities = _exports.generateTicketUserAssoc = _exports.createWorkLog = void 0;

  const generateTicketUserAssoc = async (store, ticket) => {
    const startDate = ticket.get('plannedStartDate');
    const deadline = ticket.get('plannedEndDate');
    const diff = (0, _moment.default)(deadline).diff((0, _moment.default)(startDate), 'h');
    const tua = store.createRecord('ticketUserAssoc', {
      plannedExpensesInHour: diff,
      user: ticket.get('resolver'),
      ticket: ticket,
      ticketState: ticket.get('state'),
      startDate: startDate,
      deadline: deadline
    });
    return await tua.save();
  };
  /**
   * Gets the resolvers based on the ticket's projects. Grouped by solvig groups, ordered by servicePerson (if any)
   * and by name. **Important!!** It returns ObjectProxies with accounts
   *   - Linux
   *     - Zelda (servicePerson1)
   *     - Beavis
   *     - Roger Wilco
   *   - Windows
   *     - Guybrush Threepwood
   * @param {*} store Ember Data store instance
   * @param {*} file ticket's project
   */


  _exports.generateTicketUserAssoc = generateTicketUserAssoc;

  const getResolvers = async (store, file) => {
    const fileSolverGroups = await store.query('fileSolverGroup', {
      filter: {
        where: {
          fileId: file.get('id')
        } // file is a proxy we need .get
        // does not work, API throws callback already called nested include is a no no.
        // not supported by loopback-json-api-component ?
        // include: [{'solvingGroup': 'accounts'}],

      }
    }); // as above mentioned that nested include wont work, but we still dont want to ask the solvingGroups' `accounts`
    // relation one by one, therofore we include them here, with only one extra request

    const solvingGroupsIds = fileSolverGroups.map(fsg => fsg.belongsTo('solvingGroup').id());
    const solvingGroups = await store.query('solvingGroup', {
      filter: {
        where: {
          id: {
            inq: solvingGroupsIds
          }
        },
        include: [{
          relation: 'accounts',
          scope: {
            fields: ['longName'],
            where: {
              enabled: true
            }
          }
        }, 'servicePerson1', 'servicePerson2']
      }
    });
    const sorted = await sortSolvingGroupMembers(solvingGroups);
    const managerUser = file.get('managerUser');
    sorted.push({
      groupName: 'SZM',
      options: [managerUser]
    });
    return sorted;
  };

  _exports.getResolvers = getResolvers;

  const sortSolvingGroupMembers = async solvingGroups => {
    const res = [];

    for (const sg of solvingGroups.toArray()) {
      const accounts = await sg.get('accounts');
      const arr = accounts.toArray().filter(a => a.enabled).map(a => Ember.ObjectProxy.create({
        content: a,
        servicePerson: null
      }));
      arr.forEach(a => {
        if (sg.get('servicePerson1.id') === a.get('id')) {
          a.set('servicePerson', 1);
        }

        if (sg.get('servicePerson2.id') === a.get('id')) {
          a.set('servicePerson', 2);
        }
      });
      const sorted = arr.sort((a, b) => {
        return (a.get('servicePerson') || Number.MAX_SAFE_INTEGER) - (b.get('servicePerson') || Number.MAX_SAFE_INTEGER) || a.get('longName').localeCompare(b.get('longName'));
      });
      res.push({
        groupName: sg.get('name'),
        options: sorted
      });
    }

    return res;
  };
  /**
   * set startDate / endDate of the ticket based on its serviceLevel
   *
   * @param {*} ticket
   */


  const setDatesFromServiceLevel = ticket => {
    let startTime = ticket.get('serviceLevel.startTime');
    let solvingTime = ticket.get('serviceLevel.solvingTime');
    let startDate = (0, _moment.default)().add(startTime, 'm').toDate();
    let endDate = (0, _moment.default)().add(solvingTime, 'm').toDate();
    ticket.set('plannedStartDate', startDate);
    ticket.set('plannedEndDate', endDate);
  };
  /**
   * Gets the ticket's affected-entities, based on the ticket's businessService.id and contact.id
   *
   * @param {*} ticket ED record
   * @param {*} api Service
   */


  _exports.setDatesFromServiceLevel = setDatesFromServiceLevel;

  const getEntities = async (ticket, api) => {
    const entityId = ticket.belongsTo('businessService').id();
    const contactId = ticket.belongsTo('contact').id();
    const result = await api.memberAction('entity', 'affected-entities', entityId);
    const entityIds = result.result.map(item => item.entity_id);
    return await api.query('entity', {
      filter: {
        where: {
          or: [{
            id: {
              inq: entityIds
            }
          }, {
            partnerContactId: contactId
          }]
        }
      }
    });
  };

  _exports.getEntities = getEntities;

  const createWorkLog = (store, user, ticket) => {
    return store.createRecord('workLog', {
      ticket,
      description: 'Új munkalap',
      user,
      beginDate: (0, _moment.default)().toDate(),
      endDate: (0, _moment.default)().toDate()
    });
  };

  _exports.createWorkLog = createWorkLog;

  const getFilteredServiceLevels = async (store, ticket) => {
    const typeId = ticket.belongsTo('type').id();
    const operationServiceLevelId = ticket.belongsTo('operationServiceLevel').id();
    const serviceLevel = await store.query('serviceLevel', {
      filter: {
        where: {
          operationServiceLevelId,
          typeId
        },
        include: ['priority']
      }
    });
    return serviceLevel.toArray();
  };
  /**
   * Keeps the tickets's priority sync with its serviceLevel's priority
   *
   * @param {*} model ED Ticket instance
   * @param {*} serviceLevel ED record
   */


  _exports.getFilteredServiceLevels = getFilteredServiceLevels;

  const setServiceLevel = (model, serviceLevel) => {
    const priority = serviceLevel ? serviceLevel.get('priority.value') : null;
    model.set('serviceLevel', serviceLevel);
    model.set('priority', priority);
  };

  _exports.setServiceLevel = setServiceLevel;
});