define("iris/routes/operation/report-submit/other-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let store = this.store;
      return Ember.RSVP.hash({
        ticket: store.createRecord('ticket')
      });
    },

    actions: {
      saveRemoteAccess() {
        var reportController = this.controllerFor('operation.report-submit').get("model.ticket");
        reportController.set('contactDescription', this.currentModel.ticket.get("contactDescription"));
        this.transitionTo('operation.report-submit');
      }

    },
    setupController: function (controller, model) {
      this._super(controller, model);

      var contactDescription = this.controllerFor('operation.report-submit').get("model.ticket.contactDescription");

      if (this.currentModel.ticket.get('contactDescription') === null || this.currentModel.ticket.get('contactDescription') === undefined) {
        this.currentModel.ticket.set('contactDescription', contactDescription);
      }
    }
  });

  _exports.default = _default;
});