define("iris/components/forms/application-to-intervene/feedback-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ticsFinance: Ember.computed('model.ticketInterventionContacts.[]', function () {
      return this.get('model.ticketInterventionContacts').filter(item => {
        return item.get('type') === 3 && item.get('id');
      });
    }),
    tiusFinance: Ember.computed('model.ticketInterventionUsers.[]', function () {
      return this.get('model.ticketInterventionUsers').filterBy('type', 3);
    }),
    ticsTech: Ember.computed('model.ticketInterventionContacts.[]', function () {
      return this.get('model.ticketInterventionContacts').filterBy('type', 4);
    }),
    tiusTech: Ember.computed('model.ticketInterventionUsers.[]', function () {
      return this.get('model.ticketInterventionUsers').filterBy('type', 4);
    }),
    all: Ember.computed('ticsFinance.[]', 'tiusFinance.[]', 'ticsTech.[]', 'tiusTech.[]', function () {
      let res = Ember.A();
      res.push(...this.get('ticsFinance'));
      res.push(...this.get('tiusFinance'));
      res.push(...this.get('ticsTech'));
      res.push(...this.get('tiusTech'));
      return res;
    }),
    indicator: Ember.computed('all.@each.confirmed', function () {
      let all = this.get('all');
      let confirmed = all.filter(i => i.get('confirmed') === 0);
      let accepted = all.filter(i => i.get('confirmed') === 1);
      let denied = all.filter(i => i.get('confirmed') === 2);

      if (confirmed.length === all.length) {
        return 'info';
      }

      if (accepted.length === all.length) {
        return 'success';
      }

      if (denied.length === all.length) {
        return 'danger';
      }

      return 'warning';
    })
  });

  _exports.default = _default;
});