define("iris/routes/unauth/confirmation/change-request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),

    async model(params) {
      const {
        token
      } = params;
      const model = await this.get('ajax').call('GET', 'changeRequestNotification', '', 'check-token/' + token, null, false);
      model.token = token;
      return model;
    }

  });

  _exports.default = _default;
});