define("iris/templates/crm/contracts/edit/values", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6O83/LQ3",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"forms/contract-values\",null,[[\"model\",\"currencies\",\"disableInput\"],[[24,[\"model\",\"contract\"]],[24,[\"model\",\"currencies\"]],[24,[\"disableInput\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/crm/contracts/edit/values.hbs"
    }
  });

  _exports.default = _default;
});