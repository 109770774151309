define("iris/models/entity-attribute", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-copyable", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberDataCopyable, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 150
    })],
    property: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 150
    }), (0, _emberCpValidations.validator)('slug')],
    type: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 150
    })]
  });

  var _default = _model.default.extend(Validations, _emberDataCopyable.default, {
    name: (0, _attr.default)('string'),
    property: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    orderIndex: (0, _attr.default)('number'),
    required: (0, _attr.default)('boolean'),
    searchable: (0, _attr.default)('boolean'),
    listable: (0, _attr.default)('boolean'),
    icinga: (0, _attr.default)('number'),
    defaultValue: (0, _attr.default)('string'),
    regexp: (0, _attr.default)('string'),
    regexpFlags: (0, _attr.default)('string'),
    min: (0, _attr.default)('number'),
    max: (0, _attr.default)('number'),
    parent: (0, _relationships.belongsTo)('entityAttribute', {
      inverse: 'children'
    }),
    children: (0, _relationships.hasMany)('entityAttribute', {
      inverse: 'parent'
    }),
    related: (0, _relationships.belongsTo)('entityType'),
    entityType: (0, _relationships.belongsTo)('entityType'),
    hasUnsavedDependencies: Ember.computed.equal('parent.id', null)
  });

  _exports.default = _default;
});