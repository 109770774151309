define("iris/components/file-progress/half-doughnut-chart-js", ["exports", "jquery", "chart.js"], function (_exports, _jquery, _chart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "canvas",
    attributeBindings: ["width", "height"],
    didInsertElement: function () {
      this._super(...arguments);

      var context = this.get("element");
      var data = this.get("data");
      var type = this.get("type");
      var options = this.get("options");
      var chart = new _chart.default(context, {
        plugins: INLINE_CHARTJS_PLUGINS,
        type: type,
        data: data,
        options: options
      });
      this.set("chart", chart);
      this.addObserver("data", this, this.updateChart);
      this.addObserver("data.[]", this, this.updateChart);
      this.addObserver("options", this, this.updateChart);
    },
    willDestroyElement: function () {
      this._super(...arguments);

      this.get("chart").destroy();
      this.removeObserver("data", this, this.updateChart);
      this.removeObserver("data.[]", this, this.updateChart);
    },
    updateChart: function () {
      var chart = this.get("chart");
      var data = this.get("data");
      var options = this.get("options");
      var bottomText = this.get("bottomText");
      chart.config.data = data;

      if (chart.options.elements.center) {
        chart.options.elements.center.fontColor = options.elements.center.fontColor;
      }

      if (bottomText) {
        var centerConfig = chart.config.options.elements.center;
        var ctx = chart.chart.ctx;
        ctx.save();
        ctx.font = chart.center.font;
        ctx.fillStyle = chart.center.fillStyle;
        ctx.textAlign = "center";
        ctx.textBaseline = "top";
        var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
        var widnowWidth = (0, _jquery.default)(window).width();
        var centerY = chart.chartArea.bottom - Math.round(widnowWidth / 70); // (chart.chartArea.top + chart.chartArea.bottom) / 2

        ctx.fillText(centerConfig.text, centerX, centerY);
        ctx.restore();
      }

      chart.update();
    }
  });

  _exports.default = _default;
  const INLINE_CHARTJS_PLUGINS = [// round corners
  {
    afterUpdate: function (chart) {
      if (chart.config.options.elements.arc.roundedCornersFor !== undefined) {
        var arc = chart.getDatasetMeta(0).data[chart.config.options.elements.arc.roundedCornersFor];
        arc.round = {
          x: (chart.chartArea.left + chart.chartArea.right) / 2,
          y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
          radius: (chart.outerRadius + chart.innerRadius) / 2,
          thickness: (chart.outerRadius - chart.innerRadius) / 2 - 1,
          backgroundColor: arc._model.backgroundColor
        };
      }
    },
    afterDraw: function (chart) {
      if (chart.config.options.elements.arc.roundedCornersFor !== undefined) {
        var ctx = chart.chart.ctx;
        var arc = chart.getDatasetMeta(0).data[chart.config.options.elements.arc.roundedCornersFor];
        var startAngle = Math.PI / 2 - arc._view.startAngle;
        var endAngle = Math.PI / 2 - arc._view.endAngle;
        ctx.save();
        ctx.translate(arc.round.x, arc.round.y);
        ctx.fillStyle = arc.round.backgroundColor;
        ctx.beginPath();
        ctx.arc(arc.round.radius * Math.sin(startAngle), arc.round.radius * Math.cos(startAngle), arc.round.thickness, 0, 2 * Math.PI);
        ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fill();
        ctx.restore();
      }
    }
  }, // write text plugin
  {
    afterUpdate: function (chart) {
      if (chart.config.options.elements.center) {
        var helpers = _chart.default.helpers;
        var centerConfig = chart.config.options.elements.center;
        var globalConfig = _chart.default.defaults.global;
        var ctx = chart.chart.ctx;
        var fontStyle = helpers.getValueOrDefault(centerConfig.fontStyle, globalConfig.defaultFontStyle);
        var fontFamily = helpers.getValueOrDefault(centerConfig.fontFamily, globalConfig.defaultFontFamily);
        if (centerConfig.fontSize) var fontSize = centerConfig.fontSize; // figure out the best font size, if one is not specified
        else {
          ctx.save();
          var fontSize = helpers.getValueOrDefault(centerConfig.minFontSize, 1);
          var maxFontSize = helpers.getValueOrDefault(centerConfig.maxFontSize, 256);
          var maxText = helpers.getValueOrDefault(centerConfig.maxText, centerConfig.text);

          do {
            ctx.font = helpers.fontString(fontSize, fontStyle, fontFamily);
            var textWidth = ctx.measureText(maxText).width; // check if it fits, is within configured limits and that we are not simply toggling back and forth

            if (textWidth < chart.innerRadius * 2 && fontSize < maxFontSize) fontSize += 1;else {
              // reverse last step
              fontSize -= 1;
              break;
            }
          } while (true);

          ctx.restore();
        } // save properties

        chart.center = {
          font: helpers.fontString(fontSize, fontStyle, fontFamily),
          fillStyle: helpers.getValueOrDefault(centerConfig.fontColor, globalConfig.defaultFontColor)
        };
      }
    },
    beforeDraw: function (chart) {
      if (chart.center) {
        var centerConfig = chart.config.options.elements.center;
        var ctx = chart.chart.ctx;
        ctx.save();
        ctx.font = chart.center.font;
        ctx.fillStyle = chart.center.fillStyle;
        ctx.textAlign = "center";
        ctx.textBaseline = "top";
        var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
        var widnowWidth = (0, _jquery.default)(window).width();
        var centerY = chart.chartArea.bottom - Math.round(widnowWidth / 70);
        ctx.fillText(centerConfig.text, centerX, centerY);
        ctx.restore();
      }
    }
  }, {
    afterUpdate: function (chart) {
      if (chart.config.options.elements.circles) {
        chart.circles = true;
      }
    },

    afterDraw(chart) {
      let ctx = chart.chart.ctx;

      if (chart.circles) {
        ctx.save();
        let meta = chart.getDatasetMeta(0);
        meta.data.forEach((data, i) => {
          let model = data._model;
          let y = (model.base - model.y) / 2 + model.y;
          let x = model.x;
          let value = meta.controller._data[i];
          if (value) drawCircle(x, y, value, model.width);
        });

        function drawCircle(x, y, value, width) {
          ctx.fillStyle = "white";
          ctx.strokeStyle = "#7BC425";
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.textAlign = "center";
          ctx.textBaseline = "top";
          ctx.arc(x, y, width / 1.45, 0, 2 * Math.PI);
          ctx.fill();
          ctx.stroke();
          ctx.fillStyle = "black";
          ctx.fillText(value, x, y);
          ctx.restore();
        }
      }
    }

  }];
});