define("iris/routes/finances/invoices/prompt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    actions: {
      async sendPromptEmail(invoices) {
        const ids = invoices.map(i => i.get('id'));

        try {
          await this.get('ajax').call('POST', 'invoice', '', 'send-prompt', {
            'ids': ids
          });
          this.transitionTo('finances.invoices');
        } catch (e) {
          const {
            errors
          } = e;
          errors.forEach(error => {
            this.get('flashMessages').danger(error.detail, {
              title: 'Hiba',
              icon: 'times',
              sticky: true
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});