define("iris/controllers/settings/operations/priorities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    refresh: true,
    col4Empty: true,
    col5Empty: true,
    addRow: false,
    actions: {
      refreshTable() {
        this.set('refresh', true);
      }

    }
  });

  _exports.default = _default;
});