define("iris/models/inventory", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    footer: (0, _emberCpValidations.validator)('presence', {
      presence: true
    })
  });

  var _default = _model.default.extend(Validations, {
    createDate: (0, _attr.default)('date'),
    stop: (0, _attr.default)('number'),
    forAssignedContact: (0, _attr.default)('boolean'),
    chooseContacts: (0, _attr.default)('number'),
    status: (0, _attr.default)('string'),
    partnerNames: (0, _attr.default)('string'),
    inventoryContacts: (0, _relationships.hasMany)('inventoryContact'),
    partners: (0, _relationships.hasMany)('partner'),
    contacts: (0, _relationships.hasMany)('contact'),
    departments: (0, _relationships.hasMany)('department'),
    addresses: (0, _relationships.hasMany)('address'),
    buildings: (0, _relationships.hasMany)('building'),
    floors: (0, _relationships.hasMany)('floor'),
    footer: (0, _relationships.belongsTo)('footer')
  });

  _exports.default = _default;
});