define("iris/routes/human-resource-management/event-approvement", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    sessionAccount: Ember.inject.service(),

    async model() {
      const {
        store
      } = this;
      const {
        account
      } = this.sessionAccount;
      const substituteForIds = this.get('sessionAccount.substituteForIds');
      const leaderIds = Array.isArray(substituteForIds) ? [account.id].concat(substituteForIds) : [account.id];
      return Ember.RSVP.hash({
        operationableUsers: store.query('account', {
          filter: {
            order: 'longName',
            where: {
              and: [{
                leaderId: {
                  inq: leaderIds
                }
              }, {
                enabled: 1
              }]
            }
          }
        })
      });
    },

    actions: {
      didTransition() {
        const routeName = this.controllerFor('application').get('currentRouteName');

        if (!routeName || !routeName.includes('.event-approvement')) {
          this.controller.set('selectedUsers', Ember.A());
        }

        return true;
      }

    }
  });

  _exports.default = _default;
});