define("iris/controllers/beta/grid/index", ["exports", "iris/lib/filters/column", "iris/lib/filters/storage"], function (_exports, _column, _storage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    storage: (0, _storage.useStorage)('memory'),
    columns: (0, _column.columns)(function () {
      return [{
        name: 'A',
        displayName: 'EredményTermék'
      }, {
        name: 'B',
        displayName: 'Cím'
      }, {
        name: 'C',
        displayName: 'Kezdés'
      }, {
        name: 'D',
        displayName: 'Vége'
      }];
    }),
    rows: Ember.computed(function () {
      return [{
        A: 'Tervezés',
        children: [{
          A: 'A',
          B: 'B',
          C: 'C',
          D: 'D'
        }, {
          A: 'A',
          B: 'B',
          C: 'C',
          D: 'D'
        }, {
          A: 'A',
          B: 'B',
          C: 'C',
          D: 'D'
        }]
      }, {
        A: 'Megoldás',
        children: [{
          A: 'A',
          B: 'B',
          C: 'C',
          D: 'D'
        }, {
          A: 'A',
          B: 'B',
          C: 'C',
          D: 'D'
        }, {
          A: 'A',
          B: 'B',
          C: 'C',
          D: 'D'
        }]
      }, {
        A: 'Bevezetés',
        children: [{
          A: 'A',
          B: 'B',
          C: 'C',
          D: 'D'
        }, {
          A: 'A',
          B: 'B',
          C: 'C',
          D: 'D'
        }, {
          A: 'A',
          B: 'B',
          C: 'C',
          D: 'D'
        }]
      }];
    })
  });

  _exports.default = _default;
});