define("iris/templates/components/forms/file-overview/file-operational", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x2S4i8X6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h5\",true],[10,\"class\",\"h5\"],[8],[1,[28,\"t\",[\"file-operational.overview-operational\"],null],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/file-overview/file-operational.hbs"
    }
  });

  _exports.default = _default;
});