define("iris/components/power-select-multiple-shortened", ["exports", "underscore"], function (_exports, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    shortedSelectionCount: 3,
    cuttedOptions: Ember.computed('options.[]', 'selected.[]', function () {
      const this2 = this;
      let options = this.get('options');

      if (!options || !options.length) {
        return Ember.A();
      }

      return _underscore.default.filter(options._result ? options._result : options, function (obj) {
        return !_underscore.default.findWhere(this2.get('selected'), obj);
      });
    }),
    needShorting: Ember.computed('selected.[]', function () {
      return this.get('selected.length') > this.get('shortedSelectionCount');
    }),
    shortedSelection: Ember.computed('selected.[]', function () {
      return _underscore.default.sortBy(this.get('selected'), function (select) {
        return select.text;
      }).slice(0, this.get('shortedSelectionCount'));
    }),
    shortedOpened: false,
    currentSelection: Ember.computed('shortedOpened.[]', 'selected.[]', 'shortedSelection', function () {
      return this.get('shortedOpened') && this.get('needShorting') ? this.get('selected') : this.get('shortedSelection');
    }),
    actions: {
      currentOnChange(columnFilter, selected) {
        let fullSelected = this.get('selected');

        if (this.get('needShorting') && this.get('shortedOpened')) {
          this.sendAction('onchange', selected);
        } else {
          fullSelected = mergeSelections(selected, fullSelected, this.get('shortedSelection'), this.get('shortedSelectionCount'));
          this.sendAction('onchange', fullSelected);
        }
      },

      toggleShortedOpened() {
        this.set('shortedOpened', !this.get('shortedOpened'));
      }

    }
  });

  _exports.default = _default;

  function mergeSelections(selected, fullSelected, shortedSelection, shortedSelectionCount) {
    if (selected.length < fullSelected.length && selected.length < shortedSelectionCount) {
      let deleted = _underscore.default.difference(shortedSelection, selected);

      fullSelected = _underscore.default.difference(fullSelected, deleted);
    } else {
      fullSelected = _underscore.default.uniq(_underscore.default.union(selected, fullSelected), false, _underscore.default.property('condition'));
    }

    return fullSelected;
  }
});