define("iris/components/issue-report/priority-new/component", ["exports", "iris/utils/find-service-period", "iris/utils/issue-report/ticket"], function (_exports, _findServicePeriod, _ticket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let IssueReportPriorityNew = (_dec = Ember.computed('priorityMatrices.[]'), _dec2 = Ember.computed('priorityMatrices.[]'), _dec3 = Ember._action, _dec4 = Ember._action, (_class = class IssueReportPriorityNew extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "priorityMatrices", null);

      _defineProperty(this, "filteredServiceLevels", null);

      _defineProperty(this, "selectedUrgency", null);

      _defineProperty(this, "selectedImportance", null);
    }

    get importances() {
      var _this$priorityMatrice;

      return (_this$priorityMatrice = this.priorityMatrices) === null || _this$priorityMatrice === void 0 ? void 0 : _this$priorityMatrice.mapBy('priorityImportance').uniqBy('value').sortBy('value');
    }

    get urgencies() {
      var _this$priorityMatrice2;

      return (_this$priorityMatrice2 = this.priorityMatrices) === null || _this$priorityMatrice2 === void 0 ? void 0 : _this$priorityMatrice2.mapBy('priorityUrgency').uniqBy('value').sortBy('value');
    }

    async _setServiceLevel(serviceLevel) {
      (0, _ticket.setServiceLevel)(this.model, serviceLevel);
      await this.onServiceLevelChange(serviceLevel);
    }

    async setServiceLevel() {
      if (!this.selectedUrgency || !this.selectedImportance) {
        return;
      }

      const priorityMatrix = this.priorityMatrices.find(matrix => {
        return matrix.belongsTo('priorityUrgency').id() === this.selectedUrgency.get('id') && matrix.belongsTo('priorityImportance').id() === this.selectedImportance.get('id');
      });

      if (!priorityMatrix) {
        await this._setServiceLevel(null);
        return;
      }

      const serviceLevels = this.filteredServiceLevels.filter(sl => {
        return sl.belongsTo('priority').id() === priorityMatrix.belongsTo('priority').id();
      });
      const serviceLevel = await this.chooseServiceLevel(serviceLevels);
      await this._setServiceLevel(serviceLevel);
      (0, _ticket.setDatesFromServiceLevel)(this.model);
      this.set('priorityMatrix', priorityMatrix);
    }

    async chooseServiceLevel(serviceLevels) {
      // find 24/7 if any return it
      for (const serviceLevel of serviceLevels) {
        const sp = await serviceLevel.get('servicePeriods');

        if (sp.length === 0) {
          return serviceLevel;
        }
      }

      if (serviceLevels.length === 1) {
        return serviceLevels.get('firstObject');
      } else {
        const fsp = new _findServicePeriod.default();
        return await fsp.getServiceLevel(serviceLevels);
      }
    }

    selectUrgency(urgency) {
      this.set('selectedUrgency', urgency);
      this.setServiceLevel();
    }

    selectImportance(importance) {
      this.set('selectedImportance', importance);
      this.setServiceLevel();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "importances", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "importances"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "urgencies", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "urgencies"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectUrgency", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "selectUrgency"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectImportance", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectImportance"), _class.prototype)), _class));
  _exports.default = IssueReportPriorityNew;
});