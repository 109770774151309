define("iris/routes/operation/scheduled-tasks/new", ["exports", "iris/mixins/incident-save", "iris/mixins/protected-route"], function (_exports, _incidentSave, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_incidentSave.default, _protectedRoute.default, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    userAssocName: 'ticketTemplateUserAssoc',

    async model() {
      const ticketSource = await this.get('store').query('ticketSource', {
        filter: {
          where: {
            systemCode: 9
          }
        }
      });
      return this.get('store').createRecord('ticketTemplate', {
        scheduledTaskTemplate: true,
        createUser: this.get('sessionAccount.account'),
        ticketSource: ticketSource.get('firstObject')
      });
    }

  });

  _exports.default = _default;
});