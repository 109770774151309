define("iris/components/buildings-wizard", ["exports", "iris/utils/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    level: 0,
    record: Ember.computed('level', function () {
      let store = this.get('store');
      let level = this.get('level');
      let types = Ember.A(['building', 'floor', 'location']);
      return store.createRecord(types.objectAt(level));
    }),
    texts: Ember.computed(function () {
      return [this.intl.t('address.buildings'), this.intl.t('address.floors'), this.intl.t('address.place')];
    }),
    buttonText: Ember.computed('level', 'texts', function () {
      let texts = this.get('texts');
      return texts.objectAt(this.get('level') + 1);
    }),
    headerText: Ember.computed('level', 'texts', function () {
      return this.get('texts').objectAt(this.get('level'));
    }),
    levels: Ember.A(['buildings', 'floors', 'locations']),
    breadcrumbConfig: Ember.A([{
      property: 'fullAddress',
      parent: ''
    }, {
      property: 'name',
      parent: 'address'
    }, {
      property: 'name',
      parent: 'building'
    }, {
      property: 'name',
      parent: 'floor'
    }]),
    breadcrumbs: Ember.computed('model', 'level', function () {
      let i = this.get('level');
      let model = this.get('model');
      let config = this.get('breadcrumbConfig');
      let res = Ember.A();

      while (i > -1) {
        let configItem = config.objectAt(i);
        let obj = {
          text: model.get(configItem.property),
          model: model,
          level: i
        };
        res.unshiftObject(obj);
        model = model.get(configItem.parent);
        i--;
      }

      return res;
    }),
    children: Ember.computed('level', function () {
      return this.get('levels').objectAt(this.get('level'));
    }),
    columns: Ember.computed(function () {
      retrun[{
        name: this.intl.t('general.name'),
        property: 'name'
      }];
    }),
    actions: {
      changeLevel(model) {
        if (this.get('levels.length') === this.get('level') + 1) {
          return;
        }

        this.set('model', model);
        this.incrementProperty('level');
      },

      navigate(model, level) {
        this.set('model', model);
        this.set('level', level);
      },

      add(record) {
        let children = this.get('children');
        let clone = record.copy();
        this.get('model.' + children).then(children => {
          children.pushObject(clone);
          (0, _model.resetRecordAttrs)(record);
        });
      }

    }
  });

  _exports.default = _default;
});