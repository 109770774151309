define("iris/templates/settings/cmdb/manufacturers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4wCQgb0e",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@defaultOrder\",\"@route\"],[\"entity-manufacturer\",\"createDate DESC\",\"settings.cmdb.manufacturers.edit\"]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/cmdb/manufacturers.hbs"
    }
  });

  _exports.default = _default;
});