define("iris/components/character-counter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kMwwdj3+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"h-12 text-right\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"show\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[8],[1,[28,\"t\",[\"character-counter\"],[[\"remaining\"],[[23,0,[\"remaining\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/character-counter/template.hbs"
    }
  });

  _exports.default = _default;
});