define("iris/components/holidays-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    holidayIndicator: true,
    days: Ember.computed(function () {
      return this.store.findAll('calendar-exception');
    }),
    selectedDate: null,
    holidays: Ember.computed.filterBy('days', 'holiday'),
    workDays: Ember.computed.filterBy('days', 'holiday', false),
    actions: {
      toggleType() {
        this.toggleProperty('holidayIndicator');
      },

      async addDate() {
        const record = this.store.createRecord('calendar-exception', {
          date: this.get('selectedDate'),
          holiday: this.get('holidayIndicator')
        });
        await record.save(true);
        this.set('selectedDate', null);
      }

    }
  });

  _exports.default = _default;
});