define("iris/routes/cmdb/entity-types/entities/entity/moves", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function () {
      var store = this.store;
      let entity_id = this.paramsFor('cmdb.entity-types.entities.entity').entity_id;
      return Ember.RSVP.hash({
        moves: store.query('entityMove', {
          filter: {
            where: {
              entityId: entity_id
            }
          },
          order: 'date desc'
        })
      });
    },
    columns: Ember.computed(function () {
      return [{
        name: this.intl.t('entity-move.date'),
        property: 'date',
        search: true
      }, {
        name: this.intl.t('entity-move.owner'),
        property: 'userName',
        search: true
      }, {
        name: this.intl.t('entity-move.entityName'),
        property: 'entitname',
        search: true
      }, {
        name: this.intl.t('entity-move.description'),
        property: 'entitdescription',
        search: true
      }, {
        name: this.intl.t('entity-move.entityType'),
        property: 'entitentityType.name',
        search: true
      }, {
        name: this.intl.t('entity.productKey'),
        property: 'entitserialNumber',
        search: true
      }, {
        name: this.intl.t('entity-move.source'),
        property: 'source.name',
        search: true
      }, {
        name: this.intl.t('entity-move.destination'),
        property: 'destination.name',
        search: true
      }, {
        name: this.intl.t('entity-move.type'),
        property: 'type'
      }];
    }),
    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set('columns', this.get('columns'));
    }
  });

  _exports.default = _default;
});