define("iris/components/beta/filters/add/manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2+I4TqAh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"options\"],[[23,0,[\"options\"]]]]],[28,\"hash\",null,[[\"select\",\"search\"],[[28,\"action\",[[23,0,[]],\"select\"],null],[28,\"action\",[[23,0,[]],\"search\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/beta/filters/add/manager/template.hbs"
    }
  });

  _exports.default = _default;
});