define("iris/mixins/cmdb-visualisation/layout", ["exports", "dagrejs"], function (_exports, _dagrejs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    elements: {},
    prevGraph: null,

    layoutGraph() {
      const g = new _dagrejs.default.graphlib.Graph();
      const {
        config
      } = this; // Set an object for the graph label

      const graphOptions = (config === null || config === void 0 ? void 0 : config.layout) || {
        nodeSep: 100,
        edgeSep: 160,
        rankSep: 120,
        marginX: 10,
        marginY: 10,
        rankDir: 'BT',
        weight: 1
      };
      g.setGraph(graphOptions); // Default to assigning a new object as a label for each new edge.

      g.setDefaultEdgeLabel(() => {
        return {};
      });

      for (const el of this.graph.getElements()) {
        this.elements[el.id] = el;
        g.setNode(el.id, {
          label: el.id,
          width: el.attributes.size.width,
          height: el.attributes.size.height
        });
      }

      for (const link of this.graph.getLinks()) {
        g.setEdge(link.attributes.source.id, link.attributes.target.id);
      }

      if (this.prevGraph) {
        _dagrejs.default.layout(g, {
          keepOrder: true,
          nodeOrder: g.nodes().map(n => n.id),
          prevGraph: this.prevGraph
        });
      } else {
        _dagrejs.default.layout(g, {
          keepOrder: true,
          nodeOrder: g.nodes().map(n => n.id)
        });
      }

      this.prevGraph = g;
      g.nodes().forEach(v => {
        const node = g.node(v);
        const elem = this.elements[v];
        this.animateTo(elem, node.x, node.y);
      });
    }

  });

  _exports.default = _default;
});