define("iris/routes/projects/files", ["exports", "jquery", "iris/mixins/protected-route", "iris/mixins/files-after-save", "underscore", "iris/utils/model"], function (_exports, _jquery, _protectedRoute, _filesAfterSave, _underscore, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, _filesAfterSave.default, {
    firstRoute: Ember.computed('routeName', function () {
      let routeName = this.get('routeName');

      if (routeName.indexOf('.files') !== -1) {
        return routeName + '.edit.file-progress';
      } else {
        return routeName + '.edit.overview';
      }
    }),
    actionCreate: ['projects.files.create', 'projects.files'],
    actionFinance: ['projects.files.data.finance', 'projects.files.data.finance.read', 'projects.files.data.finance.write'],
    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set('tabs', this.get('tabs'));
      controller.set('firstRoute', this.get('firstRoute'));
      controller.set('actionCreate', this.get('actionCreate'));
      controller.set('actionFinance', this.get('actionFinance'));
      controller.set('exportOptions', this.get('exportOptions'));
    },
    actions: {
      cancel: function (path, model) {
        if (model.get('attachment')) {
          model.set('attachment', Ember.A());
        }

        model.rollback();
        this.send('transitionTo', path);
      },

      importProposalItem(hash) {
        let {
          model
        } = hash;

        let _this = this;

        const flashMessages = Ember.get(this, 'flashMessages'); // this 4 rows get the backend URL

        let access_token = this.get('session.data.authenticated.id');
        var owner = Ember.getOwner(this);
        var adapter = owner.lookup('adapter:application');
        var url = adapter.buildURL(); // backend call, for import.

        var ajaxData = new FormData();
        ajaxData.append(model.uploadedFile.file.name, model.uploadedFile.file.getNative());

        _jquery.default.ajax(url + '/FileProposals/' + model.proposal.id + '/proposal-item-import?fileId=' + model.proposal.get('file.id') + '&access_token=' + access_token, {
          method: 'POST',
          cache: false,
          contentType: false,
          processData: false,
          data: ajaxData
        }).then(function (response) {
          if (response.status) {
            flashMessages.add({
              message: this.intl.t('project.import.success'),
              title: 'IRIS message',
              icon: 'info'
            });

            _this.send('back', 1);
          } else {
            flashMessages.add({
              message: this.intl.t('project.import.error'),
              title: 'IRIS message',
              icon: 'warning'
            });

            _this.send('back', 1);
          } // FYI : This row REFRESHES the items table from backend #REFRESH_TABLE


          model.proposal.get('items').reload();
        });
      },

      addTicket(hash) {
        let {
          ticket,
          file
        } = hash,
            _this = this;

        ticket.set('file', file);
        (0, _model.validateRecord)(ticket).then(_ref => {
          let {
            model,
            validations
          } = _ref;

          if (validations.get('isValid')) {
            file.get('tickets').then(tickets => {
              // hack burnt-in value
              ticket.set('ticketSourceId', 'SYS_06');

              if (hash['isNew']) {
                //set orderIndex
                let indexes = tickets.mapBy('orderIndex');
                let maxOrderIndex = Math.max.apply(null, indexes.filter(function (x) {
                  return isFinite(x);
                }));
                if (maxOrderIndex < 1) maxOrderIndex = 0;
                ticket.set('orderIndex', maxOrderIndex + 1);
              }

              tickets.addObject(ticket);
            });

            _this.send('back', 2);
          } else {
            model.set('file', null);
            model.setValidated();
          }
        });
      },

      importTicket(hash) {
        let {
          ticket
        } = hash;

        let _this = this;

        const flashMessages = Ember.get(this, 'flashMessages'); // this 4 rows get the backend URL

        let access_token = this.get('session.data.authenticated.id');
        var owner = Ember.getOwner(this);
        var adapter = owner.lookup('adapter:application');
        var url = adapter.buildURL(); // backend call, for import.

        var ajaxData = new FormData();
        ajaxData.append(ticket.uploadedFile.file.name, ticket.uploadedFile.file.getNative());

        _jquery.default.ajax(url + '/Projects/' + ticket.file.id + '/task-import?ticketStateId=' + ticket.state.id + '&access_token=' + access_token, {
          method: 'POST',
          cache: false,
          contentType: false,
          processData: false,
          data: ajaxData
        }).then(function (response) {
          if (response.status) {
            flashMessages.add({
              message: this.intl.t('project.import.success'),
              title: 'IRIS message',
              icon: 'info'
            });

            _this.send('back', 1);
          } else {
            flashMessages.add({
              message: this.intl.t('project.import.error'),
              title: 'IRIS message',
              icon: 'warning'
            });

            _this.send('back', 1);
          } // FYI : This row REFRESHES the tickets table from backend #REFRESH_TABLE


          ticket.file.get('tickets').reload();
        });
      },

      uploadAttachments: function (file) {
        this.set('file', file);
      },
      deleteAttachments: function (deleteAttachments) {
        this.set('deleteAttachments', deleteAttachments);
      },

      addTicketUserAssoc(ticket, ticketUserAssoc) {
        (0, _model.validateRecord)(ticketUserAssoc).then(_ref2 => {
          let {
            model,
            validations
          } = _ref2;

          if (validations.get('isValid')) {
            let copy = ticketUserAssoc.copy();
            copy.then(function (copy) {
              copy.set('ticket', ticket);
              ticketUserAssoc.set('user', null);
              ticketUserAssoc.set('supplier', null);
              ticketUserAssoc.set('plannedExpensesInHour', 0);
            });
          } else {
            model.setValidated();
          }
        });
      },

      addPlanner(file, selected) {
        let users = file.get('filePlannerAssocs').mapBy('user');
        let store = this.store;
        selected.forEach(user => {
          if (!users.contains(user)) {
            store.createRecord('filePlannerAssoc', {
              file: file,
              user: user
            });
          }
        });
        users.forEach(user => {
          if (!selected.contains(user)) {
            file.get('filePlannerAssocs').then(filePlannerAssocs => {
              filePlannerAssocs.forEach(filePlannerAssoc => {
                filePlannerAssoc.get('user').then(u => {
                  if (user.get('id') === u.get('id')) {
                    filePlannerAssoc.set('file', null);
                    filePlannerAssoc.set('user', null);

                    if (filePlannerAssoc.get('id')) {
                      file.get('toDelete.filePlannerAssocs').addObject(filePlannerAssoc);
                    }

                    filePlannerAssoc.deleteRecord();
                  }
                });
              });
            });
          }
        });
      },

      addSupplier(file, selected) {
        let suppliers = file.get('fileSupplierAssocs').mapBy('supplier');
        let store = this.store;
        selected.forEach(supplier => {
          if (!suppliers.contains(supplier)) {
            store.createRecord('fileSupplierAssoc', {
              file: file,
              supplier: supplier
            });
          }
        });
        suppliers.forEach(supplier => {
          if (!selected.contains(supplier)) {
            file.get('fileSupplierAssocs').then(fileSupplierAssocs => {
              fileSupplierAssocs.forEach(fileSupplierAssoc => {
                fileSupplierAssoc.get('supplier').then(u => {
                  if (supplier.get('id') === u.get('id')) {
                    fileSupplierAssoc.set('file', null);
                    fileSupplierAssoc.set('supplier', null);

                    if (fileSupplierAssoc.get('id')) {
                      file.get('toDelete.fileSupplierAssocs').addObject(fileSupplierAssoc);
                    }

                    fileSupplierAssoc.deleteRecord();
                  }
                });
              });
            });
          }
        });
      },

      competencies() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        let [action, file, oldCompetencies, newCompetencies] = args;
        let id = action.get('id');
        let store = this.store;
        let oldIds = oldCompetencies.mapBy('id');
        let newIds = newCompetencies.mapBy('id');

        let toSave = _underscore.default.difference(newIds, oldIds);

        let toDelete = _underscore.default.difference(oldIds, newIds);

        file.get('fileActionCompetencyAssocs').then(fileActionCompetencyAssocs => {
          fileActionCompetencyAssocs.forEach(faca => {
            let actionId = faca.belongsTo('action').id();

            if (actionId === id) {
              let competencyId = faca.belongsTo('competency').id();

              if (toDelete.contains(competencyId)) {
                faca.destroyRecord(true);
              }
            }
          });
          newCompetencies.forEach(competency => {
            if (toSave.contains(competency.get('id'))) {
              let record = store.createRecord('fileActionCompetencyAssoc', {
                file: file,
                competency: competency,
                action: action
              });
              record.save(true);
            }
          });
        });
      },

      createClientPartner() {
        let owner = Ember.getOwner(this);
        let route = owner.lookup('route:crm.partners.new');

        for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        route.set('backArgs', args);
        this.transitionTo('crm.partners.new.overview');
      },

      importProposalItems: function () {
        this.transitionTo(Ember.getOwner(this).lookup('controller:application').currentPath.replace('index', '').concat('import'));
      },

      // Be sure to update 'app/routes/crm/opportunities.js' as well, as the two
      // are the same action.
      async duplicateTask() {
        for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
          args[_key3] = arguments[_key3];
        }

        let [task] = args;
        let copy = await task.copy();
        let state = await task.get('state');
        let numberOfCopies = 0;
        let project = await task.get('file');
        let tasks = await project.get('tickets');

        for (let t of tasks.toArray()) {
          if (t.get('subject').includes('(másolat)')) numberOfCopies++;
        }

        numberOfCopies = numberOfCopies > 0 ? numberOfCopies : '';
        copy.set('subject', `${task.get('subject')} (másolat) ${numberOfCopies}`);
        copy.set('state', state);
      }

    }
  });

  _exports.default = _default;
});