define("iris/components/beta/cmdb/entity-lazy-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    actions: {
      selectFilterValueOption(option) {
        this.set('filter.value', {
          id: option.id,
          name: option.name
        });
      }

    }
  });

  _exports.default = _default;
});