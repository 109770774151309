define("iris/components/aiops/kanban/card/component", ["exports", "iris/lib/aiops/mapper", "iris/lib/modals"], function (_exports, _mapper, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    acceptedCp: 20,
    modals: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    isPending: Ember.computed('card', function () {
      return parseInt(this.card.belongsTo('processStatus').id()) === _mapper.TicketProcessStates.Draft;
    }),
    isUnsuccess: Ember.computed('card', function () {
      return parseInt(this.card.belongsTo('processStatus').id()) === _mapper.TicketProcessStates.Unsuccess;
    }),
    errorReasons: Ember.computed('card.errorReason', function () {
      return this.card.errorReason ? this.card.errorReason.split(',') : [];
    }),
    isP1: Ember.computed('card.priority', function () {
      return this.card.priority === 1;
    }),
    isP2: Ember.computed('card.priority', function () {
      return this.card.priority === 2;
    }),
    indefinite: Ember.computed('card', function () {
      if (!this.card.canChangeData) {
        return false;
      }

      return this.card.ticketTypeCp < this.acceptedCp || this.card.operationServiceLevelCp < this.acceptedCp || this.card.priorityCp < this.acceptedCp || this.card.userGradeCp < this.acceptedCp || this.card.publicSpentTimeCp < this.acceptedCp;
    }),
    customButtons: Ember.computed('card', 'isUnsuccess', function () {
      if (!this.isUnsuccess) {
        return [];
      }

      return [{
        icon: 'fa fa-check',
        text: this.intl.t('aiops.dashboars.accept-planned-values'),
        action: this.get('actions.acceptPlannedValues').bind(this, false),
        type: 'success',
        needClose: true
      }];
    }),
    firstName: Ember.computed('card.resolver', function () {
      if (!this.card.resolver) {
        return '';
      }

      return this.get('card.resolver.name').split(' ').slice(-1).join(' ');
    }),
    actions: {
      togglePopover() {
        this.card.toggleProperty('popover');
        this.togglePopover(this.card);
      },

      async acceptPlannedValues(needTogglePopover) {
        if (needTogglePopover) this.send('togglePopover');
        const categorizedProcessStatus = await this.store.findRecord('aiopsProcessState', _mapper.TicketProcessStates.Categorized);
        this.card.set('processStatus', categorizedProcessStatus);
        await this.card.save(true);
        this.reloadData();
      },

      lockTicket() {
        this.send('togglePopover');
        this.card.set('canChangeData', 0);
        this.card.save(true);
      },

      deleteTicket() {
        this.send('togglePopover');
        this.card.destroyRecord(true);
      },

      async editTicket() {
        this.send('togglePopover');
        let editItem = await this.modals.open(`modals/form-modal`, {
          model: this.card,
          formComponent: 'forms/aiops/ticket',
          customButtons: this.customButtons,
          noClose: false,
          noFooter: false
        }, {
          className: _modals.ModalLayoutClassName['XL']
        });
      }

    }
  });

  _exports.default = _default;
});