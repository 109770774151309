define("iris/templates/components/ellipsis-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H2YXax2W",
    "block": "{\"symbols\":[\"@title\",\"@content\"],\"statements\":[[7,\"span\",true],[8],[1,[23,2,[]],false],[9],[0,\"\\n\\n\"],[5,\"ember-tooltip\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"h3\",true],[10,\"class\",\"h3 popover-title\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"popover-content\"],[8],[0,\"\\n    \"],[1,[23,2,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/ellipsis-cell.hbs"
    }
  });

  _exports.default = _default;
});