define("iris/components/project/ticket/gantt/component", ["exports", "@kockpit/ember-gantt/utils/date-util", "iris/lib/modals"], function (_exports, _dateUtil, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    modals: Ember.inject.service(),
    projectsData: null,
    goDate: null,
    viewStart: null,
    viewEnd: null,
    dayWidth: 20,

    async init() {
      this._super(...arguments);

      const productResults = await this.loadProjectsData();

      const today = _dateUtil.default.getNewDate();

      let start = today;
      const childsStart = productResults.mapBy('plannedStartDate');

      if (childsStart.length) {
        const minStart = childsStart[0];
        start = _dateUtil.default.getNewDate(minStart);
      }

      Ember.set(this, 'viewStart', _dateUtil.default.datePlusDays(start, -30));
      Ember.set(this, 'viewEnd', _dateUtil.default.datePlusDays(today, 120));
      Ember.set(this, 'goDate', today);
    },

    // load initially projects and afterwards only data for newly expanding periods
    loadProjectsData(start, end, expanded) {
      return this.get('store').query('fileProductResult', {
        filter: {
          where: {
            fileId: this.project.id
          },
          order: 'plannedStartDate'
        }
      }).then(prs => {
        Ember.set(this, 'productResults', prs);
        return prs;
      });
    },

    actions: {
      // ToDo - Implement
      zoom(value) {
        let newDayWidth = Math.max(1, parseInt(Ember.get(this, 'dayWidth')) + parseInt(value));
        Ember.set(this, 'dayWidth', newDayWidth);
        Ember.set(this, 'goDate', _dateUtil.default.datePlusDays(this.get('goDate'), 1));
      },

      async addNewProductResult() {
        const record = this.store.createRecord('fileProductResult');
        this.project['productResults'].pushObject(record);
        let newItem = await this.modals.open('modals/form-modal', {
          model: record,
          itemName: 'productResults',
          parentModel: this.project,
          neededFields: ['name', 'plannedStartDate', 'plannedEndDate', 'billable']
        }, {
          className: _modals.ModalLayoutClassName['M']
        });

        if (newItem) {
          this.loadProjectsData(Ember.get(this, 'viewStart'), Ember.get(this, 'viewEnd'), '');
        }
      },

      async addNewTicket(productResult) {
        const record = this.store.createRecord('ticket', {
          productResult,
          plannedStartDate: productResult.plannedStartDate,
          plannedEndDate: productResult.plannedEndDate
        });
        this.project['tickets'].pushObject(record);
        let newItem = await this.modals.open('modals/form-modal', {
          model: record,
          itemName: 'tickets',
          parentModel: this.project,
          formComponent: 'forms/ticket-form'
        }, {
          className: _modals.ModalLayoutClassName['XXL']
        });

        if (newItem) {
          record.setStartEndDates();
          this.loadProjectsData(Ember.get(this, 'viewStart'), Ember.get(this, 'viewEnd'), '');
        }
      },

      async openProductResult(productResult) {
        let editItem = await this.modals.open(`modals/form-modal`, {
          model: productResult,
          itemName: 'productResults',
          parentModel: this.project,
          neededFields: ['name', 'plannedStartDate', 'plannedEndDate', 'billable']
        }, {
          className: _modals.ModalLayoutClassName['M']
        });
        this.loadProjectsData(Ember.get(this, 'viewStart'), Ember.get(this, 'viewEnd'), '');
      },

      async openTicket(ticket) {
        let editItem = await this.modals.open(`modals/form-modal`, {
          model: ticket,
          itemName: 'tickets',
          parentModel: this.project,
          formComponent: 'forms/ticket-form'
        }, {
          className: _modals.ModalLayoutClassName['XXXL']
        });
        this.loadProjectsData(Ember.get(this, 'viewStart'), Ember.get(this, 'viewEnd'), '');
      },

      onDateChange(ticket) {
        ticket.save();
        ticket.setStartEndDates();
      },

      goToDate(ticket) {
        const date = _dateUtil.default.getNewDate(ticket.get('plannedStartDate')); // scroll-to today if date not passed


        this.set('goDate', date);
      },

      /**
       * @param {Date}   newStartDate    new total view start date
       * @param {Date}   newEndDate      new total view end date
       * @param {string} expaned         { 'after' | 'before' }
       * @param {Date}   previousStart   previous start date
       * @param {Date}   previousEnd     previous end date
      */
      loadProjects(newStartDate, newEndDate, expanded, previousStart, previousEnd) {
        /*
        Ez akkor kellene, ha nem töltenénk be az egész projectData-t
        // expand before
        if (expanded === 'before') {
          this.loadProjectsData(newStartDate, previousStart, expanded);
         // expand after
        } else {
          this.loadProjectsData(previousEnd, newEndDate, expanded);
        }
        */
      }

    }
  });

  _exports.default = _default;
});