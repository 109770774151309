define("iris/components/avatar-stack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    names: Ember.computed('users.[]', function () {
      let str = '';
      this.get('users').forEach(user => {
        if (user) {
          str += Ember.get(user, 'longName') + ', ';
        }
      });
      return str.slice(0, -2);
    })
  }); // http://jsbin.com/yuhivavuza/3/edit?html,css,output


  _exports.default = _default;
});