define("iris/models/entity-type", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 150
    })],
    code: (0, _emberCpValidations.validator)('length', {
      max: 50
    }),
    entityTypeCategory: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: Ember.computed.not('model.codeStore')
    })],
    icingaName: (0, _emberCpValidations.validator)('slug')
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    longName: (0, _attr.default)('string'),
    icon: (0, _attr.default)('string'),
    codeStore: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    code: (0, _attr.default)('string'),
    icingaName: (0, _attr.default)('string'),
    generator: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    generateName: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    template: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    parent: (0, _relationships.belongsTo)('entityType', {
      inverse: 'children'
    }),
    entityTypeVisualisation: (0, _relationships.belongsTo)('entityTypeVisualisation'),
    children: (0, _relationships.hasMany)('entityType', {
      inverse: 'parent'
    }),
    entityTypeCategory: (0, _relationships.belongsTo)('entityTypeCategory'),
    entities: (0, _relationships.hasMany)('entity'),
    approvals: (0, _relationships.hasMany)('account'),
    entityAttributes: (0, _relationships.hasMany)('entityAttribute', {
      inverse: 'entityType'
    }),
    entityRelationTemplates: (0, _relationships.hasMany)('entityRelationTemplate', {
      inverse: 'entityType'
    }),
    inverseEntityRelationTemplates: (0, _relationships.hasMany)('entityRelationTemplate', {
      inverse: 'inverseEntityType'
    }),
    tickets: (0, _relationships.hasMany)('Ticket'),
    toDelete: {
      entityAttributes: Ember.A(),
      entityRelationTemplates: Ember.A(),
      entities: Ember.A()
    },
    category: Ember.computed.alias('entityTypeCategory.category')
  });

  _exports.default = _default;
});