define("iris/models/tax-number-pattern", ["exports", "@ember-data/model", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    pattern: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    country: (0, _emberCpValidations.validator)('presence', {
      presence: true
    })
  });

  var _default = _model.default.extend(Validations, {
    pattern: (0, _model.attr)('string'),
    country: (0, _relationships.belongsTo)('country'),
    examples: (0, _relationships.hasMany)('taxNumberPatternExample')
  });

  _exports.default = _default;
});