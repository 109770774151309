define("iris/lib/grid/bulk-actions", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bulkActions = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const bulkActions = fn => {
    return Ember.computed(function () {
      // `actionContext` is the component where the `bulkActions` are defined.
      let actionContext = this;

      if (!fn.call(actionContext)) {
        return null;
      } // Call the config with the host object's context. Without doing this, the
      // dependent key for the `disabled` property wouldn't work.


      return BulkActions.create({
        configClasses: fn.call(actionContext).map(config => {
          var _config$action;

          // Extend an `EmberObject` so that instances can be created inside each
          // `BulkAction` component.
          return Ember.Object.extend({ ...config,
            // Bind the action to action's context, to be able to access services &
            // properties on the object where the row actions are defined.
            //
            // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
            action: (_config$action = config.action) === null || _config$action === void 0 ? void 0 : _config$action.bind(actionContext)
          });
        })
      });
    }).readOnly();
  };

  _exports.bulkActions = bulkActions;
  let BulkActions = (_dec = Ember.computed.reads('actionTask.isRunning'), _dec2 = (0, _emberConcurrency.task)({
    restartable: true
  }), (_class = class BulkActions extends Ember.Object {
    constructor() {
      super(...arguments);

      _defineProperty(this, "selection", null);

      _initializerDefineProperty(this, "isDisabled", _descriptor, this);
    }

    *actionTask(action, params) {
      try {
        // Keep your lovin TypeScript happy...
        let maybeNewSelection = yield action(params);
        let newSelection = Array.isArray(maybeNewSelection) ? maybeNewSelection : null;

        if (newSelection) {
          let objectsToRemove = this.selection.filter( // @ts-ignore
          o => !newSelection.includes(o));
          this.selection.removeObjects(objectsToRemove);
        }

        return maybeNewSelection;
      } catch (err) {
        return err;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "actionTask", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "actionTask"), _class.prototype)), _class));
});