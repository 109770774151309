define("iris/components/entity-attribute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    property: Ember.computed('value', function () {
      let type = this.get('value.entityAttribute.type');
      let res = 'value';

      switch (type) {
        case "belongsTo":
          res = 'related';
          break;

        case "hasMany":
          res = 'relateds';
          break;
      }

      return res;
    }),
    _getValue: function (parent) {
      let parentValue = parent.get('value');
      let parentRelated = parent.belongsTo('related').id();
      let parentRelateds = parent.hasMany('relateds').ids();
      let value = null;

      if (parentRelated !== null) {
        value = 1;
      } else if (typeof parentRelateds !== 'undefined' && parentRelateds.length > 0) {
        value = 2;
      } else {
        value = parentValue;
      }

      return value;
    },
    tooltip: false,
    disabled: false,
    deserializedValue: Ember.computed('value.{value,entityAttribute.type}', function () {
      let entityValueValue = this.value.get('value');

      if (this.value.get('entityAttribute.type') === 'checkbox') {
        return entityValueValue == '1' ? 1 : 0;
      }

      return entityValueValue;
    }),
    valueObserver: Ember.observer('value.value', 'value.related', 'value.relateds', function () {
      const value = this.get('value');

      if (value.get('isDirty') && this.get('entity')) {
        this.get('entity').set('isDirtyTemp', new Date());
      }
    }),
    codeStoresObserver: Ember.computed('value.entityAttribute', function () {
      //value.entityAttribute.related.entities
      if (this.get('value')) {
        this.get('value.entityAttribute').then(entityAttribute => {
          entityAttribute.get('related').then(related => {
            if (related) {
              related.get('entities').then(entities => {
                entities.reload();

                if (!this.get('isDestroying') && !this.get('isDestroyed')) {
                  this.set('codeStores', entities);
                }
              });
            }
          });
        });
      }
    }),
    disabledObserver: Ember.computed('value.parent.value', 'value.parent.related', 'value.parent.relateds.[]', function () {
      if (this.get('value')) {
        this.get('value.parent').then(parent => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          if (parent === null) {
            this.set('disabled', false);
            return false;
          }

          let value = this._getValue(parent);

          if (Ember.isEmpty(value) || value === 0) {
            this.set('value.value', null);
            this.set('disabled', true);
            let name = parent.get('entityAttribute.name');
            this.set('tooltip', this.intl.t('entity-attribute.dependent', {
              dependentAttribute: name
            }));
          } else {
            this.set('disabled', false);
            this.set('tooltip', null);
          }
        });
      }
    }),
    actions: {
      changeCheckboxValue() {
        this.set('value.value', this.value.value == '1' ? 0 : 1);
      },

      changeHasManyValue(selected) {
        this.set('value.relateds', selected);
        this.set('value.isDirtyTemp', new Date());
      }

    }
  });

  _exports.default = _default;
});