define("iris/components/tables/activities/component", ["exports", "iris/lib/filters", "iris/services/query-params", "iris/lib/filters/types", "iris/lib/filters/column", "iris/lib/filters/storage"], function (_exports, _filters, _queryParams, _types, _column, _storage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const activitiesQueryParams = (0, _queryParams.makeFilterQueryParams)(null, {
    order: {
      property: 'date',
      direction: 'desc'
    }
  });
  const queryParamsMixin = (0, _queryParams.makeControllerMixin)(activitiesQueryParams);

  var _default = Ember.Component.extend(queryParamsMixin, {
    tagName: '',
    intl: Ember.inject.service(),
    storage: (0, _storage.useStorage)('memory'),
    columns: (0, _column.columns)(function () {
      return [{
        name: 'mode',
        displayName: this.intl.t('activities.activity'),
        width: _column.ColumnWidth.S
      }, {
        name: 'date',
        displayName: this.intl.t('activities.date'),
        type: _types.AttributeType.Date,
        width: _column.ColumnWidth.L
      }, {
        name: 'tableSchema',
        displayName: this.intl.t('activities.schema'),
        width: _column.ColumnWidth.S
      }, {
        name: 'tableName',
        displayName: this.intl.t('activities.tableName'),
        width: _column.ColumnWidth.S
      }, {
        name: 'id',
        displayName: this.intl.t('activities.id'),
        width: _column.ColumnWidth.S
      }];
    }),
    fetchParams: Ember.computed('columns', 'storage.params', function () {
      return (0, _filters.makeQueryObject)(this.columns, this.storage.params, {});
    })
  });

  _exports.default = _default;
});