define("iris/models/partner-type", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('length', {
      max: 50
    })
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    partner: (0, _relationships.hasMany)('partner'),
    isBuyer: Ember.computed.equal('id', 'SYS_01'),
    isSeller: Ember.computed.equal('id', 'SYS_02')
  });

  _exports.default = _default;
});