define("iris/routes/settings/regions/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Region extends Ember.Route {
    model(params) {
      if (params.id === 'new') {
        return this.store.createRecord('region');
      }

      return this.store.findRecord('region', params.id);
    }

  }

  _exports.default = Region;
});