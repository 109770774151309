define("iris/models/audit-log-sd", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let types = {
    'get': function (prop) {
      return Object.prototype.toString.call(prop);
    },
    'object': '[object Object]',
    'array': '[object Array]',
    'string': '[object String]',
    'boolean': '[object Boolean]',
    'number': '[object Number]'
  };

  var _default = _model.default.extend({
    ticketId: (0, _attr.default)('string'),
    parentId: (0, _attr.default)('string'),
    valueFrom: (0, _attr.default)('string'),
    valueTo: (0, _attr.default)('string'),
    functionType: (0, _attr.default)('string'),
    createDate: (0, _attr.default)('date'),
    user: (0, _relationships.belongsTo)('account'),
    ticket: (0, _relationships.belongsTo)('ticket'),
    valueToObject: Ember.computed('valueTo', function () {
      let value = {};

      if (Ember.isPresent(this.get('valueFrom'))) {
        let valueFrom = JSON.parse(this.get('valueFrom'));
        let valueTo = JSON.parse(this.get('valueTo'));

        for (let key in valueTo) {
          value[key] = {
            from: types.get(valueFrom[key]) === types.object ? valueFrom[key].name : valueFrom[key],
            to: types.get(valueTo[key]) === types.object ? valueTo[key].name : valueTo[key]
          };
        }

        return value;
      } else {
        let valueTo = JSON.parse(this.get('valueTo'));

        for (let key in valueTo) {
          value[key] = {
            from: null,
            to: types.get(valueTo[key]) === types.object ? valueTo[key].name : valueTo[key]
          };
        }

        return value;
      }
    })
  });

  _exports.default = _default;
});