define("iris/routes/aiops/source-data/tickets/operation-service-levels/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AiopsOperationServiceLevelEditRoute extends Ember.Route {
    model(params) {
      if (params.operation_service_level_id == 'new') {
        return this.store.createRecord('aiopsOperationServiceLevel');
      }

      return this.store.findRecord('aiopsOperationServiceLevel', params.operation_service_level_id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = AiopsOperationServiceLevelEditRoute;
});