define("iris/helpers/round", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.round = round;

  function round(params
  /*, hash*/
  ) {
    let value = params[0];
    let param = params[1];
    if (!param) param = 1;
    value = Math.round(value / param);
    return value;
  }

  var _default = Ember.Helper.helper(round);

  _exports.default = _default;
});