define("iris/templates/components/forms/news-feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2Y7rRmWx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-form\",null,[[\"model\",\"proportion\"],[[24,[\"model\"]],\"2-8\"]],{\"statements\":[[4,\"form-group\",null,[[\"property\",\"label\"],[\"subject\",[28,\"t\",[\"models.subject\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"subject\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\"],[\"contents\",[28,\"t\",[\"forms.content\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"trumbowyg-editor\",null,[[\"html\",\"change\"],[[24,[\"model\",\"contents\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"contents\"]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/news-feed.hbs"
    }
  });

  _exports.default = _default;
});