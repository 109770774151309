define("iris/routes/cmdb/entity-types/entities/entity/procedures", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      refresh: 0
    },

    model(params) {
      var store = this.store;
      let modelFor = this.modelFor('cmdb.entity-types.entities.entity');
      let entity = modelFor.entity;

      if (modelFor.entityProcedures.length === 0 || params.refresh) {
        const entityId = entity.get('id');
        return Ember.RSVP.hash({
          entity: store.find('entity', entityId),
          notificationTypes: store.query('entityProcedureNotificationType', {
            filter: {
              order: 'name'
            }
          }),
          solvingGroups: store.query('solvingGroup', {
            filter: {
              order: 'name'
            }
          }),
          entityProcedures: store.query('entityProcedure', {
            filter: {
              where: {
                or: [{
                  entityId
                }, {
                  realEntityId: entityId
                }]
              }
            }
          })
        });
      } else {
        return Ember.RSVP.hash({
          entity: entity,
          notificationTypes: store.query('entityProcedureNotificationType', {
            filter: {
              order: 'name'
            }
          }),
          solvingGroups: store.query('solvingGroup', {
            filter: {
              order: 'name'
            }
          }),
          entityProcedures: modelFor.entityProcedures
        });
      }
    }

  });

  _exports.default = _default;
});