define("iris/models/aiops-user-skill", ["exports", "iris/models/aiops-base", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _aiopsBase, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    user: [(0, _emberCpValidations.validator)('presence', true)],
    operationServiceLevel: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _aiopsBase.default.extend(Validations, {
    skillLevel: (0, _attr.default)('number', {
      formWidget: 'slider',
      min: 0,
      max: 10
    }),
    user: (0, _relationships.belongsTo)('aiopsSolver'),
    operationServiceLevel: (0, _relationships.belongsTo)('aiopsOperationServiceLevel'),
    translations: Ember.A()
  });

  _exports.default = _default;
});