define("iris/components/duty/calendar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CfNNgR7u",
    "block": "{\"symbols\":[],\"statements\":[[5,\"full-calendar\",[],[[\"@locale\",\"@allDayText\",\"@slotDuration\",\"@slotLabelFormat\",\"@timeFormat\",\"@timeZone\",\"@nowIndicator\",\"@height\",\"@header\",\"@editable\",\"@dayRender\",\"@events\",\"@buttonText\",\"@customButtons\",\"@eventLimit\",\"@eventLimitText\",\"@eventLimitClick\"],[[23,0,[\"sessionAccount\",\"account\",\"language\",\"languageCode\"]],[28,\"t\",[\"appcomponenthrm.all-day\"],null],\"01:00:00\",\"HH:mm\",\"HH:mm\",\"local\",true,700,[23,0,[\"header\"]],false,[23,0,[\"dayRender\"]],[23,0,[\"calendarEvents\"]],[23,0,[\"buttonText\"]],[23,0,[\"customButtons\"]],10,[28,\"t\",[\"appcomponenthrm.more\"],null],[28,\"action\",[[23,0,[]],\"eventLimitClick\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/duty/calendar/template.hbs"
    }
  });

  _exports.default = _default;
});