define("iris/templates/components/tables/partner-incoming-invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b388mOJn",
    "block": "{\"symbols\":[\"data\",\"columns\",\"row\"],\"statements\":[[4,\"bs-table-server\",null,[[\"fetchData\",\"columns\",\"hasCheckbox\",\"isShowDeleteBtn\",\"defaultFilterOptions\",\"defaultOrder\"],[[28,\"route-action\",[\"fetchData\",\"incomingInvoice\"],null],[24,[\"columns\"]],[24,[\"fale\"]],false,[24,[\"defaultFilterOptions\"]],\"dateIssued desc\"]],{\"statements\":[[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[23,3,[\"accountNumber\"]],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[0,\"\\n        \"],[1,[28,\"invoice-summary\",null,[[\"row\",\"incoming\"],[[23,3,[]],true]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[28,\"moment-format\",[[23,3,[\"dateIssued\"]],\"L\"],null],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[28,\"moment-format\",[[23,3,[\"dateFulfilled\"]],\"L\"],null],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[28,\"moment-format\",[[23,3,[\"datePaymentDue\"]],\"L\"],null],false],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"text-right\"],[8],[1,[28,\"format-number\",[[23,3,[\"netto\"]]],null],false],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"text-right\"],[8],[1,[28,\"format-number\",[[28,\"add\",[[23,3,[\"netto\"]],[23,3,[\"vat\"]]],null]],null],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[23,3,[\"currency\",\"name\"]],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[0,\"\\n        \"],[5,\"link-to\",[[12,\"class\",\"button\"]],[[\"@route\",\"@model\"],[\"finances.incoming-invoices.copy\",[23,3,[\"id\"]]]],{\"statements\":[[0,\"\\n          \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"copy\"]]],[0,\"\\n          \"],[1,[28,\"t\",[\"buttons.copying\"],null],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/tables/partner-incoming-invoices.hbs"
    }
  });

  _exports.default = _default;
});