define("iris/helpers/is-last-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isLastIndex = isLastIndex;

  function isLastIndex(_ref) {
    let [index, length] = _ref;
    return index === length - 1;
  }

  ;

  var _default = Ember.Helper.helper(isLastIndex);

  _exports.default = _default;
});