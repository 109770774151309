define("iris/components/cron-input/modal/component", ["exports", "cron-validator"], function (_exports, _cronValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let CronInputModal = (_dec = Ember.computed, _dec2 = Ember.computed('minute', 'hour', 'dom', 'month', 'dow'), _dec3 = Ember.computed('cron'), _dec4 = Ember._action, (_class = class CronInputModal extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "minute", '0');

      _defineProperty(this, "hour", '0');

      _defineProperty(this, "dom", '*');

      _defineProperty(this, "month", '*');

      _defineProperty(this, "dow", '*');

      _defineProperty(this, "monthPattern", '[0-9\\*\\-\\,]{13}');

      _defineProperty(this, "domPattern", '[0-9\\*\\-\\,]{15}');

      _defineProperty(this, "dowPatter", '[0-7\\*\\-\\,]{3}');
    }

    didReceiveAttrs() {
      if (this.data.cron) {
        this.set('cron', this.data.cron);
      }
    }

    get hours() {
      const res = [];

      for (let i = 0; i < 24; i++) {
        res.push(i);
      }

      return res;
    }

    get cron() {
      const value = `${this.minute} ${this.hour} ${this.dom} ${this.month} ${this.dow}`;
      return value;
    }

    set cron(value) {
      if (!value) {
        return value;
      }

      let [minute, hour, dom, month, dow] = value.split(/\s+/);
      Ember.set(this, 'minute', minute);
      Ember.set(this, 'hour', hour);
      Ember.set(this, 'dom', dom);
      Ember.set(this, 'month', month);
      Ember.set(this, 'dow', dow);
      return value;
    }

    get cronIsNotValid() {
      return !_cronValidator.default.isValidCron(this.cron);
    }

    onSelectMinute(m) {
      this.set('minute', m * 60);
      this.set('hour', this.hour - m);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "hours", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "hours"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cron", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "cron"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cronIsNotValid", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "cronIsNotValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectMinute", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectMinute"), _class.prototype)), _class));
  _exports.default = CronInputModal;
});