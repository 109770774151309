define("iris/components/countdown-object", ["exports", "countdownjs", "ember-countdown/components/countdown-component"], function (_exports, _countdownjs, _countdownComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _countdownComponent.default.extend({
    countdownText() {
      const newCountdown = (0, _countdownjs.default)(this.get('parsedStartDate'), this.get('parsedEndDate'), this.get('units'), this.get('max'));
      this.set('countdown', newCountdown);
    }

  });

  _exports.default = _default;
});