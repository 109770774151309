define("iris/models/aiops-data-trasfer-mode", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({});

  var _default = _model.default.extend(Validations, {
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    name: (0, _attr.default)('string'),
    translations: Ember.A()
  });

  _exports.default = _default;
});