define("iris/components/button-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    searchField: 'name',
    type: 'default',
    actions: {
      click(option) {
        if (this.disabled) {
          return;
        }

        this.get('onChange')(option);
      }

    }
  });

  _exports.default = _default;
});