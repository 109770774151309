define("iris/models/address", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-copyable", "ember-cp-validations", "iris/mixins/anonymize"], function (_exports, _model, _attr, _relationships, _emberDataCopyable, _emberCpValidations, _anonymize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 50
    })],
    addressType: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    zipCode: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 10
    })],
    town: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 50
    })],
    address: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 50
    })],
    country: [(0, _emberCpValidations.validator)('belongs-to'), (0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _model.default.extend(_emberDataCopyable.default, Validations, _anonymize.default, {
    modelName: 'Address',
    name: (0, _attr.default)('string'),
    country: (0, _relationships.belongsTo)('country'),
    zipCode: (0, _attr.default)('string'),
    town: (0, _attr.default)('string'),
    address: (0, _attr.default)('string'),
    possibility: (0, _attr.default)('boolean', {
      defaultValue: 1
    }),
    defaultWorking: (0, _attr.default)('boolean', {
      defaultValue: 1
    }),
    distance: (0, _attr.default)('number'),
    clientPartner: (0, _relationships.belongsTo)('partner'),
    addressType: (0, _relationships.belongsTo)('addressType'),
    buildings: (0, _relationships.hasMany)('building'),
    isBillingAddress: Ember.computed.bool('addressType.id', 'SYS_02'),
    //burnt-in
    fullAddress: Ember.computed('name', 'zipCode', 'town', 'address', function () {
      return `${this.get('name')} ${this.get('zipCode')} ${this.get('town')} ${this.get('address')}`;
    })
  });

  _exports.default = _default;
});