define("iris/templates/settings/aiops/optimization-parameters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dqyedERa",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\"],[\"aiops-optimization-parameter\",\"settings.aiops.optimization-parameters.edit\",[28,\"array\",[\"name\",\"parameterType\",\"parameterCategory\",\"optimizationItemType\",\"valueFrom\",\"valueTo\",\"relevancyValue\",\"active\"],null]]]],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/aiops/optimization-parameters.hbs"
    }
  });

  _exports.default = _default;
});