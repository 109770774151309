define("iris/models/contact", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "iris/mixins/custom-attributes", "ember-cp-validations", "iris/mixins/anonymize"], function (_exports, _model, _attr, _relationships, _customAttributes, _emberCpValidations, _anonymize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 250
    })],
    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 250
    })],
    phone: (0, _emberCpValidations.validator)('length', {
      max: 150
    }),
    code: (0, _emberCpValidations.validator)('length', {
      max: 250
    }),
    partner: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', true)]
    /*,
    department: [
      validator('belongs-to'),
      validator('presence', true),
    ]*/

  });

  var _default = _model.default.extend(Validations, _customAttributes.default, _anonymize.default, {
    modelName: 'Contact',
    changeTracker: {
      trackHasMany: true,
      enableIsDirty: true,
      auto: true
    },
    name: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    position: (0, _attr.default)('string'),
    code: (0, _attr.default)('string'),
    subcontractor: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isVip: (0, _attr.default)('boolean'),
    inventoryManager: (0, _attr.default)('boolean'),
    period: (0, _attr.default)('string'),
    states: (0, _attr.default)('string'),
    types: (0, _attr.default)('string'),
    syncToI2: (0, _attr.default)('date'),
    i2user: (0, _attr.default)('boolean'),
    defaultReporter: (0, _attr.default)('boolean'),
    privateOperationServices: (0, _attr.default)('boolean'),
    statesAr: Ember.computed('states', function () {
      return this.get('states').split(',');
    }),
    typesAr: Ember.computed('types', function () {
      return this.get('types').split(',');
    }),
    groupsAr: Ember.computed('groups', function () {
      return this.get('groups').split(',');
    }),
    partner: (0, _relationships.belongsTo)('partner'),
    department: (0, _relationships.belongsTo)('department'),
    entities: (0, _relationships.hasMany)('entity', {
      inverse: 'contact'
    }),
    notificationGroups: (0, _relationships.hasMany)('notificationGroup'),
    user: (0, _relationships.belongsTo)('account'),
    allowedPartner: (0, _relationships.belongsTo)('partner'),
    allowedFiles: (0, _relationships.hasMany)('project', {
      inverse: null
    }),
    allowedOperationServiceLevels: (0, _relationships.hasMany)('operationServiceLevel')
  });

  _exports.default = _default;
});