define("iris/components/aria-tab", ["exports", "ember-aria-tabs/components/aria-tab"], function (_exports, _ariaTab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _ariaTab.default;
    }
  });
});