define("iris/components/forms/settings-cmdb-entity-type-relation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    init: function () {
      this._super();

      let entityType = this.get('entityType');
      let model = this.get('model');

      if (model.belongsTo('entityType').id() !== null) {
        if (model.belongsTo('entityType').id() === entityType.id) {
          //normal
          model.set('entityType', model.get('inverseEntityType'));
        } else {
          //inverse
          model.set('inverse', true);
        }
      }
    },
    types: Ember.computed('entityTypes', function () {
      let entityType = this.get('entityType');
      let entityTypes = this.get('entityTypes');
      let entityTypeOptions = Ember.A();
      let normalEntityType = entityTypes.filter(et => {
        return et.get('codeStore') === 0 && et !== entityType && et.id !== null;
      });
      let hardware = normalEntityType.filter(et => {
        return et.belongsTo('entityTypeCategory').id().slice(-9) === '000000001';
      }).sortBy('longName');
      let software = normalEntityType.filter(et => {
        return et.belongsTo('entityTypeCategory').id().slice(-9) === '000000002';
      }).sortBy('longName');
      let business = normalEntityType.filter(et => {
        return et.belongsTo('entityTypeCategory').id().slice(-9) === '000000003';
      }).sortBy('longName');
      let technology = normalEntityType.filter(et => {
        return et.belongsTo('entityTypeCategory').id().slice(-9) === '000000004';
      }).sortBy('longName');
      let license = normalEntityType.filter(et => {
        return et.belongsTo('entityTypeCategory').id().slice(-9) === '000000006';
      });
      entityTypeOptions.pushObject({
        groupName: this.intl.t('cmdb.hardware'),
        options: hardware
      });
      entityTypeOptions.pushObject({
        groupName: this.intl.t('cmdb.software'),
        options: software
      });
      entityTypeOptions.pushObject({
        groupName: this.intl.t('cmdb.relation-visualisation.business'),
        options: business
      });
      entityTypeOptions.pushObject({
        groupName: this.intl.t('cmdb.relation-visualisation.technology'),
        options: technology
      });
      entityTypeOptions.pushObject({
        groupName: this.intl.t('cmdb-visualisation.license'),
        options: license
      });
      return entityTypeOptions;
    })
  });

  _exports.default = _default;
});