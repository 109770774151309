define("iris/templates/settings/document-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pALrWe/1",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\",\"@defaultOrder\",\"@externalRowActions\"],[\"document-type\",\"settings.document-types.edit\",[28,\"array\",[\"name\",\"shortName\",\"extensions\"],null],\"createDate DESC\",[23,0,[\"rowActions\"]]]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"accessModal\"]]],null,{\"statements\":[[4,\"liquid-tether\",null,[[\"to\",\"target\",\"targetModifier\",\"attachment\",\"on-overlay-click\",\"tetherClass\",\"overlayClass\"],[\"modal\",\"document.body\",\"visible\",\"middle center\",[28,\"route-action\",[\"closeAccessModal\"],null],\"modal-dialog modal-md\",\"modal-backdrop\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n\\n      \"],[1,[28,\"modal-header\",null,[[\"title\"],[[28,\"concat\",[[24,[\"selectedDocumentType\",\"name\"]],[28,\"t\",[\"settings.main.its-permission\"],null]],null]]]],false],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n        \"],[1,[28,\"forms/document-type-access\",null,[[\"competencies\",\"documentType\"],[[24,[\"model\",\"competencies\"]],[24,[\"selectedDocumentType\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n        \"],[1,[28,\"iris-button\",null,[[\"text\",\"action\"],[[28,\"t\",[\"buttons.close\"],null],[28,\"route-action\",[\"closeAccessModal\"],null]]]],false],[0,\"\\n\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/document-types.hbs"
    }
  });

  _exports.default = _default;
});