define("iris/routes/reports/inventories/contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    inventoryId: null,
    model: function (params) {
      var store = this.store;
      this.set('inventoryId', params.inventory_id);
      return Ember.RSVP.hash({
        partners: store.query('partner', {
          filter: {
            where: {
              or: [{
                customer: 1
              }, {
                subcontractor: 1
              }]
            },
            order: 'name'
          }
        })
      });
    },
    columns: Ember.computed(function () {
      return [{
        name: this.intl.t('inventory.partner'),
        property: 'partnerId',
        search: true
      }, {
        name: this.intl.t('inventory.contact'),
        property: 'contactId',
        search: true
      }, {
        name: this.intl.t('inventory.status'),
        property: 'status',
        search: true
      }, {
        name: this.intl.t('models.valid'),
        property: 'valid',
        search: true
      }, {
        name: '',
        property: 'icons'
      }];
    }),
    buttonCheckboxActions: Ember.computed(function () {
      return [{
        text: this.intl.t('inventory.resend'),
        action: 'resendInvetory',
        type: 'primary',
        icon: 'fa fa-paper-plane'
      }];
    }),
    exportOptions: {
      ignoreColumn: [0, 5]
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      var defaultFilterOptions = [{
        inventoryId: this.get('inventoryId')
      }];
      controller.set('exportOptions', this.get('exportOptions'));
      controller.set('defaultFilterOptions', defaultFilterOptions);
      controller.set('columns', this.get('columns'));
      controller.set('inventoryId', this.get('inventoryId'));
      controller.set('buttonCheckboxActions', this.get('buttonCheckboxActions'));
      var partnerOptions = Ember.A();
      model.partners.forEach(function (p) {
        partnerOptions.pushObject({
          id: p.get('id'),
          text: p.get('name'),
          condition: {
            partnerId: p.get('id')
          }
        });
      });
      var filters = Ember.A([{
        placeholder: this.intl.t('inventory.partner'),
        type: 'select',
        options: partnerOptions,
        selected: null,
        saveProperty: 'partner'
      }, {
        placeholder: this.intl.t('inventory.status'),
        type: 'select',
        options: Ember.A([{
          text: 'Nincs visszajelezve',
          condition: {
            status: 'no_response'
          }
        }, {
          text: 'Elfogadva',
          condition: {
            status: 'accepted'
          }
        }, {
          text: 'Elutasított',
          condition: {
            status: 'denied'
          }
        }]),
        selected: null,
        saveProperty: 'state'
      }, {
        placeholder: this.intl.t('inventory.valid'),
        type: 'select',
        options: Ember.A([{
          text: 'Nem',
          condition: {
            valid: 0
          }
        }, {
          text: 'Igen',
          condition: {
            valid: 1
          }
        }]),
        selected: null,
        saveProperty: 'valid'
      }]);
      this.controller.set('columnFilterOptions', filters);
    },
    actions: {
      async genPDF(record) {
        const {
          controller
        } = this;
        controller.set('viewPdf', true);
        controller.set('status', this.intl.t('general.pdf-generate'));

        try {
          let response = await this.get('ajax').call('GET', 'inventoryContact', 'pdf-view', record.get('id'));
          controller.set('status', null);
          controller.set('processingIndicator', false);
          controller.set('src', response.$data);
        } catch (e) {
          console.error(e);
          const flashMessages = Ember.get(this, 'flashMessages');
          e.errors.forEach(err => {
            let text = err.detail || err.title;
            flashMessages.danger(text, {
              title: this.intl.t('general.generation.error'),
              icon: 'times',
              sticky: true
            });
          });
          return false;
        }
      },

      closeViewer() {
        this.controller.set('viewPdf', false);
        this.controller.set('processingIndicator', true);
        this.controller.set('src', null);
      },

      async resendInvetory(selectedRecords) {
        const flashMessages = Ember.get(this, 'flashMessages');
        let contacts = [];
        let obj = {};
        let regionId = this.get('sessionAccount.account.regionId');
        selectedRecords.forEach(inventoryContact => {
          obj = {
            id: inventoryContact.get('contact.id'),
            name: inventoryContact.get('contact.name'),
            email: inventoryContact.get('contact.email'),
            code: inventoryContact.get('contact.code')
          };
          contacts.push(obj);
        });
        const inventory = await selectedRecords.firstObject.get('inventory');
        const {
          id: inventoryId,
          forAssignedContact
        } = inventory;
        this.controller.set('spinner', true);
        this.get('ajax').call('POST', 'inventory', '', 'sendInventoryMail', {
          inventoryId,
          contacts: contacts,
          regionId: regionId,
          updateStatus: true,
          forAssignedContact
        }).then(res => {
          this.controller.set('spinner', false);

          if (res.result.code) {
            flashMessages.add({
              message: res.result.message,
              title: 'IRIS message',
              icon: 'warning'
            });
          } else {
            flashMessages.add({
              message: res.result.message,
              title: 'IRIS message',
              icon: 'info'
            });

            let _this = this;

            Ember.run.later({}, function () {
              var controller = _this.controllerFor('reports.inventories.contacts');

              controller.set('refresh', false);
              Ember.run.next(function () {
                controller.set('refresh', true);
              });
            }, 500);
          }
        });
      }

    }
  });

  _exports.default = _default;
});