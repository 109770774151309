define("iris/components/forms/ticket-user-assoc-edit-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qgdhbWOY",
    "block": "{\"symbols\":[\"@model\",\"@parentModel\"],\"statements\":[[5,\"auto/form/field\",[],[[\"@model\",\"@property\",\"@onchange\"],[[23,1,[]],\"internalWork\",[28,\"action\",[[23,0,[]],\"changeInternalWork\"],null]]]],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"internalWork\"]]],null,{\"statements\":[[0,\"  \"],[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"user\",[28,\"t\",[\"ticket-user-assoc.user\"],null]]],{\"statements\":[[0,\"\\n    \"],[5,\"input/select/lazy\",[],[[\"@modelName\",\"@selected\",\"@onchange\",\"@order\",\"@searchProperty\",\"@where\"],[\"account\",[23,1,[\"user\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,1,[\"user\"]]],null]],null],\"longName\",\"longName\",[28,\"hash\",null,[[\"id\",\"portal\",\"enabled\"],[[28,\"hash\",null,[[\"nin\"],[[28,\"map-by\",[\"id\",[23,2,[\"file\",\"fileSolvers\"]]],null]]]],false,true]]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"supplier\",[28,\"t\",[\"ticket-user-assoc.supplier\"],null]]],{\"statements\":[[0,\"\\n    \"],[5,\"input/select/lazy\",[],[[\"@modelName\",\"@selected\",\"@onchange\",\"@where\"],[\"supplier\",[23,1,[\"supplier\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,1,[\"supplier\"]]],null]],null],[28,\"hash\",null,[[\"id\"],[[28,\"hash\",null,[[\"nin\"],[[28,\"map-by\",[\"id\",[23,2,[\"file\",\"fileSuppliers\"]]],null]]]]]]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,1,[]],\"startDate\"]]],[0,\"\\n\"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,1,[]],\"deadline\"]]],[0,\"\\n\"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,1,[]],\"plannedExpensesInHour\"]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/forms/ticket-user-assoc-edit-form/template.hbs"
    }
  });

  _exports.default = _default;
});