define("iris/components/tandm/info/time-line/component", ["exports", "ember-concurrency", "iris/utils/dependencies", "iris/utils/xlsx", "moment"], function (_exports, _emberConcurrency, _dependencies, _xlsx, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let TandmTimeline = (_dec = Ember.computed(), _dec2 = Ember.computed(), _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.alias('tandm.id'), _dec6 = Ember.computed('tandmId', 'from', 'to', 'selectedSourceTypes.[]', 'selectedVerbs.[]'), _dec7 = Ember._action, _dec8 = Ember._action, (_class = class TandmTimeline extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "from", (0, _moment.default)().add(-1, 'M'));

      _defineProperty(this, "to", (0, _moment.default)());

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "tandmId", _descriptor3, this);
    }

    get sourceTypes() {
      return [{
        name: this.intl.t('tandm.ticket'),
        value: 'Ticket'
      }, {
        name: this.intl.t('tandm.task'),
        value: 'ProjectTicket'
      }, {
        name: this.intl.t('tandm.worklog'),
        value: 'WorkLog'
      }, {
        name: this.intl.t('tandm.operationServiceLevel'),
        value: 'TandmOperationServiceLevelAssoc'
      }, {
        name: this.intl.t('tandm.overtimeProposal'),
        value: 'OvertimeProposal'
      }, {
        name: this.intl.t('tandm.calledHoursProposal'),
        value: 'CalledHoursProposal'
      }, {
        name: this.intl.t('tandm.specifiedAmountProposal'),
        value: 'SpecifiedAmountProposal'
      }, {
        name: this.intl.t('tandm.modelName'),
        value: 'Tandm'
      }];
    }

    get verbs() {
      return [{
        name: this.intl.t('tandm.credit'),
        value: 'CREDIT'
      }, {
        name: this.intl.t('tandm.create'),
        value: 'CREATE'
      }, {
        name: this.intl.t('tandm.update'),
        value: 'UPDATE'
      }, {
        name: this.intl.t('tandm.delete'),
        value: 'DELETE'
      }];
    }

    async didInsertElement() {
      this._super(...arguments);

      this.set('selectedSourceTypes', this.sourceTypes);
      this.set('selectedVerbs', this.verbs);
      await this.getTimeLineTask.perform();
    }

    get where() {
      const {
        tandmId,
        from,
        to,
        selectedSourceTypes,
        selectedVerbs
      } = this;
      return {
        tandmId,
        createDate: {
          between: [from, to]
        },
        sourceType: {
          inq: selectedSourceTypes.map(t => t.value)
        },
        verb: {
          inq: selectedVerbs.map(t => t.value)
        }
      };
    }

    *getTimeLineTask() {
      const {
        where
      } = this;
      const timeLine = yield this.api.query('tandmTimeline', {
        filter: {
          where,
          include: ['creator'],
          order: 'createDate'
        }
      });
      this.set('items', timeLine);
    }

    getXlsxData() {
      return this.items.filter(i => i.sourceType === 'WorkLog').map(i => [i.createDate, i.verb, i.get('creator.longName'), i.get('info.ticket.ticketNumber'), i.get('info.ticket.subject'), i.get('info.userGrades.userGrade.name'), i.get('info.userGrades.tandmUserGradeCost.cost')]);
    }

    async getTimeLine() {
      await this.getTimeLineTask.perform();
    }

    async export() {
      let xlsx = await (0, _dependencies.loadXLSXModule)();
      let workSheetName = 'engineer-utility-report';
      const data = this.getXlsxData();
      let workbook = new _xlsx.Workbook(),
          ws = (0, _xlsx.sheet_from_array_of_arrays)(data, xlsx);
      workbook.SheetNames.push(workSheetName);
      workbook.Sheets[workSheetName] = ws;
      let binaryWorkbook = xlsx.write(workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary'
      });
      const fileName = `${(0, _moment.default)(this.from).format('L')}-${(0, _moment.default)(this.to).format('L')}`;
      saveAs(new Blob([(0, _xlsx.s2ab)(binaryWorkbook)], {
        type: 'application/octet-stream'
      }), 'engineer-utility-report.xlsx');
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "sourceTypes", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "sourceTypes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "verbs", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "verbs"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tandmId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "where", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "where"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getTimeLineTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "getTimeLineTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getTimeLine", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "getTimeLine"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "export", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "export"), _class.prototype)), _class));
  _exports.default = TandmTimeline;
});