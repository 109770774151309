define("iris/models/aiops-interface", ["exports", "iris/models/aiops-base", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _aiopsBase, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true)],
    endpoint: [(0, _emberCpValidations.validator)('presence', true)],
    username: [(0, _emberCpValidations.validator)('presence', true)],
    interfaceType: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _aiopsBase.default.extend(Validations, {
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    name: (0, _attr.default)('string'),
    endpoint: (0, _attr.default)('string'),
    username: (0, _attr.default)('string'),
    password: (0, _attr.default)('string'),
    syncDate: (0, _attr.default)('date'),
    interfaceType: (0, _relationships.belongsTo)('aiopsInterfaceType')
  });

  _exports.default = _default;
});