define("iris/components/aiops/user-absence-calendar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wjx/xxhP",
    "block": "{\"symbols\":[],\"statements\":[[5,\"full-calendar\",[],[[\"@locale\",\"@selectable\",\"@select\",\"@allDayText\",\"@slotDuration\",\"@slotLabelFormat\",\"@timeFormat\",\"@timeZone\",\"@nowIndicator\",\"@height\",\"@header\",\"@eventClick\",\"@eventResize\",\"@eventDrop\",\"@editable\",\"@eventRender\",\"@dayRender\",\"@events\",\"@buttonText\",\"@customButtons\",\"@eventLimit\",\"@eventLimitText\",\"@eventLimitClick\"],[[23,0,[\"sessionAccount\",\"account\",\"language\",\"languageCode\"]],true,[28,\"action\",[[23,0,[]],\"select\"],null],[28,\"t\",[\"appcomponenthrm.all-day\"],null],\"01:00:00\",\"HH:mm\",\"HH:mm\",\"local\",true,700,[23,0,[\"header\"]],[28,\"action\",[[23,0,[]],\"eventClick\"],null],[28,\"action\",[[23,0,[]],\"eventResize\"],null],[28,\"action\",[[23,0,[]],\"eventDrop\"],null],true,[23,0,[\"eventRender\"]],[23,0,[\"dayRender\"]],[23,0,[\"calendarEvents\"]],[23,0,[\"buttonText\"]],[23,0,[\"customButtons\"]],3,[28,\"t\",[\"appcomponenthrm.more\"],null],[28,\"action\",[[23,0,[]],\"eventLimitClick\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/aiops/user-absence-calendar/template.hbs"
    }
  });

  _exports.default = _default;
});