define("iris/controllers/organizations/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    pieData: Ember.computed('model.partnerEntity', function () {
      let dataSets = Ember.A();
      let dataSet = {};
      let colors = [{
        color: "#EF6262",
        highlight: "#F17979"
      }, {
        color: "#56C3E7",
        highlight: "#6DCBEA"
      }, {
        color: "#FFCF66",
        highlight: "#FFD77F"
      }, {
        color: "#FFC17F",
        highlight: "#FFD6AA"
      }, {
        color: "#7A98BF",
        highlight: "#8BA5C7"
      }, {
        color: "#BDBDBD",
        highlight: "#C9C9C9"
      }, {
        color: "#BDBDBD",
        highlight: "#C9C9C9"
      }, {
        color: "#BDBDBD",
        highlight: "#C9C9C9"
      }, {
        color: "#BDBDBD",
        highlight: "#C9C9C9"
      }, {
        color: "#BDBDBD",
        highlight: "#C9C9C9"
      }];
      let i = 0;
      this.get('model.partnerEntity').forEach(function (data) {
        dataSet = {
          value: data.get('c'),
          color: colors[i].color,
          highlight: colors[i].highlight,
          label: data.get('partner.name')
        };
        dataSets.push(dataSet);
        i++;
      });
      return dataSets;
    })
  });

  _exports.default = _default;
});