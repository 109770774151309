define("iris/components/tables/entity-relations-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      linkToEntity(entity) {
        this.get('router').transitionTo('cmdb.entity-types.entities.entity.relations', entity.get('entityType.id'), entity.get('id'));
      },

      removeRelation(entity, entityRelation, inverse) {
        if (inverse) {
          entity.get('slaveRelations').then(slaveRelations => {
            // FIX: need recursive function
            if (entityRelation.get('entityRelationType.id') === 'SYS_04') {
              entityRelation.get('masterEntity').then(masterEntity => {
                if (masterEntity.get('entityType.entityTypeCategory.category') === 'software') {
                  masterEntity.destroyRecord(true);
                }
              });
            }

            slaveRelations.removeObject(entityRelation);
            entityRelation.destroyRecord(true);
          });
        } else {
          entity.get('masterRelations').then(masterRelations => {
            // FIX: need recursive function
            if (entityRelation.get('entityRelationType.id') === 'SYS_04') {
              entityRelation.get('slaveEntity').then(slaveEntity => {
                if (slaveEntity.get('entityType.entityTypeCategory.category') === 'software') {
                  slaveEntity.destroyRecord(true);
                }
              });
            }

            masterRelations.removeObject(entityRelation);
            entityRelation.destroyRecord(true);
          });
        }
      }

    }
  });

  _exports.default = _default;
});