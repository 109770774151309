define("iris/helpers/find-get", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
    @param1 array
    @param2 needle
    @param3 property
    @return the value or null
  
  */
  var _default = Ember.Helper.extend({
    compute(params) {
      let array = params[0];
      let needle = params[1];
      array.forEach(elem => {
        if (elem.get('user.id') === needle.get('id')) {
          return true;
        }
      });
    }

  });

  _exports.default = _default;
});