define("iris/models/file-user-grade-cost", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    cost: (0, _emberCpValidations.validator)('presence', true),
    file: (0, _emberCpValidations.validator)('presence', true),
    userGrade: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(Validations, {
    cost: (0, _attr.default)('number'),
    plannedCost: (0, _attr.default)('number'),
    file: (0, _relationships.belongsTo)('project'),
    userGrade: (0, _relationships.belongsTo)('userGrade')
  });

  _exports.default = _default;
});