define("iris/routes/tasks/tasks/work-logs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    uploadDocuments: Ember.inject.service(),
    actions: {
      afterSave(savedWorkLog) {
        // Upload files
        this.get('uploadDocuments').uploadPendingFiles(savedWorkLog);
      }

    }
  });

  _exports.default = _default;
});