define("iris/components/forms/duty/day/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/PJKCSmp",
    "block": "{\"symbols\":[\"dutyDayUserAssoc\",\"@day\"],\"statements\":[[1,[28,\"did-insert\",[[28,\"perform\",[[23,0,[\"setDutyDay\"]]],null]],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"border-2 rounded shadow-md p-3\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"font-bold border-b-2 border-dashed p-3 mb-6\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[[28,\"concat\",[\"duty.\",[23,2,[]]],null]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"input/select/lazy\",[],[[\"@selected\",\"@modelName\",\"@searchProperty\",\"@order\",\"@allowClear\",\"@where\",\"@onchange\",\"@disabled\"],[[23,0,[\"selectedUser\"]],\"account\",\"longName\",\"longName\",true,[28,\"hash\",null,[[\"id\"],[[28,\"hash\",null,[[\"inq\"],[[23,0,[\"accountIds\"]]]]]]]],[28,\"action\",[[23,0,[]],\"addUser\"],null],[23,0,[\"selectUserDiasbled\"]]]]],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"my-6\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"shiftChange.shiftBeginTime\",[23,0,[\"dutyDayUserAssocs\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"font-bold\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-clock-o\"],[8],[9],[0,\"\\n        \"],[1,[28,\"moment-time\",[[23,1,[\"shiftChange\",\"shiftBeginTime\"]]],null],false],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"italic\"],[8],[1,[23,1,[\"account\",\"longName\"]],false],[9],[0,\"\\n        \"],[7,\"i\",false],[12,\"class\",\"fa fa-trash\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"deleteDutyDayUserAssoc\",[23,1,[]]],null]]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/forms/duty/day/template.hbs"
    }
  });

  _exports.default = _default;
});