define("iris/routes/dashboard/ticket/work-logs/new", ["exports", "iris/routes/tasks/plans/work-logs/new"], function (_exports, _new) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _new.default.extend({
    parentRoute: 'dashboard.ticket.work-logs',
    templateName: 'tasks/plans/work-logs/new'
  });

  _exports.default = _default;
});