define("iris/templates/cmdb/entity-types/entities/entity/software/new/software/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gbv40OJQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-tether\",null,[[\"to\",\"target\",\"targetModifier\",\"attachment\",\"on-overlay-click\",\"tetherClass\",\"overlayClass\"],[\"modal\",\"document.body\",\"visible\",\"middle center\",[28,\"route-action\",[\"back\",1],null],\"modal-dialog modal-md\",\"modal-backdrop\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n      \"],[1,[28,\"forms/entity-form-min\",null,[[\"noTab\",\"model\",\"softwareMode\",\"partners\",\"entityTypes\",\"parentEntity\"],[true,[24,[\"model\"]],\"edit\",[24,[\"partners\"]],[24,[\"entityTypes\"]],[24,[\"parentEntity\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n      \"],[1,[28,\"iris-button\",null,[[\"type\",\"text\",\"action\",\"value\"],[\"primary\",[28,\"t\",[\"buttons.save\"],null],[28,\"route-action\",[\"addSoftware\",\"cmdb.entity-types.entities.entity.software.new.software\",\"edit\"],null],[24,[\"model\"]]]]],false],[0,\"\\n\\n      \"],[1,[28,\"buttons/cancel-button\",null,[[\"action\"],[[28,\"route-action\",[\"back\",1],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/entity-types/entities/entity/software/new/software/edit.hbs"
    }
  });

  _exports.default = _default;
});