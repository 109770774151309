define("iris/routes/aiops/dashboards/solvers/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AiopsSolverDashboardRoute extends Ember.Route {
    model(params) {
      return this.store.findRecord('aiopsSolver', params.solver_id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = AiopsSolverDashboardRoute;
});