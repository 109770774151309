define("iris/lib/sys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DataSource = void 0;
  let DataSource;
  _exports.DataSource = DataSource;

  (function (DataSource) {
    DataSource["Manual"] = "SYS_01";
    DataSource["Automata"] = "SYS_02";
    DataSource["ControlledImport"] = "SYS_03";
  })(DataSource || (_exports.DataSource = DataSource = {}));
});