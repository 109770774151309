define("iris/utils/xlsx", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Workbook = Workbook;
  _exports.sheet_from_array_of_arrays = _exports.s2ab = void 0;

  function Workbook() {
    if (!(this instanceof Workbook)) return new Workbook();
    this.SheetNames = [];
    this.Sheets = {};
  }

  const sheet_from_array_of_arrays = (data, xlsx) => {
    var ws = {};
    var range = {
      s: {
        c: 10000000,
        r: 10000000
      },
      e: {
        c: 0,
        r: 0
      }
    };

    for (var R = 0; R != data.length; ++R) {
      for (var C = 0; C != data[R].length; ++C) {
        if (range.s.r > R) range.s.r = R;
        if (range.s.c > C) range.s.c = C;
        if (range.e.r < R) range.e.r = R;
        if (range.e.c < C) range.e.c = C;
        var cell = {
          v: data[R][C]
        };
        if (cell.v == null) continue;
        var cell_ref = xlsx.utils.encode_cell({
          c: C,
          r: R
        });
        if (typeof cell.v === 'number') cell.t = 'n';else if (typeof cell.v === 'boolean') cell.t = 'b';else if (cell.v instanceof Date) {
          cell.t = 'n';
          cell.z = xlsx.SSF._table[14];
          cell.v = convertDateValue(cell.v);
        } else cell.t = 's';
        ws[cell_ref] = cell;
      }
    }

    if (range.s.c < 10000000) ws['!ref'] = xlsx.utils.encode_range(range);
    return ws;
  };

  _exports.sheet_from_array_of_arrays = sheet_from_array_of_arrays;

  const convertDateValue = (v, date1904) => {
    if (date1904) v += 1462;
    var epoch = Date.parse(v);
    return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
  };

  const s2ab = s => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);

    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;

    return buf;
  };

  _exports.s2ab = s2ab;
});