define("iris/components/store/query/component", ["exports", "ember-concurrency", "iris/utils/properties"], function (_exports, _emberConcurrency, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    modelName: null,
    filter: null,
    params: null,

    init() {
      this._super(...arguments);

      this.get('findModel').perform();
    },

    // Using the `{{did-update}}` modifier causes an infinte loop. Possibly
    // related: https://github.com/buschtoens/ember-render-helpers/issues/96
    didUpdateArgs: (0, _properties.observerOnce)('modelName', 'filter', 'params', function () {
      this.get('findModel').perform();
    }),
    findModel: (0, _emberConcurrency.task)(function* () {
      let filter = this.get('filter');
      let params = this.get('params');

      if (Ember.isBlank(filter) && Ember.isBlank(params)) {
        return Ember.A();
      }

      if (filter) {
        filter = {
          filter
        };
      }

      return yield this.store.query(this.get('modelName'), { ...filter,
        ...params
      });
    }).restartable(),
    lastValue: Ember.computed('findModel.last.value', function () {
      let lastValue = this.get('findModel.last.value');
      return (lastValue === null || lastValue === void 0 ? void 0 : lastValue.toArray()) || Ember.A();
    }),
    total: Ember.computed.alias('findModel.last.value.meta.total')
  });

  _exports.default = _default;
});