define("iris/templates/settings/acl/menus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JnpREZGm",
    "block": "{\"symbols\":[\"f\",\"competency\",\"index\"],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@defaultOrder\",\"@defaultFilters\",\"@noDelete\",\"@noCreate\",\"@modalEdit\",\"@formComponent\",\"@groupBy\",\"@limit\",\"@includeOnlyBelongsTo\",\"@neededFields\"],[\"action\",\"settings.acl.menus.edit\",\"parentId, rank\",[28,\"hash\",null,[[\"menu\",\"rank\"],[1,[28,\"hash\",null,[[\"neq\"],[null]]]]]],true,true,true,\"forms/menu\",\"parent\",\"500\",false,[28,\"array\",[\"name\",\"competencies\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"competencies\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"flex flex-wrap gap-2\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"name\",[23,1,[\"row\",\"competencies\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"px-3 py-1 rounded border border-steel-400 text-steel-700 bg-steel-200 dark:text-steel-200 dark:bg-steel-800 dark:border-steel-600\"],[8],[0,\"\\n          \"],[1,[23,2,[\"name\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[2,3]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/acl/menus.hbs"
    }
  });

  _exports.default = _default;
});