define("iris/components/dashboard/pie-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    themeSwitcher: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    classNames: ["no-gutter"],
    colors: ["#FF535D", "#7BC425", "#7AA2CC"],
    discs: ["danger", "success", "info"],
    tickets: Ember.computed("all", "expired", "due", function () {
      let all = this.get("all.length");
      let expired = this.get("expired.length");
      let due = this.get("due.length");
      let inProgress = all - expired - due;
      return [expired, inProgress, due];
    }),
    inProgress: Ember.computed("all", "expired", "due", function () {
      let expired = this.get("expired");
      let due = this.get("due");

      if (due && expired) {
        return this.get("all").reject(ticket => {
          return due.contains(ticket) || expired.contains(ticket);
        });
      } else {
        return [];
      }
    }),
    tooltips: Ember.computed("inProgress", "expired", "due", function () {
      return [this._joinTickets(this.get("expired")), this._joinTickets(this.get("inProgress")), this._joinTickets(this.get("due"))];
    }),

    _joinTickets(array) {
      let res = [];

      if (!array.length) {
        return false;
      }

      array.forEach(ticket => {
        let fileName = "";

        if (ticket.file && ticket.file.hasOwnProperty("name")) {
          fileName = ticket.file.name;
        }

        res.push({
          file: fileName,
          ticketNumber: ticket.ticketNumber,
          subject: ticket.subject
        });
      });

      if (res.length > 3) {
        res = res.slice(0, 3);
      }

      return res;
    },

    datasets: Ember.computed("colors", "tickets", function () {
      let colors = this.get("colors");
      let tickets = this.get("tickets");
      let ds = {
        data: tickets,
        backgroundColor: colors,
        borderWidth: [0, 0, 0],
        hoverBackgroundColor: colors
      };
      return [ds];
    }),
    labels: Ember.computed(function () {
      return [this.intl.t('dashboard.ticket.late'), this.intl.t('dashboard.ticket.inProgress'), this.intl.t('dashboard.ticket.incoming')];
    }),
    data: Ember.computed("datasets", "labels", function () {
      let datasets = this.get("datasets");
      let labels = this.get("labels");
      return {
        labels: labels,
        datasets: datasets
      };
    }),
    options: Ember.computed("all.length", "themeSwitcher.dark", function () {
      let all = this.get("all.length");
      let dark = this.get("themeSwitcher.dark");
      let fontColor = dark ? "#FFF" : "#485761";
      return {
        cutoutPercentage: 65,
        legend: {
          display: false
        },
        elements: {
          center: {
            // the longest text that could appear in the center
            maxText: "100%",
            text: all,
            fontColor: fontColor,
            fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            fontStyle: "normal",
            // fontSize: 12,
            // if a fontSize is NOT specified, we will scale (within the below limits) maxText to take up the maximum space in the center
            // if these are not specified either, we default to 1 and 256
            minFontSize: 1,
            maxFontSize: 256
          }
        }
      };
    })
  });

  _exports.default = _default;
});