define("iris/components/modals/exchange-date-bulk-action/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HTcFY5fo",
    "block": "{\"symbols\":[\"@close\",\"@data\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-content mx-auto\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"file-proposal-item.exchangeRateDate\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n    \"],[5,\"bs-datetimepicker\",[],[[\"@date\"],[[23,0,[\"exchangeDate\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"primary\",[28,\"fn\",[[23,1,[]],[23,0,[\"exchangeDate\"]]],null],[28,\"t\",[\"buttons.save\"],null]]]],[0,\"\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"default\",[28,\"fn\",[[23,1,[]],false],null],[28,\"or\",[[23,2,[\"cancelButtonLabel\"]],[28,\"t\",[\"buttons.close\"],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/modals/exchange-date-bulk-action/template.hbs"
    }
  });

  _exports.default = _default;
});