define("iris/templates/components/forms/document-type-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7GE9CRpV",
    "block": "{\"symbols\":[\"competency\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n    \"],[1,[28,\"search-field\",null,[[\"searchTerm\"],[[24,[\"searchTerm\"]]]]],false],[0,\"\\n    \"],[7,\"table\",true],[10,\"class\",\"table\"],[8],[0,\"\\n      \"],[7,\"thead\",true],[8],[0,\"\\n        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"th\",true],[10,\"scope\",\"col\"],[8],[1,[28,\"t\",[\"incident-form.group\"],null],false],[9],[0,\"\\n          \"],[7,\"th\",true],[10,\"scope\",\"col\"],[8],[1,[28,\"t\",[\"general.reading\"],null],false],[9],[0,\"\\n          \"],[7,\"th\",true],[10,\"scope\",\"col\"],[8],[1,[28,\"t\",[\"general.deletion\"],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"filteredCompetencies\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"document-type-competency\",null,[[\"competency\",\"documentType\"],[[23,1,[]],[24,[\"documentType\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/document-type-access.hbs"
    }
  });

  _exports.default = _default;
});