define("iris/templates/components/forms/report-as-another-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8TeMOrzA",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"bs-form\",null,[[\"model\",\"proportion\"],[[24,[\"model\"]],\"3-9\"]],{\"statements\":[[4,\"form-group\",null,[[\"property\",\"label\"],[\"name\",[28,\"t\",[\"general.name\"],null]]],{\"statements\":[[4,\"iris-power-select\",null,[[\"options\",\"allowClear\",\"selected\",\"onchange\"],[[24,[\"model\",\"users\"]],false,[24,[\"model\",\"newReporter\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"newReporter\"]]],null]],null]]],{\"statements\":[[0,\"      \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\"],[\"email\",[28,\"t\",[\"forms.emailcolonlower\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"form-group-text\"],[8],[0,\"\\n      \"],[1,[24,[\"model\",\"newReporter\",\"email\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/report-as-another-user.hbs"
    }
  });

  _exports.default = _default;
});