define("iris/transitions", ["exports", "liquid-tether"], function (_exports, _liquidTether) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  const options = {
    duration: 0,
    easing: 'easeInOutQuint'
  };

  function _default() {
    this.transition(this.hasClass('vehicles'), // this makes our rule apply when the liquid-if transitions to the
    // true state.
    this.toValue(true), this.use('toLeft', {
      options
    }), // which means we can also apply a reverse rule for transitions to
    // the false state.
    this.reverse('toRight', {
      options
    })); //this.transition(this.toRoute(/^projects./), this.use('toLeft', options));

    this.transition((0, _liquidTether.target)('modal-dialog'), this.use('tether', ['to-up', options]));
    this.transition((0, _liquidTether.target)('modal'), this.use('tether', ['to-up', options]));
    this.transition((0, _liquidTether.target)('confirmation-modal'), this.use('tether', ['to-up', options]));
    this.transition((0, _liquidTether.target)('flyto-dialog'), this.toValue(_ref => {
      let {
        index
      } = _ref;
      return !index;
    }), this.use('tether', ['fade', options]));
    this.transition((0, _liquidTether.target)('flyto-dialog'), this.toValue((_ref2, _ref3) => {
      let {
        index: newIndex
      } = _ref2;
      let {
        index: oldIndex
      } = _ref3;
      return newIndex > oldIndex;
    }), this.use('tether', ['fly-to', options]), this.reverse('tether', ['fly-to', options]));
    this.transition((0, _liquidTether.target)('flyto-dialog'), this.toValue(_ref4 => {
      let {
        index
      } = _ref4;
      return index === 1;
    }), this.use('tether', ['to-right', options], 'fade'));
  }
});