define("iris/templates/components/tables/manage-time-offs-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tzQ03tb/",
    "block": "{\"symbols\":[\"data\",\"columns\",\"row\"],\"statements\":[[4,\"bs-table\",null,[[\"data\",\"columns\",\"currentUser\",\"isShowDeleteBtn\",\"class\",\"paginator\"],[[24,[\"data\"]],[24,[\"columns\"]],[24,[\"currentUser\"]],true,\"mt20\",true]],{\"statements\":[[4,\"each\",[[23,1,[]]],null,{\"statements\":[[4,\"checkbox-row\",null,[[\"record\",\"action\"],[[23,3,[]],[28,\"route-action\",[\"transitionTo\",[28,\"hash\",null,[[\"route\",\"segment\"],[\"settings.manage-time-offs.edit\",[23,3,[\"id\"]]]]]],null]]],{\"statements\":[[0,\"      \"],[7,\"td\",true],[8],[1,[23,3,[\"subject\"]],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[28,\"moment-format\",[[23,3,[\"plannedStartDate\"]],\"YYYY.MM.DD\"],null],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[28,\"moment-format\",[[23,3,[\"plannedEndDate\"]],\"YYYY.MM.DD\"],null],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[23,3,[\"status\",\"name\"]],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[23,3,[\"resolver\",\"longName\"]],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[23,3,[\"createUser\",\"longName\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[3]},null]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/tables/manage-time-offs-table.hbs"
    }
  });

  _exports.default = _default;
});