define("iris/routes/crm/partners/edit", ["exports", "iris/utils/create-record"], function (_exports, _createRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    originalChildren: Ember.A(),

    async model(params) {
      if (params.partner_id == 'new') {
        return Ember.RSVP.hash({
          partner: await (0, _createRecord.createPartner)(this.store)
        });
      }

      const currentUserId = this.get('session.data.authenticated.userId');
      return Ember.RSVP.hash({
        partner: this.store.findRecord('partner', params.partner_id, {
          include: 'children'
        }),
        partnerUserAssocs: this.ajax.call('GET', 'partnerUserAssoc', '', params.partner_id + '/get-actions/')
      });
    },

    afterModel(model) {
      if (model.partner.id) {
        let sessionAccount = this.get('sessionAccount');
        let actions = this.get('sessionAccount.actions');
        let routes = Ember.A();
        model.partnerUserAssocs.filter(a => {
          if (a.system_name) {
            actions.pushObject(a);
          }

          if (a.route) {
            routes.pushObject(a);
          }
        });
        sessionAccount.set('actions', actions);
        sessionAccount.set('routes', routes);
        model.partner.get('children').forEach(child => {
          this.get('originalChildren').pushObject(child);
        });
      }
    },

    setupController: function (controller, model) {
      this._super(controller, model);
      /*ACL*/


      let partner = this.controllerFor('crm.partners.edit').get('model.partner');
      let disableInput = !this.can('read action', {
        obj: partner,
        canActions: ['crm.partners', 'crm.partners.write']
      });
      this.controller.set('disableInput', disableInput);
      let disableACL = !this.can('read action', {
        obj: partner,
        canActions: ['crm.partners.acl', 'crm.partners.acl.write']
      });
      this.controller.set('disableACL', disableACL);
    },
    actions: {
      afterSave(record) {
        this.originalChildren.forEach(oc => {
          if (record.children.filter(c => c.id === oc.id).length < 1) {
            oc.parentId = null;
            oc.save();
          }
        });
        record.children.forEach(c => {
          c.parentId = record.id;
          c.save();
        });
      }

    }
  });

  _exports.default = _default;
});