define("iris/templates/tasks/tasks/work-logs/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xcsRVkoR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-tether\",null,[[\"to\",\"target\",\"targetModifier\",\"attachment\",\"on-overlay-click\",\"tetherClass\",\"overlayClass\"],[\"modal\",\"document.body\",\"visible\",\"middle center\",[28,\"route-action\",[\"back\",[24,[\"model\"]]],null],\"modal-dialog modal-md\",\"modal-backdrop\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n\\n    \"],[1,[28,\"modal-header\",null,[[\"title\"],[[28,\"t\",[\"menus.edit\"],null]]]],false],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n      \"],[1,[28,\"forms/work-log-new\",null,[[\"model\",\"buttonActions\",\"uploadTitle\",\"selectedRecords\",\"deleteConfirmation\",\"edit\",\"addToTicket\"],[[24,[\"model\"]],[24,[\"buttonActions\"]],[24,[\"uploadTitle\"]],[24,[\"selectedRecords\"]],[24,[\"deleteConfirmation\"]],true,true]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[28,\"work-log-editable\",[[24,[\"model\",\"createDate\"]]],null],[28,\"eq\",[[24,[\"sessionAccount\",\"account\",\"id\"]],[24,[\"model\",\"user\",\"id\"]]],null],[28,\"not\",[[24,[\"model\",\"disableEditing\"]]],null]],null]],null,{\"statements\":[[0,\"        \"],[1,[28,\"buttons/save-button\",null,[[\"action\",\"value\"],[[28,\"route-action\",[\"save\",[28,\"hash\",null,[[\"afterSave\",\"redirectTo\"],[[28,\"route-action\",[\"afterSave\"],null],[24,[\"parentRoute\"]]]]]],null],[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[1,[28,\"buttons/cancel-button\",null,[[\"action\",\"value\",\"text\"],[[28,\"route-action\",[\"back\"],null],[24,[\"model\"]],[28,\"t\",[\"buttons.close\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/tasks/tasks/work-logs/edit.hbs"
    }
  });

  _exports.default = _default;
});