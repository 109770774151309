define("iris/templates/components/tables/entity-relations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dfvx1oAG",
    "block": "{\"symbols\":[\"relation\",\"content\"],\"statements\":[[1,[28,\"iris-button\",null,[[\"action\",\"type\",\"text\"],[[28,\"action\",[[23,0,[]],\"openRelationModal\"],null],\"primary\",[28,\"t\",[\"buttons.new-relation\"],null]]]],false],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"relations\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"inverse\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h3\",true],[10,\"class\",\"h3\"],[8],[1,[23,1,[\"entityRelationTypeObject\",\"inverseName\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"h3\",true],[10,\"class\",\"h3\"],[8],[1,[23,1,[\"entityRelationTypeObject\",\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"each\",[[23,1,[\"contents\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"tables/entity-relations-row\",null,[[\"parent\",\"entity\",\"entityRelation\",\"inverse\",\"hasLink\"],[[24,[\"entity\"]],[23,2,[\"entity\"]],[23,2,[\"entityRelation\"]],[23,1,[\"inverse\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/tables/entity-relations.hbs"
    }
  });

  _exports.default = _default;
});