define("iris/components/character-counter-max/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N0yPyY9P",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"h-12 text-right\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"lt\",[[23,0,[\"remaining\"]],0],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"text-red-500\"],[8],[1,[28,\"t\",[\"character-counter-over\"],[[\"remaining\"],[[23,0,[\"overMax\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"i\",true],[8],[1,[28,\"t\",[\"character-counter\"],[[\"remaining\"],[[23,0,[\"remaining\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/character-counter-max/template.hbs"
    }
  });

  _exports.default = _default;
});