define("iris/routes/tasks/tasks", ["exports", "iris/routes/tasks/plans"], function (_exports, _plans) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _plans.default.extend({
    templateName: 'tasks/plans'
  });

  _exports.default = _default;
});