define("iris/components/input/color-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ColorPicker = (_dec = Ember._action, (_class = class ColorPicker extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "colors", ['#FF000D', '#FFA4A8', '#FF7077', '#D5000B', '#A40008', '#FF6F00', '#FFCC00', '#FFCBA4', '#FFAE70', '#FFAD03', '#FA8B01', '#F56A00', '#D95E00', '#A74800', '#DC5713', '#875702', '#5A380B', '#EA698B', '#D55D92', '#C05299', '#AC46A1', '#973AA8', '#822FAF', '#6D23B6', '#6411AD', '#571089', '#003566', '#073B4C', '#114B5F', '#1D4E89', '#1C558E', '#16679A', '#0F80AA', '#277DA1', '#577590', '#106776', '#0F838D', '#0E9FA4', '#0CBABA', '#0899BA', '#04A6C2', '#4895EF', '#4361EE', '#3F37C9', '#3A0CA3', '#00857C', '#00675F', '#18FF00', '#ACFFA4', '#7EFF70', '#11B200', '#0D8900', '#A4B75C', '#647332', '#3E4C22', '#2E401C', '#669900', '#99CC33']);
    }

    changeColor(selected) {
      Ember.set(this, 'value', selected);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "changeColor", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "changeColor"), _class.prototype)), _class));
  _exports.default = ColorPicker;
});