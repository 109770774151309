define("iris/components/forms/operational-billing", ["exports", "iris/lib/modals", "ember-concurrency"], function (_exports, _modals, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modals: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),

    init() {
      this._super();

      this.get('model').syncToContract();
    },

    selected: Ember.computed.mapBy('model.filePlannerAssocs', 'user'),
    selectedSupplier: Ember.computed.mapBy('model.fileSupplierAssocs', 'supplier'),
    currencies: Ember.computed('store', function () {
      return this.get('store').findAll('currency');
    }),
    contract: Ember.computed.alias('model.contract'),
    modelValuePeriod: Ember.computed.alias('contract.valuePeriod'),
    valuePeriods: Ember.computed(function () {
      return [{
        id: 0,
        name: this.intl.t('time.month')
      }, {
        id: 1,
        name: this.intl.t('time.quarterOfYear')
      }, {
        id: 2,
        name: this.intl.t('time.year')
      }];
    }),
    valuePeriod: Ember.computed('modelValuePeriod', 'valuePeriods', {
      get(key) {
        let valuePeriods = this.get('valuePeriods');
        let valuePeriod = this.get('modelValuePeriod');
        return valuePeriods[valuePeriod];
      },

      set(key, value) {
        this.get('model.contract').then(contract => {
          if (Ember.isPresent(contract)) {
            contract.set('valuePeriod', value.id);
          }
        });
        return value;
      }

    }),
    getExchangeRate: (0, _emberConcurrency.task)(function* (currency, date) {
      const code = currency.get('code').trim();
      let exchangeRate = 1;

      if (code !== 'HUF') {
        const response = yield this.get('ajax').call('GET', 'currencies', '', 'get-exchange-rate', {
          currency: code,
          date
        });
        exchangeRate = response.value;
      }

      this.model.set('exchangeRate', exchangeRate);
    }),
    setActualTariff: (0, _emberConcurrency.task)(function* (file) {}),
    actions: {
      async editTariff() {
        await this.modals.open('operational-file/edit/file-tariff', {
          file: this.model,
          disable: this.disableInput
        }, {
          className: _modals.ModalLayoutClassName.M
        });
        this.setActualTariff.perform(this.model);
      },

      async openModal(type) {
        const creditValue = await this.modals.open('forms/tandm/modal', {
          creditValueDay: this.contract.get(`${type}Day`),
          creditValueMonth: this.contract.get(`${type}Month`),
          step: this.get('valuePeriod.id') !== 0 ? 1 : 2
        }, {
          className: _modals.ModalLayoutClassName.M
        });

        if (creditValue) {
          this.contract.set(`${type}Day`, creditValue.day);
          this.contract.set(`${type}Month`, creditValue.month);
        }
      },

      async changeCurrency(currency) {
        this.contract.set('valuePayment', currency);
        this.getExchangeRate.perform(currency, new Date());
      },

      changeContract: function (contract) {
        this.set('model.contract', contract);

        if (contract) {
          let store = this.get('store');
          let file = this.get('model');
          file.get('fileUserGradeCosts').then(GradeCosts => {
            GradeCosts.forEach(GradeCost => {
              file.get('toDelete.GradeCosts').addObject(GradeCost);
            });
            file.set('fileUserGradeCosts', Ember.A());
          });
          contract.get('contractUserGradeCosts').then(userGrades => {
            userGrades.forEach(userGrade => {
              let fugc = store.createRecord('fileUserGradeCost', {
                cost: userGrade.get('cost')
              });
              fugc.set('userGrade', userGrade.get('userGrade'));
              fugc.set('file', file);
              file.get('fileUserGradeCosts').pushObject(fugc);
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});