define("iris/templates/tasks/tasks/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CZ1pS6ex",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-tether\",null,[[\"to\",\"target\",\"targetModifier\",\"attachment\",\"on-overlay-click\",\"tetherClass\",\"overlayClass\"],[\"modal-dialog\",\"document.body\",\"visible\",\"middle center\",[28,\"route-action\",[\"transitionTo\",[24,[\"parentRoute\"]]],null],\"modal-dialog modal-md\",\"modal-backdrop\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n\\n    \"],[1,[28,\"modal-header\",null,[[\"title\"],[[28,\"t\",[\"crm.opportunities.new-task\"],null]]]],false],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n      \"],[1,[28,\"forms/tasks-plan\",null,[[\"projects\",\"model\",\"accounts\",\"delegated\"],[[24,[\"model\",\"projects\"]],[24,[\"model\",\"ticket\"]],[24,[\"model\",\"accounts\"]],[24,[\"delegated\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n      \"],[1,[28,\"iris-button\",null,[[\"type\",\"text\",\"action\",\"value\"],[\"primary\",[28,\"t\",[\"buttons.create\"],null],[28,\"route-action\",[\"save\",[28,\"hash\",null,[[\"redirectTo\",\"afterSave\"],[[24,[\"parentRoute\"]],[28,\"route-action\",[\"afterSave\"],null]]]]],null],[24,[\"model\",\"ticket\"]]]]],false],[0,\"\\n\\n      \"],[1,[28,\"buttons/cancel-button\",null,[[\"action\",\"value\"],[[28,\"route-action\",[\"transitionTo\"],null],[24,[\"parentRoute\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/tasks/tasks/new.hbs"
    }
  });

  _exports.default = _default;
});