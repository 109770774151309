define("iris/templates/finances/incoming-invoices/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x7y42TeV",
    "block": "{\"symbols\":[],\"statements\":[[5,\"forms/incoming-invoice-form\",[],[[\"@model\",\"@invoiceTypes\",\"@vats\",\"@uoms\"],[[23,0,[\"model\",\"invoice\"]],[23,0,[\"model\",\"invoiceTypes\"]],[23,0,[\"model\",\"vats\"]],[23,0,[\"model\",\"uoms\"]]]]],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/finances/incoming-invoices/new.hbs"
    }
  });

  _exports.default = _default;
});