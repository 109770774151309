define("iris/components/forms/ticket-user-assoc/component", ["exports", "iris/lib/filters/types", "iris/lib/filters/column", "iris/lib/modals", "iris/lib/filters/storage"], function (_exports, _types, _column, _modals, _storage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    modals: Ember.inject.service(),
    tagName: '',
    storage: (0, _storage.useStorage)('memory'),

    async afterSave(savedRecord) {
      const file = await savedRecord.get('ticket.file');

      if (savedRecord.belongsTo('user').id()) {
        const user = await savedRecord.get('user');
        file.fileSolvers.pushObject(user);
      } else {
        const supplier = await savedRecord.get('supplier');
        file.fileSuppliers.pushObject(supplier);
      }

      await file.save();
    },

    externalRowActions: Ember.computed(function () {
      return [{
        name: this.intl.t('general.deletion'),
        icon: 'trash',
        style: 'danger',

        async action(record, event) {
          event.stopPropagation();
          event.preventDefault();
          let shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

          if (shouldDelete) {
            try {
              const ticketUserAssocs = await this.store.query('ticketUserAssoc', {
                filter: {
                  where: {
                    ticketId: record.belongsTo('ticket').id(),
                    userId: record.belongsTo('user').id(),
                    partnerId: record.belongsTo('supplier').id()
                  }
                }
              });

              for (const ticketUserAssoc of ticketUserAssocs.toArray()) {
                ticketUserAssoc.set('plannedExpensesInHour', 0);
                await ticketUserAssoc.destroyRecord(true);
              }

              this.flashMessages.info(this.intl.t('general.delete.success'), {
                title: this.intl.t('general.delete.success-title'),
                icon: 'times'
              });
              this.storage.refresh();
            } catch (error) {
              console.log(error);
              this.flashMessages.danger(this.intl.t('general.delete.error'), {
                title: this.intl.t('general.delete.error'),
                icon: 'warning'
              });
            }
          }
        }

      }];
    }),
    externalBulkActions: Ember.computed(function () {
      return [{
        name: this.intl.t('general.assign'),
        icon: 'clock-o',

        async action(selection) {
          let shouldRefresh = await this.modals.open('modals/ticket-user-assoc-bulk-action', {
            ticket: this.parentModel,
            selection: selection
          }, {
            className: _modals.ModalLayoutClassName['M']
          });

          if (shouldRefresh) {
            this.storage.refresh();
          }
        }

      }, {
        name: this.intl.t('general.deletion'),
        icon: 'trash',

        async action(selection) {
          let shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

          if (shouldDelete) {
            try {
              for (const record of selection) {
                const ticketUserAssocs = await this.store.query('ticketUserAssoc', {
                  filter: {
                    where: {
                      ticketId: record.belongsTo('ticket').id(),
                      userId: record.belongsTo('user').id(),
                      partnerId: record.belongsTo('supplier').id()
                    }
                  }
                });

                for (const ticketUserAssoc of ticketUserAssocs.toArray()) {
                  ticketUserAssoc.set('plannedExpensesInHour', 0);
                  await ticketUserAssoc.destroyRecord(true);
                }
              }

              this.flashMessages.info(this.intl.t('general.delete.success'), {
                title: this.intl.t('general.delete.success-title'),
                icon: 'times'
              });
              this.storage.refresh();
            } catch (error) {
              this.flashMessages.danger(this.intl.t('general.delete.error'), {
                title: this.intl.t('general.delete.error'),
                icon: 'warning'
              });
            }
          }
        }

      }];
    }),
    columns: Ember.computed('fileSolvers', function () {
      return {
        user: {
          visibility: _types.AttributeVisibility.Table
        },
        workLogs: {
          type: 'hasMany',
          visibility: _types.AttributeVisibility.Table
        },
        supplier: {
          name: 'photo',
          displayName: '',
          type: 'string',
          width: _column.ColumnWidth.XS,
          visibility: _types.AttributeVisibility.Table
        },
        deadline: {
          visibility: _types.AttributeVisibility.Table
        }
      };
    }),
    actions: {
      async editItem(record, storage) {
        const ticketUserAssocs = await this.store.query('ticketUserAssoc', {
          filter: {
            where: {
              ticketId: record.belongsTo('ticket').id(),
              userId: record.belongsTo('user').id(),
              partnerId: record.belongsTo('supplier').id()
            }
          }
        });
        let model;

        if (ticketUserAssocs.toArray().length) {
          model = ticketUserAssocs.toArray()[0];
        } else {
          const user = await record.get('user');
          const ticket = await record.get('ticket');
          const supplier = await record.get('supplier');
          model = this.store.createRecord('ticketUserAssoc', {
            ticket,
            user,
            supplier,
            startDate: ticket.plannedStartDate,
            deadline: ticket.plannedEndDate,
            internalWork: record.belongsTo('user').id() ? true : false
          });
        }

        let item = await this.modals.open(`modals/form-modal`, {
          model: model,
          itemName: 'ticketUserAssocs',
          parentModel: model.get('ticket'),
          neededFields: this.neededFields,
          formComponent: 'forms/ticket-user-assoc-edit-form',
          afterSave: this.afterSave
        }, {
          className: _modals.ModalLayoutClassName['L']
        });
        storage.refresh();
      }

    }
  });

  _exports.default = _default;
});