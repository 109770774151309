define("iris/components/modal-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['modal-header'],
    actions: {
      close() {
        this.get('parentView').trigger('on-overlay-click');
      }

    }
  });

  _exports.default = _default;
});