define("iris/templates/components/forms/file-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8pwTDBVC",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[[24,[\"formType\"]]],[[\"model\",\"fileStates\",\"fileTypes\",\"fileBusinessTypes\",\"profitCenters\",\"suppliers\",\"serviceAreas\",\"disableInput\",\"disableInputFinance\",\"disableInputPlanning\",\"from\"],[[24,[\"model\"]],[24,[\"fileStates\"]],[24,[\"fileTypes\"]],[24,[\"fileBusinessTypes\"]],[24,[\"profitCenters\"]],[24,[\"suppliers\"]],[24,[\"serviceAreas\"]],[24,[\"disableInput\"]],[24,[\"disableInputFinance\"]],[24,[\"disableInputPlanning\"]],[24,[\"from\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/file-form.hbs"
    }
  });

  _exports.default = _default;
});