define("iris/components/aiops/kanban/matrix/component", ["exports", "iris/utils/properties"], function (_exports, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    ticketData: (0, _properties.computedTask)('data.matrix.matrix', function* () {
      if (!this.data.matrix) {
        return {};
      }

      const ticketIds = [];

      for (const row of this.data.matrix.matrix) {
        ticketIds.push(row.ticketId);
      }

      if (!ticketIds.length) {
        return {};
      }

      const tickets = yield this.store.query('AiopsTicket', {
        filter: {
          where: {
            id: {
              inq: ticketIds
            }
          },
          include: ['operationServiceLevel', 'userGrade', 'customer']
        }
      });
      const res = {};

      for (const ticket of tickets.toArray()) {
        res[ticket.id] = {
          subject: ticket.subject,
          operationServiceLevel: ticket.get('operationServiceLevel.name'),
          userGrade: ticket.get('userGrade.name'),
          customer: ticket.get('customer.name')
        };
      }

      return res;
    }),
    userData: (0, _properties.computedTask)('data.matrix.users', function* () {
      if (!this.data.matrix) {
        return {};
      }

      const userGradeIds = [];

      for (const row of this.data.matrix.users) {
        userGradeIds.push(row.userGradeId);
      }

      if (!userGradeIds.length) {
        return {};
      }

      const userGrades = yield this.store.query('AiopsUserGrade', {
        filter: {
          where: {
            id: {
              inq: userGradeIds
            }
          }
        }
      });
      const res = {};

      for (const userGrade of userGrades.toArray()) {
        res[userGrade.id] = {
          name: userGrade.name
        };
      }

      return res;
    })
  });

  _exports.default = _default;
});