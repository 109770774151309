define("iris/components/hrm/resource-planner/plan-priority-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RHJXkY9Z",
    "block": "{\"symbols\":[\"counter\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"grid grid-cols-\",[23,0,[\"counters\",\"length\"]],\" gap-1\"]]],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"counters\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"rounded-md text-center w-6 text-gray-800\"],[11,\"style\",[29,[\"background-color: \",[23,1,[\"color\"]],\";\"]]],[8],[0,\"\\n      \"],[1,[23,1,[\"count\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/hrm/resource-planner/plan-priority-display/template.hbs"
    }
  });

  _exports.default = _default;
});