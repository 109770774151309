define("iris/lib/aiops/mapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TicketSystemStates = _exports.TicketProcessStates = void 0;
  let TicketSystemStates;
  _exports.TicketSystemStates = TicketSystemStates;

  (function (TicketSystemStates) {
    TicketSystemStates[TicketSystemStates["Open"] = 1] = "Open";
    TicketSystemStates[TicketSystemStates["Pending"] = 2] = "Pending";
    TicketSystemStates[TicketSystemStates["Done"] = 3] = "Done";
  })(TicketSystemStates || (_exports.TicketSystemStates = TicketSystemStates = {}));

  let TicketProcessStates;
  _exports.TicketProcessStates = TicketProcessStates;

  (function (TicketProcessStates) {
    TicketProcessStates[TicketProcessStates["Draft"] = 1] = "Draft";
    TicketProcessStates[TicketProcessStates["Categorized"] = 2] = "Categorized";
    TicketProcessStates[TicketProcessStates["Done"] = 3] = "Done";
    TicketProcessStates[TicketProcessStates["Unsuccess"] = 4] = "Unsuccess";
  })(TicketProcessStates || (_exports.TicketProcessStates = TicketProcessStates = {}));
});