define("iris/utils/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatErrorMessages = void 0;

  const formatErrorMessages = error => {
    var _error$errors$reduce, _error$errors;

    return (_error$errors$reduce = (_error$errors = error.errors) === null || _error$errors === void 0 ? void 0 : _error$errors.reduce((acc, item) => `${acc} ${item === null || item === void 0 ? void 0 : item.detail}.`, '')) !== null && _error$errors$reduce !== void 0 ? _error$errors$reduce : error;
  };

  _exports.formatErrorMessages = formatErrorMessages;
});