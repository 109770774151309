define("iris/components/modals/import-incoming-invoice/modals/import-modal/component", ["exports", "ember-concurrency", "iris/utils/error", "iris/components/modals/import-incoming-invoice/objects"], function (_exports, _emberConcurrency, _error, _objects) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ImportIncomingInvoiceImportModal = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.or('savePartnerTask.isRunning', 'importTask.isRunning'), _dec4 = Ember.computed('currentSlide'), _dec5 = Ember.computed('currentSlide'), _dec6 = Ember.computed('currentSlide', 'additionalDataArr.[]'), _dec7 = Ember.computed('currentInvoice.supplier'), _dec8 = Ember.computed('data.toImport.length', 'currentSlide'), _dec9 = Ember.computed.not('partnerExists'), _dec10 = Ember.computed('partnerExists', 'currentInvoice'), _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = class ImportIncomingInvoiceImportModal extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "additionalDataArr", []);

      _defineProperty(this, "currentSlide", 0);

      _defineProperty(this, "addressesCurrentSlide", 0);

      _defineProperty(this, "partnerCreateMode", false);

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "disabledButtons", _descriptor3, this);

      _initializerDefineProperty(this, "partnerNotExists", _descriptor4, this);
    }

    didReceiveAttrs() {
      const additionalDataArr = this.data.toImport.map(i => _objects.additionalInvoiceData.create(Ember.getOwner(this).ownerInjection()));
      this.set('additionalDataArr', additionalDataArr);
    } // ------------------- COMPUTEDS -------------------


    get currentInvoice() {
      return this.data.toImport[this.currentSlide];
    }

    get currentAdditionalData() {
      return this.additionalDataArr[this.currentSlide];
    }

    get sliderAtTheEnd() {
      return this.currentSlide + 1 === this.additionalDataArr.length;
    }

    get partnerExists() {
      return typeof this.currentInvoice.supplier === 'string';
    }

    get progress() {
      return (this.currentSlide + 1) / this.data.toImport.length;
    }

    get addressObjects() {
      if (this.partnerExists) {
        return [];
      }

      const owner = Ember.getOwner(this);
      return this.currentInvoice.supplier.addresses.map(a => _objects.addressObject.create(owner.ownerInjection(), a));
    }

    *saveInvoiceTask(inputData) {
      const {
        api,
        currentInvoice: invoice,
        data: {
          selectedConnection
        },
        flashMessages,
        intl
      } = this;
      const invoiceWithData = Object.assign({}, invoice, inputData);

      try {
        const result = yield api.collectionAction('incomingInvoice', 'import-nav-invoice', {
          method: 'POST',
          data: {
            invoice: invoiceWithData,
            connectionId: selectedConnection.id
          }
        });
        flashMessages.info(intl.t('general.save.success'), {
          title: intl.t('general.save.success'),
          icon: 'floppy-o'
        });
        return true;
      } catch (error) {
        const msg = (0, _error.formatErrorMessages)(error);
        flashMessages.danger(msg, {
          title: intl.t('general.save.error'),
          sticky: true,
          icon: 'warning'
        });
        return false;
      }
    }

    *importTask() {
      var _additionalData$proje;

      const additionalData = this.currentAdditionalData;
      const valid = yield this.validateData(additionalData);

      if (valid === false) {
        return false;
      }

      return yield this.saveInvoiceTask.perform({
        fileId: (_additionalData$proje = additionalData.project) === null || _additionalData$proje === void 0 ? void 0 : _additionalData$proje.get('id'),
        fileBusinessTypeId: additionalData.fileBusinessType.get('id'),
        subject: additionalData.get('subject')
      });
    }

    *savePartnerTask() {
      const {
        api,
        intl,
        flashMessages
      } = this;

      try {
        const partner = yield api.collectionAction('partner', 'save-with-addresses', {
          method: 'POST',
          data: {
            partner: this.preparePayload()
          }
        });
        flashMessages.info(intl.t('general.save.success'), {
          title: intl.t('general.save.success-title'),
          icon: 'floppy-o'
        });
        this.data.updatePartnerStatus(partner);
        return partner;
      } catch (error) {
        flashMessages.danger(intl.t('general.save.error'), {
          title: intl.t('general.save.error'),
          icon: 'warning'
        });
      }
    }

    preparePayload() {
      const addresses = this.addressObjects.map(addressObject => addressObject.toNative());
      const partner = this.currentInvoice.supplier;
      partner.addresses = addresses;
      return partner;
    } // --------------------- ACTIONS ---------------


    toggleMode() {
      this.set('addressesCurrentSlide', 0);
      this.toggleProperty('partnerCreateMode');
    }

    async import() {
      const result = await this.importTask.perform();

      if (result === false) {
        return;
      }

      Ember.set(this.currentInvoice, 'dbRecord', true); // sets refreshAfterClose true in <Modals::ImportIncomingInvoice>

      this.data.setRefreshAfterClose();

      if (this.sliderAtTheEnd) {
        this.close();
      } else {
        this.skip();
      }
    }

    async validateData(additionalData) {
      let validationResult = await additionalData.validate();
      additionalData.set('didValidate', true);
      return validationResult.validations.isValid;
    }

    skip() {
      this.incrementProperty('currentSlide');
    }

    async savePartner() {
      for (const [index, address] of this.addressObjects.entries()) {
        let validationResult = await address.validate();
        address.set('didValidate', true);

        if (!validationResult.validations.isValid) {
          this.set('addressesCurrentSlide', index);
          return;
        }
      }

      const partner = await this.savePartnerTask.perform();
      this.toggleMode();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "disabledButtons", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "currentInvoice", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "currentInvoice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "currentAdditionalData", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "currentAdditionalData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sliderAtTheEnd", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "sliderAtTheEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "partnerExists", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "partnerExists"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "progress", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "progress"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "partnerNotExists", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addressObjects", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "addressObjects"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveInvoiceTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "saveInvoiceTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "importTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "importTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "savePartnerTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "savePartnerTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleMode", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "import", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "import"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateData", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "validateData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "skip", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "skip"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "savePartner", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "savePartner"), _class.prototype)), _class));
  _exports.default = ImportIncomingInvoiceImportModal;
});