define("iris/helpers/format-cell-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatCellValue = formatCellValue;

  function formatCellValue(_ref, _ref2) {
    let [value] = _ref;
    let {
      formatter
    } = _ref2;

    if (formatter) {
      return Ember.String.htmlSafe(formatter(value));
    }

    return value;
  }

  var _default = Ember.Helper.helper(formatCellValue);

  _exports.default = _default;
});