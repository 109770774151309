define("iris/components/forms/file-proposal-item/component", ["exports", "ember-concurrency", "iris/utils/finances", "iris/lib/modals"], function (_exports, _emberConcurrency, _finances, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FileProposalItem = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class FileProposalItem extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "modals", _descriptor2, this);

      _initializerDefineProperty(this, "sessionAccount", _descriptor3, this);
    }

    didReceiveAttrs() {
      this.setDefaults.perform();
      this.setDefaultCurrency.perform();
    } // ----------------- COMPUTEDS ------------
    // ----------------- EC TASKS --------------


    *setDefaultCurrency() {
      const currency = yield (0, _finances.getSessionDefaultCurrency)(this.sessionAccount);
      this.set('defaultCurrency', currency);

      if (this.model.get('isNew')) {
        this.model.set('currency', currency);
      }
    }

    *setDefaults() {
      if (this.model.get('isNew')) {
        const vat = yield (0, _finances.getDefaultVat)(this.store);
        const uom = yield (0, _finances.getDefaulUOM)(this.store);
        this.model.set('vat', vat);
        this.model.set('uom', uom);
      }
    }
    /**
     * Gets the exchange rate for a given currency at a given date
     * It sets the result on the proposal item, then calls recalculate on it
     * to set the new defaultAmount (gross value in region's currency)
     *
     * @param {*} currency ED record
     * @param {*} date Date instance
     * @memberof FileProposalItem
     */


    *getExchangeRate(currency, date) {
      const code = currency.get('code').trim();
      let exchangeRate = 1;

      if (code !== 'HUF') {
        const response = yield this.api.collectionAction('currencies', 'get-exchange-rate', {
          data: {
            currency: code,
            date
          }
        });
        exchangeRate = response.value;
      }

      this.model.set('exchangeRate', exchangeRate);
      this.model.set('exchangeRateContract', exchangeRate);
      yield this.model.recalculate(this.defaultCurrency.round);
    } // ------------------ ACTIONS -------------------


    async changeExchangeRate(vat) {
      const exchangeRate = await this.modals.open('modals/proposal-item-change-exchange-rate', {
        exchangeRate: this.model.get('exchangeRate')
      }, {
        className: _modals.ModalLayoutClassName.S
      });

      if (exchangeRate) {
        this.model.set('exchangeRate', exchangeRate);
        this.recalculate();
      }
    }

    async changeExchangeRateContract(vat) {
      const exchangeRate = await this.modals.open('modals/proposal-item-change-exchange-rate', {
        exchangeRate: this.model.get('exchangeRateContract')
      }, {
        className: _modals.ModalLayoutClassName.S
      });

      if (exchangeRate) {
        this.model.set('exchangeRateContract', exchangeRate);
      }
    }

    changeVat(vat) {
      this.model.set('vat', vat);
      this.recalculate();
    }

    async changeCurrency(currency) {
      this.model.set('currency', currency);
      await this.getExchangeRate.perform(currency, this.model.get('exchangeRateDate'));
    }

    async updateDateExchangeRate(date) {
      // convert moment.js object to date
      date = date.toDate();
      this.model.set('exchangeRateDate', date);
      const currency = this.model.get('currency');
      await this.getExchangeRate.perform(currency, date);
    }

    async recalculate() {
      await this.model.recalculate(this.defaultCurrency.round);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sessionAccount", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setDefaultCurrency", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "setDefaultCurrency"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDefaults", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "setDefaults"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getExchangeRate", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "getExchangeRate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeExchangeRate", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "changeExchangeRate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeExchangeRateContract", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "changeExchangeRateContract"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeVat", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "changeVat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeCurrency", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "changeCurrency"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateDateExchangeRate", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "updateDateExchangeRate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "recalculate", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "recalculate"), _class.prototype)), _class));
  _exports.default = FileProposalItem;
});