define("iris/models/document-type", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true)],
    shortName: [(0, _emberCpValidations.validator)('presence', true)],
    extensions: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _model.default.extend(Validations, {
    sessionAccount: Ember.inject.service(),
    name: (0, _attr.default)('string'),
    shortName: (0, _attr.default)('string'),
    extensions: (0, _attr.default)('string'),
    competencies: (0, _relationships.hasMany)('competency'),
    documentTypeCompetencies: (0, _relationships.hasMany)('documentTypeCompetency'),
    accountCompetencies: Ember.computed.alias('sessionAccount.account.competencies'),
    filteredDocumentTypeCompetencies: Ember.computed('accountCompetencies.[]', 'documentTypeCompetencies.[]', function () {
      const accountCompetenciesIds = this.get('accountCompetencies').toArray().mapBy('id');
      return this.get('documentTypeCompetencies').filter(dtc => {
        return accountCompetenciesIds.includes(dtc.belongsTo('competency').id());
      });
    }),
    readable: Ember.computed('filteredDocumentTypeCompetencies.@each.read', function () {
      return this.get('filteredDocumentTypeCompetencies').any(fdtc => fdtc.get('read'));
    }),
    deleteable: Ember.computed('filteredDocumentTypeCompetencies.@each.delete', function () {
      return this.get('filteredDocumentTypeCompetencies').any(fdtc => fdtc.get('delete'));
    }),
    builtIn: Ember.computed('id', function () {
      const id = this.get('id');
      return id && id.includes('SYS');
    })
  });

  _exports.default = _default;
});