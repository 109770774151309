define("iris/components/change-requests/edit/tasks/affected-entities/data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SEeG6yp7",
    "block": "{\"symbols\":[\"@model\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"model\",\"addEntity\",\"removeEntity\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"addEntity\"],null],[28,\"action\",[[23,0,[]],\"removeEntity\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/change-requests/edit/tasks/affected-entities/data/template.hbs"
    }
  });

  _exports.default = _default;
});