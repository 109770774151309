define("iris/components/action-competency-frame/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    A,
    isNone
  } = Ember;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    computedType: computed('type', function () {
      return this.type === 'operational-file' ? 'file' : this.type;
    }),
    modelActions: computed('type', function () {
      return this.store.query('action', {
        filter: {
          where: {
            and: [{
              scope: {
                eq: this.type
              }
            }, {
              forceDefault: 0
            }]
          },
          order: "rank"
        }
      });
    }),
    competencies: computed('type', function () {
      return this.store.query('competency', {
        filter: {
          where: {
            builtIn: {
              lt: 2
            }
          }
        }
      });
    }),
    notDefaultActions: computed.filterBy('modelActions', 'forceDefault', 0),
    sections: computed('notDefaultActions', {
      get() {
        let res = A();

        let _this = this;

        this.get('notDefaultActions').forEach(action => {
          action.get('parent').then(parent => {
            if (isNone(parent)) {
              res.pushObject(action);

              _this.set('sections', res);
            }
          });
        });
        return res;
      },

      set(key, value) {
        return value;
      }

    }),
    flattend: computed('sections.@each.children.length', {
      get() {
        let res = A();

        let _this = this;

        this.get('sections').forEach(section => {
          if (section.get('children.length')) {
            section.get('children').then(children => {
              let sectionArray = A();
              children.forEach(child => {
                if (!child.get('forceDefault')) {
                  sectionArray.pushObject(child);
                }
              });
              let obj = {
                name: section.get('name'),
                items: sectionArray
              };
              res.pushObject(obj);

              _this.set('flattend', res);
            });
          }
        });
        return res;
      },

      set(key, value) {
        return value;
      }

    }),
    actions: {
      save(action, c) {
        action.get('competencies').then(competencies => {
          competencies.setObjects(c);
          action.save();
        });
      }

    }
  });

  _exports.default = _default;
});