define("iris/lib/modals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ModalLayoutClassName = void 0;
  let ModalLayoutClassName;
  _exports.ModalLayoutClassName = ModalLayoutClassName;

  (function (ModalLayoutClassName) {
    ModalLayoutClassName["Grow"] = "epm-modal--grow";
    ModalLayoutClassName["Confirmation"] = "epm-modal--confirmation";
    ModalLayoutClassName["Form"] = "epm-modal--form";
    ModalLayoutClassName["XXS"] = "epm-modal--xxs";
    ModalLayoutClassName["XS"] = "epm-modal--xs";
    ModalLayoutClassName["S"] = "epm-modal--s";
    ModalLayoutClassName["M"] = "epm-modal--m";
    ModalLayoutClassName["L"] = "epm-modal--l";
    ModalLayoutClassName["XL"] = "epm-modal--xl";
    ModalLayoutClassName["XXL"] = "epm-modal--xxl";
    ModalLayoutClassName["XXXL"] = "epm-modal--xxxl";
  })(ModalLayoutClassName || (_exports.ModalLayoutClassName = ModalLayoutClassName = {}));
});