define("iris/transitions/fade-left", ["exports", "iris/transitions/fade-direction"], function (_exports, _fadeDirection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return _fadeDirection.default.call(this, 'x', -1, opts, opts.offset);
  }
});