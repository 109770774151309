define("iris/routes/cmdb/entity-types/entities/entity/relations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let store = this.store;
      let modelFor = this.modelFor('cmdb.entity-types.entities.entity');
      let entity = modelFor.entity;
      let entityTypes = modelFor.entityTypes;
      return Ember.RSVP.hash({
        entity: entity,
        entityTypes: entityTypes
      });
    }

  });

  _exports.default = _default;
});