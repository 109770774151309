define("iris/helpers/read-path", ["exports", "ember-bootstrap/helpers/read-path"], function (_exports, _readPath) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _readPath.default;
    }
  });
  Object.defineProperty(_exports, "readPath", {
    enumerable: true,
    get: function () {
      return _readPath.readPath;
    }
  });
});