define("iris/components/tables/tasks-work-logs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    canAddWorklog: Ember.computed('delegated', 'ticketState', function () {
      return !this.delegated && this.ticketState.get('id') !== 'SYS_10';
    }),
    columns: Ember.computed(function () {
      return [{
        name: this.intl.t('work-log.beginDate'),
        property: 'startsAt'
      }, {
        name: this.intl.t('work-log.solver'),
        property: 'user.longName'
      }, {
        name: this.intl.t('address.fullAddress'),
        property: 'location.fullAddress'
      }, {
        name: this.intl.t('work-log.comment'),
        property: 'comment'
      }, {
        name: this.intl.t('work-log.publicSpentTime'),
        property: 'publicSpentTime'
      }, {
        name: this.intl.t('work-log.spentTime'),
        property: 'spentTime'
      }];
    })
  });

  _exports.default = _default;
});