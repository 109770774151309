define("iris/routes/cmdb/entity-types/entities/entity/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      var store = this.store;
      let modelFor = this.modelFor('cmdb.entity-types.entities.entity');
      let entity = modelFor.entity;
      return Ember.RSVP.hash({
        suppliers: store.findAll('supplier'),
        entity: entity,
        inventoryOrganizations: store.findAll('entityInventoryOrganization')
      });
    }

  });

  _exports.default = _default;
});