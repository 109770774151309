define("iris/components/tables/incoming-invoice/component", ["exports", "iris/lib/filters", "iris/lib/filters/column", "iris/lib/filters/types", "iris/lib/grid", "iris/lib/modals", "iris/lib/table"], function (_exports, _filters, _column, _types, _grid, _modals, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TableIncomingIvoice = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = (0, _grid.bulkActions)(function () {
    return [{
      name: this.intl.t('finances.payments'),
      icon: 'money',

      async action(selection) {
        const payments = await this.createPayments(selection);
        const modalResult = await this.modals.open('modals/invoice-payment-modal', {
          payments
        }, {
          className: _modals.ModalLayoutClassName.Grow
        });

        if (modalResult !== null && modalResult !== void 0 && modalResult.shouldRefreshRecords) {
          this.storage.refresh();
        } else {
          payments.invoke('destroyRecord');
        }
      }

    }];
  }), _dec6 = (0, _table.rowActions)(function () {
    const {
      intl
    } = this;
    return [{
      name: intl.t('buttons.delete'),
      icon: 'trash',
      style: 'danger',

      async action(incomingIvoice) {
        let shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

        if (shouldDelete) {
          try {
            await incomingIvoice.destroyRecord(true);
            this.flashMessages.info(intl.t('general.delete.success-title'), {
              title: intl.t('general.delete.success'),
              icon: 'times'
            });
            this.storage.refresh();
          } catch (error) {
            this.flashMessages.danger(intl.t('general.delete.error'));
          }
        }
      }

    }, {
      name: intl.t('buttons.copying'),
      icon: 'copy',
      route: 'finances.incoming-invoices.copy',
      routeParams: Ember.computed.alias('row.id')
    }];
  }), _dec7 = (0, _column.columns)(function () {
    return [{
      name: 'datePaid',
      displayName: this.intl.t('finances.paid'),
      type: _types.AttributeType.Date,
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'accountNumber',
      displayName: this.intl.t('finances.invoice-number'),
      width: _column.ColumnWidth.SM,
      fixed: true,
      route: 'finances.incoming-invoices.edit',
      routeParams: (0, _table.rowValues)('id')
    }, {
      name: 'partnerId',
      displayName: this.intl.t('models.clientPartner'),
      valuePath: 'partner.name',
      type: _types.AttributeType.BelongsTo,
      width: _column.ColumnWidth.M
    }, {
      name: 'invoiceOwnerId',
      displayName: this.intl.t('incoming-invoice.invoiceOwner'),
      optionsResourceName: 'partner',
      valuePath: 'invoiceOwner.name',
      type: _types.AttributeType.BelongsTo,
      width: _column.ColumnWidth.M
    }, {
      name: 'subject',
      displayName: this.intl.t('models.subject'),
      valuePath: 'subject',
      width: _column.ColumnWidth.L
    }, {
      name: 'fileId',
      displayName: this.intl.t('models.fileNumber'),
      valuePath: 'project.fileNumber',
      optionsResourceName: 'project',
      width: _column.ColumnWidth.S,
      sortable: false,
      visibility: _types.AttributeVisibility.Table
    }, {
      name: 'itemTypes',
      displayName: this.intl.t('finances.device-service'),
      valuePath: 'itemTypes',
      width: _column.ColumnWidth.M,
      sortable: false,
      visibility: _types.AttributeVisibility.Table
    }, {
      name: 'needsAction',
      displayName: this.intl.t('incoming-invoice.needsAction'),
      type: _types.AttributeType.Boolean,
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'internalAccounting',
      displayName: this.intl.t('finances.own-re-invoicing'),
      valuePath: 'internalAccounting',
      width: _column.ColumnWidth.S,
      type: _types.AttributeType.Boolean,
      exportable: false,

      formatLoopBackValue(value, filter, defaultFormatter) {
        if (filter.filterType === _types.FilterType.Boolean) {
          return value ? 1 : 0;
        }

        return defaultFormatter(value);
      }

    }, {
      name: 'dateIssued',
      displayName: this.intl.t('forms.dated'),
      valuePath: 'dateIssued',
      type: _types.AttributeType.Date,
      width: _column.ColumnWidth.S
    }, {
      name: 'dateFulfilled',
      displayName: this.intl.t('finances.vat-paid'),
      valuePath: 'dateFulfilled',
      type: _types.AttributeType.Date,
      width: _column.ColumnWidth.S
    }, {
      name: 'datePaymentDue',
      displayName: this.intl.t('forms.payment-deadline'),
      valuePath: 'datePaymentDue',
      type: _types.AttributeType.Date,
      width: _column.ColumnWidth.S
    }, {
      name: 'netto',
      displayName: this.intl.t('components-main.net-interpol'),
      valuePath: 'netto',
      type: _types.AttributeType.Number,
      width: _column.ColumnWidth.S
    }, {
      name: 'gross',
      displayName: this.intl.t('components-main.gross-interpol'),
      valuePath: 'gross',
      type: _types.AttributeType.Number,
      width: _column.ColumnWidth.S,
      visibility: _types.AttributeVisibility.Table,
      sortable: false
    }, {
      name: 'navNetto',
      displayName: this.intl.t('incoming-invoice.navNetto'),
      valuePath: 'navNetto',
      type: _types.AttributeType.Number,
      width: _column.ColumnWidth.S
    }, {
      name: 'navBrutto',
      displayName: this.intl.t('incoming-invoice.navBrutto'),
      valuePath: 'navBrutto',
      type: _types.AttributeType.Number,
      width: _column.ColumnWidth.S
    }, {
      name: 'navDifference',
      displayName: this.intl.t('incoming-invoice.navDifference'),
      valuePath: 'navDifference',
      type: _types.AttributeType.Number,
      width: _column.ColumnWidth.S
    }, {
      name: 'currencyId',
      displayName: this.intl.t('forms.secondcurrency'),
      valuePath: 'currency.name',
      type: _types.AttributeType.BelongsTo,
      width: _column.ColumnWidth.S,
      sortable: false
    }, {
      name: 'balance',
      displayName: this.intl.t('finances.balance'),
      sortable: false,
      visibility: _types.AttributeVisibility.Table,
      width: _column.ColumnWidth.S,
      formatExportValue: (value, row, columns) => {
        return row.paidBalance - row.gross;
      }
    }, {
      name: 'dataSourceId',
      displayName: this.intl.t('finances.incoming-invoices.data-source'),
      valuePath: 'dataSource.name',
      type: _types.AttributeType.BelongsTo,
      width: _column.ColumnWidth.M
    }, {
      name: 'payments',
      displayName: this.intl.t('finances.payment'),
      type: _types.AttributeType.HasMany,
      sortable: false,
      visibility: _types.AttributeVisibility.Table,
      width: _column.ColumnWidth.S,
      formatExportValue: (value, row, columns) => {
        if (row.keepTrackPayments && row.datePaid) {
          return columns.find(c => c.name === 'datePaid').formatDisplayValue(row.datePaid);
        }
      }
    }];
  }), _dec8 = Ember.computed(), _dec9 = Ember.computed('columns', 'storage.params'), _dec10 = Ember._action, (_class = class TableIncomingIvoice extends Ember.Component {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "modals", _descriptor3, this);

      _initializerDefineProperty(this, "sessionAccount", _descriptor4, this);

      _initializerDefineProperty(this, "bulkActions", _descriptor5, this);

      _initializerDefineProperty(this, "rowActions", _descriptor6, this);

      _initializerDefineProperty(this, "columns", _descriptor7, this);
    }

    async createPayments(selection) {
      let m = [];
      const store = this.store;
      let payValue = 0;
      const account = this.sessionAccount.get('account');
      const now = new Date();
      const transfer = await store.find('paymentMethod', 'SYS_04');
      selection.forEach(invoice => {
        const gross = invoice.get('navBrutto') || invoice.get('vat') + invoice.get('netto');
        payValue = gross - invoice.get('paidBalance');
        m.push(store.createRecord('incomingInvoicePayment', {
          invoice: invoice,
          payValue: payValue,
          user: account,
          paymentMethod: transfer,
          datePayment: now,
          dateEntered: now
        }));
      });
      return m;
    }

    get predefinedViews() {
      return [{
        name: this.intl.t('finances.unpaid'),
        filters: [{
          name: 'datePaid',
          type: _types.FilterType.Null
        }]
      }];
    }

    get fetchParams() {
      let {
        filter
      } = (0, _filters.makeQueryObject)(this.columns, this.storage.params);
      return {
        filter: { ...filter,
          include: [{
            relation: 'partner',
            scope: {
              fields: ['name']
            }
          }, {
            relation: 'invoiceOwner',
            scope: {
              fields: ['name']
            }
          }, {
            relation: 'currency',
            scope: {
              fields: ['name']
            }
          }, {
            relation: 'paymentMethod',
            scope: {
              fields: ['name']
            }
          }, {
            relation: 'project',
            scope: {
              fields: ['name', 'fileNumber']
            }
          }, {
            relation: 'invoiceType',
            scope: {
              fields: ['name', 'code']
            }
          }, 'payments', 'items']
        }
      };
    }

    async openImportModal() {
      const {
        intl
      } = this;
      const refresh = await this.modals.open('modals/import-incoming-invoice', {
        title: intl.t('import-incoming-invoice.import-invoice'),
        subTitle: intl.t('import-incoming-invoice.import-invoice-subtitle')
      }, {
        className: _modals.ModalLayoutClassName.XXL
      });

      if (refresh) {
        this.storage.refresh();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sessionAccount", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "bulkActions", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "rowActions", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "columns", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "predefinedViews", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "predefinedViews"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchParams", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "fetchParams"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openImportModal", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "openImportModal"), _class.prototype)), _class));
  _exports.default = TableIncomingIvoice;
});