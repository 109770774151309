define("iris/models/computed-contact", ["exports", "iris/models/contact"], function (_exports, _contact) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _contact.default.extend();

  _exports.default = _default;
});