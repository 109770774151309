define("iris/models/payment", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 20
    })],
    code: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 10
    })],
    systemFlag: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    round: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    exchangeRound: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    exchangeUnit: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    exchangeRate: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    accountDebit: (0, _emberCpValidations.validator)('length', {
      max: 20
    }),
    accountCredit: (0, _emberCpValidations.validator)('length', {
      max: 20
    }),
    cashbookId: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 12
    })],
    regionId: (0, _emberCpValidations.validator)('length', {
      max: 12
    })
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    code: (0, _attr.default)('string'),
    systemFlag: (0, _attr.default)('number'),
    type: (0, _attr.default)('number'),
    round: (0, _attr.default)('number'),
    roundType: (0, _attr.default)('number'),
    exchangeRound: (0, _attr.default)('number'),
    exchangeUnit: (0, _attr.default)('number'),
    exchangeRate: (0, _attr.default)('number'),
    exchange: (0, _attr.default)('number'),
    accountDebit: (0, _attr.default)('string'),
    accountCredit: (0, _attr.default)('string'),
    cashbookId: (0, _attr.default)('string'),
    regionId: (0, _attr.default)('string'),
    systemCode: (0, _attr.default)('number')
  });

  _exports.default = _default;
});