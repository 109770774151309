define("iris/components/change-requests/edit/risks/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    summary: false,
    changeRequestRiskLevels: Ember.computed('store', 'summary', function () {
      if (this.summary) {
        return [];
      } else {
        return this.store.findAll('changeRequestRiskLevel');
      }
    })
  });

  _exports.default = _default;
});