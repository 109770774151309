define("iris/components/keycloak-session-profile", ["exports", "ember-keycloak-auth/components/keycloak-session-profile"], function (_exports, _keycloakSessionProfile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _keycloakSessionProfile.default;
    }
  });
});