define("iris/lib/filters/data", ["exports", "moment", "iris/lib/filters/types"], function (_exports, _moment, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LOOPBACK_VALUE_FORMATTERS = _exports.FILTER_TYPE_LABELS = _exports.FILTER_DEFINITIONS = _exports.DISPLAY_VALUE_FORMATTERS = _exports.DEFAULT_OPTIONS_NAME_KEY = void 0;
  const DEFAULT_OPTIONS_NAME_KEY = 'name';
  _exports.DEFAULT_OPTIONS_NAME_KEY = DEFAULT_OPTIONS_NAME_KEY;
  const FILTER_TYPE_LABELS = {
    [_types.FilterType.Boolean]: 'Igen/Nem',
    [_types.FilterType.Contains]: 'Tartalmazza',
    [_types.FilterType.ContainsAny]: 'Valamelyik',
    [_types.FilterType.Equal]: 'Pontosan',
    [_types.FilterType.NotEqual]: 'Nem egyenlő',
    [_types.FilterType.GreaterThan]: 'Nagyobb mint',
    [_types.FilterType.LessThan]: 'Kisebb mint',
    [_types.FilterType.NotNull]: 'Ki van töltve',
    [_types.FilterType.Null]: 'Nincs kitöltve'
  };
  /**
   * Validators for various filter value types.
   */

  _exports.FILTER_TYPE_LABELS = FILTER_TYPE_LABELS;
  const FILTER_VALIDATORS = {
    null: _ref => {
      let {
        newValue
      } = _ref;
      return Ember.typeOf(newValue) === 'null';
    },
    array: _ref2 => {
      let {
        newValue
      } = _ref2;
      return Ember.typeOf(newValue) === 'array' && newValue.length > 0;
    },
    boolean: _ref3 => {
      let {
        newValue
      } = _ref3;
      return Ember.typeOf(newValue) === 'boolean';
    },
    date: _ref4 => {
      let {
        newValue
      } = _ref4;
      return Ember.typeOf(newValue) === 'date';
    },
    number: _ref5 => {
      let {
        newValue
      } = _ref5;
      return Ember.typeOf(newValue) === 'number' && !Number.isNaN(newValue);
    },
    string: _ref6 => {
      let {
        newValue
      } = _ref6;
      return Ember.typeOf(newValue) === 'string';
    }
  };
  /**
   * Formatters for sending the correct values inside loopback queries. For
   * example, an `ilike` operator works best if the value is wrapped inside `%`s,
   * while a query for a `BelongsTo` association accepts string `id`s, that are
   * needed to be extracted from the object that contains both the `id` and the
   * `name` key needed for displaying record for the user inside ex. a select box.
   */

  const LOOPBACK_VALUE_FORMATTERS = {
    // For `null` values it's allowed not to pass the value, in this case `null`
    // is returned. However, if we filter to both `null` and empty string, we must
    // somehow tell the difference at serialization-time.
    [_types.LoopBackValueFormat.Null]: value => typeof value === 'undefined' ? null : value,
    [_types.LoopBackValueFormat.Exact]: value => value,
    [_types.LoopBackValueFormat.Ilike]: value => `%${value}%`,
    [_types.LoopBackValueFormat.BelongsTo]: value => value.id,
    [_types.LoopBackValueFormat.Array]: value => value.map(_ref7 => {
      let {
        id
      } = _ref7;
      return id;
    })
  };
  /**
   * Formatters for displaying values inside tables or filters.
   */

  _exports.LOOPBACK_VALUE_FORMATTERS = LOOPBACK_VALUE_FORMATTERS;
  const DISPLAY_VALUE_FORMATTERS = {
    [_types.DisplayValueFormat.Exact]: value => value,
    [_types.DisplayValueFormat.Date]: value => value ? new Date(value).toLocaleDateString(_moment.default.locale()) : ''
  };
  /**
   * This is basically a lookup table used to get the neccessary info for a
   * `Filter` to be able to build the UI (input fields, icons/symbols for the
   * operator, etc.), the LoopBack query condition.
   *
   * Note that these are NOT actual `Filter`s, as they contain no values, merely
   * the recipes to build filters!
   *
   * To actually look up a `FilterDefinition`, use the
   */

  _exports.DISPLAY_VALUE_FORMATTERS = DISPLAY_VALUE_FORMATTERS;
  const FILTER_DEFINITIONS = [// Array
  {
    attributeType: _types.AttributeType.Array,
    filterType: _types.FilterType.ContainsAny,
    inputType: _types.InputType.MultiSelect,
    operator: _types.Operator.In,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Array],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.array,
    acceptsValueFrom: [],
    serializeQueryParamValue: value => value.map(_ref8 => {
      let {
        id
      } = _ref8;
      return id;
    }),
    deserializeQueryParamValue: (value, column) => {
      var _column$options;

      let options = (_column$options = column.options) !== null && _column$options !== void 0 ? _column$options : [];
      return value.map(id => {
        let option = options.find(_ref9 => {
          let {
            id: optionId
          } = _ref9;
          return id === optionId;
        });

        if (!option) {
          console.error(`Could not find option for ${column.name} with id: ${id}`);
          return {
            name: `(name missing for id: ${id})`,
            id
          };
        }

        return option;
      });
    }
  }, {
    attributeType: _types.AttributeType.Array,
    filterType: _types.FilterType.NotNull,
    inputType: _types.InputType.Null,
    operator: _types.Operator.NotEqual,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Null],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.null,
    acceptsValueFrom: []
  }, {
    attributeType: _types.AttributeType.Array,
    filterType: _types.FilterType.Null,
    inputType: _types.InputType.Null,
    operator: _types.Operator.Equal,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Null],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.null,
    acceptsValueFrom: []
  }, // Belongs To
  {
    attributeType: _types.AttributeType.BelongsTo,
    filterType: _types.FilterType.Equal,
    inputType: _types.InputType.Select,
    operator: _types.Operator.Equal,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.BelongsTo],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.array,
    acceptsValueFrom: []
  }, {
    attributeType: _types.AttributeType.BelongsTo,
    filterType: _types.FilterType.NotEqual,
    inputType: _types.InputType.Select,
    operator: _types.Operator.NotEqual,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.BelongsTo],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.array,
    acceptsValueFrom: []
  }, {
    attributeType: _types.AttributeType.BelongsTo,
    filterType: _types.FilterType.NotNull,
    inputType: _types.InputType.Null,
    operator: _types.Operator.NotEqual,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Null],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.null,
    acceptsValueFrom: []
  }, {
    attributeType: _types.AttributeType.BelongsTo,
    filterType: _types.FilterType.Null,
    inputType: _types.InputType.Null,
    operator: _types.Operator.Equal,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Null],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.null,
    acceptsValueFrom: []
  }, // Boolean
  {
    attributeType: _types.AttributeType.Boolean,
    filterType: _types.FilterType.Boolean,
    inputType: _types.InputType.Checkbox,
    operator: _types.Operator.Equal,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Exact],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.boolean,
    acceptsValueFrom: [{
      attributeType: _types.AttributeType.Boolean
    }]
  }, {
    attributeType: _types.AttributeType.Boolean,
    filterType: _types.FilterType.NotNull,
    inputType: _types.InputType.Null,
    operator: _types.Operator.NotEqual,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Null],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.null,
    acceptsValueFrom: []
  }, {
    attributeType: _types.AttributeType.Boolean,
    filterType: _types.FilterType.Null,
    inputType: _types.InputType.Null,
    operator: _types.Operator.Equal,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Null],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.null,
    acceptsValueFrom: []
  }, // Date
  {
    attributeType: _types.AttributeType.Date,
    filterType: _types.FilterType.Equal,
    inputType: _types.InputType.Date,
    operator: _types.Operator.Equal,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Exact],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Date],
    validateFilter: FILTER_VALIDATORS.date,
    acceptsValueFrom: [{
      attributeType: _types.AttributeType.Date
    }]
  }, {
    attributeType: _types.AttributeType.Date,
    filterType: _types.FilterType.LessThan,
    inputType: _types.InputType.Date,
    operator: _types.Operator.LessThan,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Exact],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Date],
    validateFilter: FILTER_VALIDATORS.date,
    acceptsValueFrom: [{
      attributeType: _types.AttributeType.Date
    }]
  }, {
    attributeType: _types.AttributeType.Date,
    filterType: _types.FilterType.GreaterThan,
    inputType: _types.InputType.Date,
    operator: _types.Operator.GreaterThan,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Exact],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Date],
    validateFilter: FILTER_VALIDATORS.date,
    acceptsValueFrom: [{
      attributeType: _types.AttributeType.Date
    }]
  }, {
    attributeType: _types.AttributeType.Date,
    filterType: _types.FilterType.NotNull,
    inputType: _types.InputType.Null,
    operator: _types.Operator.NotEqual,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Null],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Date],
    validateFilter: FILTER_VALIDATORS.null,
    acceptsValueFrom: []
  }, {
    attributeType: _types.AttributeType.Date,
    filterType: _types.FilterType.Null,
    inputType: _types.InputType.Null,
    operator: _types.Operator.Equal,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Null],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Date],
    validateFilter: FILTER_VALIDATORS.null,
    acceptsValueFrom: []
  }, // Has Many
  {
    attributeType: _types.AttributeType.HasMany,
    filterType: _types.FilterType.Equal,
    inputType: _types.InputType.MultiSelect,
    operator: _types.Operator.In,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Exact],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.array,
    acceptsValueFrom: []
  }, // Number
  {
    attributeType: _types.AttributeType.Number,
    filterType: _types.FilterType.Equal,
    inputType: _types.InputType.Number,
    operator: _types.Operator.Equal,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Exact],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.number,
    acceptsValueFrom: [{
      attributeType: _types.AttributeType.Number
    }, {
      attributeType: _types.AttributeType.String,
      filterType: [_types.FilterType.Equal, _types.FilterType.Contains],
      converter: value => Number.isNaN(parseFloat(value)) ? undefined : parseFloat(value)
    }]
  }, {
    attributeType: _types.AttributeType.Number,
    filterType: _types.FilterType.LessThan,
    inputType: _types.InputType.Number,
    operator: _types.Operator.LessThan,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Exact],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.number,
    acceptsValueFrom: [{
      attributeType: _types.AttributeType.Number
    }, {
      attributeType: _types.AttributeType.String,
      filterType: [_types.FilterType.Equal, _types.FilterType.Contains],
      converter: value => Number.isNaN(parseFloat(value)) ? undefined : parseFloat(value)
    }]
  }, {
    attributeType: _types.AttributeType.Number,
    filterType: _types.FilterType.GreaterThan,
    inputType: _types.InputType.Number,
    operator: _types.Operator.GreaterThan,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Exact],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.number,
    acceptsValueFrom: [{
      attributeType: _types.AttributeType.Number
    }, {
      attributeType: _types.AttributeType.String,
      filterType: [_types.FilterType.Equal, _types.FilterType.Contains],
      converter: value => Number.isNaN(parseFloat(value)) ? undefined : parseFloat(value)
    }]
  }, {
    attributeType: _types.AttributeType.Number,
    filterType: _types.FilterType.NotNull,
    inputType: _types.InputType.Null,
    operator: _types.Operator.NotEqual,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Null],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.null,
    acceptsValueFrom: []
  }, {
    attributeType: _types.AttributeType.Number,
    filterType: _types.FilterType.Null,
    inputType: _types.InputType.Null,
    operator: _types.Operator.Equal,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Null],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.null,
    acceptsValueFrom: []
  }, // String
  {
    attributeType: _types.AttributeType.String,
    filterType: _types.FilterType.Contains,
    inputType: _types.InputType.Text,
    operator: _types.Operator.Ilike,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Ilike],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.string,
    acceptsValueFrom: [{
      attributeType: _types.AttributeType.String
    }, {
      attributeType: _types.AttributeType.Number,
      filterType: [_types.FilterType.Equal, _types.FilterType.LessThan, _types.FilterType.GreaterThan],
      converter: value => value.toString()
    }]
  }, {
    attributeType: _types.AttributeType.String,
    filterType: _types.FilterType.Equal,
    inputType: _types.InputType.Text,
    operator: _types.Operator.Equal,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Exact],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.string,
    acceptsValueFrom: [{
      attributeType: _types.AttributeType.String
    }, {
      attributeType: _types.AttributeType.Number,
      filterType: [_types.FilterType.Equal, _types.FilterType.LessThan, _types.FilterType.GreaterThan],
      converter: value => value.toString()
    }]
  }, {
    attributeType: _types.AttributeType.String,
    filterType: _types.FilterType.NotEqual,
    inputType: _types.InputType.Text,
    operator: _types.Operator.NotEqual,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Exact],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.string,
    acceptsValueFrom: [{
      attributeType: _types.AttributeType.String
    }]
  }, {
    attributeType: _types.AttributeType.String,
    filterType: _types.FilterType.NotNull,
    inputType: _types.InputType.Null,
    operator: _types.Operator.NotEqual,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Null],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.null,
    acceptsValueFrom: []
  }, {
    attributeType: _types.AttributeType.String,
    filterType: _types.FilterType.Null,
    inputType: _types.InputType.Null,
    operator: _types.Operator.Equal,
    formatLoopBackValue: LOOPBACK_VALUE_FORMATTERS[_types.LoopBackValueFormat.Null],
    formatDisplayValue: DISPLAY_VALUE_FORMATTERS[_types.DisplayValueFormat.Exact],
    validateFilter: FILTER_VALIDATORS.null,
    acceptsValueFrom: []
  }];
  _exports.FILTER_DEFINITIONS = FILTER_DEFINITIONS;
});