define("iris/components/tables/entity-licensed-entity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3WD4ND7u",
    "block": "{\"symbols\":[\"c\",\"@model\"],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@defaultFilters\",\"@limit\",\"@storage\",\"@readOnly\",\"@isRender\",\"@customButtons\",\"@columnOverwrites\",\"@extraColumns\",\"@externalRowActions\",\"@neededFields\"],[\"entity-licensed-entity-assoc\",[28,\"hash\",null,[[\"parentEntityId\"],[[23,2,[\"id\"]]]]],10,[23,0,[\"storage\"]],true,true,[23,0,[\"customButtons\"]],[23,0,[\"columns\"]],[23,0,[\"extraColumns\"]],[23,0,[\"rowActions\"]],[28,\"array\",[\"entity\",\"parentEntity\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"parentEntityId\"],null]],null,{\"statements\":[[0,\"    \"],[1,[23,1,[\"row\",\"entity\",\"entityType\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"entityId\"],null]],null,{\"statements\":[[4,\"link-to\",null,[[\"route\",\"models\"],[\"cmdb.entity-types.entities.entity.attributes\",[28,\"array\",[[23,1,[\"row\",\"entity\",\"entityType\",\"id\"]],[23,1,[\"row\",\"entity\",\"id\"]]],null]]],{\"statements\":[[0,\"      \"],[1,[23,1,[\"row\",\"entity\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/tables/entity-licensed-entity/template.hbs"
    }
  });

  _exports.default = _default;
});