define("iris/models/change-request-task", ["exports", "@ember-data/model", "ember-cp-validations", "ember-data/relationships"], function (_exports, _model, _emberCpValidations, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/use-ember-data-rfc-395-imports
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    comment: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    from: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    to: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    isCheck: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    changeRequest: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('belongs-to'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    resolver: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _model.attr)('string'),
    from: (0, _model.attr)('date'),
    to: (0, _model.attr)('date'),
    comment: (0, _model.attr)('string'),
    isCheck: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    changeRequest: (0, _relationships.belongsTo)('changeRequest'),
    ticket: (0, _relationships.belongsTo)('ticket'),
    resolver: (0, _relationships.belongsTo)('account')
  });

  _exports.default = _default;
});