define("iris/templates/components/acl-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xOvkA9kt",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[1,[24,[\"row\",\"property\"]],false],[0,\"\\n\"],[4,\"ember-tooltip\",null,[[\"class\"],[\"mw600\"]],{\"statements\":[[0,\"  \"],[7,\"ul\",true],[10,\"class\",\"pl-11\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"row\",\"builtinAclActionAssocs\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[1,[23,1,[\"actionSystemName\"]],false],[0,\" / \"],[1,[28,\"if\",[[23,1,[\"actionMenu\"]],[28,\"t\",[\"forms.menu\"],null],[28,\"t\",[\"forms.action\"],null]],null],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/acl-summary.hbs"
    }
  });

  _exports.default = _default;
});