define("iris/models/event", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      min: 5,
      max: 100
    })],
    eventType: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  var _default = _model.default.extend(Validations, {
    title: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    isAccepted: (0, _attr.default)('boolean', {
      allowNull: true
    }),
    emergency: (0, _attr.default)('boolean', {
      allowNull: true
    }),
    rejectReason: (0, _attr.default)('string'),
    createDate: (0, _attr.default)('date'),
    eventType: (0, _relationships.belongsTo)('eventType'),
    user: (0, _relationships.belongsTo)('account'),
    eventDays: (0, _relationships.hasMany)('eventDay')
  });

  _exports.default = _default;
});