define("iris/helpers/ticket-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.ticketState = ticketState;

  function ticketState(params
  /*, hash*/
  ) {
    let state = params[0];
    let value = 'default';

    if (state == 'SYS_10' || state == 'SYS_07' || state == 'SYS_08' || state == 'SYS_03') {
      value = 'success';
    } else if (state == 'SYS_09') {
      value = 'danger';
    } else if (state == 'SYS_05' || state == 'SYS_06') {
      value = 'info';
    } else if (state == 'SYS_02') {
      value = 'warning';
    }

    return value;
  }

  var _default = Ember.Helper.helper(ticketState);

  _exports.default = _default;
});