define("iris/templates/crm/contracts/new/grade-costs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G2IWlB/g",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"tables/contract-grade-costs\",null,[[\"data\"],[[24,[\"model\",\"contractUserGradeCosts\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/crm/contracts/new/grade-costs.hbs"
    }
  });

  _exports.default = _default;
});