define("iris/models/product-result-template", ["exports", "@ember-data/model", "ember-cp-validations", "ember-data/relationships"], function (_exports, _model, _emberCpValidations, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    description: (0, _emberCpValidations.validator)('length', {
      max: 2024
    })
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string', {
      formWidget: 'textarea'
    }),
    billable: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    active: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    projectTicketTemplate: (0, _relationships.hasMany)('projectTicketTemplate')
  });

  _exports.default = _default;
});