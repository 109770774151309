define("iris/components/tables/vats/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yDqI9m3K",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@dropDownActions\",\"@neededFields\"],[\"vat\",[28,\"hash\",null,[[\"new\",\"edit\",\"index\"],[\"settings.finances.vats.new\",\"settings.finances.vats.edit\",\"settings.finances.vats.index\"]]],[23,0,[\"dropDownActions\"]],[28,\"array\",[\"vatCode\",\"vatType\",\"vatValue\",\"dateFrom\",\"dateTo\",\"systemCode\",\"code1\",\"code2\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"vatType\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"vat-type\",null,[[\"vatType\"],[[23,1,[\"row\",\"vatType\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"vatValue\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"round-float\",[[23,1,[\"row\",\"vatValue\"]],2],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/tables/vats/template.hbs"
    }
  });

  _exports.default = _default;
});