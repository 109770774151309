define("iris/controllers/operation/scheduled-tasks/index", ["exports", "iris/services/query-params"], function (_exports, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const scheduledTasksQueryParams = (0, _queryParams.makeFilterQueryParams)();
  const queryParamsMixin = (0, _queryParams.makeControllerMixin)(scheduledTasksQueryParams);

  var _default = Ember.Controller.extend(queryParamsMixin, {
    reset() {}

  });

  _exports.default = _default;
});