define("iris/components/tables/service-orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        name: this.intl.t('service-order.file'),
        property: 'fileName'
      }, {
        name: this.intl.t('service-order.task'),
        property: 'task'
      }, {
        name: this.intl.t('service-order.startDate'),
        property: 'startDate'
      }, {
        name: this.intl.t('service-order.endDate'),
        property: 'endDate'
      }, {
        name: this.intl.t('service-order.weekDay'),
        property: 'weekDay'
      }, {
        name: this.intl.t('service-order.servicePerson1'),
        property: 'servicePerson1'
      }, {
        name: this.intl.t('service-order.servicePerson2'),
        property: 'servicePerson2'
      }];
    })
  });

  _exports.default = _default;
});