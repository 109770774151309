define("iris/components/check-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let CheckList = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class CheckList extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", 'div');

      _defineProperty(this, "expanded", true);

      _defineProperty(this, "checkedElements", Ember.A([]));
    }

    makeDraggable() {
      const target = document.getElementById(this.elementId).getElementsByClassName('note')[0]; //new Draggable(target);
    }

    selectAll(value) {
      this.checkedElements.clear();

      if (value) {
        this.checkedElements.pushObjects(this.list);
      }
    }

    checkBoxClicked(elem, value) {
      if (value) {
        this.checkedElements.pushObject(elem);
      } else {
        this.checkedElements.removeObject(elem);
      }
    }

    toggle() {
      this.toggleProperty('expanded');
    }

    async allDone() {
      if (this.afterAllChecked) {
        await this.afterAllChecked();
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "makeDraggable", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "makeDraggable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectAll", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "selectAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkBoxClicked", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "checkBoxClicked"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "allDone", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "allDone"), _class.prototype)), _class));
  _exports.default = CheckList;
});