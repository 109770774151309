define("iris/templates/operation/portal-dashboard/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9Gl9eYxr",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"operation.portal-dashboard.view\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"max-w-screen-lg mx-auto\"],[8],[0,\"\\n  \"],[5,\"portal-dashboard/edit\",[[12,\"class\",\"mb-10\"]],[[\"@model\"],[[23,0,[\"model\"]]]]],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"\\n    mb-4 flex items-center justify-end gap-4 p-4 rounded\\n    bg-gray-200 dark:bg-portoDark-gray-2e353e dark:border-gray-500\\n  \"],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"btn btn-default mr-auto flex items-center gap-4\"]],[[\"@route\"],[\"operation.portal-dashboard.index\"]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"fa-icon\",[\"arrow-left\"],null],false],[0,\"\\n      \"],[1,[28,\"t\",[\"general.back\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/operation/portal-dashboard/edit.hbs"
    }
  });

  _exports.default = _default;
});