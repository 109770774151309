define("iris/components/no-ui-slider", ["exports", "ember-cli-nouislider/components/range-slider"], function (_exports, _rangeSlider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rangeSlider.default.extend({
    attributeBindings: ['disabled'],
    disabled: null,
    updateOptions: Ember.observer('max', 'start', 'disabled', function () {
      let proprties = this.getProperties('slider', 'range', 'start');
      let {
        slider,
        range,
        start
      } = proprties;
      slider.updateOptions({
        range: range,
        start: start
      });
    })
  });

  _exports.default = _default;
});