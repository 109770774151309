define("iris/components/competency-list", ["exports", "iris/utils/properties"], function (_exports, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    sections: null,
    flattend: null,
    finance: false,
    modelActionsDidChange: (0, _properties.observerOnce)('modelActions', function () {
      let parentPromises = this.get('modelActions').filter(action => !action.get('hasParent'));
      Ember.RSVP.allSettled(parentPromises).then(array => {
        let sections = array.map(section => section.value);
        this.set('sections', sections);
      });
    }).on('init'),
    _sectionsChildren: Ember.computed.mapBy('sections', 'children'),
    sectionsDidChange: (0, _properties.observerOnce)('_sectionsChildren.@each.length', 'sections', function () {
      let res = Ember.A();
      let sections = this.get('sections');

      if (!sections) {
        return;
      }

      const finance = this.get('finance');
      sections.forEach(section => {
        if (section.get('children.length')) {
          section.get('children').then(children => {
            let sectionArray = Ember.A();
            children.forEach(child => {
              if (!!child.get('finance') === finance) {
                sectionArray.pushObject(child);
              }
            });
            let obj = {
              name: section.get('name'),
              items: sectionArray
            };
            res.pushObject(obj);
            this.set('flattend', res);
          });
        }
      });
    }).on('init'),
    actions: {
      save(action, c) {
        action.get('competencies').then(competencies => {
          competencies.setObjects(c);
          action.save();
        });
      },

      reset() {
        let file = this.get('model.file');
        let id = file.get('id');

        let _this = this;

        this.set('active', true);
        this.get('model.file.fileActionCompetencyAssocs').then(fileActionCompetencyAssocs => {
          let promises = fileActionCompetencyAssocs.invoke('destroyRecord');
          Ember.RSVP.all(promises).then(() => {
            this.get('ajax').call('POST', 'project', id, 'reset-acl', {}).then(result => {
              file.hasMany('fileActionCompetencyAssocs').reload().then(() => {
                _this.set('active', false);
              });
            });
          });
        });
      }

    }
  });

  _exports.default = _default;
});