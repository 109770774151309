define("iris/templates/settings/operations/service-areas/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "++GxUPa0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-tether\",null,[[\"to\",\"target\",\"targetModifier\",\"attachment\",\"on-overlay-click\",\"tetherClass\",\"overlayClass\"],[\"modal-dialog\",\"document.body\",\"visible\",\"middle center\",[28,\"route-action\",[\"transitionTo\",\"settings.operations.service-areas\"],null],\"modal-dialog modal-md\",\"modal-backdrop\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n\\n      \"],[1,[28,\"modal-header\",null,[[\"title\"],[[28,\"t\",[\"settings.operations.new-service-area\"],null]]]],false],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n          \"],[1,[28,\"forms/service-area\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n          \"],[1,[28,\"buttons/add-button\",null,[[\"action\",\"value\"],[[28,\"route-action\",[\"save\",[28,\"hash\",null,[[\"redirectTo\"],[\"settings.operations.service-areas\"]]]],null],[24,[\"model\",\"serviceArea\"]]]]],false],[0,\"\\n\\n          \"],[1,[28,\"buttons/cancel-button\",null,[[\"action\",\"value\"],[[28,\"route-action\",[\"transitionTo\"],null],\"settings.operations.service-areas\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/operations/service-areas/new.hbs"
    }
  });

  _exports.default = _default;
});