define("iris/templates/components/form-group-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kftG+IBO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[7,\"label\",true],[11,\"class\",[29,[\"col-md-\",[22,\"labelWidth\"],\" control-label\"]]],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"addon\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"col-md-8\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"input-group\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"input-group-addon\"],[8],[0,\"\\n        \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-\",[22,\"addon\"]]]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"input\",true],[11,\"value\",[22,\"value\"]],[10,\"class\",\"form-control\"],[11,\"readonly\",[29,[[28,\"if\",[[24,[\"readonly\"]],\"readonly\",\"\"],null]]]],[10,\"type\",\"text\"],[8],[9],[0,\"\\n      \"],[7,\"small\",true],[10,\"class\",\"form-text text-muted\"],[8],[1,[22,\"feedback\"],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"col-md-8\"],[8],[0,\"\\n    \"],[7,\"input\",true],[11,\"value\",[22,\"value\"]],[10,\"class\",\"form-control\"],[11,\"readonly\",[29,[[28,\"if\",[[24,[\"readonly\"]],\"readonly\",\"\"],null]]]],[10,\"type\",\"text\"],[8],[9],[0,\"\\n    \"],[7,\"small\",true],[10,\"class\",\"form-text text-muted\"],[8],[1,[22,\"feedback\"],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/form-group-input.hbs"
    }
  });

  _exports.default = _default;
});