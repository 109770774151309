define("iris/components/navigation-side-entity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'ul',
    classNames: ['nav', 'nav-children'],
    entityCategories: Ember.computed(function () {
      return this.get('store').query('entity-type-category', {
        filter: {
          order: 'name'
        }
      });
    }),
    actions: {
      toggleNav: function (item) {
        if (item.get('expanded')) {
          Ember.set(item, 'expanded', false);
        } else {
          Ember.set(item, 'expanded', true);
        }
      },
      activateNav: function (item) {
        if (item.get('activated')) {
          Ember.set(item, 'activated', false);
        } else {
          Ember.set(item, 'activated', true);
        }
      },
      setActiveMenu: function (intlKey) {
        this.set('currentMenu', {
          intlKey
        });
      }
    }
  });

  _exports.default = _default;
});