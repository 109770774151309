define("iris/routes/reports/pentaho", ["exports", "iris/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function (params) {
      const pathEncode = params.path.replace(/\//g, ":");
      let type = 'prpt';

      if (params.path.substr(params.path.length - 7) === 'xjpivot') {
        type = 'xjpivot';
      }

      if (params.path.substr(params.path.length - 5) === 'saiku') {
        type = 'saiku';
      }

      const ar = params.path.split("/");
      let name = ar[ar.length - 1];
      let options = _environment.default.pentaho;
      return Ember.RSVP.hash({
        path: params.path,
        pathEncode: pathEncode,
        type: type,
        name: name,
        url: options.url
      });
    }
  });

  _exports.default = _default;
});