define("iris/computeds/group-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = groupBy;

  function groupBy(collection, property) {
    var dependentKey = collection + '.@each.' + property;
    return Ember.computed(dependentKey, function () {
      var groups = Ember.A();
      var items = Ember.get(this, collection);
      items.forEach(function (item) {
        var value = Ember.get(item, property);

        if (value instanceof Date) {
          value = value.toString();
        }

        var group = groups.findBy('value', value);

        if (Ember.isPresent(group)) {
          Ember.get(group, 'items').push(item);
        } else {
          group = {
            property: property,
            value: value,
            items: [item]
          };
          groups.push(group);
        }
      });
      return groups;
    }).readOnly();
  }
});