define("iris/templates/components/power-select-related", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yJGsw+dy",
    "block": "{\"symbols\":[\"option\",\"@searchEnabled\"],\"statements\":[[4,\"iris-power-select\",null,[[\"options\",\"placeholder\",\"searchField\",\"selected\",\"onchange\",\"allowClear\",\"disabled\",\"searchEnabled\"],[[24,[\"computedOptions\"]],[24,[\"placeholder\"]],[24,[\"searchField\"]],[24,[\"selected\"]],[24,[\"onchange\"]],[24,[\"allowClear\"]],[24,[\"disabled\"]],[23,2,[]]]],{\"statements\":[[0,\"  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[1,[28,\"get\",[[23,1,[]],[24,[\"searchField\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/power-select-related.hbs"
    }
  });

  _exports.default = _default;
});