define("iris/models/vat", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let now = new Date();
  const Validations = (0, _emberCpValidations.buildValidations)({
    vatId: (0, _emberCpValidations.validator)('length', {
      max: 12
    }),
    vatCode: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 7
    })],
    vatValue: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    vatType: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    code1: (0, _emberCpValidations.validator)('length', {
      max: 15
    }),
    code2: (0, _emberCpValidations.validator)('length', {
      max: 15
    }),
    regionId: (0, _emberCpValidations.validator)('length', {
      max: 12
    })
  });

  var _default = _model.default.extend(Validations, {
    orderColumn: (0, _attr.default)('string', {
      defaultValue: 'vatCode'
    }),
    searchAttr: (0, _attr.default)('string', {
      defaultValue: 'vatCode'
    }),
    vatId: (0, _attr.default)('string'),
    vatCode: (0, _attr.default)('string'),
    vatValue: (0, _attr.default)('number'),
    vatType: (0, _attr.default)('number'),
    code1: (0, _attr.default)('string'),
    code2: (0, _attr.default)('string'),
    dateFrom: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    dateTo: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    regionId: (0, _attr.default)('string'),
    systemCode: (0, _attr.default)('number'),
    formattedValue: Ember.computed('vatValue', 'vatCode', function () {
      return `${this.vatValue * 100}% ${this.vatCode}`;
    }),
    name: Ember.computed.alias('formattedValue'),
    toJSON: function () {
      return this._super({
        includeId: true
      });
    }
  });

  _exports.default = _default;
});