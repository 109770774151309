define("iris/components/tables/cron-job-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ku5Fps6K",
    "block": "{\"symbols\":[\"t\",\"@options\"],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@readOnly\",\"@isRender\",\"@defaultFilters\"],[\"cron-job-result\",true,true,[28,\"or\",[[23,2,[]],null],null]]],{\"statements\":[[0,\"\\n\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"createDate\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"moment-format\",[[23,1,[\"row\",\"createDate\"]],\"L LT\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/tables/cron-job-results/template.hbs"
    }
  });

  _exports.default = _default;
});