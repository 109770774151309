define("iris/models/partner-view", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { belongsTo, hasMany } from 'ember-data/relationships';
  const Validations = (0, _emberCpValidations.buildValidations)({
    code: (0, _emberCpValidations.validator)('length', {
      max: 10
    }),
    name: (0, _emberCpValidations.validator)('length', {
      max: 100
    })
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    code: (0, _attr.default)('string'),
    volumen: (0, _attr.default)('number'),
    potential: (0, _attr.default)('number'),
    yearlyPotential: (0, _attr.default)('number'),
    notPaidInvoicesTotal: (0, _attr.default)('number'),
    polity: (0, _attr.default)('number'),
    domesticPartner: (0, _attr.default)('number'),
    avarageExpiration: (0, _attr.default)('number'),
    lastInvoice: (0, _attr.default)('number'),
    supplier: (0, _attr.default)('number'),
    customer: (0, _attr.default)('number'),
    subcontractor: (0, _attr.default)('number')
  });

  _exports.default = _default;
});