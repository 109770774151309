define("iris/helpers/string-to-camel-case", ["exports", "ember-string-fns/helpers/string-to-camel-case"], function (_exports, _stringToCamelCase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _stringToCamelCase.default;
    }
  });
  Object.defineProperty(_exports, "stringToCamelCase", {
    enumerable: true,
    get: function () {
      return _stringToCamelCase.stringToCamelCase;
    }
  });
});