define("iris/models/file-type", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations", "ember-data/relationships"], function (_exports, _model, _attr, _emberCpValidations, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { belongsTo, hasMany } from 'ember-data/relationships';
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 100
    })],
    code: (0, _emberCpValidations.validator)('length', {
      max: 5
    })
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    code: (0, _attr.default)('string'),
    planning: (0, _attr.default)('boolean'),
    proposal: (0, _attr.default)('boolean'),
    outgoingProposal: (0, _attr.default)('boolean'),
    orderOk: (0, _attr.default)('boolean'),
    contract: (0, _attr.default)('boolean'),
    export: (0, _attr.default)('boolean'),
    projectType: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    fileBusinessType: (0, _relationships.belongsTo)('fileBusinessType')
  });

  _exports.default = _default;
});