define("iris/components/beta/modals/delete-confirmation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hlL6XuQ4",
    "block": "{\"symbols\":[\"@close\"],\"statements\":[[5,\"beta/modals/confirmation\",[],[[\"@close\",\"@data\"],[[23,1,[]],[28,\"hash\",null,[[\"title\",\"body\"],[[28,\"t\",[\"general.deletion\"],null],[28,\"t\",[\"modals.delete-confirmation\"],null]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/beta/modals/delete-confirmation/template.hbs"
    }
  });

  _exports.default = _default;
});