define("iris/components/project/file-balance/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProjectFileBalance = (_dec = Ember.inject.service, _dec2 = Ember.computed.mapBy('model.productResults', 'amount'), _dec3 = Ember.computed.sum('productResultsAmountArray'), _dec4 = Ember.computed.mapBy('model.productResults', 'cost'), _dec5 = Ember.computed.sum('productResultsCostArray'), _dec6 = Ember.computed.mapBy('model.productResults', 'costOverhead'), _dec7 = Ember.computed.sum('productResultsOverheadCostArray'), _dec8 = Ember.computed.mapBy('model.validOutgoingProposal', 'defaultAmountUntaxed'), _dec9 = Ember.computed.sum('outgoingProposalsAmountArray'), _dec10 = Ember._action, (_class = class ProjectFileBalance extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "toOpen", {
        productResult: Ember.A(),
        proposal: Ember.A()
      });

      _initializerDefineProperty(this, "sessionAccount", _descriptor, this);

      _initializerDefineProperty(this, "productResultsAmountArray", _descriptor2, this);

      _initializerDefineProperty(this, "productResultsAmount", _descriptor3, this);

      _initializerDefineProperty(this, "productResultsCostArray", _descriptor4, this);

      _initializerDefineProperty(this, "productResultsCost", _descriptor5, this);

      _initializerDefineProperty(this, "productResultsOverheadCostArray", _descriptor6, this);

      _initializerDefineProperty(this, "productResultsOverheadCost", _descriptor7, this);

      _initializerDefineProperty(this, "outgoingProposalsAmountArray", _descriptor8, this);

      _initializerDefineProperty(this, "outgoingProposalsAmount", _descriptor9, this);
    }

    openRow(record, which) {
      let toOpen = this.get('toOpen');

      if (toOpen[which].contains(record)) {
        toOpen[which].removeObject(record);
      } else {
        toOpen[which].addObject(record);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionAccount", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "productResultsAmountArray", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "productResultsAmount", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "productResultsCostArray", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "productResultsCost", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "productResultsOverheadCostArray", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "productResultsOverheadCost", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "outgoingProposalsAmountArray", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "outgoingProposalsAmount", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openRow", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "openRow"), _class.prototype)), _class));
  /*
  
  export default Component.extend({
    sessionAccount: service(),
    productResultsAmountArray: mapBy('model.productResults', 'amount'),
    productResultsAmount: sum('productResultsAmountArray'),
    productResultsCostArray: mapBy('model.productResults', 'cost'),
    productResultsCost: sum('productResultsCostArray'),
    productResultsOverheadCostArray: mapBy(
      'model.productResults',
      'costOverhead'
    ),
    productResultsOverheadCost: sum('productResultsOverheadCostArray'),
    outgoingProposalsAmountArray: mapBy(
      'model.validOutgoingProposal',
      'defaultAmountUntaxed'
    ),
    outgoingProposalsAmount: sum('outgoingProposalsAmountArray'),
    toOpen: {
      productResult: A(),
      proposal: A(),
    },
    actions: {
      openRow: function (record, which) {
        let toOpen = this.get('toOpen');
        if (toOpen[which].contains(record)) {
          toOpen[which].removeObject(record);
        } else {
          toOpen[which].addObject(record);
        }
      },
    },
  });
  */

  _exports.default = ProjectFileBalance;
});