define("iris/templates/components/dashboard/project-completion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1NyO7q1Y",
    "block": "{\"symbols\":[\"project\"],\"statements\":[[7,\"h4\",true],[10,\"class\",\"h4\"],[8],[1,[28,\"t\",[\"dashboard.project-readiness\"],null],false],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"projects\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\",true],[10,\"class\",\"no-gutter\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-7\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-5\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-sm-9\"],[8],[0,\"\\n        \"],[1,[28,\"progress-bar\",null,[[\"progress\"],[[23,1,[\"completionLevel\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-sm-3\"],[8],[1,[23,1,[\"completionLevel\"]],false],[0,\"%\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/dashboard/project-completion.hbs"
    }
  });

  _exports.default = _default;
});