define("iris/controllers/cmdb/licenses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    entityTypeController: Ember.inject.controller('cmdb.entity-types.index'),
    actions: {
      setOldRouteAndTransition(oldRoute, entityTypeId, entityId) {
        let controller = this.entityTypeController;
        controller.set('oldRoute', oldRoute);
        this.transitionToRoute("cmdb.entity-types.entities.entity.attributes", entityTypeId, entityId);
      }

    }
  });

  _exports.default = _default;
});