define("iris/components/form/tabbed-routes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z/HXhUq6",
    "block": "{\"symbols\":[\"tab\",\"@tabs\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tabs\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"nav nav-tabs\"],[8],[0,\"\\n\"],[4,\"each\",[[23,2,[]]],null,{\"statements\":[[4,\"if\",[[28,\"or\",[[28,\"not\",[[23,1,[\"can\"]]],null],[28,\"can\",[\"read route\"],[[\"route\",\"objId\"],[[28,\"or\",[[23,1,[\"can\",\"route\"]],[23,1,[\"route\"]]],null],[23,1,[\"can\",\"objId\"]]]]]],null]],null,{\"statements\":[[0,\"        \"],[5,\"link-to\",[],[[\"@route\",\"@tagName\"],[[23,1,[\"route\"]],\"li\"]],{\"statements\":[[0,\"\\n          \"],[5,\"link-to\",[],[[\"@route\",\"@models\"],[[23,1,[\"route\"]],[28,\"or\",[[23,1,[\"models\"]],[28,\"array\",null,null]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,1,[\"icon\"]]],null,{\"statements\":[[0,\"              \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-\",[23,1,[\"icon\"]]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n            \"],[1,[23,1,[\"name\"]],false],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"tab-content\"],[8],[1,[22,\"outlet\"],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/form/tabbed-routes/template.hbs"
    }
  });

  _exports.default = _default;
});