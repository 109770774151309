define("iris/templates/components/forms/settings-cmdb-entity-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4aUV1QO1",
    "block": "{\"symbols\":[\"queue\",\"dropzone\"],\"statements\":[[4,\"bs-form\",null,[[\"model\"],[[24,[\"model\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-12 text-center\"],[8],[0,\"\\n      \"],[7,\"img\",true],[11,\"src\",[24,[\"model\",\"icon\"]]],[10,\"class\",\"img-responsive img-black-bg p15\"],[10,\"width\",\"20%\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"pl-uploader\",null,[[\"class\",\"multiple\",\"name\",\"for\",\"extensions\",\"onfileadd\"],[\"top20\",false,\"pu\",\"upload-image\",\"jpg jpeg png gif\",[28,\"action\",[[23,0,[]],\"uploadImage\"],null]]],{\"statements\":[[0,\"   \\n    \"],[7,\"div\",true],[10,\"id\",\"upload-image\"],[8],[0,\"\\n      \"],[7,\"div\",true],[11,\"id\",[23,2,[\"id\"]]],[10,\"class\",\"dropzone dz-clickable\"],[8],[0,\"\\n        \\n        \"],[7,\"div\",true],[10,\"class\",\"dz-default dz-message text-center\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"dz-icon icon-wrap icon-circle icon-wrap-md\"],[8],[0,\"\\n            \"],[7,\"i\",true],[10,\"class\",\"fa fa-cloud-upload fa-3x\"],[8],[9],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"div\",true],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"user.change-image\"],null],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n      \"],[9],[0,\"   \\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\"],[4,\"form-group\",null,[[\"property\",\"label\",\"proportion\"],[\"name\",[28,\"t\",[\"general.name\"],null],\"3-8\"]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"name\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/settings-cmdb-entity-category.hbs"
    }
  });

  _exports.default = _default;
});