define("iris/helpers/string-array-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
    @param1 stringArray
    @param2 element
    @return True if element exists in array
  
  */
  var _default = Ember.Helper.extend({
    compute(params) {
      const element = params[0];
      const stringArray = params[1];

      if (stringArray) {
        return stringArray.split(',').includes(element);
      } else {
        return false;
      }
    }

  });

  _exports.default = _default;
});