define("iris/components/modals/xwiki-path-tree/nested-tree-element/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "STm2zLpk",
    "block": "{\"symbols\":[\"space\",\"@rootData\",\"@selectedSpace\",\"@data\"],\"statements\":[[7,\"ol\",true],[10,\"class\",\"dd-list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,4,[]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"children\",\"length\"]]],null,{\"statements\":[[0,\"\\n      \"],[7,\"li\",true],[10,\"class\",\"dd-item\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"expanded\"]]],null,{\"statements\":[[0,\"          \"],[7,\"button\",false],[12,\"data-action\",\"collapse\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"toggleCollapse\",[23,1,[]]]],[8],[0,\" \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"button\",false],[12,\"data-action\",\"expand\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"toggleCollapse\",[23,1,[]]]],[8],[0,\" \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[7,\"div\",false],[12,\"class\",[29,[\"dd-handle \",[28,\"if\",[[23,1,[\"selected\"]],\"active\"],null]]]],[3,\"action\",[[23,0,[]],\"selectSpace\",[23,1,[]],[23,2,[]]]],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"expanded\"]]],null,{\"statements\":[[0,\"          \"],[5,\"modals/xwiki-path-tree/nested-tree-element\",[],[[\"@selectedSpace\",\"@data\",\"@rootData\"],[[23,3,[]],[23,1,[\"children\"]],[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n      \"],[7,\"li\",true],[10,\"class\",\"dd-item\"],[8],[0,\"\\n        \"],[7,\"div\",false],[12,\"class\",[29,[\"dd-handle \",[28,\"if\",[[23,1,[\"selected\"]],\"active\"],null]]]],[3,\"action\",[[23,0,[]],\"selectSpace\",[23,1,[]],[23,2,[]]]],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n      \\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/modals/xwiki-path-tree/nested-tree-element/template.hbs"
    }
  });

  _exports.default = _default;
});