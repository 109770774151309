define("iris/components/action-competency-partner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selected: Ember.computed('model.partnerActionCompetencyAssocs.[]', 'action', {
      get() {
        let res = Ember.A();
        let id = this.get('action.id');

        let _this = this;

        this.get('model.partnerActionCompetencyAssocs').forEach(partnerCompetency => {
          partnerCompetency.get('action').then(action => {
            if (action.get('id') === id) {
              res.addObject(partnerCompetency.get('competency'));

              _this.set('selected', res);
            }
          });
        });
        return res;
      },

      set(key, value) {
        return value;
      }

    }),
    actions: {
      competencies() {
        let action = arguments.length <= 0 ? undefined : arguments[0];
        let partner = arguments.length <= 1 ? undefined : arguments[1];
        let competencies = arguments.length <= 2 ? undefined : arguments[2];
        let id = action.get('id');
        let store = this.store;
        partner.get('partnerActionCompetencyAssocs').then(partnerActionCompetencyAssocs => {
          partnerActionCompetencyAssocs.forEach(faca => {
            let actionId = faca.belongsTo('action').id();

            if (actionId === id) {
              faca.destroyRecord(true);
            }
          });
          competencies.forEach(competency => {
            let record = store.createRecord('partnerActionCompetencyAssoc', {
              partner: partner,
              competency: competency,
              action: action
            });
            record.save(true);
          });
        });
      }

    }
  });

  _exports.default = _default;
});