define("iris/components/modals/invoice-payment-modal/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let InvoicePaymentModal = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('data.payments'), _dec3 = Ember._action, _dec4 = Ember._action, (_class = class InvoicePaymentModal extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "session", _descriptor, this);

      _defineProperty(this, "data", null);

      _defineProperty(this, "close", null);

      _defineProperty(this, "globalDatePayment", new Date());

      _initializerDefineProperty(this, "payments", _descriptor2, this);
    }

    *savePaymentsTask() {
      for (const payment of this.payments.toArray()) {
        payment.set('datePayment', this.globalDatePayment);
        let saved = yield payment.save();
        let invoice = yield saved.get('invoice');
        let balance = invoice.get('paidBalance') + saved.get('payValue');
        invoice.set('paidBalance', balance);

        if (invoice.get('paidBalance') >= invoice.get('gross')) {
          invoice.set('datePaid', payment.get('datePayment'));
        } else {
          invoice.set('datePaid', null);
        }

        yield invoice.save();
      }
    }

    async savePayments() {
      await this.savePaymentsTask.perform();
      this.close({
        shouldRefreshRecords: true
      });
    }

    async computeBalance(invoicePayment) {
      const invoice = await invoicePayment.get('invoice');
      const gross = invoice.get('navBrutto') || invoice.get('gross');
      const paidBalance = invoice.get('paidBalance') || 0;
      return invoicePayment.get('payValue') + paidBalance - gross;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "payments", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "savePaymentsTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "savePaymentsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "savePayments", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "savePayments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "computeBalance", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "computeBalance"), _class.prototype)), _class));
  _exports.default = InvoicePaymentModal;
});