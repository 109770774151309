define("iris/models/ticket-intervention-user", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    type: (0, _attr.default)('number'),
    user: (0, _relationships.belongsTo)('account'),
    ticket: (0, _relationships.belongsTo)('ticket'),
    confirmed: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    deadline: (0, _attr.default)('date'),
    message: (0, _attr.default)('string'),
    management: Ember.computed.equal('type', 4)
  });

  _exports.default = _default;
});