define("iris/models/operational-project", ["exports", "iris/models/project"], function (_exports, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _project.default.extend({});

  _exports.default = _default;
});