define("iris/routes/change-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        file: 'change-log'
      });
    },

    actionCreate: ['change-log.create'],

    setupController(controller, model) {
      this._super(controller, model);

      this.controller.set('actionCreate', this.get('actionCreate'));
    }

  });

  _exports.default = _default;
});