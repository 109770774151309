define("iris/components/bs-table/column-filter/filter-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      let cfo = this.get('columnFilter');

      if (!cfo.options) {
        cfo.options = Ember.A();
      }
    },

    actions: {
      columnSearch() {
        this.get('columnSearch')();
      },

      columnFilterSelected(columnFilter, selected) {
        Ember.set(columnFilter, 'selected', selected);

        if (!selected && columnFilter.rewriteDefault) {
          this.set('defaultFilterOptions', this.get('defaultFilterOptionsSave'));
        }
      },

      toggleBoolean(columnFilter, value) {
        if (Ember.get(columnFilter, 'value') === value) {
          Ember.set(columnFilter, 'value', null);
        } else {
          Ember.set(columnFilter, 'value', value);
        }
      },

      toggleCheckbox(columnFilter, value) {
        Ember.set(columnFilter, 'value', value);
      }

    }
  });

  _exports.default = _default;
});