define("iris/components/change-requests/edit/overview/component", ["exports", "iris/utils/properties"], function (_exports, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    summary: false,
    api: Ember.inject.service(),
    selectedPartner: null,
    priorities: Ember.computed('store', 'summary', function () {
      if (this.summary) {
        return Ember.A();
      } else {
        return this.store.findAll('changeRequestPriority');
      }
    }),
    impacts: Ember.computed('store', 'summary', function () {
      if (this.summary) {
        return Ember.A();
      } else {
        return this.store.findAll('changeRequestImpact');
      }
    }),
    types: Ember.computed('store', 'summary', function () {
      if (this.summary) {
        return Ember.A();
      } else {
        return this.store.findAll('changeRequestType');
      }
    }),
    operationServiceLevels: Ember.computed.alias('model.project.operationServiceLevels'),
    serviceLevels: Ember.computed.filter('model.operationServiceLevel.serviceLevels', function (serviceLevel) {
      return serviceLevel.belongsTo('type').id() === 'SYS_16'; // Változás
    }),
    showServiceLevel: Ember.computed('summary', 'serviceLevels.[]', function () {
      if (this.summary) {
        return true;
      } else {
        return this.serviceLevels.length;
      }
    }),
    projects: (0, _properties.computedTask)('selectedPartner.id', function* () {
      var _this$selectedPartner, _result$result;

      const partnerFilter = (_this$selectedPartner = this.selectedPartner) !== null && _this$selectedPartner !== void 0 && _this$selectedPartner.id ? {
        primeContractorId: this.selectedPartner.id
      } : {};
      const result = yield this.api.queryView('projectViews/getProjects', {
        filter: {
          where: {
            and: [{
              closed: 0
            }, partnerFilter, {
              type: 'operational'
            }]
          },
          order: 'name'
        }
      });
      return (_result$result = result.result) === null || _result$result === void 0 ? void 0 : _result$result.data;
    }),
    uniqueChangeManagers: Ember.computed.uniqBy('changeManagers', 'id'),
    actions: {
      async changeProject(projectObj) {
        const model = this.model;
        const projects = await this.store.query('project', {
          filter: {
            where: {
              id: projectObj.id
            },
            include: ['technicalManagerUser']
          }
        });
        const [project] = projects.toArray();
        model.set('project', project);
        const technicalManagerUser = await project.get('technicalManagerUser');
        const changeManagers = Ember.A([technicalManagerUser, project.get('sponsorUser'), project.get('managerUser')]);
        this.set('changeManagers', changeManagers);
        model.set('changeManager', technicalManagerUser);
        model.set('operationServiceLevel', null);
        model.set('serviceLevel', null);
      },

      changePartner(partner) {
        this.model.setProperties({
          project: null,
          changeManager: null
        });
        this.set('selectedPartner', partner);
      }

    }
  });

  _exports.default = _default;
});