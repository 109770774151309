define("iris/templates/components/checkbox-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KF69fDis",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"tr\",false],[13,1],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"click\"],null]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"hasCheckbox\"]]],null,{\"statements\":[[0,\"    \"],[7,\"td\",true],[8],[0,\"\\n      \"],[1,[28,\"bs-checkbox\",null,[[\"disabled\",\"value\",\"onchange\"],[[24,[\"disabled\"]],[28,\"contains\",[[24,[\"record\"]],[24,[\"selectedRecords\"]]],null],[28,\"action\",[[23,0,[]],\"toggleRow\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"td\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/checkbox-row.hbs"
    }
  });

  _exports.default = _default;
});