define("iris/components/project/ticket/calendar/component", ["exports", "iris/lib/modals", "moment"], function (_exports, _modals, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let ctx = {};

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    modals: Ember.inject.service(),
    loading: Ember.inject.service(),
    intl: Ember.inject.service(),

    async init() {
      this._super(...arguments);

      ctx = this;
      let publicHolidays = await this.get('store').query('publicHoliday', {
        filter: {
          order: 'date'
        }
      });
      this.set('publicHolidays', publicHolidays);
      this.refreshEvents();
    },

    publicHolidays: Ember.A(),
    calendarEvents: Ember.computed('events.[]', function () {
      return toCalendarSavedEvent(this.events);
    }),
    events: null,
    header: {
      left: 'title',
      right: 'today month,listWeek customPrev,customNext'
    },
    buttonText: Ember.computed(function () {
      return {
        today: this.intl.t('components.fullcalendar.today'),
        month: this.intl.t('components.fullcalendar.month'),
        listWeek: this.intl.t('components.fullcalendar.listWeek'),
        agendaWeek: this.intl.t('components.fullcalendar.week'),
        day: this.intl.t('components.fullcalendar.day')
      };
    }),
    customButtons: {
      customNext: {
        text: ">",
        click: function () {
          ctx.notifyPropertyChange('events');
          $('.full-calendar').fullCalendar('next');
        }
      },
      customPrev: {
        text: "<",
        click: function () {
          ctx.notifyPropertyChange('events');
          $('.full-calendar').fullCalendar('prev');
        }
      }
    },
    dayRender: function (date, cell) {
      let publicHolidays = ctx.get('publicHolidays');
      let cellDay = (0, _moment.default)(date).subtract(1, 'd');

      if ((0, _moment.default)(date).day() === 0 || (0, _moment.default)(date).day() === 6) {
        cell.addClass("weekend");
      }

      for (let i = 0; i < publicHolidays.length; i++) {
        let date = publicHolidays.objectAt(i).get('date').toISOString();

        if (cellDay.isSame((0, _moment.default)(date), 'day')) {
          if (!publicHolidays.objectAt(i).get('isWorkDay')) {
            cell.addClass("work-day");
            return;
          } else {
            cell.addClass("holiday");
          }
        }
      }
    },

    eventRender(event, element) {
      let html = "";

      if (event.isAssoc) {
        html = `<button 
        type="button"
        class="btn btn-default gap-4 items-center justify-center rounded-3xl text-sm p-1 mx-2 opacity-50 cursor-not-allowed">
        <i class='glyphicon glyphicon-ok'></i>
      </button>`;
      } else {
        html = `<button 
        type="button"
        id="ticket-user-assoc"
        class="btn btn-default gap-4 items-center justify-center rounded-3xl text-sm p-1 mx-2">
        <i class='glyphicon glyphicon-user'></i>
        Kiosztás
      </button>`;
      }

      let deleteHtml = `<a 
        id="ticket-delete"
        class="float-right text-xl text-white">
        <i id="ticket-delete" class='glyphicon glyphicon-remove'></i>
      </a>`;
      element.find(".fc-title").prepend(html).after(deleteHtml);
    },

    queryEvents: async (store, fileId) => {
      let filter = {
        filter: {
          include: ['productResult', 'ticketUserAssocs'],
          where: {
            fileId
          }
        }
      };
      let events = await store.query('ticket', filter);
      return events;
    },

    async refreshEvents() {
      this.loading.run(async () => {
        let fileId = ctx.get('project.id');
        await new Promise(r => Ember.run.later(this, r, 1000));
        this.set('events', await this.queryEvents(this.store, fileId));
      });
    },

    actions: {
      eventLimitClick(cellInfo, jsEvent, view) {
        $('.full-calendar').fullCalendar("getCalendar").changeView('listWeek', JSON.stringify(cellInfo.date));
      },

      async select(start, end) {
        const record = this.store.createRecord('ticket', {
          plannedStartDate: start.toDate(),
          plannedEndDate: parseDateWithoutTimezone(end.toDate())
        });
        this.project['tickets'].pushObject(record);
        let newItem = await this.modals.open('modals/form-modal', {
          model: record,
          itemName: 'tickets',
          parentModel: this.project,
          formComponent: 'forms/ticket-form'
        }, {
          className: _modals.ModalLayoutClassName['XXL']
        });

        if (newItem) {
          record.setStartEndDates();
          this.refreshEvents();
        }
      },

      async eventClick(event, mevent) {
        if (mevent.target.id == 'ticket-user-assoc') {
          mevent.stopPropagation();
          mevent.preventDefault();
          const record = this.store.peekRecord('ticket', event.id);
          const assoc = await this.modals.open('modals/ticket-user-assoc', {
            model: record
          }, {
            className: _modals.ModalLayoutClassName['XL']
          });
          this.refreshEvents();
        } else if (mevent.target.id == 'ticket-delete') {
          mevent.stopPropagation();
          mevent.preventDefault();
          const record = this.store.peekRecord('ticket', event.id);
          let shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

          if (shouldDelete) {
            await record.destroyRecord(true);
            this.refreshEvents();
          }
        } else {
          const ticket = this.store.peekRecord('ticket', event.id);
          let editItem = await this.modals.open(`modals/form-modal`, {
            model: ticket,
            itemName: 'tickets',
            parentModel: this.project,
            formComponent: 'forms/ticket-form'
          }, {
            className: _modals.ModalLayoutClassName['XXXL']
          });

          if (editItem) {
            this.refreshEvents();
          }
        }
      },

      async eventResize(event) {
        const record = this.store.peekRecord('ticket', event.id);
        record.set('plannedEndDate', parseDateWithoutTimezone(event.end.toDate()));
        this.loading.run(async () => {
          await record.save();
          record.setStartEndDates();
        });
      },

      async eventDrop(event) {
        const record = this.store.peekRecord('ticket', event.id);
        record.set('plannedStartDate', event.start.toDate());
        record.set('plannedEndDate', parseDateWithoutTimezone(event.end.toDate()));
        this.loading.run(async () => {
          await record.save();
          record.setStartEndDates();
        });
      }

    }
  });

  _exports.default = _default;

  function parseDateWithoutTimezone(d) {
    d.setDate(d.getDate() - 1);
    return d;
  }

  function toCalendarSavedEvent(events) {
    if (!events) {
      return;
    }

    return events.map(event => {
      let end = event.plannedEndDate;
      return {
        id: event.id,
        title: event.subject,
        isAssoc: event.get('ticketUserAssocs.length'),
        start: (0, _moment.default)(event.plannedStartDate).format("YYYY-MM-DD"),
        end: (0, _moment.default)(event.plannedEndDate).add(1, 'd').format("YYYY-MM-DD"),
        color: event.color
      };
    }).flat();
  }
});