define("iris/models/external-mapper", ["exports", "ember-data/attr", "ember-data/model", "ember-data/relationships", "ember-cp-validations"], function (_exports, _attr, _model, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/use-ember-data-rfc-395-imports */
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    irisKey: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    externalKey: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    externalIntegration: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    irisValue: (0, _attr.default)('string'),
    externalValue: (0, _attr.default)('string'),
    irisKey: (0, _attr.default)('string'),
    externalKey: (0, _attr.default)('string'),
    externalIntegrationId: (0, _attr.default)('string'),
    externalIntegration: (0, _relationships.belongsTo)('externalIntegration')
  });

  _exports.default = _default;
});