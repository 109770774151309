define("iris/templates/components/form-element/vertical/select2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A2ztc9y+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"hasLabel\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[11,\"for\",[29,[[28,\"concat\",[[24,[\"elementId\"]],\"-\",[24,[\"name\"]]],null]]]],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[8],[0,\"\\n\\n    \"],[1,[28,\"select-2\",null,[[\"id\",\"name\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"value\",\"searchEnabled\"],[[28,\"concat\",[[24,[\"elementId\"]],\"-\",[24,[\"name\"]]],null],[24,[\"name\"]],[24,[\"choices\"]],[24,[\"choiceValueProperty\"]],[24,[\"choiceLabelProperty\"]],[24,[\"value\"]],false]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[15,\"components/form-element/feedback-icon\",[]],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "iris/templates/components/form-element/vertical/select2.hbs"
    }
  });

  _exports.default = _default;
});