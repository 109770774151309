define("iris/controllers/dashboard", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function expired(dependentKey) {
    let now = (0, _moment.default)();
    return Ember.computed.filter(dependentKey, function (item) {
      return (0, _moment.default)(item.plannedEndDate).isBefore(now);
    });
  }

  function due(dependentKey) {
    let now = (0, _moment.default)();
    let deadline = (0, _moment.default)().add(2, 'd');
    return Ember.computed.filter(dependentKey, function (item) {
      return (0, _moment.default)(item.plannedEndDate).isBetween(now, deadline);
    });
  }

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    serviceMyTickets: Ember.inject.service(),
    socket: Ember.inject.service(),
    loggedInUser: Ember.computed.alias('sessionAccount.account'),
    currentUserId: Ember.computed.alias('session.data.authenticated.userId'),

    init() {
      this._super();
    },

    assignedTickets: Ember.computed('myTickets', 'loggedInUser.id', function () {
      return this.get('myTickets').filterBy('type.id', 'SYS_02');
    }),
    assignedExpired: expired('assignedTickets'),
    assignedDue: due('assignedTickets'),
    myTickets: Ember.computed.alias('serviceMyTickets.data')
  });

  _exports.default = _default;
});