define("iris/components/forms/service-period", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    modals: Ember.inject.service(),
    store: Ember.inject.service(),
    mode: Ember.computed('data.mode', function () {
      var _this$data;

      if ((_this$data = this.data) !== null && _this$data !== void 0 && _this$data.mode) {
        return this.data.mode;
      } else {
        null;
      }
    }),
    pattern: /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/,
    dateTypes: Ember.computed('data.dateTypes', function () {
      var _this$data2;

      if ((_this$data2 = this.data) !== null && _this$data2 !== void 0 && _this$data2.dateTypes) {
        return this.data.dateTypes;
      } else {
        return this.get('store').findAll('dateType');
      }
    }),
    fromOk: Ember.computed('from', function () {
      const from = this.get('from');

      if (typeof from !== 'string') {
        return false;
      }

      return from.match(this.get('pattern'));
    }),
    toOk: Ember.computed('to', function () {
      const to = this.get('to');

      if (typeof to !== 'string') {
        return false;
      }

      return to.match(this.get('pattern'));
    }),
    disabled: Ember.computed('fromOk', 'toOk', function () {
      return !(this.get('fromOk') && this.get('toOk'));
    }),

    stringToInt(str) {
      const [hours, minutes] = str.split(':');
      return Number(hours) * 60 + Number(minutes);
    },

    actions: {
      async delete(period) {
        let shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

        if (shouldDelete) {
          const deleteResult = await period.destroyRecord(true);

          if (deleteResult) {
            this.flashMessages.info(this.intl.t('general.delete.success-title'), {
              title: this.intl.t('general.delete.success'),
              icon: 'times'
            });
          } else {
            this.flashMessages.danger(this.intl.t('general.delete.error-title'), {
              title: this.intl.t('general.delete.error'),
              icon: 'times'
            });
          }
        }
      },

      async addInterval() {
        const from = this.get('from');
        const to = this.get('to');

        if (this.stringToInt(from) >= this.stringToInt(to)) {
          const m = this.intl.t('forms.service-period.dateError', {
            from,
            to
          });
          this.get('flashMessages').danger(m, {
            title: this.intl.t('general.error'),
            icon: 'times',
            sticky: true
          });
          return;
        }

        let props = {
          start: from,
          end: to
        };
        let recordName;

        if (this.get('mode') === 'file') {
          recordName = 'servicePeriodFileInterval';
          props.servicePeriod = this.get('model');
        } else {
          recordName = 'servicePeriodInterval';
        }

        const interval = await this.get('store').createRecord(recordName, props);
        const intervals = await this.get('model.servicePeriodIntervals');
        intervals.pushObject(interval);
        this.get('model').set('isDirtyTemp', new Date());
        this.set('from', null);
        this.set('to', null);
      }

    }
  });

  _exports.default = _default;
});