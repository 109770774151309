define("iris/templates/settings/project/file-business-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B8MCPAKr",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@defaultOrder\",\"@excludeFields\"],[\"file-business-type\",\"settings.project.file-business-types.edit\",\"createDate DESC\",[28,\"array\",[\"fileTypes\"],null]]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/project/file-business-types.hbs"
    }
  });

  _exports.default = _default;
});