define("iris/utils/get-base-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Returns the base URL
   *
   * ```JS
   *  getBaseUrl(this) // => http://localhost:30022/api
   * ```
   * @method
   * @return {string}
   * @param {object} objectWithOwner an object with owner (component, service, route, etc)
   */
  var _default = objectWithOwner => {
    const owner = Ember.getOwner(objectWithOwner);
    const adapter = owner.lookup('adapter:application');
    return adapter.buildURL();
  };

  _exports.default = _default;
});