define("iris/components/integrations/mappers/component", ["exports", "iris/lib/filters", "iris/lib/filters/column", "iris/lib/filters/storage", "iris/lib/filters/types", "iris/lib/table"], function (_exports, _filters, _column, _storage, _types, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    storage: (0, _storage.useStorage)(),
    columns: (0, _column.columns)('customAttributes', function () {
      return [{
        name: 'name',
        displayName: this.intl.t('forms.name-colon'),
        fixed: true,
        route: 'settings.integrations.mappers.edit',
        routeParams: (0, _table.rowValues)('id')
      }, {
        name: 'externalIntegrationId',
        displayName: this.intl.t('forms.external-integration'),
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'externalIntegration',
        valuePath: 'externalIntegration.name',
        route: 'settings.integrations.services.edit',
        routeParams: (0, _table.rowValues)('externalIntegration.id')
      }, {
        name: 'irisKey',
        displayName: this.intl.t('forms.iris-key')
      }, {
        name: 'externalKey',
        displayName: this.intl.t('forms.external-key')
      }, {
        name: 'irisValue',
        displayName: this.intl.t('forms.iris-value')
      }, {
        name: 'externalValue',
        displayName: this.intl.t('forms.external-value')
      }];
    }),
    rowActions: (0, _table.rowActions)(function () {
      return [];
    }),
    fetchParams: Ember.computed('columns', 'storage.params', function () {
      let {
        filter
      } = (0, _filters.makeQueryObject)(this.columns, this.storage.params);
      return {
        filter: { ...filter,
          include: ['externalIntegration']
        }
      };
    })
  });

  _exports.default = _default;
});