define("iris/components/forms/file-form/account-select/component", ["exports", "iris/utils/properties"], function (_exports, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    searchTerm: '',
    setSearchTermTask: (0, _properties.debounceWithTask)('searchTerm'),
    searchCondition: Ember.computed('searchTerm', function () {
      let searchTerm = this.get('searchTerm') || '';

      if (Ember.isBlank(searchTerm)) {
        return null;
      }

      let terms = searchTerm.split(/\s+/);
      return {
        where: {
          and: [{
            enabled: 1
          }, {
            and: terms.map(term => ({
              longName: {
                ilike: `%${term}%`
              }
            }))
          }, {
            contactId: null
          }]
        }
      };
    })
  });

  _exports.default = _default;
});