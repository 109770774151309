define("iris/components/gantt-timeline/component", ["exports", "@kockpit/ember-gantt/components/gantt-timeline"], function (_exports, _ganttTimeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ganttTimeline.default.extend({
    resetSticky() {
      if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
        this.set('isSticky', false);
        this.set('stickyStyle', Ember.String.htmlSafe(''));
        this.set('stickyPlaceholderStyle', Ember.String.htmlSafe(''));
      }
    }

  });

  _exports.default = _default;
});