define("iris/templates/components/bs-accordion-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vWbpTBDt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",false],[12,\"role\",\"tab\"],[12,\"class\",[29,[\"panel-heading \",[28,\"if\",[[24,[\"collapsed\"]],\"collapsed\"],null]]]],[3,\"action\",[[23,0,[]],\"toggleActive\"]],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"panel-title\"],[8],[0,\"\\n        \"],[7,\"a\",true],[10,\"href\",\"#\"],[8],[0,\"\\n            \"],[1,[22,\"title\"],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"bs-collapse\",null,[[\"collapsed\",\"class\"],[[24,[\"collapsed\"]],\"panel-collapse\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n        \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/bs-accordion-item.hbs"
    }
  });

  _exports.default = _default;
});