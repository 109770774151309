define("iris/components/cmdb/filters/custom-attributes/value-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q6RHjain",
    "block": "{\"symbols\":[\"@value\",\"@onChange\",\"@type\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[23,3,[]],\"checkbox\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"toggle-switch\",[],[[\"@value\",\"@action\"],[[23,1,[]],[23,2,[]]]]],[0,\"\\n  \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,3,[]],\"datetime\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"bs-datetimepicker\",[],[[\"@date\",\"@updateDate\"],[[23,1,[]],[28,\"action\",[[23,0,[]],[28,\"queue\",[[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,1,[]]],null]],null],[23,2,[]]],null]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,3,[]],\"number\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"input\",[],[[\"@type\",\"@value\",\"@input\",\"@class\"],[\"number\",[23,1,[]],[23,2,[]],\"form-control\"]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"input\",[],[[\"@value\",\"@input\",\"@class\"],[[23,1,[]],[23,2,[]],\"form-control\"]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/cmdb/filters/custom-attributes/value-input/template.hbs"
    }
  });

  _exports.default = _default;
});