define("iris/helpers/service-level-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * time is in minute
   * 10000      => "06n 22ó 40p"
   * 1000       =>     "16ó 40p"
   * 1          =>     "00ó 01p"
   * 0          =>         "00p"
   * undefined  =>            ""
   */
  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(_ref) {
      let [time] = _ref;
      time = parseInt(time);

      if (time === 0) {
        return this.intl.t('time.minutes-abbrev', {
          minutes: padTime(0)
        });
      }

      if (!time || isNaN(time)) {
        return;
      }

      const days = Math.floor(time / 60 / 24);
      const hours = Math.floor(time / 60) % 24;
      const minutes = Math.floor(time) % 60;
      let daysStr = this.intl.t('time.days-abbrev', {
        days: padTime(days)
      });
      let hoursStr = this.intl.t('time.hours-abbrev', {
        hours: padTime(hours)
      });
      let minutesStr = this.intl.t('time.minutes-abbrev', {
        minutes: padTime(minutes)
      });

      if (days > 0) {
        return `${daysStr} ${hoursStr} ${minutesStr}`;
      }

      return `${hoursStr} ${minutesStr}`;
    }

  });

  _exports.default = _default;

  let padTime = (time, dhm) => String(time).padStart(2, '0');
});