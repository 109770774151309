define("iris/helpers/high-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.highLight = highLight;

  function highLight(params, hash) {
    let joiner = hash.joiner ? hash.joiner : ' / ';
    let fullText = params.join(joiner);
    let reg = new RegExp(hash.term, 'gi');
    fullText = fullText.replace(reg, function (str) {
      return '<b>' + str + '</b>';
    });
    return Ember.String.htmlSafe(fullText);
  }

  var _default = Ember.Helper.helper(highLight);

  _exports.default = _default;
});