define("iris/components/forms/aiops/contract/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gTZWKbH5",
    "block": "{\"symbols\":[\"tab\",\"@model\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"active\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"name\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"validFrom\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"validTo\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"contractAmount\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[23,2,[\"id\"]]],null,{\"statements\":[[0,\"  \"],[5,\"bs-tab\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"pane\"]],[],[[\"@icon\",\"@title\"],[\"fa fa-tasks\",[28,\"t\",[\"aiops.customer.services\"],null]]],{\"statements\":[[0,\"\\n      \"],[5,\"auto/form/one2-many\",[],[[\"@parentModel\",\"@itemName\",\"@relationFiled\",\"@modalSize\",\"@proportion\",\"@neededFields\"],[[23,2,[]],\"services\",\"contractId\",\"L\",\"2-10\",[28,\"array\",[\"operationServiceLevel\",\"severancePay\",\"dailySeverancePay\",\"failingSeverancePay\",\"severancePayCount\",\"failingSeverancePayCount\"],null]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/forms/aiops/contract/template.hbs"
    }
  });

  _exports.default = _default;
});