define("iris/templates/tasks/work-logs/work-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G5HXLPKo",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-tether\",null,[[\"to\",\"target\",\"targetModifier\",\"attachment\",\"on-overlay-click\",\"tetherClass\",\"overlayClass\"],[\"modal-dialog\",\"document.body\",\"visible\",\"middle center\",[28,\"route-action\",[\"back\",[24,[\"model\"]]],null],\"modal-dialog modal-md\",\"modal-backdrop\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n\\n    \"],[1,[28,\"modal-header\",null,[[\"title\"],[[28,\"t\",[\"tasks.main.edit-worksheet\"],null]]]],false],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n      \"],[1,[28,\"forms/work-log\",null,[[\"model\",\"disabled\",\"boundaryWorkLogs\"],[[24,[\"model\"]],[24,[\"disabled\"]],[24,[\"boundaryWorkLogs\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"disabled\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"iris-button\",null,[[\"text\",\"type\",\"action\",\"value\"],[[28,\"t\",[\"operation.solve\"],null],\"success\",[28,\"action\",[[23,0,[]],\"solveWorkLogTicket\"],null],[24,[\"model\"]]]]],false],[0,\"\\n        \"],[1,[28,\"buttons/save-button\",null,[[\"action\",\"value\"],[[28,\"route-action\",[\"save\",[28,\"hash\",null,[[\"redirectTo\"],[\"tasks.work-logs\"]]]],null],[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[1,[28,\"buttons/cancel-button\",null,[[\"action\",\"value\",\"text\"],[[28,\"route-action\",[\"back\"],null],[24,[\"model\"]],[28,\"t\",[\"buttons.close\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/tasks/work-logs/work-log.hbs"
    }
  });

  _exports.default = _default;
});