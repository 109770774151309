define("iris/utils/model", ["exports", "ember-data-copyable"], function (_exports, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateRecord = _exports.resetRecordAttrs = _exports.isCopyable = _exports.inspectValidationErrors = _exports.fixPresenceValidationsOnBelongsTo = void 0;

  const isCopyable = record => _emberDataCopyable.default.detect(record);

  _exports.isCopyable = isCopyable;

  const validateRecord = async record => {
    fixPresenceValidationsOnBelongsTo(record);
    let validationResult = await record.validate();

    if (!validationResult.validations.isValid) {
      // Leave this here, so that we can inspect records on production that fail
      // to validate for some reason.
      inspectValidationErrors(record);
    }

    return validationResult;
  };

  _exports.validateRecord = validateRecord;

  const inspectValidationErrors = record => {
    try {
      let errors = Object.keys(record.get('validations._validators')).reduce((acc, attr) => {
        acc[attr] = record.validations.attrs[attr].errors;
        return acc;
      }, {});
      console.warn('inspectValidationErrors: Validation failed for record:', record);
      console.warn('inspectValidationErrors: Errors:', errors);
    } catch (err) {
      console.error(err);
    }
  }; // https://github.com/offirgolan/ember-cp-validations/issues/651
  //
  // From the issue desription:
  //
  // > After I ran notifyPropertyChange on that property the validations get
  // > updated and works correctly.


  _exports.inspectValidationErrors = inspectValidationErrors;

  const fixPresenceValidationsOnBelongsTo = record => {
    try {
      Object.entries(record.validations._validators).forEach(_ref => {
        let [attr, validators] = _ref;

        for (let {
          _type,
          parentAttribute
        } of validators) {
          if (_type === 'presence') {
            if (parentAttribute) {
              record[parentAttribute].notifyPropertyChange(attr);
            } else {
              record.notifyPropertyChange(attr);
            }

            break;
          }
        }
      });
    } catch (err) {
      console.warn('Could not perform fixPresenceValidationsOnBelongsTo(). Perhaps not a validatable record?', record);
    }

    return record;
  };
  /**
   * WARNING: this sets all attributes to `null`, instead of their default values.
   * This is to maintain compatibility with the existing behavior this replaces.
   */


  _exports.fixPresenceValidationsOnBelongsTo = fixPresenceValidationsOnBelongsTo;

  const resetRecordAttrs = record => {
    let model = record.constructor;
    model.eachAttribute(attr => {
      record.set(attr, null);
    });
    model.eachRelationship((name, descriptor) => {
      let value = descriptor.kind === 'hasMany' ? Ember.A() : null;
      record.set(name, value);
    });
  };

  _exports.resetRecordAttrs = resetRecordAttrs;
});