define("iris/templates/components/entity-attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qZFz6d3J",
    "block": "{\"symbols\":[\"value\",\"value\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"sortedValues\",\"left\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"entity-attribute\",null,[[\"value\",\"entity\",\"relationObjects\",\"codeStorePath\"],[[23,2,[]],[24,[\"entity\"]],[24,[\"relationObjects\"]],[24,[\"codeStorePath\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"sortedValues\",\"right\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"entity-attribute\",null,[[\"value\",\"entity\",\"relationObjects\",\"codeStorePath\"],[[23,1,[]],[24,[\"entity\"]],[24,[\"relationObjects\"]],[24,[\"codeStorePath\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/entity-attributes.hbs"
    }
  });

  _exports.default = _default;
});