define("iris/controllers/tasks/availability-generate", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    featured: false,
    refresh: false,
    weekDatas: Ember.computed(function () {
      return Ember.A([{
        'week': {}
      }]);
    }),
    days: Ember.A(['H', 'K', 'Sze', 'Cs', 'P', 'Szo', 'V']),
    dates: Ember.A([]),
    weeks: Ember.computed('refresh', function () {
      let store = this.get('store');
      let region = this.get('sessionAccount.region');
      let where = {
        and: [{
          'regionId': region.id
        }, {
          'settingKey': 'defaultFile'
        }]
      };
      let weeks = Ember.A(),
          a = (0, _moment.default)().startOf('week').isoWeekday(1),
          b = (0, _moment.default)(a).add('days', 42),
          o = {},
          i = 1;

      for (var m = (0, _moment.default)(a); m.isBefore(b); m.add('days', 7)) {
        let mom = (0, _moment.default)(m);
        let week = mom.format('W');
        let start = mom.format('YYYY-MM-DD');
        let end = (0, _moment.default)(mom).add('days', 6).format('YYYY-MM-DD');
        o = {
          'id': i,
          'name': week + '. hét (' + start + ' - ' + end + ')',
          'value': mom
        };
        weeks.pushObject(o);

        if (i === 1) {
          store.query('regionalSetting', {
            filter: {
              where: where
            }
          }).then(setting => {
            if (setting.get('length')) {
              setting.forEach(s => {
                let defaultFileId = s.get('settingValue');
                store.findRecord('project', defaultFileId).then(defaultFile => {
                  this.set('weekDatas', [{
                    'week': o,
                    'file': defaultFile,
                    'days': [{
                      'primary': null,
                      'secondary': null
                    }, {
                      'primary': null,
                      'secondary': null
                    }, {
                      'primary': null,
                      'secondary': null
                    }, {
                      'primary': null,
                      'secondary': null
                    }, {
                      'primary': null,
                      'secondary': null
                    }, {
                      'primary': null,
                      'secondary': null
                    }, {
                      'primary': null,
                      'secondary': null
                    }],
                    'serviceArea': null
                  }]);
                });
              });
            }
          });
        }

        i++;
      }

      return weeks;
    }),
    _copyArray: function (arr1) {
      let arr2 = [];
      arr1.forEach(arr => {
        arr2.push({
          'primary': arr.primary,
          'secondary': arr.secondary
        });
      });
      return arr2;
    },
    actions: {
      setFeatured(value) {
        this.set('featured', value);
      },

      addPanel(weekDatas) {
        let last = weekDatas.get('lastObject');
        let mom = (0, _moment.default)(last.week.value).add('days', 7);
        let week = mom.format('W');
        let start = mom.format('YYYY-MM-DD');
        let end = (0, _moment.default)(mom).add('days', 6).format('YYYY-MM-DD');
        let o = {
          'id': last.week.id + 1,
          'name': week + '. hét (' + start + ' - ' + end + ')',
          'value': mom
        };

        let days = this._copyArray(last.days);

        weekDatas.pushObject({
          'week': o,
          'days': days,
          'file': last.file,
          'serviceArea': last.serviceArea
        });
      },

      removePanel(weekDatas, index) {
        weekDatas.removeAt(index);
      },

      addDate() {
        this.get('dates').pushObject({
          'startDate': this.get('startDate'),
          'endDate': this.get('endDate')
        });
        this.set('startDate', null);
        this.set('endDate', null);
      },

      removeDate(index) {
        this.get('dates').removeAt(index);
      },

      generate() {
        let store = this.get('store');
        const flashMessages = Ember.get(this, 'flashMessages');
        let BreakException = {};

        if (this.featured) {
          this.get('dates').forEach(date => {
            store.createRecord('availability', {
              type: 'date',
              businessService: this.get('businessService'),
              startDate: date.startDate,
              endDate: date.endDate,
              file: this.get('file'),
              servicePerson1: this.get('primary'),
              servicePerson2: this.get('secondary')
            }).save(true);
            this.transitionToRoute('tasks.availabilities');
          });
        } else {
          let records = Ember.A();
          let err = false;

          try {
            this.weekDatas.forEach(data => {
              if (!data.serviceArea) {
                flashMessages.add({
                  message: this.intl.t('availability-generate.serviceAreaError'),
                  title: 'IRIS message',
                  icon: 'info'
                });
                throw BreakException;
                err = true;
              }

              let mom = (0, _moment.default)(data.week.value);
              let date = null;
              let o;
              data.days.forEach(day => {
                date = mom.toDate();

                if (day.primary) {
                  o = {
                    type: 'weekly',
                    serviceArea: data.serviceArea,
                    startDate: date,
                    endDate: date,
                    file: data.file,
                    servicePerson1: day.primary,
                    servicePerson2: day.secondary
                  };
                  let record = store.createRecord('availability', o);
                  records.pushObject(record);
                }

                mom.add('days', 1);
              });
            });

            if (!err) {
              records.invoke('save', true);
              this.transitionToRoute('tasks.availabilities');
              this.toggleProperty('refresh');
            }
          } catch (e) {
            if (e !== BreakException) throw e;
          }
        }
      }

    }
  });

  _exports.default = _default;
});