define("iris/templates/settings/operations/service-periods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n7iEdVIj",
    "block": "{\"symbols\":[\"f\",\"interval\"],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@defaultOrder\",\"@route\"],[\"service-period\",\"createDate DESC\",\"settings.operations.service-periods.edit\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"servicePeriodIntervals\"],null]],null,{\"statements\":[[4,\"each\",[[28,\"sort-by\",[\"start\",[23,1,[\"row\",\"servicePeriodIntervals\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"badge badge-success\"],[8],[0,\"\\n        \"],[1,[23,2,[\"start\"]],false],[0,\"\\n        -\\n        \"],[1,[23,2,[\"end\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/operations/service-periods.hbs"
    }
  });

  _exports.default = _default;
});