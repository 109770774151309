define("iris/components/modals/proposal-item-change-exchange-rate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MgtgEcJ+",
    "block": "{\"symbols\":[\"@data\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-content mx-auto\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"file-proposal-item.changeExchangeRate\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n    \"],[5,\"bs-form\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"exchangeRate\",[28,\"t\",[\"file-proposal-item.exchangeRate\"],null]]],{\"statements\":[[0,\"\\n        \"],[5,\"one-way-number-mask\",[],[[\"@decimal\",\"@options\",\"@update\",\"@value\"],[true,[28,\"hash\",null,[[\"groupSeparator\",\"allowMinus\",\"digits\",\"max\"],[\" \",false,\"2\",[23,0,[\"max\"]]]]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,1,[\"exchangeRate\"]]],null]],null],[23,1,[\"exchangeRate\"]]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"primary\",[28,\"action\",[[23,0,[]],\"changeExchangeRate\"],null],[28,\"t\",[\"general.select\"],null]]]],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/modals/proposal-item-change-exchange-rate/template.hbs"
    }
  });

  _exports.default = _default;
});