define("iris/components/forms/contact-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    partners: Ember.computed(function () {
      return this.get('store').findAll('partner');
    })
  });

  _exports.default = _default;
});