define("iris/templates/components/panel-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JKNyaGEG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"header\",true],[10,\"class\",\"panel-heading\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"h2 panel-title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"panel-subtitle\"],[8],[1,[22,\"subtitle\"],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"footer\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[\"body\"]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"footer\"]]],null,{\"statements\":[[0,\"  \"],[7,\"footer\",true],[10,\"class\",\"panel-footer text-right\"],[8],[0,\"\\n    \"],[14,1,[\"footer\"]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/panel-section.hbs"
    }
  });

  _exports.default = _default;
});