define("iris/components/beta/table/checkbox-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ux+PXSEV",
    "block": "{\"symbols\":[\"@mixed\",\"@checked\",\"&attrs\",\"@onClick\"],\"statements\":[[7,\"span\",false],[12,\"role\",\"checkbox\"],[12,\"aria-checked\",[28,\"if\",[[23,2,[]],\"checked\",[28,\"if\",[[23,1,[]],\"mixed\",\"false\"],null]],null]],[12,\"class\",[29,[\"\\n    iris--checkbox-button\\n    \",[28,\"if\",[[23,2,[]],\"--checked\",[28,\"if\",[[23,1,[]],\"--mixed\"],null]],null],\"\\n  \"]]],[13,3],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[23,4,[]]]],[8],[9],[0,\"\\n  \"],[7,\"span\",true],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/beta/table/checkbox-button/template.hbs"
    }
  });

  _exports.default = _default;
});