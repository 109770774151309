define("iris/helpers/number-to-human-size", ["exports", "ember-number-to-human-size/helpers/number-to-human-size"], function (_exports, _numberToHumanSize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _numberToHumanSize.default;
    }
  });
  Object.defineProperty(_exports, "numberToHumanSize", {
    enumerable: true,
    get: function () {
      return _numberToHumanSize.numberToHumanSize;
    }
  });
});