define("iris/utils/listen-to-cp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * CP macro that listens to dependent (external) property, but allows overriding it locally without violating DDAU
   * By using a simple setter it will still trigger on changes of the dependent property even when being set before.
   *
   * Kudos to @fsmanuel for coming up with this solution.
   *
   * @method
   * @return {boolean}
   * @param {string} dependentKey
   * @param {*} defaultValue
   * @private
   */
  function _default(dependentKey) {
    let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    return Ember.computed(dependentKey, {
      get() {
        return Ember.get(this, dependentKey) === undefined ? defaultValue : Ember.get(this, dependentKey);
      },

      set(key, value) {
        // eslint-disable-line no-unused-vars
        return value;
      }

    });
  }
});