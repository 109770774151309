define("iris/components/tandm/info/time-line/item/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TandmTimelineItem = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('item.info.userGrades.tandmUserGradeCost.cost'), _dec3 = Ember.computed('item.sourceType'), _dec4 = Ember._action, (_class = class TandmTimelineItem extends Ember.Component {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "cost", _descriptor2, this);
    }

    get icon() {
      const sourceType = this.item.get('sourceType');
      const verb = this.item.get('verb');

      if (verb === 'CREDIT') {
        return 'credit-card';
      }

      if (sourceType === 'Ticket') {
        return 'tag';
      }

      if (sourceType === 'ProjectTicket') {
        return 'list';
      }

      if (sourceType === 'TandmOperationServiceLevelAssoc') {
        return 'list';
      }

      if (sourceType === 'OvertimeProposal') {
        return 'list';
      }

      if (sourceType === 'CalledHoursProposal') {
        return 'list';
      }

      if (sourceType === 'SpecifiedAmountProposal') {
        return 'list';
      }

      if (sourceType === 'Tandm') {
        return 'file';
      }

      if (sourceType === 'WorkLog') {
        return 'briefcase';
      }
    }

    processInfo() {
      const sourceType = this.item.get('sourceType');
      const info = this.item.get('info');

      if (sourceType === 'Tandm') {
        const res = Object.keys(info).map(k => {
          let from = info[k].from;
          let to = info[k].to;

          if (['dateStart', 'dateEnd'].includes(k)) {
            from = (0, _moment.default)(from).format('L');
            to = (0, _moment.default)(to).format('L');
          }

          if (k === 'state') {
            from = from.name;
            to = to.name;
          }

          return {
            from,
            to,
            property: this.intl.t(`tandm.${k}`)
          };
        });
        return res;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "cost", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "icon", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "icon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "processInfo", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "processInfo"), _class.prototype)), _class));
  _exports.default = TandmTimelineItem;
});