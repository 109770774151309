define("iris/routes/operation/portal-dashboard/closed-reports", ["exports", "iris/routes/operation/portal-dashboard"], function (_exports, _portalDashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect() {
      let {
        queryParams
      } = _portalDashboard.tabs[3];
      this.transitionTo('operation.portal-dashboard', {
        // Apparently the router mutates the `queryParams` object? O_O
        queryParams: JSON.parse(JSON.stringify(queryParams))
      });
    }

  });

  _exports.default = _default;
});