define("iris/components/tables/entity-licensed-entity/add-entity-license/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qF5kit0+",
    "block": "{\"symbols\":[\"@data\",\"@close\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-content mx-auto\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"contact.event-log\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-body bg-white\"],[8],[0,\"\\n    \"],[5,\"tables/entity-licensed-entity/grid\",[],[[\"@defaultWhere\",\"@limit\",\"@bulkActions\"],[[28,\"hash\",null,[[\"and\"],[[28,\"array\",[[28,\"hash\",null,[[\"entityTypeId\"],[\"All\"]]],[28,\"hash\",null,[[\"id\"],[[28,\"hash\",null,[[\"nin\"],[[23,1,[\"licensedEntitiyIds\"]]]]]]]],[28,\"hash\",null,[[\"partnerId\"],[[23,1,[\"model\",\"partner\",\"id\"]]]]]],null]]]],10,[23,0,[\"bulkActions\"]]]]],[0,\"\\n\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n    \"],[5,\"bs-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"primary\",[28,\"fn\",[[23,2,[]],[23,0,[\"bulkActions\",\"selection\"]]],null],[28,\"t\",[\"tables.choose\"],null]]]],[0,\"\\n    \"],[5,\"bs-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"default\",[23,2,[]],[28,\"t\",[\"general.close\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"  \\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/tables/entity-licensed-entity/add-entity-license/template.hbs"
    }
  });

  _exports.default = _default;
});