define("iris/helpers/parent-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.parentRoute = parentRoute;

  function parentRoute(params
  /*, hash*/
  ) {
    let [route, back] = params;
    back = back ? back * -1 : -1;
    return route.split('.').slice(0, back).join('.');
  }

  var _default = Ember.Helper.helper(parentRoute);

  _exports.default = _default;
});