define("iris/templates/components/forms/settings-sector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Iw2hHeFJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-form\",null,[[\"model\",\"inline\"],[[24,[\"model\"]],true]],{\"statements\":[[7,\"div\",true],[10,\"class\",\"--iris-inline\"],[8],[0,\"\\n\"],[4,\"form-group\",null,[[\"property\",\"label\"],[\"name\",[28,\"t\",[\"general.name\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"name\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"--iris-inline\"],[8],[0,\"\\n\"],[4,\"form-group\",null,[[\"class\",\"property\",\"label\"],[\"checkbox\",\"polity\",[28,\"t\",[\"crm.main.state\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-checkbox\",null,[[\"value\"],[[24,[\"model\",\"polity\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/settings-sector.hbs"
    }
  });

  _exports.default = _default;
});