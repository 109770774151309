define("iris/utils/dependencies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loadXLSXModule = void 0;

  const loadXLSXModule = async () => {
    let xlsxModule = await emberAutoImportDynamic("js-xlsx");
    return xlsxModule.default;
  };

  _exports.loadXLSXModule = loadXLSXModule;
});