define("iris/components/beta/filters/add/ui/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "awa8Nnl/",
    "block": "{\"symbols\":[\"@data\",\"@acts\",\"&attrs\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"w-16 h-16 flex relative\"],[13,3],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",[29,[\"\\n      py-2 w-16 h-16 flex items-center justify-center\\n      rounded-sm border dark:border-transparent\\n      hover:border-gray-300 hover:bg-gray-50\\n      dark:text-gray-200 dark:hover:text-steel-300\\n      dark:hover:bg-gray-700 dark:hover:border-gray-600\\n      \",[28,\"if\",[[23,0,[\"isSelecting\"]],\"border border-gray-300 bg-gray-50\\n        dark:text-steel-300 dark:bg-gray-700 dark:border-gray-600\"],null],\"\\n    \"]]],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[28,\"fn\",[[28,\"set\",[[23,0,[]],\"isSelecting\",true],null]],null]]],[8],[0,\"\\n    \"],[1,[28,\"fa-icon\",[\"plus\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"isSelecting\"]]],null,{\"statements\":[[0,\"    \"],[5,\"beta/filters/add/ui/select\",[[12,\"class\",\"absolute inset-0\"]],[[\"@data\",\"@acts\",\"@onClose\",\"@triggerClass\"],[[23,1,[]],[23,2,[]],[28,\"action\",[[23,0,[]],[28,\"set\",[[23,0,[]],\"isSelecting\",false],null]],null],\"invisible h-16\"]]],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/beta/filters/add/ui/template.hbs"
    }
  });

  _exports.default = _default;
});