define("iris/initializers/clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(app) {
    app.inject('controller', 'clock', 'service:clock');
    app.inject('component', 'clock', 'service:clock');
  }

  var _default = {
    name: 'clock',
    initialize
  };
  _exports.default = _default;
});