define("iris/components/project/file-balance-opportunity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    productResultsAmountArray: Ember.computed.mapBy('model.productResults', 'amount'),
    productResultsAmount: Ember.computed.sum('productResultsAmountArray'),
    outgoingProposalsAmountArray: Ember.computed.mapBy('model.validOutgoingProposal', 'amountUntaxed'),
    outgoingProposalsAmount: Ember.computed.sum('outgoingProposalsAmountArray'),
    toOpen: {
      'productResult': Ember.A(),
      'proposal': Ember.A()
    },
    actions: {
      openRow: function (record, which) {
        let toOpen = this.get('toOpen');

        if (toOpen[which].contains(record)) {
          toOpen[which].removeObject(record);
        } else {
          toOpen[which].addObject(record);
        }
      }
    }
  });

  _exports.default = _default;
});