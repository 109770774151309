define("iris/templates/cmdb/impact-study", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xyl9Q8Ij",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[10,\"class\",\"h1\"],[8],[0,\"\\n  \"],[1,[23,0,[\"model\",\"name\"]],false],[0,\"\\n  \"],[5,\"iris-button\",[],[[\"@action\",\"@text\",\"@type\"],[[28,\"route-action\",[\"historyBack\"],null],[28,\"t\",[\"general.back\"],null],\"primary\"]]],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[8],[1,[23,0,[\"model\",\"entityType\",\"name\"]],false],[0,\"\\n  \"],[1,[28,\"t\",[\"cmdb.visualisation.visualisation\"],null],false],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"style\",\"height: 70vh\"],[8],[0,\"\\n  \"],[5,\"cmdb-visualisation\",[],[[\"@model\"],[[23,0,[\"model\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/impact-study.hbs"
    }
  });

  _exports.default = _default;
});