define("iris/components/modals/completion-certificate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "36qdhG90",
    "block": "{\"symbols\":[\"@data\",\"@close\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-content mx-auto\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"finances.tig-generation\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n    \"],[5,\"project/completion-certificate\",[],[[\"@model\",\"@alreadyRead\"],[[23,1,[\"model\"]],[23,0,[\"alreadyRead\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\",\"@disabled\"],[\"primary\",[28,\"action\",[[23,0,[]],\"generateCompletionCertificatePDF\",[23,1,[\"model\"]]],null],[28,\"t\",[\"buttons.pdf\"],null],[28,\"not\",[[24,[\"alreadyRead\"]]],null]]]],[0,\"\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"default\",[28,\"fn\",[[23,2,[]],false],null],[28,\"or\",[[23,1,[\"cancelButtonLabel\"]],[28,\"t\",[\"buttons.close\"],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/modals/completion-certificate/template.hbs"
    }
  });

  _exports.default = _default;
});