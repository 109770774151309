define("iris/components/forms/settings-cmdb-entity-type-attribute", ["exports", "pro-slugs", "ember-concurrency"], function (_exports, _proSlugs, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.get('loadEntityTypes').perform();
    },

    entityTypes: null,
    loadEntityTypes: (0, _emberConcurrency.task)(function* () {
      let entityTypes = yield this.store.query('entityType', {
        filter: {
          where: {
            template: 0
          },
          order: 'longName'
        }
      });
      let entityTypeOptions = Ember.A();
      let codeStores = entityTypes.filter(et => {
        return et.get('codeStore') === 1;
      }).sortBy('longName');
      let normalEntityType = entityTypes.filter(et => {
        return et.get('codeStore') === 0;
      }).sortBy('longName');
      entityTypeOptions.pushObject({
        groupName: this.intl.t('code-store.models'),
        options: codeStores
      });
      entityTypeOptions.pushObject({
        groupName: this.intl.t('entity-type.modelName'),
        options: normalEntityType
      });
      this.set('types', entityTypeOptions);
    }),
    attributeTypes: Ember.computed(function () {
      return [{
        id: 'string',
        name: this.intl.t('entity-attribute.types.string')
      }, {
        id: 'textarea',
        name: this.intl.t('entity-attribute.types.text')
      }, {
        id: 'number',
        name: this.intl.t('entity-attribute.types.number')
      }, {
        id: 'datetime',
        name: this.intl.t('entity-attribute.types.dateTime')
      }, {
        id: 'date',
        name: this.intl.t('entity-attribute.types.date')
      }, {
        id: 'belongsTo',
        name: this.intl.t('entity-attribute.types.belongsTo')
      }, {
        id: 'hasMany',
        name: this.intl.t('entity-attribute.types.hasMany')
      }, {
        id: 'checkbox',
        name: this.intl.t('entity-attribute.types.checkbox')
      }, {
        id: 'array',
        name: this.intl.t('entity-attribute.types.array')
      }];
    }),
    type: Ember.computed('model.type', 'attributeTypes', {
      get(key) {
        let attributeTypes = this.get('attributeTypes');
        let type = this.get('model.type');
        return attributeTypes.filter(function (element) {
          return element.id === type;
        })[0];
      },

      set(key, value) {
        if (value) {
          Ember.run.next(() => {
            if (!this.get('isDestroyed') && !this.get('isDestroying')) {
              // TODO: backtracking-rerender
              this.set('model.type', value.id);
            }
          });
        } else {
          Ember.run.next(() => {
            if (!this.get('isDestroyed') && !this.get('isDestroying')) {
              // TODO: backtracking-rerender
              this.set('model.type', null);
            }
          });
        }

        return value;
      }

    }),
    actions: {
      changeName: function () {
        let model = this.get('model');
        let name = this.get('model.name');
        let property = this.get('model.property');

        if (Ember.isEmpty(property)) {
          model.set('property', (0, _proSlugs.default)(name).replace(RegExp('-', 'g'), '_'));
        }
      }
    }
  });

  _exports.default = _default;
});