define("iris/templates/components/bs-modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aSQMRUWg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"modal-dialog \",[22,\"sizeClass\"]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"header\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"bs-modal-header\",null,[[\"title\",\"closeButton\"],[[24,[\"title\"]],[24,[\"closeButton\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"body\"]]],null,{\"statements\":[[4,\"bs-modal-body\",null,null,{\"statements\":[[0,\"                \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"footer\"]]],null,{\"statements\":[[0,\"            \"],[1,[22,\"bs-modal-footer\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/bs-modal-dialog.hbs"
    }
  });

  _exports.default = _default;
});