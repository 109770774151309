define("iris/templates/organizations/sites/floors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IOffPI9N",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\"],[\"floor\",\"organizations.sites.floors.edit\",[28,\"array\",[\"name\",\"clientPartner\",\"address.town\",\"address.address\",\"building\"],null]]]],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/organizations/sites/floors.hbs"
    }
  });

  _exports.default = _default;
});