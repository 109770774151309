define("iris/models/project-view", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { belongsTo, hasMany } from 'ember-data/relationships';
  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    partner: (0, _attr.default)('string'),
    stateOutgoingProposal: (0, _attr.default)('number'),
    typeOutgoingProposal: (0, _attr.default)('number'),
    stateProposal: (0, _attr.default)('number'),
    typeProposal: (0, _attr.default)('number'),
    stateOrderOk: (0, _attr.default)('number'),
    typeOrderOk: (0, _attr.default)('number'),
    stateContract: (0, _attr.default)('number'),
    typeContract: (0, _attr.default)('number'),
    stateName: (0, _attr.default)('string'),
    stateId: (0, _attr.default)('string'),
    closed: (0, _attr.default)('number'),
    planPeriodCloseDate: (0, _attr.default)('date'),
    mustBePlanned: (0, _attr.default)('number'),
    completionLevel: (0, _attr.default)('number'),
    investmentActual: (0, _attr.default)('number'),
    investmentPlanned: (0, _attr.default)('number'),
    closedTickets: (0, _attr.default)('number'),
    ticketsInProgress: (0, _attr.default)('number'),
    dueTickets: (0, _attr.default)('number'),
    expiredTickets: (0, _attr.default)('number'),
    orderedOutgoingProposalsCount: (0, _attr.default)('number'),
    outgoingProposalsCount: (0, _attr.default)('number'),
    proposalsCount: (0, _attr.default)('number'),
    polity: (0, _attr.default)('number'),
    onTime: (0, _attr.default)('string'),
    onBudgetInHour: (0, _attr.default)('string'),
    onBudgetInValue: (0, _attr.default)('string'),
    crVersion: (0, _attr.default)('number'),
    originalFileId: (0, _attr.default)('string'),
    approvalDate: (0, _attr.default)('date'),
    deletedAt: (0, _attr.default)('date'),
    live: Ember.computed('crVersion', function () {
      return this.get('crVersion') !== -1;
    })
  });

  _exports.default = _default;
});