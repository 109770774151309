define("iris/controllers/cmdb/procedures/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    defaultFilters: {
      'entity.monitoringStartDate': {
        neq: null
      }
    },
    actions: {
      edit(entityId) {
        this.set('actionSync', true);
        this.set('editedEntities', [entityId]);
      },

      cancelSync() {
        this.set('actionSync', false);
      }

    }
  });

  _exports.default = _default;
});