define("iris/routes/settings/document-types", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model() {
      const store = this.get('store');
      return Ember.RSVP.hash({
        documentTypes: store.findAll('documentType'),
        competencies: store.query('competency', {
          filter: {
            where: {
              builtIn: {
                neq: 3
              }
            }
          }
        })
      });
    },

    intl: Ember.inject.service(),
    rowActions: Ember.computed(function () {
      return [{
        name: this.intl.t('document-type.competencies'),
        icon: 'group',
        style: 'danger',
        action: record => {
          this.send('openAccessModal', record);
        }
      }];
    }),

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('rowActions', this.rowActions);
    },

    actions: {
      closeAccessModal() {
        const controller = this.get('controller');
        controller.set('accessModal', false);
        controller.set('selectedDocumentType', null);
      },

      openAccessModal(documentType) {
        const controller = this.get('controller');
        controller.set('accessModal', true);
        controller.set('selectedDocumentType', documentType);
      }

    }
  });

  _exports.default = _default;
});