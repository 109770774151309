define("iris/routes/crm/contracts/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function (params) {
      var store = this.store;
      return Ember.RSVP.hash({
        contract: store.find('contract', params.contract_id),
        partners: store.query('partner', {
          filter: {
            where: {
              or: [{
                customer: 1
              }, {
                subcontractor: 1
              }]
            },
            order: 'name'
          }
        }),
        contracts: store.findAll('contract'),
        users: store.query('account', {
          filter: {
            where: {
              enabled: 1
            }
          }
        }),
        currencies: store.findAll('currency')
      });
    },
    actions: {
      willTransition: function (transition) {
        let record = this.controller.get('model.contract');
        record.rollback();
        record.get('contractUserGradeCosts').then(contractUserGradeCosts => {
          contractUserGradeCosts.invoke('rollback');
        });
      }
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      this.controller.set('tabs', this.get('tabs'));
      /*ACL*/

      let contract = this.controllerFor('crm.contracts.edit').get('model.contract');
      let disableInput = !this.can('read action', {
        obj: contract,
        canActions: ['crm.contracts', 'crm.contracts.write']
      });
      this.controller.set('disableInput', disableInput);
    }
  });

  _exports.default = _default;
});