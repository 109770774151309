define("iris/templates/components/power-select-multiple-shortened", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mxqWgBBT",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"power-select-multiple-shortened-container\"],[8],[0,\"\\n\"],[4,\"iris-power-select-multiple\",null,[[\"options\",\"placeholder\",\"searchField\",\"selected\",\"onchange\"],[[28,\"sort-by\",[\"text\",[24,[\"cuttedOptions\"]]],null],[24,[\"placeholder\"]],[24,[\"searchField\"]],[28,\"sort-by\",[\"text\",[24,[\"currentSelection\"]]],null],[28,\"action\",[[23,0,[]],\"currentOnChange\",[24,[\"columnFilter\"]]],null]]],{\"statements\":[[0,\"    \"],[1,[23,1,[\"text\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[4,\"if\",[[24,[\"needShorting\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",[29,[\"glyphicon open-btn glyphicon\",[28,\"if\",[[24,[\"shortedOpened\"]],\"-minus\",\"-plus\"],null]]]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"toggleShortedOpened\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/power-select-multiple-shortened.hbs"
    }
  });

  _exports.default = _default;
});