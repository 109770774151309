define("iris/routes/crm/contracts/new/dates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let hash = this.modelFor('crm.contracts.new');
      return hash.contract;
    }

  });

  _exports.default = _default;
});