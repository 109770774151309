define("iris/components/cmdb-visualisation2/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u3tWH+Vl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"holder\"],[8],[0,\"\\n\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"selectedEntity\"]]],null,{\"statements\":[[0,\"  \"],[5,\"cmdb-visualisation2/info-panel\",[],[[\"@attribureValues\",\"@selectedEntity\",\"@onClose\"],[[23,0,[\"attribureValues\"]],[23,0,[\"selectedEntity\"]],[28,\"action\",[[23,0,[]],[23,0,[\"infoPanelClose\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/cmdb-visualisation2/template.hbs"
    }
  });

  _exports.default = _default;
});