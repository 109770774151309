define("iris/models/entity-procedure-reminder", ["exports", "ember-data", "ember-data/attr", "ember-data/relationships"], function (_exports, _emberData, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    entityIds: (0, _attr.default)('string'),
    user: (0, _relationships.belongsTo)('account')
  });

  _exports.default = _default;
});