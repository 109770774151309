define("iris/components/sl-time-badge/tooltip/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const layout = Ember.HTMLBars.template({
    "id": "+V8KD6jb",
    "block": "{\"symbols\":[\"@text\"],\"statements\":[[0,\"\\n  \"],[5,\"ember-tooltip\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"block px-4 py-2\"],[10,\"data-test-sl-time-badge-tooltip\",\"\"],[8],[0,\"\\n      \"],[1,[23,1,[]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {}
  });

  var _default = Ember.Component.extend({
    tagName: '',
    layout
  });

  _exports.default = _default;
});