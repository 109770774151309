define("iris/templates/components/action-competency-contract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vccUDjW9",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"iris-power-select-multiple\",null,[[\"disabled\",\"options\",\"selected\",\"onchange\"],[[24,[\"disableInput\"]],[24,[\"competencies\"]],[24,[\"selected\"]],[28,\"route-action\",[\"competencies\",[24,[\"action\"]],[24,[\"contract\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/action-competency-contract.hbs"
    }
  });

  _exports.default = _default;
});