define("iris/components/cmdb/filters/custom-attributes/component", ["exports", "ember-concurrency", "iris/classes/filter/filter", "iris/utils/ember-concurrency"], function (_exports, _emberConcurrency, _filter, _emberConcurrency2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    // If `filters` comes as a component argument, it must be at least an empty
    // array, otherwise it'll be initialized to be an internal property.
    filters: null,
    // EmberArray<EntityAttributeFilter> | null
    onSubmit: null,
    // () => void
    onChange: null,

    // (filters: EmberArray<EntityAttributeFilter>) => void
    init() {
      this._super(...arguments);

      if (!this.filters) {
        this.set('filters', Ember.A());
      }
    },

    searchEntityAttibutes: (0, _emberConcurrency.task)(function* (q) {
      yield (0, _emberConcurrency2.timeout)(500);
      let entityAttributes = yield this.get('ajax').call('GET', 'entityAttribute', null, 'getDistinctEntityAttributes', {
        filter: {
          q
        }
      });
      return Ember.A(entityAttributes.result.data).map(ea => Ember.Object.create(ea));
    }).restartable(),
    canAddFilter: Ember.computed('filters.@each.isValid', function () {
      return this.get('filters').filter(f => !f.get('isValid')).length === 0;
    }),
    actions: {
      addAttributeFilter() {
        let owner = Ember.getOwner(this);

        let filter = _filter.default.create();

        Ember.setOwner(filter, owner);
        this.filters.pushObject(filter);
      },

      removeAttributeFilter(filter) {
        this.filters.removeObject(filter);
      },

      setEntityAttribute(filter, entityAttribute) {
        filter.setEntityAttribute(entityAttribute);
      },

      pushCustomAttributes() {
        this.onChange && this.onChange(this.filters);
      },

      submit(event) {
        event.preventDefault();
        this.get('onSubmit') && this.get('onSubmit')();
      }

    },

    serializeFilters() {
      return this.filters.map(f => f.serialize()).filter(Boolean);
    },

    attributeTypeName: Ember.computed(function () {
      return {
        [_filter.AttributeType.String]: this.intl.t('filters.attributeTypes.string'),
        [_filter.AttributeType.Number]: this.intl.t('filters.attributeTypes.number'),
        [_filter.AttributeType.Checkbox]: this.intl.t('filters.attributeTypes.checkbox'),
        [_filter.AttributeType.Date]: this.intl.t('filters.attributeTypes.date'),
        [_filter.AttributeType.Array]: this.intl.t('filters.attributeTypes.array'),
        [_filter.AttributeType.BelongsTo]: this.intl.t('filters.attributeTypes.belongsTo'),
        [_filter.AttributeType.HasMany]: this.intl.t('filters.attributeTypes.hasMany')
      };
    })
  });

  _exports.default = _default;
});