define("iris/controllers/reports/inventories/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    contactFilter: Ember.computed('model.inventory.partners.[]', 'model.inventory.departments.[]', function () {
      const partnerIds = this.model.inventory.hasMany('partners').ids();
      const departmentIds = this.model.inventory.hasMany('departments').ids();
      const where = {};

      if (partnerIds.length) {
        where.partnerId = {
          inq: partnerIds
        };
      }

      if (departmentIds.length) {
        where.departmentId = {
          inq: departmentIds
        };
      }

      return where;
    }),
    buildingFilter: Ember.computed('model.inventory.partners.[]', 'model.inventory.addresses.[]', function () {
      const partnerIds = this.model.inventory.hasMany('partners').ids();
      const addressIds = this.model.inventory.hasMany('addresses').ids();
      const where = {};

      if (partnerIds.length) {
        where.partnerId = {
          inq: partnerIds
        };
      }

      if (addressIds.length) {
        where.addressId = {
          inq: addressIds
        };
      }

      return where;
    }),
    floorFilter: Ember.computed('model.inventory.partners.[]', 'model.inventory.addresses.[]', 'model.inventory.buildings.[]', function () {
      const partnerIds = this.model.inventory.hasMany('partners').ids();
      const addressIds = this.model.inventory.hasMany('addresses').ids();
      const buildingIds = this.model.inventory.hasMany('buildings').ids();
      const where = {};

      if (partnerIds.length) {
        where.partnerId = {
          inq: partnerIds
        };
      }

      if (addressIds.length) {
        where.addressId = {
          inq: addressIds
        };
      }

      if (buildingIds.length) {
        where.buildingId = {
          inq: buildingIds
        };
      }

      return where;
    }),
    actions: {
      setPartners(partners) {
        this.model.inventory.setProperties({
          'partners': partners,
          'departments': [],
          'contacts': [],
          'addresses': [],
          'buildings': [],
          'floors': []
        });
      },

      setDepartments(departments) {
        this.model.inventory.setProperties({
          'departments': departments,
          'contacts': []
        });
      },

      setAddresses(addresses) {
        this.model.inventory.setProperties({
          'addresses': addresses,
          'buildings': [],
          'floors': []
        });
      },

      setBuildings(buildings) {
        this.model.inventory.setProperties({
          'buildings': buildings,
          'floors': []
        });
      }

    }
  });

  _exports.default = _default;
});