define("iris/helpers/fr-markdown-file", ["exports", "ember-fr-markdown-file/helpers/fr-markdown-file"], function (_exports, _frMarkdownFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _frMarkdownFile.default;
    }
  });
  Object.defineProperty(_exports, "frMarkdownFile", {
    enumerable: true,
    get: function () {
      return _frMarkdownFile.frMarkdownFile;
    }
  });
});