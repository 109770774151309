define("iris/services/query-params", ["exports", "ember-parachute", "iris/lib/filters/storage"], function (_exports, _emberParachute, _storage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeResetQueryParamsRouteMixin = _exports.makeFilterQueryParams = _exports.makeControllerMixin = _exports.default = void 0;

  // Eagerly waiting for this RFC getting finished & backported to 3.4
  // https://github.com/nullvoxpopuli/rfcs/blob/update-query-param-docs/text/0712-query-params-as-derived-data.md
  var _default = Ember.Service.extend({
    router: Ember.inject.service(),
    currentController: Ember.computed('router.currentRouteName', function () {
      return Ember.getOwner(this).lookup(`controller:${this.router.currentRouteName}`);
    }),
    params: Ember.computed.alias('currentController.allQueryParams'),

    update(param, value) {
      this.router.transitionTo(this.router.currentRouteName, {
        queryParams: {
          [param]: value
        }
      });
    },

    replace(param, value) {
      this.router.replaceWith(this.router.currentRouteName, {
        queryParams: {
          [param]: value
        }
      });
    },

    reset(params) {
      this.currentController.resetQueryParams(params);
    },

    refresh() {
      this.notifyPropertyChange('params');
    }

  });

  _exports.default = _default;

  const makeFilterQueryParams = function () {
    let queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let defaults = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return new _emberParachute.default({
      page: {
        defaultValue: defaultValueForKey(defaults, 'page', _storage.DEFAULT_PAGE)
      },
      limit: {
        defaultValue: defaultValueForKey(defaults, 'limit', _storage.DEFAULT_LIMIT)
      },
      order: {
        defaultValue: defaultValueForKey(defaults, 'order', _storage.DEFAULT_ORDER),

        serialize(_ref) {
          let {
            property,
            direction
          } = _ref;
          return `${property}--${direction}`;
        },

        deserialize(value) {
          let [property, direction] = value.split('--');
          return {
            property,
            direction
          };
        }

      },
      q: {
        defaultValue: defaultValueForKey(defaults, 'q', _storage.DEFAULT_FUZZY_SEARCH_KEYWORD)
      },
      filters: {
        defaultValue: defaultValueForKey(defaults, 'filters', _storage.DEFAULT_FILTERS),

        serialize(value) {
          try {
            return value ? JSON.stringify(value) : _storage.DEFAULT_FILTERS;
          } catch (error) {
            console.error(`Error serializing filter param: ${value}`);
          }
        },

        deserialize(value) {
          try {
            return value ? JSON.parse(value) : _storage.DEFAULT_FILTERS;
          } catch (error) {
            console.error(`Error parsing filter param: ${value}`);
          }
        }

      },
      ...queryParams
    });
  };
  /**
   * Creates the controller mixin with the passed query params config. It also
   * adds a reset functionality to the controller if configured.
   *
   * @param {*} queryParams An Ember Parachute QueryParams instance, created
   * either directly or returned by `makeFilterQueryParams()`
   * @param {Object} options Configuration object
   * @param {boolean} [options.resetWhenExiting] Indicates if the query params
   * should reset when the controller's route is exiting.
   * @returns {Mixin} An Ember Parachute Controller Mixin
   */


  _exports.makeFilterQueryParams = makeFilterQueryParams;

  const makeControllerMixin = function (queryParams) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return Ember.Mixin.create(queryParams.Mixin, {
      reset(_ref2, isExiting) {
        let {
          queryParams
        } = _ref2;

        if (options.resetWhenExiting && isExiting) {
          this.resetQueryParams();
        }
      }

    });
  };
  /**
   * Creates a route mixin that can be used to automatically reset a controller's
   * query params when the route is deactivated.
   *
   * @param {string} routePath The path of the route that's controller needs to be
   * reset when exiting the route
   * @returns {Mixin} An `Ember.Mixin` that to be mixed in to the route we want to
   * perform the reset when exiting
   */


  _exports.makeControllerMixin = makeControllerMixin;

  const makeResetQueryParamsRouteMixin = routePath => {
    return Ember.Mixin.create({
      deactivate() {
        this._super(...arguments);

        this.controllerFor(routePath).resetQueryParams();
      }

    });
  };

  _exports.makeResetQueryParamsRouteMixin = makeResetQueryParamsRouteMixin;

  const defaultValueForKey = (obj, key, defaultValue) => {
    if (obj && typeof obj === 'object' && key in obj) {
      return obj[key];
    }

    return defaultValue;
  };
});