define("iris/templates/components/forms/organization-department-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jfl3FQy9",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"bs-form\",null,[[\"model\"],[[24,[\"model\"]]]],{\"statements\":[[4,\"form-group\",null,[[\"property\",\"label\",\"proportion\"],[\"name\",[28,\"t\",[\"general.name\"],null],\"3-7\"]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"name\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\",\"proportion\"],[\"partner\",[28,\"t\",[\"finances.partner\"],null],\"3-7\"]],{\"statements\":[[4,\"iris-power-select\",null,[[\"options\",\"selected\",\"allowClear\",\"onchange\"],[[24,[\"partners\"]],[24,[\"model\",\"partner\"]],false,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"partner\"]]],null]],null]]],{\"statements\":[[0,\"      \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[4,\"if\",[[24,[\"model\",\"customValues\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h4\",true],[10,\"class\",\"h4\"],[8],[1,[28,\"t\",[\"cmdb.entity.custom-fields\"],null],false],[9],[0,\"\\n    \"],[1,[28,\"forms/custom-attributes\",null,[[\"values\",\"large\"],[[24,[\"model\",\"customValues\"]],false]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/organization-department-form.hbs"
    }
  });

  _exports.default = _default;
});