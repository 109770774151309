define("iris/routes/unauth/confirmation/portal-registration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),

    async model(params) {
      const token = params.token;
      const res = await this.get('ajax').call('POST', 'account', null, 'confirm-portal-registration', {
        token: token
      }, false);

      if (res) {
        return true;
      } else {
        return false;
      }
    }

  });

  _exports.default = _default;
});