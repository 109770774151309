define("iris/components/menu-breadcrumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ol',
    classNames: ['breadcrumbs'],
    intl: Ember.inject.service('intl'),
    mappedBreadcrumbs: Ember.computed('breadcrumbs', 'pageTitleSegments', function () {
      let temp = '';
      let res = [];

      if ((!this.breadcrumbs || !this.breadcrumbs.length) && this.pageTitleSegments && this.pageTitleSegments.length) {
        return this.pageTitleSegments;
      }

      for (let i = 0; i < this.breadcrumbs.length; i++) {
        if (i > 0) {
          temp = `${temp}.${this.breadcrumbs[i]}`;
        } else if (i === 0) {
          temp = this.breadcrumbs[i];
        }

        res.push(this.intl.t(`menus.${temp}.root`));
      }

      return res;
    })
  });

  _exports.default = _default;
});