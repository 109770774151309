define("iris/templates/components/project/on-time-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "10Ve37ov",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"badge badge-success\"],[11,\"title\",[28,\"t\",[\"closedTickets\"],null]],[8],[0,\"\\n  \"],[1,[24,[\"row\",\"closedTickets\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"badge badge-info\"],[11,\"title\",[28,\"t\",[\"ticketsInProgress\"],null]],[8],[0,\"\\n  \"],[1,[24,[\"row\",\"ticketsInProgress\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"badge badge-warning\"],[11,\"title\",[28,\"t\",[\"dueTickets\"],null]],[8],[0,\"\\n  \"],[1,[24,[\"row\",\"dueTickets\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"badge badge-danger\"],[11,\"title\",[28,\"t\",[\"expiredTickets\"],null]],[8],[0,\"\\n  \"],[1,[24,[\"row\",\"expiredTickets\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[24,[\"row\",\"onTimePerCent\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/project/on-time-info.hbs"
    }
  });

  _exports.default = _default;
});