define("iris/routes/settings/finances/uoms/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UomEditRoute extends Ember.Route {
    model(params) {
      if (params.id == 'new') {
        return this.store.createRecord('uom');
      }

      return this.store.findRecord('uom', params.id).catch(function () {
        return {};
      });
    }

    setupController(controller, model) {
      super.setupController(...arguments);
      controller.set('navNames', Ember.A(['PIECE', 'KILOGRAM', 'TON', 'KWH', 'DAY', 'HOUR', 'MINUTE', 'MONTH', 'LITER', 'KILOMETER', 'CUBIC_METER', 'METER', 'LINEAR_METER', 'CARTON', 'PACK', 'OWN']));
    }

  }

  _exports.default = UomEditRoute;
});