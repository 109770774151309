define("iris/components/checkbox-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // https://github.com/DamitDev/iris/issues/174
  // eslint-disable-next-line ember/new-module-imports
  const {
    assert
  } = Ember;

  var _default = Ember.Component.extend({
    tagName: '',
    table: Ember.computed('parentView', function () {
      let parentView = this.get('parentView');

      while (parentView) {
        if (parentView.get('classNames').contains('table-component')) {
          return parentView;
        }

        parentView = parentView.get('parentView');
      }

      return assert(false, 'Cannot find table');
    }),
    selectedRecords: Ember.computed.alias('table.selectedRecords'),
    hasCheckbox: true,
    model: Ember.computed.alias('record'),
    onToggleRow: null,
    actions: {
      click: function (e) {
        if (e.target.cellIndex) {
          this.sendAction();
        }
      },
      toggleRow: function (state) {
        let onToggleRow = this.get('onToggleRow');
        let selectedRecords = this.get('selectedRecords');
        let record = this.get('record');

        if (state) {
          selectedRecords.addObject(record);
        } else {
          selectedRecords.removeObject(record);
        }

        if (onToggleRow) {
          onToggleRow(selectedRecords);
        }
      }
    }
  });

  _exports.default = _default;
});