define("iris/templates/settings/finances/uoms/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i+LTo/0i",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"isLoaded\"]]],null,{\"statements\":[[0,\"  \"],[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@proportion\",\"@frame\",\"@needClose\"],[[23,0,[\"model\"]],\"settings.finances.uoms\",\"2-4\",true,true]],{\"statements\":[[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"name\"]]],[0,\"\\n    \"],[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"navName\",[28,\"t\",[\"forms.nav-name\"],null]]],{\"statements\":[[0,\"\\n      \"],[5,\"iris-power-select\",[],[[\"@options\",\"@selected\",\"@onchange\"],[[23,0,[\"navNames\"]],[23,0,[\"model\",\"navName\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"model\",\"navName\"]]],null]],null]]],{\"statements\":[[0,\"\\n        \"],[1,[23,1,[]],false],[0,\"\\n      \"]],\"parameters\":[1]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/finances/uoms/edit.hbs"
    }
  });

  _exports.default = _default;
});