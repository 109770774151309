define("iris/components/language-selector", ["exports", "@glimmer/component", "moment", "ember-concurrency"], function (_exports, _component, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LanguageSelector = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service('theme-switcher'), _dec7 = Ember.computed.alias('sessionAccount.currentLanguage'), _dec8 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec9 = Ember.computed.alias('languagesTask.last.value'), _dec10 = Ember._action, _dec11 = Ember._action, (_class = class LanguageSelector extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "moment", _descriptor3, this);

      _initializerDefineProperty(this, "sessionAccount", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);

      _initializerDefineProperty(this, "theme", _descriptor6, this);

      _defineProperty(this, "toggleLanguageMenu", false);

      _initializerDefineProperty(this, "currentLanguage", _descriptor7, this);

      _initializerDefineProperty(this, "languages", _descriptor8, this);

      this.languagesTask.perform();
    }

    *languagesTask() {
      return yield this.store.findAll('language');
    }

    async changeLanguage(language) {
      let currentUserId = this.sessionAccount.account.id;
      await this.ajax.call('POST', 'account', currentUserId, 'changeUserSettings', {
        values: {
          languageId: language.id
        }
      }).then(result => {
        this.sessionAccount.loadCurrentUser();
        this.intl.setLocale(language.languageCode);
        this.moment.changeLocale(language.flagCode);

        _moment.default.locale(language.flagCode);

        Ember.set(this, 'toggleLanguageMenu', false);
        window.location.reload(true);
      });
    }

    toggleLanguageMenuAction() {
      Ember.set(this, 'toggleLanguageMenu', !this.toggleLanguageMenu);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "moment", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sessionAccount", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "currentLanguage", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "languagesTask", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "languagesTask"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "languages", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeLanguage", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "changeLanguage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleLanguageMenuAction", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "toggleLanguageMenuAction"), _class.prototype)), _class));
  _exports.default = LanguageSelector;
});