define("iris/helpers/replace-query-param", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // See also: app/helpers/update-query-param
  var _default = Ember.Helper.extend({
    queryParams: Ember.inject.service(),

    compute(_ref) {
      let [param] = _ref;
      return value => this.queryParams.replace(param, value);
    }

  });

  _exports.default = _default;
});