define("iris/components/beta/filters/icons/attribute-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const layout = Ember.HTMLBars.template({
    "id": "iJITF9XO",
    "block": "{\"symbols\":[\"@attributeType\",\"&attrs\"],\"statements\":[[0,\"\\n  \"],[7,\"span\",false],[13,2],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[]],\"string\"],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"fa-icon\",[\"font\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"contains\",[[23,1,[]],[28,\"array\",[\"array\",\"hasMany\",\"belongsTo\"],null]],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"fa-icon\",[\"list\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[]],\"date\"],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"fa-icon\",[\"calendar\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[]],\"boolean\"],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"fa-icon\",[\"check-square\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[]],\"number\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"small\",true],[8],[0,\"123\"],[9],[0,\"\\n\"],[0,\"    \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {}
  });

  var _default = Ember.Component.extend({
    tagName: '',
    layout
  });

  _exports.default = _default;
});