define("iris/components/beta/filters/edit/component", ["exports", "deepmerge", "iris/lib/filters/data", "iris/lib/filters/filter"], function (_exports, _deepmerge, _data, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    columns: null,
    // @, object[]
    filter: null,
    // @, object
    filters: null,
    // @, object[]
    queryCustomFilters: null,
    // @, Task
    onSubmit: null,
    // @, function
    onAdd: null,
    // @, function
    onRemove: null,
    // @, function
    onClear: null,
    // @, function
    isSelectingAttributeType: false,
    // Since `this.filter` is a proxy, we would've had to use `Ember.get()`.
    // Because of this, these aliases come in handy.
    filterAttributeType: Ember.computed.alias('filter.attributeType'),
    filterFilterTypes: Ember.computed.alias('filter.filterTypes'),
    filterInputType: Ember.computed.alias('filter.inputType'),
    filterFilterType: Ember.computed.alias('filter.filterType'),
    selectedFilterType: Ember.computed('filterFilterType', function () {
      return {
        name: _data.FILTER_TYPE_LABELS[this.filterFilterType],
        filterType: this.filterFilterType,
        symbol: (0, _filter.symbolForFilterType)(this.filterFilterType)
      };
    }),
    filterTypeOptions: Ember.computed('filterAttributeType', 'filterFilterTypes.length', function () {
      var _this$filterFilterTyp;

      if ((_this$filterFilterTyp = this.filterFilterTypes) !== null && _this$filterFilterTyp !== void 0 && _this$filterFilterTyp.length) {
        const res = [];

        for (const filterType of this.filterFilterTypes) {
          res.push(...(0, _filter.getFilterDefinitionsBy)({
            attributeType: this.filterAttributeType,
            filterType
          }).map(_ref => {
            let {
              filterType
            } = _ref;
            return {
              name: _data.FILTER_TYPE_LABELS[filterType],
              filterType,
              symbol: (0, _filter.symbolForFilterType)(filterType)
            };
          }));
        }

        return res;
      } else {
        return (0, _filter.getFilterDefinitionsBy)({
          attributeType: this.filterAttributeType
        }).map(_ref2 => {
          let {
            filterType
          } = _ref2;
          return {
            name: _data.FILTER_TYPE_LABELS[filterType],
            filterType,
            symbol: (0, _filter.symbolForFilterType)(filterType)
          };
        });
      }
    }),
    actions: {
      selectFilterAttribute(newFilter) {
        // Unless pending data, the `this.filter.get('data')` would only return
        // the original filter data, not the modified one. This came out when
        // transferring value from a new filter, where the entered data is
        // inherently new.
        //
        // TODO: `this.filter.get('pendingData')`
        // - Requires ember-changeset@3.7.1
        //   - Requires ember-source@3.13
        let pendingData = (0, _deepmerge.default)(this.filter.get('data'), this.filter.get('change'));
        (0, _filter.transferFilterValue)(pendingData, newFilter);
        this.onAdd({ ...newFilter,
          replaceFilterWithId: this.filter.get('id')
        });
      },

      selectFilterType(option) {
        let previousInputType = this.filterInputType;
        let filterDefinition = (0, _filter.findFilterDefinitionBy)({
          attributeType: this.filterAttributeType,
          filterType: option.filterType
        });
        this.set('filter.inputType', filterDefinition.inputType);
        this.set('filter.filterType', option.filterType);

        if (filterDefinition.inputType !== previousInputType) {
          this.set('filter.value', null);
        }
      },

      cancelEditing() {
        // TODO: move rollback to manager
        this.filter.rollback();
        this.onClear();
      },

      onSubmit(e) {
        e.preventDefault();
        this.onSubmit();
      }

    }
  });

  _exports.default = _default;
});