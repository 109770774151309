define("iris/components/modals/import-tsm/component", ["exports", "iris/utils/get-base-url", "ember-concurrency", "iris/utils/converter", "iris/utils/error"], function (_exports, _getBaseUrl, _emberConcurrency, _converter, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ImportTSM = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class ImportTSM extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "name", 'importTSM');

      _defineProperty(this, "extensions", 'xlsx');

      _defineProperty(this, "result", null);

      _defineProperty(this, "fileToUpload", null);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "uploader", _descriptor3, this);
    }

    cleanUpFile(file) {
      // destroy file to remove it from the queue
      file.destroy();
      this.set('fileToUpload', null);
    }

    blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();

        reader.onloadend = () => resolve(reader.result);

        reader.readAsDataURL(blob);
      });
    }

    *processExcelTask(file) {
      const accessToken = this.get('session.data.authenticated.id');
      const baseUrl = (0, _getBaseUrl.default)(this);
      const url = `${baseUrl}/projects/import-tsm?access_token=${accessToken}`;

      try {
        const res = yield file.upload(url);
        this.cleanUpFile(file);
        saveAs(_converter.default.b64toBlob(res.body.base64, 'application/vnd.ms-excel'), `TSM-${moment().format('YYYY/MM/DD')}.xlsx`);
        this.set('result', res.body.result);
      } catch (error) {
        this.cleanUpFile(file);
        const {
          flashMessages,
          intl
        } = this;
        const msg = error.response ? (0, _error.formatErrorMessages)(JSON.parse(error.response)) : error.message;
        flashMessages.danger(msg, {
          title: intl.t('general.error'),
          sticky: true,
          icon: 'warning'
        });
      }
    }

    addFile(file) {
      const fileQueue = this.uploader.files;

      if (fileQueue.length > 1) {
        const oldFile = fileQueue.find(f => f.name !== file.name);
        oldFile.destroy();
      }

      this.set('fileToUpload', file);
    }

    async uploadFile() {
      this.processExcelTask.perform(this.fileToUpload);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uploader", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "processExcelTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "processExcelTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addFile", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "addFile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadFile", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "uploadFile"), _class.prototype)), _class));
  _exports.default = ImportTSM;
});