define("iris/models/ticket-intervention-time", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    startDate: (0, _attr.default)('date'),
    endDate: (0, _attr.default)('date'),
    filledOut: Ember.computed('startDate', 'endDate', 'name', function () {
      return !!(this.get('startDate') && this.get('endDate') && this.get('name'));
    }),
    ticket: (0, _relationships.belongsTo)('ticket')
  });

  _exports.default = _default;
});