define("iris/templates/operation/report-submit/close-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cZ0UkKEx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-tether\",null,[[\"to\",\"target\",\"targetModifier\",\"attachment\",\"on-overlay-click\",\"tetherClass\",\"overlayClass\"],[\"modal-dialog\",\"document.body\",\"visible\",\"middle center\",[28,\"route-action\",[\"transitionTo\",\"operation.report-submit\"],null],\"modal-dialog modal-md\",\"modal-backdrop\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n\\n    \"],[1,[28,\"modal-header\",null,[[\"title\"],[[28,\"t\",[\"operation.closing\"],null]]]],false],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n      \"],[1,[28,\"forms/close-report\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n      \"],[1,[28,\"iris-button\",null,[[\"type\",\"action\",\"text\",\"value\"],[\"primary\",[28,\"route-action\",[\"closeOnFirstCall\"],null],[28,\"t\",[\"operation.closing\"],null],[24,[\"model\",\"ticket\"]]]]],false],[0,\"\\n\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/operation/report-submit/close-report.hbs"
    }
  });

  _exports.default = _default;
});