define("iris/models/solving-group", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "iris/mixins/anonymize"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _anonymize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 300
    })],
    servicePerson1: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _model.default.extend(Validations, _anonymize.default, {
    modelName: 'SolvingGroup',
    changeTracker: {
      trackHasMany: true,
      enableIsDirty: true,
      auto: true
    },
    name: (0, _attr.default)('string'),
    default: (0, _attr.default)('boolean'),
    color: (0, _attr.default)('string', {
      formWidget: 'color'
    }),
    email: (0, _attr.default)('string'),
    leader: (0, _relationships.belongsTo)('account'),
    accounts: (0, _relationships.hasMany)('account'),
    operationServiceLevels: (0, _relationships.hasMany)('operationServiceLevel'),
    servicePerson1: (0, _relationships.belongsTo)('account'),
    servicePerson2: (0, _relationships.belongsTo)('account'),
    serviceAreaSolvingGroupAssocs: (0, _relationships.hasMany)('serviceAreaSolvingGroupAssoc'),
    shiftChanges: (0, _relationships.hasMany)('shiftChange')
  });

  _exports.default = _default;
});