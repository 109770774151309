define("iris/components/change-requests/edit/notifications/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TlOXQlte",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[23,0,[\"disabled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"section\",true],[10,\"class\",\"panel\"],[8],[0,\"\\n    \"],[7,\"header\",true],[10,\"class\",\"panel-heading\"],[8],[0,\"\\n      \"],[7,\"h2\",true],[10,\"class\",\" h2 panel-title\"],[8],[1,[28,\"t\",[\"forms.personal-conditions\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n      \"],[5,\"change-requests/edit/notifications/person\",[],[[\"@model\"],[[23,0,[\"model\"]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"section\",true],[10,\"class\",\"panel\"],[8],[0,\"\\n  \"],[7,\"header\",true],[10,\"class\",\"panel-heading\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\" h2 panel-title\"],[8],[1,[28,\"t\",[\"operation.change-request.persons-approve\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n    \"],[5,\"change-requests/edit/persons-table\",[],[[\"@model\",\"@type\",\"@disabled\"],[[23,0,[\"model\",\"notifications\"]],1,[23,0,[\"disabled\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"section\",true],[10,\"class\",\"panel\"],[8],[0,\"\\n  \"],[7,\"header\",true],[10,\"class\",\"panel-heading\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\" h2 panel-title\"],[8],[1,[28,\"t\",[\"operation.change-request.persons-notify\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n    \"],[5,\"change-requests/edit/persons-table\",[],[[\"@model\",\"@type\",\"@disabled\"],[[23,0,[\"model\",\"notifications\"]],2,[23,0,[\"disabled\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/change-requests/edit/notifications/template.hbs"
    }
  });

  _exports.default = _default;
});