define("iris/models/entity-procedure", ["exports", "ember-data", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "iris/models/types/entity-procedure"], function (_exports, _emberData, _attr, _relationships, _emberCpValidations, _entityProcedure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 350
    })],
    priorityWarning: (0, _emberCpValidations.validator)('presence', true),
    priorityCritical: (0, _emberCpValidations.validator)('presence', true),
    notificationWarning: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', true)],
    notificationCritical: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _emberData.default.Model.extend(Validations, {
    name: (0, _attr.default)('string'),
    entityName: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    priorityWarning: (0, _attr.default)('number'),
    priorityCritical: (0, _attr.default)('number'),
    changed: (0, _attr.default)('number'),
    dontSaveChange: (0, _attr.default)('number'),
    entity: (0, _relationships.belongsTo)('entity', {
      inverse: 'entityProcedures'
    }),
    realEntity: (0, _relationships.belongsTo)('entity', {
      inverse: 'realEntityProcedures'
    }),
    notificationWarning: (0, _relationships.belongsTo)('entityProcedureNotificationType'),
    notificationCritical: (0, _relationships.belongsTo)('entityProcedureNotificationType'),
    solvingGroup: (0, _relationships.belongsTo)('solvingGroup'),
    notificationGroups: (0, _relationships.hasMany)('notificationGroup'),
    // Since change tracking is somewhat broken for has-many relationships, this
    // is a workaround for setting `isDirty` on the record. This is because a
    // record's own attribute always dirties the model, which will make ED perform
    // a save action on the related records. There are issues with boolean values,
    // so `Date` seems the safest way to create a dirty attribute. Note that this
    // property will be serialized and sent to the server.
    isDirtyTemp: (0, _attr.default)('date'),
    isChangeUnchanged: Ember.computed.equal('changed', _entityProcedure.default.Unchanged),
    isChangeChanged: Ember.computed.equal('changed', _entityProcedure.default.Changed),
    isChangeDeleted: Ember.computed.equal('changed', _entityProcedure.default.Deleted)
  });

  _exports.default = _default;
});