define("iris/helpers/project-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(params, hash) {
      let state = params[0];
      let value = this.intl.t('forms.projecttwo');

      if (state === 0) {
        value = this.intl.t('forms.projecttwo');
      } else if (state === 1) {
        value = this.intl.t('forms.agile');
      } else if (state === 2) {
        value = this.intl.t('forms.operation');
      } else if (state === 3) {
        value = this.intl.t('forms.waterfall');
      }

      return value;
    }

  });

  _exports.default = _default;
});