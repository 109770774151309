define("iris/components/forms/partner-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    _modelTypesPartnerTypes: Ember.computed.mapBy('model.types', 'partnerType'),
    partnerPartnerTypes: Ember.computed.mapBy('_modelTypesPartnerTypes', 'content'),
    actions: {
      addType: function (selected) {
        let partner = this.get('model');
        let partnerTypes = partner.get('partnerTypes');
        let store = this.get('store');
        selected.forEach(partnerType => {
          if (!partnerTypes.contains(partnerType)) {
            store.createRecord('partnerPartnerTypeAssoc', {
              partnerType: partnerType,
              partner: partner
            });
          }
        });
        partnerTypes.forEach(partnerType => {
          if (!selected.contains(partnerType)) {
            partnerType.get('partnerPartnerTypeAssoc').then(p => {
              p.set('partnerType', null);
              p.set('partner', null);

              if (p.get('id')) {
                partner.get('toDelete.partnerType').addObject(p);
              }

              p.deleteRecord();
            });
          }
        });
      },

      async onChangeChildren(selected) {
        const children = this.model.get('children');

        for (const child of children.toArray()) {
          if (!selected.contains(child.get('parent'))) {
            child.set('parent', null);
            await child.save();
          }
        }

        this.model.set('children', selected);
      }

    }
  });

  _exports.default = _default;
});