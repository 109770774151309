define("iris/components/beta/table/cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    cell: null,
    // @
    row: null,
    // @
    column: null,
    // @
    cellMeta: null,
    // @
    rowMeta: null,
    // @
    columnMeta: null,
    // @
    routeParams: Ember.computed('columnCPContext.routeParams', function () {
      let {
        routeParams
      } = this.columnCPContext; // Make sure it's an array, because it is passed to `@models`

      return routeParams ? [].concat(routeParams) : [];
    }),
    columnCPContext: Ember.computed('column.cellConfigClass', 'row', function () {
      return this.column.cellConfigClass.create({
        row: this.row
      });
    })
  });

  _exports.default = _default;
});