define("iris/services/error-handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),

    validate(model, icon) {
      let errors = [];

      if (model.get('validations') && model.get('validations.errors')) {
        errors = model.get('validations.errors');
      }

      if (!errors.length) {
        return;
      }

      const flashMessages = Ember.get(this, 'flashMessages');
      errors = errors.map(error => {
        let translationKey = `models.${model.modelName}.attributes.${error.attribute}`;
        let hasTranslation = this.intl.exists(translationKey, this.intl.locale);
        let translation = hasTranslation ? this.intl.t(translationKey) : translationKey;
        return `<div><b>${translation}:</b> ${error.message}<div>`;
      }).join('');
      flashMessages.danger(errors, {
        title: 'Hiba...',
        icon: icon ? icon : 'warning',
        sticky: true
      });
    }

  });

  _exports.default = _default;
});