define("iris/components/ui-slider", ["exports", "ui-slider/components/ui-slider"], function (_exports, _uiSlider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _uiSlider.default.extend({
    init() {
      this._super(); // Apply initial disabled state


      this._disabled.apply(this);
    },

    didInsertElement() {
      this._super(...arguments); // There is a bug in ui-slider which results in rendering labels incorrectly
      // because the addon was reporting incorrect size to Bootstrap-slider.
      // Bootstrap-slider mitigates the resizing issue by using window resize
      // event to refresh itself - this won't help in our case. Since this can
      // happen easily with Iris modals, it's probably best if the slider is
      // refreshed in the next runloop (afterRender is too soon for some reason).
      //
      // ui-slider issue: https://github.com/lifegadget/ui-slider/issues/25
      // Behavior observed at:
      // http://localhost:4200/operation/application-to-intervene/DAM000022651/overview


      Ember.run.next(() => {
        this._slider.slider('refresh');
      });
    },

    setDefaultValue() {
      let {
        defaultValue,
        value,
        max
      } = this.getProperties('defaultValue', 'value', 'max');

      if (Ember.A(['null', 'undefined']).contains(Ember.typeOf(value))) {
        defaultValue = Ember.typeOf(defaultValue) === 'string' && defaultValue.split(',').length > 1 ? defaultValue.split(',') : defaultValue;
        this.set('value', defaultValue);
      }

      if (value > max) {
        this.set('value', max);
      }
    }

  });

  _exports.default = _default;
});