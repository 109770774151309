define("iris/utils/hash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cyrb53 = void 0;

  /**
   * A simple but high quality 53-bit hash. It's quite fast, provides very good* hash distribution,
   * and because it outputs 53 bits, has significantly lower collision rates compared to any 32-bit hash.
   *
   * https://stackoverflow.com/a/52171480/15515613
   *
   * @param {*} str
   * @param {number} [seed=0]
   * @return {*}
   */
  const cyrb53 = function (str) {
    let seed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;

    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }

    h1 = Math.imul(h1 ^ h1 >>> 16, 2246822507) ^ Math.imul(h2 ^ h2 >>> 13, 3266489909);
    h2 = Math.imul(h2 ^ h2 >>> 16, 2246822507) ^ Math.imul(h1 ^ h1 >>> 13, 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  };

  _exports.cyrb53 = cyrb53;
});