define("iris/models/tandm-cumulable", ["exports", "@ember-data/model", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({});

  var _default = _model.default.extend(Validations, {
    createDate: (0, _model.attr)('date'),
    balance: (0, _model.attr)('number'),
    tandm: (0, _relationships.belongsTo)('tandm')
  });

  _exports.default = _default;
});