define("iris/routes/settings/aiops/optimization-parameters/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AiopsOptimizationParameterEditRoute extends Ember.Route {
    model(params) {
      if (params.optimization_parameter_id == 'new') {
        return this.store.createRecord('aiopsOptimizationParameter');
      }

      return this.store.findRecord('aiopsOptimizationParameter', params.optimization_parameter_id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = AiopsOptimizationParameterEditRoute;
});