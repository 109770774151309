define("iris/templates/change-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zxa/tasi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"main-content\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"can\",[\"read action\"],[[\"canActions\"],[[24,[\"actionCreate\"]]]]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"iris-button\",null,[[\"icon\",\"action\",\"disabled\",\"title\",\"type\",\"refresh\",\"value\",\"text\"],[\"glyphicon-plus\",[28,\"route-action\",[\"transitionTo\"],null],[24,[\"disabled\"]],[24,[\"title\"]],[24,[\"type\"]],[24,[\"refresh\"]],\"change-log.add-to-news\",[28,\"t\",[\"buttons.add-to-news\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"prose prose-xl dark:prose-light\"],[8],[0,\"\\n    \"],[1,[28,\"markdown-to-html\",null,[[\"markdown\"],[[28,\"fr-markdown-file\",[[24,[\"model\",\"file\"]]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/change-log.hbs"
    }
  });

  _exports.default = _default;
});