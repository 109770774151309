define("iris/components/solving-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5E4q+CMh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"isLoaded\"]]],null,{\"statements\":[[0,\"  \"],[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@proportion\",\"@frame\",\"@afterSave\",\"@excludeFields\"],[[23,0,[\"model\"]],\"settings.operations.solving-group\",\"3-9\",true,[23,0,[\"afterSave\"]],[28,\"array\",[\"operationServiceLevels\",\"serviceAreaSolvingGroupAssocs\"],null]]],{\"statements\":[[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"name\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"default\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"color\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"email\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"leader\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"accounts\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"servicePerson1\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"servicePerson2\"]]],[0,\"\\n\\n    \"],[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"partner\",[28,\"t\",[\"solving-group.shiftChanges\"],null]]],{\"statements\":[[0,\"\\n      \"],[5,\"shift-changes\",[],[[\"@model\"],[[23,0,[\"model\"]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/solving-group/template.hbs"
    }
  });

  _exports.default = _default;
});