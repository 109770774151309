define("iris/templates/cmdb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A8KFQa6b",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"sessionAccount\",\"menus\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"am-content\"],[8],[0,\"\\n    \"],[1,[22,\"liquid-outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb.hbs"
    }
  });

  _exports.default = _default;
});