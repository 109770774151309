define("iris/controllers/tasks/work-logs/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      selectTicket(selected) {
        let id = selected.id;
        this.store.find('ticket', id).then(ticket => {
          this.get('model').set('ticket', ticket);
        });
      }

    }
  });

  _exports.default = _default;
});