define("iris/components/aiops/dashboards/competency/time-utilization-chart/component", ["exports", "iris/utils/properties"], function (_exports, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    themeSwitcher: Ember.inject.service(),
    currentUser: Ember.computed.reads('sessionAccount.account'),
    from: moment().add(-1, 'M'),
    to: moment(),
    timestamp: moment(),
    data: (0, _properties.computedTask)('timestamp', 'model.id', function* () {
      return yield this.ajax.call('POST', 'AiopsPartner', '', 'TimeUtilizationChart', {
        competencyId: this.model.id,
        languageId: this.currentUser.belongsTo('language').id(),
        from: this.from,
        to: this.to
      }, true);
    }),
    labels: Ember.computed('data', function (U) {
      let labels = [];

      if (!this.data) {
        return [];
      }

      for (const row of this.data) {
        labels.push(row['name']);
      }

      return labels;
    }),
    colors: Ember.computed('data', function (U) {
      let colors = [];

      if (!this.data) {
        return [];
      }

      for (const row of this.data) {
        colors.push('#ff535d');
      }

      return colors;
    }),
    chartData: Ember.computed('data', 'labels', 'colors', 'model.id', function () {
      const data = getData(this.data);
      return {
        labels: this.labels,
        datasets: [{
          axis: 'y',
          data,
          backgroundColor: this.colors,
          borderColor: this.colors,
          barPercentage: 0.2
        }]
      };
    }),
    options: Ember.computed('themeSwitcher.dark', function () {
      const fontColor = this.get('themeSwitcher.dark') ? '#FFF' : '#485761';
      return {
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              fontColor: fontColor,
              beginAtZero: true
            }
          }]
        }
      };
    }),
    actions: {
      getData() {
        this.set('timestamp', moment());
      }

    }
  });

  _exports.default = _default;

  const getData = function (data) {
    let resData = [];

    if (data) {
      data.forEach(function (cd) {
        resData.push(cd['sum']);
      });
    }

    return resData;
  };
});