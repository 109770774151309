define("iris/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      didTransition() {
        var controller = this.controllerFor('application');
        var lastTransition = controller.get('lastTransition');

        if (lastTransition) {
          lastTransition.retry();
        } else {
          this.transitionTo('dashboard');
        }
      }

    }
  });

  _exports.default = _default;
});