define("iris/components/aiops/dashboards/solver/sum-spent-time/component", ["exports", "iris/utils/properties"], function (_exports, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.reads('sessionAccount.account'),
    sumSpentTimeData: (0, _properties.computedTask)('currentUser', 'model.id', function* () {
      return yield this.ajax.call('POST', 'AiopsPartner', '', 'sumSpentTimeTable', {
        userId: this.model.id,
        languageId: this.currentUser.belongsTo('language').id()
      }, true);
    })
  });

  _exports.default = _default;
});