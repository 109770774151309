define("iris/controllers/finances/bank-balances", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BankBalancesController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('sessionAccount.region.ownerPartner.id'), (_class = class BankBalancesController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionAccount", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _defineProperty(this, "getOwnerPartners", async () => {
        return await this.store.query('partner', {
          filter: {
            where: {
              multiOwnerPartner: true
            }
          }
        });
      });

      _defineProperty(this, "bankAccountOwnerOptionQuery", async () => {
        return await this.getOwnerPartners();
      });

      _defineProperty(this, "bankAccountOptionsQuery", async () => {
        const ownerPartners = await this.getOwnerPartners();
        const ownerPartnerIds = ownerPartners.map(p => p.id);
        const partnerBankcodes = await this.store.query('partnerBankcode', {
          filter: {
            where: {
              partnerId: {
                inq: ownerPartnerIds
              }
            }
          },
          include: ['clientPartner']
        });
        const result = [];

        for (const partnerBankcode of partnerBankcodes.toArray()) {
          const {
            clientPartner
          } = partnerBankcode;
          const found = result.find(r => r.id === clientPartner.get('id'));
          const option = {
            id: partnerBankcode.id,
            name: partnerBankcode.get('bankname')
          };

          if (found) {
            found.options.push(option);
          } else {
            result.push({
              groupName: clientPartner.get('name'),
              id: clientPartner.get('id'),
              options: [option]
            });
          }
        }

        return result;
      });
    }

    get columns() {
      return {
        bankAccount: {
          optionsQuery: this.bankAccountOptionsQuery,
          displayName: this.intl.t('partner-bankcode.bankcode')
        },
        bankAccountOwner: {
          optionsQuery: this.bankAccountOwnerOptionQuery,
          displayName: this.intl.t('bank-balance.bankAccountOwner')
        }
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionAccount", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "columns", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "columns"), _class.prototype)), _class));
  _exports.default = BankBalancesController;
});