define("iris/controllers/human-resource-management/event-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    accountController: Ember.inject.controller('human-resource-management/event-manager/account'),
    account: Ember.computed.alias('accountController.model.account'),
    events: Ember.computed.alias('accountController.model.events'),
    eventTypes: Ember.computed.alias('accountController.model.eventTypes'),
    isLoading: false
  });

  _exports.default = _default;
});