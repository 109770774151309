define("iris/templates/components/forms/settings-operations-ticket-states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e+kGBNrN",
    "block": "{\"symbols\":[\"option\",\"@model\",\"@fetchDestinationStates\"],\"statements\":[[5,\"bs-form\",[],[[\"@model\",\"@proportion\"],[[23,2,[]],\"2-10\"]],{\"statements\":[[0,\"\\n  \"],[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"name\",[28,\"t\",[\"ticket-state.name\"],null]]],{\"statements\":[[0,\"\\n    \"],[5,\"bs-input\",[],[[\"@value\"],[[23,2,[\"name\"]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"ticketStates\",[28,\"t\",[\"forms.valid-steps\"],null]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[5,\"power-select-multiple\",[],[[\"@options\",\"@placeholder\",\"@searchField\",\"@selected\",\"@disabled\",\"@onChange\"],[[28,\"sort-by\",[\"name\",[24,[\"availableTicketStates\"]]],null],[28,\"t\",[\"forms.statusesdots\"],null],\"text\",[23,2,[\"destinationStates\"]],[23,3,[\"isRunning\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,2,[\"destinationStates\"]]],null]],null]]],{\"statements\":[[0,\"\\n        \"],[7,\"span\",true],[8],[0,\"\\n          \"],[1,[23,1,[\"name\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"]],\"parameters\":[1]}],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/settings-operations-ticket-states.hbs"
    }
  });

  _exports.default = _default;
});