define("iris/routes/operation/scheduled-tasks/edit", ["exports", "iris/mixins/incident-save", "iris/mixins/protected-route"], function (_exports, _incidentSave, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_incidentSave.default, _protectedRoute.default, {
    async model(params) {
      const ticketTemplates = await this.store.query('ticketTemplate', {
        filter: {
          where: {
            id: params.id
          },
          include: ['file', 'contact', 'solvingGroup', 'resolver', 'serviceLevel']
        }
      });
      return ticketTemplates.get('firstObject');
    },

    redirect(model, transition) {
      if (!model) {
        this.transitionTo('not-found', 'not-found');
      }
    }

  });

  _exports.default = _default;
});