define("iris/components/sl-time-badge/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // The date of deadline in string (ISO date) format, or null if solved. Use
    // this to check if the ticket has DEADLINE violation, NOT the `violation`
    // property!
    deadline: null,
    // @, null | string
    // The distance from deadline in minutes or `NaN` :(
    distanceFromDeadline: null,
    // @, number
    // The minutes from the SL to solve the ticket
    original: null,
    // @, number
    // Formula: `original` minus the time elapsed since the ticket was created
    // (not incrementing when the ticket is waiting on client). It'll become
    // negative, if the time spent on the ticket is greater than the time
    // available for solving. Note that violation is only calculated after the
    // ticket status has changed, and will only be negative, if the ticket is
    // started/solved!
    violation: null,
    // @, number
    // The thresholds in percent (0-100)
    critical: null,
    // @, number
    warning: null,
    // @, number
    _distanceFromDeadline: Ember.computed('distanceFromDeadline', function () {
      if (isNaN(this.distanceFromDeadline)) {
        return null;
      }

      return this.distanceFromDeadline;
    }),
    _isPassedDeadline: Ember.computed('_distanceFromDeadline', 'deadline', function () {
      if (this._distanceFromDeadline) {
        return (0, _moment.default)().isAfter(this.deadline);
      }

      return false;
    }),
    // This only has meaning if the ticket is unsolved
    isLessThan24HoursUntilDeadline: Ember.computed('_distanceFromDeadline', function () {
      if (typeof this._distanceFromDeadline === 'number') {
        return Math.abs(this._distanceFromDeadline) <= 60 * 24;
      }

      return false;
    }),
    level: Ember.computed('_distanceFromDeadline', '_isPassedDeadline', 'critical', 'deadline', 'original', 'warning', function () {
      if (this._distanceFromDeadline === null) {
        // Solved, but let's return a proper value nonetheless
        return 'normal';
      }

      let elapsedFromOriginal = this.original - this._distanceFromDeadline;
      let limit = elapsedFromOriginal / this.original * 100;
      let isPassedWarningLimit = limit >= this.warning;
      let isPassedCriticalLimit = limit >= this.critical;

      if (this._isPassedDeadline || isPassedCriticalLimit) {
        return 'critical';
      } else if (isPassedWarningLimit) {
        return 'warning';
      } else {
        return 'normal';
      }
    }),
    isNegativeViolation: Ember.computed.lt('violation', 0)
  });

  _exports.default = _default;
});