define("iris/templates/settings/cmdb/entity-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+9JPrwa1",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"menus.settings-entity-types\"],null]],null],false],[0,\"\\n\\n\"],[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\",\"@defaultFilters\",\"@defaultOrder\"],[\"entity-type\",\"settings.cmdb.entity-types.edit\",[28,\"array\",[\"name\",\"entityTypeCategory\",\"code\"],null],[28,\"hash\",null,[[\"codeStore\"],[0]]],\"createDate DESC\"]]],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/cmdb/entity-types.hbs"
    }
  });

  _exports.default = _default;
});