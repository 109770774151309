define("iris/components/forms/acl-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onInitOfUploader: function () {},
      uploadImage: function (file) {
        let user = this.get('model');
        let image = file.file.getNative();
        ImageTools.resize(image, {
          width: 120,
          // maximum width
          height: 120 // maximum height

        }, function (blob, didItResize) {
          let reader = new window.FileReader();
          reader.readAsDataURL(blob);

          reader.onloadend = function () {
            let base64data = reader.result;
            user.set('userPhoto', base64data);
          };
        });
      }
    }
  });

  _exports.default = _default;
});