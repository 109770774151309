define("iris/components/modals/xwiki-path-tree/nested-tree-element/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    updateValueInNestedArray: function (arr, key, value, newValue) {
      for (let i = 0; i < arr.length; i++) {
        if (typeof arr[i] === 'object' && arr[i] !== null) {
          if (arr[i][key] === value) {
            Ember.set(arr[i], key, newValue);
          } else if (Array.isArray(arr[i].children)) {
            this.updateValueInNestedArray(arr[i].children, key, value, newValue);
          }
        }
      }
    },
    actions: {
      toggleCollapse(space) {
        if (space.expanded) {
          Ember.set(space, 'expanded', false);
        } else {
          Ember.set(space, 'expanded', true);
        }
      },

      selectSpace(space, rootData) {
        if (space.selected) {
          Ember.set(space, 'selected', false);
          this.set('selectedSpace', null);
        } else {
          this.updateValueInNestedArray(rootData, 'selected', true, false);
          Ember.set(space, 'selected', true);
          this.set('selectedSpace', space.space);
        }
      }

    }
  });

  _exports.default = _default;
});