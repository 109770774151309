define("iris/components/forms/tax-number-pattern-example/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wDLcH+l1",
    "block": "{\"symbols\":[],\"statements\":[[5,\"form-group\",[],[[\"@proportion\",\"@property\",\"@label\"],[\"4-8\",\"example\",[28,\"t\",[\"tax-number-pattern-example.example\"],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"one-way-input-mask\",[[12,\"class\",\"form-control\"]],[[\"@value\",\"@update\",\"@options\",\"@disabled\"],[[23,0,[\"model\",\"example\"]],[28,\"action\",[[23,0,[]],\"setValue\"],null],[28,\"hash\",null,[[\"regex\"],[[23,0,[\"model\",\"taxNumberPattern\",\"pattern\"]]]]],[28,\"string-starts-with\",[[23,0,[\"model\",\"id\"]],\"SYS_\"],null]]]],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\",\"@proportion\",\"@disabled\"],[[23,0,[\"model\"]],\"comment\",\"4-8\",[28,\"string-starts-with\",[[23,0,[\"model\",\"id\"]],\"SYS_\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/forms/tax-number-pattern-example/template.hbs"
    }
  });

  _exports.default = _default;
});