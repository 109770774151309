define("iris/models/entity-relation", ["exports", "ember-data", "ember-data/relationships", "ember-data-copyable"], function (_exports, _emberData, _relationships, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_emberDataCopyable.default, {
    entityRelationType: (0, _relationships.belongsTo)('entityRelationType'),
    masterEntity: (0, _relationships.belongsTo)('entity'),
    slaveEntity: (0, _relationships.belongsTo)('entity'),
    copyableOptions: Ember.computed('entityRelationType.id', function () {
      if (this.get('entityRelationType.id') !== 'SYS_04') {
        return {
          copyByReference: ['slaveEntity', 'masterEntity']
        };
      } else {
        return {};
      }
    })
  });

  _exports.default = _default;
});