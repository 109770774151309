define("iris/components/kanban/card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c+Qwcqal",
    "block": "{\"symbols\":[\"@card\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"relative flex flex-col items-start p-6 mt-5 bg-white rounded-xl cursor-pointer bg-opacity-90 group hover:bg-opacity-100\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[11,\"class\",[28,\"concat\",[\"mt-6 text-xl font-medium\"],null]],[8],[7,\"b\",true],[8],[1,[23,1,[\"title\"]],false],[9],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex items-center w-full mt-5 text-lg font-medium text-gray-400\"],[8],[0,\"\\n    \"],[1,[23,1,[\"description\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/kanban/card/template.hbs"
    }
  });

  _exports.default = _default;
});