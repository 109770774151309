define("iris/components/aiops/kanban/component", ["exports", "iris/lib/aiops/mapper", "iris/lib/modals", "moment"], function (_exports, _mapper, _modals, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modals: Ember.inject.service(),
    intl: Ember.inject.service(),
    api: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    regionId: Ember.computed.reads('sessionAccount.account.regionId'),
    currentUser: Ember.computed.reads('sessionAccount.account'),
    refreshTime: null,
    blinkText: false,
    matrix: [],
    popover: [],

    init() {
      this.reloadData();

      this._super();
    },

    async reloadData(userId) {
      if (userId === this.currentUser.id || this.popover.length) {
        return;
      }

      this.set('refreshTime', (0, _moment.default)().add(4, 'seconds'));
      const ticketStates = await this.store.findAll('AiopsTicketState');
      const openStates = ticketStates.filter(ts => parseInt(ts.belongsTo('ticketStatusSystem').id()) === _mapper.TicketSystemStates.Open).map(ts => ts.id);
      const tickets = await this.store.query('AiopsTicket', {
        filter: {
          where: {
            ticketStatusId: {
              inq: openStates
            }
          },
          include: ['resolver']
        }
      });
      const data = {
        unsuccess: [],
        draft: [],
        categorized: [],
        assigned: []
      };

      for (const ticket of tickets.toArray()) {
        if (parseInt(ticket.belongsTo('processStatus').id()) === _mapper.TicketProcessStates.Unsuccess) {
          data['unsuccess'].push(ticket);
        } else if (parseInt(ticket.belongsTo('processStatus').id()) === _mapper.TicketProcessStates.Draft) {
          data['draft'].push(ticket);
        } else if (parseInt(ticket.belongsTo('processStatus').id()) === _mapper.TicketProcessStates.Categorized) {
          if (!ticket.belongsTo('resolver').id()) data['categorized'].push(ticket);else data['assigned'].push(ticket);
        }
      }

      this.set('data', data);
    },

    kanban: Ember.computed('data', function () {
      if (!this.data) {
        return [];
      }

      return [{
        canAdd: false,
        name: this.intl.t('aiops.dashboars.unsuccess'),
        cards: this.data.unsuccess,
        popover: false
      }, {
        canAdd: false,
        name: this.intl.t('aiops.dashboars.draft'),
        cards: this.data.draft,
        popover: false
      }, {
        canAdd: false,
        name: this.intl.t('aiops.dashboars.categorized'),
        cards: this.data.categorized,
        popover: false
      }, {
        canAdd: false,
        name: this.intl.t('aiops.dashboars.assigned'),
        cards: this.data.assigned,
        popover: false
      }];
    }),
    actions: {
      reloadData(socketData) {
        if (socketData) {
          const {
            userId,
            matrix
          } = socketData;
          this.reloadData(userId);

          if (matrix) {
            this.set('matrix', matrix);
            this.set('blinkText', true);
          }
        } else {
          this.reloadData();
        }
      },

      togglePopover(card) {
        if (this.popover.includes(card)) {
          this.set('popover', []);
        } else {
          this.set('popover', [card]);
        }
      },

      closePopover() {
        for (const card of this.popover) {
          card.set('popover', false);
        }

        this.set('popover', []);
      },

      showMatrix() {
        this.set('blinkText', false);
        let editItem = this.modals.open(`aiops/kanban/matrix`, {
          matrix: this.matrix
        }, {
          className: _modals.ModalLayoutClassName['XXXL']
        });
      },

      showOptimalizaton() {
        let editItem = this.modals.open(`aiops/optimalization`, {}, {
          className: _modals.ModalLayoutClassName['XXXL']
        });
      },

      async addTestTicket() {
        const testTickets = await this.store.query('AiopsTestTicket', {
          filter: {
            where: {
              active: true
            },
            limit: 1,
            order: 'id'
          }
        });

        if (testTickets.get('length')) {
          const testTicket = testTickets.firstObject;
          this.api.collectionAction('ticket', 'create-pre-ticket-simple', {
            method: 'POST',
            data: {
              data: {
                contact: null,
                email: testTicket.email,
                subject: testTicket.subject,
                description: testTicket.description,
                attachments: null,
                entityId: null,
                serviceName: null,
                serviceStatus: null,
                ticketStateId: 'SYS_19'
              }
            }
          });
          testTicket.set('active', false);
          testTicket.save(true);
          this.reloadData();
        }
      },

      async nullPoint() {
        await this.api.collectionAction('aiopsTicket', 'null-point', {
          method: 'GET'
        });
        this.reloadData();
      },

      assign() {
        this.api.collectionAction('aiopsTicket', 'assign', {
          method: 'GET'
        });
      }

    }
  });

  _exports.default = _default;
});