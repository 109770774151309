define("iris/validators/actual-length", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Utility function to calculate the actual length of the string
  function getActualLength(str) {
    // Remove all whitespace characters (spaces, tabs, newlines, etc.)
    return (str || '').replace(/\s+/g, '').length;
  }

  var _default = _base.default.extend({
    intl: Ember.inject.service(),

    validate(value, options) {
      // Extract the minimum length from the options
      const {
        min
      } = options; // Calculate the actual length of the value

      const actualLength = getActualLength(value); // Check if the actual length meets the minimum length requirement

      if (actualLength >= min) {
        return true; // Validation passed
      } else {
        // Return an error message if validation failed
        return this.intl.t('errors.tooShort', {
          min
        });
      }
    }

  });

  _exports.default = _default;
});