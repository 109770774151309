define("iris/templates/settings/regions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3jWRQuN5",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@defaultOrder\",\"@neededFields\"],[\"region\",\"settings.regions.edit\",\"createDate DESC\",[28,\"array\",[\"name\",\"ownerPartner\",\"code\",\"fileCodeFormat\",\"ticketCodeFormat\",\"workLogFormat\"],null]]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/regions.hbs"
    }
  });

  _exports.default = _default;
});