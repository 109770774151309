define("iris/mixins/cmdb-visualisation/shapes", ["exports", "iris/utils/visualisation"], function (_exports, _visualisation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    initShapes(joint, config, graph) {
      const ir = joint.dia.Element.define('iris.Rectangle', {
        attrs: {
          body: {
            rx: 5,
            // add a corner radius
            ry: 5,
            refWidth: '100%',
            refHeight: '100%',
            strokeWidth: 2,
            stroke: '#000000',
            fill: 'blue',
            filter: {
              name: 'dropShadow',
              args: {
                dx: 2,
                dy: 2,
                blur: 3
              }
            }
          },
          label: {
            textVerticalAnchor: 'middle',
            textAnchor: 'middle',
            refX: '50%',
            refY: '50%',
            fontSize: 14,
            fill: '#333333'
          },
          typeBox: {
            ref: 'body',
            rx: 5,
            // add a corner radius
            ry: 5,
            refWidth: '80%',
            refX: '50%',
            refY: -25,
            height: 40,
            strokeWidth: 2,
            stroke: '#000000',
            fill: 'white'
          },
          typeBoxText: {
            ref: 'typeBox',
            textVerticalAnchor: 'middle',
            textAnchor: 'middle',
            refX: '50%',
            refY: '50%',
            fontSize: 14,
            fill: '#333',
            fontWeight: 'bold'
          },
          state: {
            value: 0,
            refX: -12,
            refY: -12,
            ref: 'body',
            transform: 'scale(0.01)',
            fill: '#ff535d',
            strokeWidth: 80,
            stroke: 'black',
            d: 'M1440 893q0-161-87-295l-754 753q137 89 297 89 111 0 211.5-43.5t173.5-116.5 116-174.5 43-212.5zm-999 299l755-754q-135-91-300-91-148 0-273 73t-198 199-73 274q0 162 89 299zm1223-299q0 157-61 300t-163.5 246-245 164-298.5 61-298.5-61-245-164-163.5-246-61-300 61-299.5 163.5-245.5 245-164 298.5-61 298.5 61 245 164 163.5 245.5 61 299.5z'
          },
          collapseTop: {
            width: 70,
            height: 30,
            fill: 'transparent',
            cursor: 'pointer',

            /*strokeWidth: 2,*/

            /*stroke: '#000000',*/
            refX: 0,
            refY: '-30%'
          },
          collapseTopSign: {
            ref: 'collapseTop',
            d: _visualisation.PLUS_SIGN,
            stroke: '#000000',
            transform: 'scale(2)',
            strokeWidth: 3,
            refX: '27%',
            refY: '27%',
            cursor: 'pointer'
          },
          masterLinkStats: {
            ref: 'collapseTop',
            textVerticalAnchor: 'middle',
            textAnchor: 'middle',
            refX: 0.5,
            refY: 0.5,
            fill: 'black',
            fontWeight: 'bold',
            fontSize: 14,
            cursor: 'pointer'
          },
          collapseBottom: {
            width: 70,
            height: 30,
            fill: 'transparent',
            cursor: 'pointer',
            refX: '20%',
            refY: '100%'
          },
          slaveLinkStats: {
            ref: 'collapseBottom',
            textVerticalAnchor: 'middle',
            textAnchor: 'middle',
            refX: 0.5,
            refY: 0.5,
            fill: 'black',
            fontWeight: 'bold',
            fontSize: 14,
            cursor: 'pointer'
          },
          collapseBottomSign: {
            ref: 'collapseBottom',
            d: _visualisation.PLUS_SIGN,
            stroke: '#000000',
            transform: 'scale(2)',
            strokeWidth: 3,
            refX: '27%',
            refY: '27%',
            cursor: 'pointer'
          }
        }
      }, {
        markup: [{
          tagName: 'rect',
          selector: 'body'
        }, {
          tagName: 'text',
          selector: 'label'
        }, {
          tagName: 'rect',
          selector: 'typeBox'
        }, {
          tagName: 'text',
          selector: 'typeBoxText'
        }, {
          tagName: 'path',
          selector: 'state'
        }, {
          tagName: 'rect',
          selector: 'collapseTop'
        }
        /*
        {
        tagName: 'path',
        selector: 'collapseTopSign',
        },*/
        , {
          tagName: 'text',
          selector: 'masterLinkStats'
        }, {
          tagName: 'rect',
          selector: 'collapseBottom'
        }
        /*
        {
        tagName: 'path',
        selector: 'collapseBottomSign',
        },*/
        , {
          tagName: 'text',
          selector: 'slaveLinkStats'
        }],
        initialize: function () {
          this.on({
            'change:state': this.updateState,
            'change:opaque': this.setOpaque
          }, this);
          joint.shapes.basic.Generic.prototype.initialize.apply(this, arguments);
        },

        setOpaque() {
          if (this.prop('opaque')) {
            this.attr('body/opacity', 0.5);
            this.attr('typeBox/opacity', 0.5);
          }
        },

        updateState() {
          const value = this.prop('state/value'); // CI is fixed

          if (value === 0) {
            this.attr('state/visibility', 'hidden');
          } // CI is broken


          if (value === 2) {
            this.attr('state/visibility', 'visible');
          }
        },

        hideCollapse() {
          let direction = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'master';
          const side = direction === 'master' ? 'Top' : 'Bottom';
          this.attr(`collapse${side}/visibility`, 'hidden');
          this.attr(`collapse${side}Sign/visibility`, 'hidden');
        },

        updateLinkStats(graph) {
          let all = this.get('data').masterEntityIds.length;
          const outbound = graph.getConnectedLinks(this, {
            outbound: true
          });
          let text = all === 0 ? '' : `${outbound.length}/${all}`;
          this.attr('masterLinkStats/textWrap', {
            text,
            ellipsis: true,
            width: -20
          });
          all = this.get('data').slaveEntityIds.length;
          const inbound = graph.getConnectedLinks(this, {
            inbound: true
          });
          text = all === 0 ? '' : `${inbound.length}/${all}`;
          this.attr('slaveLinkStats/textWrap', {
            text,
            ellipsis: true,
            width: -20
          });
        }

      });
      ir.define('iris.Cog', {
        attrs: {
          body: {
            transform: 'scale(1.3)',
            // https://aydos.com/svgedit/ scale 0.06
            d: `m69.12 53.76q0-6.36-4.5-10.86t-10.86-4.5-10.86 4.5-4.5 10.86 4.5 10.86 10.86 4.5 10.86-4.5 4.5-10.86zm30.72-6.54v13.32q0 .72-.48 1.38t-1.2.78l-11.1 1.68q-1.14 3.24-2.34 5.46 2.1 3 6.42 8.28.6 .72.6 1.5t-.54 1.38q-1.62 2.22-5.94 6.48t-5.64 4.26q-.72 0-1.56-.54l-8.28-6.48q-2.64 1.38-5.46 2.28-.96 8.16-1.74 11.16-.42 1.68-2.16 1.68h-13.32q-.84 0-1.47-.51t-.69-1.29l-1.68-11.04q-2.94-.96-5.4-2.22l-8.46 6.42q-.6.54-1.5.54-.84 0-1.5-.66-7.56-6.84-9.9-10.08-.42-.6-.42-1.38 0-.72.48-1.38.9-1.26 3.06-3.99t3.24-4.23q-1.62-3-2.46-5.94l-10.98-1.62q-.78-.12-1.26-.75t-.48-1.41v-13.32q0-.72.48-1.38t1.14-.78l11.16-1.68q.84-2.76 2.34-5.52-2.4-3.42-6.42-8.28-.6-.72-.6-1.44 0-.6.54-1.38 1.56-2.16 5.91-6.45t5.67-4.29q.78 0 1.56.6l8.28 6.42q2.64-1.38 5.46-2.28.96-8.16 1.74-11.16.42-1.68 2.16-1.68h13.32q.84 0 1.47.51t.69 1.29l1.68 11.04q2.94.96 5.4 2.22l8.52-6.42q.54-.54 1.44-.54.78 0 1.5.6 7.74 7.14 9.9 10.2.42 .48.42 1.32 0 .72-.48 1.38-.9 1.26-3.06 3.99t-3.24 4.23q1.56 3 2.46 5.88l10.98 1.68q.78.12 1.26.75t.48 1.41z`
          },
          typeBox: {
            refWidth: '100%'
          },
          label: {
            ref: 'labelHolder',
            fontSize: 16
          },
          labelHolder: {
            ref: 'typeBox',
            rx: 5,
            // add a corner radius
            ry: 5,
            strokeWidth: 2,
            refWidth: '100%',
            refY: 50,
            refX: 0,
            opacity: 0.8,
            height: 80,
            stroke: 'black',
            fill: 'white'
          },
          collapseTop: {
            width: 50,
            height: 50,
            fill: 'transparent',
            cursor: 'pointer',
            refX: '30%',
            refY: '-50%'
          },
          collapseTopSign: {
            ref: 'collapseTop',
            d: _visualisation.PLUS_SIGN,
            stroke: '#000000',
            transform: 'scale(2)',
            strokeWidth: 3,
            refX: '27%',
            refY: '27%',
            cursor: 'pointer'
          },
          masterLinkStats: {
            ref: 'collapseTop',
            textVerticalAnchor: 'middle',
            textAnchor: 'middle',
            refX: 25,
            refY: 25,
            fill: 'black',
            fontWeight: 'bold',
            fontSize: 14,
            cursor: 'pointer'
          },
          collapseBottom: {
            width: 50,
            height: 30,
            fill: 'transparent',
            cursor: 'pointer',
            refX: '30%',
            refY: '140%'
          },
          collapseBottomSign: {
            ref: 'collapseBottom',
            d: _visualisation.PLUS_SIGN,
            stroke: '#000000',
            transform: 'scale(2)',
            strokeWidth: 3,
            refX: '27%',
            refY: '27%',
            cursor: 'pointer'
          },
          slaveLinkStats: {
            ref: 'collapseBottom',
            textVerticalAnchor: 'middle',
            textAnchor: 'middle',
            refX: 25,
            refY: 25,
            fill: 'black',
            fontWeight: 'bold',
            fontSize: 14,
            cursor: 'pointer'
          }
        }
      }, {
        markup: [{
          tagName: 'path',
          selector: 'body'
        }, {
          tagName: 'rect',
          selector: 'labelHolder'
        }, {
          tagName: 'text',
          selector: 'label'
        }, {
          tagName: 'rect',
          selector: 'typeBox'
        }, {
          tagName: 'text',
          selector: 'typeBoxText'
        }, {
          tagName: 'path',
          selector: 'state'
        }, {
          tagName: 'rect',
          selector: 'collapseTop'
        },
        /*
        {
          tagName: 'path',
          selector: 'collapseTopSign',
        },*/
        {
          tagName: 'text',
          selector: 'masterLinkStats'
        }, {
          tagName: 'rect',
          selector: 'collapseBottom'
        }
        /*
        {
        tagName: 'path',
        selector: 'collapseBottomSign',
        },*/
        , {
          tagName: 'text',
          selector: 'slaveLinkStats'
        }]
      });
      ir.define('iris.Cert', {
        attrs: {
          body: {
            transform: 'scale(1.3)',
            d: `M90.24 53.76l8.28 8.1q1.8 1.68 1.2 4.2-.72 2.46-3.12 3.06l-11.28 2.88 3.18 11.16q.72 2.46-1.14 4.2-1.74 1.86-4.2 1.14l-11.16-3.18-2.88 11.28q-.6 2.4-3.06 3.12-.72.12-1.14.12-1.86 0-3.06-1.32l-8.1-8.28-8.1 8.28q-1.68 1.8-4.2 1.2-2.46-.66-3.06-3.12l-2.88-11.28-11.16 3.18q-2.46.72-4.2-1.14-1.86-1.74-1.14-4.2l3.18-11.16-11.28-2.88q-2.4-.6-3.12-3.06-.6-2.52 1.2-4.2l8.28-8.1-8.28-8.1q-1.8-1.68-1.2-4.2.72-2.46 3.12-3.06l11.28-2.88-3.18-11.16q-.72-2.46 1.14-4.2 1.74-1.86 4.2-1.14l11.16 3.18 2.88-11.28q.6-2.46 3.06-3.06 2.46-.72 4.2 1.14l8.1 8.34 8.1-8.34q1.74-1.8 4.2-1.14 2.46.6 3.06 3.06l2.88 11.28 11.16-3.18q2.46-.72 4.2 1.14 1.86 1.74 1.14 4.2l-3.18 11.16 11.28 2.88q2.4.6 3.12 3.06.6 2.52-1.2 4.2z`
          },
          typeBox: {
            refWidth: '100%'
          },
          label: {
            ref: 'labelHolder',
            fontSize: 16
          },
          labelHolder: {
            ref: 'typeBox',
            rx: 5,
            // add a corner radius
            ry: 5,
            strokeWidth: 2,
            refWidth: '100%',
            refY: 50,
            refX: 0,
            opacity: 0.8,
            height: 80,
            stroke: 'black',
            fill: 'white'
          }
        }
      }, {
        markup: [{
          tagName: 'path',
          selector: 'body'
        }, {
          tagName: 'rect',
          selector: 'labelHolder'
        }, {
          tagName: 'text',
          selector: 'label'
        }, {
          tagName: 'rect',
          selector: 'typeBox'
        }, {
          tagName: 'text',
          selector: 'typeBoxText'
        }, {
          tagName: 'path',
          selector: 'state'
        }]
      }); // https://stackoverflow.com/questions/37152510/using-predefined-svg-file-for-creating-a-custom-jointjs-shape-with-ports

      ir.define('iris.Icon', {
        attrs: {
          wrapper: {
            ref: 'body',
            refWidth: '200%'
          },
          body: {
            //visibility: 'hidden',
            strokeWidth: 5,
            r: 80 //points: `53.21,0 79.81,15.36 106.42,30.72 106.42,61.44 106.42,92.16 79.81,107.52 53.21,122.88 26.6,107.52 0,92.16 0,61.44 0,30.72 26.6,15.36 53.21,0`,

          },
          image: {
            'xlink:href': 'data:image/svg+xml;utf8,' + encodeURI(_visualisation.defaultIcon),
            ref: 'body',
            refX: 0.15,
            refWidth: '69%',
            // r * sqrt(2) / 2 => r * 1.414213562 / 2
            refY: 0.15
          },
          typeBox: {
            ref: 'label',
            rx: 5,
            // add a corner radius
            ry: 5,
            refX: -10,
            refY: -10,
            refWidth: '100%',
            refWidth2: 20,
            refHeight: '100%',
            refHeight2: 20,
            strokeWidth: 2,
            stroke: '#000000',
            fill: 'white'
          },
          label: {
            ref: 'wrapper',
            textVerticalAnchor: 'middle',
            textAnchor: 'middle',
            refX: '50%',
            refY: '120%',
            fontSize: 14,
            fill: '#333',
            fontWeight: 'bold'
          },
          collapseTop: {
            ref: 'masterLinkStats',
            fill: 'transparent',
            cursor: 'pointer',
            refX: -10,
            refY: -10,
            refWidth: '100%',
            refWidth2: 20,
            refHeight: '100%',
            refHeight2: 20,
            rx: 5,
            // add a corner radius
            ry: 5,
            strokeWidth: 2,
            stroke: '#000000',
            fill: 'white',
            opacity: 0.8
          },
          masterLinkStats: {
            ref: 'body',
            textVerticalAnchor: 'middle',
            textAnchor: 'middle',
            refX: 0.5,
            refY: '-5%',
            fill: 'black',
            fontWeight: 'bold',
            fontSize: 14,
            cursor: 'pointer'
          },
          collapseBottom: {
            ref: 'slaveLinkStats',
            fill: 'transparent',
            cursor: 'pointer',
            refX: -10,
            refY: -10,
            refWidth: '100%',
            refWidth2: 20,
            refHeight: '100%',
            refHeight2: 20,
            rx: 5,
            // add a corner radius
            ry: 5,
            strokeWidth: 2,
            stroke: '#000000',
            fill: 'white',
            opacity: 0.8
          },
          slaveLinkStats: {
            ref: 'typeBox',
            textVerticalAnchor: 'middle',
            textAnchor: 'middle',
            refX: 0.5,
            refY: '200%',
            fill: 'black',
            fontWeight: 'bold',
            fontSize: 14,
            cursor: 'pointer'
          },
          state: {
            value: 0,
            refX: -12,
            refY: -12,
            ref: 'body',
            transform: 'scale(0.01)',
            fill: '#ff535d',
            strokeWidth: 80,
            stroke: 'black',
            d: 'M1440 893q0-161-87-295l-754 753q137 89 297 89 111 0 211.5-43.5t173.5-116.5 116-174.5 43-212.5zm-999 299l755-754q-135-91-300-91-148 0-273 73t-198 199-73 274q0 162 89 299zm1223-299q0 157-61 300t-163.5 246-245 164-298.5 61-298.5-61-245-164-163.5-246-61-300 61-299.5 163.5-245.5 245-164 298.5-61 298.5 61 245 164 163.5 245.5 61 299.5z'
          }
        }
      }, {
        markup: [{
          tagName: 'g',
          selector: 'wrapper',
          children: [{
            tagName: 'circle',
            selector: 'body'
          }, {
            tagName: 'image',
            selector: 'image'
          }]
        }, {
          tagName: 'rect',
          selector: 'typeBox'
        }, {
          tagName: 'text',
          selector: 'label'
        }, {
          tagName: 'rect',
          selector: 'collapseTop'
        }, {
          tagName: 'text',
          selector: 'masterLinkStats'
        }, {
          tagName: 'rect',
          selector: 'collapseBottom'
        }, {
          tagName: 'text',
          selector: 'slaveLinkStats'
        }, {
          tagName: 'path',
          selector: 'state'
        }],
        initialize: function () {
          this.on({
            'change:image/xlink:href': this.updateImage,
            'change:state': this.updateState
          }, this);
          joint.shapes.basic.Generic.prototype.initialize.apply(this, arguments);
        },

        updateState() {
          const value = this.prop('state/value'); // CI is fixed

          if (value === 0) {
            this.attr('state/visibility', 'hidden');
          } // CI is broken


          if (value === 2) {
            this.attr('state/visibility', 'visible');
          }
        },

        hideCollapse() {
          for (const side of ['Top', 'Bottom']) {
            this.attr(`collapse${side}/visibility`, 'hidden');
            this.attr(`collapse${side}Sign/visibility`, 'hidden');
          }
        }

      });
    }

  });

  _exports.default = _default;
});