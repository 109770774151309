define("iris/routes/crm/contracts", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    intl: Ember.inject.service(),
    model: function (params) {
      var store = this.store;
      return Ember.RSVP.hash({
        partners: store.query('partner', {
          filter: {
            where: {
              or: [{
                customer: 1
              }, {
                subcontractor: 1
              }]
            },
            order: 'name'
          }
        })
      });
    },
    columns: Ember.computed(function () {
      return [{
        name: this.intl.t('contact.partnerShortName'),
        property: 'partnerShortname',
        search: true
      }, {
        name: this.intl.t('partner.publicId'),
        property: 'publicId',
        search: true
      }, {
        name: this.intl.t('contact.name'),
        property: 'name',
        search: true
      }, {
        name: '',
        property: 'icons'
      }, {
        name: this.intl.t('partner.volumen'),
        property: 'volumen'
      }, {
        name: this.intl.t('partner.yearlyPotential'),
        property: 'yearlyPotential'
      }, {
        name: this.intl.t('partner.potential'),
        property: 'potential'
      }, {
        name: this.intl.t('contact.notPaid'),
        property: 'notPaidInvoicesTotal'
      }, {
        name: this.intl.t('contact.dateEnd'),
        property: 'dateEnd'
      }];
    }),
    actionCreate: ['crm.contracts.create', 'crm.contracts'],
    exportOptions: {
      ignoreColumn: [0, 4, 10]
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set('columns', this.get('columns'));
      controller.set('actionCreate', this.get('actionCreate'));
      controller.set('filterOptions', this.get('filterOptions'));
      controller.set('exportOptions', this.get('exportOptions')); //Filters

      var partnerOptions = Ember.A();
      model.partners.forEach(function (p) {
        partnerOptions.pushObject({
          text: p.get('name'),
          condition: {
            partnerId: p.get('id')
          }
        });
      });
      var filters = Ember.A([{
        placeholder: this.intl.t('contact.contractName'),
        type: 'search',
        property: 'name',
        searchTerm: null
      }, {
        placeholder: this.intl.t('partner.modelName'),
        type: 'select',
        options: partnerOptions,
        selected: null,
        saveProperty: 'partner'
      }]);
      this.controller.set('columnFilterOptions', filters);
    },

    /*
    model: function() {
      return this.store.findAll('contract');
    },
    */
    actions: {
      beforeDelete: function (records, next) {
        records.forEach(record => {
          var promises = [];
          record.get('contractUserGradeCosts').then(function (actions) {
            actions.forEach(function (action) {
              promises.push(action.destroyRecord(true));
            });
            Ember.RSVP.all(promises).then(function () {
              next();
            });
          });
        });
      },
      afterSave: function (savedContract) {
        let toDelete = savedContract.get('toDelete.GradeCosts');
        let promises = toDelete.invoke('destroyRecord', true);
        let controller = this.controllerFor('crm.contracts');
        Ember.RSVP.all(promises).then(function () {
          savedContract.get('contractUserGradeCosts').then(contractUserGradeCosts => {
            contractUserGradeCosts.invoke('save');
          });
          controller.set('refresh', false);
          Ember.run.next(function () {
            controller.set('refresh', true);
          });
        });
      },

      competencies() {
        let action = arguments.length <= 0 ? undefined : arguments[0];
        let contract = arguments.length <= 1 ? undefined : arguments[1];
        let competencies = arguments.length <= 2 ? undefined : arguments[2];
        let id = action.get('id');
        let store = this.store;
        contract.get('contractActionCompetencyAssocs').then(contractActionCompetencyAssocs => {
          contractActionCompetencyAssocs.forEach(faca => {
            let actionId = faca.belongsTo('action').id();

            if (actionId === id) {
              faca.destroyRecord(true);
            }
          });
          competencies.forEach(competency => {
            let record = store.createRecord('contractActionCompetencyAssoc', {
              contract: contract,
              competency: competency,
              action: action
            });
            record.save();
          });
        });
      }

    }
  });

  _exports.default = _default;
});