define("iris/components/operational-file/file-balance/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fS/zcrgL",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"div\",true],[8],[0,\"\\n  \"],[5,\"iris-button\",[],[[\"@type\",\"@icon\",\"@action\"],[[28,\"if\",[[28,\"eq\",[[23,0,[\"mode\"]],\"balance\"],null],\"primary\"],null],\"fa fa-list\",[28,\"action\",[[23,0,[]],[28,\"set\",[[23,0,[]],\"mode\",\"balance\"],null]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"eq\",[[23,0,[\"mode\"]],\"balance\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"operational-file/file-balance/balance\",[],[[\"@model\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,0,[\"mode\"]],\"stats\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"operational-file/file-balance/statistics\",[],[[\"@file\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,0,[\"mode\"]],\"cumulables\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"operational-file/file-balance/cumulables\",[],[[\"@file\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/operational-file/file-balance/template.hbs"
    }
  });

  _exports.default = _default;
});