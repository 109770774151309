define("iris/validators/number-own", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    intl: Ember.inject.service(),

    validate(value
    /*, options, model, attribute*/
    ) {
      if (isNaN(value)) {
        return this.intl.t('validators.numberOwn.mustBeNumber');
      } else {
        return true;
      }
    }

  });

  _exports.default = _default;
});