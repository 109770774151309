define("iris/helpers/string-to-sentence-case", ["exports", "ember-string-fns/helpers/string-to-sentence-case"], function (_exports, _stringToSentenceCase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _stringToSentenceCase.default;
    }
  });
  Object.defineProperty(_exports, "stringToSentenceCase", {
    enumerable: true,
    get: function () {
      return _stringToSentenceCase.stringToSentenceCase;
    }
  });
});