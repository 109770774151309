define("iris/components/forms/application-to-intervene", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    unfilleds: Ember.computed.filterBy('model.ticketInterventionTimes', 'filledOut', false),
    type1: Ember.computed.filterBy('model.ticketInterventionGrids', 'type', 1),
    type2: Ember.computed.filterBy('model.ticketInterventionGrids', 'type', 2),
    type3: Ember.computed.filterBy('model.ticketInterventionGrids', 'type', 3),
    toDelete: null,

    matcher(option, term) {
      return `${option.get('clientPartner.name')} ${option.get('name')}`.toLowerCase().indexOf(term.toLowerCase());
    },

    deleteConfirmation: false,
    selectedPartner: Ember.computed.alias('model.file.clientPartner'),
    partners: Ember.computed.mapBy('files', 'clientPartner'),
    filteredFiles: Ember.computed('files', 'selectedPartner', function () {
      let selectedPartner = this.get('selectedPartner');
      let files = this.get('files');

      if (!selectedPartner) {
        return files;
      }

      return files.filter(file => {
        return file.get('clientPartner.id') === selectedPartner.get('id');
      });
    }),
    states: Ember.computed(function () {
      return this.get('store').query('ticketState', {
        filter: {
          where: {
            applicationToIntervene: 1
          }
        }
      });
    }),
    deadline: (0, _moment.default)().add(10, 'd').toDate(),
    actions: {
      add() {
        this.get('model').addInterventionDatas();
      },

      setDeadline(tit, date) {
        tit.set('endDate', date);
      },

      remove() {
        let index = this.get('toDelete');
        this.get('model.ticketInterventionTimes').then(tit => {
          let record = tit.objectAt(index);
          tit.removeObject(record);
          record.destroyRecord();
        });
        let records = Ember.A([]);

        for (let i = 1; i <= 3; i++) {
          records.pushObject(this.get('type' + i).objectAt(index));
        }

        this.get('model.ticketInterventionGrids').then(tig => {
          tig.removeObjects(records);
          records.invoke('destroyRecord');
        });
        this.send('closeConfirmation');
      },

      deleteConfirmation(index) {
        this.set('toDelete', index);
        this.set('deleteConfirmation', true);
      },

      closeConfirmation() {
        this.set('deleteConfirmation', false);
      },

      setPriority() {
        let days = 10;

        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        let [action, value] = args;

        if (action === 'slideStop') {
          if (value === 1) {
            days = 2;
          }

          let deadline = (0, _moment.default)().add(days, 'd').toDate();
          this.set('deadline', deadline);
        }
      },

      changeBillable() {
        let newValue;

        for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        let [type, b] = args;
        if (type === 0 && b) newValue = 0;
        if (type === 1 && b || type === 2 && !b || type === 0 && !b) newValue = 1;
        if (type === 1 && !b || type === 2 && b) newValue = 2;
        this.set('model.billable', newValue);
      }

    }
  });

  _exports.default = _default;
});