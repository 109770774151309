define("iris/components/invoice-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    nettos: Ember.computed.mapBy('row.items', 'netto'),
    sum: Ember.computed.sum('nettos')
  });

  _exports.default = _default;
});