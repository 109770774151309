define("iris/models/contract-user-grade-cost", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    cost: (0, _attr.default)('number'),
    userGrade: (0, _relationships.belongsTo)('userGrade'),
    contract: (0, _relationships.belongsTo)('contract')
  });

  _exports.default = _default;
});