define("iris/components/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    String
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['progress'],
    progress: 0,
    barWidth: Ember.computed('progress', function () {
      return String.htmlSafe('width:' + this.get('progress') + '%');
    }),
    color: Ember.computed('progress', function () {
      let progress = this.get('progress');

      if (progress < 20) {
        return 'danger';
      }

      if (progress < 40) {
        return 'warning';
      }

      if (progress < 80) {
        return 'info';
      }

      return 'success';
    })
  });

  _exports.default = _default;
});