define("iris/templates/organizations/contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jbiuDD9N",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\",\"@columnOverwrites\"],[\"contact\",\"organizations.contacts.edit\",[28,\"array\",[\"name\",\"partner\",\"department\",\"position\",\"email\",\"defaultReporter\",\"active\",\"subcontractor\",\"inactive\"],null],[28,\"hash\",null,[[\"inactive\",\"active\"],[[28,\"hash\",null,[[\"visibility\",\"type\",\"displayName\"],[\"filter\",\"boolean\",[28,\"t\",[\"filters.show-inactive\"],null]]]],[28,\"hash\",null,[[\"visibility\"],[\"table\"]]]]]]]]],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/organizations/contacts.hbs"
    }
  });

  _exports.default = _default;
});