define("iris/templates/components/bs-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HHPuobKL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\",\"renderInPlace\"],[\"ember-bootstrap-modal-container\",[24,[\"renderInPlace\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"bs-modal-dialog\",null,[[\"close\",\"fade\",\"in\",\"id\",\"title\",\"closeButton\",\"keyboard\",\"header\",\"body\",\"footer\",\"size\",\"backdropClose\"],[[28,\"action\",[[23,0,[]],\"close\"],null],[24,[\"fade\"]],[24,[\"in\"]],[24,[\"modalId\"]],[24,[\"title\"]],[24,[\"closeButton\"]],[24,[\"keyboard\"]],[24,[\"header\"]],[24,[\"body\"]],[24,[\"footer\"]],[24,[\"size\"]],[24,[\"backdropClose\"]]]],{\"statements\":[[0,\"  \"],[14,1,[[23,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showBackdrop\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"modal-backdrop \",[28,\"if\",[[24,[\"fade\"]],\"fade\"],null],\" \",[28,\"if\",[[24,[\"in\"]],\"in\"],null]]]],[11,\"id\",[29,[[22,\"backdropId\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/bs-modal.hbs"
    }
  });

  _exports.default = _default;
});