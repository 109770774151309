define("iris/validators/ensure-loaded-relation", ["exports", "ember-cp-validations/utils/utils", "ember-cp-validations/validators/base"], function (_exports, _utils, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This validator always returns true, as its only job is loading the
  // `belongsTo` or `hasMany` relation during `validate()`.
  //
  // Its raison d'être originates from the fact that the `presence` validator is
  // not capable of loading the relation, only checking the `content` property of
  // an `ObjectProxy` or `ArrayProxy` - which is `undefined` for a relation that
  // is not loaded yet. While this is not the case when `presence` is used in
  // conjunction with the `belongs-to` or `has-many` validators,
  // `ensure-loaded-relation` takes advantage of how validators run in sequence,
  // similarly to `belongs-to` and `has-many`, only without having to use either.
  //
  // Note that the order of the validators matter: `belongs-to`, `has-many` or
  // `ensure-loaded-relation` must come before `presence` to have the desired
  // effect!
  const EnsureLoadedRelation = _base.default.extend({
    validate(value) {
      if ((0, _utils.isPromise)(value)) {
        return value.then(_model => true);
      }

      return true;
    }

  });

  EnsureLoadedRelation.reopenClass({
    getDependentsFor(attribute) {
      return [`model.${attribute}.content`];
    }

  });
  var _default = EnsureLoadedRelation;
  _exports.default = _default;
});