define("iris/components/bs-collapse", ["exports", "ember-bootstrap/components/bs-collapse"], function (_exports, _bsCollapse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsCollapse.default.extend({
    style: Ember.computed('collapseSize', function () {
      let size = this.get('collapseSize');
      let dimension = this.get('collapseDimension');

      if (Ember.isEmpty(size)) {
        return '';
      }

      return `${dimension}: ${size}px`;
    })
  });

  _exports.default = _default;
});