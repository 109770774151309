define("iris/components/operational-file/edit/file-tariff/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jp1XO9qI",
    "block": "{\"symbols\":[\"c\",\"@data\",\"@close\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-content mx-auto\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"menus.edit\"],null],false],[0,\":\\n      \"],[1,[28,\"t\",[\"file-tariff.modelName\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n\\n    \"],[5,\"auto/form/one2-many\",[],[[\"@parentModel\",\"@itemName\",\"@relationFiled\",\"@readOnly\",\"@beforeSave\",\"@createRecord\",\"@defaultOrder\",\"@neededFields\"],[[23,2,[\"file\"]],\"fileTariffs\",\"fileId\",[23,2,[\"disable\"]],[23,0,[\"beforeSave\"]],[28,\"action\",[[23,0,[]],\"createRecord\"],null],\"dateStart DESC\",[28,\"array\",[\"amount\",\"dateStart\",\"dateEnd\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"amount\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"a\",false],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"editTariff\",[23,1,[\"o2m\"]],[23,1,[\"row\"]]],null]]],[8],[0,\"\\n          \"],[1,[28,\"format-number\",[[23,1,[\"row\",\"amount\"]]],null],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"]],\"parameters\":[1]}],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"default\",[28,\"fn\",[[23,3,[]],false],null],[28,\"or\",[[23,2,[\"cancelButtonLabel\"]],[28,\"t\",[\"buttons.close\"],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/operational-file/edit/file-tariff/template.hbs"
    }
  });

  _exports.default = _default;
});