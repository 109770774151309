define("iris/components/forms/service-level", ["exports", "iris/models/ticket-communication"], function (_exports, _ticketCommunication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    withTemplates: false,
    template: null,
    store: Ember.inject.service(),
    ticketTypes: Ember.computed('model', 'store', function () {
      return this.get('store').findAll('ticketType');
    }),
    priorities: Ember.computed('model', 'store', function () {
      return this.get('store').findAll('priority');
    }),
    servicePeriods: Ember.computed('model', 'model.operationServiceLevel.file.id', function () {
      let fileId = this.get('model.operationServiceLevel.file.id');

      if (fileId) {
        return this.get('store').query('servicePeriodFile', {
          filter: {
            where: {
              fileId
            }
          }
        });
      }
    }),
    templates: Ember.computed('model', 'store', function () {
      return this.get('store').findAll('serviceLevelTemplate');
    }),

    async copyEscalation() {
      const template = this.get('template');
      const model = this.get('model');
      const tcs = await template.get('ticketCommunications');
      const modelTCs = await model.get('ticketCommunications'); // cleanup

      for (const tc of modelTCs.toArray()) {
        if (tc.get('fromTemplate')) {
          await tc.destroyRecord();
        }
      }

      for (const tc of tcs.toArray()) {
        // without this relations will not be copied
        await (0, _ticketCommunication.loadRelationsForCopying)(tc);
        const copy = await tc.copy(false, {
          overwrite: {
            serviceLevelTemplate: null,
            serviceLevel: null,
            deletable: false,
            fromTemplate: true
          }
        });
        modelTCs.addObject(copy);
      }
    },

    actions: {
      async setTemplate(template) {
        this.set('template', template);
        let model = this.get('model');
        let properties = ['name', 'type', 'priority', 'servicePeriod', 'solvingTime', 'reactionTime', 'startTime', 'reactionCritical', 'reactionNormal', 'reactionOverdue', 'reactionTime', 'reactionWarning', 'solvingCritical', 'solvingNormal', 'solvingOverdue', 'solvingTime', 'solvingWarning', 'startCritical', 'startNormal', 'startOverdue', 'startTime', 'startWarning', 'vip'];
        properties.forEach(property => {
          model.set(property, template.get(property));
        });
        await this.copyEscalation();
      },

      selectServicePeriods: function (selected) {
        this.set('model.servicePeriods', selected);
        this.get('model').set('isDirtyTemp', new Date());
      }
    }
  });

  _exports.default = _default;
});