define("iris/components/forms/entity-contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    modals: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    partnerOwner: Ember.observer('model.contact.partner', function () {
      let partner = this.get('model.contact.partner');
      let model = this.get('model');
      model.set('partner', partner);
    }),
    departmentOwner: Ember.computed('model.contact.department', {
      get(key) {
        let department = this.get('model.contact.department');
        return department;
      }

    }),
    departmentAssigned: Ember.computed('model.assignedContact.department', {
      get(key) {
        let department = this.get('model.assignedContact.department');
        return department;
      }

    }),
    actions: {
      changePartnerOwner(selected) {
        this.set('departmentOwner', null);
        this.set('model.contact', null);
        this.set('model.partner', selected);
        let setDefault = false;
        selected.get('siteZones').then(siteZones => {
          siteZones.forEach(siteZone => {
            if (siteZone.get('default')) {
              this.set('model.siteZone', siteZone);
              setDefault = true;
            }
          });

          if (!setDefault) {
            let region = this.get('sessionAccount.region');
            region.get('ownerPartner').then(ownerPartner => {
              ownerPartner.get('siteZones').then(siteZones => {
                siteZones.forEach(siteZone => {
                  if (siteZone.get('default')) {
                    this.set('model.siteZone', siteZone);
                  }
                });
              });
            });
          }
        });
      },

      changeDepartmentOwner(selected) {
        //this.set('model.contact', null);
        this.set('departmentOwner', selected);
      },

      async changeAssignedPartner(selected) {
        const {
          model
        } = this;
        this.set('departmentAssigned', null);
        this.set('model.assignedContact', null);
        model.set('assignedPartner', selected);
        const location = await this.model.get('location');

        if (location) {
          const clientPartnerId = location.belongsTo('clientPartner').id();
          const partner = await model.get('partner');
          const selectedId = selected ? Ember.get(selected, 'id') : null;

          if (![selectedId, partner.id].includes(clientPartnerId)) {
            let confirm = await this.modals.open('beta/modals/confirmation', {
              title: this.intl.t('general.warning'),
              body: this.intl.t('cmdb.entity.change-assigned-partner-warning')
            });

            if (confirm) {
              try {
                model.set('location', null);
                await model.save();
                this.flashMessages.info(this.intl.t('general.save.success'), {
                  title: this.intl.t('general.save.success-title'),
                  icon: 'floppy-o'
                });
              } catch (error) {}
            }
          }
        }
      },

      changeDepartmentAssigned(selected) {
        this.set('model.assignedContact', null);
        this.set('departmentAssigned', selected);
      },

      changeContact(selected) {
        this.set('model.contact', selected); //if(!this.get('model.assignedContact.content')){

        this.model.set('assignedPartner', this.get('model.partner'));
        this.set('departmentAssigned', this.get('departmentOwner'));
        this.set('model.assignedContact', selected); //}
      },

      changeAssignedContact(selected) {
        this.model.set('assignedContact', selected);
        this.model.set('assignedPartner', selected.get('partner'));
      }

    }
  });

  _exports.default = _default;
});