define("iris/routes/operation/report-submit", ["exports", "iris/mixins/incident-save", "iris/mixins/protected-route", "iris/utils/routing"], function (_exports, _incidentSave, _protectedRoute, _routing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_incidentSave.default, _protectedRoute.default, {
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    uploadTitle: computed('property', function () {
      return this.intl.t('attachments.uploadTitle');
    }),
    beforeModel: function () {
      const previousRoutes = (0, _routing.getCurrentRouteInfos)(this.router);
      const previousRoute = previousRoutes ? previousRoutes.pop().name : 'operation.sd-dashboard.index';
      this.set('previousRoute', previousRoute);
    },

    model() {
      const store = this.store;
      const description = this.get('session.reportDescription');
      this.set('session.reportDescription', null);
      return store.createRecord('ticket', {
        incident: true,
        createUser: this.get('sessionAccount.account'),
        description: description
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('previousRoute', this.previousRoute);
    }

  });

  _exports.default = _default;
});