define("iris/components/forms/ticket-users/table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editing: null,
    internalWork: true,
    tagName: 'tr',
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),
    actions: {
      edit(row) {
        this.set('editing', row);
        this.set('oldAttributes', row.getProperties('plannedExpensesInHour', 'deadline', 'startDate'));
      },

      confirm(row) {
        let hours = row.get('plannedExpensesInHour');

        if (hours % 0.25 === 0) {
          this.set('editing', null);
        } else {
          const flashMessages = Ember.get(this, 'flashMessages');
          flashMessages.add({
            message: this.intl.t('validators.quarterOfHour'),
            title: 'IRIS message',
            icon: 'warning'
          });
        }
      },

      cancel(row) {
        this.set('editing', null);
        row.setProperties(this.get('oldAttributes'));
      },

      toggleRow(record, state) {
        let selectedRecords = this.get('selectedRecords');

        if (state) {
          selectedRecords.addObject(record);
        } else {
          selectedRecords.removeObject(record);
        }

        if (this.get('ticket.ticketUserAssocs.length') === this.get('selectedRecords.length')) {
          this.set('allRecordSelected', true);
        } else {
          this.set('allRecordSelected', false);
        }
      }

    }
  });

  _exports.default = _default;
});