define("iris/routes/organizations/departments/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DepartmentEditRoute extends Ember.Route {
    model(params) {
      if (params.department_id == 'new') {
        return this.store.createRecord('department');
      }

      return this.store.findRecord('department', params.department_id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = DepartmentEditRoute;
});