define("iris/templates/components/forms/ticket-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OSHrcNZQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-form\",null,[[\"model\",\"proportion\"],[[24,[\"model\"]],\"3-9\"]],{\"statements\":[[4,\"form-group\",null,[[\"property\",\"label\"],[\"name\",[28,\"t\",[\"general.name\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"name\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-offset-2 col-md-5\"],[8],[0,\"\\n\"],[4,\"form-group\",null,[[\"property\",\"label\"],[\"sd\",[28,\"t\",[\"forms.sd\"],null]]],{\"statements\":[[0,\"      \"],[1,[28,\"bs-checkbox\",null,[[\"value\"],[[24,[\"model\",\"sd\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-5\"],[8],[0,\"\\n\"],[4,\"form-group\",null,[[\"property\",\"label\"],[\"project\",[28,\"t\",[\"forms.projecttwo\"],null]]],{\"statements\":[[0,\"      \"],[1,[28,\"bs-checkbox\",null,[[\"value\"],[[24,[\"model\",\"project\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-offset-2 col-md-5\"],[8],[0,\"\\n\"],[4,\"form-group\",null,[[\"property\",\"label\"],[\"proposal\",[28,\"t\",[\"forms.rbk\"],null]]],{\"statements\":[[0,\"      \"],[1,[28,\"bs-checkbox\",null,[[\"value\"],[[24,[\"model\",\"applicationToIntervene\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/ticket-state.hbs"
    }
  });

  _exports.default = _default;
});