define("iris/models/aiops-severance-pay", ["exports", "iris/models/aiops-base", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _aiopsBase, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    contractType: [(0, _emberCpValidations.validator)('presence', true)],
    severancePayType: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _aiopsBase.default.extend(Validations, {
    severancePayFeeMin: (0, _attr.default)('number', {
      formWidget: 'mask'
    }),
    severancePayFeeMax: (0, _attr.default)('number', {
      formWidget: 'mask'
    }),
    customer: (0, _relationships.belongsTo)('aiopsCustomer'),
    contractType: (0, _relationships.belongsTo)('aiopsContractType'),
    severancePayType: (0, _relationships.belongsTo)('aiopsSeverancePayType')
  });

  _exports.default = _default;
});