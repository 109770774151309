define("iris/controllers/settings/roles/role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    competencyAssocs: Ember.computed('model.{actionCompetencyAssocs.@each.competency,competency.id}', function () {
      let id = this.get('model.competency.id');
      return this.get('model.actionCompetencyAssocs').filter(item => {
        return item.get('competency.id') === id;
      });
    }),
    openNodes: Ember.A(),
    actions: {
      change(action, checked) {
        let competency = this.get('model.competency');

        if (checked) {
          competency.get('actions').then(actions => {
            actions.addObject(action);
            competency.save();
          });
        } else {
          competency.get('actions').then(actions => {
            actions.removeObject(action);
            competency.save();
          });
        }
      },

      expand(action) {
        this.get('openNodes').addObject(action);
      },

      collapse(action) {
        this.get('openNodes').removeObject(action);
      }

    }
  });

  _exports.default = _default;
});