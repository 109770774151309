define("iris/routes/settings/payments/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PaymentEditRoute extends Ember.Route {
    model(params) {
      if (params.id == 'new') {
        return this.store.createRecord('payment');
      }

      return this.store.findRecord('payment', params.id).catch(function () {
        return {};
      });
    }

    async setupController(controller, model) {
      super.setupController(...arguments);
      controller.set('selectedPayType', controller.paymentTypes.findBy('id', model.type));
      controller.set('mainSelectedPayType', controller.paymentTypes.findBy('id', model.systemFlag));
      controller.set('selectedRoundType', controller.roundTypes.findBy('id', model.roundType));
    }

  }

  _exports.default = PaymentEditRoute;
});