define("iris/models/advanced-filter", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _model.attr)('string'),
    route: (0, _model.attr)('string'),
    rank: (0, _model.attr)('number'),
    filter: (0, _model.attr)('json', {
      defaultValue: '[]'
    }),
    q: (0, _model.attr)('string')
  });

  _exports.default = _default;
});