define("iris/components/stop-bubbling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'td',
    actions: {
      dummy() {}

    }
  });

  _exports.default = _default;
});