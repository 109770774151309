define("iris/templates/operation/sd-dashboard/edit-preticket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r16oS6ev",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[5,\"issue-report\",[],[[\"@model\",\"@preTicket\"],[[23,0,[\"model\"]],true]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/operation/sd-dashboard/edit-preticket.hbs"
    }
  });

  _exports.default = _default;
});