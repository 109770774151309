define("iris/components/modals/file-upload-promise/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    uploadDocuments: Ember.inject.service(),
    actions: {
      savePendingFiles() {
        let model = this.get('data.model');

        if (model.id) {
          this.get('uploadDocuments').uploadPendingFiles(model);
        }

        this.close(false);
      }

    }
  });

  _exports.default = _default;
});