define("iris/components/on-socket-event", ["exports", "iris/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const socketConfig = {
    transports: ['websocket']
  };
  const layout = Ember.HTMLBars.template({
    "id": "irF9MV6Y",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n  \"],[14,1,[[23,0,[\"refresh\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {}
  });

  var _default = Ember.Component.extend({
    tagName: '',
    layout,
    events: null,
    // @, string | string[]: The events to register to.
    action: null,
    // @, fn, optional: The action to be called when any of the events fire.
    room: '',
    // @, string, optional: The name of the room to join to
    socketIO: Ember.inject.service('socket-io'),
    sessionAccount: Ember.inject.service(),
    registeredEvents: null,
    // The `refresh` property gets reassigned with a random value each time any of
    // the events fire. This is due to how `{{bs-table-server}}` operates at the
    // moment for refreshing its data. Assign the yielded value to its `refresh`
    // property to get it working.
    refresh: 'INITIAL_VALUE',

    init() {
      this._super(...arguments);

      let socket = this.socketIO.socketFor(_environment.default.APP.host, socketConfig);

      if (this.room) {
        socket.emit(this.room, {
          joined: true,
          userId: this.sessionAccount.account.id,
          socketId: socket.id,
          room: this.room
        });
      }

      this.registeredEvents = [].concat(this.events).map(event => {
        return [event, data => {
          this.set('refresh', Math.random());

          if (this.action) {
            this.action(data);
          }
        }];
      });
      this.registeredEvents.forEach(_ref2 => {
        let [event, fn] = _ref2;
        socket.on(event, fn);
      });
    },

    willDestroy() {
      this._super(...arguments);

      let socket = this.socketIO.socketFor(_environment.default.APP.host, socketConfig);
      this.registeredEvents.forEach(_ref3 => {
        let [event, fn] = _ref3;
        socket.off(event, fn);
      });

      if (this.room) {
        socket.emit(this.room, {
          left: true,
          userId: this.sessionAccount.account.id,
          socketId: socket.id,
          room: this.room
        });
      }
    }

  });

  _exports.default = _default;
});