define("iris/mixins/managed-relationships-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    flashMessages: Ember.inject.service(),

    async save() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      let [force] = args;

      let showErrorMessage = (title, message) => {
        this.flashMessages.danger(message, {
          title,
          icon: 'times',
          sticky: true
        });
      };

      if (this.isDirty || this.isNew || force) {
        try {
          return await this._super(...args);
        } catch (e) {
          // Log error mostly for Mirage, which may fail to produce nicely
          // formatted error messages what the API does. This happens frequently
          // when an URL handler is not defined or there is an exception handling
          // the URL.
          console.error(e);
          let title = `Model: ${this.constructor.modelName}`;

          if (Array.isArray(e.errors)) {
            e.errors.forEach(err => {
              var _err$source;

              console.error(err);
              showErrorMessage(title, `
              Detail: ${err.detail}
              Source: ${(_err$source = err.source) === null || _err$source === void 0 ? void 0 : _err$source.pointer}
            `);
            });
          } else {
            showErrorMessage(title, `Uknown error: ${e.toString()}`);
          }

          return false;
        }
      } else {
        return Promise.resolve(this);
      }
    }

  });

  _exports.default = _default;
});