define("iris/routes/settings/news-feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    columns: [{
      name: (void 0).intl.t('news.subject'),
      property: 'subject'
    }, {
      name: (void 0).intl.t('news.contents'),
      property: 'contents'
    }, {
      name: (void 0).intl.t('news.createDate'),
      property: 'createDate'
    }, {
      name: (void 0).intl.t('news.lastUpdate'),
      property: 'lastUpdate'
    }],

    model() {
      return Ember.RSVP.hash({});
    },

    setupController: function (controller, model) {
      this._super(controller, model);

      this.controller.set('columns', this.get('columns'));
    }
  });

  _exports.default = _default;
});