define("iris/models/aiops-solver", ["exports", "iris/models/aiops-base", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _aiopsBase, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    userGrade: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _aiopsBase.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    userPhoto: (0, _attr.default)('string'),
    overheadFee: (0, _attr.default)('number', {
      formWidget: 'mask'
    }),
    userGrade: (0, _relationships.belongsTo)('aiopsUserGrade'),
    userSkills: (0, _relationships.hasMany)('aiopsUserSkill'),
    parameters: (0, _relationships.hasMany)('aiopsPmTypeUserAssocComputed')
  });

  _exports.default = _default;
});