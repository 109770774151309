define("iris/models/recurring-pattern", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-copyable", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberDataCopyable, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true
    })
  });

  var _default = _model.default.extend(_emberDataCopyable.default, Validations, {
    name: (0, _attr.default)('string'),
    startDate: (0, _attr.default)('date'),
    separationCount: (0, _attr.default)('number'),
    maxNumOfOccurrences: (0, _attr.default)('number'),
    dayOfWeek1: (0, _attr.default)('boolean'),
    dayOfWeek2: (0, _attr.default)('boolean'),
    dayOfWeek3: (0, _attr.default)('boolean'),
    dayOfWeek4: (0, _attr.default)('boolean'),
    dayOfWeek5: (0, _attr.default)('boolean'),
    dayOfWeek6: (0, _attr.default)('boolean'),
    dayOfWeek7: (0, _attr.default)('boolean'),
    weekOfMonth: (0, _attr.default)('number'),
    dayOfMonth: (0, _attr.default)('number'),
    monthOfYear: (0, _attr.default)('number'),
    servicePeriodId: (0, _attr.default)('number'),
    recurringType: (0, _relationships.belongsTo)('recurringType'),
    servicePeriod: (0, _relationships.belongsTo)('servicePeriod')
  });

  _exports.default = _default;
});