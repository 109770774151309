define("iris/components/iris-power-select-multiple/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let IrisPowerSelectMultiple = (_dec = Ember.computed('searchEnabled'), _dec2 = Ember.computed('searchField'), (_class = class IrisPowerSelectMultiple extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');
    }

    get _searchEnabled() {
      var _this$searchEnabled;

      return (_this$searchEnabled = this.searchEnabled) !== null && _this$searchEnabled !== void 0 ? _this$searchEnabled : true;
    }

    get _searchField() {
      var _this$searchField;

      return (_this$searchField = this.searchField) !== null && _this$searchField !== void 0 ? _this$searchField : 'name';
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "_searchEnabled", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "_searchEnabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_searchField", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "_searchField"), _class.prototype)), _class));
  _exports.default = IrisPowerSelectMultiple;
});