define("iris/components/beta/filters/manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4QP5dM13",
    "block": "{\"symbols\":[\"@rows\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"q\",\"columns\",\"fuzzyColumns\",\"filters\",\"editedFilter\",\"selectedFilterChangeset\",\"dependentFilters\",\"adHocFilters\",\"savedTabs\"],[[23,0,[\"params\",\"q\"]],[23,0,[\"filterColumns\"]],[23,0,[\"fuzzyColumns\"]],[23,0,[\"filters\"]],[23,0,[\"editedFilter\"]],[23,0,[\"selectedFilterChangeset\"]],[23,0,[\"dependentFilters\"]],[23,0,[\"adHocFilters\"]],[23,0,[\"savedTabs\"]]]]],[28,\"hash\",null,[[\"selectFilter\",\"clearSelection\",\"removeFilter\",\"removeSelectedFilter\",\"addFilter\",\"commit\",\"updateFilters\",\"updateFuzzySearchParam\",\"resetParams\",\"export\",\"saveTab\",\"deleteTab\",\"editTab\",\"sortSavedTabs\"],[[28,\"action\",[[23,0,[]],\"selectFilter\"],null],[28,\"action\",[[23,0,[]],\"clearSelection\"],null],[28,\"action\",[[23,0,[]],\"removeFilter\"],null],[28,\"action\",[[23,0,[]],\"removeSelectedFilter\"],null],[28,\"action\",[[23,0,[]],\"addFilter\"],null],[28,\"action\",[[23,0,[]],\"commit\"],null],[28,\"action\",[[23,0,[]],\"updateFilters\"],null],[28,\"action\",[[23,0,[]],\"updateFuzzySearchParam\"],null],[28,\"action\",[[23,0,[]],\"resetParams\"],null],[28,\"if\",[[23,1,[]],[28,\"action\",[[23,0,[]],\"export\"],null],null],null],[28,\"action\",[[23,0,[]],\"saveTab\"],null],[28,\"action\",[[23,0,[]],\"deleteTab\"],null],[28,\"action\",[[23,0,[]],\"editTab\"],null],[28,\"action\",[[23,0,[]],\"sortSavedTabs\"],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/beta/filters/manager/template.hbs"
    }
  });

  _exports.default = _default;
});