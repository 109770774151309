define("iris/components/portal-dashboard/edit/component", ["exports", "iris/models/ticket-state"], function (_exports, _ticketState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let IssueReportEdit = (_dec = Ember.computed('model.state.id'), (_class = class IssueReportEdit extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');
    }

    get commentDisabled() {
      return [_ticketState.TICKET_STATE_ID.Closed, _ticketState.TICKET_STATE_ID.ImmediatelyResolved].includes(this.model.belongsTo('state').id());
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "commentDisabled", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "commentDisabled"), _class.prototype)), _class));
  _exports.default = IssueReportEdit;
});