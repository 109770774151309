define("iris/controllers/application", ["exports", "iris/utils/properties", "iris/mixins/loading-slider", "iris/lib/modals", "iris/config/environment"], function (_exports, _properties, _loadingSlider, _modals, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_loadingSlider.default, {
    modals: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    serviceMyTickets: Ember.inject.service(),
    themeSwitcher: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    pageTitleList: Ember.inject.service(),
    router: Ember.inject.service(),

    async init() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super(args);

      if (this.sessionAccount.account) {
        // logged in user's id
        const {
          id
        } = this.sessionAccount.account;
        const result = await this.store.query('myTicket', {
          filter: {
            include: ['type']
          }
        });
        this.set('serviceMyTickets.data', result);

        if (!this.router.currentRouteName) {
          this.set('currentMenu', {
            intlKey: 'dashboard'
          });
          return;
        }

        let sliceIndex = this.router.currentRouteName.search(/(\.new|\.edit|\.index)/);
        let systemName = this.router.currentRouteName;

        if (sliceIndex > -1) {
          systemName = systemName.slice(0, sliceIndex);
        }

        const menu = await this.store.query('action', {
          filter: {
            where: {
              systemName: systemName
            }
          }
        });

        if (menu && menu.length) {
          this.set('currentMenu', menu.get('firstObject'));
        } else if (systemName === 'dashboard') {
          this.set('currentMenu', {
            intlKey: 'dashboard'
          });
        }
      }
    },

    isAiops: Ember.computed(function () {
      return location.hostname === 'localhost' || location.hostname.startsWith('aiops') ? true : false;
    }),
    languages: Ember.computed(function () {
      return this.store.findAll('language');
    }),
    version: _environment.default.APP.version,
    taskMenu: false,
    toggleMenu: false,
    toggleProfile: false,
    rightSidebarOpened: false,
    currentMenu: null,
    breadcrumbs: Ember.computed('currentMenu', function () {
      let currentMenu = this.currentMenu;

      if (!currentMenu || !currentMenu.intlKey) {
        return [];
      }

      return currentMenu.intlKey.split('.');
    }),
    pageTitleSegments: null,
    // Since Ember Page Title is scheduled to be updated in the `afterRender`
    // queue, making this a CP would result in the famous "...modified twice in a
    // single render" error.
    //
    // https://github.com/ember-cli/ember-page-title/blob/4.0.5/addon/helpers/page-title.js#L35
    observeTokens: Ember.on('init', (0, _properties.observerOnce)('pageTitleList.sortedTokens.@each.title', function () {
      if (this.pageTitleList.sortedTokens.length === 1) {
        // The first token `{ title: 'Iris', ... }` is always present, fall back to
        // breadcrumbs passed as params.
        return this.set('pageTitleSegments', null);
      }

      return this.set('pageTitleSegments', this.pageTitleList.sortedTokens.slice(1).map(t => t.title));
    })),
    option: Ember.Object.create({
      options: Ember.A()
    }),
    actions: {
      async onChangeAssign() {
        const result = await this.store.query('myTicket', {
          filter: {
            include: ['type']
          }
        });
        this.set('serviceMyTickets.data', result);
      },

      openRightSidebar() {
        let _this = this;

        Ember.run.later({}, function () {
          $('html').addClass('sidebar-right-opened');

          _this.set('rightSidebarOpened', true);
        }, 100);
      },

      closeRightSidebar() {
        $('html').removeClass('sidebar-right-opened');
        this.set('rightSidebarOpened', false);
        this.set('toggleMenu', false);
        this.set('taskMenu', false);
      },

      toggleMenuAction() {
        this.toggleProperty('toggleMenu');
        this.set('taskMenu', false);
      },

      async openSubstitutionModal() {
        await this.modals.open('modals/substitution', {}, {
          className: _modals.ModalLayoutClassName.S
        });
      },

      async toggleProfileAction() {
        this.set('toggleMenu', false);
        const save = await this.modals.open('modals/my-profile', {
          languages: this.languages
        }, {
          className: _modals.ModalLayoutClassName.S
        });
        const {
          ajax,
          flashMessages,
          intl,
          sessionAccount: {
            account
          }
        } = this;

        if (save) {
          try {
            await ajax.call('POST', 'account', account.id, 'changeUserSettings', {
              values: {
                userPhoto: account.get('userPhoto'),
                email: account.get('email'),
                defaultPlace: account.get('defaultPlace'),
                password: account.get('password'),
                languageId: account.get('language.id')
              }
            });
            flashMessages.info(intl.t('general.save.success'), {
              title: intl.t('general.save.success-title'),
              icon: 'floppy-o'
            });
          } catch (error) {
            flashMessages.danger(intl.t('general.save.error'), {
              title: intl.t('general.save.error'),
              icon: 'warning'
            });
          }
        } else {
          account.rollback();
        }
      },

      linkToSettingsRoles: function () {
        this.send('toggleMenuAction');
        this.send('transitionTo', 'settings.roles');
      },

      saveTheme() {
        let account = this.get('sessionAccount.account');
        this.ajax.call('POST', 'account', account.get('id'), 'changeUserSettings', {
          values: {
            theme: account.theme
          }
        }).then(result => {
          if (this.currentRouteName === 'operation.sd-dashboard.index') {
            this.target.send('refresh');
          }
        });
      },

      redirectToTask: function (selected) {
        this.set('taskMenu', false);
        this.transitionToRoute('tasks.tasks.work-logs', selected);
      },
      redirectToRoute: function (route, param) {
        $('html').removeClass('sidebar-right-opened');
        this.set('rightSidebarOpened', false);

        if (param) {
          this.transitionToRoute(route, param);
        } else {
          this.transitionToRoute(route);
        }
      }
    }
  });

  _exports.default = _default;
});