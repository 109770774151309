define("iris/components/button-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fJe3HWfn",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"btn-group flex\"],[10,\"role\",\"group\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"options\",\"length\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\",true],[11,\"class\",[29,[\"btn flex-grow \",[28,\"if\",[[28,\"eq\",[[23,1,[\"id\"]],[24,[\"selected\",\"id\"]]],null],\"btn-primary\",\"btn-default\"],null]]]],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"click\",[23,1,[]]],null]],[8],[0,\"\\n        \"],[1,[28,\"get\",[[23,1,[]],[24,[\"searchField\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"a\",true],[10,\"class\",\"btn flex-grow btn-default\"],[10,\"role\",\"button\"],[8],[0,\"\\n        \"],[1,[28,\"get\",[[24,[\"selected\"]],[24,[\"searchField\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/button-select/template.hbs"
    }
  });

  _exports.default = _default;
});