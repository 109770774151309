define("iris/templates/components/tables/partner-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OIuT0lSk",
    "block": "{\"symbols\":[\"data\",\"columns\",\"row\"],\"statements\":[[4,\"bs-table\",null,[[\"class\",\"data\",\"columns\",\"hasCheckbox\"],[\"no-pointer\",[24,[\"model\"]],[24,[\"filesColumns\"]],false]],{\"statements\":[[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/tables/partner-types.hbs"
    }
  });

  _exports.default = _default;
});