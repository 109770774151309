define("iris/components/forms/entity-procedure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    // DS.Model
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    priorities: [{
      name: 1,
      id: 1
    }, {
      name: 2,
      id: 2
    }, {
      name: 3,
      id: 3
    }, {
      name: 4,
      id: 4
    }, {
      name: 5,
      id: 5
    }],
    priorityCritical: Ember.computed('model.priorityCritical', 'priorities', function () {
      return this.priorities[this.model.priorityCritical - 1];
    }),
    priorityWarning: Ember.computed('model.priorityWarning', 'priorities', function () {
      return this.priorities[this.model.priorityWarning - 1];
    }),
    buttonCheckboxActions: Ember.computed(function () {
      return [{
        text: this.intl.t('buttons.solver-apply-to-all'),
        action: 'solverGroupApplyToAll',
        icon: 'fa fa-user-plus'
      }, {
        text: this.intl.t('buttons.notification-apply-to-all'),
        action: 'notificationApplyToAll',
        icon: 'fa fa-envelope'
      }, {
        text: this.intl.t('buttons.notification-group-apply-to-all'),
        action: 'notificationGroupApplyToAll',
        icon: 'fa fa-users'
      }];
    }),
    actions: {
      setPriorityCritical(value) {
        this.model.set('priorityCritical', value.id);
      },

      setPriorityWarning(value) {
        this.model.set('priorityWarning', value.id);
      },

      setNotificationGroups(selected) {
        this.model.set('notificationGroups', selected);
        this.model.set('dontSaveChange', 1);
        this.model.set('isDirtyTemp', new Date());
        this.model.save();
        this.model.set('dontSaveChange', 0);
      }

    }
  });

  _exports.default = _default;
});