define("iris/models/action-user-assoc", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    systemName: (0, _attr.default)('string'),
    route: (0, _attr.default)('string'),
    objId: (0, _attr.default)('string'),
    userId: (0, _attr.default)('string'),
    user: (0, _relationships.belongsTo)('account'),
    menu: (0, _attr.default)('number'),
    regionId: (0, _attr.default)('string')
  });

  _exports.default = _default;
});