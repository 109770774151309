define("iris/utils/finances", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSessionDefaultCurrency = _exports.getDefaultVat = _exports.getDefaulUOM = void 0;

  /**
   * Gets the active session's regionOwner's currency
   *
   * @param {*} session
   */
  const getSessionDefaultCurrency = async session => {
    const region = session.get('region');
    const ownerPartner = await region.get('ownerPartner');
    return await ownerPartner.get('currencyPayment');
  };
  /**
   * Gets the default vat value which is SYS_03 (0.27) at the moment
   *
   * @param {*} store service
   * @return {*}
   */


  _exports.getSessionDefaultCurrency = getSessionDefaultCurrency;

  const getDefaultVat = async store => {
    return await store.findRecord('vat', 'SYS_03');
  };
  /**
   * Gets the default UOM (SYS_01) db / piece(s)
   *
   * @param {*} store service
   * @return {*}
   */


  _exports.getDefaultVat = getDefaultVat;

  const getDefaulUOM = async store => {
    return await store.findRecord('uom', 'SYS_01');
  };

  _exports.getDefaulUOM = getDefaulUOM;
});