define("iris/components/beta/cmdb/entity-lazy-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "USNSbvPB",
    "block": "{\"symbols\":[\"option\",\"@filter\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"w-72\"],[8],[0,\"\\n  \"],[5,\"input/select/lazy\",[],[[\"@multiple\",\"@modelName\",\"@selected\",\"@onchange\",\"@allowClear\",\"@triggerClass\"],[false,\"Entity\",[23,2,[\"value\"]],[28,\"action\",[[23,0,[]],\"selectFilterValueOption\"],null],true,\"w-72 h-14 overflow-y-hidden\"]],{\"statements\":[[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[1,[23,1,[\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/beta/cmdb/entity-lazy-select/template.hbs"
    }
  });

  _exports.default = _default;
});