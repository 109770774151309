define("iris/components/beta/table/pagination/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bi7mLPmR",
    "block": "{\"symbols\":[\"option\",\"@count\",\"&attrs\",\"@storage\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"flex items-center\"],[13,3],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"mr-8\"],[8],[1,[28,\"t\",[\"labels.total\"],null],false],[0,\": \"],[1,[23,2,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[5,\"power-select\",[],[[\"@options\",\"@selected\",\"@onChange\",\"@allowClear\",\"@searchEnabled\"],[[28,\"array\",[10,25,50,100,250,500,1000],null],[23,4,[\"params\",\"limit\"]],[28,\"action\",[[23,0,[]],\"updateStorageParam\",\"limit\"],null],false,false]],{\"statements\":[[0,\"\\n    \"],[1,[23,1,[]],false],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ml-auto\"],[8],[0,\"\\n    \"],[5,\"beta/pagination\",[],[[\"@limit\",\"@page\",\"@total\",\"@onSelectPage\"],[[23,4,[\"params\",\"limit\"]],[23,4,[\"params\",\"page\"]],[23,2,[]],[28,\"action\",[[23,0,[]],\"updateStorageParam\",\"page\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/beta/table/pagination/template.hbs"
    }
  });

  _exports.default = _default;
});