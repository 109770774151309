define("iris/components/form/fields/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "budtYBSr",
    "block": "{\"symbols\":[\"@model\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"model\",\"field\"],[[23,1,[]],[28,\"component\",[\"form/field\"],[[\"model\"],[[23,1,[]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/form/fields/template.hbs"
    }
  });

  _exports.default = _default;
});