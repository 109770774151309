define("iris/components/advanced-filter/item", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['row'],
    attribute: Ember.computed.alias('criteria.attribute'),
    operator: Ember.computed.alias('criteria.operator'),
    value: Ember.computed.alias('criteria.value'),
    selected: Ember.computed.alias('criteria.selected'),
    searchAttr: Ember.computed.alias('criteria.searchAttr'),
    attributeType: Ember.computed.alias('attribute.meta.type'),
    conditions: Ember.computed('attributeType', function () {
      let attributeType = this.get('attributeType') || '';
      let allConditions = this.get('allConditions');

      if (attributeType === 'boolean') {
        Ember.run.next(() => {
          if (!this.get('isDestroyed') && !this.get('isDestroying')) {
            // TODO: backtracking-rerender
            this.set('operator', allConditions.findBy('value', 'eq'));
          }
        });
        return false;
      }

      let rules = Ember.Object.create({
        'string': Ember.A(['gt', 'lt']),
        'date': Ember.A(['ilike']),
        'belongsTo': Ember.A(['ilike', 'gt', 'lt'])
      });
      let rule = rules.get(attributeType); // no rule found, we return all the conditions

      if (Ember.isNone(rule) || !rule.length) {
        return allConditions;
      }

      let res = allConditions.filter(condition => {
        return !rule.contains(condition.value);
      });
      return res;
    }),
    hasValue: Ember.computed('value', 'selected', function () {
      return this.get('selected') || Ember.isPresent(this.get('value'));
    }),
    isValid: Ember.observer('attribute', 'operator', 'hasValue', function () {
      if (this.get('attribute') && this.get('operator') && this.get('hasValue')) {
        this.set('criteria.valid', true);
      } else {
        this.set('criteria.valid', false);
      }

      return this.get('criteria.valid');
    }),
    component: Ember.computed('attributeType', function () {
      switch (this.get('attributeType')) {
        case 'date':
          return 'bs-datetimepicker';

        case 'belongsTo':
          return 'power-select';

        case 'boolean':
          return 'checkbox';

        default:
          return 'bs-input';
      }
    }),
    actions: {
      changeAttribute(attribute) {
        this.set('attribute', attribute);
        let value = null;
        let selected = null;

        switch (this.get('attributeType')) {
          case 'date':
            value = (0, _moment.default)();
            break;

          case 'boolean':
            value = false;
            break;
        }

        if (this.get('attributeType') === 'belongsTo') {
          let options = this.get('store').findAll(attribute.meta.modelName);
          this.set('options', options);
          options.then(options => {
            this.set('searchAttr', options.get('firstObject.searchAttr'));
          });
        } else {
          this.set('searchAttr', null);
        }

        this.set('value', value);
        this.set('selected', selected);
      }

    }
  });

  _exports.default = _default;
});