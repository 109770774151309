define("iris/models/aiops-optimization-parameter", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    valueFrom: [(0, _emberCpValidations.validator)('presence', true)],
    valueTo: [(0, _emberCpValidations.validator)('presence', true)],
    defaultValue: [(0, _emberCpValidations.validator)('presence', true)],
    parameterType: [(0, _emberCpValidations.validator)('presence', true)],
    parameterCategory: [(0, _emberCpValidations.validator)('presence', true)],
    optimizationItemType: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _model.default.extend(Validations, {
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    name: (0, _attr.default)('string'),
    valueFrom: (0, _attr.default)('number', {
      formWidget: 'mask'
    }),
    valueTo: (0, _attr.default)('number', {
      formWidget: 'mask'
    }),
    defaultValue: (0, _attr.default)('number', {
      formWidget: 'mask'
    }),
    relevancyValue: (0, _attr.default)('number', {
      formWidget: 'mask'
    }),
    parameterType: (0, _relationships.belongsTo)('aiopsParameterType'),
    parameterCategory: (0, _relationships.belongsTo)('aiopsParameterCategory'),
    optimizationItemType: (0, _relationships.belongsTo)('AiopsOptimizationItemType'),
    translations: Ember.A()
  });

  _exports.default = _default;
});