define("iris/templates/components/forms/settings-regional-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r+QjsyQ2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-form\",null,[[\"model\"],[[24,[\"model\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"--iris-inline\"],[8],[0,\"\\n\"],[4,\"form-group\",null,[[\"property\",\"label\"],[\"settingKey\",[28,\"t\",[\"forms.key\"],null]]],{\"statements\":[[0,\"        \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"settingKey\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"--iris-inline\"],[8],[0,\"\\n\"],[4,\"form-group\",null,[[\"property\",\"label\"],[\"settingKey\",[28,\"t\",[\"forms.value\"],null]]],{\"statements\":[[0,\"        \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"settingValue\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/settings-regional-setting.hbs"
    }
  });

  _exports.default = _default;
});