define("iris/models/recurring-type", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-copyable", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberDataCopyable, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true
    })
  });

  var _default = _model.default.extend(_emberDataCopyable.default, Validations, {
    name: (0, _attr.default)('string'),
    serviceLevel: (0, _relationships.hasMany)('recurringPattern')
  });

  _exports.default = _default;
});