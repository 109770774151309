define("iris/components/forms/entity-attribute-custom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    customActivated: false,
    actions: {
      toggleCustomActivated: function () {
        this.toggleProperty('customActivated');
      }
    }
  });

  _exports.default = _default;
});