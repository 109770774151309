define("iris/templates/aiops/optimalizations/optimalization-defaults", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "df87IMqP",
    "block": "{\"symbols\":[\"c\"],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@isRender\",\"@neededFields\",\"@readOnly\"],[\"aiops-optimization-parameter\",true,[28,\"array\",[\"name\",\"parameterType\",\"optimizationItemType\",\"defaultValue\",\"active\"],null],true]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"defaultValue\"],null]],null,{\"statements\":[[0,\"    \"],[5,\"inline-edit\",[],[[\"@saveRecord\",\"@property\",\"@popoverTitle\",\"@model\"],[true,\"defaultValue\",[28,\"t\",[\"menus.optimalization-defaults\"],null],[23,1,[\"row\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/aiops/optimalizations/optimalization-defaults.hbs"
    }
  });

  _exports.default = _default;
});