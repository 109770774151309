define("iris/components/inline-edit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let InlineEdit = (_dec = Ember.computed('property', 'model'), _dec2 = Ember.computed.alias('model.isSaving'), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class InlineEdit extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "classNames", ['inline-edit']);

      _defineProperty(this, "edit", false);

      _defineProperty(this, "formattedValue", null);

      _defineProperty(this, "afterSaving", true);

      _defineProperty(this, "saveButton", true);

      _defineProperty(this, "buttons", true);

      _initializerDefineProperty(this, "saving", _descriptor, this);
    }

    get value() {
      return this.get('model.' + this.property);
    }

    save() {
      const {
        model,
        saveRecord
      } = this;
      this.set('edit', false);

      if (!saveRecord) {
        return;
      }

      if (saveRecord === true) {
        model.save();
        return;
      }

      if (typeof saveRecord === 'function') {
        this.saveRecord(model);
        return;
      }
    }

    edit() {
      this.set('edit', true);
    }

    cancel() {
      this.set('edit', false);
      this.get('model').rollbackAttributes();
    }

    close() {
      this.set('edit', false);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "value", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "value"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "saving", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "edit", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class));
  /*
  export default Component.extend({
    classNames: ['inline-edit'],
    edit: false,
    formattedValue: null,
    afterSaving: true,
    saveButton: true,
    buttons: true,
    value: computed('property', 'model', function () {
      var property = this.get('property');
      return this.get('model.' + property);
    }),
    save() {
      this.set('edit', false);
      if (this.saveRecord) {
        this.model.save();
      }
    },
    saving: alias('model.isSaving'),
    actions: {
      edit() {
        this.set('edit', true);
      },
      cancel() {
        this.set('edit', false);
        this.get('model').rollbackAttributes();
      },
      close() {
        this.set('edit', false);
      },
    },
  });
  */

  _exports.default = InlineEdit;
});