define("iris/transitions/tether", ["exports", "iris/transitions/explode"], function (_exports, _explode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = tether;

  function tether(tetherUse, overlayUse) {
    const transitions = [];
    transitions.push({
      pick: '.liquid-tether > :first-child',
      use: tetherUse
    });

    if (overlayUse) {
      transitions.unshift({
        pick: '.liquid-tether-overlay',
        use: overlayUse
      });
    }

    return _explode.default.apply(this, transitions);
  }
});