define("iris/components/aiops/ticket-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sy+iAb3K",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[8],[0,\"\\n   \"],[5,\"chart-js\",[],[[\"@data\",\"@type\",\"@options\",\"@title\"],[[22,\"data\"],\"doughnut\",[22,\"options\"],[22,\"title\"]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/aiops/ticket-chart/template.hbs"
    }
  });

  _exports.default = _default;
});