define("iris/components/issue-report-edit/status-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7bKhQoc7",
    "block": "{\"symbols\":[\"option\",\"@model\"],\"statements\":[[4,\"if\",[[24,[\"saveTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"text-3xl font-extrabold fa fa-refresh fa-spin fa-fw\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"iris-power-select\",[],[[\"@options\",\"@disabled\",\"@selected\",\"@allowClear\",\"@onchange\",\"@selectedItemComponent\"],[[23,0,[\"statuses\"]],[28,\"not\",[[23,0,[\"statuses\",\"length\"]]],null],[23,2,[\"state\"]],false,[28,\"action\",[[23,0,[]],\"changeState\"],null],\"issue-report-edit/selected-state\"]],{\"statements\":[[0,\"\\n    \"],[1,[23,1,[\"name\"]],false],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/issue-report-edit/status-edit/template.hbs"
    }
  });

  _exports.default = _default;
});