define("iris/controllers/settings/acl/acl/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    selectedActions: Ember.A(),
    propertyOptions: Ember.A([{
      id: '*',
      text: '*',
      condition: {
        property: '*'
      }
    }, {
      id: 'find',
      text: 'find',
      condition: {
        property: 'find'
      }
    }, {
      id: 'findById',
      text: 'findById',
      condition: {
        property: 'findById'
      }
    }, {
      id: '__get__relationName',
      text: '__get__relationName',
      condition: {
        property: '__get__relationName'
      }
    }, {
      id: '__findById__relationName',
      text: '__findById__relationName',
      condition: {
        property: '__findById__relationName'
      }
    }, {
      id: '__count__relationName',
      text: '__count__relationName',
      condition: {
        property: '__count__relationName'
      }
    }, {
      id: 'count',
      text: 'count',
      condition: {
        property: 'count'
      }
    }, {
      id: 'findOne',
      text: 'findOne',
      condition: {
        property: 'findOne'
      }
    }, {
      id: 'upsert',
      text: 'upsert',
      condition: {
        property: 'upsert'
      }
    }, {
      id: 'create',
      text: 'create',
      condition: {
        property: 'create'
      }
    }, {
      id: 'updateAttributes',
      text: 'updateAttributes',
      condition: {
        property: 'updateAttributes'
      }
    }, {
      id: 'deleteById',
      text: 'deleteById',
      condition: {
        property: 'deleteById'
      }
    }, {
      id: 'replaceById',
      text: 'replaceById',
      condition: {
        property: 'replaceById'
      }
    }, {
      id: '__create__relationName',
      text: '__create__relationName',
      condition: {
        property: '__create__relationName'
      }
    }, {
      id: '__updateById__relationName',
      text: '__updateById__relationName',
      condition: {
        property: '__updateById__relationName'
      }
    }, {
      id: '__destroyById__relationName',
      text: '__destroyById__relationName',
      condition: {
        property: '__destroyById__relationName'
      }
    }, {
      id: '__delete__relationName',
      text: '__delete__relationName',
      condition: {
        property: '__delete__relationName'
      }
    }, {
      id: 'replaceOrCreate',
      text: 'replaceOrCreate',
      condition: {
        property: 'replaceOrCreate'
      }
    }, {
      id: 'updateAll',
      text: 'updateAll',
      condition: {
        property: 'updateAll'
      }
    }, {
      id: 'upsertWithWhere',
      text: 'upsertWithWhere',
      condition: {
        property: 'upsertWithWhere'
      }
    }]),
    accessTypeOptions: Ember.A([{
      id: '*',
      text: '*',
      condition: {
        accessType2: '*'
      }
    }, {
      id: 'READ',
      text: 'READ',
      condition: {
        accessType2: 'READ'
      }
    }, {
      id: 'WRITE',
      text: 'WRITE',
      condition: {
        accessType2: 'WRITE'
      }
    }, {
      id: 'EXECUTE',
      text: 'EXECUTE',
      condition: {
        accessType2: 'EXECUTE'
      }
    }]),
    permissionOptions: Ember.A([{
      id: 'DENY',
      text: 'DENY',
      condition: {
        permission: 'DENY'
      }
    }, {
      id: 'ALLOW',
      text: 'ALLOW',
      condition: {
        permission: 'ALLOW'
      }
    }]),
    actions: {
      async afterSave(acl) {
        let builtinAclActionAssocs = await this.model.get('builtinAclActionAssocs');
        await builtinAclActionAssocs.invoke('destroyRecord', true);
        this.selectedActions.forEach(async selectedAction => {
          let data = {
            actionSystemName: selectedAction.get('systemName'),
            actionMenu: selectedAction.get('menu'),
            builtinAcl: this.model
          };
          let res = await this.get('store').createRecord('builtinAclActionAssoc', data).save(true);
        });
      },

      changeProperty(property, option) {
        this.model.set(property, option.id ? option.id : option);
      }

    }
  });

  _exports.default = _default;
});