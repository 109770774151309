define("iris/validators/slug", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    intl: Ember.inject.service(),

    validate(value
    /*, options, model, attribute*/
    ) {
      let bannedWords = ['version', 'array'];

      if (bannedWords.contains(value)) {
        return this.intl.t('validators.slug.bannedWords', {
          bannedWords: bannedWords.join(',')
        });
      }

      if (/^[a-zA-Z0-9_-]*$/.test(value)) {
        return true;
      } else {
        return this.intl.t('validators.slug.allowedCharacters');
      }
    }

  });

  _exports.default = _default;
});