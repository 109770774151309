define("iris/routes/unauth/forgotten-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function (params) {
      let store = this.store;
      let s = window.location.href;
      let id = s.split('?')[1];
      return Ember.RSVP.hash({
        initiator: store.query('changePasswordRequest', {
          filter: {
            where: {
              "token": params.token
            }
          }
        }),
        newPassword: store.find('changePasswordRequest', id),
        reEnterPassword: ''
      });
    },
    actions: {
      saveNewPassword: function (pwd) {
        if (pwd.get('tempPwd') !== this.currentModel.reEnterPassword) {
          this.controller.set('errorMessage', true);
        } else {
          pwd.save();
          this.controllerFor('login').set('passwordChanged', true);
          this.transitionTo('login');
        }
      }
    },

    setupController(controller, model) {
      this._super(controller, model);

      if (model.newPassword.get('successful') !== null) {
        this.transitionTo('login');
      }

      var initiatorUser = this.currentModel.initiator.content[0]._data;
      this.controller.set('initiatorUser', initiatorUser);
    }

  });

  _exports.default = _default;
});