define("iris/controllers/finances/pipelines/contracted", ["exports", "iris/controllers/finances/pipelines/dashboard"], function (_exports, _dashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dashboard.default.extend({
    orderedByBusiness: Ember.computed('model.fileBusinessTypes', 'ordered', function () {
      let result = {};
      this.get('model.fileBusinessTypes').forEach(op => {
        result[op.get('name')] = {
          income: 0,
          weightedIncome: 0
        };
      });
      this.get('ordered').forEach(op => {
        if (!(op.businessTypeName in result)) {
          result[op.businessTypeName] = {
            income: 0,
            weightedIncome: 0
          };
        }

        result[op.businessTypeName].income += parseInt(op.income);
        result[op.businessTypeName].weightedIncome += parseInt(op.weightedIncome);
      });
      return result;
    }),
    orderedBySector: Ember.computed('ordered', function () {
      let result = {
        besorolatlan: {
          income: 0,
          weightedIncome: 0
        },
        közig: {
          income: 0,
          weightedIncome: 0
        },
        üzleti: {
          income: 0,
          weightedIncome: 0
        }
      };
      this.get('ordered').forEach(op => {
        let key = op.partnerSector;
        if (key == null) key = 'besorolatlan';

        if (!(key in result)) {
          result[key] = {
            income: 0,
            weightedIncome: 0
          };
        }

        result[key].income += parseInt(op.income);
        result[key].weightedIncome += parseInt(op.weightedIncome);
      });
      return result;
    }),
    labels: Ember.computed('orderedByBusiness', function () {
      let res = [];

      for (var k in this.get('orderedByBusiness')) {
        res.push(k);
      }

      return res;
    }),
    labels2: Ember.computed('orderedBySector', function () {
      let res = [];

      for (var k in this.get('orderedBySector')) {
        res.push(k);
      }

      return res;
    }),
    values: Ember.computed('orderedByBusiness', function () {
      let res = [];
      let orderedByBusiness = this.get('orderedByBusiness');

      for (var k in orderedByBusiness) {
        res.push(orderedByBusiness[k].income);
      }

      return res;
    }),
    values2: Ember.computed('orderedBySector', function () {
      let res = [];
      let orderedBySector = this.get('orderedBySector');

      for (var k in orderedBySector) {
        res.push(orderedBySector[k].income);
      }

      return res;
    }),
    colors: Ember.computed('orderedByBusiness', function (U) {
      let colors = '#7BC425';
      return colors;
    }),
    colors2: Ember.computed('orderedBySector', function (U) {
      let colors = '#7BC425';
      return colors;
    }),
    dataset: Ember.computed('colors', 'values', function () {
      let colors = this.get('colors');
      let values = this.get('values');
      return [{
        label: 'HUF',
        backgroundColor: '#7BC425',
        borderColor: colors,
        data: values
      }];
    }),
    dataset2: Ember.computed('colors2', 'values2', function () {
      let colors = this.get('colors2');
      let values = this.get('values2');
      return [{
        label: 'HUF',
        backgroundColor: colors,
        borderColor: colors,
        data: values
      }];
    }),
    data: Ember.computed('dataset', 'labels', function () {
      return {
        labels: this.get('labels'),
        datasets: this.get('dataset')
      };
    }),
    data2: Ember.computed('dataset2', 'labels2', function () {
      return {
        labels: this.get('labels2'),
        datasets: this.get('dataset2')
      };
    }),
    options: {
      elements: {
        circles: false
      },
      tooltips: {
        enabled: true
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          ticks: {
            fontSize: 10,
            autoSkip: false,
            maxRotation: 90
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }],
        yAxes: [{
          gridLines: {
            color: '#cbd8e4',
            drawBorder: false,
            tickMarkLength: 1
          },
          ticks: {
            suggestedMax: 8,
            beginAtZero: true
          }
        }]
      }
    }
  });

  _exports.default = _default;
});