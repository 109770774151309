define("iris/helpers/literal-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.literalNumber = literalNumber;

  function literalNumber(params
  /*, hash*/
  ) {
    let num = params[0];
    num = parseInt(num);

    switch (num) {
      case 0:
        return 'zero';

      case 1:
        return 'one';
    }
  }

  var _default = Ember.Helper.helper(literalNumber);

  _exports.default = _default;
});