define("iris/routes/crm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set('sessionAccount', this.get('sessionAccount'));
    }
  });

  _exports.default = _default;
});