define("iris/components/tables/sd-dashboard/component", ["exports", "css-animation-sync", "diff-arrays-of-objects", "ember-concurrency", "fast-deep-equal", "iris/config/environment", "iris/lib/filters", "iris/lib/filters/column", "iris/lib/filters/storage", "iris/lib/filters/types", "iris/lib/grid", "iris/lib/table", "iris/models/ticket-state", "iris/utils/flatten-eq-operators", "moment"], function (_exports, _cssAnimationSync, _diffArraysOfObjects, _emberConcurrency, _fastDeepEqual, _environment, _filters, _column, _storage, _types, _grid, _table, _ticketState, _flattenEqOperators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TICKET_STATE_DEFAULT_ID = '000';
  const PRIORITY_OPTIONS = [{
    name: 'P1',
    id: 1
  }, {
    name: 'P2',
    id: 2
  }, {
    name: 'P3',
    id: 3
  }, {
    name: 'P4',
    id: 4
  }, {
    name: 'P5',
    id: 5
  }, {
    name: 'P6+',
    id: 6
  }];

  var _default = Ember.Component.extend({
    init() {
      this.getLiveSetting.perform();
      this.getPreTickets.perform();

      this._super(...arguments);
    },

    apiPath: 'ticketSlViews/getTickets',
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    modals: Ember.inject.service(),
    regionElement: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    socketIO: Ember.inject.service('socket-io'),
    numberOfPreTickets: null,
    live: null,
    aiops: Ember.computed('regionElement', function () {
      return this.regionElement.show('aiops');
    }),
    socketId: Ember.computed('sessionAccount.account.id', function () {
      const io = this.socketIO.socketFor(_environment.default.APP.host, {
        transports: ['websocket']
      });
      return io.socket.id;
    }),
    updateSocketChannel: Ember.computed('sessionAccount.account.id', function () {
      var _this$sessionAccount$;

      const id = (_this$sessionAccount$ = this.sessionAccount.account) === null || _this$sessionAccount$ === void 0 ? void 0 : _this$sessionAccount$.id;
      return id ? `/tickets/update/${id}` : false;
    }),
    storage: (0, _storage.useStorage)('query-params', {
      controllerPath: 'operation.sd-dashboard.index'
    }),
    columns: (0, _column.columns)(function () {
      const {
        intl
      } = this;
      return [{
        name: 'ticketNumber',
        displayName: this.intl.t('ticket.ticketNumber'),
        fixed: true,
        route: 'operation.sd-dashboard.edit',
        routeParams: (0, _table.rowValues)('id'),
        width: _column.ColumnWidth.LS
      }, {
        name: 'ticketStateId',
        displayName: this.intl.t('ticket.ticketStateId'),
        type: _types.AttributeType.BelongsTo,
        visibility: _types.AttributeVisibility.Filter,

        createOptions(records, nameKey, defaultMapper) {
          // Exclue ticket state named "Default"
          return records.filter(r => r.id !== TICKET_STATE_DEFAULT_ID);
        }

      }, {
        name: 'priority',
        displayName: this.intl.t('ticket.priority'),
        type: _types.AttributeType.Array,
        options: PRIORITY_OPTIONS,
        visibility: _types.AttributeVisibility.Filter,
        formatDisplayValue: value => {
          var _PRIORITY_OPTIONS$fin, _PRIORITY_OPTIONS$fin2;

          return (_PRIORITY_OPTIONS$fin = (_PRIORITY_OPTIONS$fin2 = PRIORITY_OPTIONS.find(po => po.id === value)) === null || _PRIORITY_OPTIONS$fin2 === void 0 ? void 0 : _PRIORITY_OPTIONS$fin2.name) !== null && _PRIORITY_OPTIONS$fin !== void 0 ? _PRIORITY_OPTIONS$fin : '-';
        }
      }, {
        name: 'priority',
        displayName: 'P(!)',
        width: _column.ColumnWidth.XXS,
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'clientPartnerShortName',
        displayName: this.intl.t('ticket.supplierPartner'),
        width: _column.ColumnWidth.LS
      }, {
        name: 'clientPartnerId',
        displayName: this.intl.t('ticket.supplierPartner'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'partner'
      }, {
        name: 'partnerShortName',
        displayName: this.intl.t('ticket.clientPartner'),
        width: _column.ColumnWidth.LS
      }, {
        name: 'partnerId',
        displayName: this.intl.t('ticket.clientPartner'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'partner'
      }, {
        name: 'mergedParentTicketNumber',
        displayName: this.intl.t('ticket.mergedParentTicketNumber'),
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'contactName',
        displayName: this.intl.t('ticket.contact'),
        width: _column.ColumnWidth.SM
      }, {
        name: 'solverName',
        displayName: this.intl.t('ticket.resolver'),
        visibility: _types.AttributeVisibility.Table,
        fuzzySearch: false,
        width: _column.ColumnWidth.S
      }, {
        name: 'createUid',
        displayName: this.intl.t('ticket.creator'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'colleague'
      }, {
        name: 'resolverId',
        displayName: this.intl.t('ticket.resolver'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'colleague'
      }, {
        name: 'isParent',
        displayName: this.intl.t('ticket.parent'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.Boolean
      }, {
        name: 'solvingGroupId',
        displayName: this.intl.t('ticket.solverGroup'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'solvingGroup'
      }, {
        name: 'ticketTypeId',
        displayName: this.intl.t('ticket.type'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'ticketType'
      }, {
        name: 'ticketStateName',
        displayName: this.intl.t('ticket.ticketStateId'),
        fuzzySearch: false,
        width: _column.ColumnWidth.S,
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'subject',
        displayName: this.intl.t('ticket.subject'),
        width: _column.ColumnWidth.LSM
      }, {
        name: 'createDate',
        displayName: this.intl.t('ticket.createDate'),
        type: _types.AttributeType.Date,
        width: _column.ColumnWidth.S,

        formatLoopBackValue(value, filter, defaultFormatter) {
          if (filter.filterType === _types.FilterType.Equal) {
            const from = (0, _moment.default)(value).clone().format('YYYY-MM-DD');
            const to = (0, _moment.default)(value).clone().add(1, 'd').format('YYYY-MM-DD');
            return {
              between: [from, to]
            };
          }

          return defaultFormatter(value);
        }

      }, {
        name: 'reaction',
        displayName: this.intl.t('incident-form.reaction-lc'),
        fuzzySearch: false,
        visibility: _types.AttributeVisibility.Table,
        width: _column.ColumnWidth.S,
        sortable: false,
        exportable: false,
        fixed: 'right'
      }, {
        name: 'start',
        displayName: this.intl.t('incident-form.getting-started-lc'),
        fuzzySearch: false,
        visibility: _types.AttributeVisibility.Table,
        width: _column.ColumnWidth.LS,
        sortable: false,
        exportable: false,
        fixed: 'right'
      }, {
        name: 'end',
        displayName: this.intl.t('incident-form.solution-lc'),
        fuzzySearch: false,
        visibility: _types.AttributeVisibility.Table,
        width: _column.ColumnWidth.LS,
        sortable: false,
        exportable: false,
        fixed: 'right'
      }, {
        name: 'solveDeadline',
        displayName: this.intl.t('incident-form.solution-lc'),
        type: _types.AttributeType.Date,
        visibility: _types.AttributeVisibility.Filter,

        formatLoopBackValue(value, filter, defaultFormatter) {
          if (filter.filterType === _types.FilterType.Equal) {
            const from = (0, _moment.default)(value).clone().format('YYYY-MM-DD');
            const to = (0, _moment.default)(value).clone().add(1, 'd').format('YYYY-MM-DD');
            return {
              between: [from, to]
            };
          }

          return defaultFormatter(value);
        }

      }, {
        name: 'repairTicket',
        displayName: intl.t('operation.sd-dashboard.repair-ticket'),
        type: _types.AttributeType.Boolean,
        visibility: _types.AttributeVisibility.Filter
      }];
    }),
    dropDownActions: Ember.computed('live.value', 'toggleLive', function () {
      var _this$live;

      const name = ((_this$live = this.live) === null || _this$live === void 0 ? void 0 : _this$live.value) === true ? this.intl.t('operation.sd-dashboard.liveOff') : this.intl.t('operation.sd-dashboard.liveOn');
      return [{
        name,
        action: this.toggleLive.bind(this)
      }];
    }),
    getLiveSetting: (0, _emberConcurrency.task)(function* () {
      const {
        intl
      } = this;
      const userSettings = yield this.api.query('userSetting', {
        filter: {
          where: {
            userId: this.sessionAccount.account.id,
            key: 'sd-dashboard-live'
          }
        }
      });

      if (userSettings.length > 1) {
        this.flashMessages.danger(intl.t('operation.sd-dashboard.settings-load-error'), {
          title: intl.t('general.error'),
          icon: 'times',
          sticky: true
        });
      }

      if (userSettings.length === 1) {
        this.set('live', userSettings.firstObject);
      }
    }),

    async toggleLive() {
      let record = this.live;

      if (!record) {
        record = this.store.createRecord('userSetting', {
          user: this.sessionAccount.account,
          key: 'sd-dashboard-live',
          value: true
        });
        this.set('live', record);
      } else {
        record.toggleProperty('value');
      }

      await record.save();

      if (record.value === true) {
        this.send('onReconnect', this.q);
      }
    },

    urgentTicketStates: Ember.computed(function () {
      return this.api.where('ticketState', {
        id: {
          inq: [_ticketState.TICKET_STATE_ID.Unresolved, _ticketState.TICKET_STATE_ID.InProgress, _ticketState.TICKET_STATE_ID.WaitingForReporter, _ticketState.TICKET_STATE_ID.WaitingForThirdParty, _ticketState.TICKET_STATE_ID.Rejected, _ticketState.TICKET_STATE_ID.WaitingForRelease]
        }
      });
    }),
    mappedUrgentTicketStates: Ember.computed.map('urgentTicketStates', function (ticketSate) {
      return {
        name: 'ticketStateId',
        type: _types.FilterType.Equal,
        value: {
          id: ticketSate.id,
          name: ticketSate.name
        }
      };
    }),
    urgentFilter: Ember.computed('mappedUrgentTicketStates.[]', function () {
      return {
        filters: [{
          name: 'priority',
          type: _types.FilterType.ContainsAny,
          value: [1, 2]
        }, ...this.mappedUrgentTicketStates, {
          name: 'mergedParentTicketNumber',
          type: _types.FilterType.Null
        }]
      };
    }),
    predefinedViews: Ember.computed('numberOfPreTickets', 'urgentFilter.filters', function () {
      const {
        intl,
        numberOfPreTickets
      } = this;
      const filters = [{
        name: this.intl.t('general.all'),
        filters: null
      }, {
        name: 'P1-P2',
        filters: this.urgentFilter.filters
      }, {
        name: numberOfPreTickets === null ? this.intl.t('operation.sd-dashboard.preTickets') : this.intl.t('operation.sd-dashboard.preTicketsWithNumber', {
          numberOfPreTickets
        }),
        filters: [{
          name: 'ticketStateId',
          type: _types.FilterType.Equal,
          value: {
            id: _ticketState.TICKET_STATE_ID.PreReport,
            name: this.intl.t('operation.sd-dashboard.preTicket')
          }
        }]
      }];
      const repairTicketFilter = {
        name: intl.t('operation.sd-dashboard.repair-ticket'),
        filters: [{
          name: 'repairTicket',
          type: _types.FilterType.Boolean,
          value: true
        }, {
          name: 'ticketStateId',
          type: _types.FilterType.NotEqual,
          value: {
            id: _ticketState.TICKET_STATE_ID.Closed,
            name: intl.t('menus.closed-reports')
          }
        }]
      };

      if (this.regionElement.show('repair-module')) {
        filters.push(repairTicketFilter);
      }

      return filters;
    }),
    defaultFilters: Ember.computed(function () {
      return filters => {
        let column = (0, _filters.makeColumn)({
          name: 'mergedParentId'
        });
        let mergedParentId = (0, _filters.makeFilterFromColumn)(column, {
          attributeName: 'mergedParentId',
          filterType: _types.FilterType.Equal,
          value: 'null'
        });
        return [mergedParentId];
      };
    }),
    bulkActions: (0, _grid.bulkActions)(function () {
      const {
        store,
        intl
      } = this;
      return [{
        name: intl.t('operation.notification-merger'),
        icon: 'compress',

        async action(selection) {
          if (selection.length < 2) {
            this.flashMessages.warning(this.intl.t('operation.sd-dashboard.mergeTicketsError'), {
              title: 'Figyelem!',
              icon: 'warning'
            });
            return;
          }

          const contactIds = selection.map(s => s.contactId); // check if contacts of the to be merged tickets are the same

          if (new Set(contactIds).size !== 1) {
            const contacts = await this.api.query('contact', {
              filter: {
                where: {
                  id: {
                    inq: [...new Set(contactIds)]
                  }
                },
                include: ['partner']
              }
            });
            const partnerIds = contacts.map(c => c.belongsTo('partner').id());

            if (new Set(partnerIds).size !== 1) {
              this.flashMessages.warning(this.intl.t('operation.sd-dashboard.mergeTicketsErrorUnique'), {
                title: this.intl.t('general.warning'),
                icon: 'warning',
                sticky: true
              });
              return;
            }
          }

          const selectedTicket = await this.modals.open('tables/sd-dashboard/modals/merge', {
            selection
          });

          if (!selectedTicket) {
            return;
          }

          const subTickets = selection.filter(t => t.id !== selectedTicket.id);
          let mainTicketRecord = await store.find('ticket', selectedTicket.id);

          const setTicket = ticket => {
            ticket.set('mergedParentId', mainTicketRecord.id);
            ticket.set('mergedParent', mainTicketRecord);
          };

          for (const ticket of subTickets) {
            let ticketRecord = await store.find('ticket', ticket.id);
            setTicket(ticketRecord);
            await ticketRecord.save();
            const childTickets = await this.api.where('ticket', {
              mergedParentId: ticketRecord.id
            });

            if (childTickets.length) {
              for (const child of childTickets) {
                setTicket(child);
                await child.save();
              }
            }
          }

          this.storage.refresh();
          this.flashMessages.info(intl.t('operation.notification-merger'), {
            title: intl.t('operation.notification-merger'),
            icon: 'check'
          });
          return [];
        }

      }, {
        name: intl.t('operation.restoration'),
        icon: 'expand',

        async action(selection) {
          await this.modals.open('beta/modals/confirmation', {
            title: this.intl.t('operation.restoration'),
            body: this.intl.t('operation.restore-selected-notifications')
          });

          for (const record of selection) {
            let ticketRecord = await this.store.find('ticket', record.id);
            ticketRecord.set('mergedParentId', null);
            ticketRecord.set('mergedParent', null);
            await ticketRecord.save();
          }

          this.storage.refresh();
          this.flashMessages.info(intl.t('operation.restoration-success'), {
            title: intl.t('operation.restoration'),
            icon: 'check'
          });
          return [];
        }

      }];
    }),
    fetchParams: Ember.computed('columns', 'defaultFilters', 'storage.params.filters', 'urgentFilter.filters', function () {
      var _this$live2;

      let {
        filter
      } = (0, _filters.makeQueryObject)(this.columns, this.storage.params, {}); // Unless the `eq` operators are removed and theire value are used directly,
      // the API woud throw.
      //
      // Ex. `{ ticketStateId: { eq: '...' } }` -> `{ ticketStateId: '...' }`

      (0, _flattenEqOperators.flattenEqOperators)(filter.where, 'ticketStateId');
      let order = (0, _fastDeepEqual.default)(this.storage.params.filters, this.urgentFilter.filters) ? ['priority asc', 'solveDeadline asc'] : [filter.order];
      const payload = {
        filter: { ...filter,
          // Order must be an array, otherwise the API would throw
          order
        }
      }; // If live if turned on, we send the socket Id too

      if ((_this$live2 = this.live) !== null && _this$live2 !== void 0 && _this$live2.value) {
        payload.socketId = this.socketId;
      }

      return payload;
    }),

    /**
     * Get the new data with a new socketId.
     * It is used after reconnection, when the client gets a new socketId
     * after reestablish the connection with the backend. The result passed to
     * setIndicators to compare the old and this new data.
     *
     * @param {String} socketId
     * @return {Promise}
     */
    getResult(socketId) {
      const fetchParams = this.fetchParams;
      fetchParams.socketId = socketId;
      return this.api.queryView(this.apiPath, fetchParams);
    },

    getPreTickets: (0, _emberConcurrency.task)(function* () {
      const preTickets = yield this.api.queryView(this.apiPath, {
        filter: {
          where: {
            and: [{
              ticketStateId: 'SYS_17'
            }, {
              deletedAt: null
            }, {
              mergedParentId: null
            }]
          }
        }
      });
      this.set('numberOfPreTickets', preTickets.result.count);
    }),

    /**
     *
     *
     * @param {*} q the last known query
     * @param {*} tickets the new data from backend
     */
    setIndicators(q, tickets) {
      // return if query is still running
      if (q.isLoading) {
        return;
      } // set q if we receive data first time from the backend via websocket


      if (!this.q) {
        this.set('q', q);
      }

      const actualList = this.q.result.result.data; // For checking the delta between the actual and the
      // new list we remove the indicator (moment object),
      // since it never part of the data from backend it
      // would be always seen as difference

      const actualListWoIndicator = actualList.map(_ref => {
        let {
          ROW_CHANGED_INDICATOR,
          ...keepAttrs
        } = _ref;
        return keepAttrs;
      });
      const delta = (0, _diffArraysOfObjects.default)(actualListWoIndicator, tickets.data, 'id');
      const changedRowsInActualList = actualList.filter(r => r.ROW_CHANGED_INDICATOR); // id's of new or changed rows

      const difference = delta.updated.map(r => r.id).concat(delta.added.map(r => r.id));

      if (difference.length === 0) {
        return;
      }

      const withNewIndicator = tickets.data.map(row => {
        const found = changedRowsInActualList.find(r => r.id === row.id);

        if (found) {
          row.ROW_CHANGED_INDICATOR = found.ROW_CHANGED_INDICATOR.clone();
        }

        if (difference.includes(row.id)) {
          row.ROW_CHANGED_INDICATOR = (0, _moment.default)();
        }

        return row;
      });
      Ember.set(this.q.result.result, 'data', withNewIndicator);
      Ember.set(this.q.result.result, 'count', tickets.count);
      (0, _cssAnimationSync.default)('pulse-font');
    },

    actions: {
      async onReconnect(q) {
        this.notifyPropertyChange('socketId');
        this.flashMessages.info(this.intl.t('general.list-refreshed'), {
          title: this.intl.t('general.socket.reconnected'),
          icon: 'check'
        });
        const result = await this.getResult(this.socketId);
        this.setIndicators(q, result.result);
      },

      onDidUpdate(positional) {
        this.set('q', positional[0]);
      },

      socketNewPreticket(number) {
        this.set('numberOfPreTickets', number);
      },

      ticketSocketUpdated(q, tickets) {
        this.setIndicators(q, tickets);
      },

      setAnimation() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        const [element, [hasClass]] = args;

        if (hasClass) {
          this.$(element).parents('tr').addClass('pulse-row');
        } else {
          this.$(element).parents('tr').removeClass('pulse-row');
        }
      }

    }
  });

  _exports.default = _default;
});