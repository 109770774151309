define("iris/components/change-requests/edit/evaluation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    minChar: 50,
    router: Ember.inject.service(),
    modals: Ember.inject.service(),
    intl: Ember.inject.service(),
    disableButton: Ember.inject.service(),
    api: Ember.inject.service(),
    showModal: false,
    disabled: Ember.computed('model.state.id', function () {
      return this.model.belongsTo('state').id() === 'SYS_05';
    }),
    showButton: Ember.computed('model.{evaluationPositive.length,evaluationNegative.length}', 'minChar', function () {
      const str1 = this.model.evaluationPositive;
      const str2 = this.model.evaluationNegative;

      if (typeof str1 !== 'string' || typeof str2 !== 'string') {
        return false;
      }

      if (str1.length < this.minChar || str2.length < this.minChar) {
        return false;
      }

      return true;
    }),
    actions: {
      async closeChangeRequest() {
        const confirmed = await this.modals.open('beta/modals/confirmation', {
          title: this.intl.t('operation.change-request.close-change-request'),
          body: this.intl.t('operation.change-request.close-change-request-confirm')
        });

        if (!confirmed) {
          return;
        }

        this.set('disableButton.disabled', true);
        const flashMessages = this.flashMessages;

        try {
          await this.api.memberAction('changeRequest', 'close', this.model.id, {
            method: 'POST',
            data: this.model.getProperties('evaluationNegative', 'evaluationPositive', 'successful')
          });
          flashMessages.info(this.intl.t('operation.change-request.crCloseSuccess', {
            changeRequest: this.model.name
          }), {
            title: this.intl.t('operation.change-request.closeSuccessfull'),
            icon: 'times'
          });
        } catch (error) {
          flashMessages.danger(this.intl.t('operation.change-request.crCloseError', {
            changeRequest: this.model.name
          }), {
            title: this.intl.t('general.error'),
            icon: 'times'
          });
        }

        await this.model.reload();
        this.router.transitionTo('operation.change-requests.index');
        this.set('disableButton.disabled', false);
      }

    }
  });

  _exports.default = _default;
});