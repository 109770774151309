define("iris/routes/cmdb/entity-types/entities/new-entity/software/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let store = this.store;
      let parentModel = this.modelFor('cmdb.entity-types.entities.new-entity');
      let parentEntity = parentModel.entity;
      let partners = parentModel.partners;
      let entityTypes = parentModel.entityTypes;
      return Ember.RSVP.hash({
        entity: store.createRecord('entity', {
          partner: parentEntity.get('partner'),
          parent: parentEntity
        }),
        parentEntity: parentEntity,
        partners: partners,
        entityTypes: entityTypes
      });
    },

    setupController(controller, model) {
      this._super(controller, model); //let modelBankAccount = this.modelFor('crm.partners.edit');
      //controller.set('currencies', modelBankAccount.currencies);

    }

  });

  _exports.default = _default;
});