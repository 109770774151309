define("iris/components/tables/tasks-table", ["exports", "iris/lib/filters/storage"], function (_exports, _storage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    storage: (0, _storage.useStorage)('memory'),
    ticketStates: Ember.computed(function () {
      let filter = {
        where: {
          and: [{
            project: 1
          }, {
            id: {
              neq: 'SYS_10'
            }
          }]
        }
      };
      return this.store.query('ticketState', {
        filter: filter
      });
    }),
    actions: {
      changeState(ticket, state) {
        Ember.set(ticket, 'state', state);
        this.get('store').find('ticket', ticket.id).then(ticket => {
          ticket.set('state', state);
          ticket.save().then(() => {
            this.storage.refresh();
          });
        });
      }

    }
  });

  _exports.default = _default;
});