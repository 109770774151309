define("iris/components/tables/vacations-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    columns: [],
    data: Ember.computed(function () {
      return this.get('store').query('ticket', {
        filter: {
          where: {
            "ticketTypeId": "SYS_04"
          }
        }
      });
    })
  });

  _exports.default = _default;
});