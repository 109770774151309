define("iris/models/repair-form", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    clientAddress: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    orderNumber: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    deviceSerialNumber: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    deviceType: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    repairEngineer: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    repairClaim: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    client: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  var _default = _model.default.extend(Validations, {
    clientAddress: (0, _attr.default)('string'),
    orderNumber: (0, _attr.default)('string'),
    deviceType: (0, _attr.default)('string'),
    deviceSerialNumber: (0, _attr.default)('string'),
    devicePartNumber: (0, _attr.default)('string'),
    warranty: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    returnable: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    newPartSPN: (0, _attr.default)('string'),
    newPartName: (0, _attr.default)('string'),
    externalID: (0, _attr.default)('string'),
    repairFormNumber: (0, _attr.default)('string'),
    dateRepairStart: (0, _attr.default)('date'),
    dateRepairEnd: (0, _attr.default)('date'),
    dateTicketCreate: (0, _attr.default)('date'),
    repairEngineer: (0, _relationships.belongsTo)('account'),
    repairClaim: (0, _relationships.belongsTo)('repairClaim'),
    client: (0, _relationships.belongsTo)('contact') // this should be stay commented out. On backend RepairForm hasOne ticket
    // but if we patch a repairForm instance with the correct ticket relation id
    // loopback tries to set the ticket's repairFormId to null !?! needs more investigation
    //ticket: belongsTo('ticket'),

  });

  _exports.default = _default;
});