define("iris/components/pell-editor/component", ["exports", "ember-pell/components/pell-editor", "sanitize-html"], function (_exports, _pellEditor, _sanitizeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IrisPellEditor extends _pellEditor.default {
    _setValue() {
      const val = this.get('value');

      if (this.get('pell').innerHTML !== val) {
        this.get('pell').innerHTML = val === undefined ? null : this._sanitize(val);
      }
    }

    _sanitize(html) {
      return (0, _sanitizeHtml.default)(html, {
        allowedTags: ['p', 'div', 'br', 'em', 'strong', 'hr', 'img'],
        allowedClasses: {},
        allowedSchemes: ['http', 'https', 'data'],
        allowedAttributes: {
          '*': ['style'],
          img: ['src']
        },
        allowedStyles: {
          '*': {
            'text-align': [/^left$/, /^right$/, /^center$/],
            margin: [/^\d+(?:px|em|%)$/],
            padding: [/^\d+(?:px|em|%)$/],
            width: [/^\d+(?:px|em|%)$/],
            height: [/^\d+(?:px|em|%)$/]
          }
        }
      });
    }

  }

  _exports.default = IrisPellEditor;
});