define("iris/templates/settings/partners/partner-sectors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rONfSBCK",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@defaultOrder\",\"@noDelete\",\"@externalRowActions\"],[\"partner-sector\",\"settings.partners.partner-sectors.edit\",\"createDate DESC\",true,[23,0,[\"rowActions\"]]]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/partners/partner-sectors.hbs"
    }
  });

  _exports.default = _default;
});