define("iris/components/tables/entity-relations", ["exports", "iris/lib/modals"], function (_exports, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modals: Ember.inject.service(),
    relations: Ember.computed('masterRelations.[]', 'slaveRelations.[]', function () {
      let masterRelations = this.get('masterRelations');
      let slaveRelations = this.get('slaveRelations');
      let result = Ember.A();
      masterRelations.forEach(mr => {
        let entityRelationTypeId = mr.belongsTo('entityRelationType').id();
        let hasType = result.findBy('entityRelationType', entityRelationTypeId);

        if (!hasType) {
          result.pushObject(Ember.Object.create({
            entityRelationType: entityRelationTypeId,
            entityRelationTypeObject: mr.get('entityRelationType'),
            contents: Ember.A(),
            inverse: 0
          }));
        }

        result.findBy('entityRelationType', entityRelationTypeId).get('contents').pushObject({
          entity: mr.get('slaveEntity'),
          entityRelation: mr
        });
      });
      slaveRelations.forEach(sr => {
        let entityRelationTypeId = sr.belongsTo('entityRelationType').id();
        let hasType = result.findBy('entityRelationType', entityRelationTypeId + 'inverse');

        if (!hasType) {
          result.pushObject(Ember.Object.create({
            entityRelationType: entityRelationTypeId + 'inverse',
            entityRelationTypeObject: sr.get('entityRelationType'),
            contents: Ember.A(),
            inverse: 1
          }));
        }

        result.findBy('entityRelationType', entityRelationTypeId + 'inverse').get('contents').pushObject({
          entity: sr.get('masterEntity'),
          entityRelation: sr
        });
      });
      return result;
    }),
    actions: {
      openRelationModal: function () {
        this.modals.open('cmdb/relation-visualisation', {
          entityToVisualise: this.entity,
          entityTypes: this.entityTypes
        }, {
          className: _modals.ModalLayoutClassName.Grow
        });
      }
    }
  });

  _exports.default = _default;
});