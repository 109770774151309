define("iris/components/backtracking-render/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p3noX/DA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[22,\"someProp\"],false],[0,\"\\n\\n\"],[14,1,[[24,[\"someProp\"]],[28,\"action\",[[23,0,[]],\"setProp\"],null]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/backtracking-render/template.hbs"
    }
  });

  _exports.default = _default;
});