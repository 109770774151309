define("iris/helpers/string-index-of", ["exports", "ember-string-fns/helpers/string-index-of"], function (_exports, _stringIndexOf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _stringIndexOf.default;
    }
  });
  Object.defineProperty(_exports, "stringIndexOf", {
    enumerable: true,
    get: function () {
      return _stringIndexOf.stringIndexOf;
    }
  });
});