define("iris/components/trumbowyg-editor", ["exports", "ember-pell/components/pell-editor", "iris/utils/properties"], function (_exports, _pellEditor, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pellEditor.default.extend({
    classNames: ['iris-pell-editor'],
    disabled: false,
    value: Ember.computed.alias('html'),
    onChange: Ember.computed.alias('change'),

    setDisabled() {
      this.pell.setAttribute('contenteditable', !this.disabled);
    },

    didInsertElement: function () {
      this._super(...arguments);

      this.setDisabled();
    },
    disabledDidChange: (0, _properties.observerOnce)('disabled', function () {
      this.setDisabled();
    })
  });

  _exports.default = _default;
});