define("iris/adapters/application", ["exports", "ember-inflector", "ember-data/adapters/json-api", "ember-keycloak-auth/mixins/keycloak-adapter", "iris/config/environment"], function (_exports, _emberInflector, _jsonApi, _keycloakAdapter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_FILTER_STRING_LENGTH = 5500;

  var _default = _jsonApi.default.extend(_keycloakAdapter.default, {
    host: _environment.default.APP.host,
    namespace: _environment.default.APP.namespace,

    pathForType(modelName) {
      var camelized = Ember.String.camelize(modelName);
      return (0, _emberInflector.pluralize)(camelized);
    },

    handleResponse(status, headers, payload, requestData) {
      let total = parseInt(headers['x-total-count']);

      if (!Number.isNaN(total)) {
        payload.meta = { ...payload.meta,
          total
        };
      }

      return this._super(status, headers, payload, requestData);
    },

    async ajax(url, type, options) {
      // Mirage can't handle the serialized `filter` attribute, nor it needs to
      // have, as there is no limitation when the HTTP layer is mocked out.
      if (!Ember.testing) {
        try {
          var _options$data;

          if (options !== null && options !== void 0 && (_options$data = options.data) !== null && _options$data !== void 0 && _options$data.filter) {
            // Avoids mutating the data property
            options.data = JSON.parse(JSON.stringify(options.data));
            options.data.filter = JSON.stringify(options.data.filter);

            if (options.data.filter.length > MAX_FILTER_STRING_LENGTH) {
              let lzutf8Module = await emberAutoImportDynamic("lzutf8");
              let lzutf8 = lzutf8Module.default;
              let compressed = lzutf8.compress(options.data.filter, {
                outputEncoding: 'Base64'
              });
              options.data.filter = compressed;
            }
          }
        } catch (error) {
          console.error(error);
        }
      }

      return this._super(url, type, options);
    }

  });

  _exports.default = _default;
});