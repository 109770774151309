define("iris/controllers/human-resource-management/my-events/new", ["exports", "iris/utils/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    myEventsController: Ember.inject.controller('human-resource-management/my-events'),

    init() {
      this._super(...arguments);

      this.notifyPropertyChange('maskedEndDate');
    },

    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    maskedEndDate: Ember.computed('model.end', 'model.event', 'model.event.id', function () {
      return (0, _moment.default)(this.get('model.end')).subtract(2, 'h');
    }),
    currentUser: Ember.computed('session.data.authenticated.userId', function () {
      let currentUserId = this.get('session.data.authenticated.userId');
      return this.get('store').find('account', currentUserId);
    }),
    types: Ember.computed(function () {
      return this.get('store').query('eventType', {
        filter: {
          order: 'name'
        }
      });
    }),
    minDate: (0, _moment.default)().add(1, 'd'),
    endDateMinDate: Ember.computed('model.start', function () {
      return this.get('model.start') ? this.get('model.start') : this.get('minDate');
    }),
    disabledAccept: Ember.computed('session.data.authenticated.userId', 'model.event', function () {
      return this.get("session.data.authenticated.userId") !== this.get('model.event.user.leaderId');
    }),
    eventIsAccepted: Ember.computed('model.event', function () {
      return this.get('model.event.isAccepted') === true ? true : this.get('model.event.isAccepted') === false ? false : null;
    }),
    eventIsbeforeTwoWeeks: Ember.computed('model.start', function () {
      let model = this.get('model');

      if (!model || !model.start) {
        return false;
      }

      return (0, _moment.default)(model.start).isBefore((0, _moment.default)().add(14, 'd'));
    }),
    disableSave: Ember.computed('eventIsbeforeTwoWeeks', 'model.event.emergency', function () {
      return this.get('eventIsbeforeTwoWeeks') && !this.get('model.event.emergency');
    }),
    actions: {
      async saveEvent() {
        if (this.get('currentUser').get('remainingHolidays') - (0, _moment.default)(this.get('model').end).diff((0, _moment.default)(this.get('model').end), 'd') < 0) {
          const flashMessages = Ember.get(this, 'flashMessages');
          flashMessages.danger(this.intl.t('hrm.notEnoughHoliday'), {
            title: this.intl.t('hrm.notEnoughHolidayTitle'),
            icon: 'times',
            sticky: true
          });
          return;
        }

        let {
          validations
        } = await (0, _model.validateRecord)(this.model.event);
        this.model.event.setValidated();

        if (!validations.isValid) {
          return;
        }

        this.model.event.set('user', this.currentUser);
        let event = this.model.event.serialize();
        let diff = (0, _moment.default)(this.get('model').end).diff((0, _moment.default)(this.get('model').start), 'd');
        event.data.attributes.days = [];

        for (let i = 0; i < diff; i++) {
          event.data.attributes.days.push((0, _moment.default)(this.get('model').start).add(i, 'd').format('YYYY-MM-DD'));
        }

        try {
          await this.ajax.call('POST', 'event', null, 'saveEvent', {
            event: event
          });
        } catch (error) {
          const flashMessages = Ember.get(this, 'flashMessages');
          let errorString = error.errors.map(_ref => {
            let {
              detail
            } = _ref;
            return detail;
          }).join('\n');
          flashMessages.danger(errorString, {
            title: this.intl.t('general.error'),
            icon: 'times',
            sticky: true
          });
        } finally {
          this.router.transitionTo('human-resource-management.my-events');
          this.get('myEventsController').refreshEvents();
        }
      }

    }
  });

  _exports.default = _default;
});