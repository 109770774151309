define("iris/templates/components/tables/entity-relations-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KsGmu4i/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ml40 row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-8\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"hasLink\"]]],null,{\"statements\":[[0,\"      \"],[7,\"h5\",false],[12,\"style\",\"display:inline\"],[3,\"action\",[[23,0,[]],\"linkToEntity\",[24,[\"entity\"]]]],[8],[7,\"a\",true],[8],[1,[24,[\"entity\",\"name\"]],false],[9],[9],[0,\" \"],[7,\"i\",true],[8],[0,\"(\"],[1,[24,[\"entity\",\"entityType\",\"longName\"]],false],[0,\")\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"h5\",true],[10,\"style\",\"display:inline\"],[8],[1,[24,[\"entity\",\"name\"]],false],[9],[0,\" \"],[7,\"i\",true],[8],[0,\"(\"],[1,[24,[\"entity\",\"entityType\",\"longName\"]],false],[0,\")\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-4 pull-right\"],[8],[0,\"\\n    \"],[1,[28,\"iris-button\",null,[[\"icon\",\"action\"],[\"glyphicon glyphicon-remove\",[28,\"action\",[[23,0,[]],\"removeRelation\",[24,[\"parent\"]],[24,[\"entityRelation\"]],[24,[\"inverse\"]]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"hr\",true],[10,\"class\",\"--porto-hr\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/tables/entity-relations-row.hbs"
    }
  });

  _exports.default = _default;
});