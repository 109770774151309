define("iris/routes/settings/acl/acl/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AclEditRoute extends Ember.Route {
    async model(params) {
      if (params.id == 'new') {
        return await this.store.createRecord('builtinAcl');
      }

      return await this.store.findRecord('builtinAcl', params.id, {
        include: 'builtinAclActionAssocs'
      });
    }

    async setupController(controller, model) {
      super.setupController(controller, model);
      const builtinAclActionAssocs = await model.get('builtinAclActionAssocs');
      let models = await this.get('ajax').call('GET', 'Actions', '', 'getModelList', {});
      controller.set('models', models);

      if (!Ember.isEmpty(builtinAclActionAssocs)) {
        let and = [];
        let where_ = null;
        builtinAclActionAssocs.forEach(baaa => {
          and.push({
            and: [{
              systemName: baaa.get('actionSystemName')
            }, {
              menu: baaa.get('actionMenu')
            }]
          });
        });

        if (and.length > 1) {
          where_ = {
            or: and
          };
        } else if (and.length == 1) {
          where_ = and[0];
        } else {
          where_ = {};
        }

        let actions = await this.get('store').query('action', {
          filter: {
            where: where_
          }
        });
        controller.set('selectedActions', actions);
      }

      ;
    }

  }

  _exports.default = AclEditRoute;
});