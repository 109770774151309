define("iris/components/document-type-competency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),

    async init() {
      this._super();

      const store = this.get('store');
      const documentType = this.get('documentType');
      const competency = this.get('competency');
      const res = await store.query('documentTypeCompetency', {
        filter: {
          where: {
            competencyId: competency.id,
            documentTypeId: documentType.id
          }
        }
      });

      if (res.get('length') === 1) {
        this.set('documentTypeCompetency', res.get('firstObject'));
      } else {
        const record = store.createRecord('documentTypeCompetency', {
          documentType: documentType,
          competency: competency
        });

        try {
          const res = await record.save(true);
          this.set('documentTypeCompetency', res);
        } catch (e) {
          console.error(e);
        }
      }
    },

    tagName: 'tr',
    documentTypeCompetency: null,
    actions: {
      checkboxClicked(command) {
        const record = this.get('documentTypeCompetency');
        record.toggleProperty(command);
        record.save();
      }

    }
  });

  _exports.default = _default;
});