define("iris/models/change-request", ["exports", "@ember-data/model", "ember-cp-validations", "ember-data/relationships", "iris/models/ticket-state"], function (_exports, _model, _emberCpValidations, _relationships, _ticketState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/use-ember-data-rfc-395-imports
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    description: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    from: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    to: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    tokenValid: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    state: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    type: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    impact: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    project: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    changeManager: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    priority: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    riskDefault: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    riskDefaultHandle: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    riskDefaultLevel: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('belongs-to'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    riskIntervention: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    riskInterventionHandle: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    riskInterventionLevel: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    riskInfra: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    riskInfraHandle: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    riskInfraLevel: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    operationServiceLevel: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    serviceLevel: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    evaluationPositive: [(0, _emberCpValidations.validator)('length', {
      //dependentKeys: ['model.sate.id'],
      //disabled: computed.not('model.state.id', 'SYS_03').volatile(),
      min: 50
    })],
    evaluationNegative: (0, _emberCpValidations.validator)('length', {
      dependentKeys: ['model.sate.id'],
      disabled: Ember.computed.not('model.state.id', 'SYS_03').volatile(),
      min: 50
    })
  });

  var _default = _model.default.extend(Validations, {
    // Change tracker 0.7.6 thinks attributes with custom transform are belongsTo
    // relationships.
    //
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    changeTracker: {
      except: ['affectedEntities']
    },
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    riskDefault: (0, _model.attr)('string'),
    riskDefaultHandle: (0, _model.attr)('string'),
    riskIntervention: (0, _model.attr)('string'),
    riskInterventionHandle: (0, _model.attr)('string'),
    riskInfra: (0, _model.attr)('string'),
    riskInfraHandle: (0, _model.attr)('string'),
    from: (0, _model.attr)('date'),
    to: (0, _model.attr)('date'),
    tokenValid: (0, _model.attr)('date'),
    affectedEntities: (0, _model.attr)('json', {
      defaultValue: '[]'
    }),
    successful: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    evaluationPositive: (0, _model.attr)('string'),
    evaluationNegative: (0, _model.attr)('string'),
    project: (0, _relationships.belongsTo)('project'),
    state: (0, _relationships.belongsTo)('changeRequestState'),
    priority: (0, _relationships.belongsTo)('changeRequestPriority'),
    changeManager: (0, _relationships.belongsTo)('account'),
    type: (0, _relationships.belongsTo)('changeRequestType'),
    impact: (0, _relationships.belongsTo)('changeRequestImpact'),
    riskDefaultLevel: (0, _relationships.belongsTo)('changeRequestRiskLevel'),
    riskInterventionLevel: (0, _relationships.belongsTo)('changeRequestRiskLevel'),
    riskInfraLevel: (0, _relationships.belongsTo)('changeRequestRiskLevel'),
    operationServiceLevel: (0, _relationships.belongsTo)('operationServiceLevel'),
    serviceLevel: (0, _relationships.belongsTo)('serviceLevel'),
    notifications: (0, _model.hasMany)('changeRequestNotification'),
    tasks: (0, _model.hasMany)('changeRequestTask'),
    entities: (0, _model.hasMany)('entity'),
    showTickets: Ember.computed('state.id', function () {
      return this.belongsTo('state').id() === _ticketState.TICKET_STATE_ID.WaitingForRelease;
    })
  });

  _exports.default = _default;
});