define("iris/components/aiops/dashboards/competency/time-utilization-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cieNO/HN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mt-12 panel panel-default\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"mx-6\"],[8],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-sm-9 aiops-page-header\"],[8],[0,\"\\n          \"],[7,\"h2\",true],[10,\"class\",\"h2\"],[8],[1,[28,\"t\",[\"aiops.dashboars.time-utilization-chart\"],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"col-sm-3 mt-3\"],[8],[0,\"\\n          \"],[5,\"bs-daterangepicker\",[],[[\"@opens\",\"@start\",\"@end\",\"@applyAction\"],[\"left\",[23,0,[\"from\"]],[23,0,[\"to\"]],[28,\"action\",[[23,0,[]],\"getData\"],null]]]],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"py-4\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"w-full border-t border-gray-300 dark:border-black\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \\n      \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-sm-12 m-8\"],[8],[0,\"\\n          \"],[5,\"chart-js\",[[12,\"height\",\"100\"]],[[\"@data\",\"@type\",\"@options\"],[[23,0,[\"chartData\"]],\"horizontalBar\",[22,\"options\"]]]],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/aiops/dashboards/competency/time-utilization-chart/template.hbs"
    }
  });

  _exports.default = _default;
});