define("iris/templates/components/option-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6pOYZ1HH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\",true],[10,\"class\",\"user-photo\"],[11,\"src\",[29,[[24,[\"model\",\"userPhoto\"]]]]],[8],[9],[0,\" \"],[1,[24,[\"model\",\"longName\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/option-user.hbs"
    }
  });

  _exports.default = _default;
});