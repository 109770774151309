define("iris/components/visualisation-icon/modal/component", ["exports", "ember-concurrency", "iris/utils/ember-concurrency", "iris/utils/properties"], function (_exports, _emberConcurrency, _emberConcurrency2, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let VisualisationIconModal = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.mapBy('icons', 'visualisationCategory'), _dec4 = Ember.computed.uniqBy('visualisationCategories', 'id'), _dec5 = Ember.computed.alias('loadIconsTask.last.value'), _dec6 = Ember.computed('searchTerm', 'icons.[]', 'selectedCategory.id'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class VisualisationIconModal extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "searchTerm", '');

      _defineProperty(this, "selectedIcon", null);

      _defineProperty(this, "selectedCategory", null);

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor2, this);

      _initializerDefineProperty(this, "visualisationCategories", _descriptor3, this);

      _initializerDefineProperty(this, "uniqueCategories", _descriptor4, this);

      _initializerDefineProperty(this, "icons", _descriptor5, this);

      this.loadIconsTask.perform();
    }

    *loadIconsTask() {
      return yield this.api.query('entityTypeVisualisation', {
        filter: {
          include: ['visualisationCategory']
        }
      });
    }

    *searchTask(searchTerm) {
      if (_properties.DEFAULT_DEBOUNCE_MS > 0) {
        yield (0, _emberConcurrency2.timeout)(_properties.DEFAULT_DEBOUNCE_MS);
      }

      this.set('searchTerm', searchTerm);
    }

    get filteredIcons() {
      const {
        searchTerm,
        selectedCategory
      } = this;
      let icons = this.icons;

      if (searchTerm) {
        const lowerCase = searchTerm.toLowerCase();
        icons = icons.filter(i => i.get('name').toLowerCase().includes(lowerCase));
      }

      if (selectedCategory) icons = icons.filter(i => i.belongsTo('visualisationCategory').id() === selectedCategory.get('id'));
      return icons;
    }

    selectIcon(icon) {
      icon = icon === this.selectedIcon ? null : icon;
      this.set('selectedIcon', icon);
    }

    getIconIndex() {
      if (!this.selectedIcon) {
        return -1;
      }

      return this.filteredIcons.indexOf(this.selectedIcon);
    }

    stepRight() {
      let index = this.getIconIndex();

      if (index > -1 && index < this.filteredIcons.length) {
        const next = this.filteredIcons.objectAt(++index);
        this.selectIcon(next);
      }
    }

    stepLeft() {
      let index = this.getIconIndex();

      if (index > 0) {
        const prev = this.filteredIcons.objectAt(--index);
        this.selectIcon(prev);
      }
    }

    search(searchTerm) {
      this.searchTask.perform(searchTerm);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadIconsTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadIconsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchTask"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "visualisationCategories", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uniqueCategories", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "icons", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "filteredIcons", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "filteredIcons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stepRight", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "stepRight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stepLeft", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "stepLeft"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "search", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype)), _class));
  _exports.default = VisualisationIconModal;
});