define("iris/components/change-requests/edit/summary/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    tagName: '',
    note: '',
    actions: {
      async confirm(value) {
        this.set('feedback', 'FEEDBACK-PROCESSING');
        const res = await this.ajax.call('POST', 'changeRequestNotification', '', 'confirm', {
          token: this.model.token,
          value,
          message: this.note
        }, false);
        this.set('feedback', res.status);
      }

    }
  });

  _exports.default = _default;
});