define("iris/models/nav-setting", ["exports", "ember-data", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _emberData, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    signatureKey: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    exchangeKey: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    login: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    taxNumber: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    partner: [(0, _emberCpValidations.validator)('belongs-to'), (0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _emberData.default.Model.extend(Validations, {
    signatureKey: (0, _attr.default)('string'),
    exchangeKey: (0, _attr.default)('string'),
    login: (0, _attr.default)('string'),
    pass: (0, _attr.default)('string'),
    taxNumber: (0, _attr.default)('string'),
    demo: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    import: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    partner: (0, _relationships.belongsTo)('partner')
  });

  _exports.default = _default;
});