define("iris/models/types/entity-procedure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EntityProcedureChange = void 0;
  let EntityProcedureChange;
  _exports.EntityProcedureChange = EntityProcedureChange;

  (function (EntityProcedureChange) {
    EntityProcedureChange[EntityProcedureChange["Unchanged"] = 0] = "Unchanged";
    EntityProcedureChange[EntityProcedureChange["Changed"] = 1] = "Changed";
    EntityProcedureChange[EntityProcedureChange["Deleted"] = 2] = "Deleted";
  })(EntityProcedureChange || (_exports.EntityProcedureChange = EntityProcedureChange = {}));
});