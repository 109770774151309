define("iris/routes/tasks/tasks/work-logs/new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    parentRoute: 'tasks.tasks.work-logs',

    model() {
      let date = (0, _moment.default)();
      let round = 15;
      let remainder = (round - date.minute()) % round;
      date.add(remainder, 'm');
      let ticket = this.modelFor(this.get('parentRoute'));
      let user = this.get('sessionAccount.account');
      return this.store.createRecord('workLog', {
        ticket: ticket,
        description: this.intl.t('operation.report-submit.new-worksheet'),
        user: user,
        beginDate: date.toDate(),
        endDate: date.clone().add(1, 'h').toDate()
      });
    },

    buttonActions: Ember.computed(function () {
      return [{
        text: this.intl.t('general.download'),
        action: 'onDownloadBtnClick',
        type: 'primary',
        icon: 'fa fa-download'
      }];
    }),
    uploadTitle: Ember.computed(function () {
      return this.intl.t('attachments.uploadTitle');
    }),

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('parentRoute', this.get('parentRoute'));
      this.controller.set('buttonActions', this.get('buttonActions'));
    }

  });

  _exports.default = _default;
});