define("iris/helpers/to-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toArray = toArray;

  function toArray(params
  /*, hash*/
  ) {
    let promiseArray = params[0];

    if (promiseArray) {
      return promiseArray.toArray();
    } else {
      return null;
    }
  }

  var _default = Ember.Helper.helper(toArray);

  _exports.default = _default;
});