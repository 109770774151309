define("iris/components/time-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    view: 'hours',
    wholeDay: false,
    hours: Ember.computed('wholeDay', function () {
      let res = Ember.A();
      const numberOfHours = this.wholeDay ? 24 : 12;

      for (let i = 0; i < numberOfHours; i++) {
        res.pushObject(i);
      }

      return res;
    }),
    minutes: Ember.computed(function () {
      let res = Ember.A();

      for (let i = 0; i < 4; i++) {
        res.pushObject(i * 0.25);
      }

      return res;
    }),
    actions: {
      selectHour(hour) {
        this.set('value', hour);
        this.set('view', 'minutes');

        if (typeof this.onSelectHour === 'function') {
          this.onSelectHour(hour);
        }
      },

      addMinute(minute, dropdown) {
        let hour = this.get('value');
        this.set('value', hour + minute);
        dropdown.actions.close();
        this.set('view', 'hours');

        if (typeof this.onSelectMinute === 'function') {
          this.onSelectMinute(minute);
        }
      },

      back() {
        this.set('view', 'hours');
      }

    }
  });

  _exports.default = _default;
});