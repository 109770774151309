define("iris/components/operational-file/file-balance/balance/outgoing-proposals/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OperationalFileBalanceOutgoingProposals = (_dec = Ember.computed.alias('balance.outgoingProposals'), _dec2 = Ember._action, (_class = class OperationalFileBalanceOutgoingProposals extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "openedOutgoingProposalTypes", Ember.A());

      _initializerDefineProperty(this, "outgoingProposals", _descriptor, this);
    }

    toggleOutgoingProposalType(outgoingProposalType) {
      if (this.openedOutgoingProposalTypes.includes(outgoingProposalType)) {
        this.openedOutgoingProposalTypes.removeObject(outgoingProposalType);
      } else {
        this.openedOutgoingProposalTypes.addObject(outgoingProposalType);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "outgoingProposals", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleOutgoingProposalType", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "toggleOutgoingProposalType"), _class.prototype)), _class));
  _exports.default = OperationalFileBalanceOutgoingProposals;
});