define("iris/components/forms/tasks-plan", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberCan.CanMixin, {
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super();

      if (this.get('delegated')) {
        let ticket = this.get('model');
        if (ticket.hasMany('ticketUserAssocs').ids().length === 0) ticket.addTicketUserAssoc();
      }
    },

    canActions: ['projects.files.tickets-open', 'projects.files.tickets-open.create'],
    canProjects: Ember.computed('projects.[]', function () {
      let canActions = this.get('canActions');
      return this.get('projects').filter(project => {
        return this.can('read action', {
          obj: project,
          canActions: canActions
        });
      });
    }),
    allTUAsValid: Ember.computed('model.ticketUserAssocs.@each.isValid', function () {
      var tuas = this.get('model.ticketUserAssocs');
      return tuas.filterBy('isValid', true).get('length');
    }),
    tuasMapByUser: Ember.computed.mapBy('model.ticketUserAssocs', 'user'),
    alreadyDelegated: Ember.computed.filterBy('tuasMapByUser', 'id'),
    filteredAccounts: Ember.computed('accounts.[]', 'alreadyDelegated.[]', function () {
      let alreadyDelegated = this.get('alreadyDelegated').mapBy('id');
      return this.get('accounts').filter(account => {
        return !alreadyDelegated.contains(account.id);
      });
    }),
    allPlannedExpensesInHour: Ember.computed('model.ticketUserAssocs.@each.plannedExpensesInHour', function () {
      return this.get('model.ticketUserAssocs').reduce(function (previousValue, item, index, enumerable) {
        let hour = parseInt(item.get('plannedExpensesInHour'));
        hour = isNaN(hour) ? 0 : hour;
        return previousValue + hour;
      }, 0);
    }),

    _recomputeTicket() {
      this.get('model').set('plannedExpensesInHour', this.get('allPlannedExpensesInHour'));
    },

    actions: {
      present(item) {
        // burnt-in (mailing address)
        return Ember.isPresent(item) && item.get('addressType.id') !== 'SYS_03';
      },

      addTicketUserAssoc() {
        this.get('model').addTicketUserAssoc();
      },

      removeTicketUserAssoc(tua) {
        this.get('model').get('ticketUserAssocs').then(ticketUserAssocs => {
          ticketUserAssocs.removeObject(tua);

          this._recomputeTicket();
        });
      },

      recomputeTicket() {
        this._recomputeTicket();
      }

    }
  });

  _exports.default = _default;
});