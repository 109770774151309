define("iris/utils/properties", ["exports", "ember-concurrency", "iris/utils/properties/computed-task", "iris/utils/properties/observer-task"], function (_exports, _emberConcurrency, _computedTask, _observerTask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.action = _exports.DEFAULT_DEBOUNCE_MS = void 0;
  _exports.asyncObserver = asyncObserver;
  Object.defineProperty(_exports, "computedTask", {
    enumerable: true,
    get: function () {
      return _computedTask.default;
    }
  });
  _exports.integerToString = _exports.debounceWithTask = void 0;
  _exports.observerOnce = observerOnce;
  Object.defineProperty(_exports, "observerTask", {
    enumerable: true,
    get: function () {
      return _observerTask.default;
    }
  });

  function asyncObserver() {
    for (var _len = arguments.length, observerArgs = new Array(_len), _key = 0; _key < _len; _key++) {
      observerArgs[_key] = arguments[_key];
    }

    let fn = observerArgs.pop();
    (false && !(typeof fn === 'function') && Ember.assert('observer must be provided a function', typeof fn === 'function'));
    return Ember.observer.apply(Ember, [...observerArgs, function () {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      // using Ember.run.backburner.schedule instead of Ember.run.schedule specifically to ensure
      // that the autorun assertion (only present in Ember < 3.4) does not get fired for async
      // observers
      Ember.run.backburner.schedule('actions', this, fn, ...args);
    }]);
  }

  function observerOnce() {
    for (var _len3 = arguments.length, observerArgs = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      observerArgs[_key3] = arguments[_key3];
    }

    let fn = observerArgs.pop();
    (false && !(typeof fn === 'function') && Ember.assert('observer must be provided a function', typeof fn === 'function'));
    return Ember.observer.apply(Ember, [...observerArgs, function () {
      for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
        args[_key4] = arguments[_key4];
      }

      // using Ember.run.backburner.scheduleOnce instead of
      // Ember.run.scheduleOnce specifically to ensure that the autorun
      // assertion (only present in Ember < 3.4) does not get fired for async
      // observers
      Ember.run.backburner.scheduleOnce('actions', this, fn, ...args);
    }]);
  }

  const DEFAULT_DEBOUNCE_MS = 500;
  _exports.DEFAULT_DEBOUNCE_MS = DEFAULT_DEBOUNCE_MS;

  const debounceWithTask = function (property) {
    let ms = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_DEBOUNCE_MS;
    return (0, _emberConcurrency.task)(function* (value) {
      yield (0, _emberConcurrency.timeout)(ms);
      this.set(property, value);
    }).restartable();
  };

  _exports.debounceWithTask = debounceWithTask;

  const action = a => Ember.computed(function () {
    return a.bind(this);
  }); // https://alisdair.mcdiarmid.org/typecasting-form-fields-in-ember/


  _exports.action = action;

  const integerToString = attribute => Ember.computed(attribute, {
    get() {
      let value = this.get(attribute);

      if (typeof value === 'number') {
        return value.toString();
      }

      return value;
    },

    set(key, value) {
      this.set(attribute, parseInt(value, 10));
      return value;
    }

  });

  _exports.integerToString = integerToString;
});