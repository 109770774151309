define("iris/routes/projects/changes", ["exports", "iris/routes/projects/files"], function (_exports, _files) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _files.default.extend({});

  _exports.default = _default;
});