define("iris/routes/finances/invoices/storno", ["exports", "iris/routes/finances/invoices/new"], function (_exports, _new) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _new.default.extend({
    intl: Ember.inject.service(),
    storno: true,
    templateName: 'finances/invoices/new',

    async createInvoice(params) {
      let store = this.store;
      let originalInvoice = await store.findRecord('invoice', params.invoice_id);
      let invoiceType = await store.findRecord('invoiceType', 'SYS_02'); // burnt in

      let stornoInvoice = await originalInvoice.copy(true);
      let currency = await originalInvoice.get('currency');
      const bankCode = await originalInvoice.get('bankCode');
      stornoInvoice.set('currency', currency);
      stornoInvoice.set('invoiceType', invoiceType);
      stornoInvoice.set('parent', originalInvoice);
      stornoInvoice.set('datePaid', null);
      stornoInvoice.set('transactionId', null);
      stornoInvoice.set('navStatus', null);
      stornoInvoice.set('bankCode', bankCode);
      let items = await stornoInvoice.get('items');
      let originalItems = await originalInvoice.get('items');
      items.forEach((item, i) => {
        let parent = originalItems.objectAt(i);
        item.set('parent', parent);
        item.set('isCorrectionItem', true);
        item.set('quantity', item.get('quantity') * -1);
      }); // remove empty item

      let empty = items.find(item => {
        return !item.get('quantity');
      });
      items.removeObject(empty);
      stornoInvoice.set('accountNumber', null);
      return stornoInvoice;
    },

    setupController(controller, model) {
      this._super(controller, model);

      const accountNumber = model.invoice.get('parent.accountNumber');
      controller.set('title', this.intl.t('invoice.deleting-invoice', {
        accountNumber
      }));
    }

  });

  _exports.default = _default;
});