define("iris/components/forms/file-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    formType: Ember.computed('model.type', function () {
      let type = this.get('model.type');
      /*
      if (this.get('model.isOpportunity')) {
        type = 'normal'
      }*/

      return 'forms/file-form/file-' + type;
    })
  });

  _exports.default = _default;
});