define("iris/helpers/sum-resource-plans", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sumArray = sumArray;

  function sumArray(params
  /*, hash*/
  ) {
    return params;
  }

  var _default = Ember.Helper.extend({
    recomputeOnArrayChange: Ember.observer('_array.[]', 'refresh', function () {
      this.recompute();
    }),
    compute: function (_ref) {
      let [tuas, stickyCards, resourcePeriod, dailyAllocatedWorkTime, refresh] = _ref;

      if (!tuas) {
        return 0;
      }

      this.set('_array', tuas);
      this.set('_refresh', refresh);
      let plans = tuas.filter(item => {
        return item.resourcePlans.length;
      }).map(tua => {
        return tua.resourcePlans.filter(plan => {
          return plan.resourcePeriod.get('id') === resourcePeriod.get('id');
        })[0];
      }).filter(item => {
        return item;
      });
      const sum = plans.reduce((acc, curr) => {
        return acc + (parseInt(curr['plannedHours']) || 0);
      }, 0);
      const stickyDates = stickyCards.map(sticky => sticky.dates).flat();
      const comparisonValues = stickyDates.map(v => v.valueOf());
      const distinctStickyDates = stickyDates.filter((v, i) => comparisonValues.indexOf(v.valueOf()) == i);
      return sum + distinctStickyDates.length * dailyAllocatedWorkTime || 0;
    }
  });

  _exports.default = _default;
});