define("iris/templates/settings/aiops/parameter-categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "laR0bFMa",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\"],[\"aiops-parameter-category\",\"settings.aiops.parameter-categories.edit\",[28,\"array\",[\"name\",\"active\"],null]]]],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/aiops/parameter-categories.hbs"
    }
  });

  _exports.default = _default;
});