define("iris/routes/aiops/source-data/tickets/ticket-status/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AiopsTicketStateEditRoute extends Ember.Route {
    model(params) {
      if (params.ticket_state_id == 'new') {
        return this.store.createRecord('aiopsTicketState');
      }

      return this.store.findRecord('aiopsTicketState', params.ticket_state_id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = AiopsTicketStateEditRoute;
});