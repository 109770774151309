define("iris/components/tandm/info/time-line/item/summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ooU9ehfn",
    "block": "{\"symbols\":[\"@item\"],\"statements\":[[1,[28,\"t\",[\"tandm.balance\"],null],false],[0,\":\\n\"],[7,\"span\",true],[10,\"class\",\"font-bold\"],[8],[1,[23,1,[\"balance\"]],false],[9],[0,\"\\n\"],[1,[28,\"t\",[\"tandm.alreadyUsed\"],null],false],[0,\":\\n\"],[7,\"span\",true],[10,\"class\",\"font-bold\"],[8],[1,[23,1,[\"alreadyUsed\"]],false],[9],[0,\"\\n\"],[1,[28,\"t\",[\"tandm.alreadyInvoiced\"],null],false],[0,\":\\n\"],[7,\"span\",true],[10,\"class\",\"font-bold\"],[8],[1,[23,1,[\"alreadyInvoiced\"]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/tandm/info/time-line/item/summary/template.hbs"
    }
  });

  _exports.default = _default;
});