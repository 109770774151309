define("iris/helpers/sum-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sumArray = sumArray;

  function sumArray(params
  /*, hash*/
  ) {
    return params;
  }

  var _default = Ember.Helper.extend({
    recomputeOnArrayChange: Ember.observer('_array.[]', function () {
      this.recompute();
    }),
    compute: function (_ref) {
      let [array, property] = _ref;

      if (!array) {
        return 0;
      }

      this.set('_array', array);

      if (property) {
        let properties = [];

        if (property.indexOf('.')) {
          properties = property.split('.');
        } else {
          properties.push(property);
        }

        let mappedArray = [];

        for (const element of array) {
          let temp = element;

          for (const prop of properties) {
            temp = temp[prop];

            if (!temp) {
              temp = 0;
              break;
            }
          }

          mappedArray.push(temp);
        }

        var sum = mappedArray.reduce((acc, curr) => {
          return acc + (parseInt(curr) || 0);
        }, 0);
      } else {
        var sum = array.reduce((acc, curr) => {
          return acc + (parseInt(curr) || 0);
        }, 0);
      }

      return sum || 0;
    }
  });

  _exports.default = _default;
});