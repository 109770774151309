define("iris/models/entity-type-visualisation-category", ["exports", "@ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    entityTypeVisualisations: (0, _relationships.hasMany)('entityTypeVisualisation')
  });

  _exports.default = _default;
});