define("iris/routes/tasks/work-logs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    uploadDocuments: Ember.inject.service(),

    setupController() {
      this.controllerFor('tasks.work-logs').send('refresh');
    },

    actions: {
      didTransition() {
        if (this.controller.get('loadData')) {
          this.controller.fetchData();
        }

        return true; // Bubble the didTransition event
      },

      afterSave(savedWorkLog) {
        // Upload files
        this.get('uploadDocuments').uploadPendingFiles(savedWorkLog, 'WorkLog');
      }

    }
  });

  _exports.default = _default;
});