define("iris/components/forms/integration-form/component", ["exports", "iris/utils/properties", "ember-concurrency"], function (_exports, _properties, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    init: function () {
      this._super();

      this.get('fetchBusinessServiceIdsTask').perform();
      this.set('businessServiceIds', Ember.A());
    },
    model: null,
    // @
    ajax: Ember.inject.service(),
    projectDidChange: (0, _properties.observerOnce)('model.project', function () {
      this.get('fetchBusinessServiceIdsTask').perform();
    }),
    fetchBusinessServiceIdsTask: (0, _emberConcurrency.task)(function* () {
      var _project$belongsTo;

      let project = yield this.get('model.project');
      let partnerId = project === null || project === void 0 ? void 0 : (_project$belongsTo = project.belongsTo('clientPartner')) === null || _project$belongsTo === void 0 ? void 0 : _project$belongsTo.id();

      if (!partnerId) {
        return;
      }

      let result = yield this.get('ajax').call('GET', 'entity', partnerId, 'businessServices', {});
      this.set('businessServiceIds', result);
    }).restartable(),
    businessServices: Ember.computed('businessServiceIds.[]', function () {
      const businessServiceIds = this.get('businessServiceIds');
      if (Ember.isEmpty(businessServiceIds)) return Ember.A();
      return this.get('store').query('businessService', {
        filter: {
          where: {
            id: {
              inq: businessServiceIds
            }
          },
          include: ['operationServiceLevel']
        }
      });
    }),
    actions: {
      async setDefaultBusinessService(businessService) {
        let model = this.get('model');
        model.set('defaultBusinessService', businessService);
      }

    }
  });

  _exports.default = _default;
});