define("iris/models/entity-license", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    entityId: (0, _attr.default)('string'),
    licenseId: (0, _attr.default)('string'),
    entityTypeId: (0, _attr.default)('string'),
    licenseTypeId: (0, _attr.default)('string'),
    entityName: (0, _attr.default)('string'),
    contactName: (0, _attr.default)('string'),
    licenseName: (0, _attr.default)('string'),
    licenseTypeName: (0, _attr.default)('string'),
    partner: (0, _attr.default)('string'),
    contact: (0, _attr.default)('string'),
    department: (0, _attr.default)('string'),
    location: (0, _attr.default)('string'),
    address: (0, _attr.default)('string'),
    expirationDate: (0, _attr.default)('date')
  });

  _exports.default = _default;
});