define("iris/controllers/cmdb/entity-types/index", ["exports", "iris/services/query-params", "iris/lib/filters/types"], function (_exports, _queryParams, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const cmdbQueryParams = (0, _queryParams.makeFilterQueryParams)(null, {
    filters: [{
      "name": "entityTypeCategory",
      "type": _types.FilterType.Equal,
      "value": "Eszközök"
    }]
  });
  const queryParamsMixin = (0, _queryParams.makeControllerMixin)(cmdbQueryParams);

  var _default = Ember.Controller.extend(queryParamsMixin, {
    intl: Ember.inject.service(),
    oldRoute: 'cmdb.entity-types.index',
    predefinedViews: Ember.computed(function () {
      return [{
        name: this.intl.t('cmdb.hardware'),
        filters: [{
          "name": "entityTypeCategory",
          "type": _types.FilterType.Equal,
          "value": "Eszközök"
        }]
      }, {
        name: this.intl.t('cmdb.software'),
        filters: [{
          "name": "entityTypeCategory",
          "type": _types.FilterType.Equal,
          "value": "Szoftverek"
        }]
      }, {
        name: this.intl.t('cmdb.technical-service'),
        filters: [{
          "name": "entityTypeCategory",
          "type": _types.FilterType.Equal,
          "value": "Technikai szolgáltatás"
        }]
      }, {
        name: this.intl.t('cmdb.business-service'),
        filters: [{
          "name": "entityTypeCategory",
          "type": _types.FilterType.Equal,
          "value": "Üzleti szolgáltatások"
        }]
      }, {
        name: this.intl.t('cmdb.licence'),
        filters: [{
          "name": "entityTypeCategory",
          "type": _types.FilterType.Equal,
          "value": "Licencek"
        }]
      }, {
        name: this.intl.t('cmdb.certificate'),
        filters: [{
          "name": "entityTypeCategory",
          "type": _types.FilterType.Equal,
          "value": "Tanusítványok"
        }]
      }];
    })
  });

  _exports.default = _default;
});