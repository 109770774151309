define("iris/components/hrm/event-manager/component", ["exports", "iris/utils/human-resource-management/event-manager/account", "iris/utils/properties", "moment", "underscore", "iris/models/event-type"], function (_exports, _account, _properties, _moment, _underscore, _eventType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.daysToRanges = void 0;
  _exports.mapDaysToEvents = mapDaysToEvents;
  _exports.mapStartEndToDays = mapStartEndToDays;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    account: null,
    // @account
    eventId: null,
    // @eventId
    eventTypes: null,
    // @eventTypes: RecordArray<EventType>
    isLoading: null,
    // @isLoading
    events: null,
    // Event[]
    selectionEvents: null,
    // Event[]
    accountSelectConditions: null,
    // Obj
    calendarEvents: Ember.computed('selectionEvents.[]', 'events.[]', function () {
      return _underscore.default.union(toCalendarSelection(this.selectionEvents), toCalendarSavedEvent(this.events));
    }),
    selectedEvent: null,

    async init() {
      this._super(...arguments);

      this.set('selectionEvents', []);

      if (this.eventId) {
        this.set('selectedEvent', this.store.findRecord('event', this.eventId));
      }

      this.router.on('didTransition', () => {
        if (this.selectedEvent && this.selectedEvent.id) {
          this.set('selectedEvent', null);
        }
      });
      this.set('accountSelectConditions', {
        portal: false
      });
    },

    _isLoading: Ember.computed.or('isLoading', 'account.isSaving'),
    yearlyHolidays: (0, _properties.integerToString)('account.yearlyHolidays'),

    eventRender(event, element) {
      let icon = "question";

      if (event.isAccepted) {
        icon = "ok";
      }

      if (event.isAccepted === false) {
        icon = "remove";
      }

      element.find(".fc-title").prepend(`<i class='glyphicon glyphicon-${icon}-sign'></i>  <b>${event.userName}</b> - `);
    },

    buttonText: {
      today: 'Mai nap',
      day: 'Nap'
    },
    header: {
      left: 'title',
      right: 'today prev,next'
    },

    isYearlyHolidaysChanged() {
      let {
        yearlyHolidays
      } = this.account.changedAttributes();
      return !!yearlyHolidays;
    },

    async createEvent() {
      if (!this.selectedEvent && this.account) {
        let days = [];

        if (this.selectionEvents) {
          days = this.selectionEvents.flatMap(event => mapStartEndToDays(event.start, event.end)).map(day => this.store.createRecord('eventDay', {
            date: day
          }));
        }

        this.set('selectedEvent', this.store.createRecord('event', {
          eventDays: days,
          eventType: this.eventTypes.find(et => et.id === _eventType.EventTypeId.Vacation)
        }));
      }
    },

    actions: {
      selectAccount: async function (account) {
        if (account) {
          let path = 'human-resource-management.event-manager.account';
          this.router.transitionTo(path, account.id);
        } else {
          this.router.transitionTo('human-resource-management.event-manager');
        }
      },

      async updateLeader(leader) {
        this.account.set('leader', leader);
        await (0, _account.saveAccount)(this.store, this.account);
      },

      async updateYearlyHolidays() {
        if (!this.account.isSaving && this.isYearlyHolidaysChanged()) {
          await (0, _account.saveAccount)(this.store, this.account);
        }
      },

      createEvent() {
        this.createEvent();
      },

      async eventClick(info) {
        this.set('selectionEvents', []);
        let selectedEvent = await this.store.findRecord('event', info.id);
        this.set('selectedEvent', selectedEvent);
        let selectionEvents = mapDaysToEvents(selectedEvent.eventDays.map(_ref => {
          let {
            date
          } = _ref;
          return date;
        }));
        this.set('selectionEvents', toCalendarSelection(selectionEvents));
      },

      focusRange(range) {
        this.$('.full-calendar').fullCalendar('gotoDate', (0, _moment.default)(range.start));
      },

      select(start, end) {
        let days = this.selectionEvents ? this.selectionEvents.flatMap(event => mapStartEndToDays(event.start, event.end)) : [];
        let newDays = mapStartEndToDays(start.local(), end.local());

        let diffDays = _underscore.default.difference(_underscore.default.union(newDays, days), _underscore.default.intersection(days, newDays));

        let selectionEvents = mapDaysToEvents(diffDays);
        this.set('selectionEvents', selectionEvents);

        if (this.selectedEvent) {
          let eventDayRecords = diffDays.map(day => {
            return this.store.createRecord('eventDay', {
              date: day
            });
          });
          this.selectedEvent.set('eventDays', eventDayRecords);
        }
      },

      cancelEditing() {
        this.selectedEvent.rollback();
        this.selectedEvent.set('eventDays', Ember.A());
        this.set('selectedEvent', null);
        this.set('events', (0, _account.queryEvents)(this.store, this.account.id));
      },

      clearSelection() {
        this.set('selectionEvents', null);
      },

      reloadEvents() {
        this.set('events', (0, _account.queryEvents)(this.store, this.account.id));
      },

      async reloadAccount() {
        this.set('account', await (0, _account.queryAccount)(this.store, this.account.id));
      }

    }
  });

  _exports.default = _default;

  function mapStartEndToDays(start, end) {
    let temp = (0, _moment.default)(start);
    let days = [];

    while (temp.isBefore(end)) {
      days.push(temp.format('YYYY-MM-DD'));
      temp.add(1, 'd');
    }

    return days;
  }

  function mapDaysToEvents(days) {
    if (!days || !days.length) {
      return [];
    }

    let events = [];
    let momentDayList = days.map(day => (0, _moment.default)(day)).sort((a, b) => a.diff(b, 'd'));
    let tempStart = momentDayList[0];

    for (let i = 0; i < momentDayList.length - 1; i++) {
      if (momentDayList[i + 1].diff(momentDayList[i], 'd') > 1) {
        events.push({
          start: tempStart,
          end: momentDayList[i].clone().add(1, 'd')
        });
        tempStart = momentDayList[i + 1];
      }
    }

    events.push({
      start: tempStart,
      end: momentDayList[momentDayList.length - 1].clone().add(1, 'd')
    });
    return events;
  }

  function toCalendarSelection(events) {
    if (!events) {
      return;
    }

    return events.map(_ref2 => {
      let {
        start,
        end
      } = _ref2;
      return {
        start,
        end,
        id: 'selectionEvent',
        rendering: 'background',
        color: '#ff9f89',
        allDay: true
      };
    });
  }

  function toCalendarSavedEvent(events) {
    if (!events) {
      return;
    }

    return events.map(event => {
      let days = event.get('eventDays').map(eventDay => eventDay.date);
      return mapDaysToEvents(days).map(_ref3 => {
        let {
          start,
          end
        } = _ref3;
        return {
          id: event.id,
          title: event.title,
          userName: event.get('user.longName'),
          start,
          end,
          color: event.get('eventType.color'),
          allDay: true,
          isAccepted: event.isAccepted
        };
      });
    }).flat();
  }

  const daysToRanges = days => {
    return days.sort().reduce((acc, day) => {
      if (acc.length === 0) {
        acc.push([day]);
        return acc;
      }

      let [currentGroup] = acc.slice(-1);
      let [lastDayInCurrentGroup] = currentGroup.slice(-1); // Add a day to the last day of the current group

      if ((0, _moment.default)(lastDayInCurrentGroup).clone().add(1, 'days').isSame((0, _moment.default)(day))) {
        currentGroup.push(day);
        return acc;
      }

      acc.push([day]);
      return acc;
    }, []).map(group => {
      let [start] = group;
      let [end] = group.slice(-1);

      if (start === end) {
        return {
          start
        };
      }

      return {
        start,
        end
      };
    });
  };

  _exports.daysToRanges = daysToRanges;
});