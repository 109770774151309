define("iris/mixins/cmdb-visualisation/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // https://coolors.co/fefe99-d8eb98-b2d898-9abecc-80a3fe
    configs: [{
      name: 'classic',
      ciCategory: {
        '000000001': 'FEFE99',
        '000000002': 'D8EB98',
        '000000003': 'B2D898',
        '000000004': '9ABECC',
        '000000005': '80A3FE'
      },
      defaultStrokes: {
        link: {
          color: '#808697',
          width: 2
        },
        element: '#000'
      },
      states: {
        error: '#ff535d',
        warning: '#ffb143',
        ok: '#'
      },
      stateDictionary: {
        OK: 0,
        WARNING: 1,
        CRITICAL: 2,
        UNKNOWN: 3
      },
      wrapLabel: true,
      layout: {
        nodeSep: 100,
        edgeSep: 160,
        rankSep: 120,
        marginX: 10,
        marginY: 10,
        rankDir: 'BT',
        weight: 1
      }
    }, {
      name: 'new',
      ciCategory: {
        '000000001': 'e1e4d8',
        '000000002': 'e1e4d8',
        '000000003': 'e1e4d8',
        '000000004': 'e1e4d8',
        '000000005': 'e1e4d8'
      },
      defaultStrokes: {
        link: {
          color: '#808697',
          width: 2
        },
        element: '#000'
      },
      states: {
        error: '#ff535d',
        warning: '#ffb143',
        ok: '#'
      },
      stateDictionary: {
        OK: 0,
        WARNING: 1,
        CRITICAL: 2,
        UNKNOWN: 3
      },
      wrapLabel: false,
      layout: {
        nodeSep: 200,
        edgeSep: 320,
        rankSep: 320,
        marginX: 10,
        marginY: 10,
        rankDir: 'BT',
        weight: 1
      }
    }],

    async loadUserSettings() {
      const {
        account
      } = this.sessionAccount;
      const config = await this.store.query('userSetting', {
        filter: {
          where: {
            userId: account.id,
            key: 'cmdb-visualisation-theme'
          }
        }
      });
      return config.length === 0 ? this.configs.find(c => c.name === 'new') : config;
    },

    async setConfig() {
      const config = await this.loadUserSettings();
      this.set('config', config);
    },

    async toggleTheme() {
      const {
        name
      } = this.config;
      const config = name === 'classic' ? this.configs[1] : this.configs[0];
      this.set('config', config);
      const directions = ['slave', 'master'];

      for (const oldElement of this.graph.getElements()) {
        const oldId = oldElement.id;
        const newElem = await this.addElementToGraph(oldElement.get('data'), false); // copy selections too

        for (const direction of directions) {
          const propName = `${direction}SelectedEntityIds`;
          newElem.set(propName, oldElement.get(propName));
        }

        for (const link of this.graph.getConnectedLinks(oldElement)) {
          if (link.getTargetElement() === oldElement) {
            link.target(newElem);
          } else {
            link.source(newElem);
          }

          link.getTargetElement().updateLinkStats(this.graph);
          link.getSourceElement().updateLinkStats(this.graph);
        }

        oldElement.remove();
      }

      this.layoutGraph();
    }

  });

  _exports.default = _default;
});