define("iris/models/building", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 150
    })],
    clientPartner: [(0, _emberCpValidations.validator)('presence', true)],
    address: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    clientPartner: (0, _relationships.belongsTo)('partner'),
    address: (0, _relationships.belongsTo)('address'),
    floors: (0, _relationships.hasMany)('floor', {
      constraints: true
    })
  });

  _exports.default = _default;
});