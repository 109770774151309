define("iris/components/beta/table/status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    theme: Ember.inject.service('theme-switcher')
  });

  _exports.default = _default;
});