define("iris/models/tandm", ["exports", "@ember-data/model", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    dateStart: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    dateEnd: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    hoursBudget: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('number', {
      integer: true,
      gt: 0
    })],
    state: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    methodOfSettlement: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    amount: [(0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['model.methodOfSettlement'],
      disabled: Ember.computed('model', function () {
        return this.get('model.methodOfSettlement') !== 'specified_amount';
      }).volatile(),
      presence: true
    })],
    overtimeModifier: [(0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['model.methodOfSettlement'],
      disabled: Ember.computed('model', function () {
        return this.get('model.methodOfSettlement') !== 'called_hours';
      }).volatile(),
      presence: true
    })],
    billingCycle: [(0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['model.methodOfSettlement'],
      disabled: Ember.computed('model', function () {
        return this.get('model.methodOfSettlement') !== 'called_hours';
      }).volatile(),
      presence: true
    })],
    settlementOfOvertime: (0, _emberCpValidations.validator)('presence', {
      presence: true
    })
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _model.attr)('string'),
    dateStart: (0, _model.attr)('date'),
    dateEnd: (0, _model.attr)('date'),
    fixed: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    hoursBudget: (0, _model.attr)('number', {
      defaultValue: 0,
      formWidget: 'mask',
      formWidgetOptions: {
        decimal: false
      }
    }),
    cumulable: (0, _model.attr)('number', {
      defaultValue: 0,
      formWidget: 'mask',
      formWidgetOptions: {
        decimal: false
      }
    }),
    creditValueDay: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    billingCycleDay: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    billingCycleMonth: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    settlementOfOvertimeDay: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    settlementOfOvertimeMonth: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    baseBalance: (0, _model.attr)('number'),
    balance: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    alreadyUsed: (0, _model.attr)('number'),
    alreadyInvoiced: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    amount: (0, _model.attr)('number'),
    methodOfSettlement: (0, _model.attr)('string'),
    settlementOfOvertime: (0, _model.attr)('string'),
    overtimeModifier: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    billingCycle: (0, _model.attr)('string'),
    state: (0, _relationships.belongsTo)('tandmState'),
    file: (0, _relationships.belongsTo)('project'),
    operationServiceLevels: (0, _relationships.hasMany)('operationServiceLevel'),
    userGradeCosts: (0, _relationships.hasMany)('tandmUserGradeCost'),
    tickets: (0, _relationships.hasMany)('ticket'),
    outgoingProposals: (0, _relationships.hasMany)('fileOutgoingProposal')
  });

  _exports.default = _default;
});