define("iris/models/db-activity", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    tableId: (0, _attr.default)('string'),
    tableName: (0, _attr.default)('string'),
    mode: (0, _attr.default)('string'),
    userId: (0, _attr.default)('string'),
    date: (0, _attr.default)('date')
  });

  _exports.default = _default;
});