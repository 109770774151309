define("iris/lib/filters/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseComputedArgs = _exports.getMappedConfigValues = _exports.concat = void 0;

  const concat = (first, second) => {
    return [...(Array.isArray(first) ? first : []), ...(Array.isArray(second) ? second : [])];
  };

  _exports.concat = concat;

  const getMappedConfigValues = entry => {
    if (typeof entry === 'string') {
      return [entry, entry];
    }

    try {
      let [keyValuePair] = Object.entries(entry);
      return keyValuePair;
    } catch (err) {
      throw new Error(`getConfigKeyValue(): a config key must be either a string or a key-value pair, such as: "{ key: 'value' }"`);
    }
  };

  _exports.getMappedConfigValues = getMappedConfigValues;

  const parseComputedArgs = args => ({
    dependentKeys: args.slice(0, -1),
    fn: args[args.length - 1]
  });

  _exports.parseComputedArgs = parseComputedArgs;
});