define("iris/components/issue-report-edit/modals/work-logs/component", ["exports", "iris/lib/table", "iris/helpers/work-log-editable"], function (_exports, _table, _workLogEditable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IssueReportEditWorkLogsModal = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed('data.ticket.state.id'), _dec6 = Ember._action, (_class = class IssueReportEditWorkLogsModal extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "uploadDocuments", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "sessionAccount", _descriptor3, this);

      _initializerDefineProperty(this, "modals", _descriptor4, this);
    }

    get noCreate() {
      const stateId = this.data.ticket.belongsTo('state').id();
      return ['SYS_08', 'SYS_10'].includes(stateId);
    }

    async afterSave(workLog) {
      await this.uploadDocuments.uploadPendingFiles(workLog);
      workLog.hasMany('documents');
    }

    get rowActions() {
      const {
        flashMessages,
        intl,
        noCreate,
        modals,
        sessionAccount
      } = this;
      return [{
        name: intl.t('general.deletion'),
        icon: 'trash',
        style: 'danger',
        disabled: Ember.computed('row.id', function () {
          return !this.row.id || noCreate || !((0, _workLogEditable.workLogEditable)(this.row.createDate) && sessionAccount.account.get('id') === this.row.user.get('id') && !this.row.disableEditing) ? intl.t('general.delete.cant-delete') : false;
        }),

        async action(record, event) {
          event.stopPropagation();
          event.preventDefault();
          let shouldDelete = await modals.open('beta/modals/delete-confirmation');

          if (shouldDelete === false) {
            return;
          }

          const res = await record.destroyRecord(true);

          if (res) {
            this.storage.refresh();
            flashMessages.info(intl.t('general.delete.success'), {
              title: intl.t('general.delete.success-title'),
              icon: 'times'
            });
          } else {
            flashMessages.danger(this.intl.t('general.delete.error'), {
              title: this.intl.t('general.delete.error'),
              icon: 'warning'
            });
          }
        }

      }];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "uploadDocuments", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sessionAccount", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "noCreate", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "noCreate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterSave", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "afterSave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "rowActions", [_table.rowActions], Object.getOwnPropertyDescriptor(_class.prototype, "rowActions"), _class.prototype)), _class));
  _exports.default = IssueReportEditWorkLogsModal;
});