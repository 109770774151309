define("iris/templates/components/bs-daterangepicker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lv0toy8B",
    "block": "{\"symbols\":[\"@label\",\"@inputClass\",\"@placeholder\"],\"statements\":[[7,\"label\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"],[7,\"input\",true],[11,\"value\",[23,0,[\"rangeText\"]]],[11,\"class\",[28,\"or\",[[23,2,[]],\"daterangepicker-input form-control\"],null]],[11,\"placeholder\",[23,3,[]]],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"hide\"],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"value\"],[[24,[\"start\"]]]]],false],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"value\"],[[24,[\"end\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/bs-daterangepicker.hbs"
    }
  });

  _exports.default = _default;
});