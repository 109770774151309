define("iris/models/resource-plan-priority", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 254
    })],
    color: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    intlKey: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    priority: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      positive: true,
      lte: 10
    })]
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    color: (0, _attr.default)('string'),
    intlKey: (0, _attr.default)('string'),
    priority: (0, _attr.default)('number')
  });

  _exports.default = _default;
});