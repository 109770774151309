define("iris/components/kanban/custom-cards/resource-plan-sticky-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DTwfm+ae",
    "block": "{\"symbols\":[\"date\",\"@card\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"p-6 mt-5 bg-white rounded-xl dark:bg-portoDark-gray-2e353e dark:text-portoDark-gray-bdbdbd\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"mt-1 text-xl mb-4 font-bold\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[\"duas\",\"length\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"duty.modelName\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,2,[\"euas\",\"length\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"models.event.modelName\"],null],false],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,2,[\"dates\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[\"rounded-md mt-2 badge \",[28,\"if\",[[23,2,[\"duas\",\"length\"]],\"bg-blue-400\",\"bg-green-500\"],null]]]],[8],[0,\"\\n        \"],[1,[28,\"moment-format\",[[23,1,[]],\"YYYY-MM-DD\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/kanban/custom-cards/resource-plan-sticky-card/template.hbs"
    }
  });

  _exports.default = _default;
});