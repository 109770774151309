define("iris/utils/flatten-eq-operators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.flattenEqOperators = void 0;

  // WARNING: This mutates the passed where condition!
  const flattenEqOperators = whereCondition => {
    if (whereCondition.and) {
      whereCondition.and = whereCondition.and.map(c => flattenEqOperators(c));
    } else if (whereCondition.or) {
      whereCondition.or = whereCondition.or.map(c => flattenEqOperators(c));
    } else {
      for (let [key, value] of Object.entries(whereCondition)) {
        if (typeof value === 'object' && value !== null && value.hasOwnProperty('eq')) {
          whereCondition[key] = whereCondition[key].eq;
        }
      }
    }

    return whereCondition;
  };

  _exports.flattenEqOperators = flattenEqOperators;
});