define("iris/utils/array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.groupBy = _exports.cycle = void 0;

  // @ts-ignore
  const cycle = (num, arr) => arr[num % arr.length];

  _exports.cycle = cycle;

  const groupBy = (array, groupOn) => {
    const groupFn = typeof groupOn === 'function' ? groupOn : o => o[groupOn];
    return Object.fromEntries(array.reduce((acc, obj) => {
      const groupKey = groupFn(obj);
      return acc.set(groupKey, [...(acc.get(groupKey) || []), obj]);
    }, new Map()));
  };

  _exports.groupBy = groupBy;
});