define("iris/templates/aiops/source-data/tickets/tickets/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C1CoTGXO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"isLoaded\"]]],null,{\"statements\":[[0,\"  \"],[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@proportion\",\"@frame\"],[[23,0,[\"model\"]],\"aiops.source-data.tickets.tickets\",\"2-10\",true]],{\"statements\":[[0,\"\\n      \"],[5,\"forms/aiops/ticket\",[],[[\"@model\"],[[23,0,[\"model\"]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/aiops/source-data/tickets/tickets/edit.hbs"
    }
  });

  _exports.default = _default;
});