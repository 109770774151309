define("iris/components/forms/report-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    toggleEdit: false,
    actions: {
      toggleEdit: function () {
        if (this.get('toggleEdit') === false) {
          this.set('toggleEdit', true);
        } else if (this.get('toggleEdit') === true) {
          this.set('toggleEdit', false);
        }
      }
    }
  });

  _exports.default = _default;
});