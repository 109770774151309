define("iris/routes/crm/contracts/edit/values", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.modelFor('crm.contracts.edit');
    },

    setupController(controller, model) {
      this._super(controller, model);
      /*ACL*/


      let disableInput = this.controllerFor('crm.contracts.edit').get('disableInput');
      this.controller.set('disableInput', disableInput);
    }

  });

  _exports.default = _default;
});