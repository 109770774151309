define("iris/templates/components/christmas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OxKDzMMW",
    "block": "{\"symbols\":[\"flake\"],\"statements\":[[7,\"a\",false],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"toggleChristmas\"],null]]],[8],[0,\"\\n  \"],[7,\"img\",true],[10,\"src\",\"assets/img/baby_yoda.png\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[23,0,[\"flakes\"]]],null,{\"statements\":[[0,\"  \"],[1,[23,1,[]],true],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/christmas.hbs"
    }
  });

  _exports.default = _default;
});