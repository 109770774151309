define("iris/helpers/highlight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.highLight = highLight;

  function highLight(_ref, _ref2) {
    let [text] = _ref;
    let {
      term,
      class: className
    } = _ref2;

    // Don't highlight without text or term
    if (!text || !term) {
      return text;
    }

    let _text = Ember.String.isHTMLSafe(text) ? text.toString() : text;

    let _term = Ember.String.isHTMLSafe(term) ? term.toString() : term; // trim


    _text = _text.trim();
    _term = _term.trim(); // Also don't highlight anything if either text or string is blank

    if (!_text || !_term) {
      return text;
    }

    let highlighted = _text.replace(new RegExp(_term, 'gi'), match => {
      return `<span class="${className}">${match}</span>`;
    });

    return Ember.String.htmlSafe(highlighted);
  }

  var _default = Ember.Helper.helper(highLight);

  _exports.default = _default;
});