define("iris/mixins/forms/incident-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ajax: Ember.inject.service(),

    reset(model) {
      // Make sure the model isn't deleted for some reason.
      // https://sentry.io/organizations/damit/issues/2052920958/?project=5376673&query=is%3Aunresolved
      if (!model.isDeleted) {
        model.setProperties({
          'maxHoursPerWorkLog': 0,
          'resolver': null,
          'file': null,
          'operationServiceLevel': null
        });
      }

      this.set('usersFromSolvingGroups', null);
    },

    actions: {
      // sets the ticket's operationServiceLevel based on its businessService
      async setOperationServiceLevel() {
        const model = this.get('model');
        const businessServiceId = model.get('businessService.id');

        if (!businessServiceId) {
          this.reset(model);
          return;
        }

        const result = await this.get('store').query('operationServiceLevel', {
          filter: {
            where: {
              businessServiceId
            },
            include: ['file', 'solvingGroup']
          }
        }); // query returns an array but it should have only one item

        const operationServiceLevel = result.get('firstObject');
        const file = operationServiceLevel.get('file');
        const solvingGroup = operationServiceLevel.get('solvingGroup');
        model.set('operationServiceLevel', operationServiceLevel);
        model.set('file', file);
        model.set('maxHoursPerWorkLog', file.get('maxHoursPerWorkLog'));

        if (model.get('isNew')) {
          model.set('resolver', solvingGroup.get('servicePerson1'));
        }

        if (!this.get('isDestroyed') && !this.get('isDestroying')) {
          this.set('usersFromSolvingGroups', solvingGroup.get('accounts'));
        }
      }

    },
    entities: Ember.computed('model.contact.id', 'model.businessService.id', async function () {
      const contactId = this.get('model.contact.id');
      const entityId = this.get('model.businessService.id');

      if (!contactId || !entityId) {
        return Ember.A();
      }

      const result = await this.get('ajax').call('GET', 'entity', entityId, 'affected-entities', {});
      const entityIds = result.result.map(item => item.entity_id);
      return this.get('store').query('entity', {
        filter: {
          where: {
            or: [{
              'id': {
                inq: entityIds
              }
            }, {
              'partnerContactId': contactId
            }]
          }
        }
      });
    })
  });

  _exports.default = _default;
});