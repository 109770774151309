define("iris/controllers/human-resource-management/resource-planner", ["exports", "iris/lib/modals", "iris/utils/dependencies", "iris/utils/xlsx", "ember-can"], function (_exports, _modals, _dependencies, _xlsx, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ResourcePlannerController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed('model.period', 'model.period.state', 'model.period.resourceGroup.planners', function () {
    return this.model.period.get('state.resourceKey') === 'closed' || this.cannot('edit resourcePeriod', {
      period: this.model.period
    });
  }), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class ResourcePlannerController extends Ember.Controller.extend(_emberCan.CanMixin) {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "ajax", _descriptor3, this);

      _initializerDefineProperty(this, "modals", _descriptor4, this);

      _defineProperty(this, "columns", Ember.A());

      _initializerDefineProperty(this, "disableForm", _descriptor5, this);
    }

    async backToList() {
      this.router.transitionTo('human-resource-management.resource-periods');
    }

    async closePlan() {
      try {
        const confirmation = await this.modals.open('beta/modals/confirmation', {
          title: this.intl.t('hrm.resource-period.close-planning'),
          body: this.intl.t('hrm.resource-period.close-planning-confirmation')
        }, {
          className: _modals.ModalLayoutClassName.L
        });

        if (!confirmation) {
          return;
        }

        await this.get('ajax').call('POST', 'resourcePeriod', null, 'closeResourcePeriod', {
          id: this.model.period.id
        }, true);
        this.flashMessages.info(this.intl.t('hrm.resource-period.close-planning-succes'), {
          title: this.intl.t('hrm.resource-period.close-planning'),
          icon: 'floppy-o'
        });
        this.send('refreshModel');
      } catch (err) {
        this.flashMessages.danger(err, {
          title: this.intl.t('general.error'),
          icon: 'warning'
        });
      }
    }

    async reopenPlan() {
      try {
        const confirmation = await this.modals.open('beta/modals/confirmation', {
          title: this.intl.t('hrm.resource-period.reopen-planning'),
          body: this.intl.t('hrm.resource-period.reopen-planning-confirmation')
        }, {
          className: _modals.ModalLayoutClassName.L
        });

        if (!confirmation) {
          return;
        }

        await this.get('ajax').call('POST', 'resourcePeriod', null, 'reopenResourcePeriod', {
          id: this.model.period.id
        }, true);
        this.flashMessages.info(this.intl.t('hrm.resource-period.close-planning-succes'), {
          title: this.intl.t('hrm.resource-period.close-planning'),
          icon: 'floppy-o'
        });
        this.send('refreshModel');
      } catch (err) {
        this.flashMessages.danger(err, {
          title: this.intl.t('general.error'),
          icon: 'warning'
        });
      }
    }

    async resetSort(card) {
      let period = this.model.period;
      let column = this.model.columns.find(column => {
        return column.get('id') === card.get('user.id');
      });

      if (!column) {
        return;
      }

      let sortedCards = column.cards.sort((a, b) => {
        var _a$get, _b$get;

        let aPlan = (_a$get = a.get('resourcePlans')) === null || _a$get === void 0 ? void 0 : _a$get.filter(async a => (await a.get('resourcePeriod.id')) === period.get('id'));
        let bPlan = (_b$get = b.get('resourcePlans')) === null || _b$get === void 0 ? void 0 : _b$get.filter(async b => (await b.get('resourcePeriod.id')) === period.get('id'));

        if (aPlan.length) {
          return -1;
        } else if (bPlan.length) {
          return 1;
        } else {
          return 0;
        }
      });
      column.set('cards', sortedCards);
    }

    async saveAction(args) {
      this.set('isLoading', true);
      let {
        draggedItem,
        targetArgs,
        targetList,
        targetIndex,
        sourceArgs,
        sourceList,
        sourceIndex
      } = args;

      if (!draggedItem.currentPlan) {
        //first you need to create a plan
        sourceList.insertAt(sourceIndex, draggedItem);
        targetList.removeAt(targetIndex);
        this.set('isLoading', false);
        return;
      }

      if (targetArgs.column.id === sourceArgs.column.id) {
        this.set('isLoading', false);
        return;
      }

      try {
        if (draggedItem.get('ticket.isOperational')) {
          let currentPlan = await this.store.find('resource-plan', draggedItem.get('currentPlan.id'));
          await currentPlan.set('tua', null);
          await currentPlan.save();
          let ticket = await draggedItem.get('ticket');
          await ticket.set('resolver', targetArgs.column);
          await ticket.save(true);
          let newTuas = await this.store.query('ticketUserAssoc', {
            filter: {
              where: {
                and: [{
                  userId: targetArgs.column.id
                }, {
                  ticketId: ticket.get('id')
                }]
              }
            }
          });
          await currentPlan.set('tua', await newTuas.get('firstObject'));
          await currentPlan.save(true);
        } else {
          let targetTuas = await this.store.query('ticketUserAssoc', {
            filter: {
              where: {
                and: [{
                  userId: targetArgs.column.id
                }, {
                  ticketId: draggedItem.get('ticket.id')
                }]
              }
            }
          });
          let currentPlan = await this.store.find('resource-plan', draggedItem.get('currentPlan.id'));
          let draggedTua = await this.store.find('ticket-user-assoc', draggedItem.get('id'));

          if (targetTuas.length) {
            let targetTua = targetTuas.get('firstObject');
            await targetTua.set('plannedExpensesInHour', Number(targetTua.plannedExpensesInHour) + Number(currentPlan.plannedHours));
            await draggedTua.set('plannedExpensesInHour', Number(draggedItem.plannedExpensesInHour) - Number(currentPlan.plannedHours));
            await currentPlan.set('tua', targetTua);
            await currentPlan.save();
            await draggedTua.save();
          } else {
            let copy = await draggedTua.copy(false, {
              overwrite: {
                user: targetArgs.column,
                plannedExpensesInHour: Number(currentPlan.plannedHours)
              },
              ignoreAttributes: ['resourcePlans']
            });
            await copy.save(true);
            await currentPlan.set('tua', copy);
            await currentPlan.save(true);
            await draggedTua.set('plannedExpensesInHour', Number(draggedItem.plannedExpensesInHour) - Number(currentPlan.plannedHours));
            await draggedTua.save();
          }

          this.flashMessages.info(this.intl.t('operation.change-request.changeSuccess'), {
            title: this.intl.t('general.save.success-title'),
            icon: 'floppy-o'
          });
        }
      } catch (err) {
        this.flashMessages.danger(err, {
          title: this.intl.t('general.error'),
          icon: 'warning'
        });
      } finally {
        await this.send('refreshModel');
        await this.set('isLoading', false);
      }
    }

    async exportPlans() {
      let data = [];
      let period = this.model.period;
      data.push([this.intl.t('resource-period.resourcePeriodName'), period.name]);
      data.push([this.intl.t('resource-group.manager'), await period.get('resourceGroup.authorizer.longName')]);
      data.push([this.intl.t('service-period-interval.modelName'), period.startDate, period.endDate]);
      let header = [this.intl.t('user.full-name'), this.intl.t('partner.partner'), this.intl.t('tasks.main.notification-number'), this.intl.t('forms.subject'), this.intl.t('resource-plan-priority.modelName'), this.intl.t('resource-plan.approvedHours')];
      data.push([]);
      data.push(header);
      let workSheetName = 'resource-plan-export';
      let plans = await period.get('plans');
      plans = plans.sortBy('tua.userId');

      for (const plan of plans) {
        data.push([plan.get('tua.user.longName'), plan.get('tua.ticket.file.clientPartner.name'), plan.get('tua.ticket.ticketNumber'), plan.get('tua.ticket.subject'), plan.get('priority.name'), plan.plannedHours]);
      }

      let xlsx = await (0, _dependencies.loadXLSXModule)();
      let workbook = (0, _xlsx.Workbook)(),
          ws = (0, _xlsx.sheet_from_array_of_arrays)(data, xlsx);
      workbook.SheetNames.push(workSheetName);
      ws['!cols'] = [{
        wpx: 180
      }, {
        wpx: 180
      }, {
        wpx: 180
      }, {
        wpx: 180
      }, {
        wpx: 120
      }];
      let cell = xlsx.utils.encode_cell({
        c: 1,
        r: 1
      });
      workbook.Sheets[workSheetName] = ws;
      let binaryWorkbook = xlsx.write(workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary'
      });
      saveAs(new Blob([(0, _xlsx.s2ab)(binaryWorkbook)], {
        type: 'application/octet-stream'
      }), 'resource-plan-export.xlsx');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "disableForm", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "backToList", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "backToList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closePlan", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "closePlan"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reopenPlan", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "reopenPlan"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetSort", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "resetSort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveAction", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "saveAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exportPlans", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "exportPlans"), _class.prototype)), _class));
  _exports.default = ResourcePlannerController;
});