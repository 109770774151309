define("iris/models/profit-center", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations", "ember-data/relationships"], function (_exports, _model, _attr, _emberCpValidations, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 100
    })],
    code: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 10
    })]
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    code: (0, _attr.default)('string'),
    fileBusinessTypes: (0, _relationships.hasMany)('fileBusinessType')
  });

  _exports.default = _default;
});