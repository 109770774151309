define("iris/models/escalation", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    phase: (0, _attr.default)('number'),
    percent: (0, _attr.default)('number'),
    account: (0, _relationships.belongsTo)('account'),
    serviceLevel: (0, _relationships.belongsTo)('serviceLevel')
  });

  _exports.default = _default;
});