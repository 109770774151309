define("iris/templates/human-resource-management/event-manager/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ap3yoeHi",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[23,0,[\"model\",\"account\",\"longName\"]]],null],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/human-resource-management/event-manager/account.hbs"
    }
  });

  _exports.default = _default;
});