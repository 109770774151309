define("iris/controllers/settings/finances/tax-number-patterns/edit", ["exports", "iris/controllers/settings/finances/tax-number-patterns"], function (_exports, _taxNumberPatterns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TaxNumberPatternsEditController extends _taxNumberPatterns.default {}

  _exports.default = TaxNumberPatternsEditController;
});