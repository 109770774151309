define("iris/components/tables/partner-incoming-invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        name: this.intl.t('incoming-invoice.accountNumber'),
        property: 'accountNumber',
        search: true
      }, {
        name: this.intl.t('incoming-invoice.subject'),
        property: 'subject',
        search: true
      }, {
        name: this.intl.t('incoming-invoice.dateIssued'),
        property: 'dateIssued',
        search: true
      }, {
        name: this.intl.t('incoming-invoice.datePaymentDue'),
        property: 'datePaymentDue',
        search: true
      }, {
        name: this.intl.t('incoming-invoice.datePaymentDue'),
        property: 'datePaymentDue',
        search: true
      }, {
        name: this.intl.t('incoming-invoice.netto'),
        property: 'netto',
        search: true
      }, {
        name: this.intl.t('incoming-invoice.brutto'),
        property: 'netto',
        search: false
      }, {
        name: this.intl.t('incoming-invoice.currency'),
        property: 'currency',
        search: true
      }, {
        name: '',
        property: 'a'
      }];
    }),
    defaultFilterOptions: Ember.computed('partner.id', function () {
      const id = this.get('partner.id');
      return [{
        partnerId: id
      }];
    })
  });

  _exports.default = _default;
});