define("iris/components/modals/cmdb-import/export-schema/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NDuDaDQ6",
    "block": "{\"symbols\":[\"@data\",\"@close\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-content mx-auto\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[0,\"\\n      \"],[1,[23,1,[\"title\"]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"i\",true],[8],[1,[23,1,[\"subTitle\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n    \"],[5,\"input/select/lazy\",[],[[\"@multiple\",\"@modelName\",\"@selected\",\"@onchange\"],[true,\"entityType\",[23,0,[\"selectedEntityTypes\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"selectedEntityTypes\"]]],null]],null]]]],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@icon\",\"@action\",\"@text\",\"@disabled\"],[\"primary\",\"fa fa-download\",[28,\"action\",[[23,0,[]],\"downloadSchemas\"],null],[28,\"t\",[\"general.download\"],null],[23,0,[\"downloadSchemasTask\",\"isRunning\"]]]]],[0,\"\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\",\"@disabled\"],[\"default\",[28,\"fn\",[[23,2,[]]],null],[28,\"t\",[\"buttons.close\"],null],[23,0,[\"downloadSchemasTask\",\"isRunning\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/modals/cmdb-import/export-schema/template.hbs"
    }
  });

  _exports.default = _default;
});