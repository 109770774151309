define("iris/components/cmdb-visualisation2/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v3SZ4pyw",
    "block": "{\"symbols\":[\"dd\",\"&attrs\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"absolute top-0 right-0 h-16 w-16\"],[8],[0,\"\\n  \"],[5,\"basic-dropdown\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"Trigger\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"w-12 h-12 focus:text-scarlet-500 focus:outline-none\"],[13,2],[12,\"type\",\"button\"],[8],[0,\"\\n        \"],[1,[28,\"fa-icon\",[\"cog\"],[[\"class\"],[\"text-3xl\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[6,[23,1,[\"Content\"]],[],[[\"@class\"],[\"filter-config-dropdown-content\"]],{\"statements\":[[0,\"\\n      \"],[7,\"ul\",true],[10,\"role\",\"listbox\"],[10,\"class\",\"py-1 rounded border shadow border-gray-200 dark:border-portoDark-gray-6d7a83 bg-white dark:bg-portoDark-gray-282d36\"],[8],[0,\"\\n        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[7,\"button\",false],[12,\"class\",\"block px-4 py-2 text-scarlet-500\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"toggle\"],null]]],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"entityTypeVisualisation.themeSwitcher\"],null],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/cmdb-visualisation2/settings/template.hbs"
    }
  });

  _exports.default = _default;
});