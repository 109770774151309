define("iris/templates/settings/operations/service-periods/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9NESZiKJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"isLoaded\"]]],null,{\"statements\":[[0,\"  \"],[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@proportion\",\"@frame\"],[[23,0,[\"model\"]],\"settings.operations.service-periods\",\"2-6\",true]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row mb-40\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n        \"],[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"name\",[28,\"t\",[\"service-period.name\"],null]]],{\"statements\":[[0,\"\\n          \"],[5,\"bs-input\",[],[[\"@value\"],[[23,0,[\"model\",\"name\"]]]]],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n        \"],[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"dateType\",[28,\"t\",[\"forms.datetype\"],null]]],{\"statements\":[[0,\"\\n          \"],[5,\"input/select/lazy\",[],[[\"@modelName\",\"@selected\",\"@onchange\"],[\"dateType\",[23,0,[\"model\",\"dateType\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"dateType\"]]],null]],null]]]],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row mb-5\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n        \"],[5,\"auto/form/one2-many\",[],[[\"@parentModel\",\"@itemName\",\"@relationFiled\",\"@excludeFieldsList\",\"@excludeFieldsForm\"],[[23,0,[\"model\"]],\"servicePeriodIntervals\",\"servicePeriodId\",[28,\"array\",[\"servicePeriod\"],null],[28,\"array\",[\"servicePeriod\"],null]]]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/operations/service-periods/edit.hbs"
    }
  });

  _exports.default = _default;
});