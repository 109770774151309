define("iris/components/forms/service-area", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      changeAvailability(model, isAvailable) {
        model.set('availability', isAvailable);
      }

    }
  });

  _exports.default = _default;
});