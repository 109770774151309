define("iris/components/portal-dashboard/edit/sl-badges/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d7WA3rWk",
    "block": "{\"symbols\":[\"&attrs\",\"@ticket\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"grid grid-cols-3 mb-4\"],[13,1],[8],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"uppercase flex items-center\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"incident-form.reaction\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"sl-time-td\",[[12,\"class\",\"col-span-2\"]],[[\"@ticket\",\"@phase\"],[[23,2,[]],\"reaction\"]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"grid grid-cols-3 mb-4\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"uppercase flex items-center\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"incident-form.getting-started\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"sl-time-td\",[[12,\"class\",\"col-span-2\"]],[[\"@ticket\",\"@phase\"],[[23,2,[]],\"start\"]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"grid grid-cols-3 mb-4\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"uppercase flex items-center\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"incident-form.solution\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"sl-time-td\",[[12,\"class\",\"col-span-2\"]],[[\"@ticket\",\"@phase\"],[[23,2,[]],\"solve\"]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/portal-dashboard/edit/sl-badges/template.hbs"
    }
  });

  _exports.default = _default;
});