define("iris/components/forms/entity-procedure-management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    page: 1,
    limit: 10,
    pages: Ember.computed('changedEntites', 'page', function () {
      let totalPage = Math.ceil(this.get('changedEntites.length') / this.get('limit'));
      let pages = Ember.A();
      let curPage = this.get('page');
      let page = 0;
      let startPage = curPage < 5 ? 1 : curPage - 4;
      let endPage = 8 + startPage;
      endPage = totalPage < endPage ? totalPage : endPage;
      let diff = startPage - endPage + 8;
      startPage -= startPage - diff > 0 ? diff : 0;

      if (startPage > 1) {
        pages.pushObject({
          number: 1,
          text: this.intl.t('general.first')
        });
        pages.pushObject({
          text: '...'
        });
      }

      for (let i = startPage; i <= endPage; i++) {
        page = i;
        pages.pushObject({
          number: page,
          text: page,
          cssClass: page === curPage ? 'active' : ''
        });
      }

      if (endPage < totalPage) {
        pages.pushObject({
          text: '...'
        });
        pages.pushObject({
          number: totalPage,
          text: this.intl.t('general.last')
        });
      }

      return pages;
    }),
    onlyChanged: false,
    alwaysVisible: false,
    changedEntitesPaginated: Ember.computed('changedEntites', 'page', function () {
      if (!this.get('changedEntites.length')) {
        return Ember.A();
      }

      let page = this.get('page');
      let limit = this.get('limit');
      return this.get('changedEntites').slice((page - 1) * limit, page * limit);
    }),
    changedEntitesPaginatedArr: Ember.computed.reads('changedEntitesPaginated'),
    entities: Ember.computed('changedEntitesPaginated', function () {
      if (!this.get('changedEntitesPaginated.length')) {
        return Ember.A();
      } else {
        return this.get('store').query('entity', {
          filter: {
            where: {
              id: {
                inq: this.get('changedEntitesPaginated')
              }
            },
            include: ['entityProcedures']
          }
        });
      }
    }),
    notificationTypes: Ember.computed('changedEntites', function () {
      return this.get('store').query('entityProcedureNotificationType', {
        filter: {
          order: 'name'
        }
      });
    }),
    solvingGroups: Ember.computed('changedEntites', function () {
      return this.get('store').query('solvingGroup', {
        filter: {
          order: 'name'
        }
      });
    }),
    _saveProcedure: function (i, entityProcedures) {
      let _this = this;

      if (entityProcedures[i].get('changed') === 2) {
        entityProcedures[i].destroyRecord(true).then(savedRecord => {
          i--;

          if (i >= 0) {
            _this._saveProcedure(i, entityProcedures);
          }
        });
      } else {
        entityProcedures[i].save(true).then(savedRecord => {
          i--;

          if (i >= 0) {
            _this._saveProcedure(i, entityProcedures);
          }
        });
      }
    },
    actions: {
      changePage(page) {
        this.set('page', page);
      },

      biggerThan(a, b) {
        return b > a;
      },

      save(entity) {
        this.set('spinner', true);

        let _this = this;

        entity.save(true).then(savedEntity => {
          _this.get('ajax').call('POST', 'entity', entity.get('id'), 'update-monitoring', {
            'realEntityId': entity.get('id'),
            'mustDeletWhenUpdateMonitoring': 0
          }).then(diffRes => {
            const flashMessages = Ember.get(this, 'flashMessages');
            this.get('ajax').call('POST', 'entity', null, 'set-host-notes', {
              'id': entity.id
            }).then(res => {
              entity.get('entityProcedures').then(entityProcedures => {
                if (entityProcedures.length) {
                  _this._saveProcedure(entityProcedures.length - 1, entityProcedures.toArray());
                }

                if (!this.get('alwaysVisible')) {
                  let visibilityArray = this.get('visibilityArray');
                  let changedEntitesPaginatedArr = this.get('changedEntitesPaginatedArr');
                  visibilityArray.removeObject(entity.get('id'));
                  changedEntitesPaginatedArr.removeObject(entity.get('id'));

                  if (changedEntitesPaginatedArr.length === 0) {
                    this.set('page', 0);
                    this.set('page', 1);
                  }
                }

                flashMessages.success(this.intl.t('entity-procedure.saveSuccess'), {
                  title: this.intl.t('general.save.success-title'),
                  icon: 'check'
                });
              });

              _this.set('spinner', false);
            }).catch(err => {
              _this.set('spinner', false);

              console.error(err);
              let errorMessages = '';

              if (err.errors) {
                err.errors.forEach(error => {
                  errorMessages += error.detail + ".";
                });
              } else {
                errorMessages += err;
              }

              flashMessages.danger(errorMessages, {
                title: 'Sync Entity Procedures',
                icon: 'warning',
                sticky: true
              });
            });
          });
        });
      }

    }
  });

  _exports.default = _default;
});