define("iris/components/change-requests/edit/tasks-table/component", ["exports", "iris/lib/modals"], function (_exports, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    modals: Ember.inject.service(),
    intl: Ember.inject.service(),
    model: null,
    // @
    accounts: null,
    // @
    showTickets: null,
    // @
    disabled: null,
    // @
    columns: Ember.computed('disabled', 'showTickets', function () {
      const columns = Ember.A([{
        name: this.intl.t('models.changeRequest.name')
      }, {
        name: this.intl.t('models.changeRequest.start')
      }, {
        name: this.intl.t('models.changeRequest.end')
      }, {
        name: this.intl.t('models.changeRequest.solver')
      }, {
        name: ''
      }]);

      if (this.showTickets) {
        columns.insertAt(4, {
          name: this.intl.t('models.changeRequest.ticket')
        });
      }

      if (this.disabled) {
        columns.popObject();
      }

      return columns;
    }),
    tasks: Ember.computed.filter('model', function (task) {
      return task.id;
    }),
    actions: {
      async editTask(task) {
        let shouldSave = await this.modals.open('change-requests/edit/tasks-table/edit-modal', {
          task,
          accounts: this.accounts
        }, {
          className: _modals.ModalLayoutClassName.S
        });

        if (shouldSave) {
          let res = await task.save();

          if (res) {
            this.flashMessages.info(this.intl.t('operation.change-request.changeSuccess'), {
              title: this.intl.t('operation.change-request.changeSuccess'),
              icon: 'times'
            });
          } else {
            task.rollback();
          }
        } else {
          task.rollback();
        }
      },

      async deleteTask(task) {
        let shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

        if (shouldDelete) {
          try {
            await task.destroyRecord(true);
            this.flashMessages.info(this.intl.t('general.delete.success-title'), {
              title: this.intl.t('general.delete.success'),
              icon: 'exclamation'
            });
          } catch (error) {
            this.flashMessages.danger(formatErrorMessages(error), {
              title: this.intl.t('general.delete.error'),
              icon: 'times',
              sticky: true
            });
          }
        }
      }

    }
  });

  _exports.default = _default;

  const formatErrorMessages = error => {
    var _error$errors;

    return (_error$errors = error.errors) === null || _error$errors === void 0 ? void 0 : _error$errors.reduce(_ref => {
      let [acc, item] = _ref;
      return `${acc} ${item.detail}.`;
    }, '');
  };
});