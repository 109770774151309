define("iris/validators/ticket-resolver", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    intl: Ember.inject.service(),

    validate(value, options, model, attribute) {
      let resolver = model.get('resolver');
      let resolverGroup = model.get('solvingGroup');

      if (!resolver.content && !resolverGroup.content) {
        return this.intl.t('validators.presence');
      }

      return true;
    }

  });

  _exports.default = _default;
});