define("iris/templates/components/tables/acl-user-competencies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HYgPf3Dc",
    "block": "{\"symbols\":[\"c\",\"@model\"],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@readOnly\",\"@isRender\",\"@defaultFilters\",\"@defaultOrder\",\"@neededFields\"],[\"competency\",true,true,[28,\"hash\",null,[[\"builtIn\"],[[28,\"hash\",null,[[\"lte\"],[2]]]]]],\"name\",[28,\"array\",[\"name\",\"accounts\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"accounts\"],null]],null,{\"statements\":[[0,\"    \"],[5,\"bs-checkbox\",[],[[\"@value\",\"@onchange\"],[[28,\"array-contains\",[[23,2,[\"competencies\"]],[23,1,[\"row\"]]],null],[28,\"action\",[[23,0,[]],\"toggleCompetencyEnabled\",[23,1,[\"row\"]],[23,2,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/tables/acl-user-competencies.hbs"
    }
  });

  _exports.default = _default;
});