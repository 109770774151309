define("iris/components/visualisation-icon/modal/search-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qGoOHY3h",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",true],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"relative\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none\"],[8],[0,\"\\n      \"],[7,\"svg\",true],[10,\"class\",\"w-5 h-5 text-gray-500 dark:text-gray-400\"],[10,\"fill\",\"none\"],[10,\"stroke\",\"currentColor\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[7,\"path\",true],[10,\"stroke-linecap\",\"round\"],[10,\"stroke-linejoin\",\"round\"],[10,\"stroke-width\",\"2\"],[10,\"d\",\"M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z\"],[8],[9],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"input\",false],[12,\"id\",\"default-search\"],[12,\"class\",\"block p-4 pl-10 w-full form-control input-lg\"],[12,\"placeholder\",\"Server, Disk, SSD...\"],[12,\"required\",\"\"],[12,\"type\",\"search\"],[3,\"on\",[\"input\",[28,\"action\",[[23,0,[]],\"_search\"],[[\"value\"],[\"target.value\"]]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/visualisation-icon/modal/search-input/template.hbs"
    }
  });

  _exports.default = _default;
});