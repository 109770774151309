define("iris/components/forms/acl-user-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    users: Ember.computed(function () {
      return this.get('store').findAll('account');
    }),
    actions: {
      clearPassword() {
        this.model.set('password', null);
        this.model.set('passwordConfirmation', null);
      }

    }
  });

  _exports.default = _default;
});