define("iris/components/input-mask", ["exports", "ember-inputmask/components/input-mask"], function (_exports, _inputMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _inputMask.default.extend({
    classNames: ['form-control']
  });

  _exports.default = _default;
});