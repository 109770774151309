define("iris/models/ticket-sl-view", ["exports", "iris/models/ticket", "ember-data/attr"], function (_exports, _ticket, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ticket.default.extend({
    modelName: 'TicketSlView',
    ticketNumber: (0, _attr.default)('string'),
    subject: (0, _attr.default)('string'),
    mergedParentId: (0, _attr.default)('string'),
    partnerName: (0, _attr.default)('string'),
    ticketStateName: (0, _attr.default)('string'),
    ticketTypeName: (0, _attr.default)('string'),
    createUserName: (0, _attr.default)('string'),
    ticketSourceName: (0, _attr.default)('string'),
    solverName: (0, _attr.default)('string'),
    contactName: (0, _attr.default)('string'),
    businessServiceName: (0, _attr.default)('string'),
    mergedParentTicketNumber: (0, _attr.default)('string'),
    startDeadline: (0, _attr.default)('date'),
    reactionDeadline: (0, _attr.default)('date'),
    solveDeadline: (0, _attr.default)('date'),
    violationReaction: (0, _attr.default)('number'),
    violationStart: (0, _attr.default)('number'),
    violationSolve: (0, _attr.default)('number'),
    originalReaction: (0, _attr.default)('number'),
    originalStart: (0, _attr.default)('number'),
    originalSolve: (0, _attr.default)('number')
  });

  _exports.default = _default;
});