define("iris/routes/operation/sd-dashboard", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    themeSwitcher: Ember.inject.service(),

    model() {
      return Ember.RSVP.hash({
        theme: this.get('themeSwitcher.dark')
      });
    },

    actions: {
      limit: null,
      noteContent: null,

      getMainDataByProperty(key) {
        this.transitionTo(this.controller.get(`mainTabs.${key}.route`));
        let mainTabs = this.controller.get('mainTabs');

        for (let label in mainTabs) {
          this.controller.set(`mainTabs.${label}.isActive`, false);
        }

        this.controller.set(`mainTabs.${key}.isActive`, true);
      },

      deleteNoteContent() {
        this.controller.set('noteContent', '');
      },

      createReportFromNoteContent() {
        this.set('session.reportDescription', this.controller.get('noteContent'));
        this.transitionTo('operation.report-submit');
      }

    },

    setupController(controller, model) {
      this._super(controller, model);

      this.controller.set('limit', 5);
      this.controller.set('noteContent', this.get('noteContent'));
      this.controller.set('currentUserId', this.get('currentUserId'));
    }

  });

  _exports.default = _default;
});