define("iris/components/sl-time-badge/pill/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    level: null,
    // @, 'critical' | 'warning' | any
    status: null,
    // @
    statusLevel: null // @, 'bleeding' | 'danger' | any

  });

  _exports.default = _default;
});