define("iris/components/modals/form-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dt6ee1X9",
    "block": "{\"symbols\":[\"af\",\"@data\",\"@close\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-content mx-auto\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[\"formComponent\"]]],null,{\"statements\":[[0,\"    \"],[5,\"auto/form\",[],[[\"@model\",\"@itemName\",\"@parentModel\",\"@close\",\"@frame\",\"@modal\",\"@noFooter\",\"@noHeader\",\"@noClose\",\"@proportion\",\"@customButtons\",\"@afterSave\",\"@beforeSave\",\"@beforeCloseModal\"],[[23,2,[\"model\"]],[23,2,[\"itemName\"]],[23,2,[\"parentModel\"]],[23,3,[]],true,true,[23,2,[\"noFooter\"]],[23,2,[\"noHeader\"]],[23,2,[\"noClose\"]],[23,2,[\"proportion\"]],[23,2,[\"customButtons\"]],[23,2,[\"afterSave\"]],[23,2,[\"beforeSave\"]],[23,2,[\"beforeCloseModal\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"component\",[[23,2,[\"formComponent\"]]],[[\"model\",\"parentModel\",\"data\",\"close\",\"noFooter\",\"disableForm\"],[[23,2,[\"model\"]],[23,2,[\"parentModel\"]],[23,2,[\"formComponentData\"]],[23,3,[]],[23,2,[\"noFooter\"]],[23,2,[\"disableForm\"]]]]],false],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"auto/form\",[],[[\"@model\",\"@itemName\",\"@parentModel\",\"@close\",\"@neededFields\",\"@excludeFields\",\"@translationFileds\",\"@modal\",\"@proportion\",\"@componentLayout\",\"@customButtons\",\"@afterSave\",\"@beforeSave\",\"@beforeCloseModal\",\"@disableForm\"],[[23,2,[\"model\"]],[23,2,[\"itemName\"]],[23,2,[\"parentModel\"]],[23,3,[]],[23,2,[\"neededFields\"]],[23,2,[\"excludeFields\"]],[23,2,[\"translationFileds\"]],true,[23,2,[\"proportion\"]],[28,\"if\",[[23,2,[\"componentLayout\"]],[23,2,[\"componentLayout\"]],\"col-1\"],null],[23,2,[\"customButtons\"]],[23,2,[\"afterSave\"]],[23,2,[\"beforeSave\"]],[23,2,[\"beforeCloseModal\"]],[23,2,[\"disableForm\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/modals/form-modal/template.hbs"
    }
  });

  _exports.default = _default;
});