define("iris/routes/crm/partners", ["exports", "jquery", "iris/mixins/protected-route"], function (_exports, _jquery, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    actionCreate: ['crm.partners.create', 'crm.partners'],
    userPartnerAssocByUser: function (partner, user) {
      return partner.get('users').find(assoc => {
        return assoc.get('user.id') === user.get('id');
      });
    },
    actions: {
      searchByZipCode(model) {
        let country = model.get('country');
        let countryCode = typeof country !== 'undefined' && typeof country.get('code') !== 'undefined' ? country.get('code').substr(0, 2) : '';
        let zipCode = model.get('zipCode');

        if (typeof countryCode !== 'undefined') {
          if (countryCode.length === 2 && zipCode.length > 3) {
            var owner = Ember.getOwner(this);
            var adapter = owner.lookup('adapter:application');
            let accessToken = this.get('session.data.authenticated.id');
            var url = adapter.buildURL();

            _jquery.default.getJSON(url + '/GModels/geocode?country=' + countryCode + '&zipcode=' + zipCode, {
              access_token: accessToken
            }).then(function (data) {
              if (typeof data !== 'undefined' && data.status === 'OK' && typeof data.cityName !== 'undefined') {
                model.set('town', data.cityName);
              } else {
                model.set('town', '');
              }
            }).catch(function (data) {
              console.error('Google API error response: ', data);
            });
          }
        }
      },

      toggleFinance: function () {
        let user = arguments.length <= 0 ? undefined : arguments[0];
        let partner = arguments.length <= 1 ? undefined : arguments[1];
        let value = arguments.length <= 2 ? undefined : arguments[2];
        let store = this.store; // add

        if (value) {
          // check if assoc already exists
          if (partner.get('userPartnerAssocs').contains(user)) {
            let assoc = this.userPartnerAssocByUser(partner, user);
            assoc.set('finance', false);
          } else {
            store.createRecord('userPartnerAssoc', {
              user: user,
              clientPartner: partner
            });
          }
        } else {
          // remove
          let assoc = this.userPartnerAssocByUser(partner, user);
          assoc.set('finance', true);
        }
      },
      toggleUserEnabled: function () {
        let user = arguments.length <= 0 ? undefined : arguments[0];
        let partner = arguments.length <= 1 ? undefined : arguments[1];
        let value = arguments.length <= 2 ? undefined : arguments[2];
        let store = this.store;

        if (value) {
          store.createRecord('userPartnerAssoc', {
            user: user,
            clientPartner: partner
          });
        } else {
          let assoc = this.userPartnerAssocByUser(partner, user);
          assoc.set('parnerClient', null);
          assoc.deleteRecord();
        }
      }
    }
  });

  _exports.default = _default;
});