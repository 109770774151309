define("iris/utils/enum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeEnum = void 0;

  const makeEnum = members => {
    let enumObj = Object.freeze(members.reduce((acc, member, index) => {
      if (typeof member === 'string') {
        acc[acc[member] = index] = member;
      } else {
        let [name, value] = Object.entries(member).flat();
        acc[name] = value;
      }

      return acc;
    }, {}));
    return new Proxy(enumObj, {
      get(target, prop, _receiver) {
        if (!target.hasOwnProperty(prop)) {
          let keys = Object.keys(target).join(', ');
          throw new Error(`Member '${String(prop)}' doesn't exist on enum. Available members are: ${keys}`);
        }

        return target[prop];
      }

    });
  };

  _exports.makeEnum = makeEnum;
});