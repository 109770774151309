define("iris/templates/organizations/notification-groups/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NL+QA7wO",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@proportion\",\"@excludeFields\"],[[23,0,[\"model\"]],\"organizations.notification-groups\",\"2-4\",\"contact\"]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/organizations/notification-groups/edit.hbs"
    }
  });

  _exports.default = _default;
});