define("iris/components/kanban/custom-cards/resource-plan-sticky-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ResourcePlanStickyCard extends Ember.Component {}

  _exports.default = ResourcePlanStickyCard;
});