define("iris/templates/organizations/sites/buildings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Iifqg1J",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\"],[\"building\",\"organizations.sites.buildings.edit\",[28,\"array\",[\"name\",\"clientPartner\",\"address.town\",\"address.address\"],null]]]],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/organizations/sites/buildings.hbs"
    }
  });

  _exports.default = _default;
});