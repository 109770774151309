define("iris/initializers/moment-duration-format", ["exports", "moment", "moment-duration-format"], function (_exports, _moment, _momentDurationFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  // Required by app/helpers/moment-time
  function initialize(_app) {
    (0, _momentDurationFormat.default)(_moment.default);
  }

  var _default = {
    name: 'moment-duration-format',
    initialize
  };
  _exports.default = _default;
});