define("iris/templates/settings/aiops/day-types/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hUUtjER1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"isLoaded\"]]],null,{\"statements\":[[0,\"  \"],[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@proportion\",\"@frame\",\"@needClose\"],[[23,0,[\"model\"]],\"settings.aiops.day-types\",\"3-9\",true,true]],{\"statements\":[[0,\"\\n      \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"active\"]]],[0,\"\\n      \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"code\"]]],[0,\"\\n      \"],[5,\"translation\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"name\"]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/aiops/day-types/edit.hbs"
    }
  });

  _exports.default = _default;
});