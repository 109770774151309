define("iris/routes/finances/pipelines/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function (params) {
      var store = this.store;
      return Ember.RSVP.hash({
        partners: store.query('partner', {
          filter: {
            where: {
              or: [{
                customer: 1
              }, {
                subcontractor: 1
              }]
            },
            order: 'name'
          }
        }),
        fileTypes: store.query('fileType', {
          filter: {
            order: 'name'
          }
        }),
        fileBusinessTypes: store.query('fileBusinessType', {
          filter: {
            order: 'name'
          }
        }),
        fileStates: store.query('fileState', {
          filter: {
            order: 'name'
          }
        })
      });
    }
  });

  _exports.default = _default;
});