define("iris/components/navigation-main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    tagName: 'ul',
    classNames: ['nav', 'navbar-nav', 'am-nav-right']
  });

  _exports.default = _default;
});