define("iris/components/beta/table/component", ["exports", "@salsify/ember-block-slots", "deepmerge", "ember-concurrency", "iris/lib/filters/column", "iris/lib/table", "iris/utils/hash"], function (_exports, _emberBlockSlots, _deepmerge, _emberConcurrency, _column, _table, _hash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberBlockSlots.default, {
    columns: null,
    // @
    rows: null,
    // @, []
    rowActions: null,
    // @
    fuzzySearchTerm: null,
    // @
    config: null,
    // @
    isLoading: null,
    // @
    isDisabled: null,
    // @,
    error: null,
    // @
    sorts: null,
    // @
    onUpdateSorts: null,
    // @
    rowClick: null,
    // @
    rowSelection: null,
    // @, []
    onSelectRow: null,
    // @, []
    savedColumns: null,
    router: Ember.inject.service(),
    api: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.loadSavedColumns.perform(this.tableHash);
    },

    loadSavedColumns: (0, _emberConcurrency.task)(function* (tableHash) {
      var _this$sessionAccount$;

      const userSettings = yield this.api.query('userSetting', {
        filter: {
          where: {
            userId: (_this$sessionAccount$ = this.sessionAccount.account) === null || _this$sessionAccount$ === void 0 ? void 0 : _this$sessionAccount$.id,
            key: tableHash
          }
        }
      });
      let userSetting;

      if (userSettings.length === 0) {
        userSetting = this.store.createRecord('userSetting', {
          user: this.sessionAccount.account,
          key: tableHash
        });
      }

      if (userSettings.length === 1) {
        userSetting = userSettings.firstObject;
      }

      this.set('savedColumns', userSetting);
    }),
    actions: {
      setupTableClassNames() {
        Ember.run.schedule('afterRender', () => {
          this.$('table').addClass('table table-striped iris-table');
        });
      },

      toggleRowSelection() {
        var _this$rows, _this$rowSelection2;

        const selectedDisabledRows = [];
        const unSelectedDisabledRow = [];

        for (const disbledRow of this.disabledRows) {
          var _this$rowSelection;

          if ((_this$rowSelection = this.rowSelection) !== null && _this$rowSelection !== void 0 && _this$rowSelection.includes(disbledRow)) {
            selectedDisabledRows.push(disbledRow);
          } else {
            unSelectedDisabledRow.push(disbledRow);
          }
        }

        const notDisabledRows = ((_this$rows = this.rows) === null || _this$rows === void 0 ? void 0 : _this$rows.filter(row => !this.disabledRows.includes(row))) || [];
        const notDisabledSelection = ((_this$rowSelection2 = this.rowSelection) === null || _this$rowSelection2 === void 0 ? void 0 : _this$rowSelection2.filter(row => !this.disabledRows.includes(row))) || [];
        let selection = !notDisabledSelection.length || notDisabledSelection.length < notDisabledRows.length ? notDisabledRows.concat(selectedDisabledRows) : selectedDisabledRows;
        this.onSelectRow(selection);
      },

      async onReorder() {
        await this.saveColumns();
      },

      async onResize() {
        await this.saveColumns();
      }

    },

    async saveColumns() {
      const serialized = this._columns.map(c => ({
        name: c.name,
        width: c.width
      }));

      this.savedColumns.set('value', serialized);
      await this.savedColumns.save();
      this.flashMessages.info(this.intl.t('general.save.success'), {
        title: this.intl.t('general.save.success-title'),
        icon: 'floppy-o'
      });
    },

    tableHash: Ember.computed('columns.[]', 'router.currentRouteName', function () {
      const columnNames = this.columns.mapBy('name').sort().join();
      return this.router.currentRouteName + (0, _hash.cyrb53)(columnNames);
    }),
    _columns: Ember.computed('columns.[]', 'onSelectRow', 'rowActions.[]', 'savedColumns.value', function () {
      var _this$savedColumns;

      let columns = this.columns;
      columns = columns.map(col => {
        let {
          routeParams,
          isFixed,
          fixed,
          sortable,
          resizable,
          width,
          ...column
        } = col;

        if (!isFixed && fixed) {
          isFixed = typeof fixed === 'string' ? fixed : 'left';
        }

        return {
          cellConfigClass: Ember.Object.extend({
            routeParams: typeof routeParams === 'function' ? routeParams() : routeParams
          }),
          isFixed,
          isSortable: sortable,
          isResizable: resizable,
          isReorderable: !isFixed,
          width: width !== null && width !== void 0 ? width : _column.ColumnWidth.M,
          ...column
        };
      });

      if (this.onSelectRow) {
        columns = [{
          name: '__ROW_SELECTION__',
          isFixed: 'left',
          isSortable: false,
          isResizable: false,
          width: _column.ColumnWidth.XS
        }].concat(columns);
      }

      if (Array.isArray(this.rowActions)) {
        let defaultGroup = this.rowActions.filter(ra => ra.placement === _table.RowActionPlacement.Default);
        let restGroup = this.rowActions.filter(ra => ra.placement === _table.RowActionPlacement.Rest);
        let numberOfActionButtons = defaultGroup.length;

        if (restGroup.length > 0) {
          numberOfActionButtons++; // Make place for the "..." button
        }

        let padding = 2 * 8; // Default horizontal td padding

        let buttonWidths = numberOfActionButtons * 35;
        let gap = numberOfActionButtons > 1 ? (numberOfActionButtons - 1) * 7.5 : 0;
        let width = buttonWidths + gap + padding;
        columns = columns.concat({
          name: '__ROW_ACTIONS__',
          isFixed: 'right',
          isSortable: false,
          isResizable: false,
          width
        });
      }

      if ((_this$savedColumns = this.savedColumns) !== null && _this$savedColumns !== void 0 && _this$savedColumns.value) {
        const {
          value
        } = this.savedColumns;

        for (const col of columns) {
          const c = value.find(c => c.name === col.name);

          if (c && c.width) {
            col.width = c.width;
          }
        }

        columns.sort((a, b) => {
          const indexA = value.findIndex(c => c.name === a.name);
          const indexB = value.findIndex(c => c.name === b.name);
          return indexA - indexB;
        });
      }

      return columns;
    }),
    _config: Ember.computed('config', function () {
      return (0, _deepmerge.default)(this.config, {
        head: {
          widthConstraint: 'gte-container'
        }
      });
    }),
    isSomeRowsSelected: Ember.computed.gt('rowSelection.length', 0),
    isAllRowsSelected: Ember.computed('rowSelection.[]', 'rows.[]', function () {
      var _this$rows2, _this$rowSelection3;

      let rlen = (_this$rows2 = this.rows) === null || _this$rows2 === void 0 ? void 0 : _this$rows2.length;
      let slen = (_this$rowSelection3 = this.rowSelection) === null || _this$rowSelection3 === void 0 ? void 0 : _this$rowSelection3.length;

      if (!rlen || !slen) {
        return false;
      }

      return rlen === slen;
    })
  });

  _exports.default = _default;
});