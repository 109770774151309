define("iris/templates/reports/inventories/contacts/items/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tLAmecAf",
    "block": "{\"symbols\":[\"data\",\"columns\",\"row\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel panel-default\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n\"],[4,\"bs-table\",null,[[\"class\",\"data\",\"columns\",\"newRecords\",\"newButton\",\"hasCheckbox\"],[\"no-pointer\",[24,[\"model\",\"items\"]],[24,[\"columns\"]],false,false,false]],{\"statements\":[[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"td\",true],[8],[1,[28,\"get\",[[23,3,[]],\"entity.name\"],null],false],[9],[0,\"\\n          \"],[7,\"td\",true],[8],[1,[23,3,[\"entity\",\"manufacturer\",\"name\"]],false],[0,\" \"],[1,[23,3,[\"entity\",\"type\",\"name\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"     \\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/reports/inventories/contacts/items/list.hbs"
    }
  });

  _exports.default = _default;
});