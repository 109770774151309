define("iris/routes/settings/operations/ticket-states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({// Data is fetched in the `settings.operations.ticket-states.index` route.
    // However, this route's controller is used to store the model & feed the
    // template, because the table needs to be displayed while we're on the `edit`
    // route and also must be reloaded each time the `edit` route is dismissed.
    //
    // In short:
    //  - Look for controller logic in `settings.operations.ticket-states`
    //  - Look for route logic in `settings.operations.ticket-states.index`
  });

  _exports.default = _default;
});