define("iris/components/aiops/dashboards/system/wrong-ticket-table/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    intervalFilter01: Ember.computed('intl', function () {
      return {
        id: 'weekly',
        name: this.intl.t('aiops.dashboars.weekly'),
        value: 7
      };
    }),
    priorityFilters: Ember.computed('intervalFilter01', function () {
      const date = (0, _moment.default)().subtract(this.intervalFilter01.value, 'days');
      return {
        priorityOk: false,
        createDate: {
          gt: date.format('YYYY-MM-DD')
        }
      };
    }),
    ticketTypeFilters: Ember.computed('intervalFilter01', function () {
      const date = (0, _moment.default)().subtract(this.intervalFilter01.value, 'days');
      return {
        ticketTypeOk: false,
        createDate: {
          gt: date.format('YYYY-MM-DD')
        }
      };
    }),
    operationServiceLevelFilters: Ember.computed('intervalFilter01', function () {
      const date = (0, _moment.default)().subtract(this.intervalFilter01.value, 'days');
      return {
        operationServiceLevelOk: false,
        createDate: {
          gt: date.format('YYYY-MM-DD')
        }
      };
    }),
    userGradeFilters: Ember.computed('intervalFilter01', function () {
      const date = (0, _moment.default)().subtract(this.intervalFilter01.value, 'days');
      return {
        userGradeOk: false,
        createDate: {
          gt: date.format('YYYY-MM-DD')
        }
      };
    }),
    spentTimeFilters: Ember.computed('intervalFilter01', function () {
      const date = (0, _moment.default)().subtract(this.intervalFilter01.value, 'days');
      return {
        spentTimeOk: false,
        createDate: {
          gt: date.format('YYYY-MM-DD')
        }
      };
    }),
    actions: {
      updateintervalFilter01(selected) {
        this.set('intervalFilter01', selected);
      }

    }
  });

  _exports.default = _default;
});