define("iris/components/debounce-input", ["exports", "ember-bootstrap/components/bs-input"], function (_exports, _bsInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsInput.default.extend({
    onValueChange: Ember.observer('value', function () {
      Ember.run.debounce(this, this._setVal, 500);
    }),

    _setVal() {
      this.get('action')(this.get('value'));
    }

  });

  _exports.default = _default;
});