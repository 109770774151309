define("iris/components/forms/sites/location/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let SiteFloor = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class SiteFloor extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "buildings", []);

      _defineProperty(this, "addresses", []);

      _defineProperty(this, "floors", []);
    }

    async changePartner(partner) {
      const addresses = await partner.get('addresses');
      let address = null;
      let building = null;
      let floor = null;
      this.set('addresses', addresses);

      if (addresses.length === 1) {
        address = addresses.get('firstObject');
        const buildings = await address.get('buildings');

        if (buildings.length === 1) {
          building = buildings.get('firstObject');
          const floors = await building.get('floors');
          this.set('floors', floors);

          if (floors.length === 1) {
            this.model.set('floor', floors.get('firstObject'));
          }
        } else {
          this.set('buildings', buildings);
          this.model.set('floor', null);
        }
      } else {
        this.model.set('floor', null);
      }

      this.model.set('address', address);
      this.model.set('building', building);
      this.model.set('clientPartner', partner);
    }

    async changeAddress(address) {
      const buildings = await address.get('buildings');
      let building = null;
      this.set('buildings', buildings);

      if (buildings.length === 1) {
        building = buildings.get('firstObject');
        const floors = await building.get('floors');
        this.set('floors', floors);

        if (floors.length === 1) {
          this.model.set('floor', floors.get('firstObject'));
        } else {
          this.model.set('floor', null);
        }
      } else {
        this.model.set('floor', null);
      }

      this.model.set('address', address);
      this.model.set('building', building);
    }

    async changeBuilding(building) {
      const floors = await building.get('floors');
      this.set('floors', floors);

      if (floors.length === 1) {
        this.model.set('floor', floors.get('firstObject'));
      } else {
        this.model.set('floor', null);
      }

      this.model.set('building', building);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "changePartner", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "changePartner"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeAddress", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "changeAddress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeBuilding", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "changeBuilding"), _class.prototype)), _class));
  _exports.default = SiteFloor;
});