define("iris/helpers/string-pad-end", ["exports", "ember-string-fns/helpers/string-pad-end"], function (_exports, _stringPadEnd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _stringPadEnd.default;
    }
  });
  Object.defineProperty(_exports, "stringPadEnd", {
    enumerable: true,
    get: function () {
      return _stringPadEnd.stringPadEnd;
    }
  });
});