define("iris/routes/finances/invoices/view-pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),

    model(params) {
      const id = params.invoice_id;
      return this.get('ajax').call('GET', 'invoice', '', 'pdf-view/' + id);
    }

  });

  _exports.default = _default;
});