define("iris/templates/components/dashboard/news-feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jaEI8HZ9",
    "block": "{\"symbols\":[\"value\",\"key\",\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tm-body\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"-each-in\",[[24,[\"model\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"tm-title\"],[8],[0,\"\\n          \"],[7,\"h3\",true],[10,\"class\",\"h5 text-uppercase\"],[8],[1,[28,\"moment-format\",[[23,2,[]],\"YYYY. MMMM\"],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"ol\",true],[10,\"class\",\"tm-items\"],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"            \"],[7,\"li\",true],[8],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"tm-box\"],[8],[0,\"\\n                    \"],[7,\"p\",true],[10,\"class\",\"text-muted mb-none\"],[8],[1,[28,\"moment-format\",[[23,3,[\"createDate\"]],\"MM-DD HH:mm\"],null],false],[9],[0,\"\\n\\n                    \"],[7,\"p\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,3,[\"linkSubject\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"route\"],[[23,3,[\"transitionTo\"]]]],{\"statements\":[[0,\"                          \"],[7,\"a\",true],[8],[0,\"\\n                            \"],[1,[23,3,[\"subject\"]],false],[0,\"\\n                          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"                        \"],[1,[23,3,[\"subject\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"                    \"],[9],[0,\"\\n\\n                    \"],[7,\"p\",true],[8],[1,[23,3,[\"contents\"]],true],[9],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/dashboard/news-feed.hbs"
    }
  });

  _exports.default = _default;
});