define("iris/helpers/calculate-progress-bar-percent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculateProgressBarPercent = calculateProgressBarPercent;
  _exports.default = void 0;

  function calculateProgressBarPercent(params
  /*, hash*/
  ) {
    return params[0] * 100 / params[1];
  }

  var _default = Ember.Helper.helper(calculateProgressBarPercent);

  _exports.default = _default;
});