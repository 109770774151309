define("iris/routes/organizations/dashboard", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model: function (params) {
      var store = this.store;
      return Ember.RSVP.hash({
        partners: store.query('partner', {
          filter: {
            where: {
              or: [{
                customer: 1
              }, {
                subcontractor: 1
              }]
            },
            order: 'name'
          }
        }),
        partnerEntity: store.findAll('partnerEntity')
      });
    },
    columns: [{
      name: 'Partner neve',
      property: 'name',
      search: true
    }],
    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set('columns', this.get('columns'));
    }
  });

  _exports.default = _default;
});