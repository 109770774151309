define("iris/templates/finances/invoices/view-pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MAFwsLzM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-tether\",null,[[\"to\",\"target\",\"targetModifier\",\"attachment\",\"on-overlay-click\",\"tetherClass\",\"overlayClass\"],[\"modal-dialog\",\"document.body\",\"visible\",\"middle center\",[28,\"route-action\",[\"back\"],null],\"modal-dialog modal-md\",\"modal-backdrop\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n    \\n    \"],[1,[28,\"modal-header\",null,[[\"title\"],[[28,\"t\",[\"finances.incoming-invoices.invoice-pdf-size\"],null]]]],false],[0,\"\\n      \\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"style\",\"width:100%;min-height: 60vh\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"$data\"]]],null,{\"statements\":[[0,\"          \"],[7,\"object\",true],[11,\"data\",[29,[\"data:application/pdf;base64, \",[24,[\"model\",\"$data\"]]]]],[10,\"style\",\"width:100%;height: 60vh;\"],[10,\"type\",\"application/pdf\"],[8],[0,\"\\n            \"],[7,\"iframe\",true],[10,\"src\",\"https://docs.google.com/viewer?&embedded=true\"],[10,\"style\",\"width:100%;height:100%\"],[8],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n      \"],[1,[28,\"buttons/cancel-button\",null,[[\"text\",\"action\",\"value\"],[[28,\"t\",[\"general.close\"],null],[28,\"route-action\",[\"back\"],null],[24,[\"model\",\"invoice\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/finances/invoices/view-pdf.hbs"
    }
  });

  _exports.default = _default;
});