define("iris/components/shift-changes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y6dr79jf",
    "block": "{\"symbols\":[\"shiftChange\"],\"statements\":[[7,\"div\",true],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex gap-6 border-b border-dashed mb-6 pb-6\"],[8],[0,\"\\n    \"],[5,\"time-picker\",[],[[\"@disabled\",\"@value\",\"@wholeDay\"],[[23,0,[\"disabled\"]],[23,0,[\"value\"]],true]]],[0,\"\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@icon\",\"@action\",\"@text\"],[\"primary\",\"fa fa-plus\",[28,\"action\",[[23,0,[]],\"addShiftBegin\"],null],[28,\"t\",[\"buttons.add\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex gap-6\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"shiftBeginTime\",[23,0,[\"model\",\"shiftChanges\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"border-2 rounded font-bold p-3 text-2xl\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-clock-o\"],[8],[9],[0,\"\\n        \"],[1,[28,\"moment-time\",[[23,1,[\"shiftBeginTime\"]]],null],false],[0,\"\\n        \"],[7,\"i\",false],[12,\"class\",\"fa fa-trash cursor-pointer\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"deleteShiftChange\",[23,1,[]]],null]]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/shift-changes/template.hbs"
    }
  });

  _exports.default = _default;
});