define("iris/controllers/tasks/work-logs", ["exports", "moment", "underscore"], function (_exports, _moment, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    loadData: true,
    spinner: true,
    groupOptions: Ember.computed(function () {
      return Ember.A([{
        id: 1,
        name: this.intl.t('work-log.workDate'),
        property: 'startsAt'
      }, {
        id: 2,
        name: this.intl.t('work-log.file'),
        property: 'ticket.file'
      }]);
    }),
    deleteWorklogId: null,
    from: (0, _moment.default)().subtract(29, 'days'),
    to: (0, _moment.default)(),
    init: function () {
      this._super();

      this.set('selectedGroupOption', this.get('groupOptions.firstObject'));
    },
    fetchData: function () {
      let res = Ember.A();
      let id = this.get('selectedGroupOption.id');
      this.set('spinner', true);
      this.get('ajax').call('GET', 'account', '', 'workLogs', {
        group: id
      }).then(result => {
        let groups = result.groups;
        let files = Ember.A();
        let clients = Ember.A();

        for (let key in groups) {
          let items = Ember.A(groups[key]);
          let sum = 0; // collect filter options

          let filesInGroup = items.mapBy('ticket.file');
          let clientInGroup = items.mapBy('ticket.file.primeContractor');

          let uniqFiles = _underscore.default.uniq(filesInGroup, 'id');

          let uniqClients = _underscore.default.uniq(clientInGroup, 'id');

          files = files.concat(uniqFiles);
          clients = clients.concat(uniqClients); // sum spent time per group

          for (let i = 0; i < items.length; i++) {
            sum += Number(items[i].publicSpentTime) + Number(items[i].spentTime);
          }

          let value = id === 1 ? (0, _moment.default)(key).format('L') : key;
          res.push({
            value: value,
            items: items,
            sum: sum
          });
        }

        this.set('groupedWorkLogs', res);

        let uniqClients = _underscore.default.without(_underscore.default.uniq(clients, 'id'), undefined);

        this.set('clients', _underscore.default.sortBy(uniqClients, 'name'));
        this.set('projects', _underscore.default.sortBy(_underscore.default.without(_underscore.default.uniq(files, 'id'), undefined), 'name'));
        this.set('loadData', false);
        this.set('spinner', false);
      });
    },
    _filteredWorkLogs: Ember.computed('selectedProject', 'selectedClient', 'groupedWorkLogs', 'from', 'to', function () {
      let ret = {
        filteredWorkLogs: Ember.A(),
        allHours: 0,
        inactiveHours: 0
      };
      let groupedWorkLogs = this.get('groupedWorkLogs');
      let selectedProject = this.get('selectedProject');
      let selectedClient = this.get('selectedClient');
      let f = this.get('from');
      let t = this.get('to');

      if (Ember.isNone(groupedWorkLogs)) {
        return ret;
      }

      groupedWorkLogs.forEach(group => {
        let items = Ember.A();
        let sum = 0;
        let inactives = 0;
        group.items.forEach(workLog => {
          if (workLog.ticket) {
            let file = workLog.ticket.file;

            if (f && (0, _moment.default)(f).isAfter(workLog.beginDate, 'd')) {
              return;
            }

            if (t && (0, _moment.default)(t).isBefore(workLog.beginDate, 'd')) {
              return;
            }

            if ((!selectedProject || selectedProject.id === file.id) && (!selectedClient || selectedClient.id === file.primeContractor.id)) {
              items.push(workLog);
              sum += Number(workLog.publicSpentTime) + Number(workLog.spentTime);
              inactives += Number(workLog.spentTime);
            }
          }
        });

        if (items.length) {
          ret.filteredWorkLogs.push({
            value: group.value,
            items: items,
            sum: sum
          });
        }

        ret.inactiveHours += inactives;
        ret.allHours += sum;
      });
      return ret;
    }),
    filteredWorkLogs: Ember.computed.alias('_filteredWorkLogs.filteredWorkLogs.[]'),
    allHours: Ember.computed.alias('_filteredWorkLogs.allHours'),
    inactiveHours: Ember.computed.alias('_filteredWorkLogs.inactiveHours'),
    actions: {
      calendarAddOccurrence: function (occurrence) {
        if (this.get('selectedTicket')) {
          this._addWorkLog(occurrence.get('startsAt'));
        } else {
          this.set('clickedSlot', occurrence.get('startsAt'));
          this.set('showModalDialog', true);
        }
      },
      calendarUpdateOccurrence: function (occurrence, properties, original) {
        let id = original ? original.get('id') : occurrence.get('id');

        let valid = this._validChange(properties, id);

        occurrence.set('valid', valid);

        if (!occurrence.get('id')) {
          occurrence.setProperties(properties);
        } else {
          if (valid) {
            occurrence.setProperties(properties);
            occurrence.save();
          } else {
            const flashMessages = Ember.get(this, 'flashMessages');
            flashMessages.add({
              message: this.intl.t('work-log.overlap'),
              title: 'IRIS message',
              icon: 'info'
            });
          }
        }
      },
      calendarRemoveOccurrence: function (occurrence) {
        this.send('delete', occurrence);
      },

      setTicket(ticket) {
        if (ticket.get('priority') > this.get('priorityLevel')) {
          return;
        }

        this.set('selectedTicket', ticket);
        this.set('showCalendarHint', true);
      },

      occurrenceClicked(occurrence) {
        this.set('workLog', occurrence.get('content'));
        this.set('showModalDialog', true);
      },

      closeModalDialog(record) {
        if (record) {
          record.rollback();
        }

        this.set('showModalDialog', false);
      },

      goToWorkLog(workLog) {
        this.set('foundWorkLog', workLog);
        let date = workLog.get('startsAt');
        this.set('activeDate', date);

        let _this = this;

        Ember.run.later(function () {
          _this.set('foundWorkLog', false);
        }, 2500);
      },

      viewWorkLog(workLog) {
        let _this = this;

        let date = workLog.get('startsAt');
        this.set('activeDate', date);
        workLog.get('ticket').then(ticket => {
          _this.set('selectedTicket', ticket);

          _this.set('workLog', workLog);

          _this.set('showModalDialog', true);
        });
      },

      setOpenedTicket(ticket) {
        if (ticket === this.get('openedTicket')) {
          this.set('openedTicket', null);
        } else {
          this.set('openedTicket', ticket);
        }
      },

      closeTicket(ticket) {
        ticket.set('state', this.get('model.closedState'));
        ticket.save();
      },

      addWorkLog(ticket) {
        this.set('selectedTicket', ticket);
        let now = new Date();
        let minutes = now.getMinutes();
        let hours = now.getHours();
        let m = parseInt((minutes + 7.5) / 15) * 15 % 60;
        let h = minutes > 52 ? hours === 23 ? 0 : ++hours : hours;
        now.setMinutes(m);
        now.setHours(h);

        this._addWorkLog(now);
      },

      selectTicket(ticket) {
        this._addWorkLog(this.get('clickedSlot'), ticket);
      },

      solveTicket(ticket) {
        ticket.set('state', this.get('solveTicket'));
        ticket.save();
      },

      toggleTicket(ticket, value) {
        if (value) {
          this.set('selectedTicket', ticket);
        } else {
          this.set('selectedTicket', null);
        }
      },

      hideUserHint() {
        this.set('showUserHint', false);
      },

      setTicketState(ticket, state) {
        ticket.set('state', state);
        ticket.save();
      },

      regroup(group) {
        this.set('selectedGroupOption', group);
        this.fetchData();
      },

      refresh() {
        this.fetchData();
      },

      deleteWorklog(id) {
        if (typeof id === 'string') {
          this.get('store').find('workLog', id).then(workLog => {
            this.send('delete', {
              afterDelete: this.get('actions.refresh').bind(this)
            }, workLog);
          });
        }
      },

      close() {
        this.set('deleteWorklogId', null);
      }

    }
  });

  _exports.default = _default;
});