define("iris/components/portal-dashboard/new/component", ["exports", "ember-concurrency", "iris/utils/properties"], function (_exports, _emberConcurrency, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    api: Ember.inject.service(),
    ajax: Ember.inject.service(),
    regionElement: Ember.inject.service(),
    model: null,
    // @
    isDisabled: false,
    // @
    businessServiceIds: null,
    // []
    isShowingUploadModal: false,

    // TODO: until attachments are fixed
    init() {
      this._super(...arguments);

      this.set('businessServiceIds', []);
      this.fetchBusinessServiceIdsTask.perform();
    },

    fetchBusinessServiceIdsTask: (0, _emberConcurrency.task)(function* () {
      let partner = yield this.model.get('contact.partner');

      if (!partner.id) {
        return;
      }

      let contact = yield this.model.belongsTo('contact').value();
      let contactId = contact.id;
      let businessServiceIds = yield this.api.memberAction('entity', 'businessServices', contactId);
      this.set('businessServiceIds', businessServiceIds);
    }),
    aiops: Ember.computed('regionElement', function () {
      return this.regionElement.show('aiops');
    }),
    affectedEntityIds: (0, _properties.computedTask)('model.businessService.id', function* () {
      let businessServiceId = this.model.get('businessService.id');

      if (!businessServiceId) {
        return;
      }

      let result = yield this.api.memberAction('entity', 'affected-entities', businessServiceId);
      return result.result.map(_ref => {
        let {
          entity_id
        } = _ref;
        return entity_id;
      });
    }),
    entitiesConditions: Ember.computed('affectedEntityIds.[]', 'affectedEntityIdsa', 'entityIds.[]', 'model.contact.id', function () {
      let contactId = this.model.get('contact.id');

      if (!contactId | Ember.isBlank(this.affectedEntityIds)) {
        return;
      }

      return {
        or: [{
          id: {
            inq: this.affectedEntityIds
          }
        }, {
          partnerContactId: contactId
        }]
      };
    }),
    setBusinessServiceTask: (0, _emberConcurrency.task)(function* (businessService) {
      this.model.set('businessService', businessService);
      this.model.set('entities', []); // Also see:
      // client/app/mixins/forms/incident-form.js

      let osls = yield this.store.query('operationServiceLevel', {
        filter: {
          where: {
            businessServiceId: businessService.id
          },
          include: ['file']
        }
      });
      let operationServiceLevel = osls.get('firstObject');
      let file = operationServiceLevel.get('file');
      this.model.set('operationServiceLevel', operationServiceLevel);
      this.model.set('file', file);
    }).restartable()
  });

  _exports.default = _default;
});