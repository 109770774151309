define("iris/components/tables/entity-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    existingEntityIds: Ember.computed.mapBy('existingEntities', 'id'),
    defaultFilterOptions: Ember.computed('existingEntityIds', function () {
      return [{
        entityTypeId: 'All'
      }, {
        id: {
          nin: this.get('existingEntityIds')
        }
      }];
    }),
    statuses: Ember.computed(function () {
      return this.get('store').query('entityStatus', {
        filter: {
          order: 'name'
        }
      });
    }),
    locations: Ember.computed(function () {
      return this.get('store').query('location', {
        filter: {
          order: 'name'
        }
      });
    }),
    workSites: Ember.computed(function () {
      return this.get('store').query('address', {
        filter: {
          order: 'name'
        }
      });
    }),
    contacts: Ember.computed(function () {
      return this.get('store').query('contact', {
        filter: {
          order: 'name'
        }
      });
    }),
    entityTypeCategories: Ember.computed(function () {
      return this.get('store').query('entityTypeCategory', {
        filter: {
          order: 'name'
        }
      });
    }),
    partners: Ember.computed(function () {
      return this.get('store').query('partner', {
        filter: {
          where: {
            or: [{
              customer: 1
            }, {
              subcontractor: 1
            }]
          },
          order: 'name'
        }
      });
    }),
    departments: Ember.computed(function () {
      return this.get('store').query('department', {
        filter: {
          order: 'name'
        }
      });
    }),
    columnFilterOptions: Ember.computed('entityTypeCategories.[]', 'partners.[]', 'departments.[]', 'workSites.[]', 'contacts.[]', 'locations.[]', 'statuses.[]', function () {
      let entityTypeOptions = Ember.A();
      let entityCategoryOptions = Ember.A();
      let o = Ember.A();
      this.entityTypeCategories.forEach(function (tc) {
        entityCategoryOptions.pushObject({
          id: tc.get('id'),
          text: tc.get('name'),
          condition: {
            categoryId: tc.get('id')
          }
        });
        tc.get('entityTypes').then(entityTypes => {
          o = Ember.A();
          let entityTypesSorted = entityTypes.sortBy('longName');
          entityTypesSorted.forEach(function (t) {
            if (t.get('codeStore') === 0) {
              o.pushObject({
                text: t.get('longName'),
                condition: {
                  entityTypeId: t.get('id')
                }
              });
            }
          });
          entityTypeOptions.pushObject({
            groupName: tc.get('name'),
            foreginKey: tc.get('id'),
            options: o
          });
        });
      });
      let partnerOptions = Ember.A();
      this.partners.forEach(function (p) {
        partnerOptions.pushObject({
          id: p.get('id'),
          text: p.get('name'),
          condition: {
            partner: p.get('name')
          }
        });
      });
      let departmentOptions = Ember.A();
      this.departments.forEach(function (d) {
        departmentOptions.pushObject({
          id: d.get('id'),
          text: d.get('name'),
          foreginKey: d.belongsTo('partner').id(),
          condition: {
            department: d.get('name')
          }
        });
      });
      let contactOptions = Ember.A();
      this.contacts.forEach(function (c) {
        contactOptions.pushObject({
          text: c.get('name'),
          foreginKey: c.belongsTo('department').id(),
          condition: {
            contact: c.get('name')
          }
        });
      });
      let workSiteOptions = Ember.A();
      this.workSites.forEach(function (w) {
        workSiteOptions.pushObject({
          id: w.get('id'),
          text: w.get('name'),
          foreginKey: w.belongsTo('clientPartner').id(),
          condition: {
            workSite: w.get('name')
          }
        });
      });
      let locationOptions = Ember.A();
      this.locations.forEach(function (l) {
        locationOptions.pushObject({
          text: l.get('name'),
          foreginKey: l.belongsTo('address').id(),
          condition: {
            location: l.get('name')
          }
        });
      });
      let statusOptions = Ember.A();
      this.statuses.forEach(function (s) {
        statusOptions.pushObject({
          text: s.get('name'),
          condition: {
            status: s.get('name')
          }
        });
      });
      return Ember.A([{
        placeholder: this.intl.t('entity.name'),
        type: 'search',
        property: 'name',
        searchTerm: null
      }, {
        placeholder: this.intl.t('cmdb-visualisation.serialNumber'),
        type: 'search',
        property: 'serialNumber',
        searchTerm: null
      }, {
        placeholder: this.intl.t('cmdb-visualisation.category'),
        type: 'select',
        options: entityCategoryOptions,
        property: 'categoryId',
        selected: this.selectedCategory
      }, {
        placeholder: this.intl.t('entity.entityType'),
        type: 'select',
        options: entityTypeOptions,
        property: 'CIType',
        selected: null,
        related: '2',
        foreginKey: 'foreginKey'
      }, {
        placeholder: this.intl.t('cmdb-visualisation.partner'),
        type: 'select',
        property: 'partner',
        options: partnerOptions,
        selected: {
          id: this.model.get('partner.id'),
          text: this.model.get('partner.name'),
          condition: {
            partner: this.model.get('partner.name')
          }
        }
      }, {
        placeholder: this.intl.t('department.modelName'),
        type: 'select',
        property: 'department',
        options: departmentOptions,
        selected: null,
        related: '4',
        foreginKey: 'foreginKey'
      }, {
        placeholder: this.intl.t('forms.coworker'),
        type: 'select',
        property: 'contact',
        options: contactOptions,
        selected: null,
        related: '5',
        foreginKey: 'foreginKey'
      }, {
        placeholder: this.intl.t('entity.worksite'),
        type: 'select',
        property: 'workSite',
        options: workSiteOptions,
        selected: null,
        related: '4',
        foreginKey: 'foreginKey'
      }, {
        placeholder: this.intl.t('location.name'),
        type: 'select',
        property: 'location',
        options: locationOptions,
        selected: null,
        related: '7',
        foreginKey: 'foreginKey'
      }, {
        placeholder: this.intl.t('entity.status'),
        type: 'select',
        property: 'state',
        options: statusOptions,
        selected: null
      }]);
    })
  });

  _exports.default = _default;
});