define("iris/components/change-requests/edit/tasks/affected-entities/list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      wichCIType(entityCategoryId) {
        if (typeof entityCategoryId !== 'string') {
          return 'badge-secondary';
        }

        if (entityCategoryId.slice(-9) === '000000003') {
          return 'badge-primary';
        } else if (entityCategoryId.slice(-9) === '000000004') {
          return 'badge-warning';
        } else {
          return 'badge-secondary';
        }
      }

    }
  });

  _exports.default = _default;
});