define("iris/components/tables/entity-licensed-entity/component", ["exports", "iris/lib/modals", "iris/lib/filters/types", "iris/lib/table", "iris/lib/filters/storage"], function (_exports, _modals, _types, _table, _storage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EntityLicence = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _storage.useStorage)('memory'), _dec4 = Ember.computed, _dec5 = Ember.computed, _dec6 = Ember.computed, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class EntityLicence extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "modals", _descriptor2, this);

      _initializerDefineProperty(this, "storage", _descriptor3, this);
    }

    get columns() {
      return {
        entity: {
          visibility: _types.AttributeVisibility.Table
        },
        parentEntity: {
          displayName: this.intl.t('entity.entityType'),
          visibility: _types.AttributeVisibility.Table
        }
      };
    }

    get extraColumns() {
      return [{
        name: 'entity.name',
        displayName: this.intl.t('entity.name'),
        type: _types.AttributeType.String,
        visibility: _types.AttributeVisibility.Filter
      }];
    }

    get customButtons() {
      return [{
        icon: 'fa fa-plus',
        text: this.intl.t('entity-licensed-entity-assoc.new-button'),
        action: this.get('actions.assignEntities').bind(this),
        type: 'primary',
        needClose: true
      }];
    }

    get rowActions() {
      return [{
        name: this.intl.t('general.deletion'),
        icon: 'trash',
        style: 'danger',

        async action(license, event) {
          event.stopPropagation();
          event.preventDefault();
          let shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

          if (shouldDelete) {
            try {
              const licenseRecord = await this.store.findRecord('entity-licensed-entity-assoc', license.id);
              const res = await licenseRecord.destroyRecord(true);

              if (res) {
                this.flashMessages.info(this.intl.t('general.delete.success-title'), {
                  title: this.intl.t('general.delete.success'),
                  icon: 'times'
                });
                this.storage.refresh();
              } else {
                licenseRecord.rollback();
              }
            } catch (error) {
              this.flashMessages.danger(error, {
                sticky: true,
                title: this.intl.t('general.delete.error'),
                icon: 'times'
              });
            }
          }
        }

      }];
    }

    async assignEntities() {
      const licensedEntitiyIds = await this.model.hasMany('licensedEntities').ids();
      licensedEntitiyIds.push(this.model.id);
      const selectedItems = await this.modals.open(`tables/entity-licensed-entity/add-entity-license`, {
        model: this.model,
        licensedEntitiyIds
      }, {
        className: _modals.ModalLayoutClassName['XXXL']
      });

      for (const entityObj of selectedItems) {
        let entity = await this.store.findRecord('entity', entityObj.id);
        let record = this.store.createRecord('entityLicensedEntityAssoc', {
          parentEntity: this.model,
          entity
        });
        await record.save();
      }

      if (selectedItems && selectedItems.length) {
        this.storage.refresh();
      }
    }

    removeLicensedEntity(selected) {
      this.get('model.licensedEntities').removeObjects(selected);
      this.get('model').set('isDirtyTemp', new Date());
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "columns", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "columns"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "extraColumns", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "extraColumns"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "customButtons", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "customButtons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "rowActions", [_table.rowActions], Object.getOwnPropertyDescriptor(_class.prototype, "rowActions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "assignEntities", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "assignEntities"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeLicensedEntity", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "removeLicensedEntity"), _class.prototype)), _class));
  _exports.default = EntityLicence;
});