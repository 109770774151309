define("iris/components/tandm/info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ku8S5p/y",
    "block": "{\"symbols\":[\"@data\"],\"statements\":[[7,\"div\",true],[8],[0,\"\\n  \"],[5,\"iris-button\",[],[[\"@type\",\"@icon\",\"@action\"],[[28,\"if\",[[28,\"eq\",[[23,0,[\"mode\"]],\"timeline\"],null],\"primary\"],null],\"fa fa-list\",[28,\"action\",[[23,0,[]],[28,\"set\",[[23,0,[]],\"mode\",\"timeline\"],null]],null]]]],[0,\"\\n  \"],[5,\"iris-button\",[],[[\"@type\",\"@icon\",\"@action\"],[[28,\"if\",[[28,\"eq\",[[23,0,[\"mode\"]],\"stats\"],null],\"primary\"],null],\"fa fa-bar-chart\",[28,\"action\",[[23,0,[]],[28,\"set\",[[23,0,[]],\"mode\",\"stats\"],null]],null]]]],[0,\"\\n  \"],[5,\"iris-button\",[],[[\"@type\",\"@icon\",\"@action\"],[[28,\"if\",[[28,\"eq\",[[23,0,[\"mode\"]],\"cumulables\"],null],\"primary\"],null],\"fa fa-pie-chart\",[28,\"action\",[[23,0,[]],[28,\"set\",[[23,0,[]],\"mode\",\"cumulables\"],null]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"eq\",[[23,0,[\"mode\"]],\"timeline\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"tandm/info/time-line\",[],[[\"@tandm\",\"@timelineData\",\"@defaultCurrency\"],[[23,1,[\"tandm\"]],[23,0,[\"timelineData\"]],[23,0,[\"defaultCurrency\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,0,[\"mode\"]],\"stats\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"tandm/info/statistics\",[],[[\"@tandm\"],[[23,1,[\"tandm\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,0,[\"mode\"]],\"cumulables\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"tandm/info/cumulables\",[],[[\"@tandm\"],[[23,1,[\"tandm\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/tandm/info/template.hbs"
    }
  });

  _exports.default = _default;
});