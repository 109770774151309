define("iris/templates/components/form-element/horizontal/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uiv4ea3K",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"hasLabel\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\",true],[11,\"class\",[29,[\"control-label \",[22,\"horizontalLabelGridClass\"]]]],[11,\"for\",[29,[[22,\"formElementId\"]]]],[8],[1,[22,\"label\"],false],[9],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"horizontalInputGridClass\"]]]],[8],[0,\"\\n        \"],[1,[28,\"bs-select\",null,[[\"id\",\"name\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"value\",\"disabled\",\"required\"],[[24,[\"formElementId\"]],[24,[\"name\"]],[24,[\"choices\"]],[24,[\"choiceValueProperty\"]],[24,[\"choiceLabelProperty\"]],[24,[\"value\"]],[24,[\"disabled\"]],[24,[\"required\"]]]]],false],[0,\"\\n        \"],[15,\"components/form-element/feedback-icon\",[]],[0,\"\\n        \"],[15,\"components/form-element/errors\",[]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"horizontalInputGridClass\"],\" \",[22,\"horizontalInputOffsetGridClass\"]]]],[8],[0,\"\\n        \"],[1,[28,\"bs-select\",null,[[\"name\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"value\"],[[24,[\"name\"]],[24,[\"choices\"]],[24,[\"choiceValueProperty\"]],[24,[\"choiceLabelProperty\"]],[24,[\"value\"]]]]],false],[0,\"\\n        \"],[15,\"components/form-element/feedback-icon\",[]],[0,\"\\n        \"],[15,\"components/form-element/errors\",[]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":true}",
    "meta": {
      "moduleName": "iris/templates/components/form-element/horizontal/select.hbs"
    }
  });

  _exports.default = _default;
});