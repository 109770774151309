define("iris/models/document", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    originalname: (0, _attr.default)('string'),
    path: (0, _attr.default)('string'),
    size: (0, _attr.default)('number'),
    mimetype: (0, _attr.default)('string'),
    createDate: (0, _attr.default)('date'),
    documentType: (0, _relationships.belongsTo)('documentType'),
    viewable: Ember.computed('mimetype', function () {
      const mimetypes = ['application/pdf', 'image/jpeg', 'text/plain', 'image/png'];
      return mimetypes.includes(this.get('mimetype'));
    })
  });

  _exports.default = _default;
});