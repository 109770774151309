define("iris/helpers/moment-diff", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    rerun(ms) {
      Ember.run.later(() => {
        this.recompute();
      }, ms);
    },

    compute(params, _ref) {
      let {
        unit,
        mode,
        rerun,
        rerunMs
      } = _ref;
      let u = unit || 'd';
      let m = mode || 'normal';
      let a = (0, _moment.default)(params[0]);
      let b = (0, _moment.default)();

      if (params.length > 1) {
        b = (0, _moment.default)(params[1]);
      }

      if (m === 'inverse') {
        b = a;
        a = (0, _moment.default)();
      }

      if (rerun) {
        let ms = rerunMs === true ? rerun : rerun * 1000 * 60;
        this.rerun(ms);
      }

      return a.diff(b, u);
    }

  }); //http://jsbin.com/hirukowica/1/edit?js,console,output


  _exports.default = _default;
});