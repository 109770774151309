define("iris/templates/components/forms/notification-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TGKIdg1F",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-form\",null,[[\"model\",\"proportion\",\"class\"],[[24,[\"notificationGroup\"]],\"3-9\",\"form-horizontal\"]],{\"statements\":[[0,\"\\n\"],[4,\"form-group\",null,[[\"property\",\"label\"],[\"name\",[28,\"t\",[\"intervene.name\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"notificationGroup\",\"name\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"form-group\",null,[[\"property\",\"label\",\"proportion\"],[\"partner\",[28,\"t\",[\"forms.partner-owner\"],null],\"3-9\"]],{\"statements\":[[0,\"    \"],[5,\"input/select/lazy\",[],[[\"@modelName\",\"@where\",\"@selected\",\"@onchange\"],[\"partner\",[28,\"hash\",null,[[\"or\"],[[28,\"array\",[[28,\"hash\",null,[[\"customer\"],[1]]],[28,\"hash\",null,[[\"subcontractor\"],[1]]]],null]]]],[23,0,[\"notificationGroup\",\"partner\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"notificationGroup\",\"partner\"]]],null]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \\n\"],[4,\"form-group\",null,[[\"property\",\"label\",\"proportion\"],[\"notificationGroupType\",[28,\"t\",[\"forms.notificationGroupType\"],null],\"3-9\"]],{\"statements\":[[0,\"    \"],[5,\"input/select/lazy\",[],[[\"@modelName\",\"@selected\",\"@onchange\"],[\"notificationGroupType\",[23,0,[\"notificationGroup\",\"notificationGroupType\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"notificationGroup\",\"notificationGroupType\"]]],null]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"form-group\",null,[[\"label\",\"proportion\"],[[28,\"t\",[\"forms.inside_the_zone\"],null],\"3-3\"]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-checkbox\",null,[[\"value\"],[[23,0,[\"notificationGroup\",\"inside_the_zone\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/notification-group.hbs"
    }
  });

  _exports.default = _default;
});