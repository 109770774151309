define("iris/components/forms/ticket-users-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super();

      let max = this.get('ticket.availableTimeToAssign') + this.get('model.plannedExpensesInHour');
      this.set('max', max);
    },

    _modelTicketUserAssocsUsers: Ember.computed.mapBy('model.ticketUserAssocs', 'user'),
    ticketUserUsers: Ember.computed.mapBy('_modelTicketUserAssocsUsers', 'content'),
    _fileSupplierAssocsSuppliers: Ember.computed.mapBy('file.fileSupplierAssocs', 'supplier'),
    suppliers: Ember.computed.mapBy('_fileSupplierAssocsSuppliers', 'content'),
    sortBy: ['longName'],
    orderedTicketUsers: Ember.computed.sort('ticketUsers', 'sortBy'),
    filtered: Ember.computed('orderedTicketUsers', 'ticket.ticketUsers.[]', function () {
      let ticketUserUsers = this.get('ticket.ticketUsers');
      ticketUserUsers = ticketUserUsers.mapBy('content');
      return this.get('orderedTicketUsers').filter(user => {
        return !ticketUserUsers.contains(user);
      });
    }),
    actions: {
      changeInternal: function (value) {
        if (value) {
          this.set('model.supplier', null);
        } else {
          this.set('model.user', null);
        }
      }
    }
  });

  _exports.default = _default;
});