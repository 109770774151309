define("iris/templates/components/time-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lY1UsFWk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-3\"],[8],[0,\"\\n    \"],[1,[28,\"bs-input\",null,[[\"class\",\"placeholder\",\"value\"],[\"service-level-solving-time-input\",[28,\"t\",[\"operation.main.number\"],[[\"number\"],[\"00\"]]],[24,[\"days\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-3\"],[8],[0,\"\\n    \"],[1,[28,\"bs-input\",null,[[\"class\",\"placeholder\",\"value\"],[\"service-level-solving-time-input\",[28,\"t\",[\"operation.main.number\"],[[\"number\"],[\"00\"]]],[24,[\"hours\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-3\"],[8],[0,\"\\n    \"],[1,[28,\"bs-input\",null,[[\"class\",\"placeholder\",\"value\"],[\"service-level-solving-time-input\",[28,\"t\",[\"operation.main.number\"],[[\"number\"],[\"00\"]]],[24,[\"minutes\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/time-input.hbs"
    }
  });

  _exports.default = _default;
});