define("iris/components/project/completion-certificate/component", ["exports", "bankers-rounding"], function (_exports, _bankersRounding) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    loading: Ember.inject.service(),
    intl: Ember.inject.service(),
    step: 1,
    maxStep: 3,
    roundDigits: 0,
    fileId: null,
    isRange: false,

    async init() {
      this._super(...arguments);

      this.set('alreadyRead', false);
      const model = this.get('model');
      const json = model.toJSON({
        includeId: true
      });
      this.set('fileId', model.get('file.id'));
      await this.loading.run(async () => {
        let items = await model.get('items');
        const file = await model.get('file'); // TODO: no-unused-vars

        const currency = await model.get('currency');
        this.set('roundDigits', currency.get('round'));
        let tickets = null;

        if (model.type === 'type_tandm' || model.type === 'type_tandm_overtime') {
          const tandm = await model.get('tandm');
          tickets = await tandm.get('tickets');
        } else if (model.type === 'type_project') {
          tickets = Ember.A([]);
          const productResults = await model.get('fileProductResults');

          for (const productResult of productResults.toArray()) {
            let t = await productResult.get('tickets');
            tickets.addObjects(t);
          }
        } else {
          tickets = await file.get('tickets');
          tickets = await tickets.filter(ticket => {
            return ticket.get('isOperational') && !ticket.get('tandm');
          });
        }

        const partner = await file.get('clientPartner'); // primeContractor (Ügyfél) : clientPartner (Partner)

        const manager = await file.get('managerUser');
        const sponsor = await file.get('sponsorUser');
        const providerCompletionCertSigner = await file.get('providerCompletionCertSigner');
        const partnerSponsor = await file.get('completionCertSigner');
        let addresses = await partner.get('addresses');
        addresses = JSON.parse(JSON.stringify(addresses.toArray()));
        const ownerPartner = await file.get('fileOwnerPartner');
        let ownerAddresses = await ownerPartner.get('addresses');
        ownerAddresses = JSON.parse(JSON.stringify(ownerAddresses.toArray()));
        const ownerBillingAddress = ownerAddresses.find(a => a.addressType === 'SYS_02');
        const partnerBillingAddress = addresses.find(a => a.addressType === 'SYS_02'); // burnt-in

        items = JSON.parse(JSON.stringify(items.toArray()));

        for (const item of items) {
          item.quantity = item.quantity || 1;
          let vatRef;

          if (item.vat) {
            vatRef = await this.store.findRecord('vat', item.vat);
          } else {
            // find default vat
            const vats = await this.store.query('vat', {
              filter: {
                where: {
                  systemCode: 1
                }
              }
            });
            vatRef = vats.get('firstObject');
          }

          if (item.discount) {
            item.price = (0, _bankersRounding.default)(item.price + item.price * item.discount / 100, this.roundDigits);
          }

          item.vatRef = vatRef;
          item.vat = vatRef.get('vatValue');

          if (item.uom) {
            let res = await this.get('store').findRecord('uom', item.uom);
            item.uom = res.toJSON().name;
            item.uomNav = res.toJSON().navName;
          } else {
            item.uom = 'Db';
            item.uomNav = 'PIECE';
          }
        }

        json.items = Ember.A(items);
        json.date = new Date();
        json.file = file.toJSON();
        json.currency = currency.toJSON();
        json.file.tickets = JSON.parse(JSON.stringify(tickets.toArray())).map(t => t.subject).join(' | ');
        json.file.partner = partner && partner.toJSON();
        json.file.manager = manager && manager.toJSON();
        json.file.sponsor = sponsor && sponsor.toJSON();
        json.file.partnerSponsor = partnerSponsor && partnerSponsor.toJSON();
        json.file.providerCompletionCertSigner = providerCompletionCertSigner && providerCompletionCertSigner.toJSON();
        json.file.partner.billingAddress = this.fullAddress(partnerBillingAddress);
        json.ownerPartner = ownerPartner.toJSON();
        json.ownerPartner.billingAddress = this.fullAddress(ownerBillingAddress);
        json.subjectOfCompletion = this.intl.t('projects.completion-certificate-insure', {
          name: json.file.name
        });
        json.sum = this.intl.t('projects.completion-certificate-sum');
        this.set('model', json);
      });
    },

    fullAddress(billingAddress) {
      return `${billingAddress.zipCode.trim()} ${billingAddress.town}, ${billingAddress.address}`;
    },

    quantityMask: '9{*}.{*}9{*}',
    vats: Ember.computed(function () {
      return this.get('store').findAll('vat');
    }),
    uoms: Ember.computed(function () {
      return this.get('store').findAll('uom');
    }),
    titel: Ember.computed('step', function () {
      const step = this.get('step') - 1;
      const titles = [this.intl.t('completionCertificate.steps.project'), this.intl.t('completionCertificate.steps.invoiceItems'), this.intl.t('completionCertificate.steps.fulfil')];
      return titles[step];
    }),
    navigationsDisabled: Ember.computed('step', 'maxStep', 'model.file.partnerSponsor', function () {
      const step = this.get('step');
      const maxStep = this.get('maxStep');
      return {
        // this.model is POJO
        forward: step === maxStep || !Ember.get(this.model, 'file.partnerSponsor'),
        back: step === 1
      };
    }),
    items: Ember.computed.alias('model.items'),
    sumNet: Ember.computed('items.[]', 'items.@each.quantity', 'items.@each.price', function () {
      const sum = this.get('items').reduce((prev, item) => {
        return prev + (0, _bankersRounding.default)(Ember.get(item, 'quantity') * Ember.get(item, 'price'), this.roundDigits);
      }, 0);
      Ember.run.next(() => {
        if (!this.get('isDestroyed') && !this.get('isDestroying')) {
          // TODO: backtracking-rerender
          Ember.set(this, 'model.sumNet', sum);
        }
      });
      return sum;
    }),
    sumGross: Ember.computed('items.[]', 'items.@each.quantity', 'items.@each.price', 'items.@each.vat', function () {
      const sum = this.get('items').reduce((prev, item) => {
        const net = (0, _bankersRounding.default)(Ember.get(item, 'quantity') * Ember.get(item, 'price'), this.roundDigits);
        const vatValue = (0, _bankersRounding.default)(net * Ember.get(item, 'vat'), this.roundDigits);
        return prev + net + vatValue;
      }, 0);
      Ember.run.next(() => {
        if (!this.get('isDestroyed') && !this.get('isDestroying')) {
          // TODO: backtracking-rerender
          Ember.set(this, 'model.sumGross', sum);
        }
      });
      return sum;
    }),
    actions: {
      selected(step) {
        if (step === 3) {
          this.set('alreadyRead', true);
        }
      },

      removeItem(item) {
        this.get('model.items').removeObject(item);
      },

      copyItem(item) {
        this.get('model.items').addObject({
          name: item.name,
          amountUntaxed: item.amountUntaxed,
          quantity: item.quantity,
          vat: item.vat,
          vatRef: item.vatRef,
          price: item.price,
          uom: item.uom,
          uomNav: item.uomNav
        });
      },

      async addItem() {
        const item = {
          quantity: 1,
          vat: 0.27,
          price: 0,
          uom: 'Db',
          uomNav: 'PIECE'
        };
        const vats = await this.store.query('vat', {
          filter: {
            where: {
              systemCode: 1
            }
          }
        });
        item.vatRef = vats.get('firstObject');
        this.get('model.items').addObject(item);
      },

      calcGross(quantity, price, vat, item) {
        const nettoPrice = (0, _bankersRounding.default)(price * quantity, this.roundDigits);
        const gross = (0, _bankersRounding.default)(nettoPrice * (1 + vat), this.roundDigits);
        Ember.set(item, 'gross', gross);
        return gross;
      },

      changeInput(item) {
        // netto price before discount
        let nettoPrice = (0, _bankersRounding.default)(item.price * item.quantity, this.roundDigits);

        if (item.discount) {
          const discountAmount = -(item.discount / 100) * nettoPrice;
          nettoPrice = (0, _bankersRounding.default)(nettoPrice - discountAmount, roundDigits);
        }

        Ember.set(item, 'amountUntaxed', nettoPrice);
      },

      changeVat(item, vat) {
        Ember.set(item, 'vat', vat.get('vatValue'));
        Ember.set(item, 'vatRef', vat);
      },

      changeSelectedTicketOfDate(ticketOfDate) {
        this.set('model.ticketOfDate', ticketOfDate);
        this.set('selectedTicket', ticketOfDate);
      },

      changeIsRange() {
        if (!this.get('isRange')) {
          this.set('model.completionEndDate', null);
        }
      }

    }
  });

  _exports.default = _default;
});