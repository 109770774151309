define("iris/components/cmdb-visualisation/info-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      closeInfo() {
        this.set('selectedEntity', null);
      }

    }
  });

  _exports.default = _default;
});