define("iris/templates/settings/finances/nav-settings/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oilyUz4l",
    "block": "{\"symbols\":[],\"statements\":[[5,\"forms/nav-setting\",[],[[\"@model\"],[[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/finances/nav-settings/new.hbs"
    }
  });

  _exports.default = _default;
});