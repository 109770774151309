define("iris/components/bs-datetimepicker", ["exports", "jquery", "moment"], function (_exports, _jquery, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const computedProps = Ember.A(['minDate', 'maxDate', 'disabledDates', 'enabledDates', 'dateIcon', 'placeholder']); //https://jsbin.com/dozurutiga/1/edit?output
  //http://front-back.com/how-to-make-absolute-positioned-elements-overlap-their-overflow-hidden-parent

  var bsDateTimePickerComponent = Ember.Component.extend({
    concatenatedProperties: ['textFieldClassNames'],
    classNames: ['date'],
    classNameBindings: ['inputGroupClass'],
    textFieldClassNames: ['form-control'],
    bsDateTimePicker: null,
    dateIcon: 'glyphicon glyphicon-calendar',
    placeholder: '',
    keepOpen: true,
    allowClear: false,
    inputGroupClass: Ember.computed('attrs.noIcon', function () {
      if (!this.getAttr('noIcon')) {
        return 'input-group';
      }
    }),
    // does not work, see action show for workaround
    allowInputToggle: true,
    timePicker: false,
    format: Ember.computed('timePicker', function () {
      let str = this.get('timePicker') ? 'L LT' : 'L';
      return _moment.default.localeData().longDateFormat(str);
    }),
    formatString: _moment.default.localeData().longDateFormat('L'),
    stepping: 15,
    locale: _moment.default.locale(),
    widgetParent: 'body',

    didInsertElement() {
      this._super(...arguments);

      var target;

      if (this.getAttr('noIcon')) {
        target = this.$('.' + this.get('textFieldClassNames').join('.'));
      } else {
        target = this.$('input');
      }

      var bsDateTimePicker = target.datetimepicker(this._buildConfig()).on('dp.show', function () {
        var elem = (0, _jquery.default)('body .bootstrap-datetimepicker-widget');
        var top = (0, _jquery.default)(this).offset().top + elem.offset().top;
        var left = (0, _jquery.default)(this).offset().left + elem.offset().left;
        elem.css('top', top);
        elem.css('left', left);
      });
      this.bsDateTimePicker = bsDateTimePicker.data('DateTimePicker');
      this.scheduledUpdate = Ember.run.scheduleOnce('afterRender', this, this._setupChangeEvent, bsDateTimePicker);

      this._updateDateTimePicker();

      if (this.attrs.open) {
        this.bsDateTimePicker.show();
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      Ember.run.cancel(this.scheduledUpdate);
      this.bsDateTimePicker.destroy();
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this._updateDateTimePicker();
    },

    _setupChangeEvent(bsDateTimePicker) {
      bsDateTimePicker.on('dp.change', ev => {
        /*
        run(() => {
          if(this.attrs.updateDate) {
            if (Ember.isNone(ev.date) || ev.date === false) {
              this.sendAction('updateDate', undefined);
            } else if (!ev.date.isSame(this.getAttr('date'))) {
              if (this.attrs.forceDateOutput) {
                this.sendAction('updateDate', ev.date.toDate());
              } else {
                this.sendAction('updateDate', ev.date);
              }
            }
          }
        }); */
        let previousDate = this.get('date');
        Ember.run.schedule('afterRender', () => {
          if (this.get('isDestroying') || this.get('isDestroyed')) {
            return;
          }

          let {
            date
          } = ev;

          if (date === false && this.allowClear === false) {
            date = (0, _moment.default)(previousDate);
          } // Cannot both set `this.date` and send `updateDate`, because it
          // would destroy the binding. Be sure you set the date when passing
          // an action to `updateDate`!


          if (this.attrs.updateDate) {
            this.sendAction('updateDate', date);
          } else {
            // if date is a moment object the convert it to date if it is false, set null
            date = date ? date.toDate() : null;
            this.set('date', date);
          }
        });
      });
    },

    _updateDateTimePicker() {
      var dateTimePicker = this.bsDateTimePicker;

      if (dateTimePicker) {
        if (this.getAttr('disabled')) {
          dateTimePicker.disable();
        } else {
          dateTimePicker.enable();
        }

        if (this.getAttr('date') === undefined) {
          dateTimePicker.date(null);
        } else {
          dateTimePicker.date(this.getAttr('date'));
        }

        if (!this.getAttr('minDate')) {
          dateTimePicker.minDate(false);
        } else {
          dateTimePicker.minDate(this.getAttr('minDate'));
        }

        if (!this.getAttr('maxDate')) {
          dateTimePicker.maxDate(false);
        } else {
          dateTimePicker.maxDate(this.getAttr('maxDate'));
        }

        if (!this.getAttr('disabledDates')) {
          dateTimePicker.disabledDates([]);
        } else {
          dateTimePicker.disabledDates(this.getAttr('disabledDates'));
        }

        if (!this.getAttr('enabledDates')) {
          dateTimePicker.enabledDates([]);
        } else {
          dateTimePicker.enabledDates(this.getAttr('enabledDates'));
        }
      }
    },

    _buildConfig() {
      var datetimepickerDefaultConfig = _jquery.default.fn.datetimepicker.defaults;
      var isDatetimepickerConfigKeys = Object.keys(datetimepickerDefaultConfig);
      var config = {};
      var configKey;

      for (var i = 0; i < isDatetimepickerConfigKeys.length; i++) {
        configKey = isDatetimepickerConfigKeys[i];

        if (!computedProps.includes(configKey)) {
          config[configKey] = this.get(configKey) === undefined ? datetimepickerDefaultConfig[configKey] : this.get(configKey);
        }
      }

      return config;
    },

    actions: {
      show() {
        this.bsDateTimePicker.show();
      }

    }
  });
  var _default = bsDateTimePickerComponent;
  _exports.default = _default;
});