define("iris/models/action", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true
    })
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    systemName: (0, _attr.default)('string'),
    route: (0, _attr.default)('string'),
    icon: (0, _attr.default)('string'),
    scope: (0, _attr.default)('string'),
    menu: (0, _attr.default)('number'),
    finance: (0, _attr.default)('number'),
    rank: (0, _attr.default)('number'),
    forceDefault: (0, _attr.default)('number'),
    regionId: (0, _attr.default)('string'),
    intlKey: (0, _attr.default)('string'),
    isTopChild: true,
    hasChild: Ember.computed.gt('children.length', 0),
    hasParent: Ember.computed.gt('parent.id', 0),
    parent: (0, _relationships.belongsTo)('action', {
      inverse: 'children'
    }),
    children: (0, _relationships.hasMany)('action', {
      inverse: 'parent'
    }),
    competencies: (0, _relationships.hasMany)('competency'),
    group: (0, _relationships.hasMany)('action'),
    expanded: false,
    activated: false,
    x: Ember.computed('children', {
      get() {
        let row = Array.apply(null, Array(6));
        row[0] = this;
        this.get('children').then(children => {
          children.forEach(child => {
            let idx = child.get('rank') - 1;
            row[idx] = child;
          });
          this.set('x', Ember.A(row));
        });
        return Ember.A();
      },

      set(key, value) {
        return value;
      }

    }),
    resolvedCompetencies: Ember.computed('competencies', {
      get() {
        this.get('competencies').then(competencies => {
          this.set('resolvedCompetencies', competencies);
        });
        return Ember.A();
      },

      set(key, value) {
        return value;
      }

    })
  });

  _exports.default = _default;
});