define("iris/templates/components/document-type-competency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2i7He6aU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[8],[1,[24,[\"competency\",\"name\"]],false],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n  \"],[1,[28,\"bs-checkbox\",null,[[\"value\",\"click\"],[[24,[\"documentTypeCompetency\",\"read\"]],[28,\"action\",[[23,0,[]],\"checkboxClicked\",\"read\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n  \"],[1,[28,\"bs-checkbox\",null,[[\"value\",\"click\"],[[24,[\"documentTypeCompetency\",\"delete\"]],[28,\"action\",[[23,0,[]],\"checkboxClicked\",\"delete\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/document-type-competency.hbs"
    }
  });

  _exports.default = _default;
});