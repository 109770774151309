define("iris/components/beta/filters/add/manager/component", ["exports", "ember-concurrency", "iris/lib/filters", "iris/lib/filters/types", "iris/utils/ember-concurrency", "iris/utils/properties"], function (_exports, _emberConcurrency, _filters, _types, _emberConcurrency2, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The `<Add>` component's `<Manager>` is what does the heavy lifting: it
   * receives `columns` it can offer for adding as filters, existing `filters` to
   * make sure it doesn't allow adding ex. a boolean filter twice, an `onSelect`
   * action to send the event when a filter got selected. It can also receive a
   * `queryCustomFilters` task, which, when provided, will be executed when search
   * text is entered to UI's search field. The same input field normally would
   * only narrow down the list for matching characters.
   */
  var _default = Ember.Component.extend({
    tagName: '',
    api: Ember.inject.service(),
    columns: null,
    // @, array
    filters: null,
    // @, array
    onSelect: null,
    // @, () => {}
    queryCustomFilters: null,
    // @ e-c task
    options: Ember.computed('columns', 'filters.[]', function () {
      return this.columns.map(column => {
        let disabled = Boolean(this.filters.find(_ref => {
          let {
            attributeName,
            attributeType
          } = _ref;
          // Don't allow Boolean fields of the same name, as it doesn't make
          // sense logically. This could be enhanced by actually allowing the
          // option to be selected, but instead of creating a new filter, it
          // merely selects the existing one.
          return attributeName === column.name && attributeType === _types.AttributeType.Boolean;
        }));
        let filter = (0, _filters.makeFilterFromColumn)(column);
        return {
          filter,
          disabled
        };
      });
    }),
    apiSearchTask: (0, _emberConcurrency.task)(function* (term) {
      try {
        let columnOptions = this.options.filter(o => o.filter.displayName.toLowerCase().indexOf(term.toLowerCase()) > -1);
        let customAttributeOptions = []; // eslint-disable-next-line ember/no-get

        if (this.get('queryCustomFilters')) {
          yield (0, _emberConcurrency2.timeout)(_properties.DEFAULT_DEBOUNCE_MS); // eslint-disable-next-line ember/no-get

          let customAttributeFilters = yield this.get('queryCustomFilters').perform(term);
          customAttributeOptions = customAttributeFilters.map(filter => {
            return {
              filter,
              disabled: false
            };
          });
        }

        return [{
          groupName: 'Alapértelmezett',
          options: columnOptions
        }, {
          groupName: 'Egyedi',
          options: customAttributeOptions
        }].filter(group => !!group.options.length);
      } catch (err) {
        console.error(err);
      }
    }).restartable(),
    actions: {
      search(term) {
        // eslint-disable-next-line ember/no-get
        return this.get('apiSearchTask').perform(term);
      },

      select(option) {
        this.set('isSelecting', false);
        this.onSelect(option.filter);
      }

    }
  });

  _exports.default = _default;
});