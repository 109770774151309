define("iris/templates/components/navigation-main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GAPpe2Vx",
    "block": "{\"symbols\":[\"menu\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"nav navbar-nav am-nav-right\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"menus\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"tagName\",\"disabled\",\"route\"],[\"li\",[23,1,[\"disabled\"]],[23,1,[\"route\"]]]],{\"statements\":[[0,\"      \"],[7,\"a\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/navigation-main.hbs"
    }
  });

  _exports.default = _default;
});