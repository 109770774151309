define("iris/services/attachments", ["exports", "@bower_components/file-saver", "iris/utils/converter"], function (_exports, _fileSaver, _converter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AttachmentsService = (_dec = Ember.inject.service, (_class = class AttachmentsService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "api", _descriptor, this);
    }

    async download(document) {
      let base64 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      if (!base64) {
        base64 = await this.fetchBase64(document);
      }

      let blob = _converter.default.b64toBlob(base64, document.mimetype);

      this._saveAs(blob, document.originalname);

      return blob;
    }

    async fetchBase64(document) {
      return this.api.memberAction('document', 'download', document.id);
    } // So that we can mock this out in tests and not to tell the browser to
    // actually download a file.


    _saveAs(blob, name) {
      (0, _fileSaver.saveAs)(blob, name);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AttachmentsService;
});