define("iris/routes/cmdb/entity-types", ["exports", "iris/mixins/protected-route", "iris/utils/model"], function (_exports, _protectedRoute, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    uploadDocuments: Ember.inject.service(),
    intl: Ember.inject.service(),
    _afterSaveProcedure: async function (savedEntity, category, isDirty) {
      const parent = await savedEntity.get('parent');
      let entityProcedures = await savedEntity.get('entityProcedures').toArray();

      if (entityProcedures.length === 0) {
        entityProcedures = await savedEntity.get('realEntityProcedures').toArray();
      }

      for (const procedure of entityProcedures) {
        if ((category === 'business' || category === 'technology') && parent) {
          procedure.set('entity', parent);
          procedure.set('realEntity', savedEntity);
        }

        if (procedure.get('changed') === 2) {
          await procedure.destroyRecord(true);
        } else {
          await procedure.save(isDirty);
        }
      }

      return true;
    },
    _afterSaveMasterRelations: async function (savedEntity) {
      for (const mr of savedEntity.get('masterRelations').toArray()) {
        if (mr.get('isDirty')) {
          await mr.save();
        }

        this._afterSaveMasterRelations(await mr.get('slaveEntity'));
      }
    },
    _afterSaveValues: async function (savedEntity) {
      const entityValues = savedEntity.get('entityValues');

      for (const entityValue of entityValues.toArray()) {
        await entityValue.save();
      }
    },
    _afterSaveSoftware: async function () {
      let savedEntity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let forceDirty = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      console.log('_afterSaveRelation - Inspect: ' + savedEntity.get('name'));
      let isDirty = false;
      let isDirtyChild = false;

      if (savedEntity.get('isDirty') || forceDirty) {
        console.log('_afterSaveRelation - isDirty, Save values...: ' + savedEntity.get('name'));
        isDirty = true;
        await this._afterSaveValues(savedEntity);
      }

      const softwares = savedEntity.get('children');

      for (const software of softwares.toArray()) {
        console.log('software Inspect: ' + software.get('name'));

        if (software.get('isDirty')) {
          isDirty = true;
          console.log('Save software: ' + software.get('name'));
          await software.save();
          await this._afterSaveValues(software);
        }

        isDirtyChild = await this._afterSaveSoftware(software);
      }

      return isDirtyChild || isDirty;
    },

    async _removeSoftwareProcedures(software) {
      const realEntityProcedures = await software.get('realEntityProcedures');

      for (const realEntityProcedure of realEntityProcedures.toArray()) {
        await realEntityProcedure.deleteRecord();
      }

      const entityProcedures = await software.get('entityProcedures');

      for (const entityProcedure of entityProcedures.toArray()) {
        await entityProcedure.deleteRecord();
      }

      let children = software.get('children');

      for (const child of children.toArray()) {
        await this._removeSoftwareProcedures(child);
      }
    },

    actions: {
      async afterSave(savedEntity, startMonitoring) {
        let isDirty1 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        const category = savedEntity.get('entityType.entityTypeCategory.category');
        const controller = this.controllerFor('cmdb.entity-types.index');
        const oldRoute = controller.get('oldRoute');
        this.controller.set('spinner', true);
        const slaveRelations = savedEntity.get('slaveRelations');

        for (const slaveRelation of slaveRelations.toArray()) {
          await slaveRelation.save();
        } // Save Softwares


        const hasDirtySoftware = await this._afterSaveSoftware(savedEntity, isDirty1);

        this._afterSaveMasterRelations(savedEntity);

        const customValues = savedEntity.get('customValues');

        if (customValues) {
          customValues.forEach(customValue => {
            customValue.set('modelId', savedEntity.get('id'));
            customValue.save();
          });
        }

        const isDirty = isDirty1 || hasDirtySoftware;

        if (!isDirty) {
          //Save Procedures
          await this._afterSaveProcedure(savedEntity, category, false);
          this.send('transitionTo', oldRoute);
          this.controller.set('spinner', false);
          return;
        }

        this.get('uploadDocuments').uploadPendingFiles(savedEntity, 'Entity');

        if (startMonitoring === 1 || startMonitoring === 2 && savedEntity.get('monitoringStartDate')) {
          const flashMessages = Ember.get(this, 'flashMessages');
          let entityId = savedEntity.get('id');

          if (category === 'business' || category === 'technology') {
            let res = await this.get('ajax').call('POST', 'entity', null, 'get-or-create-zone', {
              zoneId: savedEntity.get('siteZone.id'),
              zoneName: savedEntity.get('siteZone.name'),
              category: category,
              entityId: savedEntity.get('id')
            });
            entityId = res.zoneEntityId;
          }

          try {
            let res = null;

            if (startMonitoring === 1) {
              const contacts = await this.store.query('Contact', {
                filter: {
                  where: {
                    partnerId: savedEntity.get('partner.id'),
                    defaultReporter: true,
                    active: 1
                  }
                }
              });

              if (contacts.length === 0) {
                const {
                  flashMessages,
                  intl
                } = this;
                flashMessages.danger(intl.t('contact.messages.defaultReporterCheckCMDB.message'), {
                  title: intl.t('contact.messages.defaultReporterCheckCMDB.title'),
                  sticky: true,
                  icon: 'warning'
                });
                this.controller.set('spinner', false);
                return false;
              }

              res = await this.get('ajax').call('POST', 'entity', entityId, 'start-monitoring', {
                realEntityId: savedEntity.get('id')
              });
            } else {
              res = await this.get('ajax').call('POST', 'entity', entityId, 'update-monitoring', {
                realEntityId: savedEntity.get('id'),
                mustDeletWhenUpdateMonitoring: savedEntity.get('mustDeletWhenUpdateMonitoring')
              }); //Save Procedures

              await this._afterSaveProcedure(savedEntity, category, true);
            }

            this.controller.set('spinner', false);

            if (startMonitoring === 1) {
              savedEntity.set('monitoringStartDate', new Date());
              savedEntity.set('icingaStatus', 'OK');
            }

            if (res.result) {
              flashMessages.warning(this.intl.t('entity-type.missingData'), {
                title: this.intl.t('general.warning'),
                icon: 'warning',
                sticky: true
              });

              if (startMonitoring === 1) {
                this.send('transitionTo', {
                  route: 'cmdb.entity-types.entities.entity.procedures',
                  segment: savedEntity.get('id')
                });
              } else {
                this.transitionTo('cmdb.entity-types.entities.entity.procedures', {
                  queryParams: {
                    refresh: 1
                  }
                });
              }
            } else {
              this.send('transitionTo', oldRoute);
            }
          } catch (err) {
            console.error(err);
            this.controller.set('spinner', false);
            let errorMessages = '';

            if (err.errors) {
              err.errors.forEach(error => {
                errorMessages += error.detail + '.';
              });
            } else {
              errorMessages += err;
            }

            flashMessages.danger(errorMessages, {
              title: 'Start monitoring',
              icon: 'warning',
              sticky: true
            });
          }
        } else {
          this.controller.set('spinner', false);
          this.send('transitionTo', oldRoute);
        }
      },

      removeSoftware: async function (masterRelations, children, records) {
        let store = this.get('store');
        children.removeObjects(records);
        const runType = await store.findRecord('entityRelationType', 'SYS_04');

        for (const software of records.toArray()) {
          for (const relation of masterRelations.toArray()) {
            if (relation.belongsTo('entityRelationType').id() === runType.get('id') && relation.belongsTo('slaveEntity').id() === software.get('id')) {
              await relation.destroyRecord(true);
            }
          }

          await this._removeSoftwareProcedures(software);
          await software.destroyRecord(true);
        }
      },
      addSoftware: function (route, softwareMode, software) {
        let store = this.get('store');
        let entity = software.get('parent'); // if the second software is validated, ember-cp-validate
        // does not set didValidate to true on hasMany models

        software.get('entityValues').forEach(function (value) {
          value.set('didValidate', true);
        });
        (0, _model.validateRecord)(software).then(_ref => {
          let {
            model,
            validations
          } = _ref;

          if (validations.get('isValid')) {
            if (softwareMode === 'new') {
              if (software.get('generateName')) {
                software.set('name', this.intl.t('cmdb.entities.new-software'));
              }

              store.findRecord('entityRelationType', 'SYS_04').then(runType => {
                store.createRecord('entityRelation', {
                  entityRelationType: runType,
                  masterEntity: entity,
                  slaveEntity: software
                });
              });
            }

            this.send('transitionTo', route);
          } else {
            model.setValidated();
          }
        });
      },
      backToRoute: function () {
        let controller = this.controllerFor('cmdb.entity-types.index');
        controller.transitionToRoute(controller.get('oldRoute'));
      },
      transitionToRoute: function (hash) {
        let controller = this.controllerFor('cmdb.entity-types.index');
        controller.set('oldRoute', hash.oldRoute);
        controller.transitionToRoute(hash.route, hash.entityTypeId, hash.entityId);
      },

      solverGroupApplyToAll(entityProcedures, model) {
        entityProcedures.forEach(element => {
          element.set('solvingGroup', model.get('solvingGroup'));
        });
      },

      notificationApplyToAll(entityProcedures, model) {
        entityProcedures.forEach(element => {
          element.set('description', model.get('description'));
          element.set('priorityWarning', model.get('priorityCritical'));
          element.set('priorityCritical', model.get('priorityCritical'));
          element.set('notificationWarning', model.get('notificationCritical'));
          element.set('notificationCritical', model.get('notificationCritical'));
        });
      },

      notificationGroupApplyToAll(entityProcedures, model) {
        entityProcedures.forEach(element => {
          element.set('notificationGroups', model.get('notificationGroups'));
          element.set('dontSaveChange', 1);
          element.save(true);
          element.set('dontSaveChange', 0);
        });
        model.set('isDirtyTemp', new Date());
      }

    }
  });

  _exports.default = _default;
});