define("iris/components/translation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    themeSwitcher: Ember.inject.service(),
    currentUser: Ember.computed.reads('sessionAccount.account'),
    otherTranslations: Ember.A(),
    show: false,
    loading: false,
    proportion: '3-9',
    selectedTranslation: null,

    async init() {
      this._super(...arguments);

      this.set('otherTranslations', Ember.A());
      const modelName = Ember.String.underscore(this.model.constructor.modelName);
      this.set('loading', true);
      const languages = await this.store.query('language', {
        filter: {
          where: {
            id: {
              neq: '000'
            }
          }
        }
      });
      const translations = await this.store.query('translation', {
        filter: {
          where: {
            and: [{
              sourceId: this.model.id
            }, {
              sourceType: modelName
            }, {
              property: this.property
            }]
          }
        }
      });
      this.model.set('translations', Ember.A());

      for (const language of languages.toArray()) {
        let translation;

        for (let t of translations.toArray()) {
          if (t.belongsTo('language').id() === language.id) {
            translation = t;
          }
        }

        if (!translation) {
          translation = this.store.createRecord('translation', {
            property: this.property,
            sourceId: this.model.id,
            sourceType: modelName,
            language
          });
        }

        this.model.get('translations').pushObject(translation);

        if (translation.belongsTo('language').id() === this.currentUser.belongsTo('language').id()) {
          this.set('selectedTranslation', translation);
        } else {
          this.otherTranslations.pushObject(translation);
        }
      }

      this.set('loading', false);
    },

    actions: {
      openTranslations() {
        this.toggleProperty('show');
      }

    }
  });

  _exports.default = _default;
});