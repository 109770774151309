define("iris/templates/components/forms/invoice-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4p+JhP/U",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-form\",null,[[\"model\",\"proportion\"],[[24,[\"model\"]],\"3-9\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"page-head\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[10,\"class\",\"h4\"],[8],[1,[28,\"t\",[\"forms.account-category-properties\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"panel panel-default\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n\"],[4,\"form-group\",null,[[\"property\",\"label\"],[\"name\",[28,\"t\",[\"general.name\"],null]]],{\"statements\":[[0,\"            \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"name\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/invoice-category.hbs"
    }
  });

  _exports.default = _default;
});