define("iris/controllers/crm/partners/edit", ["exports", "iris/utils/model", "ember-concurrency"], function (_exports, _model, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PartnerEditController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed('model.partner.approved', 'approveTask.isRunning'), _dec6 = Ember._action, _dec7 = Ember._action, (_class = class PartnerEditController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "modals", _descriptor4, this);
    }

    get customButtons() {
      if (this.model.partner.approved === false) {
        const {
          isRunning
        } = this.approveTask;
        return [{
          icon: isRunning ? 'fa fa-refresh fa-spin fa-fw' : 'fa fa-thumbs-o-up',
          text: this.intl.t('general.approval'),
          action: this.approve.bind(this),
          type: 'success',
          disable: isRunning
        }];
      }
    }

    *approveTask() {
      const {
        model: {
          partner
        },
        api,
        flashMessages,
        intl
      } = this;
      const {
        model,
        validations
      } = yield (0, _model.validateRecord)(partner);

      if (validations.get('isValid')) {
        try {
          const saveResult = yield partner.save();
          const result = yield api.memberAction('partner', 'approve', partner.id, {
            method: 'POST'
          });
          yield partner.reload();
          flashMessages.info(intl.t('general.approve.success'), {
            title: intl.t('general.approve.success-title'),
            icon: 'info'
          });
        } catch (error) {
          flashMessages.danger(intl.t('general.approve.error'), {
            title: intl.t('general.error'),
            icon: 'warning'
          });
        }
      } else {
        model.setValidated();
      }
    }

    async beforeSaveContact(savedFile) {
      if (savedFile.defaultReporter) {
        const where = {
          partnerId: savedFile.belongsTo('partner').id(),
          defaultReporter: true,
          active: 1
        };

        if (savedFile.id) {
          where['id'] = {
            neq: savedFile.id
          };
        }

        const contacts = await this.store.query('Contact', {
          filter: {
            where
          }
        });

        if (contacts.length) {
          const {
            flashMessages,
            intl
          } = this;
          flashMessages.danger(intl.t('contact.messages.defaultReporterCheck.message'), {
            title: intl.t('contact.messages.defaultReporterCheck.title'),
            sticky: true,
            icon: 'warning'
          });
          return false;
        } else {
          return true;
        }
      }
    }

    async approve() {
      this.approveTask.perform();
    }

    async uploadPhoto() {
      const logo = await this.modals.open('modals/photo-crop');

      if (logo) {
        this.model.partner.set('logo', logo);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "customButtons", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "customButtons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "approveTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "approveTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "approve", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "approve"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadPhoto", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "uploadPhoto"), _class.prototype)), _class));
  _exports.default = PartnerEditController;
});