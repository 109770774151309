define("iris/controllers/beta/sd-dashboard/index", ["exports", "iris/lib/filters/types", "iris/services/query-params"], function (_exports, _types, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultWhere = {
    filters: [{
      name: 'priority',
      type: _types.FilterType.ContainsAny,
      value: [1, 2]
    }, {
      name: 'solveDeadline',
      type: _types.FilterType.NotNull
    }, {
      name: 'mergedParentTicketNumber',
      type: _types.FilterType.Null
    }]
  };
  const indexQueryParams = (0, _queryParams.makeFilterQueryParams)({});
  const queryParamsMixin = (0, _queryParams.makeControllerMixin)(indexQueryParams);

  var _default = Ember.Controller.extend(queryParamsMixin, {
    defaultWhere
  });

  _exports.default = _default;
});