define("iris/components/pick-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super(args); // set up wrapper arrays


      this.set('leftSelectedItems', Ember.A());
      this.set('rightSelectedItems', Ember.A());
      let leftList = Ember.A();
      let rightList = this.get('rightList');
      this.get('selected').forEach(item => {
        rightList.pushObject(item);
      });
      this.set('rightList', rightList);
      this.get('list').forEach(item => {
        if (!rightList.contains(item)) {
          leftList.pushObject(item);
        }
      });
      this.set('leftList', leftList);
    },

    actions: {
      move(from) {
        // if from => move items from left to right
        let selected = from === 1 ? this.get('leftSelectedItems') : this.get('rightSelectedItems');
        let list = from === 1 ? this.get('leftList') : this.get('rightList');
        let oppositList = from === 1 ? this.get('rightList') : this.get('leftList');
        oppositList.pushObjects(selected);
        list.removeObjects(selected);
        selected.clear();
      }

    }
  });

  _exports.default = _default;
});