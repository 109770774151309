define("iris/templates/components/form-element/horizontal/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HHr1s2Yf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[22,\"horizontalInputGridClass\"],\" \",[22,\"horizontalInputOffsetGridClass\"]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"checkbox\"],[8],[0,\"\\n        \"],[7,\"label\",true],[8],[0,\"\\n            \"],[1,[28,\"input\",null,[[\"name\",\"type\",\"checked\",\"disabled\",\"required\"],[[24,[\"name\"]],\"checkbox\",[24,[\"value\"]],[24,[\"disabled\"]],[24,[\"required\"]]]]],false],[0,\" \"],[1,[22,\"label\"],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[15,\"components/form-element/errors\",[]],[0,\"\\n\"],[9]],\"hasEval\":true}",
    "meta": {
      "moduleName": "iris/templates/components/form-element/horizontal/checkbox.hbs"
    }
  });

  _exports.default = _default;
});