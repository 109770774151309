define("iris/routes/organizations/sites/locations/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LocationEditRoute extends Ember.Route {
    model(params) {
      if (params.location_id == 'new') {
        return this.store.createRecord('location');
      }

      return this.store.findRecord('location', params.location_id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = LocationEditRoute;
});