define("iris/components/change-requests/edit/tasks-table/edit-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wbs5NDuq",
    "block": "{\"symbols\":[\"@data\",\"@close\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"operation.change-request.edit-task\"],null],false],[0,\"\\n      \"],[1,[23,1,[\"task\",\"id\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n    \"],[5,\"change-requests/edit/tasks/task\",[],[[\"@model\",\"@accounts\"],[[23,1,[\"task\"]],[23,1,[\"accounts\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n    \"],[5,\"bs-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"default\",[28,\"fn\",[[23,2,[]],false],null],[28,\"t\",[\"buttons.cancel\"],null]]]],[0,\"\\n\\n    \"],[5,\"bs-button\",[],[[\"@text\",\"@type\",\"@action\",\"@disabled\"],[[28,\"t\",[\"buttons.save\"],null],\"primary\",[28,\"fn\",[[23,2,[]],true],null],[28,\"not\",[[23,1,[\"task\",\"isDirty\"]]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/change-requests/edit/tasks-table/edit-modal/template.hbs"
    }
  });

  _exports.default = _default;
});