define("iris/routes/finances/incoming-invoices/forward-to-accounting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      const now = new Date();
      controller.set('globalDatePayment', now);

      if (model) {
        model.forEach(invoice => {
          invoice.set('forwardedToAccounting', now);
        });
      }

      this._super(controller, model);
    },

    actions: {
      back(invoices) {
        invoices.forEach(invoice => {
          invoice.rollbackAttributes();
        });
        this.transitionTo('finances.incoming-invoices');
      },

      setDates(date) {
        const model = this.controller.get('model');
        model.forEach(invoice => {
          invoice.set('forwardedToAccounting', date);
        });
      },

      async forwardInvoices(invoices) {
        const flashMessages = Ember.get(this, 'flashMessages');
        const promises = invoices.invoke('save');
        await Ember.RSVP.all(promises);
        flashMessages.add({
          message: 'Successfully updated!',
          title: 'IRIS message',
          icon: 'info'
        });
        this.transitionTo('finances.incoming-invoices');
      }

    }
  });

  _exports.default = _default;
});