define("iris/models/service-period-file", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-copyable", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberDataCopyable, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    dateType: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    servicePeriodIntervals: [(0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('length', {
      min: 1,
      messageKey: 'errors.has-many-add'
    })]
  });

  var _default = _model.default.extend(_emberDataCopyable.default, Validations, {
    // Here we wanted to overwrite the default settings of the change-tracker but
    // because the change-tracker cannot handle the hasMany relationships well, it
    // caused some bug in the related record savings and I have to change it back
    // to the default value.
    //
    // Trello card of the actual issue:
    // https://trello.com/c/GQjNarUZ/636-szolg-id%C5%91-szerkeszt%C3%A9s-hiba
    changeTracker: {
      trackHasMany: false,
      enableIsDirty: true,
      auto: true
    },
    name: (0, _attr.default)('string'),
    dateType: (0, _relationships.belongsTo)('dateTypeFile'),
    file: (0, _relationships.belongsTo)('project'),
    servicePeriodIntervals: (0, _relationships.hasMany)('servicePeriodFileInterval')
  });

  _exports.default = _default;
});