define("iris/components/modals/completion-certificate/signer-alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p8IZyy9Y",
    "block": "{\"symbols\":[\"@data\",\"@close\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-content mx-auto\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"projects.completion-certificate-generate-error\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"alert alert-warning\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"projects.files.no-completion-cert-signer\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"bs-form\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"completionCertSigner\",[28,\"t\",[\"models.completionCertSigner\"],null]]],{\"statements\":[[0,\"\\n        \"],[5,\"input/select/lazy\",[],[[\"@modelName\",\"@where\",\"@selected\",\"@onchange\"],[\"Contact\",[28,\"hash\",null,[[\"partnerId\"],[[23,1,[\"file\",\"clientPartner\",\"id\"]]]]],[22,\"signer\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"signer\"]]],null]],null]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"primary\",[28,\"action\",[[23,0,[]],\"generateCompletionCertificate\"],null],[28,\"or\",[[23,1,[\"okButtonLabel\"]],[28,\"t\",[\"finances.tig-generation\"],null]],null]]]],[0,\"\\n\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"default\",[28,\"fn\",[[23,2,[]],false],null],[28,\"or\",[[23,1,[\"cancelButtonLabel\"]],[28,\"t\",[\"buttons.cancel\"],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/modals/completion-certificate/signer-alert/template.hbs"
    }
  });

  _exports.default = _default;
});