define("iris/components/bs-table-server", ["exports", "iris/classes/filter/condition-builder"], function (_exports, _conditionBuilder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    classNames: ['table-component'],
    init: function () {
      this._super(); // see https://dockyard.com/blog/2014/04/17/ember-object-self-troll


      this.set('customFilters', Ember.A());
      this.set('selectedRecords', Ember.A());
      this.set('filterMode', {
        text: 'Bármelyik feltétel igaz',
        mode: 'or'
      });

      if (this.get('fetchData')) {
        this.set('data', Ember.A()); // Ha van columnFilterOptions akkor az adatok betöltését a column-filter compononens kezdeményezi

        if (!this.get('columnFilterOptions') && !this.get('globalSearchTerm')) {
          this._fetchData();
        }
      }

      this.set('defaultFilterOptionsSave', this.get('defaultFilterOptions'));
    },
    setWidths: function (widths) {
      var width = 0,
          widths = widths.split(',');
      this.$('table tr th').each(function (i, v) {
        width += $(v).width();
      });
      this.$('table tr th').each(function (i, v) {
        var w = widths[i] * width / 100;
        $(v).width(w);
      });
    },
    didInsertElement: function () {
      this._super(...arguments);

      if (this.get('colResizable')) {
        this.$('table').colResizable();
      }

      var widths = this.get('widths');

      if (widths) {
        this.setWidths(widths);
      }
    },
    didRender: function () {
      this._super(...arguments);

      if (this.get('colResizable')) {
        this.$('table').colResizable({
          disable: true
        });
        this.$('table').colResizable();
      }
    },
    allRecordSelected: Ember.computed('selectedRecords.length', 'paginatedData.length', function () {
      return this.get('paginatedData.length') !== 0 && this.get('selectedRecords.length') === this.get('paginatedData.length');
    }),
    observDataLength: Ember.observer('data.length', function () {
      this.set('isLoading', false);
    }),
    refreshTable: Ember.observer('refresh', function () {
      var refresh = this.get('refresh');

      if (refresh) {
        this.set('page', 1);

        this._fetchData();
      }

      return true;
    }),
    total: Ember.computed.reads('data.length'),
    isLoading: false,
    colResizable: false,
    hasActions: false,
    hasCheckbox: true,
    newRecords: false,
    enableSortable: false,
    isShowDeleteBtn: true,
    search: true,
    export: true,
    filterOpen: false,
    offset: Ember.computed('page', 'limit', function () {
      return (this.get('page') - 1) * this.get('limit');
    }),
    needPanel: true,
    page: 1,
    limit: 25,
    columns: [],
    clearFilterObject: Ember.observer('columnFilterOptions', 'defaultFilterOptions', function () {//this.set('filterObject', A());
    }),
    customFilters: null,
    // EmberArray<EntityAttributeFilter> | null
    customFilterConditions: Ember.computed('customFilters.@each.{property,operator,value}', function () {
      let serializedFilters = this.customFilters.map(f => f.serialize()).filter(Boolean);
      return (0, _conditionBuilder.buildConditions)(serializedFilters);
    }),
    _cfoSelection: Ember.computed.mapBy('columnFilterOptions', 'selected'),
    filterObject: Ember.computed('columnFilterOptions.@each.searchTerm', 'columnFilterOptions.@each.date', 'columnFilterOptions.@each.value', '_cfoSelection.[]', 'customFilterConditions.[]', 'defaultFilterOptions', function () {
      let res = Ember.A();
      let obj1 = null;
      let obj2 = null;
      let date = null;
      const columns = this.get('columns');
      let searchTerm = this.get('globalSearchTerm') || '';

      if (searchTerm !== '') {
        let searchObj = {
          or: []
        };
        columns.forEach(column => {
          if (column.serverTableSearch) {
            let temp = {};
            temp[column.property] = {
              ilike: `%${searchTerm}%`
            };
            searchObj.or.push(temp);
          }
        });
        res.pushObject(searchObj);
      }

      let columnFilterOptions = this.get('columnFilterOptions');

      if (columnFilterOptions) {
        columnFilterOptions.forEach(cfo => {
          let cfoList = [];

          if (cfo.fieldSet) {
            cfoList = cfo.fieldSet.fields;
          } else {
            cfoList.push(cfo);
          }

          for (let i = 0; i < cfoList.length; i++) {
            obj1 = null;

            switch (cfoList[i].type) {
              case 'checkbox':
                obj1 = {};

                if (cfoList[i].checkboxType === 'string') {
                  obj1[cfoList[i].property] = cfoList[i].value === 1 ? {
                    neq: null
                  } : null;
                } else if (cfoList[i].value == true) {
                  obj1[cfoList[i].property] = cfoList[i].value === 1 ? true : false;
                }

                break;

              case 'boolean':
                obj1 = {};
                obj1[cfoList[i].property] = cfoList[i].value;
                break;

              case 'multiselect':
                if (cfoList[i].multiple && cfoList[i].selected.length) {
                  obj1 = {};
                  let temp = cfoList[i].selected.map(item => {
                    return item.condition;
                  });

                  if (cfoList[i].property) {
                    obj1[cfoList[i].property] = {
                      inq: temp
                    };
                  } else {
                    obj1 = {
                      or: temp
                    };
                  }
                }

                break;

              case 'select':
                if (cfoList[i].selected && cfoList[i].selected.condition) {
                  obj1 = cfoList[i].selected.condition;
                }

                break;

              case 'date':
                if (cfoList[i].date) {
                  obj1 = {};
                  obj2 = {};
                  date = moment(cfoList[i].date).format("YYYY-MM-DD");
                  obj2[cfoList[i].operator] = date;
                  obj1[cfoList[i].property] = obj2;

                  if (obj2.lte) {
                    date = moment(cfoList[i].date).add(1, 'days').format("YYYY-MM-DD");
                  }

                  obj2[cfoList[i].operator] = date;
                }

                break;

              case 'search':
                if (cfoList[i].searchTerm) {
                  let tempSearchTerm = cfoList[i].searchTerm.replace(/\\/g, "\\");
                  obj1 = {};
                  obj1[cfoList[i].property] = {
                    ilike: '%' + tempSearchTerm + '%'
                  };
                }

                break;

              default:
                if (cfoList[i].value) {
                  obj1 = {};
                  obj1[cfoList[i].property] = cfoList[i].value;
                }

                break;
            }

            if (obj1) {
              if (cfoList[i].rewriteDefault) {
                Ember.run.next(() => {
                  if (!this.get('isDestroyed') && !this.get('isDestroying')) {
                    // TODO: backtracking-rerender
                    this.set('defaultFilterOptions', Ember.A());
                    this.get('defaultFilterOptions').pushObject(obj1);
                  }
                });
              } else {
                res.pushObject(obj1);
              }
            }
          }
        });
      }

      if (Array.isArray(this.get('customFilterConditions'))) {
        res = res.concat(this.get('customFilterConditions'));
      }

      return res;
    }),
    defaultFilterOptionsSave: null,
    filterModes: [{
      text: 'Bármelyik feltétel igaz',
      mode: 'or'
    }, {
      text: 'Mindegyik feltétel igaz',
      mode: 'and'
    }, {
      text: 'Egyik feltétel sem igaz',
      mode: 'neq'
    }],
    filterMode: {
      text: 'Bármelyik feltétel igaz',
      mode: 'or'
    },
    selectedFilter: Ember.computed(function () {
      let filterOptions = this.get('filterOptions');
      let res = Ember.A();

      if (filterOptions) {
        filterOptions.forEach(fo => {
          if ("options" in fo) {
            fo.options.forEach(o => {
              if (o.default) {
                res.pushObject(o);
              }
            });
          } else {
            if (fo.default) {
              res.pushObject(fo);
            }
          }
        });
      }

      return res;
    }),
    itemsPerPage: Ember.computed('total', function () {
      let total = this.get('total');
      return [{
        label: "5",
        value: 5
      }, {
        label: "25",
        value: 25
      }, {
        label: "50",
        value: 50
      }, {
        label: "100",
        value: 100
      }, {
        label: "Összes",
        value: total
      }];
    }),
    selectedItemsPerPage: Ember.computed('limit', 'itemsPerPage', function () {
      return this.get('itemsPerPage').findBy('value', this.get('limit'));
    }),
    sortProperties: Ember.computed(function () {
      let defaultOrder = this.get('defaultOrder');
      let res = Ember.A();

      if (defaultOrder) {
        res.pushObject(defaultOrder);
      }

      return res;
    }),
    searchColumns: Ember.computed('columns', function () {
      let columns = this.get('columns');
      let res = Ember.A();

      if (columns) {
        columns.forEach(c => {
          if (c.search) res.pushObject(c.property);else if (Ember.typeOf(c) === 'instance') {
            if (c.get('type') === 'string' || c.get('type') === 'belongsTo') res.pushObject(c.get('property'));
          }
        });
      }

      return res;
    }),
    operators: {
      '=': function (a, b) {
        return a === b;
      },
      '>': function (a, b) {
        return a > b;
      },
      '<': function (a, b) {
        return a < b;
      },
      'eq': function (a, b) {
        return a == b;
      },
      'cont': function (a, b) {
        return a.toLowerCase().indexOf(b.toLowerCase()) > -1;
      }
    },
    searchedColumns: Ember.computed.mapBy('columns', 'property'),
    paginatedData: Ember.computed.reads('data'),

    _fetchData() {
      this.set('isLoading', true);
      let hash = {
        limit: this.get('limit'),
        offset: this.get('offset'),
        filterObject: this.get('filterObject'),
        defaultFilterOptions: this.get('defaultFilterOptions'),
        defaultFilter: this.get('defaultFilter'),
        filterMode: this.get('filterMode.mode'),
        searchTerm: this.get('searchTerm'),
        searchColumns: this.get('searchColumns'),
        sortProperties: this.get('sortProperties'),
        method: this.get('method'),
        verb: this.get('verb'),
        id: this.get('modelId'),
        table: this
      };
      this.get('fetchData')(hash);
      this.set('selectedRecords', Em.A());
    },

    pages: Ember.computed('total', 'limit', 'page', function () {
      let totalPage = Math.ceil(this.get('total') / this.get('limit'));
      let pages = Ember.A();
      let curPage = this.get('page');
      let page = 0;
      let startPage = curPage < 5 ? 1 : curPage - 4;
      let endPage = 8 + startPage;
      endPage = totalPage < endPage ? totalPage : endPage;
      let diff = startPage - endPage + 8;
      startPage -= startPage - diff > 0 ? diff : 0;

      if (startPage > 1) {
        pages.pushObject({
          number: 1,
          text: 'Első'
        });
        pages.pushObject({
          text: '...'
        });
      }

      for (let i = startPage; i <= endPage; i++) {
        page = i;
        pages.pushObject({
          number: page,
          text: page,
          cssClass: page === curPage ? 'active' : ''
        });
      }

      if (endPage < totalPage) {
        pages.pushObject({
          text: '...'
        });
        pages.pushObject({
          number: totalPage,
          text: 'Utolsó'
        });
      }

      return pages;
    }),
    countRightBtns: Ember.computed('buttonCheckboxActions', 'buttonActions', function () {
      var count = 0;
      var checkBtns = this.get('buttonCheckboxActions');
      var actionBtns = this.get('buttonActions');

      if (checkBtns) {
        checkBtns.forEach(item => {
          if (item.position === 'right') {
            count++;
          }
        });
      }

      if (actionBtns) {
        actionBtns.forEach(item => {
          if (item.position === 'right') {
            count++;
          }
        });
      }

      return count;
    }),
    countLeftBtns: Ember.computed('buttonCheckboxActions', 'buttonActions', function () {
      var count = 0;
      var checkBtns = this.get('buttonCheckboxActions');
      var actionBtns = this.get('buttonActions');

      if (checkBtns) {
        checkBtns.forEach(item => {
          if (item.position !== 'right') {
            count++;
          }
        });
      }

      if (actionBtns) {
        actionBtns.forEach(item => {
          if (item.position !== 'right') {
            count++;
          }
        });
      }

      return count;
    }),
    hasExtraBtns: Ember.computed('buttonCheckboxActions', 'buttonActions', function () {
      var checkBtns = this.get('buttonCheckboxActions');
      var actionBtns = this.get('buttonActions');

      if (checkBtns) {
        return true;
      }

      if (actionBtns) {
        return true;
      }

      return false;
    }),
    predefinedFilterOptions: Ember.computed('filterOptions', function () {
      this.get('filterOptions').forEach(group => {
        group.options.forEach(option => {
          if (option.default) {}
        });
      });
    }),
    moreFilter: false,
    monitoringFilter: false,
    actions: {
      toggleMoreFilter() {
        this.toggleProperty('moreFilter');
      },

      toggleMonitoringFilter() {
        this.toggleProperty('monitoringFilter');
      },

      setLimit(limit) {
        this.set('limit', limit.value);
        this.set('page', 1);

        this._fetchData();
      },

      changePage(page) {
        this.set('page', page);

        this._fetchData();
      },

      sort(property, e) {
        if (!property) {
          return;
        }

        var res = [];
        var sortProperties = this.get('sortProperties');
        var index = sortProperties.findIndex(item => item.indexOf(property) > -1);

        if (e.shiftKey) {
          sortProperties.forEach(item => res.push(item));
        }
        /* jshint expr:true */


        if (index === -1) {
          res.push(property);
        } else if (sortProperties[index].indexOf(' desc') > -1) {
          e.shiftKey ? res.splice(index, 1) : res.push(property);
        } else {
          e.shiftKey ? res[index] = property + ' desc' : res.push(property + ' desc');
        }

        this.set('sortProperties', res);

        this._fetchData();
      },

      updateLimit(selection) {
        this.set('limit', selection.id);
        this.set('offset', 1);
      },

      emptySelectedRecords() {
        this.set('selectedRecords', Ember.A());
      },

      delete() {
        let selectedRecords = this.get('selectedRecords');
        let deleteAction = this.get('deleteAction');
        let ids = [];
        let entitiesArray = Ember.A();

        if (this.get('model')) {
          //Pseudo model
          selectedRecords.forEach(record => {
            ids.push(record.id);
          });
          this.get('store').query(this.get('model'), {
            filter: {
              where: {
                id: {
                  inq: ids
                }
              }
            }
          }).then(entities => {
            entities.forEach(function (entity) {
              entitiesArray.pushObject(entity);
            });

            if (deleteAction) {
              this.sendAction('deleteAction', entitiesArray, this.get('actions.search').bind(this));
            } else {
              let router = Ember.getOwner(this).lookup('router:main');
              router.send('delete', {
                afterDelete: this.get('actions.search').bind(this)
              }, entitiesArray);
            }
          });
        } else {
          if (deleteAction) {
            this.sendAction('deleteAction', selectedRecords);
          } else {
            let router = Ember.getOwner(this).lookup('router:main');
            router.send('delete', selectedRecords);
          }
        }
      },

      search() {
        this.set('page', 1);

        this._fetchData();
      },

      columnSearch(page) {
        this.notifyPropertyChange('filterObject');
        this.set('filterMode', {
          mode: 'and'
        });
        this.set('page', page > 0 ? page : 1);

        this._fetchData();

        if (this.get('afterSearch')) {
          this.get('afterSearch')();
        }
      },

      deleteFilter(skipFetch) {
        let columnFilterOptions = this.get('columnFilterOptions');
        let defaultFilterOptionsSave = this.get('defaultFilterOptionsSave');

        if (columnFilterOptions) {
          columnFilterOptions.forEach(cfo => {
            if (cfo.fieldSet) {
              cfo.fieldSet.fields.forEach(fieldSetCfo => {
                Ember.set(fieldSetCfo, 'selected', []);
                Ember.set(fieldSetCfo, 'searchTerm', null);
                Ember.set(fieldSetCfo, 'date', null);
                Ember.set(fieldSetCfo, 'value', null);
              });
            } else {
              Ember.set(cfo, 'selected', []);
              Ember.set(cfo, 'searchTerm', null);
              Ember.set(cfo, 'date', null);
              Ember.set(cfo, 'value', null);
            }
          });
          this.set('globalSearchTerm', null);
        }

        this.set('defaultFilterOptions', defaultFilterOptionsSave);
        this.set('page', 1);

        if (!skipFetch) {
          this._fetchData();
        }
      },

      selectAll() {
        if (!this.get('allRecordSelected')) {
          let records = Ember.A();
          this.get('paginatedData').forEach(record => {
            records.pushObject(record);
          });
          this.set('selectedRecords', records);
        } else {
          this.send('emptySelectedRecords');
        }
      },

      filterSelected(selected) {
        this.set('selectedFilter', selected);
        this.set('page', 1);

        if (selected.length) {
          selected.forEach(s => {
            this.get('filterObject').pushObject(s.condition);
          });
        }

        this._fetchData();
      },

      filterModeSelected(selected) {
        this.set('filterMode', selected);
        this.set('page', 1);

        this._fetchData();
      },

      advancedFilter(criterias) {
        this.set('filterMode', {
          mode: 'and'
        });
        this.set('filterObject', criterias);
        this.set('page', 1);

        this._fetchData();
      },

      columnFilterSelected(columnFilter, selected) {
        Ember.set(columnFilter, 'selected', selected);

        if (!selected && columnFilter.rewriteDefault) {
          this.set('defaultFilterOptions', this.get('defaultFilterOptionsSave'));
        }
      },

      exportTable() {
        let options = this.get('exportOptions') || {};
        options.type = 'csv';
        options.fileName = 'Iris export';
        options.csvSeparator = ';';
        let table = this.$('table').clone();
        table.each(function () {
          $(this).find('tr').each(function () {
            $(this).find('td').each(function () {
              let newText = $(this).text().replace(new RegExp(" ", "g"), '').replace(new RegExp(" ", "g"), '');
              if (!isNaN(newText)) $(this).text(newText);
            });
          });
        });

        if (options.beforeExport) {
          table = options.beforeExport(table);
        }

        table.tableExport(options);
      },

      callSwitchAction(switchButton) {
        switchButton.action(switchButton.value);
      }

    }
  });

  _exports.default = _default;
});