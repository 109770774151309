define("iris/models/project", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "iris/mixins/custom-attributes", "iris/mixins/anonymize"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _customAttributes, _anonymize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    managerUser: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    profitCenter: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    fileType: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    fileBusinessType: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    fileState: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    primeContractor: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    completionLevel: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    projectValue: [(0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['model.isOpportunity', 'model.type'],
      disabled: Ember.computed(function () {
        return this.get('model').get('type') === 'operational';
      }).volatile(),
      presence: true
    })],
    projectValueCurrency: [(0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['model.isOpportunity', 'model.type'],
      disabled: Ember.computed(function () {
        return this.get('model').get('type') === 'operational';
      }).volatile(),
      presence: true
    })],
    clientPartner: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    fileOwnerPartner: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    sponsorUser: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    serviceDesk: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['model.isOpportunity', 'model.type'],
      disabled: Ember.computed(function () {
        return this.get('model').get('type') !== 'operational' || this.get('model').get('isOpportunity');
      }).volatile(),
      presence: true
    })],
    providerCompletionCertSigner: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed(function () {
        return !this.get('model.id');
      }).volatile()
    })],
    technicalManagerUser: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    commission: [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      decimal: 1,
      positive: true
    })],
    probability: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });
  let now = new Date();

  var _default = _model.default.extend(Validations, _customAttributes.default, _anonymize.default, {
    changeTracker: {
      trackHasMany: true,
      enableIsDirty: true,
      auto: true
    },
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    setMinCompletionLevel: function () {
      let level = this.get('completionLevel');
      level = isNaN(level) ? 0 : level;
      this.set('minCompletionLevel', level);
    }.on('didLoad', 'didCreate', 'didUpdate', 'init'),
    modelName: 'Project',
    name: (0, _attr.default)('string'),
    fileNumber: (0, _attr.default)('string'),
    crType: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    type: (0, _attr.default)('string', {
      defaultValue: 'normal'
    }),
    dateStart: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    dateEnd: (0, _attr.default)('date'),
    dateCreate: (0, _attr.default)('date'),
    closed: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    report: (0, _attr.default)('boolean', {
      defaultValue: 0
    }),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: 1
    }),
    publicInternal: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    publicExternal: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    accountable: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    description: (0, _attr.default)('string'),
    fileNumberIndex: (0, _attr.default)('number'),
    projectValue: (0, _attr.default)('number', {
      defaultValue: 0,
      formWidget: 'mask'
    }),
    tariff: (0, _attr.default)('number', {
      defaultValue: 0,
      formWidget: 'mask'
    }),
    priority: (0, _attr.default)('number', {
      defaultValue: 3
    }),
    completionLevel: (0, _attr.default)('number', {
      defaultValue: function () {
        return 0;
      }
    }),
    planPeriodCloseDate: (0, _attr.default)('date'),
    warning: (0, _attr.default)('number'),
    crVersion: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    approvalDate: (0, _attr.default)('date'),
    probability: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    subcontractor: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    continuous: (0, _attr.default)('number'),
    newCustomer: (0, _attr.default)('number'),
    overHead: (0, _attr.default)('number', {
      defaultValue: 1.76
    }),
    registrationDayLimit: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    maxHoursPerWorkLog: (0, _attr.default)('number', {
      defaultValue: 10
    }),
    stateChange: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    internalAccounting: (0, _attr.default)('number'),
    orderNumber: (0, _attr.default)('string'),
    projectTicketTemplateGroupId: (0, _attr.default)('string'),
    commission: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    comment: (0, _attr.default)('string', {
      formWidget: 'textarea'
    }),
    exchangeRate: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    isOpportunity: Ember.computed.lt('probability', 100),
    isCr: Ember.computed.bool('crVersion'),
    fileYear: Ember.computed('dateStart', function () {
      return moment().isSame(moment(this.get('dateStart')), 'y');
    }),
    //Filters
    completionLevelInDanger: Ember.computed('completionLevel', 'investmentPlanned', 'investmentActual', function () {
      return this.get('investmentActual') / this.get('investmentPlanned') * 100 > this.get('completionLevel');
    }),
    //Semaphors
    mustBePlanned: Ember.computed.and('fileType.planning', 'fileState.planning'),
    proposalsCount: function () {
      return this.get('proposals.length');
    }.property('proposals.length'),
    outgoingProposalsCount: function () {
      return this.get('outgoingProposals.length');
    }.property('outgoingProposals.length'),
    orderedOutgoingProposals: Ember.computed('outgoingProposals.[]', {
      get(key) {
        let _this = this;

        let value;
        this.get('outgoingProposals').then(outgoingProposals => {
          _this.set('orderedOutgoingProposals', outgoingProposals);

          value = outgoingProposals;
        });
        return value;
      },

      set(key, value) {
        return value;
      }

    }),
    orderedOutgoingProposalsCount: Ember.computed.filterBy('orderedOutgoingProposals', 'isOrdered', true),
    baseOutgoingProposal: Ember.computed('outgoingProposals.@each.valid', {
      get(key) {
        let value = null;

        let _this = this;

        this.get('outgoingProposals').then(proposals => {
          let valids = proposals.filterBy('valid', true);
          let ordereds = valids.filterBy('isOrdered', true);
          let base = ordereds.filterBy('type', 'type_base');

          _this.set('baseOutgoingProposal', base);
        });
        return value;
      },

      set(key, value) {
        return value;
      }

    }),
    tandmOutgoingProposal: Ember.computed('outgoingProposals.@each.valid', {
      get(key) {
        let value = null;

        let _this = this;

        this.get('outgoingProposals').then(proposals => {
          let valids = proposals.filterBy('valid', true);
          let ordereds = valids.filterBy('isOrdered', true);
          let tandm = ordereds.filterBy('type', 'type_tandm');

          _this.set('tandmOutgoingProposal', tandm);
        });
        return value;
      },

      set(key, value) {
        return value;
      }

    }),
    tandmOvertimeOutgoingProposal: Ember.computed('outgoingProposals.@each.valid', {
      get(key) {
        let value = null;

        let _this = this;

        this.get('outgoingProposals').then(proposals => {
          let valids = proposals.filterBy('valid', true);
          let ordereds = valids.filterBy('isOrdered', true);
          let tandmOvertime = ordereds.filterBy('type', 'type_tandm_overtime');

          _this.set('tandmOvertimeOutgoingProposal', tandmOvertime);
        });
        return value;
      },

      set(key, value) {
        return value;
      }

    }),
    projectOutgoingProposal: Ember.computed('outgoingProposals.@each.valid', {
      get(key) {
        let value = null;

        let _this = this;

        this.get('outgoingProposals').then(proposals => {
          let valids = proposals.filterBy('valid', true);
          let ordereds = valids.filterBy('isOrdered', true);
          let project = ordereds.filterBy('type', 'type_project');

          _this.set('projectOutgoingProposal', project);
        });
        return value;
      },

      set(key, value) {
        return value;
      }

    }),
    validOutgoingProposal: Ember.computed('outgoingProposals.@each.valid', 'probability', {
      get(key) {
        let value = null;

        let _this = this;

        const probability = this.probability;
        this.get('outgoingProposals').then(proposals => {
          let valids = proposals.filterBy('valid', true); // if project is opportunity, outgoing proposal
          // must not be ordered to appear in balance

          let ordereds = probability < 100 ? valids : valids.filterBy('isOrdered', true);

          _this.set('validOutgoingProposal', ordereds);
        });
        return value;
      },

      set(key, value) {
        return value;
      }

    }),
    validProposals: Ember.computed('proposals.@each.valid', {
      get(key) {
        let value = null;

        let _this = this;

        this.get('proposals').then(proposals => {
          let valids = proposals.filterBy('valid');

          _this.set('validProposals', valids);
        });
        return value;
      },

      set(key, value) {
        return value;
      }

    }),
    //Budget
    incomePlannedBudgetMoney: Ember.computed.mapBy('tickets.content', 'plannedBudgetMoney'),
    income: Ember.computed.sum('incomePlannedBudgetMoney'),
    weightedIncome: Ember.computed('probability', 'projectValue', function () {
      let projectValue = this.get('projectValue');
      let probability = this.get('probability');
      if (!probability) probability = 100;
      return projectValue * probability / 100;
    }),
    workLogs: Ember.computed({
      get() {
        let id = this.get('id');
        this.get('ajax').call('GET', 'projects', id, 'work-logs', {}).then(result => {
          this.set('workLogs', Ember.A(result));
        });
      },

      set(key, value) {
        return value;
      }

    }),
    durationInDays: Ember.computed('dateStart', 'dateEnd', function () {
      let start = moment(this.get('dateStart'));
      let end = moment(this.get('dateEnd'));
      return Math.abs(start.diff(end, 'days')) + 1;
    }),
    remainingDays: Ember.computed('dateEnd', function () {
      let end = moment(this.get('dateEnd'));
      return end.diff(moment(), 'days') + 1;
    }),
    elapsedPerCent: Ember.computed('durationInDays', 'remainingDays', function () {
      let remainingDays = this.get('remainingDays');
      let durationInDays = this.get('durationInDays');
      return (durationInDays - remainingDays) / durationInDays * 100;
    }),
    onTimePerCent: Ember.computed('completionLevel', 'elapsedPerCent', function () {
      let res = this.get('completionLevel') / this.get('elapsedPerCent') * 100;
      return parseFloat(res).toFixed(2);
    }),
    isProposal: Ember.computed.equal('fileState.id', 'SYS_02'),
    // burnt-in value
    resolvedTickets: Ember.computed('tickets.[]', {
      get(key) {
        let _this = this;

        let value;
        this.get('tickets').then(tickets => {
          _this.set('resolvedTickets', tickets);

          value = tickets;
        });
        return value;
      },

      set(key, value) {
        return value;
      }

    }),
    ticketsInProgress: Ember.computed.filter('resolvedTickets', function (ticket) {
      return ticket.belongsTo('state').id() !== 'SYS_10';
    }),
    dueTickets: Ember.computed('ticketsInProgress.@each.plannedEndDate', function () {
      let dueDate = moment().add(5, 'd');
      return this.get('ticketsInProgress').filter(ticket => {
        return moment(ticket.get('plannedEndDate')).isBetween(dueDate, moment());
      });
    }),
    expiredTickets: Ember.computed('ticketsInProgress.@each.plannedEndDate', function () {
      return this.get('ticketsInProgress').filter(ticket => {
        return moment().isAfter(moment(ticket.get('plannedEndDate')));
      });
    }),
    expiredTicketsOperational: Ember.computed('ticketsInProgress.@each.solveDeadline', function () {
      return this.get('ticketsInProgress').filter(ticket => {
        if (ticket.get('solveDeadline')) {
          return moment().isAfter(moment(ticket.get('solveDeadline')));
        } else {
          return false;
        }
      });
    }),
    _ticketsTicketUserAssocs: Ember.computed.mapBy('tickets', 'ticketUserAssocs'),
    ticketUserAssocs: Ember.computed.mapBy('_ticketsTicketUserAssocs', 'content'),
    publicSpentTimes: Ember.computed.map('workLogs', function (item) {
      let times = item.mapBy('publicSpentTime');

      if (times.length) {
        return times.reduce((prev, current) => {
          return prev + current;
        });
      }

      return 0;
    }),
    plannedExpensesInHours: Ember.computed.map('ticketUserAssocs', function (item) {
      let times = item.mapBy('plannedExpensesInHour');

      if (times.length) {
        return times.reduce((prev, current) => {
          return prev + current;
        });
      }

      return 0;
    }),
    spentTimes: Ember.computed.map('workLogs', function (item) {
      let times = item.mapBy('spentTime');

      if (times.length) {
        return times.reduce((prev, current) => {
          return prev + current;
        });
      }

      return 0;
    }),
    resolvedWorkLogs: Ember.computed.mapBy('resolvedTickets', 'allGradeCosts'),
    ticketPlannedExpensesInHours: Ember.computed.mapBy('tickets.content', 'plannedExpensesInHour'),
    investmentActive: Ember.computed.sum('publicSpentTimes'),
    investmentInActive: Ember.computed.sum('spentTimes'),
    investmentAllocated: Ember.computed.sum('plannedExpensesInHours'),
    investmentPlanned: Ember.computed.sum('ticketPlannedExpensesInHours'),
    investmentAssignable: Ember.computed('investmentPlanned.[]', 'investmentAllocated.[]', function () {
      return this.get('investmentPlanned') - this.get('investmentAllocated');
    }),
    investmentActual: Ember.computed('investmentActive.[]', 'investmentInActive.[]', function () {
      return this.get('investmentActive') + this.get('investmentInActive');
    }),
    investmentPlanAct: Ember.computed('investmentPlanned.[]', 'investmentActive.[]', 'investmentInActive.[]', function () {
      return this.get('investmentPlanned') - this.get('investmentActive') - this.get('investmentInActive');
    }),
    onTime: Ember.computed('dateStart', 'dateEnd', function () {
      let start = moment(this.get('dateStart'));
      let end = moment(this.get('dateEnd'));
      let duration = moment.duration(end.diff(start));
      let days = duration.asDays();

      if (days == 0) {
        return 100;
      } else {
        days = Math.round(moment.duration(moment().diff(start)).asDays() / moment.duration(end.diff(start)).asDays() * 100);
        return days;
      }
    }),
    onBudgetHour: Ember.computed('investmentPlanned.[]', 'investmentActual.[]', function () {
      let investmentPlanned = this.get('investmentPlanned');
      let investmentActual = this.get('investmentActual');

      if (investmentActual && investmentPlanned) {
        return Math.round(investmentActual / (investmentPlanned / 100));
      } else {
        return 0;
      }
    }),
    plannedBudgetMoney: Ember.computed('investmentPlanned.[]', function () {
      let investmentPlanned = this.get('investmentPlanned'); //let cost = item.get('cost');

      let cost = 13500; // Ide kell mad a jó érték

      return investmentPlanned * cost;
    }),
    actualBudgetMoney: Ember.computed('investmentActual.[]', function () {
      let investmentActual = this.get('investmentActual'); //let cost = item.get('cost');

      let cost = 8000; // Ide kell mad a jó érték

      return investmentActual * cost;
    }),
    onBudgetValue: Ember.computed(function () {
      let plannedBudgetMoney = this.get('plannedBudgetMoney');
      let actualBudgetMoney = this.get('actualBudgetMoney');

      if (plannedBudgetMoney) {
        return Math.round(actualBudgetMoney / plannedBudgetMoney * 100);
      } else {
        return 0;
      }
    }),
    originalFile: (0, _relationships.belongsTo)('project'),
    region: (0, _relationships.belongsTo)('region'),
    clientPartner: (0, _relationships.belongsTo)('partner'),
    fileOwnerPartner: (0, _relationships.belongsTo)('partner'),
    primeContractor: (0, _relationships.belongsTo)('partner'),
    contract: (0, _relationships.belongsTo)('contract'),
    fileState: (0, _relationships.belongsTo)('fileState'),
    fileType: (0, _relationships.belongsTo)('fileType'),
    fileBusinessType: (0, _relationships.belongsTo)('fileBusinessType'),
    profitCenter: (0, _relationships.belongsTo)('profitCenter'),
    sponsorUser: (0, _relationships.belongsTo)('account'),
    partnerSponsor: (0, _relationships.belongsTo)('contact'),
    managerUser: (0, _relationships.belongsTo)('account'),
    salesManager: (0, _relationships.belongsTo)('account'),
    technicalManagerUser: (0, _relationships.belongsTo)('account'),
    serviceArea: (0, _relationships.belongsTo)('serviceArea'),
    serviceDesk: (0, _relationships.belongsTo)('solvingGroup'),
    completionCertSigner: (0, _relationships.belongsTo)('contact'),
    providerCompletionCertSigner: (0, _relationships.belongsTo)('account'),
    projectValueCurrency: (0, _relationships.belongsTo)('currency'),
    workLogs: (0, _relationships.hasMany)('workLog'),
    documents: (0, _relationships.hasMany)('document', {
      polymorphic: true
    }),
    invoices: (0, _relationships.hasMany)('invoice'),
    tickets: (0, _relationships.hasMany)('ticket', {
      constraints: true
    }),
    productResults: (0, _relationships.hasMany)('fileProductResult'),
    fileUserGradeCosts: (0, _relationships.hasMany)('fileUserGradeCost'),
    filePlanners: (0, _relationships.hasMany)('account'),
    fileSolvers: (0, _relationships.hasMany)('account'),
    fileSuppliers: (0, _relationships.hasMany)('supplier'),
    fileSolverGroups: (0, _relationships.hasMany)('fileSolverGroup'),
    proposals: (0, _relationships.hasMany)('fileProposal'),
    outgoingProposals: (0, _relationships.hasMany)('fileOutgoingProposal'),
    fileActionCompetencyAssocs: (0, _relationships.hasMany)('fileActionCompetencyAssoc'),
    operationServiceLevels: (0, _relationships.hasMany)('operationServiceLevel'),
    dateTypes: (0, _relationships.hasMany)('dateTypeFile'),
    servicePeriods: (0, _relationships.hasMany)('servicePeriodFile'),
    partnerITcontacts: (0, _relationships.hasMany)('contact'),
    tandms: (0, _relationships.hasMany)('tandm'),
    fileTariffs: (0, _relationships.hasMany)('fileTariff'),
    toDelete: {
      fileSolverGroups: Ember.A()
    },
    sync: Ember.observer('name', function () {
      if (this.get('isDestroyed') || this.get('isDestroying') || this.get('isDeleted')) {
        return;
      }

      if (this.get('type') === 'operational') {
        this.syncToContract();
      }
    }),

    syncToContract() {
      let props = this.getProperties('name');
      this.get('contract').then(contract => {
        // When the `contract` record is in the `root.empty` state, which happens
        // when the `project` is accessed through a `ticket` (ex.
        // `/operation/sd-dashboard/:id`), the following error is thrown:
        //
        // > Error: Attempted to handle event `didSetProperty` on
        // > <contract:DAM000000XXX> while in state root.empty
        if (contract && contract.currentState.stateName !== 'root.empty') {
          contract.setProperties(props);
        }
      });
    }

  });

  _exports.default = _default;
});