define("iris/components/modals/proposal-item-change-exchange-rate/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    exchangeRate: 0,
    max: 999.99,
    actions: {
      changeExchangeRate() {
        let {
          data: {
            exchangeRate
          },
          max
        } = this;

        if (exchangeRate > max) {
          exchangeRate = max;
        }

        this.close(exchangeRate);
      }

    }
  });

  _exports.default = _default;
});