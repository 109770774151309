define("iris/components/forms/file-type/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    projectTypes: Ember.computed(function () {
      return [{
        id: 0,
        name: this.intl.t('forms.projecttwo')
      }, {
        id: 1,
        name: this.intl.t('forms.agile')
      }, {
        id: 2,
        name: this.intl.t('forms.operation')
      }, {
        id: 3,
        name: this.intl.t('forms.waterfall')
      }];
    }),
    projectType: Ember.computed('model.projectType', 'projectTypes', {
      get(key) {
        let projectTypes = this.get('projectTypes');
        let projectType = this.get('model.projectType');
        return projectTypes[projectType];
      },

      set(key, value) {
        Ember.run.next(() => {
          if (!this.get('isDestroyed') && !this.get('isDestroying')) {
            this.set('model.projectType', value.id);
          }
        });
        return value;
      }

    })
  });

  _exports.default = _default;
});