define("iris/components/forms/settings-cmdb-entity-category", ["exports", "iris/utils/image-tools"], function (_exports, _imageTools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onInitOfUploader: function () {},
      // http://stackoverflow.com/questions/2434458/image-resizing-client-side-with-javascript-before-upload-to-the-server
      uploadImage: function (file) {
        let category = this.get('model');
        let image = file.file.getNative();

        _imageTools.default.resize(image, {
          width: 30,
          // maximum width
          height: 30 // maximum height

        }, function (blob, didItResize) {
          let reader = new window.FileReader();
          reader.readAsDataURL(blob);

          reader.onloadend = function () {
            let base64data = reader.result;
            category.set('icon', base64data);
          };
        });
      }
    }
  });

  _exports.default = _default;
});