define("iris/routes/settings/manage-time-offs/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      let store = this.store;
      return Ember.RSVP.hash({
        timeOff: store.find('ticket', params.id),
        statuses: store.query('status', {
          filter: {
            where: {
              or: [{
                "id": 'SYS_100'
              }, {
                "id": 'SYS_101'
              }, {
                "id": 'SYS_102'
              }]
            }
          }
        })
      });
    },

    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set("myTimeOffs", this.controllerFor('settings.manage-time-offs').get('myTimeOffs'));
      controller.set("isAdmin", true);
    }
  });

  _exports.default = _default;
});