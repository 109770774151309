define("iris/templates/components/menu-breadcrumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X+lndqE9",
    "block": "{\"symbols\":[\"breadcrumb\",\"index\",\"&default\"],\"statements\":[[4,\"active-link\",null,null,{\"statements\":[[4,\"link-to\",null,[[\"route\"],[\"dashboard\"]],{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"fa fa-home\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[23,0,[\"mappedBreadcrumbs\"]]],null,{\"statements\":[[4,\"if\",[[28,\"not\",[[28,\"is-last-index\",[[23,2,[]],[23,0,[\"mappedBreadcrumbs\",\"length\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[7,\"span\",true],[8],[1,[23,1,[]],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"breadcrumbs\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\",true],[8],[7,\"span\",true],[8],[1,[28,\"t\",[[28,\"concat\",[\"menus\",\".\",[28,\"join\",[\".\",[24,[\"breadcrumbs\"]]],null]],null]],null],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/menu-breadcrumbs.hbs"
    }
  });

  _exports.default = _default;
});