define("iris/components/form/fields/tax-number/modal/summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pTOvWV0w",
    "block": "{\"symbols\":[\"@patternValid\",\"@taxNumberPattern\",\"@taxNumbersFound\",\"@taxNumber\"],\"statements\":[[4,\"if\",[[23,4,[]]],null,{\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[10,\"class\",\"text-success\"],[8],[0,\"\\n        \"],[1,[28,\"fa-icon\",[\"check\"],null],false],[0,\"\\n        \"],[1,[28,\"t\",[\"tax-number-component.tax-number-valid\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"li\",true],[10,\"class\",\"text-danger\"],[8],[0,\"\\n        \"],[1,[28,\"fa-icon\",[\"times\"],null],false],[0,\"\\n        \"],[1,[28,\"t\",[\"tax-number-component.tax-number-invalid\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,3,[]],0],null]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[10,\"class\",\"text-success\"],[8],[0,\"\\n      \"],[1,[28,\"fa-icon\",[\"check\"],null],false],[0,\"\\n      \"],[1,[28,\"t\",[\"tax-number-component.tax-number-unique\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"gt\",[[23,3,[]],0],null]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[10,\"class\",\"text-danger\"],[8],[0,\"\\n      \"],[1,[28,\"fa-icon\",[\"times\"],null],false],[0,\"\\n      \"],[1,[28,\"t\",[\"tax-number-component.tax-number-not-unique\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/form/fields/tax-number/modal/summary/template.hbs"
    }
  });

  _exports.default = _default;
});