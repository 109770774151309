define("iris/components/modals/ticket-user-assoc-bulk-action/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5wvVBIl/",
    "block": "{\"symbols\":[\"@data\",\"@close\"],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-content mx-auto\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"data\",\"ticket\",\"activeTandm\"]]],null,{\"statements\":[[0,\"      \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"incident-form.allocation\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"forms.allocable-hours\"],null],false],[0,\": \"],[7,\"span\",true],[10,\"class\",\"badge badge-success text-2xl\"],[8],[1,[23,1,[\"ticket\",\"availableTimeToAssign\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-lg-8\"],[8],[0,\"\\n        \"],[1,[28,\"number-input\",null,[[\"mask\",\"value\"],[\"9{*}.{*}9{*}\",[24,[\"generalPlannedExpensesInHour\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-lg-4\"],[8],[0,\"\\n        \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@disabled\",\"@text\"],[\"primary\",[28,\"action\",[[23,0,[]],\"divide\"],null],[23,0,[\"validPannedExpensesInHour\"]],[28,\"t\",[\"forms.portion\"],null]]]],[0,\"\\n        \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"default\",[28,\"fn\",[[23,2,[]],false],null],[28,\"t\",[\"buttons.close\"],null]]]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/modals/ticket-user-assoc-bulk-action/template.hbs"
    }
  });

  _exports.default = _default;
});