define("iris/authenticators/keycloak", ["exports", "ember-simple-auth/authenticators/base", "iris/config/environment"], function (_exports, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    ajax: Ember.inject.service(),
    keycloakSession: Ember.inject.service(),

    restore(data) {
      return Ember.RSVP.resolve(data);
    },

    // authenticate(token) {
    //   return this.get('ajax').call('POST', 'account', '', 'checkKeycloakToken?token=' + token);
    // },
    async authenticate(token) {
      let res = null;

      try {
        res = await this.get('ajax').call('POST', 'account', '', 'checkKeycloakToken');
      } catch (err) {
        let foo = $('body section');
        let loginErrorPara = null;

        if (!foo.length) {
          let section = $('<section />').appendTo('body');
          section.attr('class', 'body-sign');
          let centerSign = $('<div />').appendTo(section);
          centerSign.attr('class', 'center-sign');
          let panel = $('<div />').appendTo(centerSign);
          panel.attr('class', 'panel panel-sign');
          let panelBody = $('<div />').appendTo(panel);
          panelBody.attr('class', 'panel-body');
          loginErrorPara = $('<p />').appendTo(panelBody);
          loginErrorPara.attr('class', 'login-error-para');
        } else {
          loginErrorPara = $('.login-error-para');
        }

        loginErrorPara.append(err.errors[0].detail);
      }

      if (res) {
        return res;
      }
    },

    invalidate() {
      const keycloakSession = this.get('keycloakSession');
      this.get('ajax').call('POST', 'account', '', 'invalidateKeycloakToken').then(ret => {
        const url = window.location.href;
        keycloakSession.logout(url); // Pentaho Front Channel Logout

        const pentahoUrl = _environment.default.pentaho.url;
        Ember.$.post(pentahoUrl + '/Logout');
      }); // The above promise can't just be returned, because then Ember Simple Auth
      // would act and the page had been reloaded before Keycloak could finish its
      // logout procedure. It will reload the window anyway, so we can make this
      // hang here indefinitely.

      return new Promise(() => {});
    }

  });

  _exports.default = _default;
});