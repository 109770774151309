define("iris/components/forms/tandm/modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let FormTandmModal = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class FormTandmModal extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "selectedMonth", false);

      _defineProperty(this, "months", [...Array(12)].map((_, i) => 1 + i));

      _defineProperty(this, "selectedDay", false);

      _defineProperty(this, "days", [...Array(31)].map((_, i) => 1 + i));
    }

    selectMonth(month) {
      if (month === this.data.creditValueMonth) {
        return;
      }

      const selectedMonth = month === this.selectedMonth ? false : month;
      this.set('selectedMonth', selectedMonth);
    }

    selectDay(day) {
      if (day === this.data.creditValueDay) {
        return;
      }

      const selectedDay = day === this.selectedDay ? false : day;
      this.set('selectedDay', selectedDay);
    }

    setNextStep() {
      this.set('data.step', 2);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "selectMonth", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "selectMonth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectDay", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "selectDay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setNextStep", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setNextStep"), _class.prototype)), _class));
  _exports.default = FormTandmModal;
});