define("iris/templates/projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kmuzt9P2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"sessionAccount\",\"menus\"]]],null,{\"statements\":[[0,\"  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/projects.hbs"
    }
  });

  _exports.default = _default;
});