define("iris/templates/settings/project/product-result-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oPcd/CJv",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@defaultOrder\",\"@neededFields\",\"@columnOverwrites\"],[\"product-result-template\",\"settings.project.product-result-templates.edit\",\"createDate DESC\",[28,\"array\",[\"name\",\"billable\",\"active\",\"inactive\"],null],[28,\"hash\",null,[[\"inactive\",\"active\"],[[28,\"hash\",null,[[\"visibility\",\"type\",\"displayName\"],[\"filter\",\"boolean\",[28,\"t\",[\"filters.show-inactive\"],null]]]],[28,\"hash\",null,[[\"visibility\"],[\"table\"]]]]]]]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/project/product-result-templates.hbs"
    }
  });

  _exports.default = _default;
});