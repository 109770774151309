define("iris/templates/settings/finances/nav-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HRoSNiBy",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@excludeFields\"],[\"nav-setting\",[28,\"hash\",null,[[\"new\",\"edit\",\"index\"],[\"settings.finances.nav-settings.new\",\"settings.finances.nav-settings.edit\",\"settings.finances.nav-settings.index\"]]],[28,\"array\",[\"pass\"],null]]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/finances/nav-settings.hbs"
    }
  });

  _exports.default = _default;
});