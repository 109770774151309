define("iris/components/tables/duties/duty-day-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mfra47Az",
    "block": "{\"symbols\":[\"dda\"],\"statements\":[[4,\"each\",[[23,0,[\"dutyDayUserAssocs\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"badge badge-info\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-clock-o\"],[8],[9],[0,\"\\n    \"],[1,[28,\"moment-time\",[[23,1,[\"shiftChange\",\"shiftBeginTime\"]]],null],false],[0,\"\\n    -\\n    \"],[1,[23,1,[\"account\",\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/tables/duties/duty-day-cell/template.hbs"
    }
  });

  _exports.default = _default;
});