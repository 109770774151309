define("iris/routes/tasks/work-logs/work-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel(model) {
      let parentController = this.controllerFor('tasks.work-logs');
      parentController.set('activeDate', model.get('startsAt'));
      parentController.set('workLog', model);
    },

    setupController(controller, model) {
      controller.set('model', model);
      let parentController = this.controllerFor('tasks.work-logs');
      controller.set('workLogsOfWeek', parentController.get('workLogsOfWeek'));
      controller.set('parentController', parentController);
    },

    actions: {
      willTransition() {
        let record = this.controller.get('model');
        record.rollback();
      }

    }
  });

  _exports.default = _default;
});