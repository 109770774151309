define("iris/serializers/application", ["exports", "ember-data/serializers/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    keyForAttribute(key) {
      return key;
    },

    keyForRelationship(key) {
      return key;
    },

    serializeAttribute(record, json, key, attribute) {
      if (attribute.options && attribute.options.readOnly) {
        return;
      }

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});