define("iris/components/iris-power-select-multiple/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TPO0aHPs",
    "block": "{\"symbols\":[\"option\",\"&default\"],\"statements\":[[5,\"power-select-multiple\",[],[[\"@allowClear\",\"@class\",\"@closeOnSelect\",\"@disabled\",\"@matcher\",\"@onChange\",\"@onOpen\",\"@options\",\"@placeholder\",\"@search\",\"@searchEnabled\",\"@searchField\",\"@selected\"],[[23,0,[\"_allowClear\"]],[23,0,[\"class\"]],[23,0,[\"closeOnSelect\"]],[23,0,[\"disabled\"]],[23,0,[\"matcher\"]],[23,0,[\"onchange\"]],[23,0,[\"onopen\"]],[23,0,[\"options\"]],[23,0,[\"placeholder\"]],[23,0,[\"search\"]],[23,0,[\"_searchEnabled\"]],[23,0,[\"_searchField\"]],[23,0,[\"selected\"]]]],{\"statements\":[[0,\"\\n  \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/iris-power-select-multiple/template.hbs"
    }
  });

  _exports.default = _default;
});