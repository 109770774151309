define("iris/helpers/string-concat", ["exports", "ember-string-fns/helpers/string-concat"], function (_exports, _stringConcat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _stringConcat.default;
    }
  });
  Object.defineProperty(_exports, "stringConcat", {
    enumerable: true,
    get: function () {
      return _stringConcat.stringConcat;
    }
  });
});