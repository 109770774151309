define("iris/helpers/string-from-code-point", ["exports", "ember-string-fns/helpers/string-from-code-point"], function (_exports, _stringFromCodePoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _stringFromCodePoint.default;
    }
  });
  Object.defineProperty(_exports, "stringFromCodePoint", {
    enumerable: true,
    get: function () {
      return _stringFromCodePoint.stringFromCodePoint;
    }
  });
});