define("iris/components/forms/ticket-user-assoc-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FjD3EW6G",
    "block": "{\"symbols\":[\"@small\"],\"statements\":[[7,\"button\",false],[12,\"class\",[28,\"concat\",[\"btn btn-default gap-4 items-center justify-center rounded-3xl \",[28,\"if\",[[23,1,[]],\"text-sm p-1\",\"text-xxl\"],null]],null]],[12,\"style\",[28,\"if\",[[23,1,[]],\"margin: -7px;\"],null]],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"assoc\"],null]]],[8],[0,\"\\n  \"],[1,[28,\"fa-icon\",[\"user\"],null],false],[0,\"\\n  \"],[1,[28,\"t\",[\"general.assign\"],null],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/forms/ticket-user-assoc-button/template.hbs"
    }
  });

  _exports.default = _default;
});