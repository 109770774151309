define("iris/components/modals/xwiki-path-tree/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    spacesTree: Ember.computed('data.spaces', function () {
      let result = [];

      if (!Array.isArray(this.data.spaces)) {
        return result;
      }

      for (const item of this.data.spaces) {
        let currentObj = result;

        for (const sub of item.split('.')) {
          let obj = currentObj.find(o => o.name === sub);

          if (!obj) {
            let selected = sub === this.space ? true : false;
            obj = {
              name: sub,
              space: item,
              children: [],
              expanded: false,
              selected
            };
            currentObj.push(obj);
          }

          currentObj = obj.children;
        }
      }

      return result;
    }),
    actions: {
      selectSpace() {
        this.close(this.space);
      }

    }
  });

  _exports.default = _default;
});