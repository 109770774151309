define("iris/models/aiops-services-contracts-x", ["exports", "iris/models/aiops-base", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _aiopsBase, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({});

  var _default = _aiopsBase.default.extend(Validations, {
    severancePay: (0, _attr.default)('number', {
      formWidget: 'mask'
    }),
    dailySeverancePay: (0, _attr.default)('number', {
      formWidget: 'mask'
    }),
    failingSeverancePay: (0, _attr.default)('number', {
      formWidget: 'mask'
    }),
    severancePayCount: (0, _attr.default)('number', {
      formWidget: 'mask'
    }),
    dailySeverancePayCount: (0, _attr.default)('number', {
      formWidget: 'mask'
    }),
    failingSeverancePayCount: (0, _attr.default)('number', {
      formWidget: 'mask'
    }),
    contract: (0, _relationships.belongsTo)('AiopsContract'),
    operationServiceLevel: (0, _relationships.belongsTo)('AiopsOperationServiceLevel')
  });

  _exports.default = _default;
});