define("iris/templates/components/sl-time-td", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HJs+yaeA",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[5,\"sl-time-badge\",[[13,1]],[[\"@deadline\",\"@violation\",\"@original\",\"@warning\",\"@critical\",\"@distanceFromDeadline\"],[[23,0,[\"deadline\"]],[23,0,[\"violation\"]],[23,0,[\"original\"]],[23,0,[\"warning\"]],[23,0,[\"critical\"]],[23,0,[\"diff\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/sl-time-td.hbs"
    }
  });

  _exports.default = _default;
});