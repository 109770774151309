define("iris/models/entity-value", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-copyable", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberDataCopyable, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    value: [(0, _emberCpValidations.validator)('entity-value')],
    relateds: [(0, _emberCpValidations.validator)('entity-related')]
  });

  var _default = _model.default.extend(_emberDataCopyable.default, Validations, {
    value: (0, _attr.default)('string'),
    orderIndex: (0, _attr.default)('number'),
    // Since change tracking is somewhat broken for has-many relationships, this
    // is a workaround for setting `isDirty` on the record. This is because a
    // record's own attribute always dirties the model, which will make ED perform
    // a save action on the related records. There are issues with boolean values,
    // so `Date` seems the safest way to create a dirty attribute. Note that this
    // property will be serialized and sent to the server.
    isDirtyTemp: (0, _attr.default)('date'),
    related: (0, _relationships.belongsTo)('entity', {
      inverse: null
    }),
    relateds: (0, _relationships.hasMany)('entity'),
    entity: (0, _relationships.belongsTo)('entity'),
    entityAttribute: (0, _relationships.belongsTo)('entityAttribute'),
    parent: (0, _relationships.belongsTo)('entityValue', {
      inverse: 'children'
    }),
    children: (0, _relationships.hasMany)('entityValue', {
      inverse: 'parent'
    }),
    copyableOptions: {
      copyByReference: ['related', 'relateds', 'entityAttribute']
    }
  });

  _exports.default = _default;
});