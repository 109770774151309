define("iris/controllers/settings/roles/actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sections: Ember.computed('model.actions', {
      get() {
        let res = Ember.A();

        let _this = this;

        this.get('model.actions').forEach(action => {
          action.get('parent').then(parent => {
            if (Ember.isNone(parent)) {
              res.pushObject(action);

              _this.set('sections', res);
            }
          });
        });
        return res;
      },

      set(key, value) {
        return value;
      }

    }),
    _sectionsChildren: Ember.computed.mapBy('sections', 'children'),
    flattend: Ember.computed('_sectionsChildren.@each.length', {
      get() {
        let res = Ember.A();

        let _this = this;

        this.get('sections').forEach(section => {
          if (section.get('children.length')) {
            section.get('children').then(children => {
              let sectionArray = Ember.A();
              children.forEach(child => {
                sectionArray.pushObject(child);
              });
              let obj = {
                name: section.get('name'),
                items: sectionArray
              };
              res.pushObject(obj);

              _this.set('flattend', res);
            });
          }
        });
        return res;
      },

      set(key, value) {
        return value;
      }

    }),
    actions: {
      save(action, c) {
        action.get('competencies').then(competencies => {
          competencies.setObjects(c);
          action.save();
        });
      }

    }
  });

  _exports.default = _default;
});