define("iris/helpers/string-html-safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringHtmlSafe = stringHtmlSafe;

  /**
   * Create html safe string.
   *
   * @public
   * @function stringHtmlSafe
   * @param {string} value The string value.
   * @returns {string} The safe string.
   */
  function stringHtmlSafe(_ref) {
    let [value = ''] = _ref;
    return Ember.String.htmlSafe(value);
  }

  var _default = Ember.Helper.helper(stringHtmlSafe);

  _exports.default = _default;
});