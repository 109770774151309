define("iris/components/store/view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JSCL6dCY",
    "block": "{\"symbols\":[\"@params\",\"@path\",\"&default\"],\"statements\":[[1,[28,\"did-insert\",[[28,\"perform\",[[23,0,[\"fetch\"]]],null],[23,2,[]],[23,1,[]]],null],false],[0,\"\\n\"],[14,3,[[28,\"hash\",null,[[\"result\",\"error\",\"isLoading\",\"trigger\"],[[23,0,[\"lastValue\"]],[23,0,[\"fetch\",\"last\",\"error\"]],[23,0,[\"fetch\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"fetch\"]]],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/store/view/template.hbs"
    }
  });

  _exports.default = _default;
});