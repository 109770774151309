define("iris/templates/components/countdown-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wid5R/Be",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"pad-start\",[[24,[\"countdown\",\"hours\"]],2],null],false],[0,\": \"],[1,[28,\"pad-start\",[[24,[\"countdown\",\"minutes\"]],2],null],false],[0,\" : \"],[1,[28,\"pad-start\",[[24,[\"countdown\",\"seconds\"]],2],null],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/countdown-object.hbs"
    }
  });

  _exports.default = _default;
});