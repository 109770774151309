define("iris/templates/organizations/departments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lJHs1X5K",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\"],[\"department\",\"organizations.departments.edit\",[28,\"array\",[\"name\",\"partner\"],null]]]],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/organizations/departments.hbs"
    }
  });

  _exports.default = _default;
});