define("iris/models/competency", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true
    })
  });

  var _default = _model.default.extend(Validations, {
    changeTracker: {
      trackHasMany: true,
      enableIsDirty: true,
      auto: true
    },
    name: (0, _attr.default)('string'),
    builtIn: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    userCompetencyAssocs: (0, _relationships.hasMany)('userCompetencyAssoc'),
    competencyUsers: Ember.computed.mapBy('userCompetencyAssocs', 'user'),
    actions: (0, _relationships.hasMany)('action'),
    accounts: (0, _relationships.hasMany)('account'),
    resolvedAccounts: Ember.computed('accounts', {
      get() {
        this.get('accounts').then(accounts => {
          this.set('resolvedAccounts', accounts);
        });
        return Ember.A();
      },

      set(key, value) {
        return value;
      }

    })
  });

  _exports.default = _default;
});