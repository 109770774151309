define("iris/abilities/resource-period", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ResourcePeriodAbility = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('sessionAccount.account'), _dec3 = Ember.computed.alias('sessionAccount.substituteForIds'), _dec4 = Ember.computed.alias('period.resourceGroup.planners'), _dec5 = Ember.computed('account'), _dec6 = Ember.computed('account'), (_class = class ResourcePeriodAbility extends _emberCan.Ability {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionAccount", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _initializerDefineProperty(this, "substituteForIds", _descriptor3, this);

      _initializerDefineProperty(this, "planners", _descriptor4, this);
    }

    get canEdit() {
      const userIds = Array.isArray(this.substituteForIds) ? [this.account.id].concat(this.substituteForIds) : [this.account.id];

      if (this.account.admin) {
        return true;
      }

      let authorizerId = this.period.belongsTo('resourceGroup').value().authorizer.get('id');

      if (userIds.includes(authorizerId)) {
        return true;
      }

      let plannerIds = this.planners.mapBy('id');

      for (const plannerId of plannerIds) {
        if (userIds.includes(plannerId)) {
          return true;
        }
      }

      return false;
    }

    get canEditEndDate() {
      const userIds = Array.isArray(this.substituteForIds) ? [this.account.id].concat(this.substituteForIds) : [this.account.id];

      if (this.account.admin) {
        return true;
      }

      let authorizerId = this.period.belongsTo('resourceGroup').value().authorizer.get('id');

      if (userIds.includes(authorizerId)) {
        return true;
      }

      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionAccount", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "substituteForIds", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "planners", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "canEdit", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "canEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canEditEndDate", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "canEditEndDate"), _class.prototype)), _class));
  _exports.default = ResourcePeriodAbility;
});