define("iris/components/time-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super();
    },

    days: Ember.computed('time', function () {
      return this.get('time') ? Math.floor(this.get('time') / 60 / 24) : 0;
    }),
    hours: Ember.computed('time', 'days', function () {
      return this.get('time') ? Math.floor((this.get('time') - this.get('days') * 24 * 60) / 60) : 0;
    }),
    minutes: Ember.computed('time', 'hours', 'days', function () {
      return this.get('time') ? this.get('time') - this.get('days') * 24 * 60 - this.get('hours') * 60 : 0;
    }),
    calculateTime: Ember.observer('days', 'hours', 'minutes', function () {
      this.set('hours', Math.min(this.get('hours'), 23));
      this.set('minutes', Math.min(this.get('minutes'), 59));
      const days = Number(this.get('days') * 24 * 60);
      const hours = Number(this.get('hours') * 60);
      const time = days + hours + Number(this.get('minutes'));
      this.set('time', time);
    })
  });

  _exports.default = _default;
});