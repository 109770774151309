define("iris/components/beta/filters/filter/component", ["exports", "iris/lib/filters", "iris/lib/filters/data", "iris/lib/filters/types"], function (_exports, _filters, _data, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    filter: null,
    // @
    onClick: null,
    // @ (filter) => {}
    onClickRemove: null,
    // @ (filter) => {}
    filterTypeSymbol: Ember.computed('filter.filterType', function () {
      return (0, _filters.symbolForFilterType)(this.filter.filterType);
    }),
    title: Ember.computed('filter', function () {
      let {
        attributeType,
        filterType,
        displayName,
        value
      } = this.filter;
      let filterTypeLabel = _data.FILTER_TYPE_LABELS[filterType];
      let val = null;

      if (![_types.FilterType.Null, _types.FilterType.NotNull].includes(filterType)) {
        val = attributeType === _types.AttributeType.BelongsTo ? value.name : value;
      }

      switch (filterType) {
        case _types.FilterType.Boolean:
          return `${displayName}: ${value ? 'Igen' : 'Nem'}`;

        case _types.FilterType.Null:
        case _types.FilterType.NotNull:
          return `${displayName} ${filterTypeLabel}`;

        case _types.FilterType.ContainsAny:
          return `${displayName} közül ${filterTypeLabel.toLowerCase()}: ${value.map(_ref => {
            let {
              name
            } = _ref;
            return name;
          }).join(', ')}`;

        default:
          return `${displayName} ${filterTypeLabel.toLowerCase()}: ${val}`;
      }
    })
  });

  _exports.default = _default;
});