define("iris/helpers/string-trim", ["exports", "ember-string-fns/helpers/string-trim"], function (_exports, _stringTrim) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _stringTrim.default;
    }
  });
  Object.defineProperty(_exports, "stringTrim", {
    enumerable: true,
    get: function () {
      return _stringTrim.stringTrim;
    }
  });
});