define("iris/validators/pattern-example", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    intl: Ember.inject.service(),
    dependentKeys: ['model.taxNumberPattern.pattern'],

    async validate(value, options, model, attribute) {
      const taxNumberPattern = await model.get('taxNumberPattern');
      const regex = new RegExp(taxNumberPattern.get('pattern'));
      return regex.test(value) || this.intl.t('general.regex.wrong', {
        regex: taxNumberPattern.get('pattern')
      });
    }

  });

  _exports.default = _default;
});