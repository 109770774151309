define("iris/templates/components/password-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iQ/pFqb5",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showPassword\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"bs-input\",null,[[\"disabled\",\"value\"],[[24,[\"disabled\"]],[24,[\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"bs-input\",null,[[\"disabled\",\"type\",\"value\"],[[24,[\"disabled\"]],\"password\",[24,[\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/password-input.hbs"
    }
  });

  _exports.default = _default;
});