define("iris/components/scheduled-tasks/component", ["exports", "iris/lib/filters", "iris/lib/filters/column", "iris/lib/filters/storage", "iris/lib/filters/types", "iris/lib/table"], function (_exports, _filters, _column, _storage, _types, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    modals: Ember.inject.service(),
    router: Ember.inject.service(),
    storage: (0, _storage.useStorage)(),

    accountQuery(searchTerm, options) {
      const {
        filter
      } = options.defaultQueryParams;
      filter.fields = {
        id: true,
        longName: true
      };
      return this.api.query('account', options.defaultQueryParams);
    },

    columns: (0, _column.columns)(function () {
      return [{
        name: 'id',
        displayName: this.intl.t('ticket.ticketNumber'),
        width: _column.ColumnWidth.SM,
        fixed: true,
        route: 'operation.scheduled-tasks.edit',
        routeParams: (0, _table.rowValues)('id')
      }, {
        name: 'subject',
        displayName: this.intl.t('ticket.subject'),
        width: _column.ColumnWidth.L,
        fixed: true,
        route: 'operation.scheduled-tasks.edit',
        routeParams: (0, _table.rowValues)('id')
      }, {
        name: 'fileId',
        displayName: this.intl.t('ticket.file'),
        valuePath: 'file.name',
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'project',
        optionsResourceAction: _types.OptionsResourceAction.Query,
        orderProperty: 'file.name',
        width: _column.ColumnWidth.L,
        route: 'operation.operational-file.edit',
        routeParams: (0, _table.rowValues)('file.id')
      }, {
        name: 'ticketTypeId',
        displayName: this.intl.t('ticket.type'),
        valuePath: 'type.name',
        type: _types.AttributeType.BelongsTo,
        orderProperty: 'type.name',
        width: _column.ColumnWidth.M
      }, {
        name: 'createUid',
        displayName: this.intl.t('cron-job-result.creator'),
        valuePath: 'createUser.longName',
        optionsResourceName: 'account',
        optionsNameKey: 'longName',
        optionsResourceAction: _types.OptionsResourceAction.Query,
        optionsQuery: this.accountQuery,
        type: _types.AttributeType.BelongsTo,
        orderProperty: 'createUser.longName',
        width: _column.ColumnWidth.M
      }, {
        name: 'resolverId',
        displayName: this.intl.t('models.resolver'),
        valuePath: 'resolver.longName',
        optionsResourceName: 'account',
        optionsNameKey: 'longName',
        optionsQuery: this.accountQuery,
        optionsResourceAction: _types.OptionsResourceAction.Query,
        type: _types.AttributeType.BelongsTo,
        orderProperty: 'resolver.longName',
        width: _column.ColumnWidth.M
      }, {
        name: 'solvingGroupId',
        displayName: this.intl.t('ticket.solvingGroup'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo
      }, {
        name: 'cron',
        displayName: this.intl.t('cron-job.cron'),
        width: _column.ColumnWidth.S
      }, {
        name: 'active',
        displayName: this.intl.t('models.isActive'),
        type: _types.AttributeType.Boolean,
        width: _column.ColumnWidth.S
      }];
    }),
    rowActions: (0, _table.rowActions)(function () {
      return [{
        name: this.intl.t('operation.scheduled-tasks.generate'),
        icon: 'file-text-o',

        async action(scheduledTask) {
          let shouldGenerate = await this.modals.open('beta/modals/confirmation', {
            title: this.intl.t('operation.scheduled-tasks.generate'),
            body: this.intl.t('operation.scheduled-tasks.generate-info')
          });

          if (shouldGenerate) {
            try {
              const ticketId = await this.api.memberAction('ticketTemplate', 'create-ticket', scheduledTask.id, {
                method: 'POST'
              });
              this.flashMessages.info(this.intl.t('operation.scheduled-tasks.generate-success-info', {
                id: ticketId
              }), {
                title: this.intl.t('operation.scheduled-tasks.generate-success'),
                icon: 'copy'
              });
            } catch (error) {
              this.flashMessages.danger(this.intl.t('operation.scheduled-tasks.generate-error'), {
                title: this.intl.t('general.error'),
                icon: 'warning',
                sticky: true
              });
            }
          }
        }

      }];
    }),
    fetchParams: Ember.computed('columns', 'storage.params', function () {
      let {
        filter
      } = (0, _filters.makeQueryObject)(this.columns, this.storage.params);
      return {
        filter: { ...filter,
          include: [{
            relation: 'file',
            scope: {
              fields: ['name']
            }
          }, {
            relation: 'type',
            scope: {
              fields: ['name']
            }
          }, {
            relation: 'createUser',
            scope: {
              fields: ['longName']
            }
          }, {
            relation: 'resolver',
            scope: {
              fields: ['longName']
            }
          }, {
            relation: 'solvingGroup',
            scope: {
              fields: ['name']
            }
          }]
        }
      };
    })
  });

  _exports.default = _default;
});