define("iris/models/aiops-ticket", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "iris/lib/aiops/mapper", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _mapper, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    subject: [(0, _emberCpValidations.validator)('presence', true)],
    ticketStatus: [(0, _emberCpValidations.validator)('presence', true)],
    processStatus: [(0, _emberCpValidations.validator)('presence', true)],
    customer: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _model.default.extend(Validations, {
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    dtMode: (0, _relationships.belongsTo)('AiopsDataTrasferMode'),
    subject: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    errorReason: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    department: (0, _attr.default)('string'),
    ticketTypeCp: (0, _attr.default)('number'),
    serviceLevelCp: (0, _attr.default)('number'),
    operationServiceLevelCp: (0, _attr.default)('number'),
    priority: (0, _attr.default)('number'),
    plannedPriority: (0, _attr.default)('number'),
    priorityCp: (0, _attr.default)('number'),
    userGradeCp: (0, _attr.default)('number'),
    publicSpentTime: (0, _attr.default)('number'),
    plannedPublicSpentTime: (0, _attr.default)('number'),
    publicSpentTimeCp: (0, _attr.default)('number'),
    resolverCp: (0, _attr.default)('number'),
    reactionTime: (0, _attr.default)('number'),
    startTime: (0, _attr.default)('number'),
    solvingTime: (0, _attr.default)('number'),
    canChangeResolver: (0, _attr.default)('boolean'),
    canChangeData: (0, _attr.default)('boolean'),
    isClient: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    ticketReactionTime: (0, _attr.default)('date'),
    ticketStartTime: (0, _attr.default)('date'),
    ticketSolvingTime: (0, _attr.default)('date'),
    ticketType: (0, _relationships.belongsTo)('aiopsTicketType', {
      inverse: null
    }),
    plannedTicketType: (0, _relationships.belongsTo)('aiopsTicketType', {
      inverse: null
    }),
    serviceLevel: (0, _relationships.belongsTo)('aiopsServiceLevel'),
    plannedServiceLevel: (0, _relationships.belongsTo)('aiopsServiceLevel'),
    operationServiceLevel: (0, _relationships.belongsTo)('aiopsOperationServiceLevel'),
    plannedOperationServiceLevel: (0, _relationships.belongsTo)('aiopsOperationServiceLevel'),
    userGrade: (0, _relationships.belongsTo)('aiopsUserGrade'),
    plannedUserGrade: (0, _relationships.belongsTo)('aiopsUserGrade'),
    resolver: (0, _relationships.belongsTo)('aiopsSolver'),
    plannedResolver: (0, _relationships.belongsTo)('aiopsSolver'),
    ticketStatus: (0, _relationships.belongsTo)('aiopsTicketState'),
    processStatus: (0, _relationships.belongsTo)('aiopsProcessState'),
    customer: (0, _relationships.belongsTo)('aiopsCustomer'),

    async ready() {
      // fires when the model is loaded from server or created locally
      if (!!this.get('processStatus')) {
        // don't set to defaults if processStatus is not null
        return;
      }

      const processStatus = this.store.findRecord('aiopsProcessSate', _mapper.TicketProcessStates.Draft);
      this.set('processStatus', processStatus);
    }

  });

  _exports.default = _default;
});