define("iris/models/contract", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "moment", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _moment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 100
    })],
    publicId: (0, _emberCpValidations.validator)('length', {
      max: 50
    }),
    refOfferId: (0, _emberCpValidations.validator)('length', {
      max: 50
    }),
    partner: [(0, _emberCpValidations.validator)('belongs-to'), (0, _emberCpValidations.validator)('presence', true)],
    clientPartner: [(0, _emberCpValidations.validator)('belongs-to'), (0, _emberCpValidations.validator)('presence', true)]
  });
  let now = new Date();
  let end = (0, _moment.default)().add(2, 'w').toDate();

  var _default = _model.default.extend(Validations, {
    modelName: 'Contract',
    name: (0, _attr.default)('string'),
    dateStart: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    dateEnd: (0, _attr.default)('date', {
      defaultValue: function () {
        return end;
      }
    }),
    billingCycleDay: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    billingCycleMonth: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    closed: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    publicId: (0, _attr.default)('string'),
    refOfferId: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    approximatelyValue: (0, _attr.default)('number'),
    plannedValue: (0, _attr.default)('number'),
    valuePeriod: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    dateEndType: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    periodOfNotice: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    continuous: (0, _attr.default)('boolean'),
    tandm: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    onlyTandm: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    partnerId: (0, _attr.default)('string'),
    valuePayment: (0, _relationships.belongsTo)('currency'),
    partner: (0, _relationships.belongsTo)('partner'),
    clientPartner: (0, _relationships.belongsTo)('partner'),
    accountManagerUser: (0, _relationships.belongsTo)('account'),
    operationalManagerUser: (0, _relationships.belongsTo)('account'),
    technicalAccountManagerUser: (0, _relationships.belongsTo)('account'),
    file: (0, _relationships.belongsTo)('project'),
    contractUserGradeCosts: (0, _relationships.hasMany)('contractUserGradeCost'),
    contractActionCompetencyAssocs: (0, _relationships.hasMany)('contractActionCompetencyAssoc'),
    diffInMonth: Ember.computed('dateStart', 'dateEnd', 'continuous', 'periodOfNotice', function () {
      let start = this.get('dateStart');
      let end = this.get('dateEnd');

      if (this.get('continuous')) {
        end = (0, _moment.default)().add(this.get('periodOfNotice'), 'd');
      }

      if (!start || !end) {
        return false;
      }

      return (0, _moment.default)(end).diff((0, _moment.default)(start), 'month');
    }),
    volumen: Ember.computed('valuePeriod', 'plannedValue', 'diffInMonth', function () {
      let volumen = 0;
      let diffInMonth = this.get('diffInMonth');

      if (!diffInMonth) {
        return volumen;
      }

      let valuePeriod = this.get('valuePeriod');
      let periodInMonth = valuePeriod === 0 ? 1 : valuePeriod === 1 ? 3 : 12;
      volumen = (diffInMonth + 1) / periodInMonth * this.get('plannedValue');
      return volumen.toFixed(2);
    }),
    toDelete: {
      GradeCosts: Ember.A()
    }
  });

  _exports.default = _default;
});