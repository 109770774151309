define("iris/controllers/operation/portal-dashboard/index", ["exports", "iris/routes/operation/portal-dashboard", "iris/services/query-params"], function (_exports, _portalDashboard, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const queryParams = (0, _queryParams.makeFilterQueryParams)({}, _portalDashboard.tabs[0].queryParams);
  const queryParamsMixin = (0, _queryParams.makeControllerMixin)(queryParams);

  var _default = Ember.Controller.extend(queryParamsMixin);

  _exports.default = _default;
});