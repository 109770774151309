define("iris/models/business-service", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "iris/mixins/anonymize"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _anonymize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 300
    })]
  });

  var _default = _model.default.extend(Validations, _anonymize.default, {
    name: (0, _attr.default)('string'),
    partner: (0, _relationships.belongsTo)('partner'),
    entityTypeCategory: (0, _relationships.belongsTo)('entityTypeCategory'),
    entityType: (0, _relationships.belongsTo)('entityType'),
    siteZone: (0, _relationships.belongsTo)('siteZone'),
    operationServiceLevel: (0, _relationships.belongsTo)('operationServiceLevel')
  });

  _exports.default = _default;
});