define("iris/components/beta/grid/bulk-actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FRhfTK8N",
    "block": "{\"symbols\":[\"configClass\",\"ba\",\"@bulkActions\"],\"statements\":[[4,\"each\",[[23,3,[\"configClasses\"]]],null,{\"statements\":[[0,\"  \"],[5,\"beta/grid/bulk-actions/action\",[],[[\"@selection\",\"@configClass\"],[[23,3,[\"selection\"]],[23,1,[]]]],{\"statements\":[[0,\"\\n\"],[0,\"    \"],[7,\"span\",true],[10,\"class\",\"block w-14 h-14\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"disabled\",[28,\"or\",[[23,3,[\"isDisabled\"]],[23,2,[\"disabled\"]]],null]],[12,\"class\",[29,[\"\\n          w-14 h-14 p-0 text-3xl rounded-full flex items-center justify-center\\n          dark:hover:bg-gray-800\\n\\n          \",[28,\"if\",[[28,\"not\",[[23,2,[\"style\"]]],null],\"hover:bg-gray-300\"],null],\"\\n\\n          \",[28,\"if\",[[28,\"eq\",[[23,2,[\"style\"]],\"danger\"],null],\"hover:text-scarlet-500 dark:hover:text-scarlet-300\\n            hover:bg-scarlet-200 dark:hover:bg-scarlet-900\"],null],\"\\n\\n          \",[28,\"if\",[[23,2,[\"disabled\"]],\"opacity-50\"],null],\"\\n        \"]]],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[28,\"perform\",[[23,3,[\"actionTask\"]],[23,2,[\"action\"]],[23,3,[\"selection\"]]],null]]],[8],[0,\"\\n        \"],[1,[28,\"fa-icon\",[[23,2,[\"icon\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[5,\"ember-tooltip\",[],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"block px-4 py-2\"],[8],[0,\"\\n          \"],[1,[28,\"if\",[[23,2,[\"disabled\"]],[28,\"concat\",[[23,2,[\"name\"]],\" - \",[23,2,[\"disabled\"]]],null],[23,2,[\"name\"]]],null],false],[0,\"\\n        \"],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/beta/grid/bulk-actions/template.hbs"
    }
  });

  _exports.default = _default;
});