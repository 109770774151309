define("iris/templates/cmdb/entity-types/entities/new-entity/software", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+N9LbQHO",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"tables/entity-software\",null,[[\"model\",\"mode\"],[[24,[\"model\"]],\"new-entity\"]]],false],[0,\"\\n\"],[1,[22,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/entity-types/entities/new-entity/software.hbs"
    }
  });

  _exports.default = _default;
});