define("iris/controllers/settings/operations/ticket-states", ["exports", "iris/utils/properties"], function (_exports, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    stateChangeAllows: null,
    // ALl stateChangeAllows that's origin state is in the list of ticket states
    destinationStates: null,
    // All states that items can transition to
    ticketStatesDidChange: (0, _properties.asyncObserver)('model.@each.id', function () {
      if (!this.get('model')) {
        return;
      }

      this.get('store').query('ticketStateChangeAllow', {
        filter: {
          where: {
            and: [{
              isAllowed: true
            }, {
              originStateId: {
                inq: this.get('model').mapBy('id')
              }
            }]
          }
        }
      }).then(stateChangeAllowsByOrigin => {
        this.get('store').query('ticketState', {
          filter: {
            where: {
              and: [{
                sd: 1
              }, {
                or: [{
                  isSystemType: false
                }, {
                  isSystemType: null
                }]
              }, {
                id: {
                  inq: stateChangeAllowsByOrigin.mapBy('destinationStateId')
                }
              }]
            }
          }
        }).then(destinationStates => {
          if (!this.get('isDestroyed') && !this.get('isDestroying')) {
            this.set('stateChangeAllows', stateChangeAllowsByOrigin);
            this.set('destinationStates', destinationStates);
          }
        });
      });
    }).on('init')
  });

  _exports.default = _default;
});