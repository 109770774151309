define("iris/templates/components/modals/file-upload-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RGh48piL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-tether\",null,[[\"to\",\"target\",\"targetModifier\",\"attachment\",\"tetherClass\",\"overlayClass\"],[\"modal\",\"document.body\",\"visible\",\"middle center\",\"modal-dialog modal-md\",\"modal-backdrop\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"modals.file-upload\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n      \"],[1,[28,\"attachments-control-component\",null,[[\"model\",\"multiple\",\"only\"],[[24,[\"model\"]],true,[24,[\"only\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n      \"],[1,[28,\"iris-button\",null,[[\"type\",\"action\",\"text\"],[\"primary\",[28,\"action\",[[23,0,[]],\"savePendingFiles\"],null],[28,\"t\",[\"buttons.save\"],null]]]],false],[0,\"\\n      \"],[1,[28,\"iris-button\",null,[[\"type\",\"action\",\"text\"],[\"default\",[24,[\"cancel\"]],[28,\"t\",[\"general.back\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/modals/file-upload-modal.hbs"
    }
  });

  _exports.default = _default;
});