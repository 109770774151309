define("iris/services/entity-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    sessionAccount: Ember.inject.service(),
    model: null,
    address: null,
    building: null,
    floor: null,
    location: null,

    async setModel(model) {
      this.reset();
      this.set('model', model);
      const location = await model.get('location');

      if (location) {
        let floor = await location.get('floor');

        if (floor) {
          this.set('floor', floor);
          let building = await floor.get('building');

          if (building) {
            this.set('building', building);
            let address = await building.get('address');

            if (address) {
              this.set('address', address);
            }
          }
        }
      }
    },

    setPartner(partner) {
      this.model.set('contact', null);
      this.model.set('partner', partner); // Reset address fields that depend on the partner

      this.set('address', null);
      this.set('building', null);
      this.set('floor', null);
      this.set('location', null);
      let setDefault = false;
      partner.get('siteZones').then(siteZones => {
        siteZones.forEach(siteZone => {
          if (siteZone.get('default')) {
            this.model.set('siteZone', siteZone);
            setDefault = true;
          }
        });

        if (!setDefault) {
          let region = this.sessionAccount.get('region');
          region.get('ownerPartner').then(ownerPartner => {
            ownerPartner.get('siteZones').then(siteZones => {
              siteZones.forEach(siteZone => {
                if (siteZone.get('default')) {
                  this.model.set('siteZone', siteZone);
                }
              });
            });
          });
        }
      });
    },

    setAddress(address) {
      this.set('building', null);
      this.set('floor', null);
      this.model.set('location', null);
      this.set('address', address);
    },

    setBuilding(building) {
      this.set('floor', null);
      this.model.set('location', null);
      this.set('building', building);
    },

    setFloor(floor) {
      this.model.set('location', null);
      this.set('floor', floor);
    },

    reset() {
      this.set('model', null);
      this.set('building', null);
      this.set('floor', null);
      this.set('address', null);
    }

  });

  _exports.default = _default;
});