define("iris/templates/components/forms/ticket-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "94VZLf9L",
    "block": "{\"symbols\":[\"tab\"],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"id\"]]],null,{\"statements\":[[0,\"  \"],[5,\"bs-tab\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"pane\"]],[],[[\"@title\"],[\"Alap adatok\"]],{\"statements\":[[0,\"\\n      \"],[5,\"forms/ticket-overview\",[],[[\"@model\"],[[23,0,[\"model\"]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,1,[\"pane\"]],[],[[\"@title\"],[\"Kiosztás\"]],{\"statements\":[[0,\"\\n      \"],[5,\"forms/ticket-user-assoc\",[],[[\"@model\"],[[23,0,[\"model\"]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,1,[\"pane\"]],[],[[\"@title\"],[\"Mellékletek\"]],{\"statements\":[[0,\"\\n      \"],[5,\"attachment-list\",[[12,\"class\",\"mb-4\"]],[[\"@documents\"],[[23,0,[\"model\",\"documents\"]]]]],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn-default w-full flex gap-4 items-center justify-center\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"uploadFile\"],null]]],[8],[0,\"\\n        \"],[1,[28,\"fa-icon\",[\"paperclip\"],null],false],[0,\"\\n        \"],[1,[28,\"t\",[\"tables.file-upload\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"forms/ticket-overview\",[],[[\"@model\"],[[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/ticket-form.hbs"
    }
  });

  _exports.default = _default;
});