define("iris/components/full-calendar", ["exports", "ember-fullcalendar/components/full-calendar"], function (_exports, _fullCalendar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fullCalendar.default.extend({
    didInsertElement() {
      this._super(...arguments);

      this.$().fullCalendar('option', 'buttonText', {
        today: 'ma'
      });
    }

  });

  _exports.default = _default;
});