define("iris/services/socket", ["exports", "iris/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let options = {
    transports: ['websocket']
  };

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    socketIOService: Ember.inject.service('socket-io'),
    instance: null,

    authenticate() {
      const {
        id,
        userId
      } = this.get('session.data.authenticated');
      if (!userId) return false;
      const socket = this.get('socketIOService').socketFor(_environment.default.APP.host, options);
      socket.on('connect', function () {
        socket.emit('authentication', {
          id: id,
          userId: userId
        });
      });
      this.set('instance', socket);
    }

  });

  _exports.default = _default;
});