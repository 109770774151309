define("iris/templates/components/bs-table-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M/jB5OnR",
    "block": "{\"symbols\":[],\"statements\":[[1,[24,[\"column\",\"name\"]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"direction\"]]],null,{\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"order \",[22,\"direction\"]]]],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"caret\"],[8],[0,\" \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/bs-table-header.hbs"
    }
  });

  _exports.default = _default;
});