define("iris/mixins/custom-attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    customValues: Ember.computed({
      get() {
        let store = this.get('store');
        let modelId = this.get('id');
        if (!modelId) modelId = '000';
        let customValues = Ember.A();
        let modelName = this.get('constructor.modelName');
        store.query('customAttribute', {
          filter: {
            where: {
              model: modelName
            }
          }
        }).then(cols => {
          store.query('customValue', {
            filter: {
              where: {
                model: modelName,
                modelId: modelId
              }
            }
          }).then(vals => {
            cols.forEach(col => {
              let notFind = true;
              vals.forEach(val => {
                if (val.get('customAttribute.id') === col.id) {
                  notFind = false;
                  customValues.pushObject(val);
                }
              });

              if (notFind) {
                let record = store.createRecord('customValue', {
                  value: null,
                  model: modelName,
                  modelId: this.get('id'),
                  customAttribute: col
                });
                customValues.pushObject(record);
              }
            });
            this.set('customValues', customValues);
          });
        });
      },

      set(key, value) {
        return value;
      }

    })
  });

  _exports.default = _default;
});