define("iris/models/service-period-interval", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    start: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/,
      descriptionKey: 'service-period-interval.wrong-format'
    })],
    end: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/,
      descriptionKey: 'service-period-interval.wrong-format'
    })]
  });

  var _default = _model.default.extend(Validations, {
    start: (0, _attr.default)('string'),
    end: (0, _attr.default)('string'),
    servicePeriod: (0, _relationships.belongsTo)('servicePeriod')
  });

  _exports.default = _default;
});