define("iris/models/comment", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    userId: (0, _attr.default)('string'),
    value: (0, _attr.default)('string'),
    sourceType: (0, _attr.default)('string'),
    sourceId: (0, _attr.default)('string'),
    createDate: (0, _attr.default)('date'),
    private: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    user: (0, _relationships.belongsTo)('account')
  });

  _exports.default = _default;
});