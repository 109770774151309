define("iris/templates/settings/acl/acl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "thf5aSCS",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\"],[\"builtin-acl\",\"settings.acl.acl.edit\",[28,\"array\",[\"model\",\"property\",\"accessType2\",\"permission\"],null]]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/acl/acl.hbs"
    }
  });

  _exports.default = _default;
});