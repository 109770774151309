define("iris/models/aiops-customer", ["exports", "iris/models/aiops-base", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _aiopsBase, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true)],
    address: [(0, _emberCpValidations.validator)('presence', true)],
    phone: [(0, _emberCpValidations.validator)('presence', true)],
    email: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _aiopsBase.default.extend(Validations, {
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    name: (0, _attr.default)('string'),
    address: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    contact: (0, _attr.default)('string'),
    tax: (0, _attr.default)('string'),
    contracts: (0, _relationships.hasMany)('AiopsContract'),
    parameters: (0, _relationships.hasMany)('AiopsPmTypeCustomerAssocComputed'),
    solvers: (0, _relationships.hasMany)('AiopsSolver')
  });

  _exports.default = _default;
});