define("iris/helpers/expected-margin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.expectedMargin = expectedMargin;

  function expectedMargin(params
  /*, hash*/
  ) {
    let [fed1, overHead] = params;
    fed1 = Number(fed1);
    overHead = Number(overHead);
    let em = fed1 - fed1 * (1 / (1 + overHead)) + 0;
    return Number(em).toFixed(2);
  }

  var _default = Ember.Helper.helper(expectedMargin);

  _exports.default = _default;
});