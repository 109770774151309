define("iris/routes/finances/invoices", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    exportOptions: {
      beforeExport: function (table) {
        let nrColumns = [7, 8, 11];
        table.each(function () {
          $(this).find('tr').each(function () {
            $(this).find('td').each(function (i) {
              if (nrColumns.includes(i)) {
                let text = $(this).text();
                text = text.replace(/\s/g, '').replace(',', '.');
                $(this).text(text);
              }
            });
          });
        });
        return table;
      }
    }
  });

  _exports.default = _default;
});