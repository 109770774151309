define("iris/routes/beta/partners", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PartnersRoute extends Ember.Route {
    model() {}

  }

  _exports.default = PartnersRoute;
});