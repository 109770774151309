define("iris/components/ember-tooltip", ["exports", "ember-tooltips/components/ember-tooltip", "iris/config/environment"], function (_exports, _emberTooltip, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const isTesting = _environment.default.environment === 'test';

  var _default = _emberTooltip.default.extend({
    // The app was constructed in a way that it relied popovers and tooltips to be
    // rendered on the body element. The original behavior (with the default value
    // being `false`) is to render the alongside the trigger element. However,
    // when overflow gets into the mix, the popover may be cropped or completely
    // invisible.
    popperContainer: isTesting ? '#ember-testing' : 'body'
  });

  _exports.default = _default;
});