define("iris/serializers/account", ["exports", "iris/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      shouldSerializePassword: {
        serialize: false
      },
      passwordConfirmation: {
        serialize: false
      }
    },

    serializeAttribute(snapshot, json, key, attributes) {
      if (!snapshot.attr('shouldSerializePassword') && attributes.name === 'password') {
        return;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});