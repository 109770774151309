define("iris/models/file-service-level-assoc", ["exports", "ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    project: (0, _relationships.belongsTo)('project'),
    serviceLevel: (0, _relationships.belongsTo)('serviceLevel')
  });

  _exports.default = _default;
});