define("iris/components/toggle-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['switch switch-sm'],
    value: true,
    tooltip: true,

    click() {
      this.toggleProperty('value'), this.sendAction('action');
    }

  });

  _exports.default = _default;
});