define("iris/components/cmdb-visualisation2/grid/component", ["exports", "deepmerge", "ember-concurrency", "iris/classes/filter/filter", "iris/config/environment", "iris/lib/filters", "iris/lib/filters/api", "iris/lib/filters/column", "iris/lib/filters/filter", "iris/lib/filters/storage", "iris/lib/filters/types", "iris/lib/modals", "iris/lib/table"], function (_exports, _deepmerge, _emberConcurrency, _filter, _environment, _filters, _api, _column, _filter2, _storage, _types, _modals, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const ATTRIBUTE_TYPE_CONVERSION_MAP = {
    [_filter.AttributeType.String]: _types.AttributeType.String,
    [_filter.AttributeType.Number]: _types.AttributeType.Number,
    [_filter.AttributeType.Checkbox]: _types.AttributeType.Boolean,
    [_filter.AttributeType.Date]: _types.AttributeType.Date,
    [_filter.AttributeType.Array]: _types.AttributeType.String,
    [_filter.AttributeType.BelongsTo]: _types.AttributeType.String,
    [_filter.AttributeType.HasMany]: _types.AttributeType.String
  };
  let BetaCMDBComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = (0, _storage.useStorage)('memory'), _dec8 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec9 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec10 = Ember.computed.alias('customAttributesTask.last.value'), _dec11 = Ember.computed.alias('entityAttributesTask.last.value'), _dec12 = (0, _column.columns)('customAttributes', 'entityAttributes', 'entityTypeId', function () {
    var _this$customAttribute;

    let categoryId;

    if (this.entityTypeId) {
      const entityType = this.store.peekRecord('entityType', this.entityTypeId);
      categoryId = entityType.belongsTo('entityTypeCategory').id();
    }

    let cols = [{
      name: 'categoryId',
      displayName: this.intl.t('cmdb-visualisation.category'),
      type: _types.AttributeType.BelongsTo,
      visibility: _types.AttributeVisibility.Filter,
      optionsResourceName: 'entityTypeCategory'
    }, {
      name: 'relation',
      displayName: this.intl.t('cmdb-visualisation.relation'),
      type: _types.AttributeType.BelongsTo,
      filterTypes: [_types.FilterType.Equal],
      visibility: _types.AttributeVisibility.Filter,
      inputComponentName: 'beta/cmdb/entity-lazy-select'
    }, {
      name: 'relation-master',
      displayName: this.intl.t('cmdb-visualisation.relation-master'),
      type: _types.AttributeType.BelongsTo,
      filterTypes: [_types.FilterType.Equal],
      visibility: _types.AttributeVisibility.Filter,
      inputComponentName: 'beta/cmdb/entity-lazy-select'
    }, {
      name: 'relation-slave',
      displayName: this.intl.t('cmdb-visualisation.relation-slave'),
      type: _types.AttributeType.BelongsTo,
      filterTypes: [_types.FilterType.Equal],
      visibility: _types.AttributeVisibility.Filter,
      inputComponentName: 'beta/cmdb/entity-lazy-select'
    }, {
      name: 'entityTypeCategory',
      displayName: this.intl.t('cmdb-visualisation.categoryName'),
      type: _types.AttributeType.String,
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'monitoring',
      displayName: this.intl.t('cmdb-visualisation.monitoring'),
      type: _types.AttributeType.Boolean,
      visibility: _types.AttributeVisibility.Filter,

      formatLoopBackValue(value, filter, defaultFormatter) {
        if (filter.filterType === _types.FilterType.Boolean) {
          return value ? 1 : 0;
        }

        return defaultFormatter(value);
      }

    }, {
      name: 'monitoring',
      displayName: '',
      fixed: true,
      resizable: false,
      sortable: false,
      visibility: _types.AttributeVisibility.Table,
      width: _column.ColumnWidth.XS,
      exportable: false
    }, {
      name: 'name',
      displayName: this.intl.t('cmdb-visualisation.name'),
      fixed: true,
      width: _column.ColumnWidth.L,
      route: 'cmdb.entity-types.entities.entity.attributes',
      routeParams: (0, _table.rowValues)('entityTypeId', 'id')
    }, {
      name: 'entityType',
      displayName: this.intl.t('cmdb-visualisation.entityType'),
      visibility: _types.AttributeVisibility.Table
    }, {
      name: 'entityType',
      displayName: this.intl.t('cmdb-visualisation.entityTypeName'),
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'entityTypeId',
      displayName: this.intl.t('cmdb-visualisation.entityType'),
      type: _types.AttributeType.BelongsTo,
      visibility: _types.AttributeVisibility.Filter,
      belongsTo: [{
        categoryId: 'entityTypeCategoryId'
      }],
      createOptions: async (records, nameKey, defaultMapper) => {
        let etcs = await this.api.findAll('entityTypeCategory');
        let groups = etcs.map(etc => {
          return {
            groupName: etc.name,
            options: defaultMapper(records.filter(et => et.belongsTo('entityTypeCategory').id() === etc.id))
          };
        });
        let ungrouped = {
          groupName: this.intl.t('cmdb-visualisation.others'),
          options: defaultMapper(records.filter(et => !et.belongsTo('entityTypeCategory').id()))
        };
        return [...groups, ungrouped];
      }
    }, {
      name: 'partner',
      displayName: this.intl.t('cmdb-visualisation.partnerName')
    }, {
      name: 'partnerId',
      displayName: this.intl.t('cmdb-visualisation.partner'),
      type: _types.AttributeType.BelongsTo,
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'location',
      displayName: this.intl.t('cmdb-visualisation.location'),
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'licenced',
      displayName: this.intl.t('cmdb-visualisation.license'),
      type: _types.AttributeType.Boolean,
      visibility: _types.AttributeVisibility.Filter
    }];

    if (!categoryId || categoryId.slice(-9) === '000000001') {
      //Hardware
      cols = [...cols, {
        name: 'serialNumber',
        displayName: this.intl.t('cmdb-visualisation.serialNumber'),
        fuzzySearch: false
      }, {
        name: 'locationId',
        displayName: this.intl.t('cmdb-visualisation.location'),
        type: _types.AttributeType.BelongsTo,
        belongsTo: ['workSiteId'],
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'manufacturer',
        displayName: this.intl.t('cmdb-visualisation.manufacturer'),
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'productType',
        displayName: this.intl.t('cmdb-visualisation.productType'),
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'status',
        displayName: this.intl.t('cmdb-visualisation.status'),
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'entityStatus',

        // The request sent as `{ status: { eq: 'Entity Status Name' }}` where
        // the value is the name of the `entityStatus` not the ID. To achieve
        // this, we set the option IDs to the names of the entity statuses
        // queried for the select input.
        createOptions(records, nameKey, defaultMapper) {
          return records.map(_ref => {
            let {
              name
            } = _ref;
            return {
              id: name,
              name
            };
          });
        }

      }];
    } else if (categoryId && categoryId.slice(-9) === '000000002') {
      //Software
      cols = [...cols, {
        name: 'version',
        displayName: this.intl.t('cmdb-visualisation.version')
      }, {
        name: 'productKey',
        displayName: this.intl.t('cmdb-visualisation.productKey')
      }];
    } else if (categoryId && categoryId.slice(-9) === '000000006') {
      //Licence
      cols = [...cols, {
        name: 'accountable',
        displayName: this.intl.t('cmdb-visualisation.accountable')
      }, {
        name: 'expirationDate',
        displayName: this.intl.t('cmdb-visualisation.expirationDate')
      }, {
        name: 'qty',
        displayName: this.intl.t('cmdb-visualisation.quantity')
      }, {
        name: 'availableQty',
        displayName: this.intl.t('cmdb-visualisation.available')
      }];
    }

    cols.concat(((_this$customAttribute = this.customAttributes) !== null && _this$customAttribute !== void 0 ? _this$customAttribute : []).map(_ref2 => {
      let {
        name,
        slug
      } = _ref2;
      return {
        name: slug,
        displayName: `${name} (${slug})`,
        visibility: _types.AttributeVisibility.Filter,
        fuzzySearch: false
      };
    }));

    if (this.entityAttributes) {
      for (const attribute of this.entityAttributes.toArray()) {
        cols.push({
          name: attribute.property,
          displayName: `${attribute.name} (${attribute.property})`,
          visibility: _types.AttributeVisibility.Table,
          fuzzySearch: false
        });
      }
    }

    return cols;
  }), _dec13 = (0, _table.rowActions)(function () {
    return [];
  }), _dec14 = Ember.computed('entityTypeId'), _dec15 = Ember.computed('columns', 'defaultFilters', 'defaultWhere', 'storage.params'), _dec16 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = class BetaCMDBComponent extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "openNewItem", false);

      _defineProperty(this, "newItemModal", false);

      _defineProperty(this, "selectedEntityType", null);

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "modals", _descriptor2, this);

      _initializerDefineProperty(this, "ajax", _descriptor3, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "intl", _descriptor6, this);

      _initializerDefineProperty(this, "storage", _descriptor7, this);

      _initializerDefineProperty(this, "customAttributes", _descriptor8, this);

      _initializerDefineProperty(this, "entityAttributes", _descriptor9, this);

      _initializerDefineProperty(this, "columns", _descriptor10, this);

      _initializerDefineProperty(this, "rowActions", _descriptor11, this);

      _defineProperty(this, "predefinedViews", []);
    }

    // eslint-disable-next-line ember/classic-decorator-hooks
    init() {
      super.init(...arguments);
      this.customAttributesTask.perform();
      this.entityAttributesTask.perform();
    }

    *customAttributesTask() {
      return yield this.api.query('customAttribute', {
        filter: {
          where: {
            model: 'entity'
          }
        }
      });
    }

    *entityAttributesTask() {
      if (this.entityTypeId) {
        return yield this.api.query('entityAttribute', {
          filter: {
            where: {
              entityTypeId: this.entityTypeId,
              listable: 1
            }
          }
        });
      } else {
        return null;
      }
    }

    get defaultFilters() {
      return filters => {
        let hasFilterForEntityType = filters.find(filter => ['entityTypeId', 'entityType'].includes(filter.attributeName));

        if (hasFilterForEntityType) {
          // When `entityType*` already exists, we don't want to filter for 'All'
          return [];
        }

        let column = (0, _filters.makeColumn)({
          name: 'entityTypeId'
        });
        let allEntityAttributesFilter = (0, _filters.makeFilterFromColumn)(column, {
          attributeName: 'entityTypeId',
          filterType: _types.FilterType.Equal,
          value: this.entityTypeId ? this.entityTypeId : 'All'
        });
        return [allEntityAttributesFilter];
      };
    }

    get fetchParams() {
      let {
        filter
      } = (0, _filters.makeQueryObject)(this.columns, this.storage.params, {
        defaultFilters: this.defaultFilters,
        serializeCustomFilters: (customAttributeFilters, whereCondition) => {
          if (!(customAttributeFilters !== null && customAttributeFilters !== void 0 && customAttributeFilters.length)) {
            return whereCondition;
          }

          return (0, _deepmerge.default)(whereCondition, {
            and: customAttributeFilters.map(_api.makeLoopBackConditionFromFilter).map(f => ({
              ea: f
            }))
          });
        }
      });

      if (this.defaultWhere) {
        var _filter$where, _filter$where$and;

        (_filter$where = filter.where) === null || _filter$where === void 0 ? void 0 : (_filter$where$and = _filter$where.and) === null || _filter$where$and === void 0 ? void 0 : _filter$where$and.push(this.defaultWhere);
      }

      return {
        filter: { ...filter
        }
      };
    }

    *queryCustomFiltersTask(term) {
      let result = yield this.api.queryView('entityAttributes/getDistinctEntityAttributes', {
        filter: {
          q: term
        }
      });
      return result.result.data.map(item => {
        let attributeType = ATTRIBUTE_TYPE_CONVERSION_MAP[item.type];
        return (0, _filter2.makeCustomAttributeFilter)({
          displayName: `${item.name} (${item.property})`,
          attributeType,
          attributeName: item.property
        });
      });
    }

    async manageEntityProcedures(entityIds) {
      try {
        let changedEntityIds;

        if (_environment.default.environment === 'development') {
          // Since we don't have Icinga in development, just pretend all entities
          // have changed.
          changedEntityIds = entityIds;
        } else {
          let getProceduresRequests = entityIds.map(entityId => {
            return this.api.collectionAction('entity', 'get-procedures', {
              method: 'POST',
              data: {
                ids: [entityId]
              }
            });
          });
          let getProceduresResults = await Promise.all(getProceduresRequests);
          changedEntityIds = getProceduresResults.map(_ref3 => {
            let {
              result
            } = _ref3;
            return result[0];
          });
        }

        if (!changedEntityIds.length) {
          this.flashMessages.warning(this.intl.t('cmdb-visualisation.noChanges'), {
            title: this.intl.t('cmdb-visualisation.noChangesTitle'),
            icon: 'warning',
            sticky: false
          }); // If nothing changed, just return the original selection.

          return entityIds;
        } else {
          // This will open a modal, which is a promise, and it should return the
          // record IDs that were failed to update their monitoring status.
          return this.modals.open('beta/cmdb/modals/manage-entity-procedures', {
            entityIds: changedEntityIds
          }, {
            className: _modals.ModalLayoutClassName.Grow
          });
        }
      } catch (error) {
        console.error(error);
        this.flashMessages.danger(joinErrorMessages(error), {
          title: this.intl.t('general.error'),
          icon: 'warning',
          sticky: true
        });
      }
    }

    computeEntityTypeCategoryOptions(entityTypeCategories) {
      return entityTypeCategories.map(etc => {
        return {
          groupName: etc.get('name'),
          options: etc.get('entityTypes').filterBy('codeStore', 0).sortBy('longName')
        };
      });
    }

    setSelection(rows) {
      if (!rows) {
        return;
      }

      const alreadySelectedRows = rows.filter(row => this.alreadySelectedEntityIds.includes(row.id));
      this.bulkActions.set('selection', alreadySelectedRows);
      this.set('disabledRows', alreadySelectedRows);
    }

    selectEntityType(entityType) {
      Ember.set(this, 'selectedEntityType', entityType);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "customAttributesTask", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "customAttributesTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "entityAttributesTask", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "entityAttributesTask"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "customAttributes", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "entityAttributes", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "columns", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "rowActions", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "defaultFilters", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "defaultFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchParams", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "fetchParams"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "queryCustomFiltersTask", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "queryCustomFiltersTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "computeEntityTypeCategoryOptions", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "computeEntityTypeCategoryOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setSelection", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "setSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectEntityType", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "selectEntityType"), _class.prototype)), _class));
  _exports.default = BetaCMDBComponent;

  const joinErrorMessages = error => {
    var _error$errors$reduce, _error$errors;

    return (_error$errors$reduce = (_error$errors = error.errors) === null || _error$errors === void 0 ? void 0 : _error$errors.reduce((acc, errorMessage) => {
      return `${acc} ${errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.detail}.`;
    }, '')) !== null && _error$errors$reduce !== void 0 ? _error$errors$reduce : error;
  };
});