define("iris/services/page-title-list", ["exports", "ember-page-title/services/page-title-list"], function (_exports, _pageTitleList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageTitleList.default.extend({
    _removeExistingTitleTag() {
      let {
        environment
      } = Ember.getOwner(this).resolveRegistration('config:environment');
      let isTesting = environment === 'test'; // Disable title tag removel during testing

      if (isTesting) {
        return;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});