define("iris/components/time-out", ["exports", "iris/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const isTesting = _environment.default.environment === 'test';

  var _default = Ember.Component.extend({
    classNames: ['time-out'],
    lastActivity: null,
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      let _this = this;

      let add = 60 * 12 * 60 * 1000;

      if (!isTesting) {
        $(window).bind('mousemove.myevent', function (e) {
          let logoutAfter = new Date().getTime() + add;

          _this.set('lastActivity', logoutAfter);

          if (_this.get('sessionAccount.account')) {
            Ember.run(() => {
              Ember.run.debounce(_this.get('session'), 'invalidate', add);
            });
          }
        });
      }
    },

    didDestroyElement() {
      this._super(...arguments);

      $(window).unbind('mousemove.myevent');
    }

  });

  _exports.default = _default;
});