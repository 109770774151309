define("iris/routes/human-resource-management/my-events", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    session: Ember.inject.service(),

    model() {
      var store = this.store;
      let currentUserId = this.get('session.data.authenticated.userId');
      return Ember.RSVP.hash({
        types: store.query('eventType', {
          filter: {
            order: 'name'
          }
        }),
        selectableUsers: store.query('account', {
          filter: {
            order: 'longName',
            where: {
              and: [{
                portal: false
              }, {
                enabled: 1
              }]
            }
          }
        }),
        operationableUsers: store.query('account', {
          filter: {
            order: 'longName',
            where: {
              and: [{
                id: currentUserId
              }, {
                portal: false
              }, {
                enabled: 1
              }]
            }
          }
        })
      });
    },

    actions: {
      didTransition() {
        const routeName = this.controllerFor('application').get('currentRouteName');

        if (!routeName || !routeName.includes('.my-events')) {
          this.controller.set('selectedUsers', Ember.A());
        }

        return true;
      }

    }
  });

  _exports.default = _default;
});