define("iris/templates/settings/operations/service-levels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GsXZAtkh",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\",\"@defaultOrder\"],[\"service-level-template\",\"settings.operations.service-levels.edit\",[28,\"array\",[\"name\",\"reactionTime\",\"startTime\",\"solvingTime\"],null],\"createDate DESC\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"reactionTime\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"service-level-times-dashboard\"],[8],[0,\"\\n      \"],[1,[28,\"service-level-time\",[[23,1,[\"row\",\"reactionTime\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"startTime\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"service-level-times-dashboard\"],[8],[0,\"\\n      \"],[1,[28,\"service-level-time\",[[23,1,[\"row\",\"startTime\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"solvingTime\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"service-level-times-dashboard\"],[8],[0,\"\\n      \"],[1,[28,\"service-level-time\",[[23,1,[\"row\",\"solvingTime\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/operations/service-levels.hbs"
    }
  });

  _exports.default = _default;
});