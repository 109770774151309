define("iris/templates/settings/regions/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "esQIsOxZ",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@proportion\",\"@excludeFields\",\"@frame\",\"@needClose\"],[[23,0,[\"model\"]],\"settings.regions\",\"2-4\",[28,\"array\",[\"regionalSettings\"],null],true,false]],{\"statements\":[[0,\"\\n    \\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"name\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"code\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"fileCodeFormat\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"ticketCodeFormat\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"workLogFormat\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"ownerPartner\"]]],[0,\"\\n\\n    \"],[5,\"auto/form/one2-many\",[],[[\"@parentModel\",\"@itemName\",\"@relationFiled\",\"@neededFields\"],[[23,0,[\"model\"]],\"regionalSettings\",\"regionId\",[28,\"array\",[\"settingKey\",\"settingValue\",\"partner\",\"description\"],null]]]],[0,\"\\n\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/regions/edit.hbs"
    }
  });

  _exports.default = _default;
});