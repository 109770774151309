define("iris/templates/components/finances/pipeline/pie-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ivRSmD1l",
    "block": "{\"symbols\":[\"income\",\"i\"],\"statements\":[[7,\"h4\",true],[10,\"class\",\"h4\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-sm-7\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"simple-bullet-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"incomeObj\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[11,\"class\",[28,\"get-at-index\",[[24,[\"discs\"]],[23,2,[]]],null]],[8],[0,\"\\n      \"],[1,[28,\"get-at-index\",[[24,[\"labels\"]],[23,2,[]]],null],false],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"w40p text-right pull-right\"],[8],[0,\"(\"],[1,[28,\"t\",[\"general.pc\"],[[\"pc\"],[[23,1,[\"qty\"]]]]],false],[0,\")\"],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"pull-right\"],[8],[1,[28,\"format-number\",[[23,1,[\"amount\"]]],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[7,\"li\",true],[10,\"class\",\"none\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"labels.total\"],null],false],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"w40p text-right pull-right\"],[8],[0,\"(\"],[1,[28,\"t\",[\"general.pc\"],[[\"pc\"],[[24,[\"count\"]]]]],false],[0,\")\"],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"pull-right\"],[8],[1,[28,\"format-number\",[[24,[\"total\"]]],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-sm-5\"],[8],[0,\"\\n  \"],[1,[28,\"chart-js\",null,[[\"data\",\"type\",\"options\"],[[24,[\"data\"]],\"doughnut\",[24,[\"options\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/finances/pipeline/pie-chart.hbs"
    }
  });

  _exports.default = _default;
});