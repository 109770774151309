define("iris/components/form/field/label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w+ilU5Pg",
    "block": "{\"symbols\":[\"@name\",\"&attrs\",\"&default\"],\"statements\":[[7,\"label\",false],[12,\"for\",[23,1,[]]],[13,2],[8],[0,\"\\n  \"],[14,3],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/form/field/label/template.hbs"
    }
  });

  _exports.default = _default;
});