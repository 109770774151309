define("iris/utils/properties/observer-task", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = observerTask;

  /**
   * A safer drop-in replacement where regular observers would be used.
   *
   * Creates a task that'll run when the dependent keys change. As opposed to
   * `computedTask`, the task instance is not accessible, nor it returns a value.
   * It does, however, runs right after the object is initialized, as there is no
   * way to trigger the task by consuming its value. The advantage over observers
   * is that `observerTask` will cancel itself when the host object is destroyed,
   * eliminating the "Calling set on a destroyed object" error category.
   *
   * Prefer `computedTask` over `observerTask` when possible! The very few use
   * cases include dealing with Ember Data records, where the only way of setting
   * an `attr` when the value is a result of an async computation triggered by a
   * change in a dependent key is using an observer, as `attr`s can't be computed
   * properties themselves.
   *
   * Usage:
   *
   * ```js
   * setRelatedData: observerTask('name', function * () {
   *   let result = yield fetch('/api/something);
   *
   *   this.model.set('someAttr', result);
   * })
   * ```
   */
  function observerTask() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    let {
      dependentKeys,
      generatorFn
    } = parseArgs(args);
    return Ember.on('init', function () {
      let taskPropertyName = `__OBSERVER_TASK_${TASK_COUNTER++}`;
      Ember.defineProperty(this, taskPropertyName, (0, _emberConcurrency.task)(generatorFn).restartable());
      this.get(taskPropertyName).perform();
      let expandedKeys = [];
      dependentKeys.forEach(dk => {
        Ember.expandProperties(dk, propName => {
          expandedKeys.push(propName);
        });
      });
      expandedKeys.forEach(ek => {
        // eslint-disable-next-line ember/no-observers
        this.addObserver(ek, () => {
          this.get(taskPropertyName).perform();
        });
      });
    });
  }

  let TASK_COUNTER = 1;

  const parseArgs = args => ({
    dependentKeys: args.slice(0, -1),
    generatorFn: args[args.length - 1]
  });
});