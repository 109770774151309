define("iris/components/aiops/dashboards/system/good-pie/component", ["exports", "iris/utils/properties"], function (_exports, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    themeSwitcher: Ember.inject.service(),
    intervalFilter01: Ember.computed('intl', function () {
      return {
        id: 'weekly',
        name: this.intl.t('aiops.dashboars.weekly'),
        value: 7
      };
    }),
    data: (0, _properties.computedTask)('intervalFilter01', function* () {
      return yield this.ajax.call('POST', 'AiopsPartner', '', 'systemCPPie', {
        day: this.intervalFilter01.value
      }, true);
    }),
    tableData: Ember.computed('data', function () {
      const data = getData(this.data);
      const sum = this.data && Ember.isArray(this.data) ? this.data[0].count : 0;
      return {
        priority: data[0],
        ticketType: data[1],
        operationServiceLevel: data[2],
        userGrade: data[3],
        spentTime: data[4],
        priorityPercentage: Math.round(Number(data[0]) / sum * 100),
        ticketTypePercentage: Math.round(Number(data[1]) / sum * 100),
        operationServiceLevelPercentage: Math.round(Number(data[2]) / sum * 100),
        userGradePercentage: Math.round(Number(data[3]) / sum * 100),
        spentTimePercentage: Math.round(Number(data[4]) / sum * 100)
      };
    }),
    chartData: Ember.computed('data', 'model.id', function () {
      const data = getData(this.data);
      return {
        labels: [this.intl.t('aiops.dashboars.priority'), this.intl.t('aiops.dashboars.ticket-type'), this.intl.t('aiops.dashboars.operation-service-level'), this.intl.t('aiops.dashboars.user-grade'), this.intl.t('aiops.dashboars.spent-time')],
        datasets: [{
          data,
          backgroundColor: ['rgba(216, 228, 126)', 'rgba(171, 202, 58)', 'rgba(119, 172, 23)', 'rgba(84, 138, 14)', 'rgba(68, 107, 16)'],
          borderColor: ['rgba(216, 228, 126)', 'rgba(171, 202, 58)', 'rgba(119, 172, 23)', 'rgba(84, 138, 14)', 'rgba(68, 107, 16)']
        }]
      };
    }),
    options: Ember.computed('themeSwitcher.dark', 'data', function () {
      const fontColor = this.get('themeSwitcher.dark') ? '#FFF' : '#485761';
      const count = this.data ? this.data[0].count : 0;
      return {
        cutoutPercentage: 75,
        legend: {
          display: false
        },
        elements: {
          center: {
            // the longest text that could appear in the center
            maxText: '100%',
            text: `${count} db ticket`,
            fontColor: fontColor,
            fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            fontStyle: 'normal',
            // fontSize: 12,
            // if a fontSize is NOT specified, we will scale (within the below limits) maxText to take up the maximum space in the center
            // if these are not specified either, we default to 1 and 256
            minFontSize: 1,
            maxFontSize: 26
          }
        }
      };
    }),
    actions: {
      updateintervalFilter01(selected) {
        this.set('intervalFilter01', selected);
      }

    }
  });

  _exports.default = _default;

  const getData = function (data) {
    let resData = [];

    if (data) {
      data.forEach(function (cd) {
        resData.push(cd['good_priority']);
        resData.push(cd['good_ticket_type']);
        resData.push(cd['good_operation_service_level']);
        resData.push(cd['good_user_grade']);
        resData.push(cd['good_public_spent_time']);
      });
    }

    return resData;
  };
});