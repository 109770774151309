define("iris/components/forms/work-log", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      let start = _moment.default.duration(this.get('spentTime'), 'h').asMinutes();

      this.set('start', start);
    },

    minLengthInHour: 0.25,
    // 15 minutes
    classNames: ['work-log-form'],
    startsAt: Ember.computed.alias('model.startsAt'),
    endsAt: Ember.computed.alias('model.endsAt'),
    publicSpentTime: Ember.computed.alias('model.publicSpentTime'),
    spentTime: Ember.computed.alias('model.spentTime'),
    duration: Ember.computed.alias('model.duration'),
    prevEnds: Ember.computed.alias('boundaryWorkLogs.lower.endsAt'),
    nextStarts: Ember.computed.alias('boundaryWorkLogs.upper.startsAt'),
    startsMinDate: Ember.computed('prevEnds', 'startsAt', function () {
      let ends = this.get('prevEnds');
      let dayStarts = (0, _moment.default)(this.get('startsAt')).startOf('day');

      if (Ember.isNone(ends)) {
        return dayStarts;
      }

      return (0, _moment.default)(ends).isBefore(dayStarts) ? dayStarts : ends;
    }),
    startsMaxDate: Ember.computed('endsAt', 'spentTime', function () {
      let addHour = Math.max(this.get('spentTime'), this.get('minLengthInHour'));
      return (0, _moment.default)(this.get('endsAt')).subtract(addHour, 'h');
    }),
    endsMinDate: Ember.computed('startsAt', 'spentTime', function () {
      let addHour = Math.max(this.get('spentTime'), this.get('minLengthInHour'));
      return (0, _moment.default)(this.get('startsAt')).add(addHour, 'h');
    }),
    endsMaxDate: Ember.computed('nextStarts', 'startsAt', function () {
      let starts = this.get('nextStarts');
      let dayEnds = (0, _moment.default)(this.get('startsAt')).endOf('day');

      if (Ember.isNone(starts)) {
        return dayEnds;
      }

      return (0, _moment.default)(starts).isAfter(dayEnds) ? dayEnds : starts;
    }),
    actions: {
      slide(val) {
        let hours = _moment.default.duration(parseInt(val), 'm').asHours();

        this.set('spentTime', hours);

        let duration = _moment.default.duration(this.get('duration'), 'm').asHours();

        this.set('publicSpentTime', duration - hours);
      }

    }
  });

  _exports.default = _default;
});