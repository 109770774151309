define("iris/templates/settings/project/file-business-types/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kfev1669",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@proportion\",\"@needClose\",\"@componentLayout\",\"@frame\"],[[23,0,[\"model\"]],\"settings.project.file-business-types\",\"2-10\",true,\"col-2\",true]],{\"statements\":[[0,\"\\n \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"col-sm-12 \",[28,\"if\",[[23,0,[\"model\",\"id\"]],\"col-md-4\",\"col-md-8\"],null]]]],[8],[0,\"\\n      \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\",\"@proportion\"],[[23,0,[\"model\"]],\"name\",\"2-8\"]]],[0,\"\\n      \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\",\"@proportion\"],[[23,0,[\"model\"]],\"profitCenter\",\"2-8\"]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-12 col-md-8\"],[8],[0,\"\\n      \"],[5,\"auto/form/one2-many\",[],[[\"@parentModel\",\"@itemName\",\"@relationFiled\",\"@formComponent\",\"@componentLayout\",\"@modalSize\"],[[23,0,[\"model\"]],\"fileTypes\",\"fileBusinessTypeId\",\"forms/file-type\",\"col-2\",\"M\"]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/project/file-business-types/edit.hbs"
    }
  });

  _exports.default = _default;
});