define("iris/components/async-attr/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "87ah3PAB",
    "block": "{\"symbols\":[\"@value\",\"&default\",\"@namedBlocksInfo\",\"@promise\"],\"statements\":[[4,\"if\",[[28,\"is-pending\",[[23,4,[]]],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"t\",[\"general.loading\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"is-rejected\",[[23,4,[]]],null]],null,{\"statements\":[[0,\"  ⚠️\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"-has-block\",[[23,3,[]],\"default\",[25,2]],null]],null,{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"      \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      -\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"or\",[[23,1,[]],\"-\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/async-attr/template.hbs"
    }
  });

  _exports.default = _default;
});