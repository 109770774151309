define("iris/models/ticket-source", ["exports", "ember-data", "ember-data/attr", "ember-cp-validations"], function (_exports, _emberData, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 100
    })]
  });

  var _default = _emberData.default.Model.extend(Validations, {
    name: (0, _attr.default)('string'),
    systemCode: (0, _attr.default)('number')
  });

  _exports.default = _default;
});