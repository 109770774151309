define("iris/templates/components/bs-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZYjUPG6i",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[24,[\"dismissed\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"dismissible\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"close\"],[12,\"aria-label\",\"Close\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"dismiss\"]],[8],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"×\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/bs-alert.hbs"
    }
  });

  _exports.default = _default;
});