define("iris/templates/operation/report-submit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l7R9iRED",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"main-content\"],[8],[0,\"\\n  \"],[1,[28,\"forms/incident-form\",null,[[\"model\",\"mode\",\"redirectingRoute\"],[[24,[\"model\"]],\"new\",[23,0,[\"previousRoute\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/operation/report-submit.hbs"
    }
  });

  _exports.default = _default;
});