define("iris/components/forms/document-type-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    searchTerm: '',
    filteredCompetencies: Ember.computed('competencies.[]', 'searchTerm', function () {
      const searchTerm = this.get('searchTerm');
      const competencies = this.get('competencies');

      if (searchTerm.length === 0) {
        return competencies;
      }

      return competencies.filter(competency => {
        return competency.get('name').indexOf(searchTerm) > -1;
      });
    })
  });

  _exports.default = _default;
});