define("iris/components/buttons/add-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: 'span',
    type: 'primary',
    _disabled: Ember.computed('disabled', 'value.isSaving', function () {
      const disabled = this.get('disabled');
      return disabled || this.get('value.isSaving');
    }),
    _text: Ember.computed('text', function () {
      return this.get('text') ? this.get('text') : this.intl.t('buttons.add');
    })
  });

  _exports.default = _default;
});