define("iris/components/issue-report/modals/work-log/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xr8rGTYx",
    "block": "{\"symbols\":[\"@data\",\"@close\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-content mx-auto\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel panel-default\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-heading\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[10,\"class\",\"h3 modal-title\"],[8],[0,\"\\n        \"],[1,[23,1,[\"title\"]],false],[0,\"\\n      \"],[9],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n      \"],[5,\"forms/work-log-new\",[],[[\"@model\",\"@addToTicket\"],[[23,1,[\"workLog\"]],true]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-footer min-height60\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"pull-right\"],[8],[0,\"\\n        \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@icon\",\"@text\"],[\"primary\",[28,\"action\",[[23,0,[]],\"validateWorkLog\"],null],\"floppy-o\",[28,\"t\",[[28,\"if\",[[23,1,[\"workLog\",\"isNew\"]],\"buttons.create\",\"buttons.save\"],null]],null]]]],[0,\"\\n\\n        \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"default\",[28,\"fn\",[[23,2,[]],false],null],[28,\"t\",[[28,\"if\",[[23,1,[\"workLog\",\"isNew\"]],\"buttons.cancel\",\"buttons.close\"],null]],null]]]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/issue-report/modals/work-log/template.hbs"
    }
  });

  _exports.default = _default;
});