define("iris/controllers/finances/pipelines/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actualFetchData: Ember.inject.service(),
    opportunities: Ember.computed('actualFetchData.data', function () {
      return this.get('actualFetchData.data').filter((item, index, self) => Number(item.probability) < 100);
    }),
    ordered: Ember.computed('actualFetchData.data', function () {
      return this.get('actualFetchData.data').filter((item, index, self) => Number(item.probability) === 100);
    }),
    opportunitiesOwn: Ember.computed('opportunities', function () {
      return this.get('opportunities').filter((item, index, self) => item.asPrimeContractor === 1);
    }),
    opportunitiesOther: Ember.computed('opportunities', function () {
      return this.get('opportunities').filter((item, index, self) => item.asPrimeContractor === 0);
    }),
    orderedOwn: Ember.computed('ordered', function () {
      return this.get('ordered').filter((item, index, self) => item.asPrimeContractor === 1);
    }),
    orderedOther: Ember.computed('ordered', function () {
      return this.get('ordered').filter((item, index, self) => item.asPrimeContractor === 0);
    }),
    opportunitiesOwnIncomes: Ember.computed.mapBy('opportunitiesOwn', 'weightedIncome'),
    opportunitiesOtherIncomes: Ember.computed.mapBy('opportunitiesOther', 'weightedIncome'),
    orderedOwnIncomes: Ember.computed.mapBy('orderedOwn', 'weightedIncome'),
    orderedOtherIncomes: Ember.computed.mapBy('orderedOther', 'weightedIncome'),
    opportunitiesOwnIncome: Ember.computed('opportunitiesOwnIncomes.[]', function () {
      let sum = 0;
      this.get('opportunitiesOwnIncomes').forEach(o => {
        sum += parseInt(o) * 1;
      });
      return sum;
    }),
    opportunitiesOtherIncome: Ember.computed('opportunitiesOtherIncomes.[]', function () {
      let sum = 0;
      this.get('opportunitiesOtherIncomes').forEach(o => {
        sum += parseInt(o) * 1;
      });
      return sum;
    }),
    orderedOwnIncome: Ember.computed('orderedOwnIncomes.[]', function () {
      let sum = 0;
      this.get('orderedOwnIncomes').forEach(o => {
        sum += parseInt(o) * 1;
      });
      return sum;
    }),
    orderedOtherIncome: Ember.computed('orderedOtherIncomes.[]', function () {
      let sum = 0;
      this.get('orderedOtherIncomes').forEach(o => {
        sum += parseInt(o) * 1;
      });
      return sum;
    }),
    totalOpportunities: Ember.computed('opportunitiesOwnIncome', 'opportunitiesOtherIncome', function () {
      return this.get('opportunitiesOwnIncome') + this.get('opportunitiesOtherIncome');
    }),
    countOpportunities: Ember.computed('opportunitiesOwnIncomes', 'opportunitiesOtherIncomes', function () {
      return this.get('opportunitiesOwnIncomes.length') + this.get('opportunitiesOtherIncomes.length');
    }),
    totalOrdered: Ember.computed('orderedOwnIncome', 'orderedOtherIncome', function () {
      return this.get('orderedOwnIncome') + this.get('orderedOtherIncome');
    }),
    countOrdered: Ember.computed('orderedOwnIncomes', 'orderedOtherIncomes', function () {
      return this.get('orderedOwnIncomes.length') + this.get('orderedOtherIncomes.length');
    }),
    total: Ember.computed('totalOrdered', 'totalOpportunities', function () {
      return this.get('totalOrdered') + this.get('totalOpportunities');
    }),
    count: Ember.computed('countOrdered', 'countOpportunities', function () {
      return this.get('countOrdered') + this.get('countOpportunities');
    })
  });

  _exports.default = _default;
});