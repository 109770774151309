define("iris/models/date-type-definition", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    days: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    mo: (0, _attr.default)('boolean'),
    tu: (0, _attr.default)('boolean'),
    we: (0, _attr.default)('boolean'),
    th: (0, _attr.default)('boolean'),
    fr: (0, _attr.default)('boolean'),
    sa: (0, _attr.default)('boolean'),
    su: (0, _attr.default)('boolean'),
    dates: (0, _relationships.hasMany)('dateTypeDefinitionDate')
  });

  _exports.default = _default;
});