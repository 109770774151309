define("iris/components/forms/entity-move", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        name: this.intl.t('entity-move.name'),
        property: 'name',
        search: true
      }, {
        name: this.intl.t('entity-move.type'),
        property: 'entityType.name'
      }];
    }),
    partnerOwner: Ember.computed('contact.partner', {
      get(key) {
        let partner = this.get('contact.partner');
        return partner;
      }

    }),
    departmentOwner: Ember.computed('contact.department', {
      get(key) {
        let department = this.get('contact.department');
        return department;
      }

    }),
    partnerAssigned: Ember.computed('assignedContact.partner', {
      get(key) {
        let partner = this.get('assignedContact.partner');
        return partner;
      }

    }),
    departmentAssigned: Ember.computed('assignedContact.department', {
      get(key) {
        let department = this.get('assignedContact.department');
        return department;
      }

    }),
    departmentOwnerOptions: Ember.computed('departments.[]', 'partnerOwner.id', function () {
      return this.get('departments').filter(department => {
        return department.get('partner.id') === this.get('partnerOwner.id');
      });
    }),
    contactOwnerOptions: Ember.computed('contacts.[]', 'departmentOwner.id', function () {
      return this.get('contacts').filter(contact => {
        if (this.get('departmentOwner.id')) {
          return contact.get('department.id') === this.get('departmentOwner.id');
        } else {
          return contact.get('partner.id') === this.get('partnerOwner.id');
        }
      });
    }),
    departmentAssignedOptions: Ember.computed('departments.[]', 'partnerAssigned.id', function () {
      return this.get('departments').filter(department => {
        return department.get('partner.id') === this.get('partnerAssigned.id');
      });
    }),
    contactAssignedOptions: Ember.computed('contacts.[]', 'departmentAssigned.id', function () {
      return this.get('contacts').filter(contact => {
        if (this.get('departmentOwner.id')) {
          return contact.get('department.id') === this.get('departmentAssigned.id');
        } else {
          return contact.get('partner.id') === this.get('partnerAssigned.id');
        }
      });
    }),
    actions: {
      changePartnerOwner: function (selected) {
        this.set('departmentOwner', null);
        this.set('selectedContact', null);
        this.set('partnerOwner', selected);
      },
      changeDepartmentOwner: function (selected) {
        this.set('selectedContact', null);
        this.set('departmentOwner', selected);
      },
      changePartnerAssigned: function (selected) {
        this.set('departmentAssigned', null);
        this.set('selectedAssignedContact', null);
        this.set('partnerAssigned', selected);
      },
      changeDepartmentAssigned: function (selected) {
        this.set('selectedAssignedContact', null);
        this.set('departmentAssigned', selected);
      },
      changeContact: function (selected) {
        this.set('selectedContact', selected); //if(!this.get('model.assignedContact.content')){

        this.set('partnerAssigned', this.get('partnerOwner'));
        this.set('departmentAssigned', this.get('departmentOwner'));
        this.set('selectedAssignedContact', selected); //}
      }
    }
  });

  _exports.default = _default;
});