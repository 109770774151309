define("iris/initializers/ember-cronstrue", ["exports", "iris/config/environment", "ember-cronstrue/configuration"], function (_exports, _environment, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function
    /* application */
  initialize() {
    const config = _environment.default['ember-cronstrue'] || {};

    _configuration.default.load(config);
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});