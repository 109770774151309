define("iris/templates/cmdb/entity-types/entities/entity/moves", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5A7LP+Ae",
    "block": "{\"symbols\":[\"data\",\"columns\",\"row\"],\"statements\":[[4,\"bs-table\",null,[[\"data\",\"columns\",\"newButton\",\"hasCheckbox\"],[[24,[\"model\",\"moves\"]],[24,[\"columns\"]],false,false]],{\"statements\":[[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[28,\"moment-format\",[[23,3,[\"date\"]],\"YYYY.MM.DD HH:mm\"],null],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[23,3,[\"owner\",\"longName\"]],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[23,3,[\"entity\",\"name\"]],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[23,3,[\"entity\",\"manufacturer\",\"name\"]],false],[0,\" \"],[1,[23,3,[\"entity\",\"type\",\"name\"]],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[23,3,[\"entity\",\"entityType\",\"name\"]],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[23,3,[\"entity\",\"serialNumber\"]],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[28,\"get\",[[23,3,[]],\"source.name\"],null],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[28,\"get\",[[23,3,[]],\"destination.name\"],null],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[28,\"get\",[[23,3,[]],\"type\"],null],\"assignedContact\"],null]],null,{\"statements\":[[0,\"          \"],[1,[28,\"t\",[\"entity-move.type-options.user\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[28,\"t\",[\"entity-move.type-options.owner\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\" \\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/entity-types/entities/entity/moves.hbs"
    }
  });

  _exports.default = _default;
});