define("iris/templates/components/forms/bank-balance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NMFlG0PO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-form\",null,[[\"model\",\"proportion\"],[[24,[\"model\"]],\"3-9\"]],{\"statements\":[[4,\"form-group\",null,[[\"property\",\"label\"],[\"date\",[28,\"t\",[\"tasks.main.date\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-datetimepicker\",null,[[\"date\"],[[24,[\"model\",\"date\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\"],[\"bank\",[28,\"t\",[\"forms.bank\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"number-input\",null,[[\"value\"],[[24,[\"model\",\"bank\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/bank-balance.hbs"
    }
  });

  _exports.default = _default;
});