define("iris/components/bs-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'label',
    classNames: ['form-checkbox', 'form-normal', 'form-primary', 'form-text'],
    classNameBindings: ['value:active', 'disabled:disabled'],
    model: null,
    value: 0,
    disableSetValue: false,
    click: function (e) {
      if (this.get('disabled')) {
        e.stopPropagation();
        return;
      }

      if (this.get('bubbles') === false) {
        e.stopPropagation();
      }

      if (!this.get('disableSetValue')) {
        let value = this.get('value') ? 0 : 1;
        this.set('value', value);
        let model = this.get('model');
        value = model ? model : this.get('value');
        this.sendAction('onchange', value);
      } else {
        this.sendAction('onchange');
      }
    }
  });

  _exports.default = _default;
});