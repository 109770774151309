define("iris/templates/settings/integrations/mappers/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z+n/rbvp",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"menus.new-mapper\"],null]],null],false],[0,\"\\n\"],[5,\"forms/mapper-form\",[],[[\"@model\"],[[23,0,[\"model\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/integrations/mappers/new.hbs"
    }
  });

  _exports.default = _default;
});