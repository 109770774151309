define("iris/routes/cmdb/entity-types/entities/entity/software/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      let parentModel = this.modelFor('cmdb.entity-types.entities.entity');
      let parentEntity = parentModel.entity;
      let entityTypes = parentModel.entityTypes;
      let partners = parentModel.partners;
      controller.set('parentEntity', parentEntity);
      controller.set('entityTypes', entityTypes);
      controller.set('partners', partners);
    }

  });

  _exports.default = _default;
});