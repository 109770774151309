define("iris/components/bs-matrix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['table-component'],
    intl: Ember.inject.service(),
    init: function () {
      this._super(); // see https://dockyard.com/blog/2014/04/17/ember-object-self-troll


      this.set('selectedRecords', Em.A());
      this.set('filterObject', Em.A());
    },
    setWidths: function (widths) {
      var width = 0,
          widths = widths.split(',');
      this.$('table tr th').each(function (i, v) {
        width += $(v).width();
      });
      this.$('table tr th').each(function (i, v) {
        var w = widths[i] * width / 100;
        $(v).width(w);
      });
    },
    didInsertElement: function () {
      this._super(...arguments);

      if (this.get('colResizable')) {
        this.$('table').colResizable();
      }

      var widths = this.get('widths');

      if (widths) {
        this.setWidths(widths);
      }
    },
    allRecordSelected: Ember.computed('selectedRecords.length', 'paginatedData.length', function () {
      let selectedRecords = this.get('selectedRecords');
      let paginatedData = this.get('paginatedData');

      if (paginatedData.length === 0) {
        return false;
      }

      return selectedRecords.length === paginatedData.length;
    }),
    didRender: function () {
      this._super(...arguments);

      if (this.get('colResizable')) {
        this.$('table').colResizable({
          disable: true
        });
        this.$('table').colResizable();
      }
    },
    total: Ember.computed.reads('searchedData.length'),
    paginator: true,
    colResizable: false,
    hasActions: false,
    hasCheckbox: true,
    newRecords: false,
    enableSortable: false,
    search: true,
    striped: true,
    export: true,
    offset: 1,
    limit: 10,
    page: 1,
    columns: [],
    filterObject: Ember.A(),
    filterOptionsComputed: Ember.computed(function () {
      let filterOptions = this.get('filterOptions');
      let res = Ember.A();

      if (filterOptions) {
        filterOptions.forEach(fo => {
          if ("options" in fo) {
            fo.options.forEach(o => {
              if (!o.default) {
                res.pushObject(o);
              }
            });
          } else {
            if (!fo.default) {
              res.pushObject(fo);
            }
          }
        });
      }

      return res;
    }),
    selectedFilter: Ember.computed(function () {
      let filterOptions = this.get('filterOptions');
      let res = Ember.A();

      if (filterOptions) {
        filterOptions.forEach(fo => {
          if ("options" in fo) {
            fo.options.forEach(o => {
              if (o.default) {
                //res.pushObject(o);
                this.get('filterObject').pushObject(o.condition);
              }
            });
          } else {
            if (fo.default) {
              //res.pushObject(fo);
              this.get('filterObject').pushObject(fo.condition);
            }
          }
        });
      }

      return res;
    }),
    itemsPerPage: Ember.computed('total', function () {
      let total = this.get('total');
      return [{
        label: "10",
        value: 10
      }, {
        label: "25",
        value: 25
      }, {
        label: "50",
        value: 50
      }, {
        label: this.intl.t('general.all'),
        value: total
      }];
    }),
    selectedItemsPerPage: Ember.computed('limit', 'itemsPerPage', function () {
      return this.get('itemsPerPage').findBy('value', this.get('limit'));
    }),
    sortProperties: Ember.computed(function () {
      let defaultOrder = this.get('defaultOrder');
      let res = Ember.A();

      if (defaultOrder) {
        res.pushObject(defaultOrder);
      }

      return res;
    }),
    operators: {
      '=': function (a, b) {
        return a === b;
      },
      '>': function (a, b) {
        return a > b;
      },
      '<': function (a, b) {
        return a < b;
      },
      'eq': function (a, b) {
        return a == b;
      },
      'cont': function (a, b) {
        return a.toLowerCase().indexOf(b.toLowerCase()) > -1;
      }
    },
    searchedColumns: Ember.computed.mapBy('columns', 'property'),
    filteredData: Ember.computed('data.[]', 'data', 'data.@each.isNew', 'newRecords', function () {
      var data = this.get('data');

      if (Ember.isNone(data)) {
        data = [];
      }

      if (this.get('newRecords')) {
        return data;
      } else {
        return data.filter(row => {
          row = row.get ? row : Ember.Object.create(row);

          if (row.get('id') === '000') {
            return false;
          }

          if (row.get('isDeleted')) {
            return false;
          }

          return row.isNew ? !row.get('isNew') : true;
        });
      }
    }),
    customFilter: Ember.computed('filteredData.[]', 'filterObject.[]', function () {
      let data = this.get('filteredData');
      let filter = this.get('filterObject');
      let operators = this.get('operators');

      if (filter === Ember.A()) {
        return data;
      } else {
        return data.filter(item => {
          let res = true;
          filter.forEach(f => {
            if (!operators[f.op](item.get(f.property), f.value)) {
              res = false;
              return false;
            }
          });
          return res;
        });
      }
    }),
    searchedData: Ember.computed('searchTerm', 'customFilter.[]', function () {
      let data = this.get('customFilter');
      let searchTerm = this.get('searchTerm');

      if (Ember.isNone(searchTerm)) {
        return data;
      }

      let searchedColumns = this.get('searchedColumns');
      return data.filter(function (record) {
        let found = false;
        record = record.get ? record : Ember.Object.create(record);

        for (let i = 0; i < searchedColumns.length; i++) {
          let value = record.get(searchedColumns[i]);

          if (String(value).indexOf(searchTerm) > -1) {
            found = true;
            break;
          }
        }

        return found;
      });
    }),
    sortedData: Ember.computed('searchedData.[]', 'sortProperties', function () {
      var res = this.get('searchedData');
      var sortKeys = this.get('sortProperties');
      return res.toArray().sort(function (a, b) {
        for (var i = 0; i < sortKeys.length; i++) {
          var key = sortKeys[i];
          var inverse = false;

          if (key.indexOf(' desc') > -1) {
            key = key.slice(0, -5);
            inverse = true;
          }

          var propA = Ember.get(a, key);
          var propB = Ember.get(b, key); // return 1 or -1 else continue to the next sortKey

          var compareValue = Ember.compare(propA, propB);

          if (compareValue) {
            return inverse ? compareValue * -1 : compareValue;
          }
        }

        return 0;
      });
    }),
    paginatedData: Ember.computed('sortedData.[]', 'page', 'limit', function () {
      var limit = this.get('limit');
      var start = (this.get('page') - 1) * limit;
      var end = start + limit;
      return this.get('sortedData').slice(start, end);
    }),
    pages: Ember.computed('total', 'limit', 'page', function () {
      let totalPage = Math.ceil(this.get('total') / this.get('limit'));
      let pages = Ember.A();
      let curPage = this.get('page');
      let page = 0;
      let startPage = curPage < 5 ? 1 : curPage - 4;
      let endPage = 8 + startPage;
      endPage = totalPage < endPage ? totalPage : endPage;
      let diff = startPage - endPage + 8;
      startPage -= startPage - diff > 0 ? diff : 0;

      if (startPage > 1) {
        pages.pushObject({
          number: 1,
          text: this.intl.t('matrix.first')
        });
        pages.pushObject({
          text: '...'
        });
      }

      for (let i = startPage; i <= endPage; i++) {
        page = i;
        pages.pushObject({
          number: page,
          text: page,
          cssClass: page === curPage ? 'active' : ''
        });
      }

      if (endPage < totalPage) {
        pages.pushObject({
          text: '...'
        });
        pages.pushObject({
          number: totalPage,
          text: this.intl.t('matrix.last')
        });
      }

      return pages;
    }),
    countRightBtns: Ember.computed('buttonCheckboxActions', 'buttonActions', function () {
      var count = 0;
      var checkBtns = this.get('buttonCheckboxActions');
      var actionBtns = this.get('buttonActions');

      if (checkBtns) {
        checkBtns.forEach(item => {
          if (item.position === 'right') {
            count++;
          }
        });
      }

      if (actionBtns) {
        actionBtns.forEach(item => {
          if (item.position === 'right') {
            count++;
          }
        });
      }

      return count;
    }),
    countLeftBtns: Ember.computed('buttonCheckboxActions', 'buttonActions', function () {
      var count = 0;
      var checkBtns = this.get('buttonCheckboxActions');
      var actionBtns = this.get('buttonActions');

      if (checkBtns) {
        checkBtns.forEach(item => {
          if (item.position !== 'right') {
            count++;
          }
        });
      }

      if (actionBtns) {
        actionBtns.forEach(item => {
          if (item.position !== 'right') {
            count++;
          }
        });
      }

      return count;
    }),
    hasExtraBtns: Ember.computed('buttonCheckboxActions', 'buttonActions', function () {
      var checkBtns = this.get('buttonCheckboxActions');
      var actionBtns = this.get('buttonActions');

      if (checkBtns) {
        return true;
      }

      if (actionBtns) {
        return true;
      }

      return false;
    }),
    actions: {
      sort: function (property, e) {
        var res = [];
        var sortProperties = this.get('sortProperties');
        var index = sortProperties.findIndex(item => item.indexOf(property) > -1);

        if (e.shiftKey) {
          sortProperties.forEach(item => res.push(item));
        }
        /* jshint expr:true */


        if (index === -1) {
          res.push(property);
        } else if (sortProperties[index].indexOf(' desc') > -1) {
          e.shiftKey ? res.splice(index, 1) : res.push(property);
        } else {
          e.shiftKey ? res[index] = property + ' desc' : res.push(property + ' desc');
        }

        this.set('sortProperties', res);
      },

      setLimit(limit) {
        this.set('limit', limit.value);
        this.set('page', 1);
      },

      changePage(page) {
        this.set('page', page);
      },

      emptySelectedRecords: function (res) {
        this.set('selectedRecords', Ember.A());
      },

      delete() {
        let selectedRecords = this.get('selectedRecords');
        let deleteAction = this.get('deleteAction');

        if (deleteAction) {
          this.sendAction('deleteAction', selectedRecords);
        } else {
          let router = Ember.getOwner(this).lookup('router:main');
          router.send('delete', selectedRecords);
        }
      },

      search(searchTerm) {
        this.set('searchTerm', searchTerm);
      },

      selectAll(selected) {
        if (selected) {
          this.set('selectedRecords', this.get('paginatedData'));
        } else {
          this.send('emptySelectedRecords');
        }
      },

      filterSelected(selected) {
        this.set('selectedFilter', selected);
        this.set('page', 1); //Default filter

        let filterOptions = this.get('filterOptions');
        let res = Ember.A();

        if (filterOptions) {
          filterOptions.forEach(fo => {
            if ("options" in fo) {
              fo.options.forEach(o => {
                if (o.default) {
                  res.pushObject(o.condition);
                }
              });
            } else {
              if (fo.default) {
                res.pushObject(fo.condition);
              }
            }
          });
        }

        this.set('filterObject', res);

        if (selected.length) {
          selected.forEach(s => {
            this.get('filterObject').pushObject(s.condition);
          });
        }
      },

      exportTable() {
        let options = this.get('exportOptions') || {};
        options.type = 'csv';
        options.fileName = 'Iris export';
        options.csvSeparator = ';';
        let table = this.$('table').clone();
        table.each(function () {
          $(this).find('tr').each(function () {
            $(this).find('td').each(function () {
              let newText = $(this).text().replace(new RegExp(" ", "g"), '').replace(new RegExp(" ", "g"), '');
              if (!isNaN(newText)) $(this).text(newText);
            });
          });
        });
        table.tableExport(options);
      }

    }
  });

  _exports.default = _default;
});