define("iris/routes/operation/report-submit/new-sub-ticket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    model() {
      let store = this.store;
      return Ember.RSVP.hash({
        ticket: store.createRecord('ticket'),
        status: store.findAll('status'),
        user: store.findAll('account')
      });
    },

    actions: {
      saveSubTicket(ticket) {
        let store = this.store;
        const flashMessages = Ember.get(this, 'flashMessages');
        store.find('ticketType', 'SYS_05').then(ticketType => {
          ticket.set('type', ticketType);
          ticket.set('parentId', "");
          ticket.set('subticket', "1");
        }).then(a => {
          ticket.save().then(res => {
            flashMessages.add({
              message: this.intl.t('operation.report-submit.subtaskAdded'),
              title: 'IRIS message',
              icon: 'info'
            });
          }).catch(err => {
            console.error(err);
            let errorMessages = '';

            if (err.errors) {
              err.errors.forEach(error => {
                errorMessages += error.detail + ".";
              });
            } else {
              errorMessages += err;
            }

            flashMessages.add({
              message: errorMessages + '!!!',
              title: 'IRIS message',
              icon: 'warning'
            });
          });
          var controller = this.controllerFor('operation.report-submit');
          controller.set('refresh', false);
          Ember.run.next(function () {
            controller.set('refresh', true); //controller.send('refresh');
          });
          this.transitionTo("operation.report-submit");
        });
      }

    },
    setupController: function (controller, model) {
      this._super(controller, model);

      var contact = this.controllerFor('operation.report-submit').get("model.ticket.contact");
      this.currentModel.ticket.set("contact", contact);
      this.controller.set('ticket', this.currentModel.ticket);
    }
  });

  _exports.default = _default;
});