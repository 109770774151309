define("iris/components/forms/ticket-user-assoc-edit-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',

    init() {
      this._super(...arguments);

      if (!this.get('model.id')) {
        let model = this.get('model');
        model.set('startDate', model.get('ticket.plannedStartDate'));
        model.set('deadline', model.get('ticket.plannedEndDate'));
      }
    },

    model: null,
    // @
    actions: {
      changeInternalWork() {
        this.model.set('user', null);
        this.model.set('supplier', null);
      }

    }
  });

  _exports.default = _default;
});