define("iris/components/tandm/info/time-line/item/project-ticket-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dIVlGKps",
    "block": "{\"symbols\":[\"@ticket\"],\"statements\":[[5,\"link-to\",[[12,\"target\",\"_blank\"]],[[\"@route\",\"@model\"],[\"tasks.tasks.work-logs\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n  \"],[1,[23,1,[\"ticketNumber\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"font-bold\"],[8],[1,[23,1,[\"subject\"]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/tandm/info/time-line/item/project-ticket-link/template.hbs"
    }
  });

  _exports.default = _default;
});