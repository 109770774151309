define("iris/templates/tasks/work-logs/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BWaeBj9s",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-tether\",null,[[\"to\",\"target\",\"targetModifier\",\"attachment\",\"on-overlay-click\",\"tetherClass\",\"overlayClass\"],[\"modal-dialog\",\"document.body\",\"visible\",\"middle center\",[28,\"route-action\",[\"back\",[24,[\"model\"]]],null],\"modal-dialog epm-modal--m\",\"modal-backdrop\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n\\n    \"],[1,[28,\"modal-header\",null,[[\"title\"],[[28,\"t\",[\"operation.report-submit.new-worksheet\"],null]]]],false],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n      \"],[1,[28,\"forms/work-log-new\",null,[[\"model\",\"tickets\",\"buttonActions\",\"uploadTitle\",\"selectedRecords\",\"deleteConfirmation\"],[[24,[\"model\"]],[24,[\"tickets\"]],[24,[\"buttonActions\"]],[24,[\"uploadTitle\"]],[24,[\"selectedRecords\"]],[24,[\"deleteConfirmation\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n      \"],[1,[28,\"buttons/add-button\",null,[[\"text\",\"action\",\"value\"],[[28,\"t\",[\"buttons.create\"],null],[28,\"route-action\",[\"save\",[28,\"hash\",null,[[\"redirectTo\",\"loadData\",\"afterSave\"],[\"tasks.work-logs\",true,[28,\"route-action\",[\"afterSave\"],null]]]]],null],[24,[\"model\"]]]]],false],[0,\"\\n      \"],[1,[28,\"buttons/cancel-button\",null,[[\"action\",\"value\"],[[28,\"route-action\",[\"transitionTo\",\"tasks.work-logs\"],null],[24,[\"model\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/tasks/work-logs/new.hbs"
    }
  });

  _exports.default = _default;
});