define("iris/templates/aiops/source-data/customers/severance-pay-types/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "euurI6fD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"isLoaded\"]]],null,{\"statements\":[[0,\"  \"],[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@proportion\",\"@frame\",\"@needClose\"],[[23,0,[\"model\"]],\"aiops.source-data.customers.severance-pay-types\",\"2-10\",true,true]],{\"statements\":[[0,\"\\n      \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"active\"]]],[0,\"\\n      \"],[5,\"translation\",[],[[\"@proportion\",\"@model\",\"@property\"],[\"2-10\",[23,0,[\"model\"]],\"name\"]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/aiops/source-data/customers/severance-pay-types/edit.hbs"
    }
  });

  _exports.default = _default;
});