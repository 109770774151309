define("iris/components/change-requests/edit/tasks/affected-entities/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZRQ7k6Gd",
    "block": "{\"symbols\":[\"entity\",\"&attrs\",\"@entities\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"flex flex-wrap gap-4\"],[13,2],[8],[0,\"\\n\"],[4,\"each\",[[23,3,[]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[\"\\n        px-3 rounded border border-gray-400\\n        text-gray-700 bg-gray-200\\n        dark:text-gray-200 dark:bg-gray-800 dark:border-gray-600\\n        whitespace-nowrap\\n        \",[28,\"compute\",[[28,\"action\",[[23,0,[]],\"wichCIType\",[23,1,[\"entity_type_category_id\"]]],null]],null],\"\\n      \"]]],[8],[0,\"\\n      \"],[1,[23,1,[\"entity_name\"]],false],[0,\"\\n      (\"],[1,[23,1,[\"entity_type_name\"]],false],[0,\")\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/change-requests/edit/tasks/affected-entities/list/template.hbs"
    }
  });

  _exports.default = _default;
});