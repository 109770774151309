define("iris/templates/settings/cmdb/code-stores/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1yneip9i",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"isLoaded\"]]],null,{\"statements\":[[0,\"  \"],[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@frame\"],[[23,0,[\"model\"]],\"settings.cmdb.code-stores\",true]],{\"statements\":[[0,\"\\n    \"],[5,\"auto/form/field\",[[12,\"class\",\"mb-5\"]],[[\"@model\",\"@property\",\"@proportion\"],[[23,0,[\"model\"]],\"name\",\"1-4\"]]],[0,\"\\n    \"],[5,\"auto/form/one2-many\",[],[[\"@parentModel\",\"@itemName\",\"@relationFiled\",\"@neededFields\"],[[23,0,[\"model\"]],\"entities\",\"entityTypeId\",[28,\"array\",[\"name\"],null]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/cmdb/code-stores/edit.hbs"
    }
  });

  _exports.default = _default;
});