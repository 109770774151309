define("iris/routes/organizations/sites/buildings/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BuildingEditRoute extends Ember.Route {
    model(params) {
      if (params.building_id == 'new') {
        return this.store.createRecord('building');
      }

      return this.store.findRecord('building', params.building_id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = BuildingEditRoute;
});