define("iris/templates/organizations/sites/locations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5GqQj6K2",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\"],[\"location\",\"organizations.sites.locations.edit\",[28,\"array\",[\"name\",\"clientPartner\",\"address\",\"building\",\"floor\"],null]]]],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/organizations/sites/locations.hbs"
    }
  });

  _exports.default = _default;
});