define("iris/components/store/client/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    storage: null,
    operators: {
      'greater-than': function (a, b) {
        return a > b;
      },
      'less-than': function (a, b) {
        return a < b;
      },
      'not-null': function (a) {
        return !Ember.isEmpty(a);
      },
      'null': function (a) {
        Ember.isEmpty(a);
      },
      'equal': function (a, b) {
        return a == b;
      },
      'contains': function (a, b) {
        return a.toLowerCase().indexOf(b.toLowerCase()) > -1;
      }
    },
    filteredItems: Ember.computed('items', 'storage.params.filters', 'storage.params.q', function () {
      let self = this;
      let {
        filters,
        q
      } = this.storage.params;
      let value, valuePath;
      let filterObject = {};

      if (filters) {
        for (const f of filters) {
          if (!(f.name in filterObject)) {
            filterObject[f.name] = [];
          } // belongsTo filed


          if (typeof f.value === 'object' && f.value !== null) {
            value = f.value.name;
            valuePath = `${f.name}.name`;
          } else {
            value = f.value;
            valuePath = f.name;
          }

          filterObject[f.name].push({
            value,
            valuePath,
            type: f.type
          });
        }
      }

      if (Object.entries(filterObject).length === 0) {
        return this.items;
      } else {
        return this.items.filter(row => {
          let states = [];

          for (const name in filterObject) {
            let state = false;

            for (const filter of filterObject[name]) {
              if (this.operators[filter.type](row.get(filter.valuePath), filter.value)) {
                state = true;
              }
            }

            states.push(state);
          }

          return states.every(function (element) {
            return element;
          });
        });
      }
    }),
    sortBy: Ember.computed('storage.params.order', function () {
      let order = this.storage.params.order;
      return [`${order.property}:${order.direction}`];
    }),
    sortedItems: Ember.computed.sort('filteredItems', 'sortBy'),
    result: Ember.computed('sortedItems', 'storage.params.limit', 'storage.params.page', function () {
      let {
        limit,
        page
      } = this.storage.params;
      let start = (page - 1) * limit;
      let end = start + limit;
      return this.sortedItems.slice(start, end);
    }),
    total: Ember.computed('filteredItems', function () {
      return this.filteredItems.length;
    })
  });

  _exports.default = _default;
});