define("iris/templates/settings/cmdb/code-stores", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E2VQPPdD",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@translationModel\",\"@defaultFilters\",\"@defaultOrder\",\"@neededFields\"],[\"entity-type\",\"settings.cmdb.code-stores.edit\",\"code-store\",[28,\"hash\",null,[[\"codeStore\"],[1]]],\"createDate DESC\",[28,\"array\",[\"name\"],null]]]],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/cmdb/code-stores.hbs"
    }
  });

  _exports.default = _default;
});