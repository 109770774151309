define("iris/routes/settings/project/file-business-types/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FileBusinessTypeEditRoute extends Ember.Route {
    model(params) {
      if (params.id == 'new') {
        return this.store.createRecord('fileBusinessType');
      }

      return this.store.findRecord('fileBusinessType', params.id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = FileBusinessTypeEditRoute;
});