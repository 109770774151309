define("iris/services/api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    adapter: Ember.computed(function () {
      return Ember.getOwner(this).lookup('adapter:application');
    }),

    queryView(endpointName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let baseURL = this.adapter.buildURL();
      let url = `${baseURL}/${stripLeadingSlash(endpointName)}`;
      return this.adapter.ajax(url, 'GET', {
        data: params
      });
    },

    query() {
      return this.store.query(...arguments);
    },

    where(modelName, conditions) {
      return this.query(modelName, {
        filter: {
          where: conditions
        }
      });
    },

    find() {
      return this.store.find(...arguments);
    },

    findAll() {
      return this.store.findAll(...arguments);
    },

    /**
     * Example:
     *
     * ```js
     * this.api.memberAction('partner', 'revalidate', '486', {
     *   method: 'POST',
     *   data: { foo: 1 },
     * });
     * ```
     *
     * Will make the following request:
     *
     * ```http
     * POST /api/partners/486/revalidate HTTP/1.1
     *
     * { foo: 1 }
     * ```
     */
    memberAction(modelName, action, id) {
      let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      return this._makeActionRequest(modelName, action, id, options);
    },

    /**
     * Example:
     *
     * ```js
     * this.api.memberAction('partner', 'revalidate');
     * ```
     *
     * Will make the following request:
     *
     * ```http
     * GET /api/partners/revalidate
     * ```
     */
    collectionAction(modelName, action) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this._makeActionRequest(modelName, action, null, options);
    },

    _makeActionRequest(modelName, action, id) {
      let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      let _options = {
        method: 'GET',
        authorize: true,
        data: {},
        ...options
      };

      let adapter = this._getAdapter(modelName, {
        authorize: _options.authorize
      });

      let url = `${adapter.buildURL(modelName, id)}`;
      url = action ? `${url}/${action}` : url;
      let adapterOptions = {};

      if (Object.keys(_options.data).length !== 0) {
        adapterOptions.data = _options.data;
      }

      return adapter.ajax(url, _options.method, adapterOptions);
    },

    _getAdapter(_modelName, options) {
      let adapterName = options.authorize ? 'application' : 'unauth';
      return Ember.getOwner(this).lookup(`adapter:${adapterName}`);
    }

  });

  _exports.default = _default;

  const stripLeadingSlash = str => str.replace(/^\//, '');
});