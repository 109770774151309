define("iris/helpers/string-split", ["exports", "ember-string-fns/helpers/string-split"], function (_exports, _stringSplit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _stringSplit.default;
    }
  });
  Object.defineProperty(_exports, "stringSplit", {
    enumerable: true,
    get: function () {
      return _stringSplit.stringSplit;
    }
  });
});