define("iris/components/duty/calendar/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let ctx = {};

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    modals: Ember.inject.service(),
    loading: Ember.inject.service(),
    intl: Ember.inject.service(),
    api: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    filterOn: false,
    showFilter: false,

    async init() {
      this._super(...arguments);

      ctx = this;
      let publicHolidays = await this.get('store').query('publicHoliday', {
        filter: {
          order: 'date'
        }
      });
      this.set('publicHolidays', publicHolidays);
      this.refreshEvents();
    },

    publicHolidays: Ember.A(),
    calendarEvents: Ember.computed('events.[]', function () {
      return toCalendarSavedEvent(this.events);
    }),
    events: null,
    header: {
      left: 'title',
      right: 'customToday month,listWeek customPrev,customNext'
    },
    buttonText: Ember.computed(function () {
      return {
        month: this.intl.t('components.fullcalendar.month'),
        listWeek: this.intl.t('components.fullcalendar.listWeek'),
        agendaWeek: this.intl.t('components.fullcalendar.week'),
        day: this.intl.t('components.fullcalendar.day')
      };
    }),
    customButtons: Ember.computed(function () {
      return {
        customToday: {
          text: this.intl.t('components.fullcalendar.today'),
          click: function () {
            $('.full-calendar').fullCalendar('today');
            ctx.refreshEvents();
          }
        },
        customNext: {
          text: '>',
          click: function () {
            $('.full-calendar').fullCalendar('next');
            ctx.refreshEvents();
          }
        },
        customPrev: {
          text: '<',
          click: function () {
            $('.full-calendar').fullCalendar('prev');
            ctx.refreshEvents();
          }
        }
      };
    }),
    dayRender: function (date, cell) {
      let publicHolidays = ctx.get('publicHolidays');
      let cellDay = (0, _moment.default)(date).subtract(1, 'd');

      if ((0, _moment.default)(date).day() === 0 || (0, _moment.default)(date).day() === 6) {
        cell.addClass('weekend');
      }

      for (let i = 0; i < publicHolidays.length; i++) {
        let date = publicHolidays.objectAt(i).get('date').toISOString();

        if (cellDay.isSame((0, _moment.default)(date), 'day')) {
          if (!publicHolidays.objectAt(i).get('isWorkDay')) {
            cell.addClass('work-day');
            return;
          } else {
            cell.addClass('holiday');
          }
        }
      }
    },
    queryEvents: async (api, start, end) => {
      const events = await api.collectionAction('duty', 'get-duty-days', {
        method: 'GET',
        data: {
          start,
          end
        }
      });
      return events;
    },

    async refreshEvents() {
      const start = $('.full-calendar').fullCalendar('getView').start.format('YYYY.MM.DD');
      const end = $('.full-calendar').fullCalendar('getView').end.format('YYYY.MM.DD');
      this.loading.run(async () => {
        await new Promise(r => Ember.run.later(this, r, 1000));
        this.set('events', await this.queryEvents(this.api, start, end));
      });
    },

    actions: {
      eventLimitClick(cellInfo, jsEvent, view) {
        $('.full-calendar').fullCalendar('getCalendar').changeView('listWeek', JSON.stringify(cellInfo.date));
      }

    }
  });

  _exports.default = _default;

  function parseDateWithoutTimezone(d) {
    d.setDate(d.getDate() - 1);
    return d;
  }

  function toCalendarSavedEvent(events) {
    if (!events) {
      return;
    }

    return events.map(event => {
      let time = _moment.default.duration(event.shift_change, 'h').format('HH:mm', {
        trim: false
      });

      let end = event.plannedEndDate;
      return {
        title: `${time} ${event.user_name} (${event.solver_group_name})`,
        start: (0, _moment.default)(event.date).format('YYYY-MM-DD'),
        end: (0, _moment.default)(event.date).add(1, 'd').format('YYYY-MM-DD'),
        color: event.color || '#FF000D'
      };
    }).flat();
  }
});