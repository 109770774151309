define("iris/helpers/string-ends-with", ["exports", "ember-string-fns/helpers/string-ends-with"], function (_exports, _stringEndsWith) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _stringEndsWith.default;
    }
  });
  Object.defineProperty(_exports, "stringEndsWith", {
    enumerable: true,
    get: function () {
      return _stringEndsWith.stringEndsWith;
    }
  });
});