define("iris/components/action-competency-contract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selected: Ember.computed('model.contractActionCompetencyAssocs.[]', 'action', {
      get() {
        let res = Ember.A();
        let id = this.get('action.id');

        let _this = this;

        this.get('model.contractActionCompetencyAssocs').forEach(contractCompetency => {
          contractCompetency.get('action').then(action => {
            if (action.get('id') === id) {
              res.addObject(contractCompetency.get('competency'));

              _this.set('selected', res);
            }
          });
        });
        return res;
      },

      set(key, value) {
        return value;
      }

    })
  });

  _exports.default = _default;
});