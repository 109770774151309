define("iris/templates/components/forms/new-timeoff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qKk53Tgh",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"bs-form\",null,[[\"model\"],[[24,[\"model\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"form-group\",null,[[\"property\",\"label\"],[\"subject\",[28,\"t\",[\"forms.reasoning\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"newTimeOff\",\"subject\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n\"],[4,\"form-group\",null,[[\"label\",\"proportion\"],[[28,\"t\",[\"general.beginning\"],null],\"4-8\"]],{\"statements\":[[0,\"        \"],[1,[28,\"bs-datetimepicker\",null,[[\"date\",\"timePicker\"],[[24,[\"model\",\"newTimeOff\",\"plannedStartDate\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n\"],[4,\"form-group\",null,[[\"label\"],[[28,\"t\",[\"general.completion\"],null]]],{\"statements\":[[0,\"        \"],[1,[28,\"bs-datetimepicker\",null,[[\"date\",\"timePicker\"],[[24,[\"model\",\"newTimeOff\",\"plannedEndDate\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"form-group\",null,[[\"property\",\"label\"],[\"resolver\",[28,\"t\",[\"forms.prfapproval\"],null]]],{\"statements\":[[4,\"iris-power-select\",null,[[\"options\",\"selected\",\"onchange\"],[[24,[\"users\"]],[24,[\"model\",\"newTimeOff\",\"resolver\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"newTimeOff\",\"resolver\"]]],null]],null]]],{\"statements\":[[0,\"      \"],[1,[23,1,[\"longName\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/new-timeoff.hbs"
    }
  });

  _exports.default = _default;
});