define("iris/controllers/cmdb/entity-types/entities/entity/reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    base64: null,
    open: false,
    ajax: Ember.inject.service(),
    actions: {
      async viewPdf(file) {
        this.set('open', true);
        let base64 = await this.get('ajax').call('GET', 'entity', '', this.get('entity.id') + '/view-pdf/' + file);
        this.set('base64', base64);
      },

      close() {
        this.set('open', false);
        this.set('base64', null);
      }

    }
  });

  _exports.default = _default;
});