define("iris/components/forms/contract-values", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    valuePeriods: Ember.computed(function () {
      return [{
        id: 0,
        name: this.intl.t('time.month')
      }, {
        id: 1,
        name: this.intl.t('time.quarterOfYear')
      }, {
        id: 2,
        name: this.intl.t('time.year')
      }];
    }),
    valuePeriod: Ember.computed('model.valuePeriod', 'valuePeriods', {
      get(key) {
        let valuePeriods = this.get('valuePeriods');
        let valuePeriod = this.get('model.valuePeriod');
        return valuePeriods[valuePeriod];
      },

      set(key, value) {
        Ember.run.next(() => {
          if (!this.get('isDestroyed') && !this.get('isDestroying')) {
            // TODO: backtracking-rerender
            this.set('model.valuePeriod', value.id);
          }
        });
        return value;
      }

    })
  });

  _exports.default = _default;
});