define("iris/components/project-invoice", ["exports", "iris/lib/filters/types"], function (_exports, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    showModal: false,
    base64: null,
    _columns: {
      brutto: {
        sortable: false,
        visibility: _types.AttributeVisibility.Table
      },
      info: {
        sortable: false,
        visibility: _types.AttributeVisibility.Table
      },
      balance: {
        sortable: false,
        visibility: _types.AttributeVisibility.Table
      },
      paidDays: {
        sortable: false,
        visibility: _types.AttributeVisibility.Table
      }
    },
    actions: {
      showInvoices() {
        this.set('showModal', true);
      },

      close() {
        this.set('showModal', false);
      },

      async showInvoice(invoice) {
        const base64 = await this.get('ajax').call('GET', 'invoice', '', 'pdf-view/' + invoice.id);
        this.set('base64', base64.$data);
      },

      backToList() {
        this.set('base64', null);
      }

    }
  });

  _exports.default = _default;
});