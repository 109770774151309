define("iris/components/tandm/info/cumulables/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LFZ+vNfB",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"grid grid-cols-2 gap-4 mt-16\"],[8],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[5,\"chart-js\",[],[[\"@data\",\"@type\",\"@options\"],[[23,0,[\"chart\"]],\"doughnut\",[23,0,[\"options\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex border-b-4 rounded-t-lg justify-end gap-4 border-double text-4xl py-4\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"flex-1 italic\"],[8],[1,[28,\"t\",[\"time.month\"],null],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"font-bold\"],[8],[5,\"fa-icon\",[],[[\"@icon\"],[\"clock-o\"]]],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"each\",[[23,0,[\"list\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[\"flex border-b-4 justify-end gap-4 border-dotted text-4xl py-4\\n          \",[28,\"if\",[[28,\"not\",[[23,1,[\"color\"]]],null],\"text-muted line-through opacity-50\"],null]]]],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"flex-1 italic\"],[8],[0,\"\\n          \"],[1,[23,1,[\"month\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"font-bold\"],[8],[1,[23,1,[\"balance\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/tandm/info/cumulables/template.hbs"
    }
  });

  _exports.default = _default;
});