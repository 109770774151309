define("iris/components/form/field/label/styled/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uMOMEHZs",
    "block": "{\"symbols\":[\"StyledLabel\",\"@name\",\"@hasError\",\"&attrs\",\"&default\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"form/field/label\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[[12,\"class\",[29,[\"\\n    text-gray-700 dark:text-gray-100\\n      \",[28,\"if\",[[23,3,[]],\"text-scarlet-600 dark:text-scarlet-600\"],null],\"\\n    \"]]],[13,4]],[[\"@name\"],[[23,2,[]]]],{\"statements\":[[0,\"\\n    \"],[14,5],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/form/field/label/styled/template.hbs"
    }
  });

  _exports.default = _default;
});