define("iris/components/block-slot", ["exports", "@salsify/ember-block-slots/components/block-slot"], function (_exports, _blockSlot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _blockSlot.default;
    }
  });
});