define("iris/templates/settings/project/project-ticket-templates/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4p4B8hXu",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@proportion\",\"@needClose\",\"@componentLayout\"],[[23,0,[\"model\"]],\"settings.project.project-ticket-templates\",\"2-10\",true,\"col-2\"]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/project/project-ticket-templates/edit.hbs"
    }
  });

  _exports.default = _default;
});