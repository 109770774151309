define("iris/routes/operation/portal-dashboard", ["exports", "iris/lib/filters", "iris/lib/filters/types", "iris/models/ticket-state"], function (_exports, _filters, _types, _ticketState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tabs = _exports.default = void 0;

  var _default = Ember.Route.extend();

  _exports.default = _default;
  const tabs = [{
    name: 'Előbejelentések',
    ticketStateIds: [{
      id: _ticketState.TICKET_STATE_ID.PreReport,
      name: 'Előbejelentés'
    }]
  }, {
    name: 'Megoldás alatt',
    ticketStateIds: [{
      id: _ticketState.TICKET_STATE_ID.Unresolved,
      name: 'Elintézetlen'
    }, {
      id: _ticketState.TICKET_STATE_ID.InProgress,
      name: 'Folyamatban'
    }, {
      id: _ticketState.TICKET_STATE_ID.WaitingForReporter,
      name: 'Bejelentőre vár'
    }, {
      id: _ticketState.TICKET_STATE_ID.WaitingForThirdParty,
      name: 'Harmadik félre vár'
    }]
  }, {
    name: 'Megoldott jegyek',
    ticketStateIds: [{
      id: _ticketState.TICKET_STATE_ID.WaitingForRelease,
      name: 'Kiadásra vár'
    }, {
      id: _ticketState.TICKET_STATE_ID.Resolved,
      name: 'Megoldott'
    }, {
      id: _ticketState.TICKET_STATE_ID.ImmediatelyResolved,
      name: 'Azonnal megoldott'
    }, {
      id: _ticketState.TICKET_STATE_ID.Rejected,
      name: 'Elutasított'
    }]
  }, {
    name: 'Lezárt jegyek',
    ticketStateIds: [{
      id: _ticketState.TICKET_STATE_ID.Closed,
      name: 'Lezárt'
    }]
  }].map(_ref => {
    let {
      name,
      ticketStateIds
    } = _ref;
    let filters = ticketStateIds.map(_ref2 => {
      let {
        id,
        name
      } = _ref2;
      let column = (0, _filters.makeColumn)({
        displayName: 'Ticket Állapot',
        name: 'ticketStateId'
      });
      return (0, _filters.makeFilterFromColumn)(column, {
        filterType: _types.FilterType.Equal,
        value: {
          id,
          name
        }
      });
    });
    let queryParamFilters = (0, _filters.serializeToQueryParam)(filters);
    return {
      name,
      filters,
      queryParams: {
        filters: queryParamFilters
      }
    };
  });
  _exports.tabs = tabs;
});