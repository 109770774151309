define("iris/models/ticket-intervention-grid", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    col1: (0, _emberCpValidations.validator)('presence', {
      presence: true
    })
  });

  var _default = _model.default.extend(Validations, {
    ajax: Ember.inject.service(),
    col1: (0, _attr.default)('string'),
    col2: (0, _attr.default)('string'),
    col3: (0, _attr.default)('string'),
    type: (0, _attr.default)('number'),
    ticket: (0, _relationships.belongsTo)('ticket'),
    ticketInterventionTime: (0, _relationships.belongsTo)('ticketInterventionTime'),
    entities: (0, _relationships.hasMany)('entity'),
    entitieIds: Ember.computed.mapBy('entities', 'id'),
    affectedEntities: Ember.computed('entities.[]', 'entitieIds', {
      get() {
        let entitieIds = this.get('entitieIds');

        if (entitieIds.length) {
          this.get('ajax').call('GET', 'entity', entitieIds.join("','"), 'affected-entities', {}).then(result => {
            if ('result' in result) {
              this.set('affectedEntities', Ember.A(result.result));
            }
          });
        }
      },

      set(key, value) {
        return value;
      }

    })
  });

  _exports.default = _default;
});