define("iris/components/one-way-number-mask", ["exports", "ember-inputmask/components/one-way-number-mask"], function (_exports, _oneWayNumberMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oneWayNumberMask.default.extend({
    classNames: ['form-control']
  });

  _exports.default = _default;
});