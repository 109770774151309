define("iris/controllers/tasks/delegates", ["exports", "iris/controllers/tasks/plans"], function (_exports, _plans) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _plans.default.extend({
    list: true,
    delegated: true,
    serviceMyTickets: Ember.inject.service(),
    getMyTickets: Ember.observer('loggedInUser.id', function () {
      let id = this.get('loggedInUser.id');

      if (Ember.isNone(id)) {
        return;
      }

      this.set('refresh', true);
      this.get('ajax').call('GET', 'account', id, 'delegatedTickets', {}).then(result => {
        this.set('myTickets', Ember.A(result));
        this.set('serviceMyTickets.data', result);
        this.set('refresh', false);
      });
    })
  });

  _exports.default = _default;
});