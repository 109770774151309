define("iris/routes/unauth/confirmation/access-request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),

    model(params) {
      return this.store.findRecord('ticket', params.ticket_id);
    },

    setupController(controller, model) {
      this._super(controller, model);

      if (model) {
        this.controller.set('message', '');
      }
    },

    actions: {
      confirmation(ticket, value) {
        let msg = this.controller.get('message');
        const flashMessages = Ember.get(this, 'flashMessages');

        if (msg.length > 300) {
          flashMessages.add({
            message: this.intl.t('unauth.messageMaxChar'),
            title: 'IRIS message',
            icon: 'info'
          });
          return;
        }

        this.store.findRecord('ticketState', 'SYS_13').then(ticketState => {
          ticket.set('state', ticketState);
          ticket.save(true).then(res => {
            this.controller.set('confirmed', true);
          }).catch(err => {
            flashMessages.add({
              message: err,
              title: 'IRIS message',
              icon: 'info'
            });
          });
        });
      }

    }
  });

  _exports.default = _default;
});