define("iris/templates/settings/user-grades", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4HKPKfbv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"main-content\"],[8],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel panel-default\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n        \"],[1,[28,\"tables/acl-user-grades\",null,[[\"model\",\"editUserGradePath\",\"newUserGradePath\"],[[24,[\"model\"]],\"settings.user-grades.edit\",\"settings.user-grades.new\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/user-grades.hbs"
    }
  });

  _exports.default = _default;
});