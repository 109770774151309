define("iris/utils/ember-concurrency", ["exports", "ember-concurrency", "iris/config/environment", "iris/utils/properties"], function (_exports, _emberConcurrency, _environment, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timeout = _exports.taskValue = _exports.taskTrigger = void 0;
  const isTesting = _environment.default.environment === 'test';

  const timeout = ms => isTesting ? (0, _emberConcurrency.timeout)(0) : (0, _emberConcurrency.timeout)(ms);
  /**
   * Sets up an observer (`observerOnce`) to trigger the task of the name
   * `taskPropertyName` when any of the `dependentKeys` change. It'll fire right
   * away on init, otherwise the task wouldn't have value until one of the
   * dependent keys change.
   */


  _exports.timeout = timeout;

  const taskTrigger = function (taskPropertyName) {
    let dependentKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    return (0, _properties.observerOnce)(...dependentKeys, function () {
      this.get(taskPropertyName).perform();
    }).on('init');
  };
  /**
   * A shorthand for a task's last successful value.
   */


  _exports.taskTrigger = taskTrigger;

  const taskValue = function (taskPropertyName) {
    return Ember.computed.alias(`${taskPropertyName}.lastSuccessful.value`);
  };

  _exports.taskValue = taskValue;
});