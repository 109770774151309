define("iris/templates/components/forms/acl-user-employment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "03NP4MRO",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-12 col-md-6\"],[8],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@proportion\",\"@property\"],[[23,1,[]],\"4-8\",\"leader\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@proportion\",\"@property\"],[[23,1,[]],\"4-8\",\"employmentStartDate\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@proportion\",\"@property\"],[[23,1,[]],\"4-8\",\"employmentEndDate\"]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-12 col-md-6\"],[8],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@proportion\",\"@property\"],[[23,1,[]],\"4-8\",\"yearlyHolidays\"]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@proportion\",\"@property\"],[[23,1,[]],\"4-8\",\"subcontractor\"]]],[0,\"\\n\"],[4,\"if\",[[23,1,[\"subcontractor\"]]],null,{\"statements\":[[0,\"      \"],[5,\"auto/form/field\",[],[[\"@model\",\"@proportion\",\"@property\"],[[23,1,[]],\"4-8\",\"monthlyAllocatedWorkTime\"]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"form-group\",[],[[\"@property\",\"@label\",\"@proportion\"],[\"dailyAllocatedWorkTime\",[28,\"t\",[\"account.dailyAllocatedWorkTime\"],null],\"4-8\"]],{\"statements\":[[0,\"\\n        \"],[5,\"one-way-number-mask\",[],[[\"@decimal\",\"@value\",\"@options\",\"@update\"],[true,[23,1,[\"dailyAllocatedWorkTime\"]],[28,\"hash\",null,[[\"allowMinus\",\"min\",\"max\",\"groupSeparator\",\"radixPoint\",\"digits\"],[false,0,24,\" \",\".\",2]]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,1,[\"dailyAllocatedWorkTime\"]]],null]],null]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/acl-user-employment.hbs"
    }
  });

  _exports.default = _default;
});