define("iris/services/disable-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    disabled: false,

    setDisabled(value) {
      this.set('disabled', value);
    }

  });

  _exports.default = _default;
});