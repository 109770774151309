define("iris/components/power-select-related", ["exports", "iris/utils/properties"], function (_exports, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selected: null,

    init() {
      this._super(...arguments);

      this.clearSelectedWihtoutRelatedId();
    },

    relatedIdDidChange: (0, _properties.observerOnce)('related.id', function () {
      this.clearSelectedWihtoutRelatedId();
    }),

    clearSelectedWihtoutRelatedId() {
      if (!this.get('related.id')) {
        this.set('selected', null);
      }
    },

    computedOptions: Ember.computed('options.[]', 'related.id', function () {
      let relatedId = this.get('related.id');
      let foreginKey = this.get('foreginKey');
      let options = this.get('options') || Ember.A();

      if (!relatedId) {
        return options;
      }

      return options.filter(option => {
        if (Ember.typeOf(option) === 'instance') {
          return option.get(foreginKey) === relatedId;
        } else {
          return option[foreginKey] === relatedId;
        }
      });
    })
  });

  _exports.default = _default;
});