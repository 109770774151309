define("iris/lib/filters/column", ["exports", "iris/lib/filters/data", "iris/lib/filters/filter", "iris/lib/filters/types", "iris/lib/filters/utils"], function (_exports, _data, _filter, _types, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ColumnWidth = void 0;
  _exports.columns = columns;
  _exports.makeColumns = _exports.makeColumn = _exports.lookupColumn = _exports.filterByVisiblity = void 0;

  /**
   * Defines the column configuration.
   *
   * Ensures that all functions defined inside the `columns` function callback
   * will have the correct `this` value. While this would normally be easy with
   * using arrow functions, this API provides compatibility when certain (future)
   * functions will require to not to have this behavior.
   *
   * Example:
   *
   * ```js
   * @columns(function () {
   *   return [
   *     {
   *       name: 'entityType',
   *       displayName: 'CI típus neve',
   *       visibility: AttributeVisibility.Filter,
   *     },
   *     {
   *       name: 'entityTypeId',
   *       displayName: 'CI típus',
   *       type: AttributeType.BelongsTo,
   *       visibility: AttributeVisibility.Filter,
   *       belongsTo: [{ categoryId: 'entityTypeCategoryId' }],
   *       createOptions: async (records, nameKey, defaultMapper) => {
   *         let etcs = await this.api.findAll('entityTypeCategory');
   *         ✂️...
   *       },
   *     },
   *   ];
   * })
   * columns;
   * ```
   *
   * Classic syntax example:
   *
   * ```js
   * columns: columns(function () {
   *   return [
   *     {
   *       name: 'entityType',
   *       displayName: 'CI típus neve',
   *       visibility: AttributeVisibility.Filter,
   *     },
   *     {
   *       name: 'entityTypeId',
   *       displayName: 'CI típus',
   *       type: AttributeType.BelongsTo,
   *       visibility: AttributeVisibility.Filter,
   *       belongsTo: [{ categoryId: 'entityTypeCategoryId' }],
   *       createOptions: async (records, nameKey, defaultMapper) => {
   *         let etcs = await this.api.findAll('entityTypeCategory');
   *         ✂️...
   *       },
   *     },
   *   ];
   * })
   * ```
   */
  // eslint-disable-next-line space-before-function-paren
  function columns() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    let {
      fn,
      dependentKeys
    } = (0, _utils.parseComputedArgs)(args);
    return Ember.computed(...dependentKeys, function () {
      return makeColumns(this, fn.call(this));
    });
  }
  /**
   * Shorthand for making columns using `makeColumn`, removing the empty ones, and
   * binding the the column's functions to the context the object they were
   * created on.
   */


  const makeColumns = function (context, columns) {
    return columns.filter(Boolean).map(cd => {
      let column = makeColumn(cd);

      for (let [key, value] of Object.entries(column)) {
        if (typeof value === 'function') {
          // @ts-ignore
          column[key] = value.bind(context);
        }
      }

      return column;
    });
  };
  /**
   * Makes columns by providing a `ColumnDefinition` object. It'll perform all the
   * initialization and will set default values for the fields required to use
   * them as filters or table columns.
   */


  _exports.makeColumns = makeColumns;

  const makeColumn = definition => {
    let {
      name,
      type
    } = definition;
    let attributeType = type !== null && type !== void 0 ? type : _types.AttributeType.String;
    let {
      formatDisplayValue
    } = (0, _filter.findFilterDefinitionBy)({
      attributeType
    });
    let fuzzySearch = attributeType === _types.AttributeType.String;
    let defaults = {
      displayName: name,
      valuePath: name,
      sortable: true,
      type: attributeType,
      fuzzySearch,
      orderProperty: name,
      defaultDirection: _types.Direction.Asc,
      visibility: _types.AttributeVisibility.Both,
      options: null,
      optionsResourceName: name.replace(/Id$/, ''),
      optionsResourceAction: _types.OptionsResourceAction.FindAll,
      optionsNameKey: _data.DEFAULT_OPTIONS_NAME_KEY,
      formatDisplayValue,
      inputComponentName: null,
      filterTypes: []
    };
    return { ...defaults,
      ...definition
    };
  };
  /**
   * Tries to find a column by name. If it can't it'll throw an error. If it can't
   * find a column, because for ex. it had been removed but a bookmarked URL still
   * contains column config referencing non-existent column name(s), it won't
   * break but will log an error instead an return `null`, allowing callers to
   * handle such cases.
   */


  _exports.makeColumn = makeColumn;

  const lookupColumn = (columns, name) => {
    let column = columns.find(column => column.name === name);

    if (!column) {
      console.error(`Cannot find column with name: ${name}`);
    }

    return column !== null && column !== void 0 ? column : null;
  };
  /**
   * Returns the columns that's `visibility` property matches the one provided, or
   * has the `Both` visibility (this should be the default for columns).
   */


  _exports.lookupColumn = lookupColumn;

  const filterByVisiblity = (columns, visibilities) => {
    return columns.filter(col => (typeof visibilities === 'string' ? col.visibility === visibilities : visibilities.includes(col.visibility)) || col.visibility === _types.AttributeVisibility.Both);
  }; // Hand enum for avoiding to having to remember exact initial column widths.
  // Note that since the current consumer is Ember Table, the actual value when
  // renderd depends on the width of the viewport. However, it's still an OK
  // reference.


  _exports.filterByVisiblity = filterByVisiblity;
  let ColumnWidth;
  _exports.ColumnWidth = ColumnWidth;

  (function (ColumnWidth) {
    ColumnWidth[ColumnWidth["XXS"] = 25] = "XXS";
    ColumnWidth[ColumnWidth["XS"] = 50] = "XS";
    ColumnWidth[ColumnWidth["S"] = 100] = "S";
    ColumnWidth[ColumnWidth["LS"] = 125] = "LS";
    ColumnWidth[ColumnWidth["SM"] = 150] = "SM";
    ColumnWidth[ColumnWidth["LSM"] = 175] = "LSM";
    ColumnWidth[ColumnWidth["M"] = 200] = "M";
    ColumnWidth[ColumnWidth["L"] = 300] = "L";
    ColumnWidth[ColumnWidth["XL"] = 500] = "XL";
  })(ColumnWidth || (_exports.ColumnWidth = ColumnWidth = {}));
});