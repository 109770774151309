define("iris/components/forms/i2-notification-contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super();

      if (this.get('contact.states')) {
        this.set('modelStates', this.get('contact.states').split(","));
      }

      if (this.get('contact.types')) {
        this.set('modelTypes', this.get('contact.types').split(","));
      }
    },
    states: ['OK', 'Warning', 'Critical', 'Unknown', 'Up', 'Down'],
    types: ['DowntimeStart', 'DowntimeEnd', 'DowntimeRemoved', 'Custom', 'Acknowledgement', 'Problem', 'Recovery', 'FlappingStart', 'FlappingEnd'],
    modelStates: Ember.A(),
    observerStates: Ember.observer('modelStates', function () {
      this.set('contact.states', this.get('modelStates').join(","));
    }),
    modelTypes: Ember.A(),
    observerTypes: Ember.observer('modelTypes', function () {
      this.set('contact.types', this.get('modelTypes').join(","));
    }),
    newNotificationGroupBox: false,
    actions: {
      newNotificationGroup: function () {
        let notificationGroup = this.get('store').createRecord('notificationGroup');
        this.set('notificationGroup', notificationGroup);
        this.toggleProperty('newNotificationGroupBox');
      },

      async closeNotificationGroupModal(hash) {
        this.set('newNotificationGroupBox', hash.rollback);

        if (hash.rollback) {
          this.notificationGroup.deleteRecord();
        }
      }

    }
  });

  _exports.default = _default;
});