define("iris/templates/components/forms/acl-user-grade-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MleE/Sxq",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-form\",null,[[\"model\",\"proportion\"],[[24,[\"model\"]],\"3-9\"]],{\"statements\":[[4,\"form-group\",null,[[\"property\",\"label\"],[\"code\",[28,\"t\",[\"forms.code\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"code\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\"],[\"name\",[28,\"t\",[\"user.position\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"name\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\"],[\"cost\",[28,\"t\",[\"forms.recommended-hourly-rate\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"cost\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/acl-user-grade-form.hbs"
    }
  });

  _exports.default = _default;
});