define("iris/models/partner-bankcode", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    bankname: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      descriptionKey: 'bank-name'
    }), (0, _emberCpValidations.validator)('length', {
      max: 50
    })],
    bankcode: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      descriptionKey: 'bank-account'
    }), (0, _emberCpValidations.validator)('length', {
      max: 50
    })],
    swift: (0, _emberCpValidations.validator)('length', {
      max: 11
    }),
    iban: (0, _emberCpValidations.validator)('length', {
      max: 50
    }),
    currencyPayment: [(0, _emberCpValidations.validator)('belongs-to'), (0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _model.default.extend(Validations, {
    bankname: (0, _attr.default)('string', {
      required: true
    }),
    bankcode: (0, _attr.default)('string', {
      required: true
    }),
    swift: (0, _attr.default)('string', {
      required: true
    }),
    iban: (0, _attr.default)('string', {
      required: true
    }),
    currencyPayment: (0, _relationships.belongsTo)('currency', {
      required: true
    }),
    clientPartner: (0, _relationships.belongsTo)('partner'),
    defaultBank: (0, _attr.default)('boolean', {
      defaultValue: true,
      required: true
    })
  });

  _exports.default = _default;
});