define("iris/utils/vat-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.vatTypes = void 0;
  const vatTypes = Ember.A([{
    id: 1,
    color: 'success',
    name: 'Általános'
  }, {
    id: 2,
    color: 'info',
    name: 'Adómentes'
  }, {
    id: 3,
    color: 'warning',
    name: 'ÁFA törvény hatályán kívüli'
  }]);
  _exports.vatTypes = vatTypes;
});