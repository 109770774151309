define("iris/components/tables/duties/component", ["exports", "iris/lib/filters/types", "iris/utils/create-record"], function (_exports, _types, _createRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dutyDays: ['moDutyDay', 'tuDutyDay', 'weDutyDay', 'thDutyDay', 'frDutyDay', 'saDutyDay', 'suDutyDay'],
    dutyColumnNames: ['moDutyDayId', 'tuDutyDayId', 'weDutyDayId', 'thDutyDayId', 'frDutyDayId', 'saDutyDayId', 'suDutyDayId'],
    intl: Ember.inject.service(),
    modals: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    currentUser: Ember.computed.reads('sessionAccount.account'),
    token: Ember.computed.reads('session.data.authenticated'),
    isAdmin: Ember.computed('token', function () {
      return this.token.builtInRole === '___IRISAdministrators' || this.token.builtInRole === '__DomainAdministrators' || this.token.builtInRole === 'suManagement';
    }),
    columns: Ember.computed(function () {
      return {
        moDutyDay: {
          visibility: _types.AttributeVisibility.Table
        },
        tuDutyDay: {
          visibility: _types.AttributeVisibility.Table
        },
        weDutyDay: {
          visibility: _types.AttributeVisibility.Table
        },
        thDutyDay: {
          visibility: _types.AttributeVisibility.Table
        },
        frDutyDay: {
          visibility: _types.AttributeVisibility.Table
        },
        saDutyDay: {
          visibility: _types.AttributeVisibility.Table
        },
        suDutyDay: {
          visibility: _types.AttributeVisibility.Table
        }
      };
    }),

    async beforeSave(savedRecord) {
      const moDutyDay = await savedRecord.get('moDutyDay');
      await moDutyDay.save(true);
      const tuDutyDay = await savedRecord.get('tuDutyDay');
      await tuDutyDay.save(true);
      const weDutyDay = await savedRecord.get('weDutyDay');
      await weDutyDay.save(true);
      const thDutyDay = await savedRecord.get('thDutyDay');
      await thDutyDay.save(true);
      const frDutyDay = await savedRecord.get('frDutyDay');
      await frDutyDay.save(true);
      const saDutyDay = await savedRecord.get('saDutyDay');
      await saDutyDay.save(true);
      const suDutyDay = await savedRecord.get('suDutyDay');
      await suDutyDay.save(true);
    },

    async afterSave(savedRecord) {
      const dutyDays = ['moDutyDay', 'tuDutyDay', 'weDutyDay', 'thDutyDay', 'frDutyDay', 'saDutyDay', 'suDutyDay'];

      for (const dutyDay of dutyDays) {
        const relation = await savedRecord.get(dutyDay);
        const savedDutyDay = await relation.save(true);
        const dutyDayUserAssocs = await savedDutyDay.get('dutyDayUserAssocs');

        for (const dutyDayUserAssoc of dutyDayUserAssocs.toArray()) {
          await dutyDayUserAssoc.save(true);
        }
      }
    },

    rowActions: Ember.computed(function () {
      const {
        intl
      } = this;
      return [{
        name: this.intl.t('buttons.copying'),
        icon: 'clone',

        async action(duty) {
          try {
            let clonedRecord = await this.api.memberAction('duty', 'clone', duty.id, {
              method: 'POST'
            });
            this.flashMessages.info(this.intl.t('operation.change-request.new-cr-title'), {
              title: this.intl.t('general.clone.success'),
              icon: 'copy'
            });
            this.storage.refresh();
          } catch (error) {
            this.flashMessages.danger(this.intl.t('general.clone.error'), {
              title: this.intl.t('general.error'),
              icon: 'warning',
              sticky: true
            });
          }
        }

      }, {
        name: this.intl.t('general.deletion'),
        icon: 'trash',
        style: 'danger',
        disabled: Ember.computed('row.top', function () {
          if (this.row.top === false) {
            return intl.t('duty.only-top-can-be-deleted');
          }

          return false;
        }),

        async action(duty) {
          let shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

          if (shouldDelete) {
            try {
              await duty.destroyRecord(true);
              this.flashMessages.info(intl.t('general.delete.success-title'), {
                title: intl.t('general.delete.success'),
                icon: 'times'
              });
              this.storage.refresh();
            } catch (error) {
              console.log(error);
              this.flashMessages.danger(intl.t('general.delete.error'));
            }
          }
        }

      }];
    }),
    actions: {
      async createDuty(solverGroupId) {
        return await (0, _createRecord.createDutyUtil)(this.store, this.intl, solverGroupId);
      },

      async saveChanges(row, type, selected) {
        const dutyDay = await row.get(type);
        dutyDay.set('users', selected);

        try {
          dutyDay.save(true);
          this.flashMessages.add({
            message: 'Successfully updated!',
            title: 'IRIS message',
            icon: 'info'
          });
        } catch (error) {
          this.flashMessages.add({
            message: `Error saving duty: ${row.id}`,
            title: 'IRIS message',
            icon: 'warning'
          });
        }
      }

    }
  });

  _exports.default = _default;
});