define("iris/components/modals/import-tsm/result-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PYw+IYff",
    "block": "{\"symbols\":[\"@result\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text-5xl font-extrabold\"],[8],[1,[28,\"t\",[\"general.summary\"],null],false],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"my-5\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"errors\"]],0],null]],null,{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"text-success\"],[8],[0,\"\\n        \"],[5,\"fa-icon\",[],[[\"@icon\",\"@size\"],[\"check-circle-o\",\"5\"]]],[0,\"\\n      \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"text-danger\"],[8],[0,\"\\n        \"],[5,\"fa-icon\",[],[[\"@icon\",\"@size\"],[\"exclamation-circle\",\"5\"]]],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"my-3\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"text-3xl\"],[8],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"font-bold\"],[8],[1,[23,1,[\"errors\"]],false],[9],[0,\"\\n          \"],[1,[28,\"t\",[\"tsm-import.summary.errors\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"italic\"],[8],[1,[28,\"t\",[\"tsm-import.summary.errors-info\"],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"italic\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"tsm-import.summary.all\"],null],false],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"font-bold\"],[8],[1,[23,1,[\"all\"]],false],[9],[0,\"\\n    \"],[1,[28,\"t\",[\"tsm-import.summary.success\"],null],false],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"font-bold\"],[8],[1,[28,\"sub\",[[23,1,[\"all\"]],[23,1,[\"errors\"]]],null],false],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/modals/import-tsm/result-summary/template.hbs"
    }
  });

  _exports.default = _default;
});