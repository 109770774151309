define("iris/components/tandm/info/statistics/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0d1AtnsN",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"my-6 flex justify-end\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"w-1/12 text-center\"],[8],[0,\"\\n    \"],[5,\"iris-power-select\",[],[[\"@allowClear\",\"@options\",\"@selected\",\"@onchange\"],[false,[23,0,[\"filterOptions\"]],[23,0,[\"selectedFilter\"]],[28,\"action\",[[23,0,[]],\"changeFilter\"],null]]],{\"statements\":[[0,\"\\n      \"],[1,[23,1,[\"name\"]],false],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[5,\"chart-js\",[],[[\"@data\",\"@type\",\"@options\"],[[23,0,[\"data\"]],\"bar\",[23,0,[\"options\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/tandm/info/statistics/template.hbs"
    }
  });

  _exports.default = _default;
});