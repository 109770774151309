define("iris/templates/beta/sd-dashboard/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8foVms4M",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Áttekintés\"],null],false],[0,\"\\n\\n\"],[5,\"tables/sd-dashboard\",[],[[\"@defaultWhere\"],[[23,0,[\"defaultWhere\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/beta/sd-dashboard/index.hbs"
    }
  });

  _exports.default = _default;
});