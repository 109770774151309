define("iris/components/translation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1hCzcFKG",
    "block": "{\"symbols\":[\"translation\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"loading\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"spin-spinner\",null,[[\"lines\",\"length\",\"width\",\"scale\",\"color\"],[13,20,10,1,[28,\"if\",[[23,0,[\"themeSwitcher\",\"dark\"]],\"#fff\",\"#000\"],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"bs-form\",[],[[\"@model\",\"@proportion\"],[[23,0,[\"selectedTranslation\"]],[23,0,[\"proportion\"]]]],{\"statements\":[[0,\"\\n        \"],[5,\"form-group\",[[12,\"class\",\"add-button\"]],[[\"@property\",\"@label\"],[\"name\",[28,\"t\",[\"general.denomination\"],null]]],{\"statements\":[[0,\"\\n          \"],[5,\"bs-input\",[],[[\"@value\"],[[23,0,[\"selectedTranslation\",\"translationValue\"]]]]],[0,\"\\n          \"],[5,\"iris-button\",[],[[\"@action\",\"@type\",\"@title\",\"@icon\"],[[28,\"action\",[[23,0,[]],\"openTranslations\"],null],\"primary\",[28,\"t\",[\"general.translation\"],null],\"fa fa-globe\"]]],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"show\"]]],null,{\"statements\":[[0,\"        \"],[7,\"hr\",true],[10,\"class\",\"mb-5\"],[8],[9],[0,\"\\n\"],[4,\"each\",[[23,0,[\"otherTranslations\"]]],null,{\"statements\":[[0,\"          \"],[5,\"bs-form\",[],[[\"@model\",\"@proportion\"],[[23,0,[\"translation\"]],[23,0,[\"proportion\"]]]],{\"statements\":[[0,\"\\n            \"],[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"name\",[23,1,[\"language\",\"name\"]]]],{\"statements\":[[0,\"\\n              \"],[5,\"bs-input\",[],[[\"@value\"],[[23,1,[\"translationValue\"]]]]],[0,\"\\n            \"]],\"parameters\":[]}],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/translation/template.hbs"
    }
  });

  _exports.default = _default;
});