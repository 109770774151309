define("iris/templates/finances/pipelines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n64LGJGq",
    "block": "{\"symbols\":[\"tab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"main-content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"panel panel-default\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"tabs\"],[8],[0,\"\\n            \"],[7,\"ul\",true],[10,\"class\",\"nav nav-tabs\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"tabs\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"tagName\",\"route\"],[\"li\",[23,1,[\"route\"]]]],{\"statements\":[[0,\"                  \"],[7,\"a\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"tab-content\"],[8],[1,[22,\"outlet\"],false],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/finances/pipelines.hbs"
    }
  });

  _exports.default = _default;
});