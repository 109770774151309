define("iris/components/issue-report-edit/priority-edit/component", ["exports", "iris/utils/issue-report/ticket", "ember-concurrency", "iris/utils/unwrap-proxy", "iris/utils/find-service-period"], function (_exports, _ticket, _emberConcurrency, _unwrapProxy, _findServicePeriod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IssueReportEditPriority = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class IssueReportEditPriority extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "editPriority", false);

      _defineProperty(this, "priorities", []);

      _initializerDefineProperty(this, "modals", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);
    }

    *getPrioritiesTask() {
      const serviceLevels = yield (0, _ticket.getFilteredServiceLevels)(this.store, this.model);
      const priorityProxies = serviceLevels.mapBy('priority');
      const priorities = priorityProxies.map(pp => (0, _unwrapProxy.unwrapProxy)(pp));
      this.set('priorities', [...new Set(priorities)]);
    } // ----------------------- ACTIONS ----------------------


    async setPriority(priority) {
      const shouldChange = await this.modals.open('beta/modals/confirmation', {
        title: this.intl.t('operation.main.change-priority'),
        body: this.intl.t('operation.main.change-priority-text')
      });

      if (!shouldChange) {
        return;
      }

      const ticket = this.model;
      const serviceLevels = await ticket.get('operationServiceLevel.serviceLevels');
      const filteredserviceLevels = serviceLevels.filter(sl => sl.get('priority.id') === priority.get('id') && ticket.get('type.id') === sl.get('type.id'));
      const fsp = new _findServicePeriod.default();
      const serviceLevel = await fsp.getServiceLevel(filteredserviceLevels);
      ticket.set('serviceLevel', serviceLevel);
      (0, _ticket.setDatesFromServiceLevel)(ticket);
      ticket.set('priority', priority.get('value'));
      const saved = await ticket.save();

      if (saved) {
        this.flashMessages.info(this.intl.t('general.save.success'), {
          title: this.intl.t('general.save.success-title'),
          icon: 'floppy-o'
        });
      }

      this.set('editPriority', false);
    }

    getPriorities() {
      this.getPrioritiesTask.perform();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getPrioritiesTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "getPrioritiesTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setPriority", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setPriority"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getPriorities", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "getPriorities"), _class.prototype)), _class));
  _exports.default = IssueReportEditPriority;
});