define("iris/components/tables/entity-licensed-entity/grid/component", ["exports", "iris/lib/filters", "iris/lib/filters/column", "iris/lib/filters/storage", "iris/lib/filters/types", "iris/lib/table"], function (_exports, _filters, _column, _storage, _types, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LicenceCMDBComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _storage.useStorage)('memory'), _dec4 = (0, _column.columns)(function () {
    let cols = [{
      name: 'categoryId',
      displayName: this.intl.t('cmdb-visualisation.category'),
      type: _types.AttributeType.BelongsTo,
      visibility: _types.AttributeVisibility.Filter,
      optionsResourceName: 'entityTypeCategory'
    }, {
      name: 'entityTypeCategory',
      displayName: this.intl.t('cmdb-visualisation.categoryName'),
      type: _types.AttributeType.String,
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'monitoring',
      displayName: this.intl.t('cmdb-visualisation.monitoring'),
      type: _types.AttributeType.Boolean,
      visibility: _types.AttributeVisibility.Filter,

      formatLoopBackValue(value, filter, defaultFormatter) {
        if (filter.filterType === _types.FilterType.Boolean) {
          return value ? 1 : 0;
        }

        return defaultFormatter(value);
      }

    }, {
      name: 'monitoring',
      displayName: '',
      fixed: true,
      resizable: false,
      sortable: false,
      visibility: _types.AttributeVisibility.Table,
      width: _column.ColumnWidth.XS,
      exportable: false
    }, {
      name: 'name',
      displayName: this.intl.t('cmdb-visualisation.name'),
      fixed: true,
      width: _column.ColumnWidth.L,
      route: 'cmdb.entity-types.entities.entity.attributes',
      routeParams: (0, _table.rowValues)('entityTypeId', 'id')
    }, {
      name: 'entityType',
      displayName: this.intl.t('cmdb-visualisation.entityType'),
      visibility: _types.AttributeVisibility.Table
    }, {
      name: 'entityType',
      displayName: this.intl.t('cmdb-visualisation.entityTypeName'),
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'entityTypeId',
      displayName: this.intl.t('cmdb-visualisation.entityType'),
      type: _types.AttributeType.BelongsTo,
      visibility: _types.AttributeVisibility.Filter,
      belongsTo: [{
        categoryId: 'entityTypeCategoryId'
      }],
      createOptions: async (records, nameKey, defaultMapper) => {
        let etcs = await this.api.findAll('entityTypeCategory');
        let groups = etcs.map(etc => {
          return {
            groupName: etc.name,
            options: defaultMapper(records.filter(et => et.belongsTo('entityTypeCategory').id() === etc.id))
          };
        });
        let ungrouped = {
          groupName: this.intl.t('cmdb-visualisation.others'),
          options: defaultMapper(records.filter(et => !et.belongsTo('entityTypeCategory').id()))
        };
        return [...groups, ungrouped];
      }
    }, {
      name: 'partner',
      displayName: this.intl.t('cmdb-visualisation.partnerName')
    }, {
      name: 'partnerId',
      displayName: this.intl.t('cmdb-visualisation.partner'),
      type: _types.AttributeType.BelongsTo,
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'location',
      displayName: this.intl.t('cmdb-visualisation.location'),
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'licenced',
      displayName: this.intl.t('cmdb-visualisation.license'),
      type: _types.AttributeType.Boolean,
      visibility: _types.AttributeVisibility.Filter
    }]; //Hardware

    cols = [...cols, {
      name: 'serialNumber',
      displayName: this.intl.t('cmdb-visualisation.serialNumber'),
      fuzzySearch: false
    }, {
      name: 'manufacturer',
      displayName: this.intl.t('cmdb-visualisation.manufacturer'),
      visibility: _types.AttributeVisibility.Table
    }, {
      name: 'locationId',
      displayName: this.intl.t('cmdb-visualisation.location'),
      type: _types.AttributeType.BelongsTo,
      belongsTo: ['workSiteId'],
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'contact',
      displayName: this.intl.t('inventory.contact')
    }, {
      name: 'productType',
      displayName: this.intl.t('cmdb-visualisation.productType'),
      visibility: _types.AttributeVisibility.Table
    }, {
      name: 'status',
      displayName: this.intl.t('cmdb-visualisation.status'),
      type: _types.AttributeType.BelongsTo,
      optionsResourceName: 'entityStatus',

      // The request sent as `{ status: { eq: 'Entity Status Name' }}` where
      // the value is the name of the `entityStatus` not the ID. To achieve
      // this, we set the option IDs to the names of the entity statuses
      // queried for the select input.
      createOptions(records, nameKey, defaultMapper) {
        return records.map(_ref => {
          let {
            name
          } = _ref;
          return {
            id: name,
            name
          };
        });
      }

    }];
    return cols;
  }), _dec5 = (0, _table.rowActions)(function () {
    return [];
  }), _dec6 = Ember.computed('columns', 'defaultWhere', 'storage.params'), (_class = class LicenceCMDBComponent extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "storage", _descriptor3, this);

      _initializerDefineProperty(this, "columns", _descriptor4, this);

      _initializerDefineProperty(this, "rowActions", _descriptor5, this);
    }

    get fetchParams() {
      let {
        filter
      } = (0, _filters.makeQueryObject)(this.columns, this.storage.params, {});

      if (this.defaultWhere) {
        var _filter$where, _filter$where$and;

        (_filter$where = filter.where) === null || _filter$where === void 0 ? void 0 : (_filter$where$and = _filter$where.and) === null || _filter$where$and === void 0 ? void 0 : _filter$where$and.push(this.defaultWhere);
      }

      return {
        filter: { ...filter
        }
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "columns", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "rowActions", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchParams", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "fetchParams"), _class.prototype)), _class));
  _exports.default = LicenceCMDBComponent;
});