define("iris/components/forms/date-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super();

      this.get('model').startTrack();
    },

    dateTypeDefinitions: Ember.computed(function () {
      return this.get('store').findAll('dateTypeDefinition');
    })
  });

  _exports.default = _default;
});