define("iris/components/forms/ticket-users-legacy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    _modelTicketUserUsers: Ember.computed.mapBy('model.ticketUserAssocs', 'user'),
    ticketUserUsers: Ember.computed.mapBy('_modelTicketUserUsers', 'content'),
    _fileSupplierAssocsSuppliers: Ember.computed.mapBy('file.fileSupplierAssocs', 'supplier'),
    suppliers: Ember.computed.mapBy('_fileSupplierAssocsSuppliers', 'content'),
    sortBy: ['longName'],
    orderedTicketUsers: Ember.computed.sort('ticketUsers', 'sortBy'),
    filtered: Ember.computed('orderedTicketUsers', 'ticket.ticketUsers.[]', function () {
      let ticketUserUsers = this.get('ticket.ticketUsers');

      if (ticketUserUsers) {
        ticketUserUsers = ticketUserUsers.mapBy('content');
        return this.get('orderedTicketUsers').filter(user => {
          return !ticketUserUsers.contains(user);
        });
      } else {
        return this.get('orderedTicketUsers');
      }
    }),
    actions: {
      addTicketUser(selected) {
        let ticket = this.get('model');
        let users = ticket.get('ticketUserAssocs').mapBy('user.content');
        let store = this.get('store');
        selected.forEach(user => {
          if (!users.contains(user)) {
            store.createRecord('ticketUserAssoc', {
              user: user,
              ticket: ticket
            });
          }
        });
        users.forEach(user => {
          if (!selected.contains(user)) {
            user.get('ticketUserAssoc').then(u => {
              u.set('user', null);
              u.set('ticket', null);

              if (u.get('id')) {
                ticket.get('toDelete').addObject(u);
              }

              u.deleteRecord();
            });
          }
        });
      },

      changeInternal(value) {
        if (value) {
          this.set('model.supplier', null);
        } else {
          this.set('model.user', null);
        }
      }

    }
  });

  _exports.default = _default;
});