define("iris/components/tables/duties/duty-day-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let DutyDayCell = (_dec = Ember.computed('columnName', 'row'), (_class = class DutyDayCell extends Ember.Component {
    get dutyDayUserAssocs() {
      const {
        columnName,
        row
      } = this; // moDutyDayId -> moDutyDay

      const dutyDay = columnName.substring(0, columnName.length - 2);
      return row.get(`${dutyDay}.dutyDayUserAssocs`);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "dutyDayUserAssocs", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "dutyDayUserAssocs"), _class.prototype)), _class));
  _exports.default = DutyDayCell;
});