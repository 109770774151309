define("iris/components/aiops/dashboards/competency/working-hours-chart/component", ["exports", "iris/utils/properties", "moment"], function (_exports, _properties, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    themeSwitcher: Ember.inject.service(),
    from: (0, _moment.default)().add(-1, 'M'),
    to: (0, _moment.default)(),
    timestamp: (0, _moment.default)(),
    data: (0, _properties.computedTask)('timestamp', 'model.id', function* () {
      console.log('from', this.from);
      return yield this.ajax.call('POST', 'AiopsPartner', '', 'workingHourChart', {
        competencyId: this.model.id,
        from: this.from,
        to: this.to
      }, true);
    }),
    tableData: Ember.computed('data', 'model.id', function () {
      const data = getData(this.data);
      return {
        match: data[0],
        notMatch: data[1],
        matchPercentage: Math.round(Number(data[0]) / (Number(data[0]) + Number(data[1])) * 100),
        notMatchPercentage: Math.round(Number(data[1]) / (Number(data[0]) + Number(data[1])) * 100)
      };
    }),
    chartData: Ember.computed('data', 'model.id', function () {
      const data = getData(this.data);
      return {
        labels: [this.intl.t('aiops.dashboars.work-log-in-competency'), this.intl.t('aiops.dashboars.work-log-out-competency')],
        datasets: [{
          data,
          backgroundColor: ['#7bc426', '#dc3545'],
          borderColor: ['#7bc426', '#dc3545']
        }]
      };
    }),
    options: Ember.computed('themeSwitcher.dark', 'data', function () {
      const fontColor = this.get('themeSwitcher.dark') ? '#FFF' : '#485761';
      const data = getData(this.data);
      const percentage = data && data.length ? Math.round(Number(data[0]) / (Number(data[0]) + Number(data[1])) * 100) : 0;
      return {
        cutoutPercentage: 75,
        legend: {
          display: false
        },
        elements: {
          center: {
            // the longest text that could appear in the center
            maxText: '100%',
            text: percentage,
            fontColor: fontColor,
            fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            fontStyle: 'normal',
            // fontSize: 12,
            // if a fontSize is NOT specified, we will scale (within the below limits) maxText to take up the maximum space in the center
            // if these are not specified either, we default to 1 and 256
            minFontSize: 1,
            maxFontSize: 156
          }
        }
      };
    }),
    actions: {
      getData() {
        this.set('timestamp', (0, _moment.default)());
      }

    }
  });

  _exports.default = _default;

  const getData = function (data) {
    let resData = [];

    if (data) {
      data.forEach(function (cd) {
        resData.push(cd['match']);
        resData.push(cd['notMatch']);
      });
    }

    return resData;
  };
});