define("iris/components/beta/basic-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mhkn7TT3",
    "block": "{\"symbols\":[\"row\",\"column\",\"column\",\"@columns\",\"&attrs\",\"@rows\"],\"statements\":[[7,\"table\",false],[12,\"class\",\"table table-striped\"],[13,5],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,4,[]]],null,{\"statements\":[[0,\"        \"],[7,\"th\",true],[8],[0,\"\\n          \"],[1,[23,3,[\"name\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,6,[]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,4,[]]],null,{\"statements\":[[0,\"          \"],[7,\"td\",true],[8],[0,\"\\n            \"],[1,[28,\"get\",[[23,1,[]],[23,2,[\"valuePath\"]]],null],false],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/beta/basic-table/template.hbs"
    }
  });

  _exports.default = _default;
});