define("iris/initializers/moment-range", ["exports", "moment", "moment-range"], function (_exports, _moment, _momentRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function
    /* application */
  initialize() {
    (0, _momentRange.extendMoment)(_moment.default);
  }

  var _default = {
    name: 'moment-range',
    initialize
  };
  _exports.default = _default;
});