define("iris/components/form/fields/tax-number/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "twsSiJcP",
    "block": "{\"symbols\":[\"@partner\"],\"statements\":[[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"tax\",[28,\"t\",[\"partner.tax\"],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"mr-4 flex-grow\"],[8],[0,\"\\n      \"],[5,\"bs-input\",[],[[\"@value\",\"@disabled\"],[[23,1,[\"tax\"]],true]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"bs-button\",[],[[\"@text\",\"@type\",\"@action\"],[[28,\"t\",[\"tax-number-component.set-tax-number\"],null],\"success\",[28,\"action\",[[23,0,[]],\"openModal\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/form/fields/tax-number/template.hbs"
    }
  });

  _exports.default = _default;
});