define("iris/routes/settings/operations/ticket-audit-list/edit/new-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let store = this.store;
      return Ember.RSVP.hash({
        auditItem: store.createRecord('ticketAuditListItem')
      });
    },

    actions: {
      refreshTable() {
        this.controllerFor("settings.operations.ticket-audit-list.edit").send('refresh');
      }

    },
    setupController: function (controller, model) {
      this._super(controller, model);

      this.currentModel.auditItem.set('list', this.controllerFor('settings.operations.ticket-audit-list.edit').get('model.auditList'));
    }
  });

  _exports.default = _default;
});