define("iris/validators/entity-related", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    intl: Ember.inject.service(),

    validate(value, options, model, attribute) {
      let required = model.get('entityAttribute.required');
      let type = model.get('entityAttribute.type');
      let min = model.get('entityAttribute.min');
      let max = model.get('entityAttribute.max');

      if (type === 'hasMany') {
        if (!Ember.isEmpty(value)) {
          if (min !== null && max !== null) {
            if (value.get('length') >= min && value.get('length') <= max) {
              return true;
            } else {
              return this.intl.t('validators.entityValue.between', {
                min,
                max
              });
            }
          } else if (min !== null) {
            if (value.get('length') >= min) {
              return true;
            } else {
              return this.intl.t('validators.entityValue.min', {
                min
              });
            }
          } else if (max !== null) {
            if (value.get('length') <= max) {
              return true;
            } else {
              return this.intl.t('validators.entityValue.max', {
                max
              });
            }
          } else {
            return true;
          }
        } else {
          if (required) {
            return this.intl.t('validators.entityRelated.presence', {
              attribute
            });
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    }

  });

  _exports.default = _default;
});