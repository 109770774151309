define("iris/components/invoice/info/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class InvoiceInfo extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');
    }

  }

  _exports.default = InvoiceInfo;
});