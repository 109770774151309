define("iris/components/competency-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super(args);

      this.set('rightList', Ember.A());
    },

    modal: false,
    actions: {
      toggleModal() {
        this.toggleProperty('modal');
        this.get('rightList').clear();
      },

      save() {
        let c = this.get('rightList');
        let action = this.get('action');

        let _this = this;

        action.get('competencies').then(competencies => {
          competencies.setObjects(c);
          action.save(true).then(() => {
            _this.toggleProperty('modal');

            return;
          });
        });
      }

    }
  });

  _exports.default = _default;
});