define("iris/components/beta/grid/bulk-actions/action/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    selection: null,
    // @
    configClass: null,
    // @
    bulkAction: Ember.computed('configClass', 'selection', function () {
      if (this.configClass) {
        return this.configClass.create({
          selection: this.selection
        });
      }

      return null;
    })
  });

  _exports.default = _default;
});