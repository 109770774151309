define("iris/initializers/setup-ember-can", ["exports", "require"], function (_exports, _require) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals requirejs */
  var Resolver; // This is a bit of a hack, but there is no way to detect
  // which module is needed via normal `import` statements

  if (requirejs.entries['ember-resolver'] || requirejs.entries['ember-resolver/index']) {
    // ember-resolver is provided when the consuming
    // application uses ember-resolver@^2.0.0 from NPM
    Resolver = (0, _require.default)("ember-resolver")['default'];
  } else {
    // ember/resolver is provided when the consuming
    // application uses ember-resolver@^0.1.x from Bower
    Resolver = (0, _require.default)("ember/resolver")['default'];
  }

  Resolver.reopen({
    pluralizedTypes: {
      ability: 'abilities'
    }
  });
  var _default = {
    name: 'setup-ember-can',
    initialize: function (application) {
      // make sure we create new ability instances each time, otherwise we stomp on each other's models
      if (application.optionsForType) {
        // it's a container / registry in 1.13.x
        application.optionsForType('ability', {
          singleton: false
        });
      } else {
        // Ember 2.0.x
        application.registerOptionsForType('ability', {
          singleton: false
        });
      }
    }
  };
  _exports.default = _default;
});