define("iris/models/file-solver-group", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    sd: (0, _attr.default)('boolean'),
    file: (0, _relationships.belongsTo)('project'),
    solvingGroup: (0, _relationships.belongsTo)('solvingGroup')
  });

  _exports.default = _default;
});