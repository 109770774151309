define("iris/components/keycloak-session-link", ["exports", "ember-keycloak-auth/components/keycloak-session-link"], function (_exports, _keycloakSessionLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _keycloakSessionLink.default;
    }
  });
});