define("iris/routes/crm/opportunities/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),

    async model(params) {
      if (params.file_id == 'new') {
        const fileState = await this.store.findRecord('fileState', 'SYS_02'); // Lehetőség

        return Ember.RSVP.hash({
          file: this.store.createRecord('project', {
            fileState
          })
        });
      }

      const currentUserId = this.get('session.data.authenticated.userId');
      return Ember.RSVP.hash({
        file: this.store.findRecord('project', params.file_id),
        fileUserAssocs: this.ajax.call('GET', 'fileUserAssoc', '', params.file_id + '/get-actions/')
      });
    },

    afterModel: function (model) {
      if (model.file.id) {
        let sessionAccount = this.get('sessionAccount');
        let actions = this.get('sessionAccount.actions');
        let routes = Ember.A();
        model.fileUserAssocs.filter(a => {
          if (a.system_name) {
            actions.pushObject(a);
          }

          if (a.route) {
            routes.pushObject(a);
          }
        });
        sessionAccount.set('actions', actions);
        sessionAccount.set('routes', routes);
      }
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      /*ACL overview */


      let file = model.file;
      const disableInputObject = {
        common: !this.can('read action', {
          obj: file,
          canActions: ['projects.files.data', 'projects.files.data.write']
        }),
        finance: !this.can('read action', {
          obj: file,
          canActions: ['projects.files.data.finance', 'projects.files.data.finance.write']
        }),
        financeRead: !this.can('read action', {
          obj: file,
          canActions: ['projects.files.data.finance', 'projects.files.data.finance.write', 'projects.files.data.finance.read']
        }),
        competency: !this.can('read action', {
          obj: file,
          canActions: ['projects.files.acls']
        }),
        planning: !this.can('read action', {
          obj: file,
          canActions: ['projects.files.planning']
        }),
        ticket: !this.can('read action', {
          obj: file,
          canActions: ['projects.files.tickets', 'projects.files.tickets.write', 'projects.files.planning']
        }),
        ticketRead: !this.can('read action', {
          obj: file,
          canActions: ['projects.files.tickets', 'projects.files.tickets.write', 'projects.files.tickets.read', 'projects.files.planning']
        })
      };
      this.controller.set('disableInputObject', disableInputObject);
    }
  });

  _exports.default = _default;
});