define("iris/mixins/occurrence-overlap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    occurrences: Ember.computed.alias('timetable.model.occurrences'),
    overLap: Ember.computed('range', 'occurrences.[]', function () {
      let range1 = this.get('range');
      let overLap = 0;
      let occurrences = this.get('occurrences');

      if (occurrences) {
        occurrences.forEach(occurrence => {
          let start = occurrence.get('content.startsAt');
          let end = occurrence.get('content.endsAt');
          let range2 = moment.range(start, end);

          if (range1.overlaps(range2)) {
            overLap++;
          }
        });
      }

      return overLap;
    }),
    range: Ember.computed('model.startingTime', 'model.endingTime', function () {
      return moment.range(this.get('model.startingTime'), this.get('model.endingTime'));
    }),
    _width: Ember.computed('timetable.dayWidth', 'overLap', function () {
      return this.get('timetable.dayWidth') / this.get('overLap');
    }),
    _style: Ember.computed('_height', '_top', '_width', function () {
      return `top: ${this.get('_top')}px;
            height: ${this.get('_height')}px;
            width: ${this.get('_width')}px;`.htmlSafe();
    })
  });

  _exports.default = _default;
});