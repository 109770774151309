define("iris/controllers/operation/sd-dashboard/index", ["exports", "iris/services/query-params"], function (_exports, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const indexQueryParams = (0, _queryParams.makeFilterQueryParams)({});
  const queryParamsMixin = (0, _queryParams.makeControllerMixin)(indexQueryParams);

  var _default = Ember.Controller.extend(queryParamsMixin, {});

  _exports.default = _default;
});