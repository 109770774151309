define("iris/models/inventory-contact-item", ["exports", "ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    inventoryContact: (0, _relationships.belongsTo)('inventoryContact'),
    entity: (0, _relationships.belongsTo)('entity')
  });

  _exports.default = _default;
});