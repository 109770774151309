define("iris/templates/components/tree-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+z//q0bN",
    "block": "{\"symbols\":[\"m\",\"&default\"],\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"  \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"children\",\"length\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"tree-branch\",null,[[\"rootComp\",\"model\",\"addAction\"],[[24,[\"rootComp\"]],[23,1,[]],[24,[\"addAction\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[1,[28,\"sub-ticket\",null,[[\"rootComp\",\"model\",\"addAction\"],[[24,[\"rootComp\"]],[23,1,[]],[24,[\"addAction\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/tree-group.hbs"
    }
  });

  _exports.default = _default;
});