define("iris/components/operational-file/file-balance/cumulables/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TandmTimelineCumulables = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('tandm.balance'), (_class = class TandmTimelineCumulables extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _defineProperty(this, "backgroundColors", ['rgba(216, 228, 126)', 'rgba(171, 202, 58)', 'rgba(119, 172, 23)', 'rgba(84, 138, 14)', 'rgba(68, 107, 16)']);

      _defineProperty(this, "borderColors", ['rgba(216, 228, 126)', 'rgba(171, 202, 58)', 'rgba(119, 172, 23)', 'rgba(84, 138, 14)', 'rgba(68, 107, 16)']);
    }

    get options() {
      const fontColor = this.get('themeSwitcher.dark') ? '#FFF' : '#485761';
      const value = this.tandm.balance;
      return {
        cutoutPercentage: 75,
        legend: {
          display: false
        },
        elements: {
          center: {
            // the longest text that could appear in the center
            maxText: '100%',
            text: `${value}`,
            fontColor: fontColor,
            fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            fontStyle: 'normal',
            // fontSize: 12,
            // if a fontSize is NOT specified, we will scale (within the below limits) maxText to take up the maximum space in the center
            // if these are not specified either, we default to 1 and 256
            minFontSize: 1,
            maxFontSize: 256
          }
        }
      };
    }

    async didInsertElement() {
      await this.fetchStatsTask.perform();
    }

    *fetchStatsTask() {
      const {
        id: tandmId,
        cumulable
      } = this.tandm;
      const data = yield this.api.query('tandmCumulable', {
        filter: {
          where: {
            tandmId
          },
          order: 'createDate',
          limit: cumulable + 1
        }
      });
      const {
        list,
        chart
      } = this.serialize(data);
      this.set('list', list);
      this.set('chart', chart);
    }

    serialize(data) {
      const list = [],
            chart = {
        labels: ['Base'],
        datasets: [{
          data: [this.tandm.baseBalance],
          borderColor: ['gray'],
          backgroundColor: ['gray']
        }]
      };
      const array = data.toArray();
      const {
        backgroundColors,
        borderColors
      } = this;

      for (const [i, v] of array.entries()) {
        const {
          balance,
          createDate
        } = v;
        const month = (0, _moment.default)(createDate).add(-1, 'month').format('YYYY MMMM');
        const disabled = array.length - i > this.tandm.cumulable;
        const listItem = {
          month,
          disabled,
          balance
        };

        if (disabled === false) {
          const color = backgroundColors[i % backgroundColors.length];
          listItem.color = color;
          chart.labels.push(month);
          chart.datasets[0].data.push(balance);
          chart.datasets[0].backgroundColor.push(color);
          chart.datasets[0].borderColor.push(color);
        }

        list.push(listItem);
      }

      return {
        list,
        chart
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "options", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "options"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchStatsTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchStatsTask"), _class.prototype)), _class));
  _exports.default = TandmTimelineCumulables;
});