define("iris/components/modals/import-tsm/result-summary/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ImportTSMResultSummary extends Ember.Component {}

  _exports.default = ImportTSMResultSummary;
});