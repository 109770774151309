define("iris/templates/components/dashboard/pie-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "luSH3+yZ",
    "block": "{\"symbols\":[\"ticket\",\"index\",\"tooltip\"],\"statements\":[[7,\"h4\",true],[10,\"class\",\"h4\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-sm-7\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"simple-bullet-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"tickets\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[11,\"class\",[28,\"get-at-index\",[[24,[\"discs\"]],[23,2,[]]],null]],[8],[0,\"\\n      \"],[1,[28,\"get-at-index\",[[24,[\"labels\"]],[23,2,[]]],null],false],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"pull-right\"],[8],[0,\"(\"],[1,[23,1,[]],false],[0,\")\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"get-at-index\",[[24,[\"tooltips\"]],[23,2,[]]],null]],null,{\"statements\":[[4,\"ember-tooltip\",null,[[\"class\"],[\"mw400\"]],{\"statements\":[[0,\"      \"],[7,\"ul\",true],[10,\"class\",\"tooltip-list pl-11\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"get-at-index\",[[24,[\"tooltips\"]],[23,2,[]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[7,\"b\",true],[8],[1,[28,\"t\",[\"dashboard.file-colon\"],null],false],[0,\" \"],[9],[1,[23,3,[\"file\"]],false],[7,\"br\",true],[8],[9],[0,\"\\n          \"],[7,\"b\",true],[8],[1,[28,\"t\",[\"dashboard.task-colon\"],null],false],[0,\" \"],[9],[1,[23,3,[\"subject\"]],false],[0,\" \"],[7,\"i\",true],[8],[0,\"(\"],[1,[23,3,[\"ticketNumber\"]],false],[0,\")\"],[9],[7,\"br\",true],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-sm-5\"],[8],[0,\"\\n  \"],[1,[28,\"chart-js\",null,[[\"data\",\"type\",\"options\"],[[24,[\"data\"]],\"doughnut\",[24,[\"options\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/dashboard/pie-chart.hbs"
    }
  });

  _exports.default = _default;
});