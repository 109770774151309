define("iris/models/aiops-contract", ["exports", "iris/models/aiops-base", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _aiopsBase, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    contractAmount: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  var _default = _aiopsBase.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    validFrom: (0, _attr.default)('date'),
    validTo: (0, _attr.default)('date'),
    contractAmount: (0, _attr.default)('number', {
      formWidget: 'mask'
    }),
    customer: (0, _relationships.belongsTo)('AiopsCustomer'),
    services: (0, _relationships.hasMany)('AiopsServicesContractsX')
  });

  _exports.default = _default;
});