define("iris/routes/change-log/add-to-news", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    model() {
      return this.store.createRecord('news', {
        subject: this.intl.t('news.developerNews'),
        contents: this.intl.t('news.developerNewsContents'),
        transitionTo: 'change-log',
        linkSubject: true
      });
    }

  });

  _exports.default = _default;
});