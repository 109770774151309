define("iris/helpers/work-log-editable", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.workLogEditable = workLogEditable;

  function workLogEditable(params) {
    let date = new Date(params);
    return (0, _moment.default)().diff((0, _moment.default)(date), 'h') <= 24;
  }

  var _default = Ember.Helper.helper(workLogEditable);

  _exports.default = _default;
});