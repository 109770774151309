define("iris/components/form-group-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-group'],
    labelWidth: '3'
  });

  _exports.default = _default;
});