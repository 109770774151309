define("iris/components/modals/substitution/component", ["exports", "ember-concurrency", "iris/utils/error"], function (_exports, _emberConcurrency, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Substitution = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.alias('sessionAccount.account'), _dec6 = Ember.computed('loadCurrentSubstitute.isRunning', 'getPossibleSubstitutes.isRunning', 'saveSubstituteTask.isRunning'), _dec7 = Ember._action, _dec8 = Ember._action, (_class = class Substitution extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "substitute", null);

      _defineProperty(this, "currentSubstitute", null);

      _defineProperty(this, "possibleSubstitutes", []);

      _defineProperty(this, "start", moment());

      _defineProperty(this, "end", moment().add(7, 'd'));

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "modals", _descriptor3, this);

      _initializerDefineProperty(this, "sessionAccount", _descriptor4, this);

      _initializerDefineProperty(this, "loggedInUser", _descriptor5, this);
    }

    async didReceiveAttrs() {
      this.loadCurrentSubstitute.perform();
      this.getPossibleSubstitutes.perform();
    }

    get loading() {
      return this.loadCurrentSubstitute.isRunning || this.getPossibleSubstitutes.isRunning || this.saveSubstituteTask.isRunning;
    }

    *loadCurrentSubstitute() {
      const ref = this.loggedInUser.belongsTo('pandantUser');
      yield ref.reload();
      const currentSubstitute = this.loggedInUser.pandantUser;
      this.set('currentSubstitute', currentSubstitute);
    }

    *getPossibleSubstitutes() {
      const possibleSubstitutes = yield this.api.collectionAction('account', 'substitutes');
      this.set('possibleSubstitutes', possibleSubstitutes);
    }

    *saveSubstituteTask() {
      const {
        flashMessages,
        intl
      } = this;
      const values = this.substitute ? {
        pandantUserId: this.substitute.id,
        subsitutionStartDate: this.start,
        subsitutionEndDate: this.end
      } : {
        pandantUserId: null,
        subsitutionStartDate: null,
        subsitutionEndDate: null
      };

      try {
        yield this.api.memberAction('account', 'changeUserSettings', this.loggedInUser.id, {
          method: 'POST',
          data: {
            values
          }
        });
        yield this.sessionAccount.account.reload();
        flashMessages.info(intl.t('general.save.success'), {
          title: this.intl.t('general.save.success-title'),
          icon: 'floppy-o'
        });
      } catch (error) {
        const msg = (0, _error.formatErrorMessages)(error);
        flashMessages.danger(msg, {
          title: intl.t('general.save.error'),
          sticky: true,
          icon: 'warning'
        });
      }
    }

    async saveSubstitute() {
      await this.saveSubstituteTask.perform();
      this.close();
    }

    async removeSubstitute() {
      let confirm = await this.modals.open('beta/modals/confirmation', {
        title: this.intl.t('general.warning'),
        body: this.intl.t('substitution-modal.remove-confirmation')
      });

      if (confirm) {
        this.set('substitute', null);
        await this.saveSubstituteTask.perform();
      }

      await this.loadCurrentSubstitute.perform();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sessionAccount", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "loggedInUser", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loading", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadCurrentSubstitute", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadCurrentSubstitute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getPossibleSubstitutes", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "getPossibleSubstitutes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveSubstituteTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "saveSubstituteTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveSubstitute", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "saveSubstitute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeSubstitute", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "removeSubstitute"), _class.prototype)), _class));
  _exports.default = Substitution;
});