define("iris/templates/components/toggle-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EoE81c05",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"ios-switch \",[28,\"if\",[[23,0,[\"value\"]],\"on\",\"off\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"tooltip\"]]],null,{\"statements\":[[4,\"ember-tooltip\",null,[[\"class\"],[\"mw600\"]],{\"statements\":[[4,\"if\",[[23,0,[\"value\"]]],null,{\"statements\":[[0,\"        \"],[1,[22,\"labelTrue\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[22,\"labelFalse\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"on-background background-fill\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"state-background background-fill\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"handle\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"input\",true],[10,\"name\",\"switch\"],[10,\"data-plugin-ios-switch\",\"\"],[10,\"checked\",\"checked\"],[10,\"style\",\"display: none;\"],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/toggle-switch.hbs"
    }
  });

  _exports.default = _default;
});