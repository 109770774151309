define("iris/components/issue-report-edit/status-edit/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IssueReportEditState = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('model.state.id'), _dec4 = Ember.computed.mapBy('allowedStateChanges', 'destinationStateId'), _dec5 = Ember.computed('model.id', 'allowedStateChanges', 'mappedAllowedStateChanges'), _dec6 = Ember._action, (_class = class IssueReportEditState extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "backToList", null);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "modals", _descriptor2, this);

      _initializerDefineProperty(this, "mappedAllowedStateChanges", _descriptor3, this);
    }

    get allowedStateChanges() {
      return this.store.query('ticketStateChangeAllow', {
        filter: {
          where: {
            and: [{
              isAllowed: true
            }, {
              originStateId: this.get('model.state.id')
            }]
          }
        }
      });
    }

    get statuses() {
      const {
        mappedAllowedStateChanges
      } = this;

      if (Ember.isEmpty(mappedAllowedStateChanges)) {
        return [];
      }

      return this.store.query('ticketState', {
        filter: {
          where: {
            and: [{
              sd: 1
            }, {
              or: [{
                isSystemType: false
              }, {
                isSystemType: null
              }]
            }, {
              id: {
                inq: mappedAllowedStateChanges
              }
            }]
          }
        }
      });
    }

    *saveTask(record) {
      let reason = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const saved = yield record.save();

      if (saved) {
        this.flashMessages.info(this.intl.t('general.save.success'), {
          title: this.intl.t('general.save.success-title'),
          icon: 'floppy-o'
        }); // if the user has chosen a state where giving a reason is mandatory or possible
        // (e.g.: changing FROM 'Waiting For 3rd Party' state )
        // backend generates a comment. Let's reload them to update the comment counter

        if (reason && typeof reason === 'string' && reason.length > 1) {
          const comments = record.hasMany('comments');
          comments.reload();
        } // if the new state is reason state, go back to list


        const stateId = record.belongsTo('state').id();

        if (this.reasonStates.includes(stateId)) {
          this.backToList();
        }
      }
    }

    async changeState(state) {
      const oldSate = await this.model.get('state');
      const changeToReasonState = this.reasonStates.includes(state.id);
      const changeFromReasonSate = this.reasonStates.includes(oldSate.id);
      let reason = false;

      if (changeToReasonState || changeFromReasonSate) {
        reason = await this.modals.open('issue-report-edit/modals/reason', {
          title: this.intl.t('hr.justification'),
          minChar: changeToReasonState ? 15 : 0
        });

        if (reason === false) {
          return;
        }

        this.model.set('currentReason', reason);
      }

      this.model.set('state', state);
      await this.saveTask.perform(this.model, reason);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "allowedStateChanges", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "allowedStateChanges"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "mappedAllowedStateChanges", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "statuses", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "statuses"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "saveTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeState", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "changeState"), _class.prototype)), _class));
  _exports.default = IssueReportEditState;
});