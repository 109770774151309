define("iris/templates/settings/finances/tax-number-patterns/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6MH2uLcM",
    "block": "{\"symbols\":[],\"statements\":[[5,\"forms/tax-number-pattern\",[],[[\"@model\",\"@rowActions\"],[[23,0,[\"model\"]],[23,0,[\"rowActions\"]]]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/finances/tax-number-patterns/edit.hbs"
    }
  });

  _exports.default = _default;
});