define("iris/models/ticket-communication", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-copyable"], function (_exports, _model, _attr, _relationships, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loadRelationsForCopying = _exports.default = void 0;
  const COPYABLE_RELATIONS = ['accounts', 'solvingGroups', 'competencies'];

  var _default = _model.default.extend(_emberDataCopyable.default, {
    open: (0, _attr.default)('boolean'),
    stateChange: (0, _attr.default)('boolean'),
    solve: (0, _attr.default)('boolean'),
    workLog: (0, _attr.default)('boolean'),
    normal: (0, _attr.default)('boolean'),
    critical: (0, _attr.default)('boolean'),
    warning: (0, _attr.default)('boolean'),
    overdue: (0, _attr.default)('boolean'),
    deletable: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    serviceLevel: (0, _relationships.belongsTo)('serviceLevel'),
    serviceLevelTemplate: (0, _relationships.belongsTo)('serviceLevelTemplate'),
    accounts: (0, _relationships.hasMany)('account'),
    solvingGroups: (0, _relationships.hasMany)('solvingGroup'),
    competencies: (0, _relationships.hasMany)('competency'),
    copyableOptions: {
      copyByReference: COPYABLE_RELATIONS
    }
  });

  _exports.default = _default;

  const loadRelationsForCopying = async ticketCommunication => {
    for (let relation of COPYABLE_RELATIONS) {
      await ticketCommunication.get(relation);
    }
  };

  _exports.loadRelationsForCopying = loadRelationsForCopying;
});