define("iris/components/issue-report-edit/audit-log/entries/push-notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A2djgNse",
    "block": "{\"symbols\":[\"@log\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"text-muted mb-none\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"list-unstyled changelog extra-bottom-space\"],[8],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"constructor\",\"modelName\"]],\"push-notification\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"label label-iris-info\"],[8],[0,\"\\n          \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"volume-control-phone\"]]],[0,\"\\n          \"],[1,[23,1,[\"user\",\"longName\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"constructor\",\"modelName\"]],\"push-notification-acknowledge\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"label label-iris-success\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"push-notification.reaction\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n      \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"constructor\",\"modelName\"]],\"push-notification\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"italic font-bold\"],[11,\"title\",[28,\"t\",[\"push-notification.messageId\"],null]],[8],[0,\"\\n      \"],[1,[23,1,[\"messageId\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"if\",[[28,\"eq\",[[23,1,[\"constructor\",\"modelName\"]],\"push-notification-acknowledge\"],null]],null,{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/issue-report-edit/audit-log/entries/push-notification/template.hbs"
    }
  });

  _exports.default = _default;
});