define("iris/components/beta/filters/add/ui/select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mtthBE0O",
    "block": "{\"symbols\":[\"option\",\"powerSelect\",\"&attrs\",\"@data\",\"@acts\",\"@onClose\",\"@triggerClass\"],\"statements\":[[7,\"div\",false],[13,3],[8],[0,\"\\n  \"],[5,\"power-select\",[],[[\"@options\",\"@onChange\",\"@onClose\",\"@initiallyOpened\",\"@triggerClass\",\"@allowClear\",\"@searchEnabled\",\"@searchField\",\"@search\"],[[23,4,[\"options\"]],[23,5,[\"select\"]],[23,6,[]],true,[29,[\"flex-1 \",[23,7,[]]]],true,true,\"displayName\",[23,5,[\"search\"]]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex items-center\"],[8],[0,\"\\n\"],[0,\"      \\n      \"],[7,\"span\",true],[8],[0,\"\\n        \"],[1,[28,\"highlight\",[[23,1,[\"filter\",\"displayName\"]]],[[\"term\",\"class\"],[[23,2,[\"searchText\"]],\"\\n            rounded-sm\\n            bg-carrot-300 text-gray-900\\n            dark:text-carrot-300 dark:bg-black dark:bg-opacity-30\\n          \"]]],false],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[5,\"beta/filters/icons/attribute-type\",[[12,\"class\",\"ml-auto\"]],[[\"@attributeType\"],[[23,1,[\"filter\",\"attributeType\"]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[1,2]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/beta/filters/add/ui/select/template.hbs"
    }
  });

  _exports.default = _default;
});