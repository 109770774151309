define("iris/models/operation-service-level", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "iris/mixins/anonymize"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _anonymize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    businessService: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    solvingGroup: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    operationServiceLevelType: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  var _default = _model.default.extend(Validations, _anonymize.default, {
    modelName: 'operationServiceLevel',
    name: (0, _attr.default)('string'),
    slSuccessMinimum: (0, _attr.default)('string'),
    imac: (0, _attr.default)('boolean'),
    imacLimit: (0, _attr.default)('string'),
    solvingGroupId: (0, _attr.default)('string'),
    operationServiceLevelType: (0, _relationships.belongsTo)('operationServiceLevelType'),
    businessService: (0, _relationships.belongsTo)('businessService'),
    tickets: (0, _relationships.hasMany)('ticket'),
    serviceLevels: (0, _relationships.hasMany)('serviceLevel'),
    solvingGroup: (0, _relationships.belongsTo)('solvingGroup'),
    file: (0, _relationships.belongsTo)('project'),
    ticketCosts: Ember.computed.mapBy('tickets.content', 'cost'),
    costTicket: Ember.computed.sum('ticketCosts'),
    cost: Ember.computed('costTicket', function () {
      return parseInt(this.get('costTicket'));
    }),
    ticketOverheadCosts: Ember.computed.mapBy('tickets.content', 'overheadCost'),
    costOverheadTicket: Ember.computed.sum('ticketOverheadCosts'),
    costOverhead: Ember.computed('costOverheadTicket', function () {
      return parseInt(this.get('costOverheadTicket'));
    })
  });

  _exports.default = _default;
});