define("iris/templates/crm/contracts/edit/grade-costs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w3peFS04",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"tables/contract-grade-costs\",null,[[\"data\",\"disableInput\",\"buttonActions\"],[[24,[\"model\",\"contractUserGradeCosts\"]],[24,[\"disableInput\"]],[24,[\"buttonActions\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/crm/contracts/edit/grade-costs.hbs"
    }
  });

  _exports.default = _default;
});