define("iris/helpers/string-repeat", ["exports", "ember-string-fns/helpers/string-repeat"], function (_exports, _stringRepeat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _stringRepeat.default;
    }
  });
  Object.defineProperty(_exports, "stringRepeat", {
    enumerable: true,
    get: function () {
      return _stringRepeat.stringRepeat;
    }
  });
});