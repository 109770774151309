define("iris/routes/settings/cmdb/code-stores", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      afterSave: function (savedEntityType) {
        //Attributes
        savedEntityType.get('entities').then(entities => {
          entities.invoke('save');
        }); //delete removed entityAttributes

        let toDelete = savedEntityType.get('toDelete.entities');
        toDelete.invoke('destroyRecord', true);
        toDelete.clear();
      }
    }
  });

  _exports.default = _default;
});