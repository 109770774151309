define("iris/templates/settings/operations/ticket-states/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0PrRNJKC",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@proportion\",\"@frame\"],[[23,0,[\"model\",\"model\"]],\"settings.operations.ticket-states\",\"2-4\",true]],{\"statements\":[[0,\"\\n  \"],[5,\"forms/settings-operations-ticket-states\",[],[[\"@model\"],[[23,0,[\"model\",\"model\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/operations/ticket-states/edit.hbs"
    }
  });

  _exports.default = _default;
});