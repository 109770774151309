define("iris/components/forms/duty/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KB2QcJpO",
    "block": "{\"symbols\":[\"day\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex justify-between items-center mb-6\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"h2 font-semibold\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-calendar\"],[8],[9],[0,\"\\n    \"],[1,[22,\"weekTitle\"],false],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-calendar\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"justify-self-end\"],[8],[0,\"\\n    \"],[5,\"iris-button\",[],[[\"@action\",\"@type\",\"@text\",\"@icon\"],[[28,\"action\",[[23,0,[]],\"copyMonday\"],null],\"primary\",[28,\"t\",[\"duty.copy-monday\"],null],\"fa fa-clone\"]]],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"grid grid-cols-7 gap-4\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"dutyDays\"]]],null,{\"statements\":[[0,\"    \"],[5,\"forms/duty/day\",[],[[\"@duty\",\"@day\"],[[23,0,[\"model\"]],[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/forms/duty/template.hbs"
    }
  });

  _exports.default = _default;
});