define("iris/templates/components/form-element/errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x9m1gv+H",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showErrors\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"help-block\"],[8],[1,[24,[\"errors\",\"firstObject\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/form-element/errors.hbs"
    }
  });

  _exports.default = _default;
});