define("iris/components/character-counter-max/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    remaining: Ember.computed('string.length', 'max', function () {
      if (typeof this.string !== 'string') {
        return 0;
      }

      return this.max - this.string.length;
    }),
    overMax: Ember.computed('remaining', function () {
      return Math.abs(this.remaining);
    })
  });

  _exports.default = _default;
});