define("iris/templates/reports/inventories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eSHhOA9L",
    "block": "{\"symbols\":[\"t\"],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"menus.inventories\"],null]],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"my-8\"],[8],[0,\"\\n  \"],[5,\"bs-button\",[],[[\"@type\",\"@icon\",\"@text\",\"@action\"],[\"primary\",\"fa fa-paper-plane\",[28,\"t\",[\"reports.inventories.select-inventory\"],null],[28,\"route-action\",[\"createInvetory\"],null]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\",\"@readOnly\"],[\"inventory\",\"reports.inventories.index\",[28,\"array\",[\"chooseContacts\",\"createDate\",\"status\",\"partnerNames\"],null],true]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"chooseContacts\"],null]],null,{\"statements\":[[0,\"      \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"reports.inventories.contacts\",[23,1,[\"row\",\"id\"]]]],{\"statements\":[[1,[23,1,[\"row\",\"id\"]],false]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/reports/inventories.hbs"
    }
  });

  _exports.default = _default;
});