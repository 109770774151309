define("iris/models/inventory-contact", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    valid: (0, _attr.default)('number'),
    status: (0, _attr.default)('string'),
    reason: (0, _attr.default)('string'),
    token: (0, _attr.default)('string'),
    inventory: (0, _relationships.belongsTo)('inventory'),
    contact: (0, _relationships.belongsTo)('contact'),
    partner: (0, _relationships.belongsTo)('partner'),
    entities: (0, _relationships.hasMany)('entity'),
    moves: (0, _relationships.hasMany)('entityMove')
  });

  _exports.default = _default;
});