define("iris/routes/settings/project/project-ticket-templates/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProjectTicketTemplateEditRoute extends Ember.Route {
    model(params) {
      if (params.id == 'new') {
        return this.store.createRecord('projectTicketTemplate');
      }

      return this.store.findRecord('projectTicketTemplate', params.id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = ProjectTicketTemplateEditRoute;
});