define("iris/components/auto/grid/component", ["exports", "iris/lib/filters/storage", "iris/lib/filters", "iris/lib/filters/column", "iris/lib/table", "iris/lib/grid", "iris/lib/auto/default-fields", "ember-inflector", "iris/lib/modals", "iris/lib/filters/types"], function (_exports, _storage, _filters, _column, _table, _grid, _defaultFields, _emberInflector, _modals, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modals: Ember.inject.service(),
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    scroller: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.storage.update('limit', this.limit);
      const constraintsRelations = [];
      this.model.eachRelationship((name, descriptor) => {
        var _descriptor$meta$opti;

        if ((_descriptor$meta$opti = descriptor.meta.options) !== null && _descriptor$meta$opti !== void 0 && _descriptor$meta$opti.constraints) {
          constraintsRelations.push(name);
        }
      });
      this.set('constraintsRelations', constraintsRelations);
    },

    limit: 25,
    storage: (0, _storage.useStorage)('memory'),
    onlyIndexRoute: true,
    filter: true,
    didTransition: null,
    inActive: false,
    includeOnlyBelongsTo: true,
    constraintsRelations: [],
    routeObject: Ember.computed('route', 'modelName', function () {
      if (this.route && typeof this.route === 'object') {
        return this.route;
      } else if (this.route) {
        return {
          index: this.route.replace('edit', 'index'),
          new: this.route,
          edit: this.route
        };
      } else {
        return `${(0, _emberInflector.pluralize)(this.modelName)}.edit`;
      }
    }),
    isRender: Ember.computed('didTransition', 'onlyIndexRoute', 'routeObject', function () {
      let appCtrl = Ember.getOwner(this).lookup('controller:application');

      if (appCtrl.currentRouteName !== this.routeObject.index && this.onlyIndexRoute) {
        return false;
      } else {
        return true;
      }
    }),
    newRouteLabel: Ember.computed('modelName', function () {
      let translationModel = this.translationModel ? this.translationModel : this.modelName;
      return `${this.intl.t('buttons.new')} ${this.intl.t(`${translationModel}.modelName`)}`;
    }),
    isMultiRoute: Ember.computed('route', function () {
      return typeof this.route === 'object';
    }),
    excludeFields: [],
    neededFields: [],
    externalRowActions: [],
    externalBulkActions: null,
    dropDownActions: [],
    model: Ember.computed('modelName', function () {
      return this.store.modelFor(this.modelName);
    }),
    fields: Ember.computed('neededFields', 'model', 'excludeFields', function () {
      if (this.neededFields.length) {
        return this.neededFields;
      }

      const res = [];
      const self = this;
      this.model.fields.forEach(function (kind, field) {
        if (!self.excludeFields.includes(field) && !_defaultFields.DefaultFields.Fields.includes(field)) {
          res.push(field);
        }
      });
      return res;
    }),
    includeFields: Ember.computed('model', 'neededFields', 'excludeFields', 'constraintsRelations.[]', function () {
      const res = [];
      const self = this;
      const kinds = this.includeOnlyBelongsTo ? ['belongsTo'] : ['belongsTo', 'hasMany'];
      const neededFields = this.neededFields.map(field => {
        return field.substr(0, field.indexOf('.'));
      });
      this.model.eachRelationship(function (name, descriptor) {
        if (self.neededFields.length) {
          if (self.neededFields.map(f => f.split('.')[0]).includes(name) && kinds.includes(descriptor.kind)) {
            res.push(name);
          }

          if (self.groupBy && self.groupBy === name && kinds.includes(descriptor.kind)) {
            res.push(name);
          }
        } else {
          if (!self.excludeFields.includes(name) && !_defaultFields.DefaultFields.Fields.includes(name) && kinds.includes(descriptor.kind)) {
            res.push(name);
          }
        }
      });
      return [...new Set([...res, ...this.constraintsRelations])];
    }),
    columns: (0, _column.columns)(function () {
      const res = [];
      const self = this;
      let i = 0;
      let aMapper = {};
      this.model.attributes.forEach(function (meta, name) {
        aMapper[name] = _types.AttributeMapper[meta.type];
      });
      this.fields.forEach(function (field) {
        let name = 'name';
        let displayName = self.intl.t(`${self.modelName}.${field}`);

        if (field.includes('.')) {
          let ar = field.split('.');
          field = ar[0];
          name = ar[1];
          displayName = self.intl.t(`${self.model.typeForRelationship(field, self.store).modelName}.${name}`);
        }

        let column = {
          name: field,
          displayName
        };
        let relationType = self.model.relationshipsByName.get(field) ? self.model.relationshipsByName.get(field).kind : null;

        if (i === 0 && !self.readOnly) {
          column['width'] = _column.ColumnWidth.S;

          if (self.modalEdit) {
            column['action'] = self.editItem;
          } else {
            column['route'] = self.routeObject.edit;
            column['routeParams'] = (0, _table.rowValues)('id');
          }
        } else {
          column['width'] = _column.ColumnWidth.SM;
          column['fixed'] = false;
        }

        if (relationType === 'belongsTo') {
          column['optionsResourceName'] = Ember.String.camelize(self.model.relationshipsByName.get(field).type);
          column['valuePath'] = `${field}.${name}`;
          column['type'] = _types.AttributeType.BelongsTo;
          column['name'] = `${field}Id`;
          column['orderProperty'] = `${field}.${name}`;
          column['optionsNameKey'] = name;
        } else if (relationType === 'hasMany') {
          column['visibility'] = _types.AttributeVisibility.Table;
          column['sortable'] = false;
          column['exportable'] = false;
        } else {
          column['type'] = aMapper[field];
        } // Overwrites default options


        if (self.columnOverwrites && (field in self.columnOverwrites || `${field}.${name}` in self.columnOverwrites)) {
          column = { ...column,
            ...self.columnOverwrites[field]
          };
        }

        res.push(column);
        i++;
      });

      if (this.extraColumns) {
        res.push(...this.extraColumns);
      }

      return res;
    }),
    rowActions: (0, _table.rowActions)(function () {
      if (this.readOnly || this.noDelete) {
        return this.externalRowActions;
      }

      const {
        constraintsRelations,
        intl,
        modelName
      } = this;
      return [...this.externalRowActions, {
        name: this.intl.t('general.deletion'),
        icon: 'trash',
        style: 'danger',
        disabled: Ember.computed('row', function () {
          for (const rel of constraintsRelations) {
            const ids = this.row.hasMany(rel).ids();

            if (ids.length) {
              const relName = intl.t(`${modelName}.${rel}`);
              return intl.t('general.delete.cant-delete-constraint', {
                relation: relName,
                qty: ids.length
              });
            }
          }

          return false;
        }),

        async action(record) {
          let shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

          if (shouldDelete) {
            try {
              let res = await record.destroyRecord(true);

              if (res) {
                this.flashMessages.info(this.intl.t('general.delete.success'), {
                  title: this.intl.t('general.delete.success-title'),
                  icon: 'times'
                });
              }

              this.storage.refresh();
            } catch (error) {
              this.flashMessages.danger(this.intl.t('general.delete.error'), {
                title: this.intl.t('general.delete.error'),
                icon: 'warning'
              });
            }
          }
        }

      }];
    }),
    bulkActions: (0, _grid.bulkActions)(function () {
      if (this.readOnly || this.noDelete) {
        return this.externalBulkActions;
      }

      const externalBulkActions = this.externalBulkActions ? this.externalBulkActions : [];
      const {
        flashMessages,
        intl
      } = this;

      const getNotDeletabelRecords = selection => {
        return selection.filter(record => this.constraintsRelations.some(rel => record.hasMany(rel).ids().length));
      };

      return [...externalBulkActions, {
        name: this.intl.t('general.deletion'),
        icon: 'trash',
        disabled: Ember.computed('selection', function () {
          const {
            selection
          } = this;
          const notDeletableRecords = getNotDeletabelRecords(selection);
          return notDeletableRecords.length === selection.length;
        }),

        async action(selection) {
          const notDeletableRecords = getNotDeletabelRecords(selection);
          const subtext = notDeletableRecords.length ? intl.t('general.delete.cant-delete-constraint-warning', {
            nrOfSelection: selection.length,
            nrNotDeletable: notDeletableRecords.length
          }) : '';
          let shouldDelete = await this.modals.open('beta/modals/confirmation', {
            title: intl.t('general.deletion'),
            body: intl.t('modals.delete-confirmation'),
            subtext
          });

          if (shouldDelete) {
            let ids = selection.filter(r => !notDeletableRecords.includes(r)).map(row => row.id);
            let records = await this.api.where(this.modelName, {
              id: {
                inq: ids
              }
            });

            try {
              const promises = records.invoke('destroyRecord', true);
              await Promise.all(promises);
              flashMessages.info(this.intl.t('general.delete.success'), {
                title: this.intl.t('general.delete.success-title'),
                icon: 'times'
              });
              this.storage.refresh();
            } catch (error) {
              flashMessages.danger(intl.t('general.delete.error'), {
                title: intl.t('general.delete.error'),
                icon: 'warning'
              });
            }
          }
        }

      }];
    }),

    async toggleInActive() {
      this.toggleProperty('inActive');
      this.storage.refresh();
    },

    isActiveField: Ember.computed('model', function () {
      let res = false;
      this.model.fields.forEach(function (kind, field) {
        if (field === 'active') {
          res = true;
        }
      });
      return res;
    }),
    mergedDropDownActions: Ember.computed('inActive', 'isActiveField', function () {
      if (this.isActiveField) {
        const name = this.inActive === true ? this.intl.t('general.inactive-doesnt-show') : this.intl.t('general.inactive-show');
        return [...this.dropDownActions, {
          name,
          action: this.toggleInActive.bind(this)
        }];
      } else {
        return this.dropDownActions;
      }
    }),
    fetchParams: Ember.computed('columns', 'storage.params', 'includeFields', 'defaultFilters', 'defaultOrder', function () {
      let {
        filter
      } = (0, _filters.makeQueryObject)(this.columns, this.storage.params);

      if (this.defaultFilters) {
        var _filter$where, _filter$where$and;

        (_filter$where = filter.where) === null || _filter$where === void 0 ? void 0 : (_filter$where$and = _filter$where.and) === null || _filter$where$and === void 0 ? void 0 : _filter$where$and.push(this.defaultFilters);
      }

      if (this.defaultOrder && filter.order == 'id desc') {
        filter.order = this.defaultOrder;
      }

      return {
        filter: { ...filter,
          include: this.includeFields,
          inActive: this.inActive
        }
      };
    }),

    didInsertElement() {
      let self = this;
      Ember.run.scheduleOnce('afterRender', this, function () {
        this.get('router').on('didTransition', function () {
          if (!self.isDestroyed) {
            self.set('didTransition', new Date());
          }
        });
      });
    },

    getNeededFields(record) {
      const res = {};
      res.id = record.get('id');
      res[this.modelName] = record;

      for (const field of this.neededFields) {
        res[field] = record.get(field);
      }

      return res;
    },

    createTree(arr, parentKey) {
      let result = [];
      let children = arr.filter(obj => obj.get('parent.id') === parentKey);
      children.forEach(child => {
        result.push({ ...this.getNeededFields(child),
          children: this.createTree(arr, child.id)
        });
      });
      return result;
    },

    noFilter: Ember.computed('storage.params.q', 'storage.params.filter', 'storage.params.order', function () {
      return this.storage.params.q == null && this.storage.params.filters == null;
    }),

    async editItem(row) {
      const self = this;
      const record = self.groupBy ? self.store.peekRecord(self.modelName, row.id) : row;
      let editItem = await self.modals.open(`modals/form-modal`, {
        model: record,
        itemName: self.itemName,
        parentModel: self.parentModel,
        excludeFields: self.excludeFieldsFormAll,
        neededFields: self.neededFieldsForm || self.neededFields,
        formComponent: self.formComponent,
        formComponentData: self.formComponentData,
        translationFileds: self.translationFileds,
        afterSave: self.afterSave,
        beforeSave: self.beforeSave,
        beforeCloseModal: self.beforeCloseModal,
        proportion: self.proportion,
        noClose: self.noClose,
        noFooter: self.noFooter,
        componentLayout: self.componentLayout
      }, {
        className: _modals.ModalLayoutClassName[self.modalSize || 'M']
      });
      const scroller = document.querySelector('html');
      const scrollTop = scroller.scrollTop;
      self.storage.refresh();
      Ember.run.later(() => {
        self.get('scroller').scrollVertical('html', {
          offset: scrollTop
        });
      }, 500);
    },

    actions: {
      manageStoreResult(result) {
        if (this.groupBy && this.noFilter) {
          return this.createTree(result, undefined);
        } else {
          return result;
        }
      },

      async newItem() {
        const record = this.createRecord ? await this.createRecord() : this.store.createRecord(this.modelName);
        let newItem = await this.modals.open('modals/form-modal', {
          model: record,
          itemName: this.itemName,
          excludeFields: this.excludeFieldsFormAll,
          neededFields: this.neededFieldsForm || this.neededFields,
          formComponent: this.formComponent,
          formComponentData: this.formComponentData,
          translationFileds: this.translationFileds,
          afterSave: this.afterSave,
          beforeSave: this.beforeSave,
          beforeCloseModal: this.beforeCloseModal,
          proportion: this.proportion,
          noClose: this.noClose,
          noFooter: this.noFooter,
          componentLayout: this.componentLayout
        }, {
          className: _modals.ModalLayoutClassName[this.modalSize || 'M']
        });

        if (newItem) {
          this.storage.refresh();
        }
      }

    }
  });

  _exports.default = _default;
});