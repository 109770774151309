define("iris/components/forms/duty/component", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Duty = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('lastDutyTask.last.value'), _dec3 = Ember.computed('model', 'lastDuty'), _dec4 = Ember._action, (_class = class Duty extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "model", null);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _defineProperty(this, "dutyDays", ['moDutyDay', 'tuDutyDay', 'weDutyDay', 'thDutyDay', 'frDutyDay', 'saDutyDay', 'suDutyDay']);

      _initializerDefineProperty(this, "lastDuty", _descriptor2, this);
    }

    didReceiveAttrs() {
      this.lastDutyTask.perform();
    }

    *lastDutyTask() {
      return yield this.store.query('duty', {
        filter: {
          limit: 1,
          order: 'beginDate DESC',
          where: {
            solverGroupId: this.data.id
          }
        }
      });
    }

    get weekTitle() {
      const existingRecord = this.lastDuty ? this.lastDuty.firstObject : null;
      let beginDate = null;
      let endDate = null;

      if (this.model.beginDate) {
        // Edit
        beginDate = (0, _moment.default)(this.model.beginDate);
        endDate = (0, _moment.default)(this.model.endDate);
      } else if (existingRecord) {
        // New and NOT first
        beginDate = (0, _moment.default)(existingRecord.beginDate).add(7, 'days');
        endDate = (0, _moment.default)(existingRecord.endDate).add(7, 'days');
      } else {
        // New and first
        beginDate = (0, _moment.default)().startOf('week');
        endDate = (0, _moment.default)().endOf('week');
      }

      return `${beginDate.format('YY.MM.DD')} - ${endDate.format('YY.MM.DD')}`;
    }

    async copyMonday() {
      const monday = await this.get('model.moDutyDay');
      const dutyDayUserAssocs = await monday.get('dutyDayUserAssocs');
      const mondayDutyDayUserAssocs = dutyDayUserAssocs.toArray();

      for (const dutyDay of this.dutyDays) {
        if (dutyDay === 'moDutyDay') {
          continue;
        }

        const rel = this.get(`model.${dutyDay}.dutyDayUserAssocs`);

        for (const ddua of rel.toArray()) {
          await ddua.destroyRecord(true);
        }

        rel.clear();
        const dutyDayRecord = this.model.get(dutyDay);

        for (const mondayDutyDayUserAssoc of mondayDutyDayUserAssocs) {
          const record = this.store.createRecord('dutyDayUserAssoc', {
            dutyDay: dutyDayRecord,
            account: mondayDutyDayUserAssoc.get('account'),
            shiftChange: mondayDutyDayUserAssoc.get('shiftChange')
          });
          rel.addObject(record);
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "lastDutyTask", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "lastDutyTask"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "lastDuty", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "weekTitle", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "weekTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copyMonday", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "copyMonday"), _class.prototype)), _class));
  _exports.default = Duty;
});