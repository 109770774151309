define("iris/models/entity-relation-template", ["exports", "ember-data", "ember-data/attr", "ember-data/relationships"], function (_exports, _emberData, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    entityRelationType: (0, _relationships.belongsTo)('entityRelationType'),
    entityType: (0, _relationships.belongsTo)('entityType', {
      inverse: 'entityRelationTemplates'
    }),
    inverseEntityType: (0, _relationships.belongsTo)('entityType', {
      inverse: 'inverseEntityRelationTemplates'
    }),
    inverse: (0, _attr.default)('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});