define("iris/templates/projects/operational/edit/tickets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MHfkiIXc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row margin-bot\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-3\"],[8],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"btn-group btn-group-justified\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"btn-group\"],[10,\"role\",\"group\"],[8],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",[29,[\"btn btn-\",[28,\"if\",[[24,[\"ticketView\"]],\"primary\",\"default\"],null]]]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"setView\",true]],[8],[1,[28,\"t\",[\"projects.tickets.open-task\"],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"btn-group\"],[10,\"role\",\"group\"],[8],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",[29,[\"btn btn-\",[28,\"if\",[[24,[\"ticketView\"]],\"default\",\"primary\"],null]]]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"setView\",false]],[8],[1,[28,\"t\",[\"projects.tickets.closed-task\"],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-3\"],[8],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"ticketView\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"tables/operational-tickets\",null,[[\"planPeriodCloseDate\",\"model\",\"from\"],[[24,[\"model\",\"planPeriodCloseDate\"]],[24,[\"openTickets\"]],[28,\"parent-route\",[[24,[\"parentRouteName\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"tables/operational-tickets-closed\",null,[[\"planPeriodCloseDate\",\"model\",\"from\"],[[24,[\"model\",\"planPeriodCloseDate\"]],[24,[\"closedTickets\"]],[28,\"parent-route\",[[24,[\"parentRouteName\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/projects/operational/edit/tickets.hbs"
    }
  });

  _exports.default = _default;
});