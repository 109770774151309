define("iris/components/bs-typeahead", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['typeahead'],
    open: false,
    search: true,
    list: Ember.A([]),

    didRender() {
      this._super(...arguments);

      let height = this.$('.dropdown-content').height();
      let style = `-${height + 32}px`; //this.set('style', style)

      this.$('.dropdown-content').css({
        'margin-top': style
      });
    },

    actions: {
      typingEnded(searchTerm) {
        const action = this.get('action');

        if (action && this.get('search')) {
          action(searchTerm, this);
        }

        this.set('search', true);
      },

      select(item) {
        this.set('value', item);
        this.set('open', false);
        this.set('search', false);
      },

      focusOut() {
        Ember.run.later(() => {
          this.set('open', false);
        }, 500);
      }

    }
  });

  _exports.default = _default;
});