define("iris/helpers/string-not-equals", ["exports", "ember-string-fns/helpers/string-not-equals"], function (_exports, _stringNotEquals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _stringNotEquals.default;
    }
  });
  Object.defineProperty(_exports, "stringNotEquals", {
    enumerable: true,
    get: function () {
      return _stringNotEquals.stringNotEquals;
    }
  });
});