define("iris/components/cmdb/relation-visualisation/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    loading: Ember.inject.service(),
    intl: Ember.inject.service(),
    collapsed: false,
    dataToVisualise: null,
    init: function () {
      this._super();

      this.set('selectedEntities', Ember.A());
      this.get('dataToVisualiseTask').perform(true);
    },

    async getVisualisationElements(entityId) {
      let edited = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const res = {
        links: [],
        elements: []
      };
      const elementIds = [entityId];
      const entityTypeVisualisationIds = {};
      const relations = await this.store.query('entityRelation', {
        filter: {
          where: {
            or: [{
              masterEntityId: entityId
            }, {
              slaveEntityId: entityId
            }]
          },
          include: ['masterEntity', 'slaveEntity', 'entityRelationType']
        }
      });

      for (const relation of relations.toArray()) {
        res.links.push({
          name: relation.get('entityRelationType.name'),
          source: relation.get('masterEntity.id'),
          target: relation.get('slaveEntity.id')
        });
        elementIds.push(relation.get('masterEntity.id'));
        elementIds.push(relation.get('slaveEntity.id'));
      }

      const entities = await this.store.query('entity', {
        filter: {
          where: {
            id: {
              inq: elementIds
            }
          },
          include: ['entityType']
        }
      });
      const entityTypeIds = entities.map(e => e.get('entityType.id'));
      const entityTypes = await this.store.query('entityType', {
        filter: {
          where: {
            id: {
              inq: entityTypeIds
            }
          },
          include: ['entityTypeVisualisation']
        }
      });

      for (const et of entityTypes.toArray()) {
        entityTypeVisualisationIds[et.id] = et.get('entityTypeVisualisation.svg');
      }

      for (const entity of entities.toArray()) {
        res.elements.push({
          id: entity.get('id'),
          name: entity.get('name'),
          type: entity.get('entityType.name'),
          opaque: true,
          svg: entityTypeVisualisationIds[entity.get('entityType.id')],
          threshold: 0.5,
          state: entity.get('icingaStatus'),
          category: entity.get('entityType.entityTypeCategory.id').slice(3),
          edited: edited && entity.get('id') === entityId ? true : false
        });
      }

      return res;
    },

    dataToVisualiseTask: (0, _emberConcurrency.task)(function* () {
      let init = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      let res = {};
      yield this.loading.run(async () => {
        if (init) {
          res = await this.getVisualisationElements(this.data.entityToVisualise.id, true);
          this.set('dataToVisualise', res);
        } else if (this.selectedEntities.length && this.selectedEntityType && this.entityRelationType) {
          res = await this.getVisualisationElements(this.data.entityToVisualise.id, true);

          for (const e of this.selectedEntities.toArray()) {
            let elem = await this.getVisualisationElements(e.id);
            res.elements = res.elements.concat(elem.elements);
            res.links = res.links.concat(elem.links);
            res.elements = [...new Map(res.elements.map(item => [item['id'], item])).values()];
            /* TODO: Links unque array needed?
            res.links = [
              ...new Map(res.links.map((item) => [item['id'], item])).values(),
            ];
            */

            res.links.push({
              name: this.entityRelationType.name,
              source: this.inverse ? e.id : this.data.entityToVisualise.id,
              target: this.inverse ? this.data.entityToVisualise.id : e.id,
              edited: true
            });
          }

          this.set('dataToVisualise', res);
        }
      });
    }),
    types: Ember.computed('data.entityTypes', 'entityRelationType', function () {
      let entityRelationTypeId = this.get('entityRelationType.id');
      let entityTypes = this.get('data.entityTypes');
      let entityTypeOptions = Ember.A();
      let normalEntityType = entityTypes.filter(et => {
        return et.id !== null;
      });
      let hardware = normalEntityType.filter(et => {
        return et.belongsTo('entityTypeCategory').id().slice(-9) === '000000001';
      });

      if (entityRelationTypeId === 'SYS_04') {
        return hardware;
      }

      let software = normalEntityType.filter(et => {
        return et.belongsTo('entityTypeCategory').id().slice(-9) === '000000002';
      });
      let business = normalEntityType.filter(et => {
        return et.belongsTo('entityTypeCategory').id().slice(-9) === '000000003';
      });
      let technology = normalEntityType.filter(et => {
        return et.belongsTo('entityTypeCategory').id().slice(-9) === '000000004';
      });
      let license = normalEntityType.filter(et => {
        return et.belongsTo('entityTypeCategory').id().slice(-9) === '000000006';
      });
      entityTypeOptions.pushObject({
        groupName: this.intl.t('cmdb.relation-visualisation.hardware'),
        options: hardware
      });
      entityTypeOptions.pushObject({
        groupName: this.intl.t('cmdb.relation-visualisation.software'),
        options: software
      });
      entityTypeOptions.pushObject({
        groupName: this.intl.t('cmdb.relation-visualisation.business'),
        options: business
      });
      entityTypeOptions.pushObject({
        groupName: this.intl.t('cmdb.relation-visualisation.technology'),
        options: technology
      });
      entityTypeOptions.pushObject({
        groupName: this.intl.t('cmdb.relation-visualisation.license'),
        options: license
      });
      return entityTypeOptions;
    }),
    actions: {
      toggleBox: function () {
        this.toggleProperty('collapsed');
      },
      changeRelationType: function (selected) {
        this.set('entityRelationType', selected);
        this.set('selectedEntityType', null);
        this.set('selectedEntities', Ember.A());
        this.get('dataToVisualiseTask').perform();
      },
      changeInverse: function (selected) {
        this.get('dataToVisualiseTask').perform();
      },
      changeEntityType: function (selected) {
        this.set('selectedEntityType', selected);
        this.get('dataToVisualiseTask').perform();
      },
      changeEntities: function (selected) {
        this.set('selectedEntities', selected);
        this.get('dataToVisualiseTask').perform();
      },

      async addRelation() {
        if (this.inverse === undefined) {
          this.inverse = 0;
        }

        for (const selectedEntity of this.selectedEntities) {
          let relation = null;

          if (this.inverse) {
            relation = this.get('store').createRecord('entityRelation', {
              entityRelationType: this.entityRelationType,
              masterEntity: selectedEntity,
              slaveEntity: this.data.entityToVisualise
            });
          } else {
            relation = this.get('store').createRecord('entityRelation', {
              entityRelationType: this.entityRelationType,
              masterEntity: this.data.entityToVisualise,
              slaveEntity: selectedEntity
            });
          }

          let res = await relation.save(true);

          if (!res) {
            relation.deleteRecord();
            return;
          }
        }

        this.close();
      }

    }
  });

  _exports.default = _default;
});