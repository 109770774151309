define("iris/controllers/crm/opportunities/index", ["exports", "iris/lib/filters", "iris/services/query-params", "iris/lib/filters/types", "iris/lib/filters/storage", "iris/lib/filters/column", "iris/lib/table", "iris/utils/flatten-eq-operators"], function (_exports, _filters, _queryParams, _types, _storage, _column, _table, _flattenEqOperators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const opportunitiesQueryParams = (0, _queryParams.makeFilterQueryParams)(null, {
    filters: [{
      name: 'closed',
      type: _types.FilterType.Boolean,
      value: false
    }],
    order: {
      property: 'createDate',
      direction: 'desc'
    }
  });
  const queryParamsMixin = (0, _queryParams.makeControllerMixin)(opportunitiesQueryParams);

  var _default = Ember.Controller.extend(queryParamsMixin, {
    intl: Ember.inject.service(),
    modals: Ember.inject.service(),
    storage: (0, _storage.useStorage)('query-params', {
      controllerPath: 'crm.opportunities.index'
    }),
    predefinedViews: Ember.computed(function () {
      return [{
        name: this.intl.t('general.all'),
        filters: []
      }, {
        name: this.intl.t('general.open'),
        filters: [{
          name: 'closed',
          type: _types.FilterType.Boolean,
          value: false
        }]
      }];
    }),
    rowActions: (0, _table.rowActions)(function () {
      return [{
        name: this.intl.t('general.deletion'),
        icon: 'trash',
        style: 'danger',

        async action(opportunity) {
          let shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

          if (shouldDelete) {
            try {
              const project = await this.store.findRecord('project', opportunity.id);
              const res = await project.destroyRecord(true);

              if (res) {
                this.flashMessages.info(this.intl.t('general.delete.success-title'), {
                  title: this.intl.t('general.delete.success'),
                  icon: 'times'
                });
                this.storage.refresh();
              } else {
                project.rollback();
              }
            } catch (error) {
              this.flashMessages.danger(error, {
                sticky: true,
                title: this.intl.t('general.delete.error'),
                icon: 'times'
              });
            }
          }
        }

      }];
    }),
    columns: (0, _column.columns)(function () {
      return [{
        name: 'fileNumber',
        displayName: this.intl.t('project.fileNumber'),
        fixed: true,
        route: 'crm.opportunities.edit',
        routeParams: (0, _table.rowValues)('id'),
        width: _column.ColumnWidth.S
      }, {
        name: 'name',
        displayName: this.intl.t('models.operationalFile.name')
      }, {
        name: 'primeContractorShortname',
        displayName: this.intl.t('project.primeContractor'),
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'pc.name',
        displayName: this.intl.t('project.primeContractor'),
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'pc.shortName',
        displayName: this.intl.t('project.primeContractor'),
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'partnerShortname',
        displayName: this.intl.t('project.clientPartner'),
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'p.name',
        displayName: this.intl.t('project.clientPartner'),
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'p.shortName',
        displayName: this.intl.t('project.clientPartner'),
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'stateName',
        displayName: this.intl.t('project.fileState'),
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'fileStateId',
        displayName: this.intl.t('project.fileState'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'fileState'
      }, {
        name: 'probability',
        displayName: this.intl.t('forms.probability'),
        type: _types.AttributeType.Number,
        width: _column.ColumnWidth.S
      }, {
        name: 'clientPartnerId',
        displayName: this.intl.t('project.clientPartner'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'partner'
      }, {
        name: 'fileTypeId',
        displayName: this.intl.t('models.operationalFile.type'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'fileType'
      }, {
        name: 'ft.name',
        displayName: this.intl.t('models.operationalFile.type'),
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'primeContractorId',
        displayName: this.intl.t('project.primeContractor'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'partner'
      }, {
        name: 'managerUserId',
        displayName: this.intl.t('project.managerUser'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'account'
      }, {
        name: 'sponsorUserId',
        displayName: this.intl.t('project.sponsorUser'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'account'
      }, {
        name: 'projectValue',
        displayName: this.intl.t('project.projectValueThousand'),
        type: _types.AttributeType.Number,
        width: _column.ColumnWidth.S
      }, {
        name: 'weightedIncome',
        displayName: this.intl.t('project.plannedBudgetMoneyThousand'),
        type: _types.AttributeType.Number,
        width: _column.ColumnWidth.S,
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'stateChange',
        displayName: this.intl.t('models.stateChange'),
        type: _types.AttributeType.Date,
        width: _column.ColumnWidth.M
      }, {
        name: 'fileOwnerPartnerId',
        displayName: this.intl.t('project.fileOwnerPartnerCompany'),
        type: _types.AttributeType.BelongsTo,
        valuePath: 'fileOwnerPartnerName',
        orderProperty: 'fileOwnerPartnerName',
        optionsResourceName: 'partner',
        optionsQuery: async () => {
          return await this.store.query('partner', {
            filter: {
              where: {
                multiOwnerPartner: true
              }
            }
          });
        }
      }, {
        name: 'orderNumber',
        displayName: this.intl.t('project.orderNumber'),
        width: _column.ColumnWidth.S
      }, {
        name: 'subcontractor',
        displayName: this.intl.t('project.subcontractor'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.Boolean,

        formatLoopBackValue(value, filter, defaultFormatter) {
          if (filter.filterType === _types.FilterType.Boolean) {
            return value ? 1 : 0;
          }

          return defaultFormatter(value);
        }

      }, {
        name: 'closed',
        displayName: this.intl.t('models.closed'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.Boolean,

        formatLoopBackValue(value, filter, defaultFormatter) {
          if (filter.filterType === _types.FilterType.Boolean) {
            return value ? 1 : 0;
          }

          return defaultFormatter(value);
        }

      }];
    }),
    fetchParams: Ember.computed('columns', 'storage.params', function () {
      const queryObject = (0, _filters.makeQueryObject)(this.columns, this.storage.params, {});
      queryObject.filter.where.and.push({
        isActive: 1
      }, {
        type: 'normal'
      }, {
        probability: {
          lt: 100
        }
      }, {
        or: [{
          crVersion: 'null'
        }, {
          crVersion: 0
        }]
      });
      (0, _flattenEqOperators.flattenEqOperators)(queryObject.filter.where);
      return queryObject;
    })
  });

  _exports.default = _default;
});