define("iris/router", ["exports", "iris/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType
  });
  Router.map(function () {
    this.route('holiday', function () {
      this.route('confirmation', {
        path: '/:token/:verb'
      });
    });
    this.route('aiops', function () {
      this.route('dashboards', function () {
        this.route('ticket-tracking');
        this.route('solvers', function () {
          this.route('edit', {
            path: '/:solver_id'
          });
        });
        this.route('competencies', function () {
          this.route('edit', {
            path: '/:competency_id'
          });
        });
        this.route('customers', function () {
          this.route('edit', {
            path: '/:customer_id'
          });
        });
        this.route('aiops');
      });
      this.route('data', function () {
        this.route('excel');
        this.route('api');
        this.route('interface', function () {
          this.route('edit', {
            path: '/:interface_id'
          });
        });
      });
      this.route('optimalizations', function () {
        this.route('optimalizations');
        this.route('optimalization-defaults');
      });
      this.route('source-data', function () {
        this.route('solvers', function () {
          this.route('user-grade-types', function () {
            this.route('edit', {
              path: '/:user_grade_type_id'
            });
          });
          this.route('user-grades', function () {
            this.route('edit', {
              path: '/:user_grade_id'
            });
          });
          this.route('solvers', function () {
            this.route('edit', {
              path: '/:solver_id'
            });
          });
          this.route('user-absences', function () {
            this.route('edit', {
              path: '/:user_absence_id'
            });
          });
        });
        this.route('tickets', function () {
          this.route('tickets', function () {
            this.route('edit', {
              path: '/:ticket_id'
            });
          });
          this.route('operation-service-levels', function () {
            this.route('edit', {
              path: '/:operation_service_level_id'
            });
          });
          this.route('ticket-types', function () {
            this.route('edit', {
              path: '/:ticket_type_id'
            });
          });
          this.route('ticket-status', function () {
            this.route('edit', {
              path: '/:ticket_state_id'
            });
          });
        });
        this.route('customers', function () {
          this.route('customers', function () {
            this.route('edit', {
              path: '/:customer_id'
            });
          });
          this.route('severance-pay-types', function () {
            this.route('edit', {
              path: '/:severance_pay_type_id'
            });
          });
          this.route('contract-types', function () {
            this.route('edit', {
              path: '/:contract_type_id'
            });
          });
        });
      });
    });
    this.route('beta', function () {
      this.route('grid', function () {
        this.route('index', {
          path: '/'
        });
      });
      this.route('cmdb', function () {
        this.route('index', {
          path: '/'
        });
      });
      this.route('partners', function () {
        this.route('edit', {
          path: '/:partner_id'
        });
      });
      this.route('partner-bankcodes', function () {
        this.route('edit', {
          path: '/:partner_bankcode_id'
        });
      });
      this.route('menus', function () {
        this.route('edit', {
          path: '/:menu_id'
        });
      });
      this.route('sd-dashboard', function () {
        this.route('index', {
          path: '/'
        });
      });
      this.route('partner-bankcodes', function () {
        this.route('edit', {
          path: '/:partner_bankcode_id'
        });
      });
      this.route('sd-dashboard', function () {
        this.route('index', {
          path: '/'
        });
      });
    });
    this.route('crm', function () {
      this.route('partners', function () {
        this.route('edit', {
          path: '/:partner_id'
        });
      });
      this.route('contracts', function () {
        this.route('edit', {
          path: '/:contract_id'
        }, function () {
          this.route('grade-costs');
          this.route('dates');
          this.route('contacts');
          this.route('values');
          this.route('description');
          this.route('attachments');
          this.route('files');
          this.route('competencies');
        });
        this.route('new', function () {
          this.route('dates');
          this.route('contacts');
          this.route('values');
          this.route('description');
          this.route('attachments');
          this.route('files');
          this.route('grade-costs');
        });
      });
      this.route('opportunities', function () {
        this.route('index');
        this.route('edit', {
          path: '/:file_id'
        });
      });
      this.route('operational-file', function () {
        this.route('index');
        this.route('edit', {
          path: '/:file_id'
        });
      });
    });
    this.route('projects', function () {
      this.route('changes');
      this.route('finances');
      this.route('files', function () {
        this.route('edit', {
          path: '/:file_id'
        });
      });
      this.route('statements');
    });
    this.route('tasks', function () {
      this.route('work-logs', function () {
        this.route('work-log', {
          path: '/:work-log_id'
        });
        this.route('edit', {
          path: '/:work-log_id'
        });
        this.route('new');
      });
      this.route('goals-fulfillment');
      this.route('tasks', function () {
        this.route('new');
        this.route('work-logs', {
          path: '/:ticket_id'
        }, function () {
          this.route('edit', {
            path: 'work-log/:work-log_id'
          });
          this.route('new');
        });
      });
      this.route('delegates', function () {
        this.route('new');
        this.route('work-logs', {
          path: '/:ticket_id'
        });
      });
      this.route('availabilities', function () {
        this.route('edit', {
          path: '/:availability_id'
        });
      });
      this.route('availability-generate');
      this.route('time-off', function () {
        this.route('edit', {
          path: '/:id'
        });
        this.route('new');
      });
    });
    this.route('finances', function () {
      this.route('pipelines', function () {
        this.route('dashboard');
        this.route('list');
        this.route('contracted');
        this.route('opportunities');
      });
      this.route('invoices', function () {
        this.route('new');
        this.route('storno', {
          path: '/storno/:invoice_id'
        });
        this.route('correcting-invoice', {
          path: '/correcting-invoice/:invoice_id'
        });
        this.route('edit', {
          path: '/edit/:invoice_id'
        });
        this.route('report');
        this.route('view-pdf', {
          path: '/view-pdf/:invoice_id'
        });
        this.route('invoice-payments', {
          path: '/invoice-payments/:invoices'
        });
        this.route('copy', {
          path: '/copy/:invoice_id'
        });
        this.route('advance-invoice');
        this.route('final-invoice', {
          path: '/final-invoice/:invoice_id'
        });
        this.route('prompt', {
          path: '/prompt/:invoices'
        });
      });
      this.route('incoming-invoices', function () {
        this.route('new');
        this.route('invoice-payments', {
          path: '/invoice-payments/:invoices'
        });
        this.route('edit', {
          path: '/edit/:invoice_id'
        });
        this.route('forward-to-accounting', {
          path: '/forward-to-accounting/:invoices'
        });
        this.route('copy', {
          path: '/copy/:invoice_id'
        });
      });
      this.route('bank-balances', function () {
        this.route('edit', {
          path: '/:id'
        });
      });
    });
    this.route('reports', function () {
      this.route('moves');
      this.route('warranties');
      this.route('inventories', function () {
        this.route('contacts', {
          path: '/:inventory_id'
        }, function () {
          this.route('items', {
            path: '/:inventory_contact_id'
          }, function () {
            this.route('list');
            this.route('moves');
          });
        });
        this.route('new');
      });
      this.route('engineer-utility-report');
      this.route('projects');
      this.route('pentaho', {
        path: '/:path'
      });
    });
    this.route('sd');
    this.route('parameters');
    this.route('crm.quotations');
    this.route('login');
    this.route('forgotten-password');
    this.route('settings', function () {
      this.route('aiops', function () {
        this.route('partners', function () {
          this.route('edit', {
            path: '/:partner_id'
          });
        });
        this.route('partner-types', function () {
          this.route('edit', {
            path: '/:partner_type_id'
          });
        });
        this.route('day-types', function () {
          this.route('edit', {
            path: '/:day_type_id'
          });
        });
        this.route('parameter-types', function () {
          this.route('edit', {
            path: '/:parameter_type_id'
          });
        });
        this.route('parameter-categories', function () {
          this.route('edit', {
            path: '/:parameter_category_id'
          });
        });
        this.route('optimization-item-types', function () {
          this.route('edit', {
            path: '/:optimization_item_type_id'
          });
        });
        this.route('optimization-parameters', function () {
          this.route('edit', {
            path: '/:optimization_parameter_id'
          });
        });
      });
      this.route('cron-jobs');
      this.route('acl', function () {
        this.route('roles', function () {
          this.route('edit', {
            path: '/:role_id'
          });
        });
        this.route('menus', function () {
          this.route('edit', {
            path: '/:menu_id'
          });
        });
        this.route('actions');
        this.route('finance-actions');
        this.route('acl', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
      });
      this.route('user-grades', function () {
        this.route('new');
        this.route('edit', {
          path: '/:user_grade_id'
        });
      });
      this.route('users', function () {
        this.route('edit', {
          path: '/:user_id'
        });
      });
      this.route('sectors');
      this.route('cmdb', function () {
        this.route('manufacturers', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('types', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('entity-categories', function () {
          this.route('new');
          this.route('edit', {
            path: '/:entity_category_id'
          });
        });
        this.route('entity-types', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('contact-types', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('code-stores', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('entity-statuses', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
      });
      this.route('grade-costs', function () {
        this.route('edit', {
          path: '/:id'
        });
      });
      this.route('file-types', function () {
        this.route('edit', {
          path: '/:file_type_id'
        });
      });
      this.route('file-states', function () {
        this.route('edit', {
          path: '/:file_state_id'
        });
      });
      this.route('milestones');
      this.route('regions', function () {
        this.route('edit', {
          path: '/:id'
        });
      });
      this.route('payments', function () {
        this.route('edit', {
          path: '/:id'
        });
      });
      this.route('news-feed', function () {
        this.route('edit', {
          path: '/:news_id'
        });
        this.route('new');
      });
      this.route('operations', function () {
        this.route('report-types', function () {
          this.route('edit', {
            path: '/:report_type_id'
          });
        });
        this.route('fetchmail', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('solving-group', function () {
          this.route('edit', {
            path: '/:solving_group_id'
          });
        });
        this.route('service-areas', function () {
          this.route('edit', {
            path: '/:id'
          });
          this.route('new');
        });
        this.route('report-types', function () {
          this.route('edit', {
            path: '/:report_type_id'
          });
          this.route('new');
        });
        this.route('statuses', function () {
          this.route('edit', {
            path: '/:status_id'
          });
        });
        this.route('ticket-states', function () {
          this.route('edit', {
            path: '/:ticket_state_id'
          });
        });
        this.route('ticket-audit-list', function () {
          this.route('edit', {
            path: '/:id'
          }, function () {
            this.route('new-item');
            this.route('edit-item', {
              path: '/:id'
            });
          });
          this.route('new');
        });
        this.route('service-periods', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('service-levels', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('operation-service-level-type', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('operation-service-level-type-manager', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('repair-claims', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('resource-groups', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('resource-plan-priorities', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
      });
      this.route('finances', function () {
        this.route('invoice-types', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('invoice-categories', function () {
          this.route('new');
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('uoms', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('vats', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('nav-settings', function () {
          this.route('new');
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('tax-number-patterns', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('payment-methods', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
      });
      this.route('partners', function () {
        this.route('countries', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('partner-sectors', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
      });
      this.route('manage-time-offs', function () {
        this.route('edit', {
          path: '/:id'
        });
      });
      this.route('priority', function () {
        this.route('edit', {
          path: '/:id'
        });
      });
      this.route('priority-urgency', function () {
        this.route('edit', {
          path: '/:id'
        });
      });
      this.route('priority-importance', function () {
        this.route('edit', {
          path: '/:id'
        });
      });
      this.route('priority-matrix', function () {
        this.route('edit', {
          path: '/:id'
        });
      });
      this.route('date-type-definitions', function () {
        this.route('edit', {
          path: '/:id'
        });
      });
      this.route('date-types', function () {
        this.route('edit', {
          path: '/:id'
        });
        this.route('new');
      });
      this.route('document-types', function () {
        this.route('edit', {
          path: '/:id'
        });
        this.route('new');
      });
      this.route('integrations', function () {
        this.route('services', function () {
          this.route('edit', {
            path: '/:id'
          });
          this.route('new');
        });
        this.route('mappers', function () {
          this.route('edit', {
            path: '/:id'
          });
          this.route('new');
        });
      });
      this.route('file');
      this.route('project', function () {
        this.route('project-ticket-templates', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('product-result-templates', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('project-ticket-template-groups', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('file-business-types', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('profit-centers', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
      });
    });
    this.route('cmdb', function () {
      this.route('entity-types', function () {
        this.route('entities', {
          path: '/:entity_type_id'
        }, function () {
          this.route('index');
          this.route('entity', {
            path: '/:entity_id'
          }, function () {
            this.route('overview');
            this.route('contacts');
            this.route('attributes', function () {
              this.route('code-store', {
                path: '/:value_id'
              });
            });
            this.route('sites');
            this.route('moves');
            this.route('relations');
            this.route('software', function () {
              this.route('edit', {
                path: '/:software_id'
              }, function () {
                this.route('attributes', function () {
                  this.route('code-store', {
                    path: '/:value_id'
                  });
                });
                this.route('software', function () {
                  this.route('edit', {
                    path: '/:sub_software_id'
                  });
                  this.route('new');
                });
              });
              this.route('new', function () {
                this.route('attributes', function () {
                  this.route('code-store', {
                    path: '/:value_id'
                  });
                });
                this.route('software', function () {
                  this.route('new');
                  this.route('edit', {
                    path: '/:sub_software_id'
                  });
                });
              });
            });
            this.route('procedures');
            this.route('documents');
            this.route('licensed-entity');
            this.route('licenses');
            this.route('xwiki');
          });
          this.route('new-entity', function () {
            this.route('overview');
            this.route('contacts');
            this.route('attributes', function () {
              this.route('code-store', {
                path: '/:value_id'
              });
            });
            this.route('sites');
            this.route('moves');
            this.route('relations');
            this.route('software', function () {
              this.route('new', function () {
                this.route('attributes', function () {
                  this.route('code-store', {
                    path: '/:value_id'
                  });
                });
                this.route('software', function () {
                  this.route('edit', {
                    path: '/:sub_software_id'
                  });
                  this.route('new');
                });
              });
              this.route('edit', {
                path: '/:software_id'
              }, function () {
                this.route('attributes', function () {
                  this.route('code-store', {
                    path: '/:value_id'
                  });
                });
                this.route('software', function () {
                  this.route('new');
                  this.route('edit', {
                    path: '/:sub_software_id'
                  });
                });
              });
            });
            this.route('documents');
            this.route('licensed-entity');
          });
        });
        this.route('new-entity');
        this.route('entity', function () {});
      });
      this.route('procedures', function () {
        this.route('overview');
        this.route('changes');
      });
      this.route('certifications');
      this.route('documents');
      this.route('guarantees');
      this.route('supports');
      this.route('visualisation', {
        path: 'visualisation/:id'
      });
      this.route('impact-study', {
        path: 'impact-study/:id'
      });
      this.route('licenses');
    });
    this.route('loading');
    this.route('organizations', function () {
      this.route('partners', function () {
        this.route('new', function () {
          this.route('overview');
          this.route('bank-accounts', function () {
            this.route('new');
            this.route('edit', {
              path: '/:bank_id'
            });
          });
          this.route('addresses', function () {
            this.route('new');
            this.route('edit', {
              path: '/:address_id'
            });
          });
        });
        this.route('edit', {
          path: '/:partner_id'
        }, function () {
          this.route('overview');
          this.route('bank-accounts', function () {
            this.route('new');
            this.route('edit', {
              path: '/:bank_id'
            });
          });
          this.route('addresses', function () {
            this.route('new');
            this.route('edit', {
              path: '/:address_id'
            });
          });
        });
      });
      this.route('departments', function () {
        this.route('edit', {
          path: '/:department_id'
        });
      });
      this.route('contacts', function () {
        this.route('edit', {
          path: '/:contact_id'
        });
      });
      this.route('sites', function () {
        this.route('buildings', function () {
          this.route('edit', {
            path: '/:building_id'
          });
        });
        this.route('floors', function () {
          this.route('edit', {
            path: '/:floor_id'
          });
        });
        this.route('locations', function () {
          this.route('edit', {
            path: '/:location_id'
          });
        });
        this.route('site-zones', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
      });
      this.route('notification-groups', function () {
        this.route('edit', {
          path: '/:id'
        });
      });
    });
    this.route('inventories');
    this.route('tickets');
    this.route('report', function () {});
    this.route('confirmation', {
      path: 'confirmation/:token/:verb'
    });
    this.route('dashboard', function () {
      this.route('ticket', {
        path: '/:id'
      }, function () {
        this.route('work-logs', function () {
          this.route('new');
          this.route('edit', {
            path: 'work-log/:work-log_id'
          });
        });
      });
    });
    this.route('change-log', function () {
      this.route('add-to-news');
    });
    this.route('not-found', {
      path: '/*path'
    });
    this.route('operation', function () {
      this.route('sd-dashboard', function () {
        this.route('index', {
          path: '/'
        });
        this.route('new');
        this.route('new-aiops');
        this.route('edit', {
          path: '/:id'
        });
        this.route('edit-preticket', {
          path: '/pretickets/:id'
        });
      });
      this.route('report-submit', function () {
        this.route('documents');
        this.route('new-sub-ticket');
        this.route('remote-access-informations');
        this.route('partner-entities');
        this.route('close-report');
        this.route('former-reports', {
          path: '/:contact_id'
        });
        this.route('report-as-another-user');
        this.route('work-log');
        this.route('other-information');
      });
      this.route('operational-file', function () {
        this.route('edit', {
          path: '/:file_id'
        });
      });
      this.route('application-to-intervene', function () {
        this.route('new', function () {
          this.route('proceeding');
          this.route('persons');
          this.route('descriptions');
          this.route('overview');
          this.route('intervals');
        });
        this.route('edit', {
          path: '/:ticket_id'
        }, function () {
          this.route('overview');
          this.route('intervals');
          this.route('descriptions');
          this.route('persons');
          this.route('proceeding');
        });
      });
      this.route('access-requests', function () {
        this.route('new', function () {
          this.route('overview');
          this.route('entities');
          this.route('parameters');
          this.route('persons');
        });
      });
      this.route('portal-dashboard', function () {
        this.route('new-incident');
        this.route('edit', {
          path: '/:id'
        });
        this.route('pre-reports');
        this.route('processed-reports');
        this.route('closed-reports');
        this.route('solved-reports');
      });
      this.route('scheduled-tasks', function () {
        this.route('new');
        this.route('edit', {
          path: '/:id'
        });
      });
      this.route('change-requests', function () {
        this.route('new');
        this.route('edit', {
          path: '/:id'
        });
      });
      this.route('duties');
    });
    this.route('unauth', function () {
      this.route('confirmation', function () {
        this.route('application-to-intervene', {
          path: 'application-to-intervene/:token'
        });
        this.route('access-request', {
          path: 'access-request/:ticket_id'
        }, function () {});
        this.route('change-request', {
          path: 'change-request/:token'
        });
        this.route('portal-registration', {
          path: 'portal-registration/:token'
        });
      });
      this.route('forgotten-password', {
        path: 'forgotten-password/:token'
      });
      this.route('contact-registration');
    });
    this.route('forbidden');
    this.route('human-resource-management', function () {
      this.route('my-events', function () {
        this.route('new');
        this.route('edit', {
          path: '/:id'
        });
      });
      this.route('event-approvement', function () {
        this.route('edit', {
          path: '/:id'
        });
      });
      this.route('event-manager', function () {
        this.route('account', {
          path: '/accounts/:id'
        });
      });
      this.route('resource-periods', function () {
        this.route('edit', {
          path: '/:id'
        });
      });
      this.route('resource-planner', {
        path: '/:id'
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});