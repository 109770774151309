define("iris/templates/cmdb/entity-types/entities/entity/licenses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wFEDu6aq",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"tables/entity-licenses\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[1,[22,\"liquid-outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/entity-types/entities/entity/licenses.hbs"
    }
  });

  _exports.default = _default;
});