define("iris/models/project-ticket-template", ["exports", "@ember-data/model", "ember-cp-validations", "ember-data/relationships"], function (_exports, _model, _emberCpValidations, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    subject: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    plannedExpensesInHour: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    maxHoursPerWorkLog: (0, _emberCpValidations.validator)('presence', {
      presence: true
    })
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _model.attr)('string'),
    subject: (0, _model.attr)('string'),
    description: (0, _model.attr)('string', {
      formWidget: 'textarea'
    }),
    priority: (0, _model.attr)('number', {
      defaultValue: 3
    }),
    active: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    plannedExpensesInHour: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    maxHoursPerWorkLog: (0, _model.attr)('number', {
      defaultValue: 10
    }),
    plannedEndDate: (0, _model.attr)('date'),
    productResultTemplate: (0, _relationships.belongsTo)('productResultTemplate'),
    projectTicketTemplateGroup: (0, _relationships.belongsTo)('projectTicketTemplateGroup'),
    plannedUserGrade: (0, _relationships.belongsTo)('userGrade')
  });

  _exports.default = _default;
});