define("iris/utils/create-record", ["exports", "iris/utils/finances"], function (_exports, _finances) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setNavConnection = _exports.setInvoiceBankCode = _exports.createPartner = _exports.createInvoice = _exports.createInventory = _exports.createIncomingInvoice = _exports.createFileOutgoingProposal = _exports.createDutyUtil = _exports.createChangeRequest = void 0;

  const createDutyUtil = async (store, intl, solverGroupId) => {
    const solverGroup = await store.find('solvingGroup', solverGroupId);
    const duty = store.createRecord('duty', {
      solverGroup
    });
    const moDutyDay = store.createRecord('dutyDay');
    const tuDutyDay = store.createRecord('dutyDay');
    const weDutyDay = store.createRecord('dutyDay');
    const thDutyDay = store.createRecord('dutyDay');
    const frDutyDay = store.createRecord('dutyDay');
    const saDutyDay = store.createRecord('dutyDay');
    const suDutyDay = store.createRecord('dutyDay');
    duty.set('moDutyDay', moDutyDay);
    duty.set('tuDutyDay', tuDutyDay);
    duty.set('weDutyDay', weDutyDay);
    duty.set('thDutyDay', thDutyDay);
    duty.set('frDutyDay', frDutyDay);
    duty.set('saDutyDay', saDutyDay);
    duty.set('suDutyDay', suDutyDay);
    return duty;
  };

  _exports.createDutyUtil = createDutyUtil;

  const createChangeRequest = async store => {
    const state = await store.find('changeRequestState', 'SYS_01'); //  draft

    const riskLevel = await store.find('changeRequestRiskLevel', 'SYS_03');
    const priority = await store.find('changeRequestPriority', 'SYS_01');
    const type = await store.find('changeRequestType', 'SYS_01');
    const impact = await store.find('changeRequestImpact', 'SYS_02');
    return store.createRecord('changeRequest', {
      state,
      priority,
      type,
      impact,
      riskDefaultLevel: riskLevel,
      riskInterventionLevel: riskLevel,
      riskInfraLevel: riskLevel
    });
  };

  _exports.createChangeRequest = createChangeRequest;

  const createPartner = async store => {
    const currencyPayment = await store.find('currency', 'SYS_01'); //  HUF

    const invoicePayment = await store.find('paymentMethod', 'SYS_04'); //  bank transfer

    return store.createRecord('partner', {
      currencyPayment,
      invoicePayment
    });
  };
  /**
   * Creates an Invoice record, where the default values based on the option
   * ```JS
   * // option
   * {
   *  project: <ED project record>,
   *  currency: <ED currency record>
   * }
   * ```
   *
   * @param {*} store
   * @param {*} [options={}]
   * @return {*} invoice instance
   */


  _exports.createPartner = createPartner;

  const createInvoice = async function (store) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const data = {
      invoiceType: await store.find('invoiceType', 'SYS_01'),
      invoiceOwner: null
    };
    const {
      region,
      project
    } = options;

    if (region) {
      data.invoiceOwner = region.get('ownerPartner');
    }

    if (project) {
      data.preInvoice = true;
      data.profitCenter = await project.get('profitCenter');
      data.fileBusinessType = await project.get('fileBusinessType');
      data.fileType = await project.get('fileType');
      data.project = project;
      data.subject = project.get('name');
      data.partner = project.get('clientPartner');
      data.invoiceOwner = project.get('fileOwnerPartner');
    }

    if (data.invoiceOwner === null) {
      throw new Error(`Can not create Invoice, no invoiceOwner`);
    }

    data.currency = options.currency || data.invoiceOwner.get('currencyPayment');
    data.paymentMethod = data.invoiceOwner.get('invoicePayment');
    const invoice = store.createRecord('invoice', data);

    if (region) {
      let items = await invoice.get('items');
      items.pushObject(store.createRecord('invoiceItem'));
    }

    await Promise.all([setInvoiceBankCode(store, invoice), setNavConnection(store, invoice)]);
    return invoice;
  };

  _exports.createInvoice = createInvoice;

  const createIncomingInvoice = async function (store) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const data = {
      invoiceType: await store.find('invoiceType', 'SYS_01'),
      invoiceOwner: null
    };
    const {
      region
    } = options;

    if (region) {
      data.invoiceOwner = region.get('ownerPartner');
    }

    if (data.invoiceOwner === null) {
      throw new Error(`Can not create Invoice, no invoiceOwner`);
    }

    data.currency = options.currency || data.invoiceOwner.get('currencyPayment');
    data.paymentMethod = data.invoiceOwner.get('invoicePayment');
    const incomingInvoice = store.createRecord('incomingInvoice', data);
    const items = await incomingInvoice.get('items');
    items.pushObject(store.createRecord('incomingInvoiceItem'));
    return incomingInvoice;
  };
  /**
   * Picks the first default bank code of the invoice's owner
   *
   * @param {*} store EmberData store
   * @param {*} invoice ED instance
   */


  _exports.createIncomingInvoice = createIncomingInvoice;

  const setInvoiceBankCode = async (store, invoice) => {
    const partnerBankcodes = await invoice.get('invoiceOwner.partnerBankcodes');
    const [bankCode] = partnerBankcodes.filter(b => b.defaultBank);
    invoice.set('bankCode', bankCode);
  };
  /**
   *  Finds the navConnections of the invoice's owner.
   *  If there is only one result, it sets it on the invoice, in other case it nulls out the relationship
   *
   * @param {*} store EmberData store
   * @param {*} invoice ED instance
   */


  _exports.setInvoiceBankCode = setInvoiceBankCode;

  const setNavConnection = async (store, invoice) => {
    const invoiceOwnerId = invoice.belongsTo('invoiceOwner').id();
    const navConnections = await store.query('nav-setting', {
      filter: {
        where: {
          partnerId: invoiceOwnerId
        }
      }
    });
    const navConnection = navConnections.length === 1 ? navConnections.get('firstObject') : null;
    invoice.set('navConnection', navConnection);
  };

  _exports.setNavConnection = setNavConnection;

  const createFileOutgoingProposal = async (store, intl, session, file) => {
    const description = intl.t('file-outgoing-proposal.defaultDescription');
    const currency = await (0, _finances.getSessionDefaultCurrency)(session);
    return store.createRecord('fileOutgoingProposal', {
      currency,
      file,
      description
    });
  };

  _exports.createFileOutgoingProposal = createFileOutgoingProposal;

  const createInventory = async (store, session) => {
    const region = session.get('region');
    const where = {
      and: [{
        regionId: region.id
      }, {
        settingKey: 'forAssignedContact'
      }]
    };
    const settings = await store.query('regionalSetting', {
      filter: {
        where: where
      }
    });
    const forAssignedContact = settings.length ? !!settings.firstObject.settingValue : false;
    return store.createRecord('inventory', {
      forAssignedContact
    });
  };

  _exports.createInventory = createInventory;
});