define("iris/templates/settings/integrations/services/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VkxM5KzL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mt-4\"],[8],[0,\"\\n  \"],[5,\"bs-button\",[],[[\"@type\",\"@text\",\"@action\",\"@value\",\"@class\"],[\"primary\",[28,\"t\",[\"buttons.new-integration\"],null],[28,\"route-action\",[\"transitionTo\"],null],\"settings.integrations.services.new\",\"mb-10\"]]],[0,\"\\n\\n  \"],[5,\"integrations/services\",[],[[],[]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/integrations/services/index.hbs"
    }
  });

  _exports.default = _default;
});