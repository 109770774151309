define("iris/adapters/unauth", ["exports", "ember-inflector", "ember-data/adapters/json-api", "iris/config/environment"], function (_exports, _emberInflector, _jsonApi, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    host: _environment.default.APP.host,
    namespace: _environment.default.APP.namespace,

    pathForType(modelName) {
      var camelized = Ember.String.camelize(modelName);
      return (0, _emberInflector.pluralize)(camelized);
    }

  });

  _exports.default = _default;
});