define("iris/models/document-type-competency", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    read: (0, _attr.default)('boolean'),
    delete: (0, _attr.default)('boolean'),
    competency: (0, _relationships.belongsTo)('competency'),
    documentType: (0, _relationships.belongsTo)('documentType')
  });

  _exports.default = _default;
});