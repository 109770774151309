define("iris/models/event-type", ["exports", "ember-data/model", "ember-data/attr", "iris/utils/enum"], function (_exports, _model, _attr, _enum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EventTypeId = void 0;
  const EventTypeId = (0, _enum.makeEnum)([{
    Vacation: 'SYS_01'
  }]);
  _exports.EventTypeId = EventTypeId;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    color: (0, _attr.default)('string'),
    shadedColor: (0, _attr.default)('string')
  });

  _exports.default = _default;
});