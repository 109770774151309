define("iris/templates/cmdb/entity-types/entities/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9ykNL/cC",
    "block": "{\"symbols\":[],\"statements\":[[5,\"beta/cmdb\",[],[[\"@pageTitle\",\"@entityTypeId\"],[[28,\"concat\",[[23,0,[\"model\",\"entityTypeCategory\",\"name\"]],\" / \",[23,0,[\"model\",\"name\"]]],null],[23,0,[\"model\",\"id\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/entity-types/entities/index.hbs"
    }
  });

  _exports.default = _default;
});