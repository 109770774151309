define("iris/templates/human-resource-management/resource-periods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v7xCEjq3",
    "block": "{\"symbols\":[],\"statements\":[[5,\"hrm/resource-period-manager\",[],[[\"@periods\",\"@isLoading\",\"@preSelectedPeriod\",\"@publicHolidays\"],[[23,0,[\"periods\"]],[23,0,[\"isLoading\"]],[23,0,[\"model\",\"preSelectedPeriod\"]],[23,0,[\"model\",\"publicHolidays\"]]]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/human-resource-management/resource-periods.hbs"
    }
  });

  _exports.default = _default;
});