define("iris/templates/components/bs-datetimepicker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c6y6JND+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"input\",null,[[\"type\",\"class\",\"disabled\",\"name\",\"placeholder\"],[\"text\",\"form-control\",[24,[\"disabled\"]],[24,[\"textFieldName\"]],[24,[\"placeholder\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[4,\"unless\",[[24,[\"noIcon\"]]],null,{\"statements\":[[7,\"span\",true],[10,\"class\",\"input-group-addon\"],[8],[0,\"\\n  \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"icons\",\"date\"]],[24,[\"icons\",\"date\"]],[24,[\"dateIcon\"]]],null]]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/bs-datetimepicker.hbs"
    }
  });

  _exports.default = _default;
});