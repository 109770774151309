define("iris/components/forms/file-form/account-multi-select/component", ["exports", "iris/utils/properties"], function (_exports, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    searchTerm: '',
    setSearchTermTask: (0, _properties.debounceWithTask)('searchTerm'),
    searchCondition: Ember.computed('searchTerm', function () {
      let searchTerm = this.get('searchTerm') || '';

      if (Ember.isBlank(searchTerm)) {
        return null;
      }

      let terms = searchTerm.split(/\s+/);
      let longNameIlikes = terms.map(term => ({
        longName: {
          ilike: `%${term}%`
        }
      }));
      return {
        where: {
          and: [{
            enabled: 1
          }, {
            and: longNameIlikes
          }, {
            id: {
              nin: this.get('selected').mapBy('id')
            }
          }, {
            contactId: null
          }]
        }
      };
    }),
    actions: {
      excludeSelectedOptions(selected, options) {
        return options.filter(option => !selected.mapBy('id').includes(option.id));
      }

    }
  });

  _exports.default = _default;
});