define("iris/components/forms/settings-cmdb-entity-type", ["exports", "pro-slugs", "iris/lib/modals", "iris/utils/visualisation"], function (_exports, _proSlugs, _modals, _visualisation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    defaultIcon: _visualisation.defaultIcon,
    modals: Ember.inject.service(),
    intl: Ember.inject.service(),
    types: Ember.computed('entityTypes', function () {
      let entityTypes = this.get('entityTypes');
      let model = this.get('model');
      return entityTypes.filter(et => {
        return et.get('codeStore') === 0 && et.get('parent.content') === null && et != model;
      }).sortBy('longName');
    }),
    mode: 'new',
    tabs: Ember.computed('mode', function () {
      let mode = this.get('mode');
      return [{
        name: this.intl.t('entity-type.entityAttributes'),
        route: 'settings.cmdb.entity-types.' + mode + '.attributes'
      }, {
        name: this.intl.t('entity-type.relations'),
        route: 'settings.cmdb.entity-types.' + mode + '.relations'
      }, {
        name: this.intl.t('entity-type.approvals'),
        route: 'settings.cmdb.entity-types.' + mode + '.approvals'
      }];
    }),
    actions: {
      changeEntityTypeCategory: function (selected) {
        let model = this.get('model');
        model.set('entityTypeCategory', selected);

        if (selected.get('category') === 'software') {
          model.set('generateName', true);
        }
      },
      changeEntityTypeTemplate: async function (selected) {
        let model = this.get('model');
        this.set('entityTypeTemplate', selected);
        let parentCategory = selected.get('entityTypeCategory');
        model.set('entityTypeCategory', parentCategory);

        if (parentCategory.get('category') === 'software') {
          model.set('generateName', true);
        }

        model.set('name', selected.get('name'));
        model.set('code', selected.get('code'));
        model.set('icingaName', selected.get('icingaName'));

        if (model.get('isNew')) {
          let attributes = await selected.get('entityAttributes');
          let copy = null;
          let copiedAttributes = Ember.A();
          attributes.forEach(async function (attribute) {
            copy = await attribute.copy();
            await copy.set('entityType', model);
            copiedAttributes.pushObject(copy);
          });
          model.set('entityAttributes', copiedAttributes);
        }
      },
      setParent: async function (selected) {
        let model = this.get('model');
        model.set('parent', selected);
        let parentCategory = selected.get('entityTypeCategory');
        model.set('entityTypeCategory', parentCategory);

        if (parentCategory.get('category') === 'software') {
          model.set('generateName', true);
        }

        if (model.get('isNew')) {
          let attributes = await selected.get('entityAttributes');
          let copy = null;
          let copiedAttributes = Ember.A();
          attributes.forEach(async function (attribute) {
            copy = await attribute.copy();
            await copy.set('entityType', model);
            copiedAttributes.pushObject(copy);
          });
          model.set('entityAttributes', copiedAttributes);
        }
      },

      changeName() {
        let model = this.get('model');
        let name = this.get('model.name');
        let icingaName = this.get('model.icingaName');

        if (Ember.isEmpty(icingaName)) {
          model.set('icingaName', (0, _proSlugs.default)(name).replace(RegExp('-', 'g'), '_'));
        }
      },

      async openModal() {
        const selectedEntityTypeVisualisation = await this.modals.open('modals/visualisation-icon', {}, {
          className: _modals.ModalLayoutClassName.XXL
        });

        if (selectedEntityTypeVisualisation === false) {
          return;
        }

        this.model.set('entityTypeVisualisation', selectedEntityTypeVisualisation);
      }

    }
  });

  _exports.default = _default;
});