define("iris/components/change-requests/edit/notifications/person/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    intl: Ember.inject.service(),

    async init() {
      this._super();

      this.createRecord(); // get accounts

      const accounts = Ember.A();
      const project = await this.model.get('project');
      const fileSolverGroups = await project.get('fileSolverGroups');

      for (const fileSolverGroup of fileSolverGroups.toArray()) {
        const solvingGroup = await fileSolverGroup.get('solvingGroup');
        const accs = await solvingGroup.get('accounts');
        accounts.addObjects(accs.toArray());
      }

      this.set('accounts', accounts);
    },

    account: null,
    contact: null,
    lastNotification: null,
    partnerId: Ember.computed.alias('model.project.primeContractor.id'),
    sources: Ember.computed(function () {
      return Ember.A([{
        id: 1,
        name: this.intl.t('operation.change-request.fromPartner')
      }, {
        id: 2,
        name: this.intl.t('operation.change-request.fromProvider')
      }, {
        id: 3,
        name: this.intl.t('operation.change-request.fromThirdParty')
      }]);
    }),
    types: Ember.computed(function () {
      return Ember.A([{
        id: 1,
        name: this.intl.t('operation.change-request.approver')
      }, {
        id: 2,
        name: this.intl.t('operation.change-request.contact')
      }]);
    }),
    selectedSource: Ember.computed('notification.source', 'sources', function () {
      return this.sources.find(s => {
        var _this$notification;

        return s.id === ((_this$notification = this.notification) === null || _this$notification === void 0 ? void 0 : _this$notification.source);
      });
    }),
    selectedType: Ember.computed('notification.type', 'types', function () {
      return this.types.find(t => {
        var _this$notification2;

        return t.id === ((_this$notification2 = this.notification) === null || _this$notification2 === void 0 ? void 0 : _this$notification2.type);
      });
    }),

    createRecord() {
      var _this$lastNotificatio, _this$lastNotificatio2;

      const notification = this.store.createRecord('changeRequestNotification', {
        changeRequest: this.model,
        type: ((_this$lastNotificatio = this.lastNotification) === null || _this$lastNotificatio === void 0 ? void 0 : _this$lastNotificatio.type) || 1,
        source: ((_this$lastNotificatio2 = this.lastNotification) === null || _this$lastNotificatio2 === void 0 ? void 0 : _this$lastNotificatio2.source) || 1
      });
      this.set('notification', notification);
    },

    cleanUp() {
      this.set('contact', null), this.set('account', null);
    },

    addButtonDisabled: Ember.computed('notification.{source,name,email}', 'contact', 'account', function () {
      if (this.notification.source === 1 && !this.contact || this.notification.source === 2 && !this.account || this.notification.source === 3 && (!this.notification.email || !this.notification.name)) {
        return true;
      }

      return false;
    }),
    actions: {
      changeSource(source) {
        this.notification.set('source', source.id);
        this.cleanUp();
      },

      changeType(type) {
        this.notification.set('type', type.id);
      },

      async addNotification() {
        // contact
        if (this.notification.source === 1) {
          this.notification.set('name', this.contact.name);
          this.notification.set('email', this.contact.email);
        } // account


        if (this.notification.source === 2) {
          this.notification.set('name', this.account.longName);
          this.notification.set('email', this.account.email);
        }

        const result = await this.notification.save();
        this.set('lastNotification', result);

        if (result) {
          this.flashMessages.info(this.intl.t('operation.change-request.personAdded'), {
            title: this.intl.t('general.save.success-title'),
            icon: 'times'
          });
          this.cleanUp();
          this.createRecord();
        }
      }

    }
  });

  _exports.default = _default;
});