define("iris/abilities/route", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    sessionAccount: Ember.inject.service(),
    log: false,
    canRead: Ember.computed('sessionAccount.routes', function () {
      let routeNames = this.get('sessionAccount.routes');
      let account = this.get('sessionAccount.account');
      if (!routeNames) return false;
      let route = this.get('route');
      let objId = this.get('objId');
      let log = this.get('log');
      let actionRoute = '';
      let actionObjId = '';
      let res = Ember.A();
      let routes = Ember.A();

      if (log) {
        // eslint-disable-next-line no-console
        console.log(actions); // eslint-disable-next-line no-console

        console.log(routeNames);
      }

      if (objId === null) {
        return true;
      }

      res = routeNames.filter(a => {
        if (Ember.typeOf(a) === 'instance') {
          actionRoute = a.get('route');
          actionObjId = a.get('objId');
        } else {
          actionRoute = a.route;
          actionObjId = a.obj_id;
        } //Admin


        if (account.get('admin')) {
          return true;
        }

        routes = actionRoute.split(','); //File Rights

        if (objId) {
          return routes.contains(route) && actionObjId === objId;
        } else {
          //Globális jogok
          return routes.contains(route) && actionObjId === '000';
        }
      });
      return res.length;
    }),
    canWhich: Ember.computed('sessionAccount.routes', function () {
      let routeNames = this.get('sessionAccount.routes');
      let account = this.get('sessionAccount.account');
      if (!routeNames) return false;
      let tabs = this.get('tabs');
      let objId = this.get('objId');
      let actionRoute = '';
      let actionObjId = '';
      let routes = Ember.A();

      if (objId === null) {
        return tabs.get('firstObject.route');
      }

      let res = false;
      tabs.some(function (tab) {
        let route = tab.route;
        routeNames.some(function (a) {
          if (Ember.typeOf(a) === 'instance') {
            actionRoute = a.get('route');
            actionObjId = a.get('objId');
          } else {
            actionRoute = a.route;
            actionObjId = a.obj_id;
          } //Admin


          if (account.get('admin')) {
            res = route;
            return true;
          }

          routes = actionRoute.split(',');

          if (routes.contains(route) && actionObjId === objId) {
            res = route;
            return true;
          }
        });
        if (res) return true;
      });
      return res;
    })
  });

  _exports.default = _default;
});