define("iris/components/integrations/services/component", ["exports", "iris/lib/filters", "iris/lib/filters/column", "iris/lib/filters/storage", "iris/lib/filters/types", "iris/lib/table"], function (_exports, _filters, _column, _storage, _types, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    storage: (0, _storage.useStorage)(),
    columns: (0, _column.columns)('customAttributes', function () {
      return [{
        name: 'name',
        displayName: this.intl.t('forms.name-colon'),
        fixed: true,
        route: 'settings.integrations.services.edit',
        routeParams: (0, _table.rowValues)('id')
      }, {
        name: 'irisProjectId',
        displayName: this.intl.t('forms.project'),
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'project',
        valuePath: 'project.name',
        route: 'operation.operational-file.edit',
        routeParams: (0, _table.rowValues)('project.id')
      }, {
        name: 'typeId',
        displayName: this.intl.t('forms.type')
      }, {
        name: 'baseUrl',
        displayName: this.intl.t('forms.base-url')
      }, {
        name: 'cron',
        displayName: this.intl.t('cron-job.cron')
      }, {
        name: 'isActive',
        displayName: this.intl.t('models.isActive'),
        type: _types.AttributeType.Boolean
      }];
    }),
    rowActions: (0, _table.rowActions)(function () {
      return [];
    }),
    fetchParams: Ember.computed('columns', 'storage.params', function () {
      let {
        filter
      } = (0, _filters.makeQueryObject)(this.columns, this.storage.params);
      return {
        filter: { ...filter,
          include: ['project']
        }
      };
    })
  });

  _exports.default = _default;
});