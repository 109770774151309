define("iris/models/date-type-file", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    includes: [(0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('length', {
      min: 1,
      messageKey: 'errors.has-many-add'
    })]
  });

  var _default = _model.default.extend(Validations, {
    changeTracker: {
      trackHasMany: true
    },
    name: (0, _attr.default)('string'),
    file: (0, _relationships.belongsTo)('project'),
    excludes: (0, _relationships.hasMany)('dateTypeDefinition'),
    includes: (0, _relationships.hasMany)('dateTypeDefinition')
  });

  _exports.default = _default;
});