define("iris/components/hrm/event-manager/form/component", ["exports", "iris/components/hrm/event-manager/component", "ember-concurrency", "iris/utils/model"], function (_exports, _component, _emberConcurrency, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    event: null,
    account: null,
    deleteConfirmation: false,
    isUndecided: Ember.computed('event.isAccepted', function () {
      return typeof this.event.isAccepted === 'undefined';
    }),
    isRejected: Ember.computed('isUndecided', 'event.isAccepted', function () {
      return !this.isUndecided && !this.event.isAccepted;
    }),
    saveEventTask: (0, _emberConcurrency.task)(function* () {
      let {
        validations
      } = yield (0, _model.validateRecord)(this.event);
      this.event.setValidated();

      if (!validations.isValid) {
        return;
      }

      if (!this.event.id) {
        this.event.set('user', this.account);
      }

      let event = this.event.serialize();
      event.data.id = this.event.id;
      event.data.attributes.days = this.event.eventDays.map(_ref => {
        let {
          date
        } = _ref;
        return date;
      });

      try {
        yield this.ajax.call('POST', 'event', null, 'saveEvent', {
          event: event
        });
      } catch (error) {
        const flashMessages = Ember.get(this, 'flashMessages');
        let errorString = error.errors.map(_ref2 => {
          let {
            detail
          } = _ref2;
          return detail;
        }).join('\n');
        flashMessages.danger(errorString, {
          title: this.intl.t('general.error'),
          icon: 'times',
          sticky: true
        });
      }

      this.clearSelection && this.clearSelection();
      this.reloadData && this.reloadData();
    }).restartable(),
    actions: {
      toggleIsAccepted() {
        this.event.toggleProperty('isAccepted');
      },

      acceptEvent() {
        this.event.set('isAccepted', true);
      },

      rejectEvent() {
        this.event.set('isAccepted', false);
      },

      setEventName(value) {
        this.event.set('title', value);
      },

      onDelete() {
        this.set('deleteConfirmation', true);
      },

      onConfirmDelete() {
        const flashMessages = Ember.get(this, 'flashMessages');
        let event = this.get('event');
        event.destroyRecord(true).then(() => {
          flashMessages.success(this.intl.t('general.delete.success-title'), {
            title: this.intl.t('models.event.modelName'),
            icon: 'check',
            sticky: false
          });
          this.set('deleteConfirmation', false);
        }).catch(error => {
          flashMessages.danger(error, {
            title: this.intl.t('general.error'),
            icon: 'times',
            sticky: true
          });
        }).finally(() => {
          this.clearSelection && this.clearSelection();
          this.reloadData && this.reloadData();
        });
      },

      onCancel() {
        this.clearSelection && this.clearSelection();
      },

      onCancelDelete() {
        this.set('deleteConfirmation', false);
      }

    },
    eventRanges: Ember.computed('event.eventDays', function () {
      if (!this.event && !Array.isArray(this.event.eventDays)) {
        return [];
      }

      return (0, _component.daysToRanges)(this.event.get('eventDays').map(_ref3 => {
        let {
          date
        } = _ref3;
        return date;
      }));
    })
  });

  _exports.default = _default;
});