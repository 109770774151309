define("iris/models/audit-log", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let types = {
    'get': function (prop) {
      return Object.prototype.toString.call(prop);
    },
    'object': '[object Object]',
    'array': '[object Array]',
    'string': '[object String]',
    'boolean': '[object Boolean]',
    'number': '[object Number]'
  };

  var _default = _model.default.extend({
    intl: Ember.inject.service(),
    fileId: (0, _attr.default)('string'),
    model: (0, _attr.default)('string'),
    valueFrom: (0, _attr.default)('string'),
    valueTo: (0, _attr.default)('string'),
    functionType: (0, _attr.default)('string'),
    createDate: (0, _attr.default)('date'),
    user: (0, _relationships.belongsTo)('account'),
    valueToObject: Ember.computed('valueTo', function () {
      let value = {};

      if (Ember.isPresent(this.get('valueFrom'))) {
        let valueFrom = JSON.parse(this.get('valueFrom'));
        let valueTo = JSON.parse(this.get('valueTo'));

        for (let key in valueTo) {
          value[key] = {
            from: types.get(valueFrom[key]) === types.object ? valueFrom[key].name : valueFrom[key],
            to: types.get(valueTo[key]) === types.object ? valueTo[key].name : valueTo[key]
          };
        }

        return value;
      } else {
        let valueTo = JSON.parse(this.get('valueTo'));

        for (let key in valueTo) {
          value[key] = {
            from: null,
            to: types.get(valueTo[key]) === types.object ? valueTo[key].name : valueTo[key]
          };
        }

        return value;
      }
    }),
    i18nModel: Ember.computed('model', 'functionType', 'valueTo', function () {
      return i18nModel(this.get('model'), this.intl);
    })
  });

  _exports.default = _default;

  function i18nModel(model, intl) {
    if (model === 'Project') {
      return intl.t('ticket.file');
    } else if (model === 'FileProposal') {
      return intl.t('file-proposal.modelName');
    } else if (model === 'FileProductResult') {
      return intl.t('file-product-result.modelName');
    } else if (model === 'FileSupplierAssoc') {
      return intl.t('supplier.modelName');
    } else if (model === 'FileUserGradeCost') {
      return intl.t('file-user-grade-cost.modelName');
    } else if (model === 'Ticket') {
      return intl.t('ticket.modelName');
    }
  }
});