define("iris/components/beta/table/row-actions/component", ["exports", "iris/lib/table"], function (_exports, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    rowActions: null,
    // @, []
    defaultPlacementActions: Ember.computed('rowActions.[]', function () {
      return this.rowActions.filter(configClass => configClass.placement === _table.RowActionPlacement.Default);
    }),
    restPlacementActions: Ember.computed('rowActions.[]', function () {
      return this.rowActions.filter(configClass => configClass.placement === _table.RowActionPlacement.Rest);
    }),
    actions: {}
  });

  _exports.default = _default;
});