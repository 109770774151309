define("iris/components/auto/form/field/component", ["exports", "iris/lib/modals"], function (_exports, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    modals: Ember.inject.service(),
    tagName: '',
    type: 'text',
    hide: false,
    disabled: false,
    decimal: Ember.computed('widgetOptions', function () {
      const decimal = Ember.get(this, 'widgetOptions.decimal');
      return Ember.isPresent(decimal) ? decimal : true;
    }),
    allowMinus: false,
    groupSeparator: Ember.computed('widgetOptions', function () {
      const groupSeparator = Ember.get(this, 'widgetOptions.groupSeparator');
      return Ember.isPresent(groupSeparator) ? groupSeparator : ' ';
    }),
    value: Ember.computed('model', 'property', {
      get(key) {
        return this.model[this.property];
      },

      set(key, value) {
        this.model.set(this.property, value);
        return value;
      }

    }),
    searchProperty: Ember.computed('relationModelName', function () {
      return this.store.modelFor(this.relationModelName).attributes.get('searchAttr') ? this.store.modelFor(this.relationModelName).attributes.get('searchAttr').options.defaultValue : 'name';
    }),
    order: Ember.computed('relationModelName', function () {
      return this.store.modelFor(this.relationModelName).attributes.get('orderColumn') ? this.store.modelFor(this.relationModelName).attributes.get('orderColumn').options.defaultValue : 'name';
    }),
    options: Ember.computed('model', 'property', function () {
      return this.model.constructor.attributes.get(this.property).options;
    }),
    widget: Ember.computed('options', function () {
      return this.options.formWidget;
    }),
    fieldMin: Ember.computed('options', 'min', function () {
      if (this.min) {
        return this.min;
      } else if ('sliderMin' in this.options) {
        return this.options.sliderMin;
      } else {
        return 0;
      }
    }),
    fieldMax: Ember.computed('options', 'min', function () {
      if (this.min) {
        return this.min;
      } else if ('fieldMax' in this.options) {
        return this.options.fieldMax;
      } else {
        return 10;
      }
    }),
    widgetOptions: Ember.computed('model', 'property', function () {
      return this.model.constructor.attributes.get(this.property).options.formWidgetOptions;
    }),
    label: Ember.computed('model', 'property', function () {
      return this.intl.t(`${this.model.constructor.modelName}.${this.property}`);
    }),
    fieldType: Ember.computed('model', 'property', function () {
      let type = '';

      if (this.model.constructor.attributes.get(this.property)) {
        type = this.model.constructor.attributes.get(this.property).type;
      } else {
        type = this.model.constructor.fields.get(this.property);
      }

      return type;
    }),
    relationModelName: Ember.computed('model', 'property', function () {
      return this.model.constructor.relationshipsByName.get(this.property) ? this.model.constructor.relationshipsByName.get(this.property).type : null;
    }),
    _allowClear: Ember.computed('allowClear', function () {
      return Ember.isPresent(this.allowClear) ? this.allowClear : true;
    }),
    actions: {
      async addNew() {
        const record = this.store.createRecord(this.relationModelName);

        if (this.parentModel && this.itemName) {
          this.parentModel.get(this.itemName).pushObject(record);
        }

        let newItem = await this.modals.open('modals/form-modal', {
          model: record,
          itemName: this.itemName,
          parentModel: this.parentModel,
          excludeFields: this.excludeFieldsFormAll,
          neededFields: this.neededFieldsForCreate,
          translationFileds: this.translationFileds,
          formComponent: this.formComponentForCreate,
          afterSave: this.afterSaveForCreate
        }, {
          className: _modals.ModalLayoutClassName[this.modalSize || 'M']
        });
        this.model.set(this.property, record);
      },

      setIsDirtyTemp() {
        this.model.set('isDirtyTemp', new Date());
      }

    },
    parentModel: Ember.computed('model', 'property', function () {
      return this.model.constructor.relationshipsByName.get(this.property) ? this.model.constructor.relationshipsByName.get(this.property).type : null;
    }),
    numberMin: Ember.computed('model', 'property', function () {
      var _this$model, _this$model$validatio, _validation$options;

      let validation = (_this$model = this.model) === null || _this$model === void 0 ? void 0 : (_this$model$validatio = _this$model.validations) === null || _this$model$validatio === void 0 ? void 0 : _this$model$validatio.attrs[this.property];
      let numberValidations = validation === null || validation === void 0 ? void 0 : (_validation$options = validation.options) === null || _validation$options === void 0 ? void 0 : _validation$options.number;

      if (!numberValidations) {
        return null;
      }

      if (numberValidations.gte) {
        return numberValidations.gte;
      } else if (numberValidations.gt) {
        return numberValidations.gt + 1;
      } else if (numberValidations.positive) {
        return 0;
      }

      return null;
    }),
    numberMax: Ember.computed('model', 'property', function () {
      var _this$model2, _this$model2$validati, _validation$options2;

      let validation = (_this$model2 = this.model) === null || _this$model2 === void 0 ? void 0 : (_this$model2$validati = _this$model2.validations) === null || _this$model2$validati === void 0 ? void 0 : _this$model2$validati.attrs[this.property];
      let numberValidations = validation === null || validation === void 0 ? void 0 : (_validation$options2 = validation.options) === null || _validation$options2 === void 0 ? void 0 : _validation$options2.number;

      if (!numberValidations) {
        return null;
      }

      if (numberValidations.lte) {
        return numberValidations.lte;
      } else if (numberValidations.lt) {
        return numberValidations.lt - 1;
      }

      return null;
    })
  });

  _exports.default = _default;
});