define("iris/templates/components/buttons/save-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7FfOf3Mf",
    "block": "{\"symbols\":[\"@action\"],\"statements\":[[5,\"iris-button\",[],[[\"@icon\",\"@action\",\"@disabled\",\"@title\",\"@type\",\"@refresh\",\"@value\",\"@text\"],[[22,\"icon\"],[23,1,[]],[22,\"_disabled\"],[22,\"title\"],[22,\"type\"],[22,\"refresh\"],[22,\"value\"],[28,\"if\",[[24,[\"text\"]],[24,[\"text\"]],[28,\"t\",[\"buttons.save\"],null]],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/buttons/save-button.hbs"
    }
  });

  _exports.default = _default;
});