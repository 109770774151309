define("iris/routes/settings/aiops/day-types/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AiopsDayTypeEditRoute extends Ember.Route {
    model(params) {
      if (params.day_type_id == 'new') {
        return this.store.createRecord('aiopsDayType');
      }

      return this.store.findRecord('aiopsDayType', params.day_type_id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = AiopsDayTypeEditRoute;
});