define("iris/components/key-value-pair", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    jsonObject: Ember.computed(function () {
      let result = Ember.A();

      if (this.get('value')) {
        let obj = JSON.parse(this.get('value'));

        for (let key in obj) {
          let attrName = key;
          let attrValue = obj[key];
          result.pushObject({
            'key': attrName,
            'value': attrValue
          });
        }
      }

      return result;
    }),

    _computeValue() {
      let result = {};
      let jsonString = '';
      let jsonObject = this.get('jsonObject');
      jsonObject.forEach(row => {
        result[row.key] = row.value;
      });
      jsonString = JSON.stringify(result);
      this.set('value', jsonString);
    },

    actions: {
      addRow() {
        let jsonObject = this.get('jsonObject');
        let count = jsonObject.length + 1;
        jsonObject.pushObject({
          'key': 'key' + count,
          'value': 'value' + count
        });

        this._computeValue();
      },

      deleteRow(key) {
        let jsonObject = this.get('jsonObject');
        let finded = jsonObject.findBy('key', key);
        jsonObject.removeObject(finded);

        this._computeValue();
      },

      computeValue() {
        this._computeValue();
      }

    }
  });

  _exports.default = _default;
});