define("iris/models/availability", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    type: (0, _attr.default)('string'),
    startDate: (0, _attr.default)('date'),
    endDate: (0, _attr.default)('date'),
    recognized: (0, _attr.default)('boolean'),
    file: (0, _relationships.belongsTo)('project'),
    businessService: (0, _relationships.belongsTo)('businessService'),
    serviceArea: (0, _relationships.belongsTo)('serviceArea'),
    servicePerson1: (0, _relationships.belongsTo)('account'),
    servicePerson2: (0, _relationships.belongsTo)('account'),
    workLog: (0, _relationships.belongsTo)('workLog')
  });

  _exports.default = _default;
});