define("iris/services/keycloak-session", ["exports", "ember-keycloak-auth/services/keycloak-session", "iris/config/environment"], function (_exports, _keycloakSession, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const isTesting = _environment.default.environment === 'test';

  var _default = _keycloakSession.default.extend({
    updateToken() {
      // Pretend we're always logged in during testing...
      if (isTesting) {
        return Ember.RSVP.resolve('refreshed=refreshed');
      }

      return this._super();
    }

  });

  _exports.default = _default;
});