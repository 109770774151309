define("iris/services/theme-switcher", ["exports", "iris/utils/properties"], function (_exports, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    sessionAccount: Ember.inject.service(),
    dark: Ember.computed.alias('sessionAccount.account.theme'),

    initTheme() {
      // This will load the user account, and will trigger the observer below to
      // switch the theme.
      this.get('sessionAccount.account.theme');
    },

    themeSwitchObserver: (0, _properties.observerOnce)('dark', function () {
      if (this.dark) {
        $('html').removeClass('light');
        $('html').addClass('dark');
      } else {
        $('html').removeClass('dark');
        $('html').addClass('light');
      }
    }).on('init')
  });

  _exports.default = _default;
});