define("iris/models/file-planner-assoc", ["exports", "ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    file: (0, _relationships.belongsTo)('project'),
    user: (0, _relationships.belongsTo)('account')
  });

  _exports.default = _default;
});