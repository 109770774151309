define("iris/components/power-select-infinite-scroll/status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zqsBAKCf",
    "block": "{\"symbols\":[\"@loaded\",\"@total\",\"@limit\",\"@isLoading\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"\\n    px-4 py-2 text-center text-gray-700 dark:text-gray-200\\n    border-t border-gray-300 dark:border-gray-700\\n  \"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,3,[]],[23,2,[]],[28,\"gt\",[[23,2,[]],[23,3,[]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[1,[23,1,[]],false],[0,\"/\"],[1,[23,2,[]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"ml-2 inline-block relative w-4 h-4\"],[8],[0,\"\\n\"],[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"spin-spinner\",null,[[\"lines\",\"length\",\"width\",\"scale\"],[13,20,10,0.1]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[]],[23,1,[]]],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"fa-icon\",[\"check\"],[[\"class\"],[\"text-green-500\"]]],false],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/power-select-infinite-scroll/status/template.hbs"
    }
  });

  _exports.default = _default;
});