define("iris/components/form-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // https://github.com/DamitDev/iris/issues/174
  // eslint-disable-next-line ember/new-module-imports
  const {
    assert
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['form-group'],
    classNameBindings: ['showErrorClass:has-error', 'showMessage:has-error'],
    label: true,
    inline: Ember.computed.alias('form.inline'),
    init: function () {
      this._super(...arguments);

      var valuePath = this.get('property');

      if (!Ember.isBlank(valuePath)) {
        Ember.defineProperty(this, 'validation', Ember.computed.oneWay(`model.validations.attrs.${valuePath}`));
        Ember.defineProperty(this, 'value', Ember.computed.alias(`model.${valuePath}`));
      }
    },
    labelText: Ember.computed('property', function () {
      if (this.get('label')) {
        return this.get('property').classify();
      }
    }),
    notValidating: Ember.computed.not('validation.isValidating'),
    didValidate: Ember.computed.oneWay('model.didValidate'),
    hasContent: Ember.computed('value', 'value.content', function () {
      let value = this.get('value'); // type of null is object

      if (value === null) {
        return false;
      } // probably a promis for a select


      if (typeof value === 'object' && !(value instanceof Date)) {
        value = value.get('content');
      }

      return value;
    }),
    presenceValidation: Ember.computed('validation.options', function () {
      var _this$validation, _this$validation$opti;

      if ((_this$validation = this.validation) !== null && _this$validation !== void 0 && (_this$validation$opti = _this$validation.options) !== null && _this$validation$opti !== void 0 && _this$validation$opti.presence) {
        if (this.validation.options.presence.hasOwnProperty('disabled')) {
          return this.validation.options.presence.disabled === false ? this.validation : false;
        } else {
          return this.validation;
        }
      } else {
        return false;
      }
    }),
    hasValidationClass: Ember.computed('presenceValidation', function () {
      return this.presenceValidation ? 'has-error' : '';
    }),
    isValid: Ember.computed.and('hasContent', 'validation.isValid', 'notValidating'),
    isInvalid: Ember.computed.oneWay('validation.isInvalid'),
    showErrorClass: Ember.computed.and('notValidating', 'showMessage', 'hasContent', 'validation'),
    showMessage: Ember.computed.and('didValidate', 'isInvalid'),
    // proportion
    proportion: '',
    labelControlProportion: Ember.computed('proportion', function () {
      let prop = this.get('proportion');

      if (Ember.isNone(prop)) {
        return false;
      }

      prop = prop.split('-');
      return {
        label: prop[0],
        control: prop[1]
      };
    }),
    labelClass: Ember.computed('form.class', 'inline', 'form.labelControlProportion', 'labelControlProportion', function () {
      var labelClass = false;

      if (this.get('inline')) {
        return labelClass;
      }

      var proportion = this.get('labelControlProportion.label') || this.get('form.labelControlProportion.label');

      if (this.get('form.class') === 'form-horizontal') {
        labelClass = 'col-sm-2 control-label';
      }

      if (this.get('form.class') === 'form-inline') {
        return false;
      }

      if (proportion) {
        labelClass = 'col-sm-' + proportion;
      }

      return labelClass;
    }),
    controlClass: Ember.computed('form.labelControlProportion', 'labelControlProportion', function () {
      var proportion = this.get('labelControlProportion.control') || this.get('form.labelControlProportion.control');
      return 'col-sm-' + proportion;
    }),
    form: Ember.computed('parentView', function () {
      var parentView;
      parentView = this.get('parentView');

      while (parentView) {
        if (parentView.get('tagName') === 'form') {
          return parentView;
        }

        parentView = parentView.get('parentView');
      }

      return assert(false, 'Cannot find form');
    }),
    model: Ember.computed.reads('form.model')
  });

  _exports.default = _default;
});