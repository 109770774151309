define("iris/components/issue-report-edit/modals/reason/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T4fMGBhf",
    "block": "{\"symbols\":[\"@data\",\"@close\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-content mx-auto\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[0,\"\\n      \"],[1,[23,1,[\"title\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n    \"],[5,\"trumbowyg-editor\",[],[[\"@placeholder\",\"@html\",\"@change\"],[[28,\"t\",[\"operation.report-submit.fill-reason\"],null],[23,0,[\"reason\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"reason\"]]],null]],null]]]],[0,\"\\n\\n    \"],[5,\"character-counter\",[],[[\"@string\",\"@min\"],[[23,0,[\"convertToPlain\"]],[23,1,[\"minChar\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n\\n    \"],[5,\"iris-button\",[],[[\"@disabled\",\"@type\",\"@action\",\"@icon\",\"@text\"],[[28,\"lt\",[[23,0,[\"convertToPlain\",\"length\"]],[23,1,[\"minChar\"]]],null],\"primary\",[28,\"fn\",[[23,2,[]],[23,0,[\"reason\"]]],null],\"floppy-o\",[28,\"or\",[[23,1,[\"okButtonLabel\"]],[28,\"t\",[\"buttons.create\"],null]],null]]]],[0,\"\\n\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"default\",[28,\"fn\",[[23,2,[]],false],null],[28,\"or\",[[23,1,[\"cancelButtonLabel\"]],[28,\"t\",[\"buttons.cancel\"],null]],null]]]],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/issue-report-edit/modals/reason/template.hbs"
    }
  });

  _exports.default = _default;
});