define("iris/controllers/cmdb/procedures/changes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.reads('sessionAccount.account'),
    changedEntites: Ember.A(),
    changedEntitesOberver: Ember.observer('reloader', function () {
      this.get('store').query('entityProcedureReminder', {
        filter: {
          where: {
            userId: this.get('currentUser.id')
          }
        }
      }).then(entityProcedureReminder => {
        if (entityProcedureReminder.length) {
          let entityIds = entityProcedureReminder.toArray()[0].get('entityIds');

          if (entityIds) {
            this.set('changedEntites', entityIds.split(","));
          }
        }
      });
    }).on('init')
  });

  _exports.default = _default;
});