define("iris/initializers/set-provider-registration", ["exports", "ember-provider/initializers/set-provider-registration"], function (_exports, _setProviderRegistration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _setProviderRegistration.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _setProviderRegistration.initialize;
    }
  });
});