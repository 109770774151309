define("iris/components/aiops/dashboards/customer/sum-tickets/component", ["exports", "iris/utils/properties", "moment"], function (_exports, _properties, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    intervalFilter01: Ember.computed('intl', function () {
      return {
        id: 'daily',
        name: this.intl.t('aiops.dashboars.daily'),
        value: 1
      };
    }),
    chartData: (0, _properties.computedTask)('intervalFilter01', 'model.id', function* () {
      return yield this.ajax.call('POST', 'AiopsPartner', '', 'sumTicketChart', {
        customerId: this.model.id,
        day: this.intervalFilter01.value
      }, true);
    }),
    data: Ember.computed('chartData', 'model.id', function () {
      let chartData = {};

      if (this.chartData) {
        this.chartData.forEach(function (cd) {
          chartData[String(cd['date_part'])] = {
            normal: cd['normal'],
            sla: cd['sla']
          };
        });
      }

      const daysBetweenDates = enumerateDaysBetweenDates(this.intervalFilter01.value);
      const labels = daysBetweenDates.map(x => x.formattedDate);
      let data = {
        normal: [],
        sla: []
      };
      daysBetweenDates.map(x => x.date).forEach(function (d) {
        if (d in chartData) {
          data['normal'].push(chartData[d]['normal']);
          data['sla'].push(chartData[d]['sla']);
        } else {
          data['normal'].push(0);
          data['sla'].push(0);
        }
      });
      return {
        labels: labels,
        datasets: [{
          label: this.intl.t('aiops.dashboars.solved-tickets'),
          data: data['normal'],
          backgroundColor: '#7bc426',
          barThickness: 30
        }, {
          label: this.intl.t('aiops.dashboars.sla-tickets'),
          data: data['sla'],
          backgroundColor: '#dc3545',
          barThickness: 30
        }]
      };
    }),
    options: {
      responsive: true,
      legend: {
        display: false
      },
      hover: {
        mode: 'label'
      },
      scales: {
        xAxes: [{
          stacked: true,
          display: true,
          scaleLabel: {
            display: false
          }
        }],
        yAxes: [{
          stacked: true,
          display: true,
          scaleLabel: {
            display: false
          },
          ticks: {
            stepValue: 1
          }
        }]
      },
      title: {
        display: false
      }
    },
    actions: {
      updateintervalFilter01(selected) {
        this.set('intervalFilter01', selected);
      }

    }
  });

  _exports.default = _default;

  const enumerateDaysBetweenDates = function (days) {
    let dates = [];
    let startDate = (0, _moment.default)().add(-6 * days, 'days');
    let endDate = (0, _moment.default)();
    let dateFormat = 'Y';
    let labelFormat = 'YYYY';

    if (days === 1) {
      labelFormat = 'MM.DD.';
      dateFormat = 'D';
    } else if (days === 7) {
      labelFormat = 'MM.DD.';
      dateFormat = 'W';
    } else if (days === 30) {
      labelFormat = 'MMM';
      dateFormat = 'M';
    }

    while (startDate.isSameOrBefore(endDate)) {
      dates.push({
        formattedDate: startDate.format(labelFormat),
        date: startDate.format(dateFormat)
      });
      startDate.add(days, 'days');
    }

    return dates;
  };
});