define("iris/components/forms/manage-contact-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    model: null,
    // @
    partnerFilter: null,
    // @
    departmentsWarning: null // @

  });

  _exports.default = _default;
});