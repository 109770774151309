define("iris/validators/entity-value", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    intl: Ember.inject.service(),

    validate(value, options, model, attribute) {
      let required = model.get('entityAttribute.required');
      let type = model.get('entityAttribute.type');
      let min = model.get('entityAttribute.min');
      let max = model.get('entityAttribute.max');
      let regexp = model.get('entityAttribute.regexp');
      let regexpFlags = model.get('entityAttribute.regexpFlags');
      let r = null;

      if (!Ember.isEmpty(value)) {
        switch (type) {
          case "number":
            if (isNaN(value)) {
              return this.intl.t('validators.entityValue.mustBeNumber');
            }

            if (min !== null && max !== null) {
              if (parseInt(value) >= min && parseInt(value) <= max) {
                return true;
              } else {
                return this.intl.t('validators.entityValue.between', {
                  min,
                  max
                });
              }
            } else if (min !== null) {
              if (parseInt(value) >= min) {
                return true;
              } else {
                return this.intl.t('validators.entityValue.min', {
                  min
                });
              }
            } else if (max !== null) {
              if (parseInt(value) <= max) {
                return true;
              } else {
                return this.intl.t('validators.entityValue.max', {
                  max
                });
              }
            } else {
              return true;
            }

            break;

          case "string":
          case "textarea":
            if (regexp) {
              try {
                if (regexpFlags) {
                  r = new RegExp(regexp, regexpFlags);
                } else {
                  r = new RegExp(regexp);
                }

                if (r.test(value)) {
                  return true;
                } else {
                  return this.intl.t('validators.entityValue.regex', {
                    regexp,
                    value
                  });
                }
              } catch (err) {
                return err.message;
              }
            } else {
              return true;
            }

            break;

          default:
            return true;
        }
      } else {
        if (required && type !== 'hasMany' && type !== 'belongsTo') {
          return this.intl.t('validators.entityValue.presence', {
            entityAttrId: model.get('entityAttribute.id'),
            value
          });
        } else {
          return true;
        }
      }
    }

  });

  _exports.default = _default;
});