define("iris/templates/settings/file-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SnuUV31/",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@defaultOrder\",\"@route\"],[\"file-type\",\"createDate DESC\",\"settings.file-types.edit\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"fileBusinessTypeId\"],null]],null,{\"statements\":[[4,\"if\",[[23,1,[\"row\",\"fileBusinessType\"]]],null,{\"statements\":[[0,\"        \"],[1,[23,1,[\"row\",\"fileBusinessType\",\"name\"]],false],[0,\" | \"],[1,[23,1,[\"row\",\"fileBusinessType\",\"profitCenter\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"projectType\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"project-type\",[[23,1,[\"row\",\"projectType\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/file-types.hbs"
    }
  });

  _exports.default = _default;
});