define("iris/lib/filters/index", ["exports", "iris/lib/filters/data", "iris/lib/filters/api", "iris/lib/filters/column", "iris/lib/filters/filter"], function (_exports, _data, _api, _column, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "filterByVisiblity", {
    enumerable: true,
    get: function () {
      return _column.filterByVisiblity;
    }
  });
  Object.defineProperty(_exports, "makeColumn", {
    enumerable: true,
    get: function () {
      return _column.makeColumn;
    }
  });
  Object.defineProperty(_exports, "makeFilterFromColumn", {
    enumerable: true,
    get: function () {
      return _filter.makeFilterFromColumn;
    }
  });
  Object.defineProperty(_exports, "makeFiltersFromQueryParams", {
    enumerable: true,
    get: function () {
      return _filter.makeFiltersFromQueryParams;
    }
  });
  Object.defineProperty(_exports, "makeFuzzyFilters", {
    enumerable: true,
    get: function () {
      return _api.makeFuzzyFilters;
    }
  });
  Object.defineProperty(_exports, "makeQueryObject", {
    enumerable: true,
    get: function () {
      return _api.makeQueryObject;
    }
  });
  Object.defineProperty(_exports, "makeWhereCondition", {
    enumerable: true,
    get: function () {
      return _api.makeWhereCondition;
    }
  });
  _exports.mapSelectOptionRecords = void 0;
  Object.defineProperty(_exports, "serializeToQueryParam", {
    enumerable: true,
    get: function () {
      return _filter.serializeToQueryParam;
    }
  });
  Object.defineProperty(_exports, "symbolForFilterType", {
    enumerable: true,
    get: function () {
      return _filter.symbolForFilterType;
    }
  });

  /**
   * Creates an arrey of `id`: `name` pairs from the provided records with the
   * name key to make the data structure consistent, so that they could be passed
   * to `<PowerSelect>` components without requiring to customize their templates.
   */
  const mapSelectOptionRecords = function (records) {
    let nameKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _data.DEFAULT_OPTIONS_NAME_KEY;
    return records.map(record => {
      return {
        id: record.id,
        name: record[nameKey]
      };
    });
  };

  _exports.mapSelectOptionRecords = mapSelectOptionRecords;
});