define("iris/templates/settings/operations/solving-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qhEODkIg",
    "block": "{\"symbols\":[\"f\",\"account\",\"index\"],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@columnOverwrites\",\"@defaultOrder\",\"@neededFields\"],[\"solving-group\",\"settings.operations.solving-group.edit\",[28,\"hash\",null,[[\"leader\",\"servicePerson1\",\"servicePerson2\"],[[28,\"hash\",null,[[\"displayName\"],[[28,\"t\",[\"solving-group.leader\"],null]]]],[28,\"hash\",null,[[\"displayName\"],[[28,\"t\",[\"solving-group.servicePerson1\"],null]]]],[28,\"hash\",null,[[\"displayName\"],[[28,\"t\",[\"solving-group.servicePerson2\"],null]]]]]]],\"createDate DESC\",[28,\"array\",[\"name\",\"default\",\"email\",\"accounts\",\"leader.longName\",\"servicePerson1.longName\",\"servicePerson2.longName\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"accounts\"],null]],null,{\"statements\":[[4,\"each\",[[28,\"sort-by\",[\"longName\",[23,1,[\"row\",\"accounts\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"style\",\"float: left;\"],[8],[0,\"\\n\"],[4,\"ember-tooltip\",null,[[\"class\"],[\"mw400\"]],{\"statements\":[[0,\"          \"],[5,\"profil-card\",[],[[\"@account\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"not\",[[28,\"is-last-index\",[[23,3,[]],[23,1,[\"row\",\"accounts\",\"length\"]]],null]],null]],null,{\"statements\":[[0,\"          \"],[1,[23,2,[\"longName\"]],false],[0,\", \\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[23,2,[\"longName\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[2,3]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/operations/solving-group.hbs"
    }
  });

  _exports.default = _default;
});