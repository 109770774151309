define("iris/components/aiops/optimalization/chart/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    themeSwitcher: Ember.inject.service(),
    classNames: ['no-gutter'],
    windowWidth: (0, _jquery.default)(window).width(),
    labels: Ember.computed('model', function () {
      const res = [];

      for (const record of this.model.toArray()) {
        let name = `${record.get('parameterCategory.name')} / ${record.name}`;
        res.push(name);
      }

      return res;
    }),
    computeData: Ember.computed('model.@each.relevancyValue', function () {
      const res = [];

      for (const record of this.model.toArray()) {
        let value = record.get('relevancyValue');
        res.push(value);
      }

      return res;
    }),
    datasets: Ember.computed('computeData', function () {
      const computeData = this.computeData;
      return [{
        label: 'Optimalizáció',
        data: computeData,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgb(255, 99, 132)',
        pointBackgroundColor: 'rgb(255, 99, 132)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(255, 99, 132)'
      }];
    }),
    data: Ember.computed('datasets', 'labels', function () {
      let datasets = this.get('datasets');
      let labels = this.get('labels');
      return {
        labels,
        datasets
      };
    }),
    options: {
      scale: {
        pointLabels: {
          fontSize: 16
        },
        ticks: {
          min: 0
        }
      },
      legend: {
        display: false,
        labels: {
          fontSize: 24
        }
      }
    }
  });

  _exports.default = _default;
});