define("iris/lib/filters/storage/query-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.QueryParamsStorage = void 0;
  // This only lives here because it's extremely hard to type-check Ember Objects
  const QueryParamsStorage = Ember.Object.extend({
    router: null,
    // Ember.Router
    controller: null,
    // Ember.Controller
    routeName: null,
    // string
    params: Ember.computed.alias('controller.allQueryParams'),

    update(param, value) {
      this.router.transitionTo(this.routeName, {
        queryParams: {
          [param]: value
        }
      });
    },

    replace(param, value) {
      this.router.replaceWith(this.routeName, {
        queryParams: {
          [param]: value
        }
      });
    },

    reset(params) {
      this.controller.resetQueryParams(params);
    },

    refresh() {
      this.notifyPropertyChange('params');
    }

  });
  _exports.QueryParamsStorage = QueryParamsStorage;
});