define("iris/routes/beta/menus/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MenusEditRoute extends Ember.Route {
    model(params) {
      if (params.menu_id == 'new') {
        return this.store.createRecord('action');
      }

      return this.store.findRecord('action', params.menu_id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = MenusEditRoute;
});