define("iris/templates/cmdb/licenses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h+ozdF51",
    "block": "{\"symbols\":[\"t\"],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"menus.licenses\"],null]],null],false],[0,\"\\n\\n\"],[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\",\"@readOnly\"],[\"entity-license\",\"cmdb.licenses\",[28,\"array\",[\"entityName\",\"contactName\",\"licenseName\",\"licenseTypeName\",\"expirationDate\",\"partner\",\"department\",\"contact\",\"location\"],null],true]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"entityName\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"a\",false],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"setOldRouteAndTransition\",\"cmdb.licenses\",[23,1,[\"row\",\"entityTypeId\"]],[23,1,[\"row\",\"entityId\"]]],null]]],[8],[1,[23,1,[\"row\",\"entityName\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"licenseName\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"a\",false],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"setOldRouteAndTransition\",\"cmdb.licenses\",[23,1,[\"row\",\"licenseTypeId\"]],[23,1,[\"row\",\"licenseId\"]]],null]]],[8],[1,[23,1,[\"row\",\"licenseName\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/licenses.hbs"
    }
  });

  _exports.default = _default;
});