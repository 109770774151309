define("iris/templates/settings/priority-matrix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "81vzAh7E",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@defaultOrder\",\"@excludeFields\"],[\"priority-matrix\",\"settings.priority-matrix.edit\",\"createDate DESC\",[28,\"array\",[\"serviceLevel\"],null]]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/priority-matrix.hbs"
    }
  });

  _exports.default = _default;
});