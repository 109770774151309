define("iris/templates/components/profil-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2i0E8LVC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"profil-card-container p5\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-sm-4\"],[8],[0,\"\\n            \"],[7,\"img\",true],[10,\"class\",\"user-avatar\"],[11,\"src\",[29,[[24,[\"account\",\"userPhoto\"]]]]],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-sm-8\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n                \"],[7,\"span\",true],[10,\"class\",\"name\"],[8],[1,[24,[\"account\",\"longName\"]],false],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n                \"],[7,\"span\",true],[10,\"class\",\"email\"],[8],[1,[24,[\"account\",\"email\"]],false],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n                \"],[7,\"span\",true],[10,\"class\",\"email\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"account\",\"enabled\"]]],null,{\"statements\":[[0,\"                        \"],[1,[28,\"t\",[\"general.enabled\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                        \"],[1,[28,\"t\",[\"components-main.forbidden\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/profil-card.hbs"
    }
  });

  _exports.default = _default;
});