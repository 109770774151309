define("iris/mixins/cmdb-visualisation/animation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DELAY = 50;
  const DURATION = 300;

  var _default = Ember.Mixin.create({
    /**
     * Transit an element to a coordinates with animation
     *
     * @param {*} elem
     * @param {*} x
     * @param {*} y
     */
    animateTo(elem, x, y) {
      this.transition(elem, 'position/x', x);
      this.transition(elem, 'position/y', y);
    },

    transition(elem, path, value) {
      elem.transition(path, value, {
        delay: DELAY,
        duration: DURATION,
        timingFunction: this.timingFunction,
        valueFunction: this.valueFunction
      });
    },

    timingFunction(t) {
      return t * t;
    },

    valueFunction(a, b) {
      return t => {
        return a + (b - a) * t;
      };
    }

  });

  _exports.default = _default;
});