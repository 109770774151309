define("iris/components/aiops/planned-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    proportion: '3-9',
    belongsTo: false,
    classifyProperty: Ember.computed('property', function () {
      return Ember.String.classify(this.property);
    }),
    plannedValue: Ember.computed('model', 'classifyProperty', function () {
      return this.model[`planned${this.classifyProperty}`];
    }),
    cpValue: Ember.computed('model', 'property', function () {
      return this.model[`${this.property}Cp`];
    }),
    needAlert: Ember.computed('belongsTo', 'classifyProperty', 'property', 'cpValue', function () {
      const different = this.belongsTo ? this.model.belongsTo(this.property).id() != this.model.belongsTo(`planned${this.classifyProperty}`).id() : this.model[this.property] != this.model[`planned${this.classifyProperty}`];

      if (different) {
        return 'danger';
      } else if (this.cpValue < 35) {
        return 'warning';
      } else {
        return null;
      }
    }),
    icon: Ember.computed('needAlert', function () {
      if (this.needAlert === 'danger') {
        return 'times';
      } else if (this.needAlert === 'warning') {
        return 'warning';
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});