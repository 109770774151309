define("iris/controllers/finances/pipelines/list", ["exports", "moment", "iris/lib/filters/column", "iris/lib/filters/storage", "iris/services/query-params", "iris/lib/filters", "iris/lib/filters/types"], function (_exports, _moment, _column, _storage, _queryParams, _filters, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const thisYear = [{
    name: 'dateStart',
    type: _types.FilterType.GreaterThan,
    value: (0, _moment.default)().startOf('y').toISOString()
  }, {
    name: 'dateEnd',
    type: _types.FilterType.LessThan,
    value: (0, _moment.default)().endOf('y').toISOString()
  }];
  const partnersQueryParams = (0, _queryParams.makeFilterQueryParams)(null, {
    filters: thisYear,
    order: {
      property: 'name',
      direction: 'asc'
    }
  });
  const queryParamsMixin = (0, _queryParams.makeControllerMixin)(partnersQueryParams);

  var _default = Ember.Controller.extend(queryParamsMixin, {
    actualFetchData: Ember.inject.service(),
    storage: (0, _storage.useStorage)('query-params', {
      controllerPath: 'finances.pipelines.list'
    }),
    predefinedViews: Ember.computed(function () {
      return [{
        name: 'Összes',
        filters: []
      }, {
        name: 'Idei',
        filters: thisYear
      }];
    }),
    columns: (0, _column.columns)(function () {
      return [{
        name: 'partnerName',
        displayName: 'Cégnév'
      }, {
        name: 'name',
        displayName: 'Szerződés neve'
      }, {
        name: 'domesticPartner',
        displayName: 'Belföldi értékesítés vagy Export'
      }, {
        name: 'typeName',
        displayName: 'Szerződés típusa'
      }, {
        name: 'primeContractor',
        displayName: 'Főváll. Kivitelező',
        width: _column.ColumnWidth.S
      }, {
        name: 'partnerSector',
        displayName: 'Szektor',
        width: _column.ColumnWidth.XS
      }, {
        name: 'businessTypeName',
        displayName: 'Üzletág',
        width: _column.ColumnWidth.S
      }, {
        name: 'stateName',
        displayName: 'Státusz'
      }, {
        name: 'probability',
        displayName: 'Valószínűség',
        width: _column.ColumnWidth.S,
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'dateStart',
        displayName: 'Szolgáltatás kezdete',
        valuePath: 'dateStart',
        type: _types.AttributeType.Date,
        width: _column.ColumnWidth.S
      }, {
        name: 'dateEnd',
        displayName: 'Szolgáltatás vége',
        valuePath: 'dateEnd',
        type: _types.AttributeType.Date,
        width: _column.ColumnWidth.S
      }, {
        name: 'income',
        displayName: 'Teljes becsült bevétel',
        type: _types.AttributeType.Number
      }, {
        name: 'proposalIncome',
        displayName: 'Tervezett külső ráfordítás',
        type: _types.AttributeType.Number
      }, {
        name: 'weightedIncome',
        displayName: 'Súlyozott teljes bevétel',
        type: _types.AttributeType.Number
      }];
    }),
    fetchParams: Ember.computed('columns', 'storage.params', function () {
      return (0, _filters.makeQueryObject)(this.columns, this.storage.params);
    }),
    actions: {
      sendData(data) {
        this.actualFetchData.set('data', data);
      }

    }
  });

  _exports.default = _default;
});