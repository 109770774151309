define("iris/templates/cmdb/entity-types/entities/entity/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "40UCCuIm",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"forms/entity-overview\",null,[[\"model\",\"suppliers\",\"inventoryOrganizations\"],[[24,[\"model\",\"entity\"]],[24,[\"model\",\"suppliers\"]],[24,[\"model\",\"inventoryOrganizations\"]]]]],false],[0,\"\\n\\n\"],[1,[22,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/entity-types/entities/entity/overview.hbs"
    }
  });

  _exports.default = _default;
});