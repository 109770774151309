define("iris/templates/components/form-element/vertical/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k2xocOav",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"hasLabel\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[11,\"for\",[29,[[22,\"formElementId\"]]]],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[28,\"bs-select\",null,[[\"id\",\"name\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"value\",\"disabled\",\"required\"],[[24,[\"formElementId\"]],[24,[\"name\"]],[24,[\"choices\"]],[24,[\"choiceValueProperty\"]],[24,[\"choiceLabelProperty\"]],[24,[\"value\"]],[24,[\"disabled\"]],[24,[\"required\"]]]]],false],[0,\"\\n\"],[15,\"components/form-element/feedback-icon\",[]],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "iris/templates/components/form-element/vertical/select.hbs"
    }
  });

  _exports.default = _default;
});