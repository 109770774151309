define("iris/components/forms/service-level/component", ["exports", "ember-concurrency", "iris/models/ticket-communication"], function (_exports, _emberConcurrency, _ticketCommunication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ServiceLevel = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed, _dec4 = Ember.computed, _dec5 = Ember.computed('model.operationServiceLevel.file.id'), _dec6 = Ember.computed, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class ServiceLevel extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "modals", _descriptor2, this);

      _defineProperty(this, "withTemplates", false);

      _defineProperty(this, "template", null);
    }

    get ticketTypes() {
      return this.store.findAll('ticketType');
    }

    get priorities() {
      return this.store.findAll('priority');
    }

    get servicePeriods() {
      const fileId = this.get('model.operationServiceLevel.file.id');

      if (fileId) {
        return this.store.query('servicePeriodFile', {
          filter: {
            where: {
              fileId
            }
          }
        });
      }
    }

    get templates() {
      return this.store.findAll('serviceLevelTemplate');
    }

    setPropertiesOnModel(template) {
      const properties = ['name', 'type', 'priority', 'servicePeriod', 'solvingTime', 'reactionTime', 'startTime', 'reactionCritical', 'reactionNormal', 'reactionOverdue', 'reactionTime', 'reactionWarning', 'solvingCritical', 'solvingNormal', 'solvingOverdue', 'solvingTime', 'solvingWarning', 'startCritical', 'startNormal', 'startOverdue', 'startTime', 'startWarning', 'vip'];
      properties.forEach(property => {
        this.model.set(property, template.get(property));
      });
    }

    *copyEscalationTask(template) {
      const tcs = yield template.get('ticketCommunications');
      const modelTCs = yield this.model.get('ticketCommunications');
      this.setPropertiesOnModel(template); // cleanup

      for (const tc of modelTCs.toArray()) {
        yield tc.destroyRecord(true);
      }

      for (const tc of tcs.toArray()) {
        // without this relations will not be copied
        yield (0, _ticketCommunication.loadRelationsForCopying)(tc);
        const copy = yield tc.copy(false, {
          overwrite: {
            serviceLevelTemplate: null,
            serviceLevel: null,
            deletable: false,
            fromTemplate: true
          }
        });
        modelTCs.addObject(copy);
      }

      const res = yield this.model.save();

      if (res) {
        this.flashMessages.info(this.intl.t('general.save.success'), {
          title: this.intl.t('general.save.success-title'),
          icon: 'floppy-o'
        });
      }
    }

    async setTemplate(template) {
      // we only need confirmation for not new service levels
      if (this.model.id) {
        let confirm = await this.modals.open('beta/modals/confirmation', {
          title: this.intl.t('general.warning'),
          body: this.intl.t('service-level.template-change-confirmation')
        });

        if (!confirm) {
          return;
        }
      }

      await this.copyEscalationTask.perform(template);
      this.set('template', template);
    }

    selectServicePeriods(selected) {
      this.set('model.servicePeriods', selected);
      this.get('model').set('isDirtyTemp', new Date());
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "ticketTypes", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "ticketTypes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "priorities", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "priorities"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "servicePeriods", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "servicePeriods"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templates", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "templates"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copyEscalationTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "copyEscalationTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setTemplate", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setTemplate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectServicePeriods", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "selectServicePeriods"), _class.prototype)), _class));
  _exports.default = ServiceLevel;
});