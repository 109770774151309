define("iris/models/custom-value", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    value: (0, _attr.default)('string'),
    model: (0, _attr.default)('string'),
    modelId: (0, _attr.default)('string'),
    customAttribute: (0, _relationships.belongsTo)('customAttribute')
  });

  _exports.default = _default;
});