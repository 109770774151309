define("iris/models/action-variation-assoc", ["exports", "ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    variation: (0, _relationships.belongsTo)('aclGroupVariation'),
    action: (0, _relationships.belongsTo)('action')
  });

  _exports.default = _default;
});