define("iris/helpers/get-pritable-value", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service('intl'),

    compute(params) {
      let value = params[0];
      let objectKey = params[1];

      if (value != null && value !== '') {
        if (objectKey.includes("date") || objectKey.includes("Date")) {
          value = (0, _moment.default)(value).format('YYYY-MM-DD HH:mm');
        } else if (!isNaN(value)) {
          value = this.get('intl').formatNumber(value);
        }
      }

      return value;
    }

  });

  _exports.default = _default;
});