define("iris/components/cmdb-visualisation2/modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UpFJbHBr",
    "block": "{\"symbols\":[\"@data\",\"@close\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[1,[28,\"t\",[\"operation.change-request.select-ci-elem\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n    \"],[5,\"cmdb-visualisation2/grid\",[],[[\"@bulkActions\",\"@entityTypeId\",\"@defaultWhere\",\"@alreadySelectedEntityIds\"],[[23,0,[\"bulkActions\"]],[23,1,[\"entityTypeId\"]],[28,\"compute\",[[28,\"action\",[[23,0,[]],\"getDefaultWhere\"],null],[23,1,[\"id\"]],[23,1,[\"direction\"]]],null],[23,1,[\"alreadySelectedEntityIds\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n    \"],[5,\"bs-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"primary\",[28,\"fn\",[[23,2,[]],[23,0,[\"bulkActions\",\"selection\"]]],null],[28,\"t\",[\"tables.choose\"],null]]]],[0,\"\\n    \"],[5,\"bs-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"default\",[23,2,[]],[28,\"t\",[\"general.close\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/cmdb-visualisation2/modal/template.hbs"
    }
  });

  _exports.default = _default;
});