define("iris/components/tables/entity-licenses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function () {
      this._super();
    },
    columns: Ember.computed(function () {
      return [{
        name: this.intl.t('entity.name'),
        property: 'name',
        serverTableSearch: true
      }, {
        name: this.intl.t('entity.entityType'),
        property: 'entityType.name',
        serverTableSearch: true
      }, {
        name: this.intl.t('entity-license.contactName'),
        property: 'accountable',
        serverTableSearch: true
      }, {
        name: this.intl.t('entity-license.expirationDate'),
        property: 'expirationDate',
        serverTableSearch: true
      }, {
        name: this.intl.t('forms.fquantity'),
        property: 'qty',
        serverTableSearch: true
      }, {
        name: this.intl.t('cmdb-visualisation.available'),
        property: 'availableQty',
        serverTableSearch: true
      }];
    }),
    selectedCategory: Ember.computed('sessionAccount.region', function () {
      let licenseCategotry = this.get('sessionAccount.region.id').replace('000000001', '000000006'); //Licenc entity category XXX000000006 --> XXX: RegionCode

      return {
        id: licenseCategotry,
        text: 'Licensek',
        condition: {
          categoryId: licenseCategotry
        }
      };
    }),
    listColumns: Ember.computed(function () {
      return [{
        name: '',
        property: 'icingaStatus',
        serverTableSearch: true
      }, {
        name: this.intl.t('entity.name'),
        property: 'name',
        serverTableSearch: true
      }, {
        name: this.intl.t('entity-license.partner'),
        property: 'partner',
        search: true
      }, {
        name: this.intl.t('entity.entityType'),
        property: 'entityType.name',
        serverTableSearch: true
      }, {
        name: this.intl.t('entity-license.contactName'),
        property: 'accountable',
        serverTableSearch: true
      }, {
        name: this.intl.t('entity-license.expirationDate'),
        property: 'expirationDate',
        serverTableSearch: true
      }, {
        name: this.intl.t('forms.fquantity'),
        property: 'qty',
        serverTableSearch: true
      }, {
        name: this.intl.t('cmdb-visualisation.available'),
        property: 'availableQty',
        serverTableSearch: true
      }];
    }),
    choosePopupVisible: false,
    newLicensePopupVisible: false,
    newLicenseRowVisible: false,
    entityTypes: Ember.computed(function () {
      return this.get('store').query('entityType', {
        filter: {
          where: {
            entityTypeCategoryId: {
              like: '%000000006'
            }
          },
          order: 'name'
        }
      });
    }),
    selectedEntityTypeObserver: Ember.observer('entityTypes.[]', function () {
      this.set('selectedEntityType', this.get('entityTypes.firstObject'));
    }),
    actions: {
      removeLicense: function (selected) {
        this.get('model.licenses').removeObjects(selected);
        this.get('model').set('isDirtyTemp', new Date());
      },
      openLicense: function (selected) {
        this.set('newLicense', selected);
        this.set('newLicensePopupVisible', true);
      },
      choosePopup: function () {
        this.set('choosePopupVisible', true);
      },
      newLicenseRow: function () {
        this.toggleProperty('newLicenseRowVisible');
      },
      newLicensePopup: function () {
        let newEmptyLicense = this.get('store').createRecord('entity');
        newEmptyLicense.set('entityType', this.selectedEntityType);
        this.set('newLicense', newEmptyLicense);
        this.set('choosePopupVisible', false);
        this.set('newLicensePopupVisible', true);
      },
      cancelChoosePopup: function () {
        this.set('choosePopupVisible', false);
      },
      cancelNewLicensePopup: function () {
        this.set('newLicensePopupVisible', false);
      },

      async assignEntities() {
        let licenses = await this.get('model.licenses');

        for (const entity of this.selectedRecords) {
          let entityObj = await this.get('store').findRecord('entity', entity.id);
          let availableQty = entityObj.get('availableQty');

          if (availableQty > 0) {
            licenses.pushObject(entityObj);
            entityObj.set('availableQty', availableQty - 1);
          } else {
            let flashMessages = Ember.get(this, 'flashMessages');
            flashMessages.danger(`Az elérhető mennyiség: ${availableQty}`, {
              title: 'Nincs elegendő licenc mennyiség!',
              icon: 'times',
              sticky: true
            });
          }
        }

        this.get('model').set('isDirtyTemp', new Date());
        this.set('choosePopupVisible', false);
      },

      saveNewLicenc(newLicense) {
        newLicense.get('licensedEntities').pushObject(this.model);
        newLicense.save(true);
        this.set('newLicensePopupVisible', false);
      }

    }
  });

  _exports.default = _default;
});