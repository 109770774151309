define("iris/components/aiops/dashboards/ticket-boxes/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TicketBoxes = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('publicHolidays', 'from', 'to'), _dec4 = Ember.computed('getWorkinDay', 'workinDayNumber'), _dec5 = Ember.computed('ticketBoxData', 'workinDayNumber', 'type', 'members'), _dec6 = Ember._action, (_class = class TicketBoxes extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _defineProperty(this, "from", (0, _moment.default)().add(-1, 'M'));

      _defineProperty(this, "to", (0, _moment.default)());

      _defineProperty(this, "ticketBoxData", {});

      _defineProperty(this, "members", null);

      _defineProperty(this, "publicHolidays", null);
    }

    async didInsertElement() {
      this._super(...arguments);

      await this.getDataTask.perform();
      await this.getMembers.perform();
      await this.getPublicHolidays.perform();
    }

    *getDataTask() {
      const response = yield this.api.collectionAction('AiopsPartner', 'getTicketBoxData', {
        method: 'POST',
        data: {
          objId: this.model.id,
          from: this.from,
          to: this.to,
          type: this.type
        }
      });
      this.set('ticketBoxData', response);
    }

    *getPublicHolidays() {
      let publicHolidays = yield this.get('store').query('publicHoliday', {
        filter: {
          order: 'date'
        }
      });
      this.set('publicHolidays', publicHolidays);
    }

    *getMembers() {
      const members = yield this.store.query('aiopsSolver', {
        filter: {
          where: {
            userGradeId: this.model.id
          }
        }
      });
      this.set('members', members);
    }

    get workinDayNumber() {
      let daycount = 0;

      if (!this.publicHolidays || !this.from || !this.to) {
        return 1;
      }

      const from = (0, _moment.default)(this.from);
      const to = (0, _moment.default)(this.to);
      let tempDate = from.clone();

      while (tempDate.isSameOrBefore(to, 'd')) {
        if (tempDate.day() !== 0 && tempDate.day() !== 6) {
          daycount++;
        }

        let foundPublicHoliday = this.publicHolidays.find(ph => {
          return (0, _moment.default)(ph.date).isSame(tempDate);
        });

        if (foundPublicHoliday) {
          let fphMoment = (0, _moment.default)(foundPublicHoliday.date);

          if (foundPublicHoliday.isWorkDay && (fphMoment.day() === 0 || fphMoment.day() === 6)) {
            daycount++;
          } else if (!foundPublicHoliday.isWorkDay && !(fphMoment.day() === 0 || fphMoment.day() === 6)) {
            daycount--;
          }
        }

        tempDate.add(1, 'd');
      }

      return daycount ? daycount : 1;
    }

    get ticketPerDay() {
      if (!this.ticketBoxData) {
        return 0;
      }

      return parseFloat(parseInt(this.ticketBoxData.allQty) / this.workinDayNumber).toFixed(2);
    }

    get utilization() {
      let memberLength = 1;

      if (this.type === 'competency') {
        memberLength = this.members ? this.members.length : 1;
      }

      if (!this.ticketBoxData) {
        return 0;
      }

      return parseFloat(parseFloat(this.ticketBoxData.allSpentTime) / (this.workinDayNumber * 8) * 100 / memberLength).toFixed(2);
    }

    async getData() {
      await this.getDataTask.perform();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getDataTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "getDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getPublicHolidays", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "getPublicHolidays"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getMembers", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "getMembers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "workinDayNumber", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "workinDayNumber"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ticketPerDay", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "ticketPerDay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "utilization", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "utilization"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getData", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "getData"), _class.prototype)), _class));
  _exports.default = TicketBoxes;
});