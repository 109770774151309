define("iris/routes/tasks/availability-generate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function (params) {
      var store = this.store;
      return Ember.RSVP.hash({
        users: store.query('account', {
          filter: {
            where: {
              enabled: 1
            }
          }
        }),
        files: store.query('project', {
          filter: {
            where: {
              closed: 0
            },
            order: 'name'
          }
        }),
        businessServices: store.query('businessService', {
          filter: {
            order: 'name'
          }
        }),
        serviceAreas: store.query('serviceArea', {
          filter: {
            order: 'title'
          }
        })
      });
    }
  });

  _exports.default = _default;
});