define("iris/components/forms/ticket-overview/component", ["exports", "iris/lib/modals"], function (_exports, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modals: Ember.inject.service(),
    filteredTicketStates: Ember.computed.filterBy('ticketStates', 'project'),
    actions: {
      async changeProductResult(productResult) {
        const model = this.model;
        model.set('productResult', productResult);

        if (Ember.isEmpty(model.plannedStartDate) && Ember.isEmpty(model.plannedEndDate)) {
          model.set('plannedStartDate', productResult.get('plannedStartDate'));
          model.set('plannedEndDate', productResult.get('plannedEndDate'));
        }
      },

      async addNew() {
        const record = this.store.createRecord('fileProductResult');
        this.model.get('file.productResults').pushObject(record);
        let newItem = await this.modals.open('modals/form-modal', {
          model: record,
          itemName: 'productResults',
          parentModel: this.model.file,
          neededFields: ['name', 'plannedStartDate', 'plannedEndDate', 'billable']
        }, {
          className: _modals.ModalLayoutClassName['M']
        });
        this.send('changeProductResult', record);
      },

      uploadFile() {
        const self = this;
        this.modals.open('modals/file-upload-promise', {
          model: self.model,
          only: 'Bejl'
        }, {
          className: _modals.ModalLayoutClassName['L']
        });
      }

    }
  });

  _exports.default = _default;
});