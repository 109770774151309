define("iris/components/search-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['input-group', 'search-field'],
    searchTerm: '',

    filter() {
      let searchedColumns = ['title', 'timeRange'];
      let searchTerm = this.get('searchTerm');

      if (searchTerm === '') {
        return this.get('data');
      }

      return this.get('data').filter(function (record) {
        let found = false;

        for (let i = 0; i < searchedColumns.length; i++) {
          let value = record.get(searchedColumns[i]);

          if (String(value).indexOf(searchTerm) > -1) {
            found = true;
            break;
          }
        }

        return found;
      });
    },

    actions: {
      search() {}

    }
  });

  _exports.default = _default;
});