define("iris/routes/settings/cmdb/manufacturers/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ManufacturerEditRoute extends Ember.Route {
    model(params) {
      if (params.id == 'new') {
        return this.store.createRecord('entity-manufacturer');
      }

      return this.store.findRecord('entity-manufacturer', params.id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = ManufacturerEditRoute;
});