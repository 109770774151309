define("iris/components/forms/settings-operations-ticket-states", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Initialzers does not run in old style tests, hence injections has to be
    // made explicit.
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.get('fetchAvailableTicketStates').perform();
      this.get('fetchDestinationStates').perform();
    },

    availableTicketStates: null,
    fetchAvailableTicketStates: (0, _emberConcurrency.task)(function* () {
      let ticketStates = yield this.get('store').query('ticketState', {
        filter: {
          where: {
            and: [{
              sd: 1
            }, {
              or: [{
                isSystemType: false
              }, {
                isSystemType: null
              }]
            }]
          }
        }
      });
      this.set('availableTicketStates', ticketStates.filter(ts => {
        return ts.get('id') !== this.get('model.id');
      }));
    }),
    fetchDestinationStates: (0, _emberConcurrency.task)(function* () {
      let ticketStateChangeAllows = yield this.get('store').query('ticketStateChangeAllow', {
        filter: {
          where: {
            and: [{
              isAllowed: true
            }, {
              originStateId: this.get('model.id')
            }]
          }
        }
      });
      let destinationStateIds = ticketStateChangeAllows.map(record => record.get('destinationStateId'));
      let ticketStates = [];

      if (destinationStateIds.length > 0) {
        ticketStates = yield this.get('store').query('ticketState', {
          filter: {
            where: {
              and: [{
                sd: 1
              }, {
                or: [{
                  isSystemType: false
                }, {
                  isSystemType: null
                }]
              }, {
                id: {
                  inq: destinationStateIds
                }
              }]
            }
          }
        });
      }

      this.set('model.destinationStates', ticketStates);
      this.set('model.previousDestinationStates', ticketStates); // Keep a backup copy of the current records
    })
  });

  _exports.default = _default;
});