define("iris/models/news", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { belongsTo, hasMany } from 'ember-data/relationships';
  const Validations = (0, _emberCpValidations.buildValidations)({
    subject: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 200
    })],
    transitionTo: (0, _emberCpValidations.validator)('length', {
      max: 200
    }),
    linkSubject: (0, _emberCpValidations.validator)('length', {
      max: 200
    }),
    contents: (0, _emberCpValidations.validator)('presence', {
      presence: true
    })
  });

  var _default = _model.default.extend(Validations, {
    subject: (0, _attr.default)('string'),
    contents: (0, _attr.default)('string'),
    transitionTo: (0, _attr.default)('string'),
    linkSubject: (0, _attr.default)('number'),
    createDate: (0, _attr.default)('date'),
    writeDate: (0, _attr.default)('date')
  });

  _exports.default = _default;
});