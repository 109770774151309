define("iris/components/forms/aiops/ticket/component", ["exports", "iris/utils/properties"], function (_exports, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    testTicket: (0, _properties.computedTask)('model', function* () {
      if (!this.model) {
        return;
      }

      const testTickets = yield this.store.query('AiopsTestTicket', {
        filter: {
          where: {
            subject: this.model.subject
          }
        }
      });
      return testTickets.get('firstObject');
    })
  });

  _exports.default = _default;
});