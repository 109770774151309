define("iris/components/input/select/lazy/component", ["exports", "ember-concurrency", "iris/utils/ember-concurrency", "iris/utils/properties"], function (_exports, _emberConcurrency, _emberConcurrency2, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    multiple: null,
    // boolean
    modelName: null,
    // string
    searchProperties: null,
    // string[]
    searchProperty: 'name',
    order: 'name',
    where: null,
    // @, object
    include: null,
    // @, string | string[] | null
    onLoadRecords: null,
    // (records) => void
    records: null,
    // A()
    offset: 0,
    limit: 10,
    total: 0,
    debounce: _properties.DEFAULT_DEBOUNCE_MS,
    searchTerm: '',
    conditionArgsDidChange: (0, _properties.observerOnce)('where', function () {
      this.set('total', 0);
      this.set('offset', 0);
      this.set('records', Ember.A());
    }),
    searchConditions: Ember.computed('include', 'limit', 'offset', 'order', 'searchProperties', 'searchProperty', 'searchTerm', 'where', function () {
      (false && !(typeof this.searchProperties === 'string' || typeof this.searchProperty === 'string') && Ember.assert('You must provide either a searchProperty or searchProperties to Input::Select::Lazy', typeof this.searchProperties === 'string' || typeof this.searchProperty === 'string'));
      let conditions = [];

      if (this.searchTerm) {
        let terms = this.searchTerm.split(/\s+/);
        terms.map(term => {
          if (Array.isArray(this.searchProperties)) {
            return {
              or: this.searchProperties.map(sp => ({
                [sp]: {
                  ilike: `%${term}%`
                }
              }))
            };
          }

          return {
            [this.searchProperty]: {
              ilike: `%${term}%`
            }
          };
        }).forEach(condition => conditions.push(condition));
      }

      if (this.where) {
        conditions.push(this.where);
      }

      let where = createQuery(conditions);
      let options = [];

      if (this.include) {
        options.include = [].concat(this.include);
      }

      return { ...options,
        where,
        limit: this.limit,
        offset: this.offset,
        order: this.order
      };
    }),
    // This is to be called when text is entered into the search field. As such,
    // it resets all properties, and provides a clean state, setting the records
    // when the query returned.
    searchTask: (0, _emberConcurrency.task)(function* (searchTerm) {
      this.set('total', 0);
      this.set('offset', 0);
      this.set('searchTerm', searchTerm);
      this.set('records', Ember.A());

      if (this.debounce > 0) {
        yield (0, _emberConcurrency2.timeout)(_properties.DEFAULT_DEBOUNCE_MS);
      }

      let result = yield this.queryRecordsTask.perform();
      this.updateRecords(result.toArray());
    }).restartable(),
    // Called when the list is scrolled to the end to, well, load more records and
    // append them to the existing ones. We need to check if there are records
    // already loaded, because if they aren't (no records matching the criteria),
    // increasing the `offset` won't return any records either.
    loadMoreRecordsTask: (0, _emberConcurrency.task)(function* () {
      if (!this.records) {
        return Ember.A();
      }

      this.set('offset', this.offset + this.limit);
      let result = yield this.queryRecordsTask.perform();
      let records = this.records.concat(result.toArray());
      this.updateRecords(records);
    }).restartable(),
    // This one is a bit tricky: we need this task to load records right when the
    // input becomes focused, to immediately show the first set of options without
    // having to enter anything into the search field. This is handy when there
    // are only a handful of records, and we don't require the user to know what
    // to search for. For example, finding an account from 1000s of records
    // require searching, but when the user is about to select a status for a
    // project, which could only be like 10 options, searching is in the way of a
    // good UX. But since the task runs on every `onopen` event, we need to check
    // if the list is not paginated yet, because that would erase the records that
    // are loaded in addition to the initial set.
    reloadRecordsTask: (0, _emberConcurrency.task)(function* () {
      // Test `onopen` only reloads when the offset is 0
      if (this.offset === 0) {
        let result = yield this.queryRecordsTask.perform();
        this.updateRecords(result.toArray());
      }
    }).restartable(),
    // The task to actually query records. It must not set the result array on the
    // component, because when records are loaded with a different `offset` *in
    // addition to the current records*, they'll need to be added to the existing
    // record array. It can, however, set the `total` property, because it should
    // be the same, regardless the current `offset`.
    queryRecordsTask: (0, _emberConcurrency.task)(function* () {
      var _result$meta;

      let result = yield this.store.query(this.modelName, {
        filter: this.searchConditions
      });
      this.set('total', (_result$meta = result.meta) === null || _result$meta === void 0 ? void 0 : _result$meta.total);
      return result.toArray();
    }).restartable(),

    updateRecords(records) {
      if (typeof this.onLoadRecords === 'function') {
        this.onLoadRecords(records);
      } else {
        this.set('records', records);
      }
    },

    actions: {
      focusOutTrigger() {
        this.set('searchTerm', '');
      },

      onInputTrigger(searchTerm) {
        if (searchTerm.length === 0) {
          this.set('searchTerm', searchTerm);
          this.reloadRecordsTask.perform();
        }
      }

    }
  });

  _exports.default = _default;

  const createQuery = conditions => {
    switch (conditions.length) {
      case 0:
        return {};

      case 1:
        return conditions[0];

      default:
        return {
          and: conditions
        };
    }
  };
});