define("iris/components/liquid-tether", ["exports", "liquid-tether/components/liquid-tether", "iris/config/environment"], function (_exports, _liquidTether, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const isTesting = _environment.default.environment === 'test';

  var _default = _liquidTether.default.extend({
    _mutationObserver: null,
    back: 1,

    didInsertElement() {
      this._super();

      let self = this;
      $(document).on('keyup.close', function (e) {
        if (e.keyCode === 27) {
          let onOverlayClick = self.get('on-overlay-click');

          if (onOverlayClick) {
            onOverlayClick();
          }
        }
      });

      this._setupAutoReposition();
    },

    willDestroyElement() {
      this._super(...arguments);

      $(document).unbind('keyup.close');

      this._teardownAutoReposition();
    },

    _setupAutoReposition() {
      Ember.run.schedule('afterRender', () => {
        let liquidTarget = this.get('to');
        let targetNode = document.querySelector(`.${liquidTarget}`);

        if (targetNode) {
          this._mutationObserver = new MutationObserver(this._contentDidChange.bind(this));

          this._mutationObserver.observe(targetNode, {
            childList: true,
            attributes: true,
            subtree: true
          });
        }
      });
    },

    _teardownAutoReposition() {
      if (this._mutationObserver) {
        this._mutationObserver.disconnect();
      }

      Ember.run.cancel(this._repositionTether);
    },

    _contentDidChange() {
      // The DOM could change many times in a single run loop, this ensures the
      // repositioning runs only once per loop.
      //
      // I don't think this needs to be wrapped in an `Ember.run`, but I guess the
      // MutationObserver can't be targeted as easily as other user interactions,
      // so this is here to keep the tests happy - abeit it might modify the
      // behavior a tiny bit...
      //
      // More info on when to use `Ember.run`:
      // https://stackoverflow.com/a/28774239/1763448
      let repositionTether = () => Ember.run.scheduleOnce('afterRender', this, this._repositionTether);

      isTesting ? Ember.run(repositionTether) : repositionTether();
    },

    _repositionTether() {
      if (this._tether) {
        this._tether.position();
      }
    }

  });

  _exports.default = _default;
});