define("iris/components/forms/entity-site", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EntitySite = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class EntitySite extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "model", null);

      _defineProperty(this, "addresses", []);

      _initializerDefineProperty(this, "entityForm", _descriptor, this);
    }

    *setModelTask() {
      const {
        model
      } = this;
      const addresses = [];
      yield this.entityForm.setModel(model);
      addresses.push(...(yield this.getAddresses('assignedPartner')));
      addresses.push(...(yield this.getAddresses('partner')));
      Ember.set(this, 'addresses', addresses);
    }

    async getAddresses(relation) {
      const {
        model
      } = this;
      const addresses = [];

      if (model.belongsTo(relation).id()) {
        const partnerAddresses = await model.get(`${relation}.addresses`);
        addresses.push(...partnerAddresses.toArray());
      }

      return addresses;
    }

    async changeAddress(address) {
      this.entityForm.setAddress(address);

      if (!address) {
        return;
      }

      const buildings = await address.get('buildings');

      if (buildings.length === 1) {
        this.changeBuilding(buildings.firstObject);
      }
    }

    async changeBuilding(building) {
      this.entityForm.setBuilding(building);

      if (!building) {
        return;
      }

      const floors = await building.get('floors');

      if (floors.length === 1) {
        this.changeFloor(floors.firstObject);
      }
    }

    async changeFloor(floor) {
      this.entityForm.setFloor(floor);

      if (!floor) {
        return;
      }

      const locations = await floor.get('locations');

      if (locations.length === 1) {
        this.model.set('location', locations.firstObject);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "entityForm", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setModelTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "setModelTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeAddress", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "changeAddress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeBuilding", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "changeBuilding"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeFloor", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "changeFloor"), _class.prototype)), _class));
  _exports.default = EntitySite;
});