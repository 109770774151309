define("iris/services/ajax", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    // http://stackoverflow.com/questions/9705773/non-crud-actions-with-ember-data
    call: function (method, type, id, action) {
      let hash = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      let authorize = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : true;
      var owner = Ember.getOwner(this);

      if (authorize === true) {
        var adapter = owner.lookup('adapter:application');
      } else {
        var adapter = owner.lookup('adapter:unauth');
      }

      var url = adapter.buildURL(type, id) + '/' + action; // ember-data >= 3.11 expects the `hash` argument not to be `null`

      hash = { ...hash,
        data: hash
      };
      return adapter.ajax(url, method, hash);
    },
    downloadFile: function (data, callback) {
      var defaultData = {
        method: 'GET'
      };

      if (typeof data === 'string') {
        defaultData.url = data;
      } else {
        defaultData.url = data.url;
        defaultData.method = data.method;
      }

      var oReq = new XMLHttpRequest();
      oReq.open(defaultData.method, defaultData.url, true);

      if (data.headers && Array.isArray(data.headers)) {
        data.headers.forEach(function (currentValue) {
          oReq.setRequestHeader(currentValue.key, currentValue.value);
        });
      }

      oReq.responseType = "arraybuffer";

      oReq.onload = function (oEvent) {
        callback(oReq.status, oReq.statusText, oReq.response, oReq.getAllResponseHeaders());
      };

      if (data.data) {
        defaultData.data = data.data;
        oReq.send(defaultData.data);
      } else {
        oReq.send();
      }
    }
  });

  _exports.default = _default;
});