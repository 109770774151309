define("iris/components/auto/form/component", ["exports", "ember-inflector", "iris/lib/auto/default-fields", "iris/utils/model"], function (_exports, _emberInflector, _defaultFields, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    router: Ember.inject.service(),
    loading: Ember.inject.service(),
    modals: Ember.inject.service(),
    api: Ember.inject.service(),

    init() {
      this._super();

      this.model.startTrack(); // this.addExitHandler();
    },

    tagName: '',
    defaultProportion: '2-10',
    hasRemovedExitHandler: false,
    frame: false,
    modal: false,
    noHeader: Ember.computed('model', function () {
      return !this.modal;
    }),
    noFooter: false,
    formClass: '',
    componentLayout: 'col-1',
    excludeFields: [],
    neededFields: [],
    translationFileds: [],
    fields: Ember.computed('model', 'excludeFields', 'translationFileds', 'neededFields', 'requiredFields', function () {
      this.neededFields = this.neededFields === '*' ? [] : this.neededFields;
      const translationFileds = this.translationFileds || [];
      const excludeFields = this.excludeFields || [];

      if (this.neededFields && this.neededFields.length) {
        return this.neededFields.filter(n => !excludeFields.includes(n) && !translationFileds.includes(n));
      }

      const res = [];
      this.model.constructor.fields.forEach(function (kind, field) {
        if (!excludeFields.includes(field) && !translationFileds.includes(field) && !_defaultFields.DefaultFields.Fields.includes(field)) {
          res.push(field);
        }
      });
      return res;
    }),
    sortedFields: Ember.computed('fields', function () {
      let left = Ember.A();
      let right = Ember.A();
      let l = Math.ceil(this.fields.length / 2);
      let i = 0;
      this.fields.forEach(field => {
        if (i < l) {
          left.pushObject(field);
        } else {
          right.pushObject(field);
        }

        i++;
      });
      return {
        left: left,
        right: right
      };
    }),
    modelName: Ember.computed('model', function () {
      return this.model.constructor.modelName;
    }),
    routeBack: Ember.computed('route', 'modelName', function () {
      if (this.route) {
        return this.route;
      } else {
        return (0, _emberInflector.pluralize)(this.modelName);
      }
    }),
    title: Ember.computed('model', function () {
      if (this.model.id) {
        return `${this.intl.t('menus.edit')}: ${this.intl.t(`${this.modelName}.modelName`)}`;
      } else {
        return `${this.intl.t('menus.new')}: ${this.intl.t(`${this.modelName}.modelName`)}`;
      }
    }),

    async _back(newItem) {
      // this.removeExitHandler();
      if (this.modal) {
        if (this.beforeCloseModal) {
          await this.beforeCloseModal();
        }

        this.close(newItem);
      } else {
        this.router.transitionTo(this.routeBack);
      }
    },

    addExitHandler() {
      this.router.on('routeWillChange', this, this.confirm);
    },

    removeExitHandler() {
      if (!this.hasRemovedExitHandler) {
        this.router.off('routeWillChange', this, this.confirm);
        this.hasRemovedExitHandler = true;
      }
    },

    confirm(transition) {
      if (transition.isAborted) {
        return;
      }

      this.confirmExit(transition);
    },

    async confirmExit(transition) {
      transition.abort();
      let leave = await this.modals.open('beta/modals/confirmation', {
        title: this.intl.t('general.unsaved-changes.title'),
        body: this.intl.t('general.unsaved-changes.body')
      });

      if (leave) {
        // this.removeExitHandler();
        transition.retry();
      }
    },

    willDestroy() {// this.removeExitHandler();
    },

    actions: {
      async update(record) {
        const {
          model,
          validations
        } = await (0, _model.validateRecord)(record);

        if (!validations.isValid) {
          console.log('Record is invalid', validations);
          model.setValidated();
          return;
        } else {
          model.setValidated();
        }

        try {
          if (this.beforeSave) {
            const res = await this.beforeSave(model);

            if (res === false) {
              return;
            }
          }

          let needClose = true;

          if (this.noClose && !model.id) {
            needClose = false;
          } else {
            needClose = !this.frame || this.modal || this.needClose ? true : false;
          }

          await this.loading.run(async () => {
            let savedRecord = await model.save(true);

            if (!savedRecord) {
              return;
            } // translations


            if (savedRecord.get('translations.length')) {
              for (const translation of savedRecord.get('translations').toArray()) {
                translation.set('sourceIdSave', savedRecord.get('id'));
                await translation.save(true);
              }
            }

            let customValues = savedRecord.get('customValues');

            if (customValues) {
              for (const customValue of customValues.toArray()) {
                customValue.set('modelId', savedRecord.get('id'));
                await customValue.save(true);
              }
            }

            if (needClose) {
              await this._back(true);
            } // afterSave


            if (this.afterSave) {
              await this.afterSave(savedRecord);
            }

            savedRecord.reload();
            this.flashMessages.info(this.intl.t('general.save.success'), {
              title: this.intl.t('general.save.success-title'),
              icon: 'check'
            });
          });
        } catch (reason) {
          this.flashMessages.danger(reason, {
            title: reason,
            icon: 'warning',
            sticky: true
          });
          record.unloadRecord();
        }
      },

      async cancel(record) {
        if (record.id) {
          if (record.get('isDirty')) {
            try {
              record.rollback();
            } catch (reason) {
              console.log(reason);
            }
          }
        } else {
          record.deleteRecord();
        }

        await this._back(false);
      }

    }
  });

  _exports.default = _default;
});