define("iris/models/resource-group", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    authorizer: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    authorizer: (0, _emberCpValidations.validator)('presence', {
      presence: true
    })
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    manager: (0, _relationships.belongsTo)('account'),
    authorizer: (0, _relationships.belongsTo)('account'),
    solvers: (0, _relationships.hasMany)('account'),
    planners: (0, _relationships.hasMany)('account')
  });

  _exports.default = _default;
});