define("iris/controllers/finances/pipelines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tabs: [{
      name: 'Lista',
      route: 'finances.pipelines.list'
    }, {
      name: 'Dashboard',
      route: 'finances.pipelines.dashboard'
    }, {
      name: 'Szerződött állomány',
      route: 'finances.pipelines.contracted'
    }, {
      name: 'Lehetőség állomány',
      route: 'finances.pipelines.opportunities'
    }]
  });

  _exports.default = _default;
});