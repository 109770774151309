define("iris/templates/tasks/plans", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JaZejgAv",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"tables/tasks-table\",null,[[\"filter\"],[true]]],false],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/tasks/plans.hbs"
    }
  });

  _exports.default = _default;
});