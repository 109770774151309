define("iris/components/bs-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   Extends Ember.TextField to add Bootstrap's 'form-control' class.
  
   @class Input
   @namespace Components
   @extends Ember.TextField
   @public
   */
  var _default = Ember.TextField.extend({
    classNames: ['form-control']
  });

  _exports.default = _default;
});