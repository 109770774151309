define("iris/templates/crm/opportunities/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s5fHsahp",
    "block": "{\"symbols\":[],\"statements\":[[5,\"project/edit\",[],[[\"@model\",\"@disableInputObject\",\"@route\",\"@opportunity\"],[[23,0,[\"model\"]],[23,0,[\"disableInputObject\"]],\"crm.opportunities\",true]]],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/crm/opportunities/edit.hbs"
    }
  });

  _exports.default = _default;
});