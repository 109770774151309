define("iris/services/loading-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    startLoading() {
      this.trigger('startLoading');
    },

    endLoading() {
      this.trigger('endLoading');
    },

    changeAttrs(attrs) {
      this.trigger('changeAttrs', attrs);
    }

  });

  _exports.default = _default;
});