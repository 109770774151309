define("iris/routes/organizations/departments", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model: function (params) {
      var store = this.store;
      return Ember.RSVP.hash({
        partners: store.query('partner', {
          filter: {
            where: {
              or: [{
                customer: 1
              }, {
                subcontractor: 1
              }]
            },
            order: 'name'
          }
        })
      });
    },
    actions: {
      afterSave: function (savedRecord) {
        savedRecord.get('customValues').forEach(customValue => {
          customValue.set('modelId', savedRecord.get('id'));
          customValue.save();
        });
        var controller = this.controllerFor('organizations.departments');
        controller.set('refresh', false);
        Ember.run.next(function () {
          controller.set('refresh', true);
        });
      }
    }
  });

  _exports.default = _default;
});