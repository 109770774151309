define("iris/routes/cmdb/impact-study", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ImpactStudy extends Ember.Route {
    async model(params) {
      return this.store.findRecord('entity', params.id);
    }

  }

  _exports.default = ImpactStudy;
});