define("iris/components/beta/filters/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PSMGwg8S",
    "block": "{\"symbols\":[\"filter\",\"isSelected\",\"@selectFilter\",\"@clearSelection\",\"@removeFilter\",\"@filtersToBeCleared\",\"@selectedFilter\",\"&attrs\",\"@filters\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"\\n    px-2 py flex items-center rounded border\\n    border-gray-300 bg-gray-200\\n    dark:bg-portoDark-gray-161a1e dark:border-gray-700\\n  \"],[13,8],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex flex-wrap gap-2\"],[8],[0,\"\\n\"],[4,\"each\",[[23,9,[]]],null,{\"statements\":[[4,\"let\",[[28,\"or\",[[28,\"eq\",[[23,1,[]],[23,7,[]]],null],[28,\"eq\",[[23,1,[\"id\"]],[23,7,[\"replaceFilterWithId\"]]],null]],null]],null,{\"statements\":[[0,\"        \"],[5,\"beta/filters/filter\",[],[[\"@filter\",\"@isSelected\",\"@onClick\",\"@onClickRemove\",\"@isFaded\"],[[23,1,[]],[23,2,[]],[28,\"if\",[[23,2,[]],[23,4,[]],[23,3,[]]],null],[23,5,[]],[28,\"contains\",[[23,1,[\"attributeName\"]],[28,\"map-by\",[\"attributeName\",[23,6,[]]],null]],null]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"\\n    \"],[14,10],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/beta/filters/list/template.hbs"
    }
  });

  _exports.default = _default;
});