define("iris/routes/dashboard/ticket", ["exports", "moment", "iris/mixins/incident-save", "iris/models/ticket-state"], function (_exports, _moment, _incidentSave, _ticketState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_incidentSave.default, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    uploadTitle: computed(function () {
      return this.intl.t('attachments.uploadTitle');
    }),
    redirectingRoute: 'dashboard',
    canSolve: false,

    model(params) {
      return this.store.find('ticket', params.id);
    },

    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set('parentRouteName', this.get('parentRouteName'));
      controller.set('solveTicketDialog', false);
      controller.set('reasonModal', false);
      controller.set('redirectingRoute', this.get('redirectingRoute'));
      controller.set('canSolve', this.get('canSolve'));
    },

    createWorkLog() {
      let date = (0, _moment.default)();
      let round = 15;
      let remainder = (round - date.minute()) % round;
      date.add(remainder, 'm');
      let ticket = this.controller.get('model');
      let user = this.get('sessionAccount.account');
      return this.store.createRecord('workLog', {
        ticket: ticket,
        description: this.intl.t('operation.report-submit.new-worksheet'),
        user: user,
        beginDate: date.toDate(),
        endDate: date.clone().add(1, 'h').toDate()
      });
    },

    actions: {
      closeTicket() {
        this.controller.set('closeTicketDialog', true);
      },

      cancelCloseTicket() {
        this.controller.set('closeTicketDialog', false);
      },

      async setTicketClosed(record, cb) {
        let ticketState = await this.store.find('ticketState', 'SYS_10'); // burnt-in

        record.set('state', ticketState);
        cb();
      },

      async solveTicket(record, cb) {
        let ticketState = await this.store.find('ticketState', 'SYS_07'); // burnt-in

        record.set('state', ticketState);
        cb();
        this.controller.set('closeTicketDialog', false);
      },

      openSolveTicketDialog() {
        let model = this.controller.get('model');

        if (!model.get('resolver.id')) {
          this.controller.set('solveResolverError', true);
          return;
        }

        if (!this.controller.get('model.workLogsWithId.length')) {
          let workLog = this.createWorkLog();
          this.controller.set('workLog', workLog);
        }

        this.controller.set('solveTicketDialog', true);
      },

      cancelSolveTicketDialog() {
        const workLog = this.controller.get('workLog');

        if (workLog) {
          workLog.destroyRecord();
          this.controller.set('workLog', null);
        }

        this.controller.set('solveTicketDialog', false);
      },

      async checkSaveTicket(redirectingRoute) {
        let model = this.controller.get('model');
        let reasonStates = [_ticketState.TICKET_STATE_ID.WaitingForReporter, _ticketState.TICKET_STATE_ID.WaitingForThirdParty, _ticketState.TICKET_STATE_ID.Rejected];

        if (model.get('stateChanged') && reasonStates.includes(model.get('state.id'))) {
          this.send('openReasonModal', false);
        } else if (model.get('stateChanged') && reasonStates.includes(model.get('oldStateId'))) {
          this.send('openReasonModal', true);
        } else {
          this.send('saveTicket', model, redirectingRoute);
        }
      },

      openReasonModal(isSuspendRemoval) {
        this.controller.set('isSuspendRemoval', isSuspendRemoval);
        this.controller.set('model.currentReason', null);
        this.controller.set('reasonModal', true);
      },

      closeReasonModal(isAccepted) {
        let model = this.controller.get('model');
        let redirectingRoute = this.controller.get('redirectingRoute');

        if (isAccepted) {
          this.send('saveTicket', model, redirectingRoute);
        } else {
          this.controller.set('reasonModal', false);
        }
      },

      saveTicket(model, redirectingRoute) {
        this.controller.set('model.oldStateId', null);
        this.controller.set('model.stateChanged', false);
        this.send('save', {
          afterSave: this.send('afterSave', 2, model),
          redirectTo: redirectingRoute
        }, model);
        this.send('canSolveApiCall');
      },

      async afterSaveWorkLog(workLog) {
        if (this.controller.get('model.workLogsWithId.length')) {
          let ticket = this.controller.get('model');
          const ticketState = await this.store.find('ticketState', 'SYS_07'); // burnt-in

          const redirectingRoute = this.controller.get('redirectingRoute');
          const uploadDocumentsService = this.get('uploadDocuments');
          await uploadDocumentsService.uploadPendingFiles(workLog);
          ticket.set('state', ticketState);
          this.send('saveTicket', ticket, redirectingRoute);

          if (ticket.get('ticketUserAssocs.length')) {
            ticket.get('ticketUserAssocs.firstObject').save();
          }
        }
      },

      async canSolveApiCall() {
        let allowedSolveStates = await this.get('store').query('ticketStateChangeAllow', {
          filter: {
            where: {
              and: [{
                destinationStateId: 'SYS_07'
              }, {
                isAllowed: true
              }]
            }
          }
        });
        this.set('canSolve', allowedSolveStates.map(statechange => statechange.get('originStateId')).includes(this.model.belongsTo('state').id()));
      }

    }
  });

  _exports.default = _default;
});