define("iris/components/cmdb-visualisation", ["exports", "iris/utils/properties", "iris/mixins/cmdb-visualisation/navigation", "iris/mixins/cmdb-visualisation/events", "iris/mixins/cmdb-visualisation/shapes", "underscore"], function (_exports, _properties, _navigation, _events, _shapes, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_navigation.default, _events.default, _shapes.default, {
    // Lazy load this using `this.joint = await import('jointjs').default` to avoid
    // jointjs to be bundled with the vendor code.
    joint: null,
    roots: Ember.A([]),
    affectedCIelementIDs: [],
    // @
    loading: Ember.inject.service(),
    // https://coolors.co/fefe99-d8eb98-b2d898-9abecc-80a3fe
    config: {
      ciCategory: {
        '000000001': 'FEFE99',
        '000000002': 'D8EB98',
        '000000003': 'B2D898',
        '000000004': '9ABECC',
        '000000005': '80A3FE'
      },
      defaultStrokes: {
        link: {
          color: '#808697',
          width: 2
        },
        element: '#000'
      },
      editedStrokes: {
        link: {
          color: '#F49D1A',
          width: 4
        },
        element: '#F49D1A'
      },
      states: {
        error: '#ff535d',
        warning: '#ffb143',
        ok: '#'
      },
      stateDictionary: {
        OK: 0,
        WARNING: 1,
        CRITICAL: 2,
        UNKNOWN: 3
      }
    },
    offset: {
      x: 0,
      y: 0
    },

    async didInsertElement() {
      this._super(...arguments);

      this.start();
    },

    observData: (0, _properties.observerTask)('dataToVisualise', function* () {
      if (this.dataToVisualise) {
        yield this.start();
        this.get('navigation').fit();
      }
    }),

    async start() {
      const el = this.$('div.holder', this);
      const parent = this.$().parent();
      this.set('holderElement', el);
      el.height(parent.height());
      el.width(parent.width());
      this.joint = await emberAutoImportDynamic("jointjs").default;
      const graph = new this.joint.dia.Graph();
      const paper = new this.joint.dia.Paper({
        el: el,
        model: graph,
        width: el.width(),
        height: el.height(),
        gridSize: 1,
        defaultConnectionPoint: {
          name: 'boundary',
          args: {
            stroke: true
          }
        }
      });
      this.set('graph', graph);
      this.set('paper', paper);
      let direction = 'up';

      if (this.model) {
        this.set('data', {
          elements: [],
          links: []
        });
        this.set('opaqueEntities', Ember.A());
        await this.pushEntityToData(await this.get('model'));
        const rootElementCategory = await this.get('model.entityType.entityTypeCategory');
        direction = rootElementCategory.get('id').substring(3) === '000000003' ? 'up' : 'down';
        this.set('graphDirection', direction);
      } else {
        this.set('data', this.dataToVisualise);
      }

      await this.loading.run(async () => {
        let data = null;

        if (this.model) {
          data = await this.getCells(this.get('model'), this.get('data'), direction);
          await this.addOpaqueEntitiesToData();
        } else {
          data = this.dataToVisualise;
        }

        if (!data) {
          return;
        }

        this.initShapes(this.joint, this.get('config'), graph);
        this.buildGraph(data);
        this.layoutGraph();
        this.initNavigation(el[0]);
        this.bindEvents(); // effects

        this.get('roots').addObjects(data.elements.filter(e => e.state === 'CRITICAL' || this.affectedCIelementIDs.includes(e.id)).map(e => this.findElementByIid(e.id)));
        this.renderPaths();
      });
    },

    findElementByIid(iid) {
      return _underscore.default.find(this.get('graph').getElements(), elem => {
        return iid === elem.attr('iid');
      });
    },

    layoutGraph() {
      this.joint.layout.DirectedGraph.layout(this.get('graph'), {
        nodeSep: 160,
        edgeSep: 200,
        rankSep: 160,
        marginX: 10,
        marginY: 10,
        rankDir: this.get('graphDirection') === 'up' ? 'TB' : 'BT',
        weight: 1
      });
    },

    renderPaths() {
      if (this.model) {
        this.clearEffects();
      }

      this.get('roots').forEach(r => {
        this.effects(r);
      });
    },

    connectionBoundDirection(bound) {
      const direction = this.get('graphDirection');

      if (direction === 'down' && bound === 'in') {
        return {
          inbound: true
        };
      }

      if (direction === 'down' && bound === 'out') {
        return {
          outbound: true
        };
      }

      if (direction === 'up' && bound === 'in') {
        return {
          outbound: true
        };
      }

      if (direction === 'up' && bound === 'out') {
        return {
          inbound: true
        };
      }
    },

    getElementOfLink(link, linkDirection) {
      const direction = this.get('graphDirection');

      if (linkDirection === 'source' && direction === 'down' || linkDirection === 'target' && direction === 'up') {
        return link.getSourceElement();
      }

      if (linkDirection === 'target' && direction === 'down' || linkDirection === 'source' && direction === 'up') {
        return link.getTargetElement();
      }
    },

    effects(elem) {
      let effect = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
      let depth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

      if (this.dataToVisualise) {
        return;
      }

      let strokeColor = this.get('config.states.error');
      const graph = this.get('graph');
      const elemIsTS = elem.attr('ciCategory') === '000000004' ? true : false;

      if (elemIsTS && depth > 0 || effect === 'warning') {
        strokeColor = this.get('config.states.warning');
        effect = 'warning';
      } // Technical Service is only down, if
      // - it has at least one borken inbound TS and
      // - threshold >= of the redundant (non TS) inbound connections are down


      if (elemIsTS) {
        const inboundLinks = graph.getConnectedLinks(elem, this.connectionBoundDirection('in'));
        let redundantElems = 0,
            brokenElems = 0;
        inboundLinks.forEach(link => {
          const source = this.getElementOfLink(link, 'source');

          if (source.attr('ciCategory') === '000000004') {
            if (source.attr('effectedState') === 'error') {
              strokeColor = this.get('config.states.error');
              effect = 'error';
            }
          } else {
            redundantElems++;
            if (source.attr('effectedState') === 'error') brokenElems++;
          }
        });

        if (brokenElems / redundantElems > elem.prop('threshold')) {
          strokeColor = this.get('config.states.error');
          effect = 'error';
        }
      }

      elem.attr('body/stroke', strokeColor);
      elem.attr('effectedState', effect);
      const relationTypes = ['Runs', 'Depends', 'Virtualizes', 'Runs on', 'Virtualized by', 'Depends on'];
      const outboundLinks = graph.getConnectedLinks(elem, this.connectionBoundDirection('out'));
      outboundLinks.forEach(link => {
        if (relationTypes.includes(link.attr('relationType'))) {
          link.attr('line/stroke', strokeColor);
          link.attr('line/strokeWidth', 4);
          this.effects(this.getElementOfLink(link, 'target'), effect, ++depth);
        }
      });
    },

    clearEffects() {
      this.get('graph').getLinks().forEach(link => {
        link.attr('line/stroke', this.get('config.defaultStrokes.link.color'));
        link.attr('line/strokeWidth', this.get('config.defaultStrokes.link.width'));
      });
      this.get('graph').getElements().forEach(element => {
        element.attr('body/stroke', this.get('config.defaultStrokes.element'));
        element.attr('effectedState', 'ok');
      });
    },

    buildGraph(data) {
      const graph = this.get('graph');
      const config = this.get('config');
      data.elements.forEach((element, i) => {
        let elem;

        switch (element.category) {
          case '000000004':
            elem = new this.joint.shapes.iris.Cog();
            break;

          case '000000005':
            elem = new this.joint.shapes.iris.Cert();
            break;

          default:
            elem = new this.joint.shapes.iris.Icon();
        }

        elem.resize(120, 90);

        if (!['000000004', '000000005'].includes(element.category)) {
          elem.attr({
            body: {
              r: 60
            },
            masterLinkStats: {}
          });

          if (element.svg) {
            elem.attr('image/xlink:href', 'data:image/svg+xml;utf8,' + encodeURI(element.svg));
          }

          elem.hideCollapse();
        }

        if (this.affectedCIelementIDs.includes(element.id)) {
          element.state = 'CRITICAL';
        }

        elem.prop('state/value', config.stateDictionary[element.state]);
        elem.prop('opaque', element.opaque);
        elem.prop('threshold', element.threshold);
        elem.attr({
          iid: element.id,
          ciCategory: element.category,
          body: {
            fill: '#' + config.ciCategory[element.category],
            stroke: element.edited ? config.editedStrokes.element : config.defaultStrokes.element
          }
        });
        elem.attr('label/text', element.name);
        elem.attr('image/title', element.type);
        elem.attr('typeBoxText/textWrap', {
          text: element.type,
          ellipsis: true,
          width: -20
        });
        elem.addTo(graph);
      }); // relationships

      data.links.forEach(linkData => {
        let link = new this.joint.shapes.standard.Link();
        link.source(this.findElementByIid(linkData.source));
        link.target(this.findElementByIid(linkData.target));
        link.attr({
          relationType: linkData.name,
          line: {
            stroke: linkData.edited ? config.editedStrokes.link.color : config.defaultStrokes.link.color,
            strokeWidth: linkData.edited ? config.editedStrokes.link.width : config.defaultStrokes.link.width
          }
        });
        link.labels([{
          attrs: {
            text: {
              text: linkData.name
            }
          }
        }]);
        link.connector('rounded', {
          radius: 10
        });
        link.addTo(graph);
        link.toBack();
      });
    },

    async drillUp(entity) {
      const {
        inverseEntity,
        inverseRelation
      } = this.getDirections();
      const inboundLinks = await entity.get(inverseRelation);

      for (const link of inboundLinks.toArray()) {
        const invEn = await link.get(inverseEntity);
        this.get('opaqueEntities').addObject(invEn);
        const type = await link.get('entityRelationType');
        this.pushLinkToData(type.get('name'), invEn.get('id'), entity.get('id'));
      }
    },

    async addOpaqueEntitiesToData() {
      const data = this.get('data');
      const dataElementIds = data.elements.map(e => e.id);
      const rest = this.get('opaqueEntities').filter(e => !dataElementIds.includes(e.get('id')));

      for (const entity of rest) {
        await this.pushEntityToData(entity, true);
      }
    },

    async getSVG(typeId) {
      const entityTypes = await this.store.query('entityType', {
        filter: {
          where: {
            id: typeId
          },
          include: ['entityTypeVisualisation']
        }
      });

      if (entityTypes.length) {
        const entityTypeVisualisation = entityTypes.get('firstObject').belongsTo('entityTypeVisualisation').value();

        if (entityTypeVisualisation) {
          return entityTypeVisualisation.get('svg');
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    async pushEntityToData(entity) {
      let opaque = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const data = this.get('data');
      const id = entity.get('id');

      if (!id) {
        return;
      }

      if (data.elements.find(el => el.id === id)) {
        return data;
      }

      if (opaque === false && this.get('graphDirection') === 'down') {
        await this.drillUp(entity);
      }

      const type = await entity.get('entityType');
      const category = await type.get('entityTypeCategory');
      const customAttributes = JSON.parse(entity.get('customAttributes'));
      const svg = await this.getSVG(type.get('id'));
      data.elements.push({
        id: id,
        name: entity.get('name'),
        type: type.get('name'),
        opaque: opaque,
        svg,
        threshold: customAttributes ? customAttributes['threshold'] : 0.5,
        state: entity.get('icingaStatus'),
        category: category.get('id').substring(3)
      });
      return data;
    },

    pushLinkToData(name, source, target) {
      const data = this.get('data');
      const link = {
        name: name,
        source: source,
        target: target
      }; // push only uniq links

      if (_underscore.default.findWhere(data.links, link) === undefined) {
        data.links.push(link);
      }
    },

    /**
     * entity ember data record
     * data the component's inner representation of the graph in JSON format
     * direction {down | up} default is 'down' Hardware -> Software -> BS
     */
    async getCells(entity, data) {
      let direction = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'down';
      const {
        relation,
        whichEnity,
        relationName
      } = this.getDirections(direction);
      let slaveRelations = await entity.get(relation);

      for (const slaveRelation of slaveRelations.toArray()) {
        let entityRelationType = await slaveRelation.get('entityRelationType');
        let slaveEntity = await slaveRelation.get(whichEnity);
        await this.pushEntityToData(slaveEntity);
        this.pushLinkToData(entityRelationType.get(relationName), entity.get('id'), slaveEntity.get('id'));
        await this.getCells(slaveEntity, data, direction);
      }

      return data;
    },

    getDirections(direction) {
      let res = {
        relation: 'masterRelations',
        whichEnity: 'slaveEntity',
        inverseEntity: 'masterEntity',
        relationName: 'name',
        inverseRelation: 'slaveRelations'
      };

      if (direction === 'up') {
        res.relation = 'slaveRelations';
        res.inverseEntity = 'slaveEntity', res.whichEnity = 'masterEntity';
        res.relationName = 'inverseName';
        res.inverseRelation = 'masterRelations';
      }

      return res;
    },

    bindEvents() {
      const paper = this.get('paper');
      const graph = this.get('graph');
      paper.on('element:pointerdblclick', elementView => {
        const {
          model
        } = elementView;
        const iid = model.attr('iid');
        const roots = this.get('roots');

        if (roots.includes(model)) {
          roots.removeObject(model);
        } else {
          roots.pushObject(model);
        }

        this.renderPaths();
        const state = model.prop('state/value') === 2 ? 0 : 2;
        model.prop('state/value', state);
      });
      paper.on('element:contextmenu', async elementView => {
        const iid = elementView.model.attr('iid');

        if (this.get('selectedEntity.id') === iid) {
          return this.set('selectedEntity', null);
        }

        const entity = await this.get('store').find('entity', iid);
        this.set('selectedEntity', entity);
        entity.toggleProperty('icingaInfoRefreshIndicator');
      });
    }

  });

  _exports.default = _default;
});