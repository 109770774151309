define("iris/controllers/login", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })
  });
  let recovery = Ember.Object.extend(Validations, {});
  recovery = recovery.create({
    email: ''
  });

  var _default = Ember.Controller.extend({
    showModalDialog: false,
    emailSent: false,
    changeRequestIp: null,
    recovery: recovery,
    actions: {
      closeModalDialog() {
        this.set('showModalDialog', false);
        this.set('emailSent', false);
      },

      openModal() {
        this.set('showModalDialog', true);
      },

      sendRequest(recovery) {
        const flashMessages = this.get('flashMessages');
        flashMessages.add({
          message: 'Az új jelszó igényléséhez szükséges instrukciókat elküldtük emial címére. BETA, még nem történik semmi',
          title: 'IRIS message',
          icon: 'info'
        });
        this.set('showModalDialog', false);
      },

      onCaptchaResolved() {},

      onCaptchaExpired() {}

    }
  });

  _exports.default = _default;
});