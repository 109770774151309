define("iris/templates/components/pick-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6LCiZYQg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-4\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-4 center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"btn-group\"],[8],[0,\"\\n      \"],[1,[28,\"iris-button\",null,[[\"type\",\"disabled\",\"action\",\"icon\"],[\"primary\",[28,\"not\",[[24,[\"rightSelectedItems\",\"length\"]]],null],[28,\"action\",[[23,0,[]],\"move\",0],null],\"fa fa-angle-double-left\"]]],false],[0,\"\\n      \"],[1,[28,\"iris-button\",null,[[\"type\",\"disabled\",\"action\",\"icon\"],[\"primary\",[28,\"not\",[[24,[\"leftSelectedItems\",\"length\"]]],null],[28,\"action\",[[23,0,[]],\"move\",1],null],\"fa fa-angle-double-right\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-4\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n    \"],[1,[28,\"pick-list/list-column\",null,[[\"model\",\"selectedItems\",\"otherItems\"],[[24,[\"leftList\"]],[24,[\"leftSelectedItems\"]],[24,[\"rightSelectedItems\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n    \"],[1,[28,\"pick-list/list-column\",null,[[\"model\",\"selectedItems\",\"otherItems\"],[[24,[\"rightList\"]],[24,[\"rightSelectedItems\"]],[24,[\"leftSelectedItems\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/pick-list.hbs"
    }
  });

  _exports.default = _default;
});