define("iris/templates/cmdb/entity-types/entities/entity/attributes/code-store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eLtlgkSK",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-tether\",null,[[\"to\",\"target\",\"targetModifier\",\"attachment\",\"on-overlay-click\",\"tetherClass\",\"overlayClass\"],[\"modal\",\"document.body\",\"visible\",\"middle center\",[28,\"route-action\",[\"back\",[24,[\"model\"]]],null],\"modal-dialog modal-sd\",\"modal-backdrop\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[1,[28,\"t\",[\"cmdb.attributes.add-dictionary\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n      \"],[1,[28,\"forms/entity-form-add-code-store\",null,[[\"value\",\"codeStore\"],[[24,[\"model\"]],[24,[\"codeStore\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n      \"],[1,[28,\"iris-button\",null,[[\"type\",\"text\",\"action\",\"value\"],[\"primary\",[28,\"t\",[\"buttons.add\"],null],[28,\"route-action\",[\"addCodeStore\",[24,[\"model\"]]],null],[24,[\"codeStore\"]]]]],false],[0,\"\\n\\n     \"],[1,[28,\"buttons/cancel-button\",null,[[\"action\",\"value\"],[[28,\"route-action\",[\"back\"],null],[24,[\"codeStore\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/entity-types/entities/entity/attributes/code-store.hbs"
    }
  });

  _exports.default = _default;
});