define("iris/components/beta/cmdb/modals/move-entities/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    flashMessages: Ember.inject.service(),
    data: null,
    // @, { entities: object }
    close: null,
    // @, function
    entities: Ember.computed.alias('data.entities'),
    // Properties that'll end up on the entities
    contact: null,
    assignedContact: null,
    status: null,
    statusReason: null,
    siteZone: null,
    // Properties that help narrowing down the options for `contact` &
    // `assignedContact`
    partner: null,
    department: null,
    assignedPartner: null,
    assignedDepartment: null,
    actions: {
      async save() {
        if (this.contact && this.assignedContact) {
          await Promise.all(this.entities.map(entity => {
            entity.set('contact', this.contact);
            entity.set('assignedContact', this.assignedContact);

            if (this.status) {
              entity.set('status', this.status);
              entity.set('statusReason', this.statusReason);
            }

            if (this.siteZone) {
              entity.set('siteZone', this.siteZone);
            }

            return entity.save();
          }));
          this.flashMessages.add({
            message: `A(z) ${this.entities.length} elem áthelyezése sikeres.`,
            title: 'IRIS message',
            icon: 'warning'
          });
          this.close({
            shouldRefreshRecords: true
          });
        } else {
          this.flashMessages.add({
            message: 'A tulajdonos és használó munkatárs kiválaszása kötelező!',
            title: 'IRIS message',
            icon: 'warning'
          });
        }
      },

      setPartner(partner) {
        this.set('partner', partner);
        this.set('department', null);
        this.set('contact', null);
        this.set('assignedPartner', partner);
        this.set('assignedDepartment', null);
        this.set('assignedContact', null);
        this.set('siteZone', null);
      },

      setDepartment(department) {
        this.set('department', department);
        this.set('contact', null);
        this.set('assignedDepartment', department);
        this.set('assignedContact', null);
        this.set('siteZone', null);
      },

      setContact(contact) {
        this.set('contact', contact);
        this.set('assignedContact', contact); // Reset siteZone only if its partner is different than the selected
        // contact's partner

        if (this.siteZone && this.siteZone.belongsTo('partner').id() !== contact.belongsTo('partner').id()) {
          this.set('siteZone', null);
        }
      },

      setAssignedPartner(partner) {
        this.set('assignedPartner', partner);
        this.set('assignedDepartment', null);
        this.set('assignedContact', null);
      },

      setAssignedDepartment(department) {
        this.set('assignedDepartment', department);
        this.set('assignedContact', null);
      }

    }
  });

  _exports.default = _default;
});