define("iris/components/tables/settings-cmdb-entity-types-attributes", ["exports", "pro-slugs"], function (_exports, _proSlugs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let _class = (_dec = Ember.inject.service, _dec2 = Ember.computed(), _dec3 = Ember.computed(), _dec4 = Ember.computed('entityTypes.[]'), _dec5 = Ember.computed('model.type', 'attributeTypes'), _dec6 = Ember._action, (_class2 = class _class2 extends Ember.Component {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _defineProperty(this, "mask", '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$');
    }

    get attributeTypes() {
      return [{
        id: 'string',
        name: this.intl.t('entity-attribute.types.string')
      }, {
        id: 'textarea',
        name: this.intl.t('entity-attribute.types.text')
      }, {
        id: 'number',
        name: this.intl.t('entity-attribute.types.number')
      }, {
        id: 'datetime',
        name: this.intl.t('entity-attribute.types.date')
      }, {
        id: 'belongsTo',
        name: this.intl.t('entity-attribute.types.belongsTo')
      }, {
        id: 'hasMany',
        name: this.intl.t('entity-attribute.types.hasMany')
      }, {
        id: 'checkbox',
        name: this.intl.t('entity-attribute.types.checkbox')
      }, {
        id: 'array',
        name: this.intl.t('entity-attribute.types.array')
      }];
    }

    get entityTypes() {
      return this.store.query('entityType', {
        filter: {
          where: {
            template: 0
          },
          order: 'longName'
        }
      });
    }

    get types() {
      let entityTypeOptions = Ember.A();
      let codeStores = this.entityTypes.filter(et => {
        return et.get('codeStore') === 1;
      }).sortBy('longName');
      let normalEntityType = this.entityTypes.filter(et => {
        return et.get('codeStore') === 0;
      }).sortBy('longName');
      entityTypeOptions.pushObject({
        groupName: this.intl.t('code-store.models'),
        options: codeStores
      });
      entityTypeOptions.pushObject({
        groupName: this.intl.t('menus.settings-entity-types'),
        options: normalEntityType
      });
      return entityTypeOptions;
    }

    get type() {
      let attributeTypes = this.get('attributeTypes');
      let type = this.get('model.type');
      return attributeTypes.filter(function (element) {
        return element.id === type;
      })[0];
    }

    set type(value) {
      if (value) {
        Ember.run.next(() => {
          if (!this.get('isDestroyed') && !this.get('isDestroying')) {
            // TODO: backtracking-rerender
            this.set('model.type', value.id);
          }
        });
      } else {
        Ember.run.next(() => {
          if (!this.get('isDestroyed') && !this.get('isDestroying')) {
            // TODO: backtracking-rerender
            this.set('model.type', null);
          }
        });
      }

      return value;
    }

    changeName() {
      let model = this.get('model');
      let name = this.get('model.name');
      let property = this.get('model.property');

      if (Ember.isEmpty(property)) {
        model.set('property', (0, _proSlugs.default)(name).replace(RegExp('-', 'g'), '_'));
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "attributeTypes", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "attributeTypes"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "entityTypes", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "entityTypes"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "types", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "types"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "type", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "type"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changeName", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "changeName"), _class2.prototype)), _class2));

  _exports.default = _class;
});