define("iris/components/beta/modals/view-document/component", ["exports", "iris/config/environment", "iris/utils/properties"], function (_exports, _environment, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const isTesting = _environment.default.environment === 'test';
  let ViewDocumentComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('theme-switcher'), _dec3 = (0, _properties.computedTask)('data.document', function* () {
    let doc = this.data.document;
    let name = doc.originalname;
    let mimeType = doc.mimetype;
    let base64;

    try {
      base64 = yield this.attachments.fetchBase64(doc); // Freakin' testing setup can't be forced to not to parse what's coming
      // from Mirage...

      if (isTesting && typeof base64 === 'object') {
        // Use a 1x1 blank GIF
        base64 = 'R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
      }
    } catch (error) {
      console.error(error);
      return {
        name,
        error: `Nem sikerült betölteni: ${name}`
      };
    }

    return {
      name,
      mimeType,
      base64
    };
  }), _dec4 = Ember.computed('document.{base64,error,mimeType}'), _dec5 = Ember._action, (_class = class ViewDocumentComponent extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "attachments", _descriptor, this);

      _initializerDefineProperty(this, "theme", _descriptor2, this);

      _defineProperty(this, "isObjectCover", false);

      _initializerDefineProperty(this, "document", _descriptor3, this);
    }

    get objectData() {
      var _this$document, _this$document2;

      if (!this.document || this.document.error) {
        return null;
      }

      return `data:${(_this$document = this.document) === null || _this$document === void 0 ? void 0 : _this$document.mimeType};base64,${(_this$document2 = this.document) === null || _this$document2 === void 0 ? void 0 : _this$document2.base64}`;
    }

    downloadDocument(base64) {
      this.attachments.download(this.data.document, base64);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attachments", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "document", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "objectData", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "objectData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadDocument", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "downloadDocument"), _class.prototype)), _class));
  _exports.default = ViewDocumentComponent;
});