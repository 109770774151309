define("iris/validators/entity-presence", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    intl: Ember.inject.service(),

    validate(value, options, model, attribute) {
      let category = model.get('entityType.entityTypeCategory.category');
      let generateName = model.get('generateName');
      let name = model.get('name');

      if (category === 'hardware') {
        if (attribute === 'manufacturer') {
          if (Ember.isEmpty(value.get('content'))) {
            return this.intl.t('validators.presence');
          }
        }

        if (attribute === 'type') {
          if (Ember.isEmpty(value.get('content'))) {
            return this.intl.t('validators.presence');
          }
        }

        if (attribute === 'status') {
          if (Ember.isEmpty(value.get('content'))) {
            return this.intl.t('validators.presence');
          }
        }
      } else {
        if (attribute === 'parent' && Ember.isEmpty(name)) {
          if (Ember.isEmpty(value.get('content'))) {
            return this.intl.t('validators.presence');
          }
        }
      }

      if (attribute === 'name' && !generateName) {
        if (Ember.isEmpty(value)) {
          return this.intl.t('validators.presence');
        }
      }

      return true;
    }

  });

  _exports.default = _default;
});