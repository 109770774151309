define("iris/helpers/string-starts-with", ["exports", "ember-string-fns/helpers/string-starts-with"], function (_exports, _stringStartsWith) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _stringStartsWith.default;
    }
  });
  Object.defineProperty(_exports, "stringStartsWith", {
    enumerable: true,
    get: function () {
      return _stringStartsWith.stringStartsWith;
    }
  });
});