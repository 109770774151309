define("iris/components/forms/ticket-user-assoc-button/component", ["exports", "iris/lib/modals"], function (_exports, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modals: Ember.inject.service(),
    actions: {
      async assoc() {
        const record = this.store.peekRecord('ticket', this.row.id);
        await this.modals.open('modals/ticket-user-assoc', {
          model: record
        }, {
          className: _modals.ModalLayoutClassName['XL']
        });
      }

    }
  });

  _exports.default = _default;
});