define("iris/helpers/store-query-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(_ref, _ref2) {
      let [resolvedMessage] = _ref;
      let {
        query
      } = _ref2;

      if (query.isLoading) {
        return this.intl.t('general.loading');
      } else if (query.error) {
        return this.intl.t('general.loading-error');
      } else {
        return resolvedMessage;
      }
    }

  });

  _exports.default = _default;
});