define("iris/components/forms/menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MHWiTThi",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/form/field\",[],[[\"@model\",\"@property\",\"@proportion\",\"@disabled\"],[[23,0,[\"model\"]],\"name\",\"2-8\",true]]],[0,\"\\n\"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\",\"@where\",\"@proportion\"],[[23,0,[\"model\"]],\"competencies\",[28,\"hash\",null,[[\"builtIn\"],[[28,\"hash\",null,[[\"lt\"],[2]]]]]],\"2-8\"]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/forms/menu/template.hbs"
    }
  });

  _exports.default = _default;
});