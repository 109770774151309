define("iris/components/form/fields/tax-number/modal/component", ["exports", "ember-concurrency", "iris/utils/ember-concurrency", "iris/lib/modals"], function (_exports, _emberConcurrency, _emberConcurrency2, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TaxNumberModal = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.mapBy('billingAddresses', 'country'), _dec5 = Ember.computed('taxNumberPatterns.@each.country.id', 'partnerCountry.id'), _dec6 = Ember.computed('billingAddressCountries.[]'), _dec7 = Ember.computed('taxNumberPattern.pattern', 'taxNumber'), _dec8 = Ember.computed('taxNumbersFound', 'patternValid', 'taxNumberPattern'), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class TaxNumberModal extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "address", null);

      _defineProperty(this, "taxNumber", '');

      _defineProperty(this, "taxNumbersFound", false);

      _defineProperty(this, "billingAddresses", []);

      _defineProperty(this, "mode", 'input');

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "modals", _descriptor3, this);

      _initializerDefineProperty(this, "billingAddressCountries", _descriptor4, this);
    }

    didReceiveAttrs() {
      this.setPartnerBillingAddresses.perform();
      this.loadTaxNumberPatters.perform();
    }

    *loadTaxNumberPatters() {
      const patterns = yield this.api.query('taxNumberPattern', {
        include: 'country'
      });
      this.set('taxNumberPatterns', patterns);
    }

    *setPartnerBillingAddresses() {
      const billingAddresses = yield this.api.query('address', {
        filter: {
          where: {
            partnerId: this.data.partner.id,
            addressType: 1
          }
        },
        include: ['country']
      });
      this.set('billingAddresses', billingAddresses);
    }

    *checkTaxNumberTask(taxNumber) {
      yield (0, _emberConcurrency2.timeout)(500);
      const res = yield this.api.collectionAction('partner', 'check-tax-number', {
        method: 'POST',
        data: {
          taxNumber
        }
      });
      this.set('taxNumbersFound', res);
    }

    *saveAddressTask() {
      const res = yield this.address.save();

      if (res === false) {
        return;
      }

      yield this.setPartnerBillingAddresses.perform();
      this.flashMessages.info(this.intl.t('general.save.success'), {
        title: this.intl.t('general.save.success-title'),
        icon: 'floppy-o'
      });
      this.switchToInputMode();
    }

    get taxNumberPattern() {
      const {
        taxNumberPatterns,
        partnerCountry
      } = this;

      if (!taxNumberPatterns || taxNumberPatterns.length === 0 || !partnerCountry) {
        return null;
      }

      return taxNumberPatterns.find(taxNumberPattern => taxNumberPattern.belongsTo('country').id() === partnerCountry.get('id'));
    }
    /**
     * Computes the country of the partners based on their billing addresses
     * If there is no address (country) provided, or they are ambigous it returns null
     *
     * @readonly
     * @memberof IssueReportModal
     */


    get partnerCountry() {
      const {
        billingAddressCountries
      } = this;
      const allSame = billingAddressCountries.map(c => c.get('id')).every((val, i, arr) => val === arr[0]);
      return billingAddressCountries.length === 0 || allSame === false ? null : billingAddressCountries[0];
    }

    get patternValid() {
      const {
        taxNumber,
        taxNumberPattern
      } = this;

      if (!taxNumber || !taxNumberPattern) {
        return false;
      }

      const regexp = new RegExp(taxNumberPattern.get('pattern'));
      return regexp.test(taxNumber);
    }

    get taxNumberIsValid() {
      const {
        taxNumbersFound,
        patternValid,
        taxNumberPattern
      } = this;
      return taxNumberPattern ? patternValid && taxNumbersFound === 0 : taxNumbersFound === 0;
    }

    checkTaxNumber(unmasked, masked) {
      const taxNumber = masked || unmasked;
      this.checkTaxNumberTask.perform(taxNumber);
      this.set('taxNumber', taxNumber);
    }

    async showSupportedPatters() {
      const {
        intl,
        modals,
        taxNumberPatterns
      } = this;
      await modals.open('form/fields/tax-number/modal/supported-patterns', {
        title: intl.t('tax-number-component.supported-tax-numbers'),
        info: intl.t('tax-number-component.supported-tax-numbers-info'),
        taxNumberPatterns
      }, {
        className: _modals.ModalLayoutClassName.L
      });
    }

    switchToAddressMode() {
      const address = this.store.createRecord('address', {
        clientPartner: this.data.partner
      });
      this.set('address', address);
      this.set('mode', 'addressMode');
    }

    switchToInputMode() {
      // clean up saved address
      if (this.address.get('isNew')) {
        this.address.destroyRecord();
      }

      this.set('address', null);
      this.set('mode', 'input');
    }

    async saveAddress() {
      await this.saveAddressTask.perform();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadTaxNumberPatters", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadTaxNumberPatters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setPartnerBillingAddresses", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "setPartnerBillingAddresses"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkTaxNumberTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "checkTaxNumberTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveAddressTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "saveAddressTask"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "billingAddressCountries", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "taxNumberPattern", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "taxNumberPattern"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "partnerCountry", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "partnerCountry"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "patternValid", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "patternValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "taxNumberIsValid", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "taxNumberIsValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkTaxNumber", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "checkTaxNumber"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showSupportedPatters", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "showSupportedPatters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchToAddressMode", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "switchToAddressMode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchToInputMode", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "switchToInputMode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveAddress", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "saveAddress"), _class.prototype)), _class));
  _exports.default = TaxNumberModal;
});