define("iris/components/forms/ticket-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super();

      this.set('selectedRecords', Ember.A());
      this.set('allRecordSelected', false);
      this.set('generalPlannedExpensesInHour', 0);
    },

    async willDestroyElement() {
      const ticketUserAssocs = await this.get('ticket.ticketUserAssocs');
      let toDelete = ticketUserAssocs.filter(tua => {
        return !tua.get('id') && tua.get('plannedExpensesInHour') === 0;
      });
      toDelete.invoke('deleteRecord');

      this._super(...arguments);
    },

    validPannedExpensesInHour: Ember.computed('generalPlannedExpensesInHour', 'ticket.availableTimeToAssign', function () {
      const hours = this.get('generalPlannedExpensesInHour');
      const available = this.get('ticket.availableTimeToAssign');

      if (hours <= 0 || hours % 0.25 !== 0 || hours > available) {
        return true;
      } else {
        false;
      }
    }),
    actions: {
      selectAll(selected) {
        if (selected) {
          let records = Ember.A();
          this.get('ticket.ticketUserAssocs').forEach(record => {
            records.pushObject(record);
          });
          this.set('selectedRecords', records);
        } else {
          this.get('selectedRecords').clear();
        }
      },

      changeStartDate(date) {
        this.get('selectedRecords').forEach(record => {
          Ember.set(record, 'startDate', date.toDate());
        });
      },

      changeEndDate(date) {
        this.get('selectedRecords').forEach(record => {
          Ember.set(record, 'deadline', date.toDate());
        });
      },

      divide() {
        let value = this.get('generalPlannedExpensesInHour');
        const selected = this.get('selectedRecords');
        const step = 0.25;

        if (selected.length === 0) {
          return;
        }

        while (value > 0) {
          for (let sel of selected) {
            value -= step;
            sel.incrementProperty('plannedExpensesInHour', step);

            if (value === 0) {
              break;
            }
          }
        }

        this.set('generalPlannedExpensesInHour', 0);
      }

    }
  });

  _exports.default = _default;
});