define("iris/helpers/get-at-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getAtIndex = getAtIndex;

  function getAtIndex(params
  /*, hash*/
  ) {
    return params;
  }

  var _default = Ember.Helper.extend({
    recomputeOnArrayChange: Ember.observer('_array.[]', function () {
      this.recompute();
    }),
    compute: function (_ref) {
      let [array, index] = _ref;
      this.set('_array', array);
      return array.objectAt(index);
    }
  });

  _exports.default = _default;
});