define("iris/components/aiops/ticket-chart/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    themeSwitcher: Ember.inject.service(),
    classNames: ["no-gutter"],
    windowWidth: (0, _jquery.default)(window).width(),
    labels: ["Megfeleő", "Téves predikció", "Bizonytalan"],
    computeData: Ember.computed('model', function () {
      let danger = 0;
      let warning = 0;

      if (this.model.belongsTo('ticketType').id() != this.model.belongsTo('plannedTicketType').id()) {
        danger++;
      } else if (this.model.get('ticketTypeCp') < 50) {
        warning++;
      }

      if (this.model.belongsTo('resolver').id() != this.model.belongsTo('plannedResolver').id()) {
        danger++;
      } else if (this.model.get('resolverCp') < 50) {
        warning++;
      }

      if (this.model.belongsTo('userGrade').id() != this.model.belongsTo('plannedUserGrade').id()) {
        danger++;
      } else if (this.model.get('userGradeCp') < 50) {
        warning++;
      }

      if (this.model.belongsTo('operationServiceLevel').id() != this.model.belongsTo('plannedOperationServiceLevel').id()) {
        danger++;
      } else if (this.model.get('operationServiceLevelCp') < 50) {
        warning++;
      }

      if (this.model.belongsTo('serviceLevel').id() != this.model.belongsTo('plannedServiceLevel').id()) {
        danger++;
      } else if (this.model.get('serviceLevelCp') < 50) {
        warning++;
      }

      if (this.model['priority'] != this.model['plannedPriority']) {
        danger++;
      } else if (this.model.get('priorityCp') < 50) {
        warning++;
      }

      if (this.model['publicSpentTime'] != this.model['plannedPublicSpentTime']) {
        danger++;
      } else if (this.model.get('publicSpentTimeCp') < 50) {
        warning++;
      }

      return [danger, warning];
    }),
    datasets: Ember.computed("computeData", "dangerColor", "warningColor", "normalColor", function () {
      const computeData = this.computeData;
      const warning = computeData[1];
      const danger = computeData[0];
      return [{
        label: "",
        data: [100 - Number(danger * 14) - Number(warning * 14), Number(danger * 14), Number(warning * 14)],
        backgroundColor: [this.normalColor, this.dangerColor, this.warningColor],
        borderWidth: [0, 0, 0]
      }];
    }),
    data: Ember.computed("datasets", "labels", function () {
      let datasets = this.get("datasets");
      let labels = this.get("labels");
      return {
        labels,
        datasets
      };
    }),
    options: Ember.computed("themeSwitcher.dark", "computeData", function () {
      const fontColor = this.get("themeSwitcher.dark") ? "#FFF" : "#485761";
      const computeData = this.computeData;
      const warning = computeData[1];
      const danger = computeData[0];
      const value = 100 - Number(danger * 14) - Number(warning * 14);
      return {
        cutoutPercentage: 75,
        legend: {
          display: false
        },
        elements: {
          center: {
            // the longest text that could appear in the center
            maxText: '100%',
            text: `${value}%`,
            fontColor: fontColor,
            fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            fontStyle: 'normal',
            // fontSize: 12,
            // if a fontSize is NOT specified, we will scale (within the below limits) maxText to take up the maximum space in the center
            // if these are not specified either, we default to 1 and 256
            minFontSize: 1,
            maxFontSize: 256
          }
        }
      };
    })
  });

  _exports.default = _default;
});