define("iris/helpers/week-days-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.weekDaysColor = weekDaysColor;

  function weekDaysColor(params
  /*, hash*/
  ) {
    let weekDay = parseInt(params[0]);
    let value = '';
    let mod = weekDay % 4;

    switch (mod) {
      case 0:
        value = 'success';
        break;

      case 1:
        value = 'danger';
        break;

      case 2:
        value = 'info';
        break;

      case 3:
        value = 'warning';
    }

    return value;
  }

  var _default = Ember.Helper.helper(weekDaysColor);

  _exports.default = _default;
});