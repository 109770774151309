define("iris/templates/dashboard/ticket/work-logs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8uP/zXqa",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-tether\",null,[[\"to\",\"target\",\"targetModifier\",\"attachment\",\"on-overlay-click\",\"tetherClass\",\"overlayClass\"],[\"modal\",\"document.body\",\"visible\",\"middle center\",[28,\"route-action\",[\"back\",2],null],\"modal-dialog modal-md\",\"modal-backdrop\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n\\n    \"],[1,[28,\"modal-header\",null,[[\"title\",\"back\"],[[28,\"t\",[\"projects.files.incident-worksheets\"],null],2]]],false],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"panel panel-default\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"panel-heading\"],[8],[1,[28,\"t\",[\"product.info\"],null],false],[0,\": \"],[1,[24,[\"model\",\"file\",\"primeContractor\",\"name\"]],false],[0,\" / \"],[1,[24,[\"model\",\"file\",\"name\"]],false],[0,\" / \"],[1,[24,[\"model\",\"subject\"]],false],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"panel-body panel-body-modal\"],[8],[0,\"\\n          \"],[1,[28,\"tables/tasks-work-logs\",null,[[\"workLogs\",\"parentRoute\",\"delegated\",\"ticketState\"],[[24,[\"model\",\"workLogs\"]],[24,[\"parentRoute\"]],[24,[\"delegated\"]],[24,[\"model\",\"state\"]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n      \"],[1,[28,\"buttons/cancel-button\",null,[[\"action\",\"text\"],[[28,\"route-action\",[\"back\",2],null],[28,\"t\",[\"general.close\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"liquid-outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/dashboard/ticket/work-logs.hbs"
    }
  });

  _exports.default = _default;
});