define("iris/controllers/human-resource-management/event-approvement/edit", ["exports", "iris/utils/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);
    },

    eventApprovementController: Ember.inject.controller('human-resource-management/event-approvement'),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    //modal related settings
    endDate: Ember.computed('model.id', function () {
      let sortedEventDays = this.model.eventDays.sortBy('date');
      return (0, _moment.default)(sortedEventDays.lastObject.date);
    }),
    startDate: Ember.computed('model.id', function () {
      let sortedEventDays = this.model.eventDays.sortBy('date');
      return (0, _moment.default)(sortedEventDays.firstObject.date);
    }),
    types: Ember.computed(function () {
      return this.get('store').query('eventType', {
        filter: {
          order: 'name'
        }
      });
    }),
    minDate: (0, _moment.default)().add(1, 'd'),
    endDateMinDate: Ember.computed('model.start', function () {
      return this.get('model.start') ? this.get('model.start') : this.get('minDate');
    }),
    leaderIds: Ember.computed('sessionAccount.account.id', 'sessionAccount.substitute.id', function () {
      const {
        account
      } = this.sessionAccount;
      const substituteForIds = this.get('sessionAccount.substituteForIds');
      const leaderIds = Array.isArray(substituteForIds) ? [account.id].concat(substituteForIds) : [account.id];
      return leaderIds;
    }),
    disabledAccept: Ember.computed('leaderIds', 'model.user.leaderId', function () {
      return !this.leaderIds.includes(this.model.user.get('leaderId'));
    }),
    eventIsAccepted: Ember.computed('model', function () {
      return this.get('model.isAccepted') === true ? true : this.get('model.isAccepted') === false ? false : null;
    }),
    reasonDisabled: Ember.computed.alias('disabledAccept'),
    actions: {
      async saveEvent() {
        let {
          validations
        } = await (0, _model.validateRecord)(this.model);
        this.model.setValidated();

        if (!validations.isValid) {
          return;
        }

        let event = this.model.serialize();
        event.data.id = this.model.id;
        event.data.attributes.days = this.model.eventDays.map(_ref => {
          let {
            date
          } = _ref;
          return date;
        });
        const alreadyAcceptedDays = (await this.store.query('event', {
          filter: {
            where: {
              and: [{
                isAccepted: true
              }, {
                userId: this.model.user.get('id')
              }]
            },
            include: ['eventDays']
          }
        })).toArray().flatMap(event => event.eventDays.map(eventDay => eventDay.date));

        for (const newEventDay of event.data.attributes.days) {
          for (const alreadyAcceptedDay of alreadyAcceptedDays) {
            if ((0, _moment.default)(alreadyAcceptedDay).isSame(newEventDay)) {
              this.flashMessages.warning(this.intl.t('hrm.already-accepted-holiday'), {
                title: this.intl.t('general.warning'),
                icon: 'warning',
                sticky: true
              });
              return;
            }
          }
        }

        try {
          await this.ajax.call('POST', 'event', null, 'saveEvent', {
            event: event
          });
        } catch (error) {
          const flashMessages = Ember.get(this, 'flashMessages');
          let errorString = error.errors.map(_ref2 => {
            let {
              detail
            } = _ref2;
            return detail;
          }).join('\n');
          flashMessages.danger(errorString, {
            title: 'Hiba',
            icon: 'times',
            sticky: true
          });
          this.model.rollbackAttributes();
        } finally {
          await this.router.transitionTo('human-resource-management.event-approvement');
          await this.get('eventApprovementController').refreshEvents();
        }
      },

      openDeleteEventModal() {
        this.set('deleteEventModal', true);
      },

      deleteEvent() {
        const flashMessages = Ember.get(this, 'flashMessages');
        this.get('model').destroyRecord(true).then(() => {
          this.set('deleteEventModal', false);
          this.send('back');
          flashMessages.success(this.intl.t('general.delete.success'), {
            title: this.intl.t('hr.event'),
            icon: 'check',
            sticky: true
          });
        });
      },

      closeDeleteEventModal() {
        this.set('deleteEventModal', false);
      },

      toggleIsAccepted(isAccepted) {
        let event = this.get('model');

        if (event.get('isAccepted') === isAccepted) {
          event.set('isAccepted', null);
        } else {
          event.set('isAccepted', isAccepted);
        }
      }

    }
  });

  _exports.default = _default;
});