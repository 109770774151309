define("iris/templates/organizations/notification-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2vkkM6I/",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\"],[\"notification-group\",\"organizations.notification-groups.edit\",[28,\"array\",[\"name\",\"partner\",\"notificationGroupType\",\"inside_the_zone\"],null]]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/organizations/notification-groups.hbs"
    }
  });

  _exports.default = _default;
});