define("iris/templates/cmdb/entity-types/entities/entity/xwiki", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AxpZ4+l5",
    "block": "{\"symbols\":[],\"statements\":[[5,\"xwiki-component\",[],[[\"@tags\"],[[28,\"array\",[[23,0,[\"model\",\"partner\",\"shortName\"]],[23,0,[\"model\",\"name\"]],[23,0,[\"model\",\"entityType\",\"name\"]]],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/entity-types/entities/entity/xwiki.hbs"
    }
  });

  _exports.default = _default;
});