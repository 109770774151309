define("iris/controllers/settings/integrations/services/index", ["exports", "iris/services/query-params"], function (_exports, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const integrationsQueryParams = (0, _queryParams.makeFilterQueryParams)();
  const queryParamsMixin = (0, _queryParams.makeControllerMixin)(integrationsQueryParams);

  var _default = Ember.Controller.extend(queryParamsMixin);

  _exports.default = _default;
});