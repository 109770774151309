define("iris/models/aiops-interface-type", ["exports", "iris/models/aiops-base", "ember-data/attr", "ember-cp-validations"], function (_exports, _aiopsBase, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({});

  var _default = _aiopsBase.default.extend(Validations, {
    name: (0, _attr.default)('string')
  });

  _exports.default = _default;
});