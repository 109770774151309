define("iris/models/translation", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    property: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _model.default.extend(Validations, {
    property: (0, _attr.default)('string'),
    translationValue: (0, _attr.default)('string'),
    language: (0, _relationships.belongsTo)('language'),
    sourceIdSave: (0, _attr.default)('string'),
    sourceType: (0, _attr.default)('string')
  });

  _exports.default = _default;
});