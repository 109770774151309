define("iris/components/change-requests/edit/tasks/affected-entities/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    model: null,
    // @
    onClickAddEntity: null //@

  });

  _exports.default = _default;
});