define("iris/models/my-ticket", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    ticketNumber: (0, _attr.default)('string'),
    priority: (0, _attr.default)('number'),
    clientPartner: (0, _attr.default)('string'),
    primeContractor: (0, _attr.default)('string'),
    fileName: (0, _attr.default)('string'),
    contact: (0, _attr.default)('string'),
    subject: (0, _attr.default)('string'),
    productResult: (0, _attr.default)('string'),
    operationServiceLevel: (0, _attr.default)('string'),
    solver: (0, _attr.default)('string'),
    manager: (0, _attr.default)('string'),
    plannedExpensesInHour: (0, _attr.default)('number'),
    reactionDeadline: (0, _attr.default)('date'),
    solveDeadline: (0, _attr.default)('date'),
    startDeadline: (0, _attr.default)('date'),
    violationReaction: (0, _attr.default)('date'),
    violationStart: (0, _attr.default)('date'),
    violationSolve: (0, _attr.default)('date'),
    plannedStartDate: (0, _attr.default)('date'),
    plannedEndDate: (0, _attr.default)('date'),
    alreadyAssignedTime: (0, _attr.default)('number'),
    isOperational: (0, _attr.default)('boolean'),
    serviceLevel: (0, _relationships.belongsTo)('serviceLevel'),
    type: (0, _relationships.belongsTo)('ticketType'),
    state: (0, _relationships.belongsTo)('ticketState')
  });

  _exports.default = _default;
});