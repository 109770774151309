define("iris/components/iris-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    disableButton: Ember.inject.service(),
    defaultText: null,
    loadingText: null,
    disabled: false,
    buttonType: 'button',
    active: false,
    block: false,
    toggle: false,
    iconActive: null,
    iconInactive: null,
    value: null,
    textState: 'default',
    reset: null,
    _disabled: Ember.computed.or('disableButton.disabled', 'disabled'),
    _iconActive: Ember.computed.or('icon', 'iconActive'),
    _iconInactive: Ember.computed.or('icon', 'iconInactive'),
    actions: {
      click(_event) {
        if (this.toggle) {
          this.toggleProperty('active');
        } // Passing `event` breaks the app
        // eslint-disable-next-line ember/closure-actions


        this.sendAction('action', this.value);
      }

    }
  });

  _exports.default = _default;
});