define("iris/components/navigation-side-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['nav', 'nav-children'],
    actions: {
      toggleNav: function (item) {
        if (Ember.get(item, 'expanded')) {
          Ember.set(item, 'expanded', false);
        } else {
          Ember.set(item, 'expanded', true);
        }
      },
      activateNav: function (item) {
        if (Ember.get(item, 'activated')) {
          Ember.set(item, 'activated', false);
        } else {
          Ember.set(item, 'activated', true);
        }
      }
    }
  });

  _exports.default = _default;
});