define("iris/components/forms/duty/day/shift-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m0+lYE61",
    "block": "{\"symbols\":[\"shiftChange\",\"@close\",\"@data\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-content mx-auto\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[0,\"\\n      \"],[1,[23,3,[\"title\"]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"i\",true],[8],[1,[23,3,[\"subTitle\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex gap-6\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"shiftBeginTime\",[23,3,[\"unUsedShiftChanges\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",false],[12,\"class\",\"border-2 rounded font-bold p-3 text-2xl cursor-pointer btn-primary\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,2,[]],[23,1,[]]],null]]],[8],[0,\"\\n          \"],[7,\"i\",true],[10,\"class\",\"fa fa-clock-o\"],[8],[9],[0,\"\\n          \"],[1,[28,\"moment-time\",[[23,1,[\"shiftBeginTime\"]]],null],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"default\",[28,\"fn\",[[23,2,[]],false],null],[28,\"t\",[\"buttons.close\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/forms/duty/day/shift-picker/template.hbs"
    }
  });

  _exports.default = _default;
});