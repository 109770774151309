define("iris/routes/finances/incoming-invoices/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model(params) {
      const store = this.get('store');
      const result = await store.query('incomingInvoice', {
        filter: {
          where: {
            id: params.invoice_id
          },
          include: ['partner', 'currency', 'paymentMethod', 'invoiceOwner', 'items']
        }
      });
      const invoice = result.get('firstObject');
      return Ember.RSVP.hash({
        invoice,
        invoiceTypes: store.findAll('invoiceType'),
        vats: store.findAll('vat'),
        uoms: store.findAll('uom')
      });
    }

  });

  _exports.default = _default;
});