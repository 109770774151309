define("iris/components/forms/address-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    actions: {
      searchByZipCode(model) {
        let country = model.get('country');
        let countryCode = typeof country !== 'undefined' && typeof country.get('code') !== 'undefined' ? country.get('code').substr(0, 2) : '';
        let zipCode = model.get('zipCode');

        if (typeof countryCode !== 'undefined') {
          if (countryCode.length === 2 && zipCode.length > 3) {
            var owner = Ember.getOwner(this);
            var adapter = owner.lookup('adapter:application');
            let accessToken = this.get('session.data.authenticated.id');
            var url = adapter.buildURL();
            $.getJSON(url + '/GModels/geocode?country=' + countryCode + '&zipcode=' + zipCode, {
              access_token: accessToken
            }).then(function (data) {
              if (typeof data !== 'undefined' && data.status === 'OK' && typeof data.cityName !== 'undefined') {
                model.set('town', data.cityName);
              } else {
                model.set('town', '');
              }
            }).catch(function (data) {
              console.error('Google API error response: ', data);
            });
          }
        }
      },

      async changeCountry(country) {
        const {
          flashMessages,
          intl,
          model,
          store
        } = this; // billing address

        if (model.get('addressType') === 1) {
          const partner = await model.get('clientPartner');
          const taxNumber = partner.get('tax');
          const taxNumberPatterns = await store.query('taxNumberPattern', {
            filter: {
              where: {
                countryId: country.id
              }
            }
          });

          if (taxNumberPatterns.length) {
            // should be only one
            const taxNumberPattern = taxNumberPatterns.toArray()[0];
            const regex = new RegExp(taxNumberPattern.pattern);

            if (regex.test(taxNumber) === false) {
              flashMessages.warning(intl.t('tax-number-component.new-billing-address-country', {
                regex,
                taxNumber,
                country: country.get('name')
              }), {
                title: intl.t('general.warning'),
                icon: 'warning',
                sticky: true
              });
            }
          }
        }

        model.set('country', country);
      }

    }
  });

  _exports.default = _default;
});