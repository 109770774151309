define("iris/routes/finances/incoming-invoices", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    loading: Ember.inject.service(),
    actualFetchData: Ember.inject.service(),
    uploadDocuments: Ember.inject.service(),
    selectedRecords: Ember.A(),

    model(params) {
      let store = this.store;
      return Ember.RSVP.hash({
        suppliers: store.query('partner', {
          filter: {
            where: {
              or: [{
                supplier: 1
              }, {
                subcontractor: 1
              }]
            },
            order: 'name'
          }
        })
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('exportOptions', this.get('exportOptions'));
    },

    actions: {
      createInvoice() {
        this.transitionTo('finances.incoming-invoices.new');
      },

      payments() {
        this.transitionTo('finances.incoming-invoices.invoice-payments', this.get('selectedRecords'));
      },

      invoiceSelected(selectedRecords) {
        this.set('selectedRecords', selectedRecords);
      },

      forwardToAccounting() {
        this.transitionTo('finances.incoming-invoices.forward-to-accounting', this.get('selectedRecords'));
      },

      async saveIncomingInvoice(invoice) {
        const result = await this.summarize(invoice);

        if (result === false) {
          return;
        }

        const {
          allValid,
          allNet,
          allVat
        } = result;

        if (allValid === false) {
          return;
        }

        invoice.set('netto', allNet);
        invoice.set('vat', allVat);

        try {
          await invoice.save();
        } catch (e) {
          console.error(e);
        }

        const items = await invoice.get('items');
        const promises = items.invoke('save');
        Ember.RSVP.all(promises).then(res => {
          const route = 'finances.incoming-invoices';
          this.transitionTo(route);
          const contoller = this.controllerFor(route);
          contoller.storage.refresh();
        });

        if (invoice.id) {
          this.get('uploadDocuments').uploadPendingFiles(invoice);
        }
      }

    }
  });

  _exports.default = _default;
});