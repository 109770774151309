define("iris/components/dashboard/news-feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['timeline', 'timeline-simple', 'mt-xlg', 'mb-md']
  });

  _exports.default = _default;
});