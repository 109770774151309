define("iris/templates/forbidden", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Kwu8/tu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"body-error error-inside\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"center-error\"],[8],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"col-md-8\"],[8],[0,\"\\n              \"],[7,\"div\",true],[10,\"class\",\"main-error mb-xlg\"],[8],[0,\"\\n                  \"],[7,\"h2\",true],[10,\"class\",\"h2 error-code text-dark text-center text-weight-semibold m-none\"],[8],[1,[28,\"t\",[\"operation.main.number\"],[[\"number\"],[403]]],false],[0,\" \"],[7,\"i\",true],[10,\"class\",\"fa fa-file\"],[8],[9],[9],[0,\"\\n                  \"],[7,\"p\",true],[10,\"class\",\"error-explanation text-center\"],[8],[1,[28,\"t\",[\"templates.main.path-not-find\"],null],false],[9],[0,\"\\n              \"],[9],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"col-md-4\"],[8],[0,\"\\n              \"],[7,\"h4\",true],[10,\"class\",\"h4 text\"],[8],[1,[28,\"t\",[\"unauth.navigation\"],null],false],[9],[0,\"\\n              \"],[7,\"ul\",true],[10,\"class\",\"nav nav-list primary\"],[8],[0,\"\\n                  \"],[7,\"li\",true],[8],[0,\"\\n                      \"],[7,\"a\",true],[10,\"href\",\"/login\"],[8],[7,\"i\",true],[10,\"class\",\"fa fa-caret-right text-dark\"],[8],[9],[0,\" \"],[1,[28,\"t\",[\"unauth.login\"],null],false],[9],[0,\"\\n                  \"],[9],[0,\"\\n                  \"],[7,\"li\",true],[8],[0,\"\\n                      \"],[7,\"a\",true],[10,\"href\",\"/unauth/contact-registration\"],[8],[7,\"i\",true],[10,\"class\",\"fa fa-caret-right text-dark\"],[8],[9],[0,\" \"],[1,[28,\"t\",[\"unauth.registration\"],null],false],[9],[0,\"\\n                  \"],[9],[0,\"\\n              \"],[9],[0,\"\\n          \"],[9],[0,\"\\n      \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/forbidden.hbs"
    }
  });

  _exports.default = _default;
});