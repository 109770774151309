define("iris/templates/settings/operations/ticket-audit-list/edit/edit-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NsUNqT0P",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-tether\",null,[[\"to\",\"target\",\"targetModifier\",\"attachment\",\"on-overlay-click\",\"tetherClass\",\"overlayClass\"],[\"modal-dialog\",\"document.body\",\"visible\",\"middle center\",[28,\"route-action\",[\"transitionTo\",\"settings.operations.ticket-audit-list.edit\"],null],\"modal-dialog\",\"modal-backdrop\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n\\n    \"],[1,[28,\"modal-header\",null,[[\"title\"],[[28,\"t\",[\"settings.cmdb.edit-element\"],null]]]],false],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n\"],[4,\"bs-form\",null,[[\"model\",\"proportion\"],[[24,[\"model\"]],\"3-9\"]],{\"statements\":[[4,\"form-group\",null,[[\"property\",\"label\"],[\"description\",[28,\"t\",[\"intervene.name\"],null]]],{\"statements\":[[0,\"          \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"auditItem\",\"description\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n      \"],[1,[28,\"buttons/save-button\",null,[[\"action\",\"value\"],[[28,\"route-action\",[\"save\",[28,\"hash\",null,[[\"redirectTo\"],[\"settings.operations.ticket-audit-list.edit\"]]]],null],[24,[\"model\",\"auditItem\"]]]]],false],[0,\"\\n\\n      \"],[1,[28,\"buttons/cancel-button\",null,[[\"action\",\"value\"],[[28,\"route-action\",[\"transitionTo\"],null],\"settings.operations.ticket-audit-list.edit\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/operations/ticket-audit-list/edit/edit-item.hbs"
    }
  });

  _exports.default = _default;
});