define("iris/templates/settings/integrations/services/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BvBneQoM",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"menus.new-integration\"],null]],null],false],[0,\"\\n\"],[5,\"forms/integration-form\",[],[[\"@model\"],[[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/integrations/services/new.hbs"
    }
  });

  _exports.default = _default;
});