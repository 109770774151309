define("iris/controllers/operation/operational-file/index", ["exports", "iris/services/query-params", "iris/lib/filters/types", "iris/lib/filters/storage"], function (_exports, _queryParams, _types, _storage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const operationalFileQueryParams = (0, _queryParams.makeFilterQueryParams)(null, {
    filters: [{
      name: 'closed',
      type: _types.FilterType.Boolean,
      value: false
    }],
    order: {
      property: 'createDate',
      direction: 'desc'
    }
  });
  const queryParamsMixin = (0, _queryParams.makeControllerMixin)(operationalFileQueryParams);

  var _default = Ember.Controller.extend(queryParamsMixin, {
    storage: (0, _storage.useStorage)('query-params', {
      controllerPath: 'operation.operational-file.index'
    })
  });

  _exports.default = _default;
});