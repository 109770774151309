define("iris/models/file-state", ["exports", "ember-data/model", "ember-data/attr", "iris/utils/enum", "ember-cp-validations"], function (_exports, _model, _attr, _enum, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FILE_STATE_ID = void 0;
  // import { belongsTo, hasMany } from 'ember-data/relationships';
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 100
    })]
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    planning: (0, _attr.default)('boolean'),
    proposal: (0, _attr.default)('boolean'),
    outgoingProposal: (0, _attr.default)('boolean'),
    orderOk: (0, _attr.default)('boolean'),
    contract: (0, _attr.default)('boolean'),
    allocation: (0, _attr.default)('boolean'),
    opportunity: (0, _attr.default)('boolean'),
    observation: (0, _attr.default)('boolean'),
    probabilityMin: (0, _attr.default)('number'),
    probabilityMax: (0, _attr.default)('number')
  });

  _exports.default = _default;
  const FILE_STATE_ID = (0, _enum.makeEnum)([{
    Default: '000'
  }, {
    NewProject: 'SYS_01'
  }, // Akta létrehozva
  {
    Opportunity: 'SYS_02'
  }, // Lehetőség
  {
    UnderAssessment: 'SYS_03'
  }, // Igényfelmérés alatt
  {
    OfferInProgress: 'SYS_04'
  }, // Ajánlat készülőben
  {
    OfferInConsideration: 'SYS_05'
  }, // Gondolkoznak az ajánlaton
  {
    NeedsDiscussion: 'SYS_06'
  }, // Egyeztetésre vár
  {
    WaitingForAcceptance: 'SYS_07'
  }, // Megrendelésre vár
  {
    Accepted: 'SYS_08'
  }, // Megrendelt
  {
    ProjectInProgress: 'SYS_10'
  }, // Végrehajtás alatt
  {
    ProjectDone: 'SYS_11'
  }, // Elkészült, átadás
  {
    Billed: 'SYS_12'
  }, // Számlázva
  {
    Paied: 'SYS_13'
  }, // Kifizetett
  {
    Suspended: 'SYS_14'
  }, // Akta felfüggesztve
  {
    Failed: 'SYS_15'
  } // Elveszett
  ]);
  _exports.FILE_STATE_ID = FILE_STATE_ID;
});