define("iris/components/change-requests/edit/tasks/affected-entities/data/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    model: null,

    // @
    async saveModel() {
      try {
        await this.model.save();
        this.flashMessages.add({
          message: this.intl.t('operation.change-request.changeSuccess'),
          title: 'IRIS message',
          icon: 'info'
        });
      } catch (error) {
        console.error(error);
      }
    },

    async updateAffectedEntities(entitiesArray) {
      let affectedEntities = Ember.A();

      try {
        for (let {
          id
        } of entitiesArray) {
          let result = await this.ajax.call('GET', 'entity', id, 'affected-entities', {
            direction: 'up'
          });

          if (result.result) {
            affectedEntities.pushObjects(result.result);
          }
        }

        this.model.set('affectedEntities', affectedEntities);
      } catch (err) {
        console.error(err);
        err.errors.forEach(error => {
          this.flashMessages.danger(`<b>Code: ${error.code}</b><br>${error.detail && error.detail}`, {
            title: this.intl.t('operation.change-request.errorInRequest'),
            icon: 'times',
            sticky: true
          });
        });
      }
    },

    actions: {
      async addEntity(entityId) {
        let entities = await this.model.get('entities');

        if (!hasEntity(entities.toArray(), entityId)) {
          let entity = await this.store.find('entity', entityId);
          entities.pushObject(entity);
          await this.updateAffectedEntities(entities.toArray());
          await this.saveModel();
        }
      },

      async removeEntity(entityId) {
        let entities = await this.model.get('entities');
        let entitiesArray = entities.toArray();

        if (hasEntity(entitiesArray, entityId)) {
          let newEntities = entities.reject(entity => entity.id === entityId);
          this.set('model.entities', newEntities);
          await this.updateAffectedEntities(newEntities.toArray());
          await this.saveModel();
        }
      }

    }
  });

  _exports.default = _default;

  const hasEntity = (list, id) => list.some(e => e.id === id);
});