define("iris/components/settings/belongs-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    settingValue: Ember.computed.alias('setting.settingValue'),
    selected: Ember.computed('options.[]', 'settingValue', function () {
      let value = this.get('settingValue');

      if (Ember.isNone(value)) {
        return null;
      }

      return this.get('options').findBy('id', value);
    }),
    actions: {
      change(selected) {
        this.set('settingValue', selected.id);
      }

    }
  });

  _exports.default = _default;
});