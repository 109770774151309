define("iris/helpers/string-replace-all", ["exports", "ember-string-fns/helpers/string-replace-all"], function (_exports, _stringReplaceAll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _stringReplaceAll.default;
    }
  });
  Object.defineProperty(_exports, "stringReplaceAll", {
    enumerable: true,
    get: function () {
      return _stringReplaceAll.stringReplaceAll;
    }
  });
});