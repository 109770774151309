define("iris/controllers/settings/users/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    modals: Ember.inject.service(),
    customButtons: Ember.computed('model.id', 'model.enabled', function () {
      let buttons = [];

      if (this.model.id && this.model.enabled) {
        buttons.push({
          icon: 'fa fa-times',
          text: this.intl.t('settings.users.inactivate-user'),
          action: this.get('actions.inactivateUser').bind(this),
          type: 'dark'
        });
      }

      return buttons;
    }),
    errorProperties: {
      main: ['longName', 'email', 'userGrade', 'language', 'password'],
      employment: ['yearlyHolidays', 'employmentStartDate']
    },

    beforeSave(model) {
      console.log('this.beforeSave');
      console.log(model);

      if (model.learnerId && model.repairEngineer === false) {
        model.set('repairEngineer', true);
      }
    },

    actions: {
      async inactivateUser() {
        try {
          let confirm = await this.modals.open('beta/modals/confirmation', {
            title: this.intl.t('settings.users.inactivate-user'),
            body: this.intl.t('settings.users.inactivate-info')
          });

          if (!confirm) {
            return;
          }

          await this.get('ajax').call('POST', 'account', this.model.get('id'), 'inactivateUser');
          this.flashMessages.success(this.intl.t('settings.users.inactivateSuccess'), {
            title: this.intl.t('settings.users.inactivating', {
              name: this.model.get('longName')
            }),
            icon: 'check'
          });
          this.router.transitionTo('settings.users');
        } catch (err) {
          let errorMessages = '';

          if (err.errors) {
            err.errors.forEach(error => {
              errorMessages += error.detail + '.';
            });
          } else {
            errorMessages += err;
          }

          this.flashMessages.danger(errorMessages, {
            title: this.intl.t('settings.users.inactivateFailure'),
            icon: 'warning',
            sticky: true
          });
        }
      },

      async uploadPhoto() {
        const userPhoto = await this.modals.open('modals/photo-crop');

        if (userPhoto) {
          this.model.set('userPhoto', userPhoto);
          const saved = await this.model.save();

          if (saved) {
            this.flashMessages.info(this.intl.t('general.save.success'), {
              title: this.intl.t('general.save.success-title'),
              icon: 'floppy-o'
            });
          }
        }
      }

    }
  });

  _exports.default = _default;
});