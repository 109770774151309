define("iris/models/date-type", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-copyable", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberDataCopyable, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(Validations, _emberDataCopyable.default, {
    changeTracker: {
      trackHasMany: true,
      enableIsDirty: true,
      auto: true
    },
    name: (0, _attr.default)('string'),
    excludes: (0, _relationships.hasMany)('dateTypeDefinition'),
    includes: (0, _relationships.hasMany)('dateTypeDefinition'),
    copyableOptions: {
      copyByReference: ['excludes', 'includes']
    }
  });

  _exports.default = _default;
});