define("iris/controllers/reports/pentaho", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super();

      Ember.run.scheduleOnce('afterRender', this, function () {
        $('#pentaho-iframe').contents().find('#notification-screen').hide();
      });
    }

  });

  _exports.default = _default;
});