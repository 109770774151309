define("iris/controllers/operation/sd-dashboard/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    dashboardController: Ember.inject.controller('operation.sd-dashboard'),
    canSolve: false,
    isSuspendRemoval: false,
    actions: {
      async canSolveApiCall() {
        let allowedSolveStates = await this.get('store').query('ticketStateChangeAllow', {
          filter: {
            where: {
              and: [{
                destinationStateId: 'SYS_07'
              }, {
                isAllowed: true
              }]
            }
          }
        });
        this.set('canSolve', allowedSolveStates.map(statechange => statechange.get('originStateId')).includes(this.model.belongsTo('state').id()));
      }

    }
  });

  _exports.default = _default;
});