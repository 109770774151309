define("iris/models/department", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "iris/mixins/custom-attributes", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _customAttributes, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 150
    })],
    partner: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _model.default.extend(Validations, _customAttributes.default, {
    name: (0, _attr.default)('string'),
    partner: (0, _relationships.belongsTo)('partner'),
    contacts: (0, _relationships.hasMany)('contact')
  });

  _exports.default = _default;
});