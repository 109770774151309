define("iris/components/forms/access-request/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    contacts: Ember.computed('selectedPartner.id', function () {
      const id = this.get('selectedPartner.id');
      return this.get('store').query('contact', {
        filter: {
          where: {
            partnerId: id
          }
        }
      });
    }),
    selectedPartner: Ember.computed.alias('model.file.clientPartner'),
    partners: Ember.computed.mapBy('files', 'clientPartner'),
    filteredFiles: Ember.computed('files', 'selectedPartner', function () {
      let selectedPartner = this.get('selectedPartner');
      let files = this.get('files');

      if (!selectedPartner) {
        return files;
      }

      return files.filter(file => {
        return file.get('clientPartner.id') === selectedPartner.get('id');
      });
    }),
    entityTypes: Ember.computed('model.entityTypeCategories', function () {
      var entityTypeOptions = Ember.A();
      var o = Ember.A();

      if (this.get('entityTypeCategories')) {
        this.get('entityTypeCategories').forEach(function (tc) {
          tc.get('entityTypes').then(entityTypes => {
            o = Ember.A();
            let entityTypesSorted = entityTypes.sortBy('longName');
            entityTypesSorted.forEach(function (t) {
              if (t.get('codeStore') === 0) {
                o.pushObject(t);
              }
            });
            entityTypeOptions.pushObject({
              groupName: tc.get('name'),
              options: o
            });
          });
        });
        Ember.run.next(() => {
          if (!this.get('isDestroyed') && !this.get('isDestroying')) {
            // TODO: backtracking-rerender
            this.set('entityTypeOptions', entityTypeOptions);
          }
        });
      }
    }),
    actions: {
      setPriority() {
        let days = 10;

        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        let [action, value] = args;

        if (action === 'slideStop') {
          if (value === 1) {
            days = 2;
          }

          let deadline = moment().add(days, 'd').toDate();
          this.set('deadline', deadline);
        }
      }

    }
  });

  _exports.default = _default;
});