define("iris/models/engineer-utility-report", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    workLogNumber: (0, _attr.default)('string'),
    primeContractorName: (0, _attr.default)('string'),
    technicianName: (0, _attr.default)('string'),
    ticketTypeName: (0, _attr.default)('string'),
    fileNumber: (0, _attr.default)('string'),
    fileName: (0, _attr.default)('string'),
    fileType: (0, _attr.default)('string'),
    publicSpentTime: (0, _attr.default)('number', {
      dafaultValue: 0
    }),
    spentTime: (0, _attr.default)('number', {
      dafaultValue: 0
    }),
    createDate: (0, _attr.default)('date'),
    lastUpdate: (0, _attr.default)('date'),
    ticketStateName: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    subject: (0, _attr.default)('string'),
    partnerName: (0, _attr.default)('string'),
    priority: (0, _attr.default)('number'),
    deadline: (0, _attr.default)('date')
  });

  _exports.default = _default;
});