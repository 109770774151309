define("iris/components/portal-dashboard/edit/relevant-tickets/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NnlDAhCc",
    "block": "{\"symbols\":[\"ticket\",\"&default\",\"&attrs\"],\"statements\":[[4,\"if\",[[23,0,[\"tickets\"]]],null,{\"statements\":[[0,\"  \"],[14,2],[0,\"\\n\\n  \"],[7,\"table\",false],[12,\"class\",\"table table-striped iris-table\"],[13,3],[8],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"tickets\"]]],null,{\"statements\":[[0,\"        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"td\",true],[10,\"class\",\"align-middle\"],[8],[1,[23,1,[\"type\",\"name\"]],false],[9],[0,\"\\n          \"],[7,\"td\",true],[10,\"class\",\"align-middle\"],[8],[1,[23,1,[\"ticketNumber\"]],false],[9],[0,\"\\n\\n          \"],[7,\"td\",true],[10,\"class\",\"align-middle\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"plannedStartDate\"]]],null,{\"statements\":[[0,\"              \"],[1,[28,\"moment-format\",[[23,1,[\"plannedStartDate\"]],\"L LT\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"              \"],[7,\"span\",true],[10,\"class\",\"mx-4\"],[8],[0,\"-\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n            \"],[1,[28,\"fa-icon\",[\"arrow-right\"],[[\"class\"],[\"mx-2\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"plannedEndDate\"]]],null,{\"statements\":[[0,\"              \"],[1,[28,\"moment-format\",[[23,1,[\"plannedEndDate\"]],\"L LT\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"              \"],[7,\"span\",true],[10,\"class\",\"mx-4\"],[8],[0,\"-\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"          \"],[9],[0,\"\\n\\n          \"],[7,\"td\",true],[10,\"class\",\"align-middle\"],[8],[1,[23,1,[\"priority\"]],false],[9],[0,\"\\n          \"],[7,\"td\",true],[10,\"class\",\"align-middle\"],[8],[1,[23,1,[\"subject\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/portal-dashboard/edit/relevant-tickets/template.hbs"
    }
  });

  _exports.default = _default;
});