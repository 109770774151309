define("iris/components/issue-report-edit/merged-tickets/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L4bBDecp",
    "block": "{\"symbols\":[\"t\",\"@ticket\"],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@neededFields\",\"@readOnly\",\"@isRender\",\"@limit\",\"@defaultFilters\"],[\"ticket\",[28,\"array\",[\"ticketNumber\",\"contact\",\"subject\"],null],true,true,10,[28,\"hash\",null,[[\"mergedParentId\"],[[23,2,[\"id\"]]]]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"ticketNumber\"],null]],null,{\"statements\":[[0,\"    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"operation.sd-dashboard.edit\",[23,1,[\"row\",\"id\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[23,1,[\"row\",\"ticketNumber\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"priority\"],null]],null,{\"statements\":[],\"parameters\":[]},{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/issue-report-edit/merged-tickets/template.hbs"
    }
  });

  _exports.default = _default;
});