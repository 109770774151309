define("iris/models/user-partner-assoc", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    user: (0, _relationships.belongsTo)('account'),
    clientPartner: (0, _relationships.belongsTo)('partner'),
    finance: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    sdRegister: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    sdTechnician: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    files: (0, _attr.default)('number', {
      defaultValue: 0
    })
  });

  _exports.default = _default;
});