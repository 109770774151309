define("iris/services/modals", ["exports", "ember-promise-modals/services/modals"], function (_exports, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modals.default.extend({
    _disableFocusTrap: true
  });

  _exports.default = _default;
});