define("iris/components/modals/import-incoming-invoice/modals/import-modal/partner-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hNlAQjtZ",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"bs-form\",[],[[\"@proportion\"],[\"4-8\"]],{\"statements\":[[0,\"\\n  \"],[5,\"form-group\",[],[[\"@label\"],[[28,\"t\",[\"partner.name\"],null]]],{\"statements\":[[0,\"\\n    \"],[5,\"bs-input\",[],[[\"@value\",\"@disabled\"],[[23,1,[\"name\"]],true]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"form-group\",[],[[\"@label\"],[[28,\"t\",[\"partner.tax\"],null]]],{\"statements\":[[0,\"\\n    \"],[5,\"bs-input\",[],[[\"@value\",\"@disabled\"],[[23,1,[\"tax\"]],true]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/modals/import-incoming-invoice/modals/import-modal/partner-form/template.hbs"
    }
  });

  _exports.default = _default;
});