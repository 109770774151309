define("iris/models/tandm-timeline", ["exports", "@ember-data/model", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({});

  var _default = _model.default.extend(Validations, {
    verb: (0, _model.attr)('string'),
    sourceType: (0, _model.attr)('string'),
    info: (0, _model.attr)('json'),
    createDate: (0, _model.attr)('date'),
    balance: (0, _model.attr)('number'),
    alreadyUsed: (0, _model.attr)('number'),
    alreadyInvoiced: (0, _model.attr)('number'),
    creator: (0, _relationships.belongsTo)('account')
  });

  _exports.default = _default;
});