define("iris/models/push-notification", ["exports", "@ember-data/model", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({});

  var _default = _model.default.extend(Validations, {
    sourceId: (0, _model.attr)('string'),
    sourceType: (0, _model.attr)('string'),
    sourceId: (0, _model.attr)('string'),
    info: (0, _model.attr)('json'),
    createDate: (0, _model.attr)('date'),
    dateAcknowledge: (0, _model.attr)('date'),
    messageId: (0, _model.attr)('string'),
    user: (0, _relationships.belongsTo)('account'),
    userDevice: (0, _relationships.belongsTo)('userDevice')
  });

  _exports.default = _default;
});