define("iris/routes/settings/cmdb/code-stores/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let store = this.store;
      return Ember.RSVP.hash({
        entityType: store.createRecord('entityType', {
          codeStore: 1
        })
      });
    },

    actions: {
      addItem(record) {
        let entityType = this.controller.get('model.entityType');

        let _this = this;

        entityType.get('entities').then(entities => {
          entities.pushObject(record);
        });

        _this.send('transitionTo', 'settings.cmdb.code-stores.new.items');
      },

      removeItem(items) {
        items.invoke('deleteRecord');
        items.clear();
      }

    }
  });

  _exports.default = _default;
});