define("iris/controllers/organizations/contacts/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ContactEditController = (_dec = Ember.computed('model.allowedPartner'), _dec2 = Ember.computed('model.allowedFiles'), _dec3 = Ember._action, _dec4 = Ember._action, (_class = class ContactEditController extends Ember.Controller {
    get allowedFilesWhere() {
      if (!this.model.allowedPartner) {
        return {
          id: null
        };
      }

      return {
        and: [{
          or: [{
            and: [{
              clientPartnerId: this.model.allowedPartner.get('id')
            }, {
              subcontractor: false
            }]
          }, {
            and: [{
              primeContractorId: this.model.allowedPartner.get('id')
            }, {
              subcontractor: true
            }]
          }]
        }, {
          type: 'operational'
        }]
      };
    }

    get allowedOperationServiceLevelsWhere() {
      if (!this.model.allowedFiles) {
        return {
          id: null
        };
      }

      return {
        fileId: {
          inq: this.model.allowedFiles.map(f => f.get('id'))
        }
      };
    }

    async beforeSave(savedFile) {
      if (savedFile.defaultReporter) {
        const where = {
          partnerId: savedFile.belongsTo('partner').id(),
          defaultReporter: true,
          active: 1
        };

        if (savedFile.id) {
          where['id'] = {
            neq: savedFile.id
          };
        }

        const contacts = await this.store.query('Contact', {
          filter: {
            where
          }
        });

        if (contacts.length) {
          const {
            flashMessages,
            intl
          } = this;
          flashMessages.danger(intl.t('contact.messages.defaultReporterCheck.message'), {
            title: intl.t('contact.messages.defaultReporterCheck.title'),
            sticky: true,
            icon: 'warning'
          });
          return false;
        } else {
          return true;
        }
      }
    }

    changeAllowedPartner(allowedPartner) {
      this.set('model.allowedFiles', Ember.A());
      this.set('model.allowedOperationServiceLevels', Ember.A());
      this.set('model.allowedPartner', allowedPartner);
    }

    changeAllowedFiles(allowedFiles) {
      this.set('model.allowedOperationServiceLevels', Ember.A());
      this.set('model.allowedFiles', allowedFiles);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "allowedFilesWhere", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "allowedFilesWhere"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "allowedOperationServiceLevelsWhere", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "allowedOperationServiceLevelsWhere"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeAllowedPartner", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "changeAllowedPartner"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeAllowedFiles", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "changeAllowedFiles"), _class.prototype)), _class));
  _exports.default = ContactEditController;
});