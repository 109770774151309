define("iris/utils/human-resource-management/resource-period-manager/period", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.queryPreSelectedPeriod = _exports.queryEvents = void 0;

  const queryEvents = async (store, currentUserId, substituteForIds) => {
    const userIds = Array.isArray(substituteForIds) ? [currentUserId].concat(substituteForIds) : [currentUserId];
    const rgsas = await store.query('resourceGroupSolverAssoc', {
      filter: {
        where: {
          solverId: {
            inq: userIds
          }
        }
      }
    });
    const rgpas = await store.query('resourceGroupPlannerAssoc', {
      filter: {
        where: {
          plannerId: {
            inq: userIds
          }
        }
      }
    });
    let groupIds = [rgsas.map(rgsa => rgsa.get('resourceGroup.id')), rgpas.map(rgpa => rgpa.get('resourceGroup.id'))].flat();
    const resourceGroups = await store.query('resourceGroup', {
      filter: {
        where: {
          or: [{
            id: {
              inq: groupIds
            }
          }, {
            authorizerId: {
              inq: userIds
            }
          }]
        }
      }
    });
    return await store.query('resourcePeriod', {
      filter: {
        where: {
          and: [{
            resourceGroupId: {
              inq: resourceGroups.map(rg => rg.get('id'))
            }
          }]
        },
        include: ['state', 'resourceGroup']
      }
    });
  };

  _exports.queryEvents = queryEvents;

  const queryPreSelectedPeriod = (store, id) => {
    if (!id) {
      return null;
    }

    return store.find('resourcePeriod', id);
  };

  _exports.queryPreSelectedPeriod = queryPreSelectedPeriod;
});