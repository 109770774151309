define("iris/templates/settings/operations/operation-service-level-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YMb/WlZr",
    "block": "{\"symbols\":[],\"statements\":[[5,\"link-to\",[],[[\"@route\"],[\"settings.operations.operation-service-level-type-manager\"]],{\"statements\":[[1,[28,\"t\",[\"general.maintenance\"],null],false]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\"],[\"operation-service-level-type\",\"settings.operations.operation-service-level-type.edit\",[28,\"array\",[\"name\"],null]]]],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/operations/operation-service-level-type.hbs"
    }
  });

  _exports.default = _default;
});