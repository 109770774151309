define("iris/controllers/tasks/work-logs/work-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    workLogsOfWeek: Ember.A(),
    // route sets it
    sortByStart: ['startsAt'],
    sortByEnd: ['endsAt'],
    workLogsSortedByStart: Ember.computed.sort('workLogsOfWeek', 'sortByStart'),
    workLogsSortedByEnd: Ember.computed.sort('workLogsOfWeek', 'sortByEnd'),
    boundaryWorkLogs: Ember.computed('workLogsSortedByStart', 'workLogsSortedByEnd', 'workLog', function () {
      let workLogsSortedByStart = this.get('workLogsSortedByStart');
      let workLogsSortedByEnd = this.get('workLogsSortedByEnd');
      let workLog = this.get('workLog');
      let lowerIndex = workLogsSortedByEnd.indexOf(workLog);
      let upperIndex = workLogsSortedByStart.indexOf(workLog);
      let offset = 2;
      return Ember.Object.create({
        'lower': workLogsSortedByEnd.objectAt(lowerIndex - offset),
        'upper': workLogsSortedByStart.objectAt(upperIndex + offset)
      });
    }),
    priorityGt: Ember.computed('model.resolvedTicket.priority', 'parentController.priorityLevel', function () {
      return this.get('model.resolvedTicket.priority') > this.get('parentController.priorityLevel');
    }),
    closed: Ember.computed.alias('model.closed'),
    disabled: Ember.computed.or('priorityGt', 'closed'),
    actions: {
      solveWorkLogTicket(workLog) {
        let solvedState = this.get('parentController.model.solvedState');
        workLog.get('ticket').then(ticket => {
          ticket.set('state', solvedState);
          ticket.save();
        });
      }

    }
  });

  _exports.default = _default;
});