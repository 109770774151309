define("iris/components/beta/cmdb/modals/manage-entity-procedures/component", ["exports", "ember-concurrency", "iris/models/types/entity-procedure", "iris/utils/ember-concurrency", "iris/utils/properties"], function (_exports, _emberConcurrency, _entityProcedure, _emberConcurrency2, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    data: null,

    // @, { entityIds: [] }
    init() {
      this._super(...arguments);

      this.set('failedEntityIds', []);
    },

    entityIds: Ember.computed.alias('data.entityIds'),
    failedEntityIds: null,
    // []
    entities: (0, _emberConcurrency2.taskValue)('entitiesTask'),
    entitiesTrigger: (0, _emberConcurrency2.taskTrigger)('entitiesTask', ['entityIds.[]']),
    entitiesTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('entity', {
        filter: {
          where: {
            id: {
              inq: this.entityIds
            }
          },
          order: 'name',
          include: ['entityProcedures']
        }
      });
    }).restartable(),
    _entities: Ember.computed('entities.[]', function () {
      var _this$entities$toArra, _this$entities;

      return (_this$entities$toArra = (_this$entities = this.entities) === null || _this$entities === void 0 ? void 0 : _this$entities.toArray()) !== null && _this$entities$toArra !== void 0 ? _this$entities$toArra : [];
    }),
    notificationTypes: (0, _properties.computedTask)(function* () {
      return yield this.store.query('entityProcedureNotificationType', {
        filter: {
          order: 'name'
        }
      });
    }),
    solvingGroups: (0, _properties.computedTask)(function* () {
      return yield this.store.query('solvingGroup', {
        filter: {
          order: 'name'
        }
      });
    }),
    currentEntityIndex: 0,
    currentEntity: Ember.computed('_entities.[]', 'currentEntityIndex', function () {
      return this._entities[this.currentEntityIndex];
    }),
    hasNextEntity: Ember.computed('_entities.length', 'currentEntityIndex', function () {
      return this.currentEntityIndex + 1 !== this._entities.length;
    }),

    selectNextEntity() {
      if (this.hasNextEntity) {
        this.incrementProperty('currentEntityIndex');
      } else {
        this.close(this.failedEntityIds);
      }
    },

    skipAllEntities() {
      for (let entityId of this.entityIds.slice(this.currentEntityIndex)) {
        this.pushFailedEntityId(entityId);
      }

      this.close(this.failedEntityIds);
    },

    saveEntityTask: (0, _emberConcurrency.task)(function* (entity) {
      let result = yield entity.save(true);

      if (!result) {
        // The error message has already been rendered in `entity.save()`
        this.pushFailedEntityId(entity.id);
        throw new Error('Error saving entity');
      }

      try {
        yield this.updateMonitoringForEntity(entity.id);
      } catch (error) {
        this.pushFailedEntityId(entity.id);
        console.error(error);
      }

      try {
        yield this.refreshIcingaForEntity(entity.id);
      } catch (error) {
        this.pushFailedEntityId(entity.id);
        this.flashMessages.danger(formatErrorMessages(error), {
          title: 'Sync Entity Procedures',
          icon: 'warning',
          sticky: true
        });
      }

      for (let entityProcedure of entity.get('entityProcedures').toArray()) {
        if (entityProcedure.changed === _entityProcedure.EntityProcedureChange.Deleted) {
          let result = yield entityProcedure.destroyRecord(true);

          if (!result) {
            this.flashMessages.danger({
              title: `Error Saving Entity Procedure: ${entityProcedure.name}`,
              icon: 'warning',
              sticky: true
            });
          }
        } else {
          yield entityProcedure.save(true);
        }
      }

      this.flashMessages.success(this.intl.t('forms.save-procedure-success'), {
        title: this.intl.t('forms.save-procedure'),
        icon: 'check'
      });
    }).drop(),
    actions: {
      async save(entity) {
        try {
          await this.saveEntityTask.perform(entity);
          this.selectNextEntity();
        } catch (error) {
          console.error(error);
        }
      },

      selectNextEntity() {
        this.selectNextEntity();
      },

      skipAllEntities() {
        this.skipAllEntities();
      }

    },

    refreshIcingaForEntity(entityId) {
      return this.api.collectionAction('entity', 'set-host-notes', {
        method: 'POST',
        data: {
          id: entityId
        }
      });
    },

    updateMonitoringForEntity(entityId) {
      return this.api.memberAction('entity', 'update-monitoring', entityId, {
        method: 'POST',
        data: {
          realEntityId: entityId,
          mustDeletWhenUpdateMonitoring: 0
        }
      });
    },

    pushFailedEntityId(entityId) {
      if (!this.failedEntityIds.includes(entityId)) {
        this.failedEntityIds.push(entityId);
      }
    },

    removeFailedEntityId(entityId) {
      if (this.failedEntityIds.includes(entityId)) {
        this.failedEntityIds.push(entityId);
      }
    }

  });

  _exports.default = _default;

  const formatErrorMessages = error => {
    var _error$errors$reduce, _error$errors;

    return (_error$errors$reduce = (_error$errors = error.errors) === null || _error$errors === void 0 ? void 0 : _error$errors.reduce(_ref => {
      let [acc, item] = _ref;
      return `${acc} ${item === null || item === void 0 ? void 0 : item.detail}.`;
    }, '')) !== null && _error$errors$reduce !== void 0 ? _error$errors$reduce : error;
  };
});