define("iris/components/ember-td/component", ["exports", "ember-table/components/ember-td/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    isSecondColumn: Ember.computed.equal('columnMeta.index', 1)
  });

  _exports.default = _default;
});