define("iris/routes/cmdb/entity-types/entities/entity/sites", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      // @ts-ignore
      let {
        entity
      } = this.modelFor('cmdb.entity-types.entities.entity');
      return {
        entity
      };
    }

  });

  _exports.default = _default;
});