define("iris/components/forms/duty/day/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DutyDay = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('duty.solverGroup'), _dec5 = Ember.computed.alias('solverGroup.shiftChanges'), _dec6 = Ember.computed.alias('solverGroup.shiftChanges.length'), _dec7 = Ember.computed.mapBy('solverGroup.accounts', 'id'), _dec8 = Ember.computed.mapBy('dutyDayUserAssocs', 'account.id'), _dec9 = Ember.computed('solverGroupAccountIds', 'dutyDayUserAssocsAccountIds'), _dec10 = Ember.computed('shiftChanges.[]', 'usedShiftChangeIds'), _dec11 = Ember.computed('accountIds', 'unUsedShiftChanges'), _dec12 = Ember.computed.mapBy('dutyDayUserAssocs', 'shiftChange.id'), _dec13 = Ember._action, _dec14 = Ember._action, (_class = class DutyDay extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "day", null);

      _defineProperty(this, "dutyDay", null);

      _defineProperty(this, "dutyDayUserAssocs", null);

      _defineProperty(this, "selectedUser", null);

      _defineProperty(this, "selectedShiftChange", null);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "modals", _descriptor3, this);

      _initializerDefineProperty(this, "solverGroup", _descriptor4, this);

      _initializerDefineProperty(this, "shiftChanges", _descriptor5, this);

      _initializerDefineProperty(this, "nrShifts", _descriptor6, this);

      _initializerDefineProperty(this, "solverGroupAccountIds", _descriptor7, this);

      _initializerDefineProperty(this, "dutyDayUserAssocsAccountIds", _descriptor8, this);

      _initializerDefineProperty(this, "usedShiftChangeIds", _descriptor9, this);
    }

    get accountIds() {
      const {
        dutyDayUserAssocsAccountIds
      } = this;
      return this.solverGroupAccountIds.filter(id => !dutyDayUserAssocsAccountIds.includes(id));
    }

    get unUsedShiftChanges() {
      return this.shiftChanges.filter(sch => !this.usedShiftChangeIds.includes(sch.id));
    }

    get selectUserDiasbled() {
      const {
        accountIds,
        unUsedShiftChanges
      } = this;
      return (// no more solver group member left
        accountIds.length === 0 || // no more selectable shift change left
        unUsedShiftChanges.length === 0
      );
    }

    *setDutyDay() {
      const dutyDay = yield this.duty.get(this.day);
      const dutyDayUserAssocs = yield dutyDay.get('dutyDayUserAssocs');
      Ember.setProperties(this, {
        dutyDay,
        dutyDayUserAssocs
      });
    }

    async createRecord() {
      if (!this.selectedUser || !this.selectedShiftChange) {
        return;
      }

      const dutyDay = this.duty.get(this.day);
      const dutyDayUserAssoc = this.store.createRecord('dutyDayUserAssoc', {
        account: this.selectedUser,
        shiftChange: this.selectedShiftChange,
        dutyDay
      });
      this.dutyDayUserAssocs.pushObject(dutyDayUserAssoc);

      if (dutyDay.get('id')) {
        await dutyDayUserAssoc.save();
        this.flashMessages.info(this.intl.t('general.save.success'), {
          title: this.intl.t('general.save.success-title'),
          icon: 'times'
        });
      }

      Ember.setProperties(this, {
        selectedShiftChange: null,
        selectedUser: null
      });
    }

    async addUser(user) {
      Ember.set(this, 'selectedUser', user);
      const {
        intl,
        solverGroup,
        unUsedShiftChanges
      } = this;

      if (unUsedShiftChanges.length === 1) {
        Ember.set(this, 'selectedShiftChange', unUsedShiftChanges.firstObject);
      } else {
        const response = await this.modals.open('forms/duty/day/shift-picker', {
          unUsedShiftChanges,
          title: intl.t('components.shift-picker.title'),
          subTitle: intl.t('components.shift-picker.sub-title', {
            solverGroupName: solverGroup.get('name')
          })
        });

        if (response === false) {
          Ember.set(this, 'selectedUser', null);
          return;
        } else {
          Ember.set(this, 'selectedShiftChange', response);
        }
      }

      this.createRecord();
    }

    async deleteDutyDayUserAssoc(dutydayUserAssoc) {
      const {
        flashMessages,
        intl
      } = this;
      const shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

      if (shouldDelete === false) {
        return;
      }

      try {
        await dutydayUserAssoc.destroyRecord(true);
        flashMessages.info(intl.t('general.delete.success-title'), {
          title: intl.t('general.delete.success'),
          icon: 'times'
        });
      } catch (error) {
        flashMessages.danger(intl.t('general.delete.error'), {
          title: intl.t('general.delete.error'),
          icon: 'warning'
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "solverGroup", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "shiftChanges", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "nrShifts", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "solverGroupAccountIds", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "dutyDayUserAssocsAccountIds", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "accountIds", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "accountIds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unUsedShiftChanges", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "unUsedShiftChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectUserDiasbled", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "selectUserDiasbled"), _class.prototype), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "usedShiftChangeIds", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setDutyDay", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "setDutyDay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addUser", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "addUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteDutyDayUserAssoc", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "deleteDutyDayUserAssoc"), _class.prototype)), _class));
  _exports.default = DutyDay;
});