define("iris/templates/crm/contracts/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uaItJ3EV",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-tether\",null,[[\"to\",\"target\",\"targetModifier\",\"attachment\",\"tetherClass\",\"on-overlay-click\",\"overlayClass\"],[\"modal-dialog\",\"document.body\",\"visible\",\"middle center\",\"modal-dialog modal-md\",[28,\"route-action\",[\"transitionTo\",\"crm.contracts\"],null],\"modal-backdrop\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n\\n    \"],[1,[28,\"modal-header\",null,[[\"title\"],[[28,\"t\",[\"crm.contrats.edit-contract\"],null]]]],false],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n      \"],[1,[28,\"forms/contract-form\",null,[[\"model\",\"partners\",\"contracts\",\"users\",\"currencies\",\"disableInput\"],[[24,[\"model\",\"contract\"]],[24,[\"model\",\"partners\"]],[24,[\"model\",\"contracts\"]],[24,[\"model\",\"users\"]],[24,[\"model\",\"currencies\"]],[24,[\"disableInput\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n      \"],[1,[28,\"buttons/save-button\",null,[[\"action\",\"value\"],[[28,\"route-action\",[\"save\",[28,\"hash\",null,[[\"redirectTo\",\"afterSave\"],[\"crm.contracts\",[28,\"route-action\",[\"afterSave\"],null]]]]],null],[24,[\"model\",\"contract\"]]]]],false],[0,\"\\n      \\n      \"],[1,[28,\"buttons/cancel-button\",null,[[\"action\",\"value\"],[[28,\"route-action\",[\"transitionTo\"],null],\"crm.contracts\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/crm/contracts/edit.hbs"
    }
  });

  _exports.default = _default;
});