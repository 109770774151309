define("iris/routes/finances/cash-tracking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        name: this.intl.t('bank-balance.date'),
        property: 'date',
        search: true
      }, {
        name: this.intl.t('forms.debit'),
        property: 'debit',
        search: true
      }, {
        name: this.intl.t('components-main.advance-invoice'),
        property: 'preInvoice',
        search: true
      }, {
        name: this.intl.t('forms.demand'),
        property: 'credit',
        search: true
      }, {
        name: this.intl.t('forms.bank'),
        property: 'bank',
        search: true
      }, {
        name: this.intl.t('forms.cash'),
        property: 'cash',
        search: true
      }, {
        name: '',
        property: 'a'
      }];
    }),
    buttonActions: Ember.computed(function () {
      return [{
        text: this.intl.t('tables.new-element'),
        type: 'dark',
        icon: 'fa fa-file',
        action: 'createCashTracking'
      }];
    }),

    setupController(controller, model) {
      this._super(controller, model);

      const columnFilterOptions = Ember.A([{
        fieldSet: {
          fields: [{
            label: this.intl.t('forms.from'),
            type: 'date',
            property: 'date',
            operator: 'gte'
          }, {
            label: this.intl.t('forms.to'),
            type: 'date',
            property: 'date',
            operator: 'lte'
          }]
        }
      }]);
      controller.set('columnFilterOptions', columnFilterOptions);
      controller.set('columns', this.get('columns'));
      controller.set('buttonActions', this.get('buttonActions'));
    },

    actions: {
      createCashTracking() {
        this.transitionTo('finances.cash-tracking.new');
      },

      afterSave() {
        const route = 'finances.cash-tracking';
        this.transitionTo(route);
        const contoller = this.controllerFor(route);
        contoller.toggleProperty('refresh');
      }

    }
  });

  _exports.default = _default;
});