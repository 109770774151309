define("iris/components/beta/filters/modals/edit-tab/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "19Fq54+k",
    "block": "{\"symbols\":[\"@data\",\"@close\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-content mx-auto\"],[10,\"style\",\"width: 400px\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[0,\"\\n      \"],[1,[23,1,[\"title\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"my-8\"],[8],[1,[23,1,[\"body\"]],false],[9],[0,\"\\n    \"],[5,\"bs-form\",[[3,\"on\",[\"submit\",[28,\"action\",[[23,0,[]],\"onSubmit\"],null]]]],[[\"@model\"],[[23,1,[\"tab\"]]]],{\"statements\":[[0,\"\\n      \"],[5,\"form-group\",[],[[\"@property\",\"@label\",\"@proportion\"],[\"name\",[28,\"t\",[\"intervene.name\"],null],\"4-8\"]],{\"statements\":[[0,\"\\n        \"],[5,\"bs-input\",[],[[\"@value\"],[[23,1,[\"tab\",\"name\"]]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@icon\",\"@text\"],[\"primary\",[28,\"fn\",[[23,2,[]],true],null],\"floppy-o\",[28,\"or\",[[23,1,[\"okButtonLabel\"]],[28,\"t\",[\"buttons.create\"],null]],null]]]],[0,\"\\n\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"default\",[28,\"fn\",[[23,2,[]],false],null],[28,\"or\",[[23,1,[\"cancelButtonLabel\"]],[28,\"t\",[\"buttons.cancel\"],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/beta/filters/modals/edit-tab/template.hbs"
    }
  });

  _exports.default = _default;
});