define("iris/routes/finances/incoming-invoices/new", ["exports", "iris/utils/create-record"], function (_exports, _createRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service(),

    async createInvoice() {
      return await (0, _createRecord.createIncomingInvoice)(this.store, {
        region: this.sessionAccount.region
      });
    },

    async model(params) {
      const store = this.get('store');
      const invoice = await this.createInvoice(params);
      /*
      const items = await invoice.get('items')
      items.pushObject(store.createRecord('incomingInvoiceItem'))*/

      return Ember.RSVP.hash({
        invoice: invoice,
        invoiceTypes: store.findAll('invoiceType'),
        vats: store.findAll('vat'),
        uoms: store.findAll('uom')
      });
    }

  });

  _exports.default = _default;
});