define("iris/components/modals/import-incoming-invoice/component", ["exports", "ember-concurrency", "iris/lib/modals", "iris/utils/error", "moment"], function (_exports, _emberConcurrency, _modals, _error, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const NAV_DATE_FORMAT = 'YYYY-MM-DD';
  let ImportIncomingInvoice = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.filter('invoices.@each.dbRecord', function (invoice) {
    return invoice.dbRecord;
  }), _dec7 = Ember.computed.filterBy('invoices', 'dbRecord', false), _dec8 = Ember.computed('alreadyInDB.[]', 'rowSelection.[]', function () {
    const {
      alreadyInDB
    } = this;
    return this.rowSelection.filter(sel => !alreadyInDB.includes(sel));
  }), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class ImportIncomingInvoice extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "selectedConnection", null);

      _defineProperty(this, "refreshAfterClose", false);

      _defineProperty(this, "start", (0, _moment.default)().add(-7, 'd'));

      _defineProperty(this, "end", (0, _moment.default)());

      _defineProperty(this, "invoices", []);

      _defineProperty(this, "rowSelection", []);

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "modals", _descriptor4, this);

      _initializerDefineProperty(this, "sessionAccount", _descriptor5, this);

      _initializerDefineProperty(this, "alreadyInDB", _descriptor6, this);

      _initializerDefineProperty(this, "notInDB", _descriptor7, this);

      _initializerDefineProperty(this, "toImport", _descriptor8, this);
    }

    didReceiveAttrs() {
      this.setDefaultNAVConnectionTask.perform();
    } // --------------- COMPUTEDS ---------


    // --------------- TASKS -------------
    *setDefaultNAVConnectionTask() {
      const {
        region
      } = this.sessionAccount;
      const ownerPartnerId = region.belongsTo('ownerPartner').id();
      const navConnections = yield this.api.query('navSetting', {
        filter: {
          where: {
            import: true
          }
        },
        include: ['partner']
      });
      const navConnectionsArray = navConnections.toArray();
      let selectedConnection = navConnectionsArray.find(c => c.belongsTo('partner').id() === ownerPartnerId);
      selectedConnection = selectedConnection || navConnectionsArray.firstObject;
      this.set('selectedConnection', selectedConnection);
    }

    *getInvoicesTask() {
      try {
        const result = yield this.api.collectionAction('incomingInvoice', 'get-nav-invoices', {
          data: {
            from: (0, _moment.default)(this.start).format(NAV_DATE_FORMAT),
            to: (0, _moment.default)(this.end).format(NAV_DATE_FORMAT),
            connectionId: this.selectedConnection.id
          }
        });
        const invoices = result.result;
        const alreadyInDB = invoices.filter(i => i.dbRecord);
        this.set('invoices', invoices);
        this.set('rowSelection', alreadyInDB);
      } catch (error) {
        const {
          flashMessages,
          intl
        } = this;
        const msg = (0, _error.formatErrorMessages)(error);
        flashMessages.danger(msg, {
          title: intl.t('general.loading-error'),
          sticky: true,
          icon: 'warning'
        });
      }
    } // --------------- ACTIONS ---------------

    /**
     * Updates the list's partner missing indicator with the newly inserted partner
     *
     * @param {*} partner partner who was inserted into the DB
     * @memberof ImportIncomingInvoice
     */


    updatePartnerStatus(partner) {
      for (const invoice of this.notInDB) {
        if (invoice.supplierDetailedTaxNumber === partner.tax) {
          Ember.set(invoice, 'supplier', partner.id);
        }
      }
    }

    getInvoices() {
      this.getInvoicesTask.perform();
    }

    onSelectRow(selection) {
      this.set('rowSelection', selection);
    }

    setRefreshAfterClose() {
      this.set('refreshAfterClose', true);
    }

    async importInvoices() {
      const {
        toImport,
        selectedConnection,
        updatePartnerStatus,
        setRefreshAfterClose
      } = this;
      await this.modals.open('modals/import-incoming-invoice/modals/import-modal', {
        toImport,
        selectedConnection,
        updatePartnerStatus,
        setRefreshAfterClose
      }, {
        className: _modals.ModalLayoutClassName.M
      });
    }

    changeSelectedConnection(selectedConnection) {
      this.setProperties({
        invoices: [],
        rowSelection: [],
        selectedConnection
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionAccount", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "alreadyInDB", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "notInDB", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "toImport", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setDefaultNAVConnectionTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "setDefaultNAVConnectionTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getInvoicesTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "getInvoicesTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePartnerStatus", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "updatePartnerStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getInvoices", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "getInvoices"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectRow", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectRow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setRefreshAfterClose", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "setRefreshAfterClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "importInvoices", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "importInvoices"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSelectedConnection", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "changeSelectedConnection"), _class.prototype)), _class));
  _exports.default = ImportIncomingInvoice;
});