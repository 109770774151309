define("iris/components/operational-file/edit/file-type-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    fileTypeBox: false,
    actions: {
      editFileType() {
        this.toggleProperty('fileTypeBox');
      }

    }
  });

  _exports.default = _default;
});