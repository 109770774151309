define("iris/templates/components/search-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OiPcMfi4",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"bs-input\",null,[[\"keyUp\",\"value\"],[[28,\"action\",[[23,0,[]],\"search\"],null],[24,[\"searchTerm\"]]]]],false],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"input-group-btn\"],[8],[0,\"\\n    \"],[7,\"button\",true],[10,\"class\",\"btn btn-default\"],[10,\"type\",\"submit\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"glyphicon glyphicon-search\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/search-field.hbs"
    }
  });

  _exports.default = _default;
});