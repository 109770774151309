define("iris/lib/auto/default-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DefaultFields = void 0;
  let DefaultFields;
  _exports.DefaultFields = DefaultFields;

  (function (DefaultFields) {
    DefaultFields[DefaultFields["Fields"] = ['orderColumn', 'isDirtyTemp', 'searchAttr']] = "Fields";
  })(DefaultFields || (_exports.DefaultFields = DefaultFields = {}));
});