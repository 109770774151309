define("iris/models/builtin-acl-action-assoc", ["exports", "ember-data", "ember-data/attr", "ember-data/relationships"], function (_exports, _emberData, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    actionSystemName: (0, _attr.default)('string'),
    actionMenu: (0, _attr.default)('number'),
    builtinAcl: (0, _relationships.belongsTo)('builtinAcl')
  });

  _exports.default = _default;
});