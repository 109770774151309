define("iris/templates/aiops/dashboards/aiops", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tDF7WdSt",
    "block": "{\"symbols\":[],\"statements\":[[5,\"aiops/dashboards/system/fails-line\",[],[[],[]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n    \"],[5,\"aiops/dashboards/system/good-pie\",[],[[],[]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n    \"],[5,\"aiops/dashboards/system/wrong-pie\",[],[[],[]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"aiops/dashboards/system/wrong-ticket-table\",[],[[],[]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/aiops/dashboards/aiops.hbs"
    }
  });

  _exports.default = _default;
});