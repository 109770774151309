define("iris/templates/settings/operations/operation-service-level-type-manager/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hlic4L//",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"isLoaded\"]]],null,{\"statements\":[[0,\"  \"],[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@proportion\",\"@frame\",\"@needClose\"],[[23,0,[\"model\"]],\"settings.operations.operation-service-level-type-manager\",\"3-9\",true,true]],{\"statements\":[[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@property\",\"@model\"],[\"name\",[23,0,[\"model\"]]]]],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\",\"@create\",\"@neededFieldsForCreate\"],[[23,0,[\"model\"]],\"operationServiceLevelType\",true,[28,\"array\",[\"name\"],null]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/operations/operation-service-level-type-manager/edit.hbs"
    }
  });

  _exports.default = _default;
});