define("iris/templates/unauth/confirmation/change-request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zQ3GQGQX",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[28,\"eq\",[[24,[\"model\",\"status\"]],\"INVALID_TOKEN\"],null],[28,\"eq\",[[24,[\"model\",\"status\"]],\"CHANGE_REQUEST_DECLINED\"],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"main-error mb-xlg body-error error-inside\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"h2 error-code text-dark text-center text-weight-semibold m-none\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"operation.main.number\"],[[\"number\"],[404]]],false],[0,\" \"],[7,\"i\",true],[10,\"class\",\"fa fa-file\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"error-explanation text-center\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"status\"]],\"INVALID_TOKEN\"],null]],null,{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"unauth.invalid-token2\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"status\"]],\"CHANGE_REQUEST_DECLINED\"],null]],null,{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"operation.change-request.declined\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-8 col-sm-offset-2 mt20\"],[8],[0,\"\\n    \"],[5,\"change-requests/edit/summary\",[],[[\"@model\"],[[23,0,[\"model\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/unauth/confirmation/change-request.hbs"
    }
  });

  _exports.default = _default;
});