define("iris/utils/routing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getCurrentRouteInfos = void 0;

  // Previously `handlerInfos` were accessed through the `state` property
  // (see the change at the time of this comment), which is private API
  // and deprecated (see link below). Looks like `currentRouteInfos` is
  // used even in the most recent `router.js` verions (mid-2021),
  // hopefully this foundation proves to be stable.
  //
  // https://deprecations.emberjs.com/v3.x/#toc_transition-state
  const getCurrentRouteInfos = function (routerService) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      prioritizeActiveTransition: false
    };
    let router = routerService._router.currentState.router;

    if (options.prioritizeActiveTransition && router.activeTransition) {
      return router.activeTransition.routeInfos;
    }

    return router.currentRouteInfos;
  };

  _exports.getCurrentRouteInfos = getCurrentRouteInfos;
});