define("iris/components/dashboard/weekly-tasks", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let monday = (0, _moment.default)().startOf('week');
  let sunday = (0, _moment.default)().endOf('week');

  var _default = Ember.Component.extend({
    tasks: Ember.computed.alias('tickets'),
    discs: ['danger', 'success', 'info', 'warning'],
    texts: ['today', 'weekly', 'done', 'unfinished'],
    // tickets
    today: Ember.computed.filter('tasks', function (task) {
      return (0, _moment.default)().isSame((0, _moment.default)(task.plannedEndDate), 'd');
    }),
    weekly: Ember.computed.filter('tasks', function (task) {
      return (0, _moment.default)(task.plannedEndDate).isBetween(monday, sunday, 'd', '[]');
    }),
    resolved: Ember.computed.filterBy('weekly', 'ticketStateId', 'SYS_07'),
    //burnt-in resolved
    data: Ember.computed('today', 'weekly', 'resolved', function () {
      let weekly = this.get('weekly.length');
      let resolved = this.get('resolved.length');
      return Ember.A([this.get('today.length'), weekly, resolved, weekly - resolved]);
    }),
    tooltips: Ember.computed('today', 'weekly', 'resolved', function () {
      let weekly = this.get('weekly');
      let today = this.get('today');
      let resolved = this.get('resolved');
      let unfinished = weekly.filter(ticket => {
        return ticket.ticketStateId !== 'SYS_07';
      });
      return {
        today: this._joinTickets(today),
        weekly: this._joinTickets(weekly),
        resolved: this._joinTickets(resolved),
        unfinished: this._joinTickets(unfinished)
      };
    }),

    _joinTickets(array) {
      let res = [];

      if (!array.length) {
        return false;
      }

      array.forEach(ticket => {
        res.push({
          'file': ticket.file.name,
          'ticketNumber': ticket.ticketNumber,
          'subject': ticket.subject
        });
      });

      if (res.length > 3) {
        res = res.slice(0, 3);
      }

      return res;
    }

  });

  _exports.default = _default;
});