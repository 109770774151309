define("iris/components/aiops/planned-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k1JtHv8i",
    "block": "{\"symbols\":[\"@model\",\"@property\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-11\"],[8],[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,1,[]],[23,2,[]]]]],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"row -mt-8 mb-4\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-sm-2\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-sm-10\"],[8],[0,\"\\n        \"],[7,\"i\",true],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"aiops.ticket.planned\"],null],false],[0,\": \"],[7,\"b\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"belongsTo\"]]],null,{\"statements\":[[0,\"              \"],[1,[28,\"get\",[[23,0,[\"plannedValue\"]],\"name\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"              \"],[1,[23,0,[\"plannedValue\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"          \"],[9],[0,\" \\n          \"],[1,[28,\"t\",[\"aiops.ticket.cp\"],null],false],[0,\": \"],[7,\"b\",true],[8],[1,[23,0,[\"cpValue\"]],false],[0,\"%\"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-1 text-left\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,0,[\"needAlert\"]],\"danger\"],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"fa-icon\",[\"exclamation-circle\"],[[\"class\"],[\"text-danger text-5xl mt-2\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,0,[\"needAlert\"]],\"warning\"],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"fa-icon\",[\"exclamation-triangle\"],[[\"class\"],[\"text-warning text-5xl mt-2\"]]],false],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/aiops/planned-field/template.hbs"
    }
  });

  _exports.default = _default;
});