define("iris/models/work-log", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "moment", "ember-cp-validations", "iris/mixins/anonymize"], function (_exports, _model, _attr, _relationships, _moment, _emberCpValidations, _anonymize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    ticket: (0, _emberCpValidations.validator)('presence', true),
    workLogNumber: (0, _emberCpValidations.validator)('length', {
      max: 30
    }),
    comment: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('actual-length', {
      min: 10
    })],
    publicSpentTime: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    spentTime: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  var _default = _model.default.extend(Validations, _anonymize.default, {
    modelName: 'WorkLog',
    beginDate: (0, _attr.default)('date', {
      defaultValue() {
        return new Date();
      }

    }),
    endDate: (0, _attr.default)('date'),
    // aliasis for calendar's occurrances
    startsAt: Ember.computed.alias('beginDate'),
    endsAt: Ember.computed.alias('endDate'),
    title: Ember.computed.alias('workLogNumber'),
    createDate: (0, _attr.default)('date'),
    publicSpentTime: (0, _attr.default)('number', {
      defaultValue: null
    }),
    spentTime: (0, _attr.default)('number', {
      defaultValue: null
    }),
    cost: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    rawCost: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    workLogNumber: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    ticketId: (0, _attr.default)('string'),
    travelDistance: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    comment: (0, _attr.default)('string'),
    tandm: (0, _attr.default)('number'),
    partnerComment: (0, _attr.default)('string'),
    disableEditing: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    ticket: (0, _relationships.belongsTo)('ticket'),
    user: (0, _relationships.belongsTo)('account'),
    location: (0, _relationships.belongsTo)('address'),
    documents: (0, _relationships.hasMany)('document'),
    duration: Ember.computed('startsAt', 'endsAt', function () {
      return (0, _moment.default)(this.get('endsAt')).diff(this.get('startsAt'), 'm');
    }),
    durationInHour: Ember.computed('startsAt', 'endsAt', function () {
      return (0, _moment.default)(this.get('endsAt')).diff(this.get('startsAt'), 'h');
    }),
    resolvedTicket: Ember.computed('ticket', {
      get() {
        let value = null;

        let _this = this;

        this.get('ticket').then(ticket => {
          _this.set('resolvedTicket', ticket);
        });
        return value;
      },

      set(key, value) {
        return value;
      }

    }),
    disabled: Ember.computed.or('solved', 'closed'),
    solved: Ember.computed.alias('resolvedTicket.solved'),
    closed: Ember.computed.alias('resolvedTicket.closed'),
    file: Ember.computed('ticket.file', {
      get(key) {
        let _this = this;

        let value = null;
        this.get('ticket').then(ticket => {
          let file = ticket.get('resolvedFile');

          _this.set('file', file);

          value = file;
        });
        return value;
      },

      set(key, value) {
        return value;
      }

    }),
    partner: Ember.computed('file.clientPartner', {
      get() {
        let value = null;
        this.get('file.clientPartner').then(partner => {
          this.set('partner', partner);
        });
        return value;
      },

      set(key, value) {
        return value;
      }

    })
  });

  _exports.default = _default;
});