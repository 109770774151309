define("iris/controllers/human-resource-management/my-events/edit", ["exports", "iris/utils/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);
    },

    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    //modal related settings
    endDate: Ember.computed('model.id', function () {
      let sortedEventDays = this.model.eventDays.sortBy('date');
      return (0, _moment.default)(sortedEventDays.lastObject.date);
    }),
    startDate: Ember.computed('model.id', function () {
      let sortedEventDays = this.model.eventDays.sortBy('date');
      return (0, _moment.default)(sortedEventDays.firstObject.date);
    }),
    types: Ember.computed(function () {
      return this.get('store').query('eventType', {
        filter: {
          order: 'name'
        }
      });
    }),
    minDate: (0, _moment.default)().add(1, 'd'),
    endDateMinDate: Ember.computed('model.start', function () {
      return this.get('model.start') ? this.get('model.start') : this.get('minDate');
    }),
    eventIsAccepted: Ember.computed('model', function () {
      return this.get('model.isAccepted') === true ? true : this.get('model.isAccepted') === false ? false : null;
    }),
    reasonDisabled: Ember.computed('session.data.authenticated.userId', function () {
      return this.get("session.data.authenticated.userId") !== this.get('model.user.leaderId');
    }),
    actions: {
      async saveEvent() {
        let {
          validations
        } = await (0, _model.validateRecord)(this.model);
        this.model.setValidated();

        if (!validations.isValid) {
          return;
        }

        let event = this.model.serialize();
        event.data.id = this.model.id;
        event.data.attributes.days = this.model.eventDays.map(_ref => {
          let {
            date
          } = _ref;
          return date;
        });

        try {
          await this.ajax.call('POST', 'event', null, 'saveEvent', {
            event: event
          });
        } catch (error) {
          const flashMessages = Ember.get(this, 'flashMessages');
          let errorString = error.errors.map(_ref2 => {
            let {
              detail
            } = _ref2;
            return detail;
          }).join('\n');
          flashMessages.danger(errorString, {
            title: 'Hiba',
            icon: 'times',
            sticky: true
          });
        } finally {
          this.router.transitionTo('human-resource-management.my-events');
        }
      },

      openDeleteEventModal() {
        this.set('deleteEventModal', true);
      },

      deleteEvent() {
        const flashMessages = Ember.get(this, 'flashMessages');
        this.get('model').destroyRecord(true).then(() => {
          flashMessages.success(this.intl.t('general.delete.success'), {
            title: this.intl.t('hr.event'),
            icon: 'check',
            sticky: true
          });
          this.set('deleteEventModal', false);
          this.send('back');
        });
      },

      closeDeleteEventModal() {
        this.set('deleteEventModal', false);
      },

      toggleIsAccepted(isAccepted) {
        let event = this.get('model');

        if (event.get('isAccepted') === isAccepted) {
          event.set('isAccepted', null);
        } else {
          event.set('isAccepted', isAccepted);
        }
      }

    }
  });

  _exports.default = _default;
});