define("iris/components/tables/operational-file/component", ["exports", "iris/lib/filters/types", "iris/lib/filters", "iris/lib/filters/column", "iris/lib/table", "iris/utils/flatten-eq-operators"], function (_exports, _types, _filters, _column, _table, _flattenEqOperators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    opportunity: true,
    //@ Boolean
    route: Ember.computed('opportunity', function () {
      return this.opportunity ? 'crm.operational-file' : 'operation.operational-file';
    }),
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    modals: Ember.inject.service(),
    store: Ember.inject.service(),
    constraintsRelations: [],

    init() {
      this._super(...arguments);

      const tempOpFile = this.store.createRecord('project', {
        type: 'operational'
      });
      const constraintsRelations = [];
      tempOpFile.eachRelationship((name, descriptor) => {
        var _descriptor$meta$opti;

        if ((_descriptor$meta$opti = descriptor.meta.options) !== null && _descriptor$meta$opti !== void 0 && _descriptor$meta$opti.constraints) {
          constraintsRelations.push(name);
        }
      });
      this.set('constraintsRelations', constraintsRelations);
    },

    predefinedViews: Ember.computed(function () {
      return [{
        name: this.intl.t('general.all'),
        filters: []
      }, {
        name: this.intl.t('general.open'),
        filters: [{
          name: 'closed',
          type: _types.FilterType.Boolean,
          value: false
        }]
      }];
    }),
    columns: (0, _column.columns)(function () {
      return [{
        name: 'fileNumber',
        displayName: this.intl.t('models.fileNumber'),
        fixed: true,
        route: `${this.route}.edit`,
        routeParams: (0, _table.rowValues)('id'),
        width: _column.ColumnWidth.S
      }, {
        name: 'name',
        displayName: this.intl.t('models.operationalFile.name')
      }, {
        name: 'partner',
        displayName: this.intl.t('models.operationalFile.partner'),
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'p.name',
        displayName: this.intl.t('models.operationalFile.partner'),
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'p.shortName',
        displayName: this.intl.t('project.clientPartner'),
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'primeContractorName',
        displayName: this.intl.t('models.operationalFile.primeContractor'),
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'p2.name',
        displayName: this.intl.t('models.operationalFile.primeContractor'),
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'p2.shortName',
        displayName: this.intl.t('project.primeContractor'),
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'primeContractorId',
        displayName: this.intl.t('models.operationalFile.primeContractor'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'partner'
      }, {
        name: 'sm/tm',
        displayName: this.intl.t('models.operationalFile.smtm'),
        sortable: false,
        exportable: false,
        visibility: _types.AttributeVisibility.Table,
        width: _column.ColumnWidth.SM
      }, {
        name: 'fileType',
        displayName: this.intl.t('models.operationalFile.type'),
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'fileTypeId',
        displayName: this.intl.t('models.operationalFile.type'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'fileType'
      }, {
        name: 'ft.name',
        displayName: this.intl.t('models.operationalFile.type'),
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'clientPartnerId',
        displayName: this.intl.t('models.operationalFile.partner'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'partner'
      }, {
        name: 'fileOwnerPartnerId',
        displayName: this.intl.t('models.operationalFile.fileOwnerPartner'),
        type: _types.AttributeType.BelongsTo,
        valuePath: 'fileOwnerPartnerName',
        orderProperty: 'fileOwnerPartnerName',
        optionsResourceName: 'partner',
        optionsQuery: async () => {
          return await this.store.query('partner', {
            filter: {
              where: {
                multiOwnerPartner: true
              }
            }
          });
        }
      }, {
        name: 'fileBusinessTypeId',
        displayName: this.intl.t('models.operationalFile.fileBusinessType'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'fileBusinessType'
      }, {
        name: 'orderNumber',
        displayName: this.intl.t('project.orderNumber')
      }, {
        name: 'fileStateId',
        displayName: this.intl.t('models.operationalFile.status'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'fileState'
      }, {
        name: 'managerUserId',
        displayName: this.intl.t('models.operationalFile.manager'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'account'
      }, {
        name: 'sponsorUserId',
        displayName: this.intl.t('models.operationalFile.sponsor'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'account'
      }, {
        name: 'subcontractor',
        displayName: this.intl.t('models.operationalFile.subcontractor'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.Boolean,

        formatLoopBackValue(value, filter, defaultFormatter) {
          if (filter.filterType === _types.FilterType.Boolean) {
            return value ? 1 : 0;
          }

          return defaultFormatter(value);
        }

      }, {
        name: 'closed',
        displayName: this.intl.t('models.operationalFile.closed'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.Boolean,

        formatLoopBackValue(value, filter, defaultFormatter) {
          if (filter.filterType === _types.FilterType.Boolean) {
            return value ? 1 : 0;
          }

          return defaultFormatter(value);
        }

      }, {
        name: 'dateEnd',
        displayName: this.intl.t('models.operationalFile.endDate'),
        type: _types.AttributeType.Date,
        width: _column.ColumnWidth.S
      }, {
        name: 'volumen',
        displayName: this.intl.t('models.operationalFile.volumen'),
        sortable: false,
        exportable: false,
        visibility: _types.AttributeVisibility.Table,
        width: _column.ColumnWidth.SM
      }, {
        name: 'stateName',
        displayName: this.intl.t('models.operationalFile.status'),
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'accountable',
        displayName: this.intl.t('models.operationalFile.accountable'),
        visibility: _types.AttributeVisibility.Filter,
        width: _column.ColumnWidth.XS,
        type: _types.AttributeType.Boolean
      }, {
        name: 'publicInternal',
        displayName: this.intl.t('models.operationalFile.publicInternal'),
        visibility: _types.AttributeVisibility.Filter,
        width: _column.ColumnWidth.XS,
        type: _types.AttributeType.Boolean
      }, {
        name: 'publicExternal',
        displayName: this.intl.t('models.operationalFile.publicExternal'),
        visibility: _types.AttributeVisibility.Filter,
        width: _column.ColumnWidth.XS,
        type: _types.AttributeType.Boolean
      }];
    }),
    rowActions: (0, _table.rowActions)(function () {
      const {
        constraintsRelations,
        intl,
        store,
        flashMessages
      } = this;
      return [{
        name: intl.t('general.deletion'),
        icon: 'trash',
        style: 'danger',
        disabled: Ember.computed('row', function () {
          for (const relName of constraintsRelations) {
            const ids = this.row[relName];

            if (ids && ids.length > 0) {
              return intl.t('general.delete.cant-delete-constraint', {
                relation: intl.t(`project.${relName}`),
                qty: ids.length
              });
            }
          }

          return false;
        }),

        async action(row) {
          const shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

          if (!shouldDelete) {
            return;
          }

          const record = await store.findRecord('project', row.id);
          const res = await record.destroyRecord(true);

          if (res) {
            this.storage.refresh();
            flashMessages.info(intl.t('general.delete.success'), {
              title: intl.t('general.delete.success-title'),
              icon: 'times'
            });
          } else {
            flashMessages.danger(intl.t('general.delete.error'), {
              title: this.intl.t('general.delete.error'),
              icon: 'warning'
            });
          }
        }

      }];
    }),
    fetchParams: Ember.computed('columns', 'storage.params', function () {
      const queryObject = (0, _filters.makeQueryObject)(this.columns, this.storage.params, {});
      const probability = this.opportunity ? {
        lt: 100
      } : {
        eq: 100
      };
      queryObject.filter.where.and.push({
        isActive: 1
      }, {
        type: 'operational'
      }, {
        probability
      }, {
        or: [{
          crVersion: 'null'
        }, {
          crVersion: 0
        }]
      });
      queryObject.filter.where.and.push();
      (0, _flattenEqOperators.flattenEqOperators)(queryObject.filter.where);
      return queryObject;
    })
  });

  _exports.default = _default;
});