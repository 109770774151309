define("iris/templates/components/forms/contract-contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T7PPTp1L",
    "block": "{\"symbols\":[\"option\",\"option\",\"option\"],\"statements\":[[4,\"form-group\",null,[[\"property\",\"label\"],[\"accountManagerUser\",[28,\"t\",[\"models.accountManagerUser\"],null]]],{\"statements\":[[4,\"iris-power-select\",null,[[\"disabled\",\"options\",\"searchField\",\"selected\",\"onchange\"],[[24,[\"disableInput\"]],[24,[\"sortedUsers\"]],\"longName\",[24,[\"model\",\"accountManagerUser\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"accountManagerUser\"]]],null]],null]]],{\"statements\":[[0,\"    \"],[1,[23,3,[\"longName\"]],false],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"form-group\",null,[[\"property\",\"label\"],[\"operationalManagerUser\",[28,\"t\",[\"models.operationalManagerUser\"],null]]],{\"statements\":[[4,\"iris-power-select\",null,[[\"disabled\",\"options\",\"searchField\",\"selected\",\"onchange\"],[[24,[\"disableInput\"]],[24,[\"sortedUsers\"]],\"longName\",[24,[\"model\",\"operationalManagerUser\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"operationalManagerUser\"]]],null]],null]]],{\"statements\":[[0,\"    \"],[1,[23,2,[\"longName\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"form-group\",null,[[\"property\",\"label\"],[\"technicalAccountManagerUser\",[28,\"t\",[\"models.technicalAccountManagerUser\"],null]]],{\"statements\":[[4,\"iris-power-select\",null,[[\"disabled\",\"options\",\"searchField\",\"selected\",\"onchange\"],[[24,[\"disableInput\"]],[24,[\"sortedUsers\"]],\"longName\",[24,[\"model\",\"technicalAccountManagerUser\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"technicalAccountManagerUser\"]]],null]],null]]],{\"statements\":[[0,\"    \"],[1,[23,1,[\"longName\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/contract-contacts.hbs"
    }
  });

  _exports.default = _default;
});