define("iris/components/beta/filters/fuzzy-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V96+G+Hi",
    "block": "{\"symbols\":[\"&attrs\",\"@value\"],\"statements\":[[7,\"form\",false],[12,\"class\",\"flex items-stretch w-96\"],[13,1],[3,\"on\",[\"submit\",[28,\"action\",[[23,0,[]],\"onSubmit\"],null]]],[8],[0,\"\\n  \"],[7,\"input\",false],[12,\"value\",[23,2,[]]],[12,\"placeholder\",[28,\"t\",[\"filters.fuzzy-search-placeholder\"],null]],[12,\"title\",[23,0,[\"inputTitle\"]]],[12,\"class\",\"px-4 flex-grow min-w-0 bg-transparent dark:text-gray-200\"],[12,\"type\",\"search\"],[3,\"on\",[\"input\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"_value\"]]],null]],[[\"value\"],[\"target.value\"]]]]],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"flex items-center ml-2 mr-4\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"\\n          block w-8 h-8 rounded-full leading-tight\\n          opacity-60 hover:opacity-100\\n          bg-gray-300 dark:bg-gray-400\\n          text-gray-800 dark:text-gray-900\\n        \"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"clear\"],null]]],[8],[0,\"\\n        \"],[1,[28,\"fa-icon\",[\"times\"],[[\"class\"],[\"text-lg\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",[29,[\"\\n      flex-shrink-0 w-16 rounded-sm\\n      \",[28,\"if\",[[23,2,[]],\"bg-scarlet-500 text-white\",\"bg-white text-scarlet-500 dark:bg-gray-700\"],null],\"\\n    \"]]],[12,\"type\",\"submit\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"onSubmit\"],null]]],[8],[0,\"\\n    \"],[1,[28,\"fa-icon\",[\"search\"],[[\"class\"],[\"text-2xl\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/beta/filters/fuzzy-search/template.hbs"
    }
  });

  _exports.default = _default;
});