define("iris/components/visualisation-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oOxT2Qot",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"border-dashed border-2 w-36 h-36 p-4\"],[8],[0,\"\\n  \"],[7,\"img\",false],[12,\"class\",\"cursor-pointer object-cover w-full\"],[12,\"src\",[28,\"concat\",[\"data:image/svg+xml;utf8,\",[28,\"if\",[[23,1,[\"entityTypeVisualisation\"]],[23,1,[\"entityTypeVisualisation\",\"svg\"]],[23,0,[\"defaultIcon\"]]],null]],null]],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"openModal\"],null]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/visualisation-icon/template.hbs"
    }
  });

  _exports.default = _default;
});