define("iris/mixins/incident-save", ["exports", "moment", "@sentry/browser"], function (_exports, _moment, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    uploadDocuments: Ember.inject.service(),

    // creates ticket assignemnt for the ticket
    // inherits start / end date,
    async generateTicketUserAssoc(ticket) {
      const startDate = ticket.get('plannedStartDate');
      const deadline = ticket.get('plannedEndDate');
      const diff = (0, _moment.default)(deadline).diff((0, _moment.default)(startDate), 'h'); // defined on the route, if any

      const userAssocName = this.get('userAssocName');
      const modelName = userAssocName || 'ticketUserAssoc';
      const tua = this.get('store').createRecord(modelName, {
        plannedExpensesInHour: diff,
        user: ticket.get('resolver'),
        ticket: ticket,
        ticketState: ticket.get('state'),
        startDate: startDate,
        deadline: deadline
      });
      return await tua.save(true);
    },

    async saveChildTickets(ticket) {
      const children = await ticket.get('children');

      if (!children) {
        return;
      }

      for (const child of children.toArray()) {
        const savedTicket = await child.save();
        await this.saveTicket(savedTicket);

        if (await child.get('children.length')) {
          await this.saveChildTickets(child);
        }
      }

      return;
    },

    async saveTicket(ticket) {
      // `ticket` may may not have a `.get()` method:
      // https://sentry.io/organizations/damit/issues/2051817321/?project=5376673
      if (!ticket || typeof ticket.get !== 'function') {
        try {
          Sentry.captureException(new Error(`Failed saveTicket() for ${ticket._debugContainerKey} (${JSON.stringify(ticket)})`));
        } catch (err) {
          Sentry.captureException(new Error(`Failed saveTicket() for ¯\\_(ツ)_/¯ (${JSON.stringify(ticket)})`));
        }

        return;
      } // group ticket, if it transformed from person ticket, tuas should be deleted


      let tuas = await ticket.get('ticketUserAssocs');

      if (!tuas) {
        return;
      }

      if (ticket.get('solvingGroup.id')) {
        if (tuas && tuas.length) {
          for (let tua of tuas.toArray()) {
            await tua.destroyRecord();
          }
        }
      } else {
        // new person ticket, or transformed from group ticket
        if (tuas.length === 0) {
          await this.generateTicketUserAssoc(ticket);
        } else {
          // delegated person ticket
          let [tua] = tuas.toArray();

          if (tua.get('user.id') !== ticket.get('resolver.id')) {
            tua.set('user', ticket.get('resolver'));
            await tua.save();
          }
        }
      }
    },

    // what should happen after everything is saved
    handleStrategies(strategy) {
      if (strategy === 0 || !strategy) return;

      if (strategy === 1) {
        this.controllerFor(this.get('parentRouteName')).send('refresh');
      }

      if (strategy === 2) {
        window.scrollTo(0, 0);
      } // scheduled tasks


      if (strategy === 3) {
        const parentRouteName = this.get('parentRouteName');
        const controller = this.controllerFor(this.get('parentRouteName'));
        controller.set('refresh', new Date());
        this.transitionTo(parentRouteName);
      }
    },

    actions: {
      async afterSave() {
        let ticket,
            strategy = 0;

        if (arguments.length === 1) {
          ticket = arguments.length <= 0 ? undefined : arguments[0];
        }

        if (arguments.length === 2) {
          strategy = arguments.length <= 0 ? undefined : arguments[0];
          ticket = arguments.length <= 1 ? undefined : arguments[1];
        }

        await this.saveTicket(ticket);
        await this.saveChildTickets(ticket);
        const workLogs = ticket.get('workLogs');
        let currentUserId = this.get('session.data.authenticated.userId');

        if (workLogs) {
          for (const workLog of workLogs.toArray()) {
            if (workLog.get('comment') && workLog.get('user').get('id') === currentUserId) {
              workLog.save();
            }
          }
        }

        this.handleStrategies(strategy);
        this.get('uploadDocuments').uploadPendingFiles(ticket, 'Ticket');
      },

      willTransition(transition) {
        if (transition.targetName === 'operation.sd-dashboard.index') {
          this.get('controller.model').rollback();
        }

        return true;
      },

      async setPreTicket(record, cb) {
        const preTicketState = await this.get('store').find('ticketState', 'SYS_17');
        record.set('state', preTicketState);
        cb();
      },

      async setIncident(record, cb) {
        const incidentType = await this.get('store').find('ticketType', 'SYS_01');
        record.set('type', incidentType);
        cb();
      }

    }
  });

  _exports.default = _default;
});