define("iris/components/navigation-side/menu-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IZ7Hw8cR",
    "block": "{\"symbols\":[\"&attrs\",\"@icon\"],\"statements\":[[7,\"strong\",false],[12,\"class\",\"\\n    rounded-full w-14 h-14 inline-flex justify-center items-center\\n    bg-trueGray-200 dark:bg-portoDark-gray-2e353e\\n  \"],[13,1],[8],[0,\"\\n  \"],[1,[28,\"fa-icon\",[[23,2,[]]],[[\"class\"],[\"text-3xl\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/navigation-side/menu-icon/template.hbs"
    }
  });

  _exports.default = _default;
});