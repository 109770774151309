define("iris/templates/components/action-group-drop-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V8jb44eG",
    "block": "{\"symbols\":[\"buttonAction\"],\"statements\":[[4,\"if\",[[24,[\"selectedRecords\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"class\",\"btn btn-default dropdown-toggle\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"toggleDropDown\"]],[8],[1,[28,\"t\",[\"components-main.actions\"],null],false],[0,\" \"],[7,\"span\",true],[10,\"class\",\"caret\"],[8],[9],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"show\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",\"dropdown-menu\"],[3,\"action\",[[23,0,[]],\"toggleDropDown\"]],[8],[0,\"\\n      \"],[7,\"ul\",true],[10,\"class\",\"list-unstyled\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"buttonCheckboxActions\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\",true],[8],[0,\"\\n            \"],[7,\"a\",false],[3,\"action\",[[23,0,[]],[28,\"route-action\",[[23,1,[\"action\"]],[24,[\"selectedRecords\"]],[24,[\"model\"]]],null]]],[8],[0,\"\\n              \"],[7,\"i\",true],[11,\"class\",[29,[[23,1,[\"icon\"]]]]],[8],[9],[0,\"\\n              \"],[1,[23,1,[\"text\"]],false],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/action-group-drop-down.hbs"
    }
  });

  _exports.default = _default;
});