define("iris/components/project/edit/file-type-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    fileTypeBox: false,
    actions: {
      editFileType() {
        this.toggleProperty('fileTypeBox');
      },

      setProfitCenter(profitCenter) {
        let file = this.model;
        file.set('profitCenter', profitCenter);
        file.set('fileBusinessType', null);
        file.set('fileType', null);
      },

      setFileBusinessType(fileBusinessType) {
        let file = this.model;
        file.set('fileBusinessType', fileBusinessType);
        file.set('fileType', null);
      }

    }
  });

  _exports.default = _default;
});