define("iris/lib/grid/index", ["exports", "jsonexport", "iris/lib/filters", "iris/lib/filters/types", "iris/lib/grid/bulk-actions"], function (_exports, _jsonexport, _filters, _types, _bulkActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "bulkActions", {
    enumerable: true,
    get: function () {
      return _bulkActions.bulkActions;
    }
  });
  _exports.toCSV = _exports.exportRows = void 0;

  const exportRows = (rows, columns) => {
    let exportableColumns = (0, _filters.filterByVisiblity)(columns, [_types.AttributeVisibility.Table, _types.AttributeVisibility.Export]).filter(col => col.exportable !== false);
    let headers = exportableColumns.map(col => col.displayName);
    let data = (rows || []).map(row => {
      let exportRow = {};

      for (let col of exportableColumns) {
        let value = Ember.get(row, col.valuePath);

        if (col.type === _types.AttributeType.HasMany) {
          if (!col.formatExportValue) {
            throw new Error(`AttributeType.HasMany requires formatExportValue to be defined for exporting (column: ${col.name})`);
          }

          exportRow[col.valuePath] = col.formatExportValue(value, row, columns);
        } else {
          let formattedValue = col.formatExportValue ? col.formatExportValue(value, row, columns) : // @ts-ignore
          col.formatDisplayValue(value);

          if (typeof formattedValue === 'number') {
            // Format fraction separator from `.` to `,` to make HU MS Excel happy
            formattedValue = String(formattedValue).replace(/\./g, ',');
          }

          exportRow[col.valuePath] = formattedValue;
        }
      }

      return exportRow;
    });
    return {
      headers,
      data
    };
  };

  _exports.exportRows = exportRows;

  const toCSV = async (headers, data) => {
    return new Promise((resolve, reject) => {
      (0, _jsonexport.default)(data, {
        rename: headers,
        rowDelimiter: ';',
        forceTextDelimiter: true
      }, function (err, csv) {
        if (err) {
          return reject(err);
        }

        resolve(csv);
      });
    });
  };

  _exports.toCSV = toCSV;
});