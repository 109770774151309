define("iris/components/issue-report/similar-tickets/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wV4RMv3F",
    "block": "{\"symbols\":[\"t\"],\"statements\":[[4,\"if\",[[28,\"and\",[[23,0,[\"aiops\"]],[23,0,[\"ticketIds\",\"length\"]]],null]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"font-bold text-4xl\"],[8],[1,[28,\"t\",[\"incident-form.similar-tickets\"],null],false],[9],[0,\"\\n\\n  \"],[5,\"auto/grid\",[],[[\"@modelName\",\"@readOnly\",\"@neededFields\",\"@filter\",\"@isRender\",\"@limit\",\"@defaultFilters\",\"@columnOverwrites\"],[\"ticket\",true,[28,\"array\",[\"ticketNumber\",\"subject\"],null],false,true,5,[23,0,[\"defaultFilters\"]],[23,0,[\"columns\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"ticketNumber\"],null]],null,{\"statements\":[[0,\"      \"],[5,\"link-to\",[[12,\"target\",\"_blank\"]],[[\"@route\",\"@model\"],[\"operation.sd-dashboard.edit\",[23,1,[\"row\",\"id\"]]]],{\"statements\":[[0,\"\\n        \"],[1,[23,1,[\"row\",\"ticketNumber\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"and\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"subject\"],null],[23,0,[\"sessionAccount\",\"account\",\"anonymize\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"filter blur-sm\"],[8],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/issue-report/similar-tickets/template.hbs"
    }
  });

  _exports.default = _default;
});