define("iris/templates/settings/operations/fetchmail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Lls7NO/",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\",\"@defaultOrder\"],[\"fetchmail\",\"settings.operations.fetchmail.edit\",[28,\"array\",[\"name\",\"host\",\"port\",\"fetchmailType\",\"user\",\"active\"],null],\"createDate DESC\"]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/operations/fetchmail.hbs"
    }
  });

  _exports.default = _default;
});