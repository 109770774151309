define("iris/models/external-integration", ["exports", "ember-data/attr", "ember-data/model", "ember-data/relationships", "ember-cp-validations"], function (_exports, _attr, _model, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/use-ember-data-rfc-395-imports */
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    typeId: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    baseUrl: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    project: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    defaultBusinessService: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    defaultServiceLevel: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    resyncCron: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    filterParams: (0, _attr.default)('string'),
    typeId: (0, _attr.default)('string'),
    baseUrl: (0, _attr.default)('string'),
    cron: (0, _attr.default)('string'),
    loginUserName: (0, _attr.default)('string'),
    loginUserPass: (0, _attr.default)('string'),
    isActive: (0, _attr.default)('boolean'),
    irisProjectId: (0, _attr.default)('string'),
    resyncCron: (0, _attr.default)('string'),
    project: (0, _relationships.belongsTo)('project'),
    defaultBusinessService: (0, _relationships.belongsTo)('businessService'),
    defaultServiceLevel: (0, _relationships.belongsTo)('serviceLevel'),
    technicalContact: (0, _relationships.belongsTo)('contact')
  });

  _exports.default = _default;
});