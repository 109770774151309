define("iris/components/forms/application-to-intervene/form-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    selectedContact: null,
    selectedUser: null,
    contacts: Ember.computed.alias('model.file.clientPartner.contacts'),
    unusedContacts: Ember.computed('usedContactIds.[]', 'contacts.[]', function () {
      let usedContactIds = this.get('usedContactIds');
      let contacts = this.get('contacts');

      if (Ember.isNone(contacts)) {
        return Ember.A();
      }

      return this.get('contacts').reject(contact => {
        let id = contact.get('id');
        return !id || usedContactIds.includes(id);
      });
    }),
    unusedUsers: Ember.computed('usedUserIds.[]', 'accounts.[]', function () {
      let usedUserIds = this.get('usedUserIds');
      return this.get('accounts').reject(user => {
        return usedUserIds.includes(user.get('id'));
      });
    }),
    _ticsByTypeContacts: Ember.computed.mapBy('ticsByType', 'contact'),
    usedContactIds: Ember.computed.mapBy('_ticsByTypeContacts', 'id'),
    _tiusByTypeUsers: Ember.computed.mapBy('tiusByType', 'user'),
    usedUserIds: Ember.computed.mapBy('_tiusByTypeUsers', 'id'),
    ticsByType: Ember.computed('model.ticketInterventionContacts.[]', 'type', function () {
      return this.get('model.ticketInterventionContacts').filterBy('type', this.get('type'));
    }),
    tiusByType: Ember.computed('model.ticketInterventionUsers.[]', 'type', function () {
      return this.get('model.ticketInterventionUsers').filterBy('type', this.get('type'));
    }),
    newContact: false,
    actions: {
      newContact() {
        let partner = this.get('model.file.clientPartner');
        let contact = this.get('store').createRecord('contact', {
          'partner': partner
        });
        this.set('contact', contact);
        this.set('newContact', true);
      },

      addPerson(person) {
        let modelName = person.constructor.modelName;

        if (modelName === 'account') {
          modelName = 'user';
        }

        let ticket = this.get('model');
        let record = {
          ticket: ticket,
          type: this.get('type')
        };
        record[modelName] = person;
        let deadline = this.get('deadline');

        if (deadline) {
          record['deadline'] = deadline;
        }

        let assocModel = modelName === 'contact' ? 'ticketInterventionContact' : 'ticketInterventionUser';
        record = this.get('store').createRecord(assocModel, record);
      },

      closeModal() {
        this.set('newContact', false);
      }

    }
  });

  _exports.default = _default;
});