define("iris/templates/settings/operations/ticket-states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6vWROyYR",
    "block": "{\"symbols\":[\"t\",\"destinationState\",\"stateChange\"],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@noCreate\",\"@defaultOrder\",\"@neededFields\"],[\"ticket-state\",\"settings.operations.ticket-states.edit\",true,\"createDate DESC\",[28,\"array\",[\"name\",\"project\",\"applicationToIntervene\",\"management\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"management\"],null]],null,{\"statements\":[[4,\"each\",[[24,[\"destinationStates\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"stateChangeAllows\"]]],null,{\"statements\":[[4,\"if\",[[28,\"and\",[[28,\"eq\",[[23,3,[\"originStateId\"]],[23,1,[\"row\",\"id\"]]],null],[28,\"eq\",[[23,3,[\"destinationStateId\"]],[23,2,[\"id\"]]],null]],null]],null,{\"statements\":[[0,\"            \"],[7,\"span\",true],[10,\"class\",\"label label-default\"],[10,\"style\",\"display: inline-block\"],[8],[0,\"\\n              \"],[1,[23,2,[\"name\"]],false],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[3]},null]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/operations/ticket-states.hbs"
    }
  });

  _exports.default = _default;
});