define("iris/mixins/anonymize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let propertyDictionary = {
    name: 'Name',
    longName: 'Account',
    email: 'Email',
    tax: 'Tax',
    phone: 'Phone',
    code: 'Code',
    address: 'Address',
    town: 'Town',
    zipCode: 'Zipcode',
    comment: 'Comment'
  };

  var _default = Ember.Mixin.create({
    sessionAccount: Ember.inject.service(),

    async didLoad() {
      if ((!this.sessionAccount.account || !this.sessionAccount.account.anonymize) && (this.modelName !== 'Account' || this.modelName === 'Account' && !this.anonymize)) {
        return;
      }

      let model = this;
      model.eachAttribute(async (name, meta) => {
        if (Object.keys(propertyDictionary).includes(name)) {
          let modelName = name === 'name' ? model.modelName : propertyDictionary[name];
          await model.set(name, `${modelName}${model['id'].slice(model['id'].length - 4)}`);
        }
      });
    }

  });

  _exports.default = _default;
});