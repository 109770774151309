define("iris/routes/human-resource-management/resource-planner", ["exports", "ember-concurrency", "iris/mixins/protected-route"], function (_exports, _emberConcurrency, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ResourcePlannerRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class ResourcePlannerRoute extends Ember.Route.extend(_protectedRoute.default) {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "sessionAccount", _descriptor2, this);
    }

    async model(params) {
      if (!params.id) {
        return;
      }

      const currentUser = await this.sessionAccount.account;
      const rpPriorities = this.store.findAll('resourcePlanPriority');
      const periodResult = await this.store.query('resourcePeriod', {
        filter: {
          where: {
            id: params.id
          },
          include: ['state', 'resourceGroup']
        }
      });
      const period = periodResult.get('firstObject');
      const resourceGroup = await period.get('resourceGroup');
      const solvers = await resourceGroup.get('solvers');
      const solverIds = solvers.map(solver => solver.id);
      const publicHolidays = await this.store.query('publicHoliday', {
        filter: {
          where: {
            date: {
              between: [period.startDate, period.endDate]
            }
          },
          order: 'date'
        }
      });
      let solverTuas = await this.store.query('ticketUserAssoc', {
        filter: {
          where: {
            and: [{
              userId: {
                inq: solverIds
              }
            }, {
              'ticket.ticketStateId': {
                nin: ['SYS_07', 'SYS_08', 'SYS_09', 'SYS_10', 'SYS_14', 'SYS_15']
              }
            }, {
              'ticket.deleted_at': null
            }, {
              deletedAt: null
            }]
          },
          include: ['ticket', 'resourcePlans']
        }
      });
      let solverDuas = await this.store.query('dutyUserAssoc', {
        filter: {
          where: {
            userId: {
              inq: solverIds
            },
            date: {
              between: [period.startDate, period.endDate]
            }
          },
          order: 'date'
        }
      });
      let solverEuas = await this.store.query('eventDay', {
        filter: {
          where: {
            date: {
              between: [period.startDate, period.endDate]
            },
            'event.userId': {
              inq: solverIds
            },
            'event.deleted_at': null,
            'event.isAccepted': true
          },
          include: ['event'],
          order: 'date'
        }
      });
      let columns = solvers.sortBy('longName');
      columns.forEach(column => {
        let tuaCards = solverTuas.filter(tua => {
          return tua.user.get('id') === column.id;
        }).sort((a, b) => {
          var _a$get, _b$get;

          let aPlan = (_a$get = a.get('resourcePlans')) === null || _a$get === void 0 ? void 0 : _a$get.filter(async a => (await a.get('resourcePeriod.id')) === period.get('id'));
          let bPlan = (_b$get = b.get('resourcePlans')) === null || _b$get === void 0 ? void 0 : _b$get.filter(async b => (await b.get('resourcePeriod.id')) === period.get('id'));

          if (aPlan.length) {
            return -1;
          } else if (bPlan.length) {
            return 1;
          } else {
            return 0;
          }
        });
        column.set('cards', tuaCards);
        let stickyCards = [];
        let duas = solverDuas.filter(dua => {
          return dua.user.get('id') === column.id;
        });

        if (duas.length) {
          stickyCards.push({
            duas,
            dates: duas.map(dua => dua.date)
          });
        }

        let euas = solverEuas.filter(eua => {
          return eua.get('event.user.id') === column.id;
        });

        if (euas.length) {
          stickyCards.push({
            euas,
            dates: euas.map(eua => eua.date)
          });
        }

        column.set('stickyCards', Ember.A(stickyCards));
      });
      return (0, _emberConcurrency.hash)({
        period,
        columns,
        rpPriorities,
        currentUser,
        publicHolidays
      });
    }

    async refreshModel() {
      this.refresh();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sessionAccount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshModel", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "refreshModel"), _class.prototype)), _class));
  _exports.default = ResourcePlannerRoute;
});