define("iris/components/sub-ticket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isActualModel: Ember.computed('model.id', 'rootComp.model.id', function () {
      return this.get('model.id') === this.get('rootComp.model.id') ? 'isActualModel' : '';
    }),
    actions: {
      monogram(str) {
        return str.substring(0, 1);
      }

    }
  });

  _exports.default = _default;
});