define("iris/components/tables/entity-software", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        name: this.intl.t('entity.status'),
        property: 'icingaStatus',
        search: false
      }, {
        name: this.intl.t('entity.name'),
        property: 'entitname',
        search: true
      }, {
        name: this.intl.t('forms.device-type'),
        property: 'entitentityType.name',
        search: true
      }];
    }),
    mode: 'entity',
    softwareMode: '',
    newSoftwarePath: Ember.computed('mode', 'softwareMode', function () {
      let mode = this.get('mode');
      let softwareMode = this.get('softwareMode');

      if (softwareMode) {
        return 'cmdb.entity-types.entities.' + mode + '.software.' + softwareMode + '.software.new';
      } else {
        return 'cmdb.entity-types.entities.' + mode + '.software.new.attributes';
      }
    }),
    editSoftwarePath: Ember.computed('mode', function () {
      let mode = this.get('mode');
      let softwareMode = this.get('softwareMode');

      if (softwareMode) {
        return 'cmdb.entity-types.entities.' + mode + '.software.' + softwareMode + '.software.edit';
      } else {
        return 'cmdb.entity-types.entities.' + mode + '.software.edit.attributes';
      }
    })
  });

  _exports.default = _default;
});