define("iris/templates/components/bs-modal-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FLpdnxxc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1,[[23,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"hasSubmitButton\"]]],null,{\"statements\":[[0,\"        \"],[4,\"bs-button\",null,[[\"type\",\"action\"],[\"default\",\"close\"]],{\"statements\":[[1,[22,\"closeTitle\"],false]],\"parameters\":[]},null],[0,\"\\n        \"],[4,\"bs-button\",null,[[\"type\",\"buttonType\",\"disabled\"],[\"primary\",\"submit\",[24,[\"submitDisabled\"]]]],{\"statements\":[[1,[22,\"submitTitle\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[4,\"bs-button\",null,[[\"type\",\"action\"],[\"primary\",\"close\"]],{\"statements\":[[1,[22,\"closeTitle\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/bs-modal-footer.hbs"
    }
  });

  _exports.default = _default;
});