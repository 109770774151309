define("iris/components/operational-file/file-balance/statistics/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TandmTimelineStatistics = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class TandmTimelineStatistics extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _defineProperty(this, "data", null);

      _defineProperty(this, "options", {});
    }

    async didInsertElement() {
      await this.fetchStatsTask.perform();
    }

    *fetchStatsTask() {
      const data = yield this.api.memberAction('project', 'return-chart', this.file.id);
      this.set('data', this.serialize(data));
    }

    serialize(data) {
      const labels = Object.keys(data.incomings);
      const incomings = [],
            outgoings = [];

      for (var key in data.outgoings) {
        incomings.push(data.outgoings[key]);
      }

      for (var key in data.incomings) {
        outgoings.push(data.incomings[key]);
      }

      return {
        labels,
        datasets: [{
          label: this.intl.t('file-operational.statistics.incomings'),
          data: incomings,
          fill: false,
          borderColor: '#7bc325',
          tension: 0.1
        }, {
          label: this.intl.t('file-operational.statistics.outgoings'),
          data: outgoings,
          fill: false,
          borderColor: '#fb525c',
          tension: 0.1
        }]
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchStatsTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchStatsTask"), _class.prototype)), _class));
  _exports.default = TandmTimelineStatistics;
});