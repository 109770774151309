define("iris/templates/components/language-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dCY5x1rt",
    "block": "{\"symbols\":[\"language\"],\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"toggleLanguageMenu\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,0,[\"currentLanguage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",false],[12,\"style\",\"text-decoration: none;\"],[3,\"action\",[[23,0,[]],\"toggleLanguageMenuAction\"]],[8],[0,\"\\n      \"],[1,[28,\"world-flag-svg\",[[23,0,[\"currentLanguage\",\"flagCode\"]],16],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"relative inline-block\"],[8],[0,\"\\n      \"],[1,[28,\"spin-spinner\",null,[[\"lines\",\"length\",\"width\",\"scale\",\"color\"],[13,20,10,0.2,[28,\"if\",[[23,0,[\"theme\",\"dark\"]],\"#fff\",\"#000\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"inline-block -mx-4\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"grid grid-cols-\",[23,0,[\"languages\",\"length\"]]]]],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"languages\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"transform transition duration-500 hover:scale-125 m-2\"],[8],[0,\"\\n          \"],[7,\"a\",false],[3,\"action\",[[23,0,[]],\"changeLanguage\",[23,1,[]]]],[8],[0,\"\\n            \"],[1,[28,\"world-flag-svg\",[[23,1,[\"flagCode\"]],16],null],false],[0,\"\\n          \"],[9],[0,\"\\n          \"],[5,\"ember-tooltip\",[],[[],[]],{\"statements\":[[0,\"\\n            \"],[1,[23,1,[\"name\"]],false],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/language-selector.hbs"
    }
  });

  _exports.default = _default;
});