define("iris/templates/projects/files/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C2sqShia",
    "block": "{\"symbols\":[],\"statements\":[[5,\"project/edit\",[],[[\"@model\",\"@disableInputObject\",\"@route\",\"@projectState\"],[[23,0,[\"model\"]],[23,0,[\"disableInputObject\"]],\"projects.files\",\"project\"]]],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/projects/files/edit.hbs"
    }
  });

  _exports.default = _default;
});