define("iris/controllers/settings/date-type-definitions/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let DateTypeDefinitionEditController = (_dec = Ember._action, (_class = class DateTypeDefinitionEditController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _defineProperty(this, "selectedDate", void 0);
    }

    async addDate() {
      let dates = await this.get('model.dates');
      let record = await this.get('store').createRecord('dateTypeDefinitionDate', {
        date: this.get('selectedDate')
      });
      dates.pushObject(record);
      this.set('selectedDate', null);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "addDate", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "addDate"), _class.prototype)), _class));
  _exports.default = DateTypeDefinitionEditController;
});