define("iris/templates/components/forms/settings-operations-priorities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lJCwnM3c",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-form\",null,[[\"model\"],[[24,[\"model\"]]]],{\"statements\":[[4,\"form-group\",null,[[\"property\",\"label\"],[\"urgencyLevel\",[28,\"t\",[\"forms.urgencylevel\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"urgencyLevel\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"h5\",true],[10,\"class\",\"h5\"],[8],[1,[28,\"t\",[\"forms.severity-levels\"],null],false],[9],[0,\"\\n\"],[4,\"form-group\",null,[[\"property\",\"label\"],[\"col1\",[28,\"t\",[\"operation.main.number\"],[[\"number\"],[\"1\"]]]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"col1\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\"],[\"col2\",[28,\"t\",[\"operation.main.number\"],[[\"number\"],[\"2\"]]]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"col2\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\"],[\"col3\",[28,\"t\",[\"operation.main.number\"],[[\"number\"],[\"3\"]]]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"col3\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\"],[\"col4\",[28,\"t\",[\"operation.main.number\"],[[\"number\"],[\"4\"]]]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"col4\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\"],[\"col5\",[28,\"t\",[\"operation.main.number\"],[[\"number\"],[\"5\"]]]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"col5\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/settings-operations-priorities.hbs"
    }
  });

  _exports.default = _default;
});