define("iris/components/tandm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8EBa06U4",
    "block": "{\"symbols\":[\"c\",\"@model\",\"@disableInput\"],\"statements\":[[5,\"auto/form/one2-many\",[],[[\"@parentModel\",\"@itemName\",\"@relationFiled\",\"@modalSize\",\"@proportion\",\"@readOnly\",\"@createRecord\",\"@formComponent\",\"@noFooter\",\"@externalRowActions\",\"@neededFields\"],[[23,2,[]],\"tandms\",\"fileId\",\"XL\",\"4-8\",[23,3,[]],[28,\"action\",[[23,0,[]],\"createTandmRecord\"],null],\"forms/tandm\",true,[23,0,[\"rowActions\"]],[28,\"array\",[\"name\",\"state\",\"methodOfSettlement\",\"dateStart\",\"dateEnd\",\"balance\",\"alreadyUsed\",\"alreadyInvoiced\"],null]]],{\"statements\":[[0,\"\\n\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"stateId\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"badge badge-info\"],[8],[1,[23,1,[\"row\",\"state\",\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"methodOfSettlement\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"strong\",true],[8],[1,[28,\"t\",[[28,\"concat\",[\"tandm.\",[23,1,[\"row\",\"methodOfSettlement\"]]],null]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/tandm/template.hbs"
    }
  });

  _exports.default = _default;
});