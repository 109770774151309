define("iris/services/session-account", ["exports", "@sentry/browser"], function (_exports, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    socket: Ember.inject.service(),
    currentLanguage: null,

    loadCurrentUser() {
      const accountId = this.get('session.data.authenticated.userId');
      this.get('socket').authenticate();
      let store = this.get('store');
      return store.find('account', accountId).then(account => {
        this.set('account', account);
        this.set('currentLanguage', account.language);
        this.set('substitute', account.pandantUser);
        Sentry.setUser({
          accountId
        });
        return Ember.RSVP.hash({
          region: store.find('region', account.get('regionId')),
          regionElements: store.query('regionElement', {
            filter: {
              where: {
                active: true
              }
            }
          }),
          actionNames: store.query('actionUserAssoc', {
            filter: {
              where: {
                or: [{
                  userId: accountId
                }, {
                  pandantUserId: accountId,
                  menu: 0
                }]
              }
            }
          }),
          mainMenus: store.query('action', {
            filter: {
              where: {
                and: [{
                  menu: 1
                }, {
                  parentId: 0
                }, {
                  rank: {
                    neq: 0
                  }
                }]
              },
              order: 'rank'
            }
          }),
          substituteFor: store.query('account', {
            filter: {
              where: {
                pandantUserId: accountId
              },
              fields: ['id']
            }
          })
        }).then(result => {
          this.setProperties(result);
          let actionNames = result.actionNames;
          let actions = Ember.A();
          let menus = Ember.A();
          let routes = Ember.A();
          let substituteForIds = result.substituteFor.map(a => a.id);
          actionNames.filter(a => {
            if (a.get('menu') === 0) {
              actions.pushObject(a);
            }

            if (a.get('menu')) {
              menus.pushObject(a);
            }

            if (a.get('route')) {
              routes.pushObject(a);
            }
          });
          this.set('regionElements', result.regionElements);
          this.set('actions', actions);
          this.set('menus', menus);
          this.set('routes', routes);
          this.set('substituteForIds', substituteForIds);
        });
      });
    }

  });

  _exports.default = _default;
});