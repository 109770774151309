define("iris/app", ["exports", "jquery-migrate", "@ember/map-polyfill", "iris/resolver", "ember-load-initializers", "iris/config/environment", "@sentry/browser", "@sentry/integrations"], function (_exports, _jqueryMigrate, _mapPolyfill, _resolver, _emberLoadInitializers, _environment, Sentry, _integrations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  console.info('Console.trace is aliased to console.log to avoid long traces we cannot see the output from. Find this message and remove/comment the line below to restore the original method.'); // eslint-disable-next-line no-console

  console.trace = function () {
    return console.log(...arguments);
  }; // Ember Array prototype extension
  // @ts-ignore


  Array.prototype.contains = Array.prototype.includes; // Restore `Ember.Map` for packages that depend on it, like `ember-plupload`

  Ember.Map = _mapPolyfill.default;

  if (_environment.default.sentryDsn) {
    Sentry.init({
      dsn: _environment.default.sentryDsn,
      integrations: [new _integrations.Ember()],

      beforeSend(event, hint) {
        let error = hint.originalException; // ignore aborted route transitions from the Ember.js router

        if ((error === null || error === void 0 ? void 0 : error.name) === 'TransitionAborted') {
          return null;
        }

        return event;
      }

    });
  }

  jQuery.migrateTrace = false;
  let App;
  App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});