define("iris/routes/aiops/optimalizations/optimalizations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AiopsOptilaizationSubRoute extends Ember.Route {}

  _exports.default = AiopsOptilaizationSubRoute;
});