define("iris/models/file-proposal", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "moment", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _moment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    description: (0, _emberCpValidations.validator)('length', {
      max: 200
    }),
    supplier: [(0, _emberCpValidations.validator)('belongs-to'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });
  let now = new Date();
  let end = (0, _moment.default)().add(2, 'w').toDate();

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    partnerRef: (0, _attr.default)('string'),
    createDate: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    fromDate: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    endDate: (0, _attr.default)('date', {
      defaultValue: function () {
        return end;
      }
    }),
    valid: (0, _attr.default)('boolean'),
    paid: (0, _attr.default)('boolean'),
    amount: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    amountUntaxed: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    supplier: (0, _relationships.belongsTo)('supplier'),
    file: (0, _relationships.belongsTo)('project'),
    vat: (0, _relationships.belongsTo)('vat'),
    items: (0, _relationships.hasMany)('fileProposalItem'),
    toDelete: {
      proposalItem: Ember.A()
    },
    productResultArray: Ember.computed.mapBy('items.content', 'productResult'),
    productResultNameArray: Ember.computed.mapBy('productResultArray', 'name'),
    productResults: Ember.computed('productResultNameArray', function () {
      let productResultArray = this.get('productResultNameArray');
      let productResults = '';
      let ar = [];
      let v = '';
      productResultArray.forEach((item, i) => {
        if (i > 0) v = ',';

        if (ar.indexOf(item) === -1) {
          productResults += v + item;
          ar.push(item);
        }
      });
      return productResults;
    })
  });

  _exports.default = _default;
});