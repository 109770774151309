define("iris/templates/reports/pentaho", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+gV1SNPi",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"type\"]],\"xjpivot\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"iframe\",true],[10,\"id\",\"pentaho-iframe\"],[11,\"src\",[29,[[24,[\"model\",\"url\"]],\"/plugin/jpivot/Pivot?solution=&path=\",[24,[\"model\",\"path\"]],\"&action=\",[24,[\"model\",\"name\"]]]]],[10,\"height\",\"800\"],[10,\"width\",\"100%\"],[10,\"frameBorder\",\"0\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"type\"]],\"saiku\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"iframe\",true],[10,\"id\",\"pentaho-iframe\"],[11,\"src\",[29,[[24,[\"model\",\"url\"]],\"/content/saiku-ui/index.html?biplugin5=true&dimension_prefetch=false#query/open/\",[24,[\"model\",\"pathEncode\"]]]]],[10,\"height\",\"800\"],[10,\"width\",\"100%\"],[10,\"frameBorder\",\"0\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"iframe\",true],[10,\"id\",\"pentaho-iframe\"],[11,\"src\",[29,[[24,[\"model\",\"url\"]],\"/api/repos/\",[24,[\"model\",\"pathEncode\"]],\"/viewer\"]]],[10,\"height\",\"1200\"],[10,\"width\",\"100%\"],[10,\"frameBorder\",\"0\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/reports/pentaho.hbs"
    }
  });

  _exports.default = _default;
});