define("iris/helpers/next-cron", ["exports", "moment", "cron-parser"], function (_exports, _moment, _cronParser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(_ref) {
      let [cron] = _ref;

      const interval = _cronParser.default.parseExpression(cron);

      return (0, _moment.default)(interval.next().toString()).format('L LT');
    }

  });

  _exports.default = _default;
});