define("iris/components/form/field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    model: null,
    // DS.Model
    property: null,
    // string
    name: null,

    // value -> defined at init
    // validation -> defined at init
    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'value', Ember.computed.alias(`model.${this.property}`));
      Ember.defineProperty(this, 'validation', Ember.computed.reads(`model.validations.attrs.${this.property}`));
      this.set('name', `${this.property}-${Ember.guidFor(this)}`);
    },

    hasContent: Ember.computed.notEmpty('value'),
    notValidating: Ember.computed.not('validation.isValidating'),
    isValid: Ember.computed.and('hasContent', 'validation.isValid', 'notValidating'),
    isInvalid: Ember.computed.reads('validation.isInvalid'),
    showErrorClass: Ember.computed.and('notValidating', 'hasContent', 'validation', 'didValidate'),
    hasError: Ember.computed.and('isInvalid', 'model.didValidate'),
    errorMessage: Ember.computed('validation.{messages,isDirty}', 'hasError', function () {
      if (!this.validation || !this.hasError) {
        return null;
      }

      if ((this.validation.isDirty || this.model.didValidate) && this.isInvalid) {
        return this.validation.messages;
      }

      return null;
    })
  });

  _exports.default = _default;
});