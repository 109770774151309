define("iris/models/g-model", ["exports", "ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import attr from 'ember-data/attr';
  // import { belongsTo, hasMany } from 'ember-data/relationships';
  var _default = _model.default.extend({});

  _exports.default = _default;
});