define("iris/templates/aiops/source-data/solvers/user-absences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YZIfXxws",
    "block": "{\"symbols\":[\"tab\",\"c\"],\"statements\":[[5,\"bs-tab\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"pane\"]],[],[[\"@title\",\"@icon\"],[[28,\"t\",[\"menus.list\"],null],\"fa fa-tasks\"]],{\"statements\":[[0,\"\\n    \"],[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\"],[\"aiops-user-absence\",\"aiops.source-data.solvers.user-absences.edit\",[28,\"array\",[\"day\",\"user\",\"dayType\",\"sourceId\",\"dtMode\",\"active\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,2,[\"column\",\"name\"]],\"dtModeId\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[11,\"class\",[29,[\"badge \",[28,\"if\",[[28,\"eq\",[[23,2,[\"row\",\"dtMode\",\"id\"]],\"M\"],null],\"badge-info\",\"badge-success\"],null]]]],[8],[0,\"\\n          \"],[1,[23,2,[\"row\",\"dtMode\",\"name\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,[23,2,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"]],\"parameters\":[2]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"pane\"]],[],[[\"@title\",\"@icon\"],[[28,\"t\",[\"projects.files.tabs.calendar\"],null],\"fa fa-calendar\"]],{\"statements\":[[0,\"\\n    \"],[5,\"aiops/user-absence-calendar\",[],[[],[]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/aiops/source-data/solvers/user-absences.hbs"
    }
  });

  _exports.default = _default;
});