define("iris/templates/settings/operations/report-types/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NnzaF2G9",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"isLoaded\"]]],null,{\"statements\":[[0,\"  \"],[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@proportion\"],[[23,0,[\"model\"]],\"settings.operations.report-types\",\"3-9\"]]],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/operations/report-types/edit.hbs"
    }
  });

  _exports.default = _default;
});