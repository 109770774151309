define("iris/components/forms/incoming-invoice-form/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IncomingInvoiceFormItem = (_dec = Ember.computed.oneWay('list'), _dec2 = Ember.computed('model.reverseInvoice', 'model.correctingInvoice'), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class IncomingInvoiceFormItem extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "_list", _descriptor, this);
    }

    get quantityMask() {
      if (this.get('model.reverseInvoice') || this.get('model.correctingInvoice')) {
        return '[-]9{*}.{*}9{*}';
      } else {
        return '9{*}.{*}9{*}';
      }
    }

    updateItemDescList(searchTerm, comp) {
      if (searchTerm.length > 3) {
        this.get('store').query('incomingInvoiceItem', {
          filter: {
            where: {
              description: {
                ilike: '%' + searchTerm + '%'
              }
            }
          }
        }).then(result => {
          let descriptions = result.map(item => {
            return item.get('description');
          });
          comp.set('list', descriptions.uniq());

          if (descriptions.length) {
            comp.set('open', true);
          }
        });
      }
    }

    async removeItem(item) {
      let items = await this.get('model.items');
      items.removeObject(item);
      item.destroyRecord();
    }

    async copyItem(item) {
      const copy = await item.copy();
      this.get('model.items').addObject(copy);
    }

    calcNetto(quantity, price, discount, vat, isSimplified) {
      if (isSimplified) {
        return (quantity * price - discount) / (1 + vat);
      } else {
        return quantity * price;
      }
    }

    calcGross(quantity, price, discount, vat, isSimplified) {
      let net = quantity * price - discount;

      if (isSimplified) {
        return net;
      } else {
        net = Math.round(net);
        return Math.round(net + net * vat);
      }
    }

    enableRow() {
      this.set('list', false);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_list", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "quantityMask", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "quantityMask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateItemDescList", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateItemDescList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeItem", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "removeItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copyItem", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "copyItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "calcNetto", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "calcNetto"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "calcGross", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "calcGross"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "enableRow", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "enableRow"), _class.prototype)), _class));
  _exports.default = IncomingInvoiceFormItem;
});