define("iris/lib/filters/filter", ["exports", "nanoid", "iris/lib/filters/column", "iris/lib/filters/data", "iris/lib/filters/types"], function (_exports, _nanoid, _column, _data, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transferFilterValue = _exports.symbolForFilterType = _exports.serializeToQueryParam = _exports.selectFilterType = _exports.makeFiltersFromQueryParams = _exports.makeFilterFromColumn = _exports.makeCustomAttributeFilter = _exports.isValidFilter = _exports.getFilterDefinitionsBy = _exports.findInputType = _exports.findFilterDefinitionBy = _exports.filterTypesForAttributeType = _exports.defaultValueForAttributeType = void 0;
  const NANO_ID_LENGTH = 10;
  /**
   * Determines if the passed query param filter is a custom attribute filter or
   * not.
   */

  const isCustomAttributeFilter = qpFilter => {
    return typeof qpFilter.customAttribute === 'string';
  };
  /**
   * What the function name says: it'll create actual filters from query param
   * filter data. When a column isn't found, it'll just skip that completely.
   * Handles both column & attribute filters.
   */


  const makeFiltersFromQueryParams = (columns, queryParamFilters) => {
    if (!queryParamFilters) {
      return [];
    }

    return queryParamFilters.reduce((acc, qpFilter) => {
      if (isCustomAttributeFilter(qpFilter)) {
        let {
          id,
          type: filterType,
          customAttribute,
          attributeType,
          label,
          value
        } = qpFilter;
        let filter = makeCustomAttributeFilter({
          id,
          attributeName: customAttribute,
          displayName: label,
          filterType,
          attributeType,
          value
        });
        acc.push(filter);
      } else {
        let {
          id,
          name,
          type: filterType,
          value
        } = qpFilter;
        let column = (0, _column.lookupColumn)(columns, name);

        if (column) {
          let filter = makeFilterFromColumn(column, {
            id: generateNanoId(id),
            filterType,
            value
          });
          acc.push(filter);
        }
      }

      return acc;
    }, []);
  };
  /**
   * Creates custom attribute filters from the `data` argument's properties. Since
   * custom attribute filters doesn't have column data look up, slightly more info
   * must be provided to construct one.
   */


  _exports.makeFiltersFromQueryParams = makeFiltersFromQueryParams;

  const makeCustomAttributeFilter = data => {
    var _data$optionsResource, _data$optionsResource2, _data$options, _data$optionsQuery;

    let id = generateNanoId(data.id);
    let placement = _types.AttributePlacement.CustomAttribute;
    let filterType = data.filterType;

    if (!filterType) {
      let [firstFilterType] = filterTypesForAttributeType(data.attributeType);
      filterType = firstFilterType;
    }

    let inputType = findInputType(data.attributeType, filterType);
    let optionsResourceName = (_data$optionsResource = data.optionsResourceName) !== null && _data$optionsResource !== void 0 ? _data$optionsResource : data.attributeName.replace(/Id$/, '');
    let optionsResourceAction = (_data$optionsResource2 = data.optionsResourceAction) !== null && _data$optionsResource2 !== void 0 ? _data$optionsResource2 : _types.OptionsResourceAction.FindAll;
    let options = (_data$options = data.options) !== null && _data$options !== void 0 ? _data$options : null;
    let optionsQuery = (_data$optionsQuery = data.optionsQuery) !== null && _data$optionsQuery !== void 0 ? _data$optionsQuery : null;
    return {
      id,
      attributeName: data.attributeName,
      displayName: data.displayName,
      placement,
      attributeType: data.attributeType,
      filterType,
      inputType,
      value: data.value,
      options,
      optionsResourceName,
      optionsResourceAction,
      optionsNameKey: _data.DEFAULT_OPTIONS_NAME_KEY,
      createOptions: undefined,
      optionsQuery,
      inputComponentName: null,
      formatLoopBackValue: undefined,
      fuzzySearch: false,
      dependsOn: [],
      belongsTo: []
    };
  };
  /**
   * Passing a `column` and the `filterOptions` will return a filter constructed
   * by the configuration the column provides, setting all the default values.
   */


  _exports.makeCustomAttributeFilter = makeCustomAttributeFilter;

  const makeFilterFromColumn = function (column) {
    var _fuzzySearch, _optionsResourceName, _options, _optionsQuery, _optionsNameKey, _optionsResourceActio;

    let filterOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let {
      name: attributeName,
      displayName,
      type: attributeType,
      fuzzySearch,
      options,
      optionsResourceName,
      optionsResourceAction,
      optionsNameKey,
      optionsQuery,
      createOptions,
      inputComponentName,
      filterTypes,
      formatLoopBackValue,
      dependsOn,
      belongsTo
    } = column;
    let placement = _types.AttributePlacement.Column;
    let filterType = filterOptions.filterType;

    if (!filterType) {
      let [firstFilterType] = filterTypesForAttributeType(attributeType);
      filterType = firstFilterType;
    }

    let inputType = findInputType(attributeType, filterType);
    let value = defaultValueForAttributeType(attributeType);

    if (filterOptions.value !== undefined) {
      let filterDefinition = findFilterDefinitionBy({
        attributeType,
        filterType
      });
      value = filterDefinition.deserializeQueryParamValue ? filterDefinition.deserializeQueryParamValue(filterOptions.value, column) : filterOptions.value;
    }

    fuzzySearch = (_fuzzySearch = fuzzySearch) !== null && _fuzzySearch !== void 0 ? _fuzzySearch : true;
    optionsResourceName = (_optionsResourceName = optionsResourceName) !== null && _optionsResourceName !== void 0 ? _optionsResourceName : attributeName.replace(/Id$/, '');
    options = (_options = options) !== null && _options !== void 0 ? _options : null;
    optionsQuery = (_optionsQuery = optionsQuery) !== null && _optionsQuery !== void 0 ? _optionsQuery : null;
    optionsNameKey = (_optionsNameKey = optionsNameKey) !== null && _optionsNameKey !== void 0 ? _optionsNameKey : _data.DEFAULT_OPTIONS_NAME_KEY;
    optionsResourceAction = (_optionsResourceActio = optionsResourceAction) !== null && _optionsResourceActio !== void 0 ? _optionsResourceActio : _types.OptionsResourceAction.FindAll;
    let id = generateNanoId(filterOptions.id);
    return {
      id,
      attributeName,
      displayName,
      placement,
      attributeType,
      filterType,
      inputType,
      fuzzySearch,
      options,
      optionsResourceName,
      optionsResourceAction,
      optionsNameKey,
      optionsQuery,
      createOptions,
      inputComponentName,
      filterTypes,
      formatLoopBackValue,
      dependsOn,
      belongsTo,
      ...filterOptions,
      value
    };
  };
  /**
   * UI symbol for a given filter type
   */


  _exports.makeFilterFromColumn = makeFilterFromColumn;

  const symbolForFilterType = filterType => {
    let filterTypeSymbolMap = new Map();
    filterTypeSymbolMap.set(_types.FilterType.Boolean, ''); // Only the value makes sense here

    filterTypeSymbolMap.set(_types.FilterType.Contains, '≈');
    filterTypeSymbolMap.set(_types.FilterType.ContainsAny, '∈');
    filterTypeSymbolMap.set(_types.FilterType.Equal, '=');
    filterTypeSymbolMap.set(_types.FilterType.NotEqual, '≠');
    filterTypeSymbolMap.set(_types.FilterType.GreaterThan, '>');
    filterTypeSymbolMap.set(_types.FilterType.LessThan, '<');
    filterTypeSymbolMap.set(_types.FilterType.NotNull, '∃');
    filterTypeSymbolMap.set(_types.FilterType.Null, '∅');
    return filterTypeSymbolMap.get(filterType);
  };
  /**
   * Returns all filter types for an attribute type, for example it should return
   * `Contains`, `Equal`, `NotNull`, `Null` for the `String` attribute type.
   */


  _exports.symbolForFilterType = symbolForFilterType;

  const filterTypesForAttributeType = attributeType => {
    return getFilterDefinitionsBy({
      attributeType
    }).map(fd => fd.filterType);
  };

  _exports.filterTypesForAttributeType = filterTypesForAttributeType;

  const findInputType = (attributeType, filterType) => {
    let filterDefinition = _data.FILTER_DEFINITIONS.find(def => def.attributeType === attributeType && def.filterType === filterType);

    if (!filterDefinition) {
      throw new Error(`Cannot find filter definition for attributeType "${attributeType}" and filterType "${filterType}".`);
    }

    return filterDefinition.inputType;
  };
  /**
   * Returns the first filter definition (from the `FILTER_DEFINITIONS` data
   * constant) that matches all properties of the object it was passed to the
   * function. It throws an error when no match can be found.
   */


  _exports.findInputType = findInputType;

  const findFilterDefinitionBy = properties => {
    let entries = Object.entries(properties);

    let filterDefinition = _data.FILTER_DEFINITIONS.find(def => {
      for (let [key, value] of entries) {
        // @ts-ignore
        if (def[key] !== value) {
          return false;
        }
      }

      return true;
    });

    if (!filterDefinition) {
      throw new Error(`Cannot find filter definition for ${JSON.stringify(properties)}.`);
    }

    return filterDefinition;
  };
  /**
   * Mutates the filter to have the passed `FilterType`. It involves setting the
   * `inputTypes` and a default `value` as well. Will only change to the default
   * value when the new input type is different than the previous. Ex. it won't
   * change the value when the `FilterType` is changed from `Contains` to `Equal`
   * for strings.
   */


  _exports.findFilterDefinitionBy = findFilterDefinitionBy;

  const selectFilterType = (filter, filterType) => {
    let previousInputType = filter.inputType;
    let filterDefinition = findFilterDefinitionBy({
      attributeType: filter.attributeType,
      filterType
    });
    filter.filterType = filterType;
    filter.inputType = filterDefinition.inputType;

    if (filterDefinition.inputType !== previousInputType) {
      filter.value = defaultValueForAttributeType(filter.attributeType);
    }

    return filter;
  };
  /**
   * Transfers the filter value from one filter to another, if possible. The
   * transferability is decided by checking the `acceptsValueFrom` property of the
   * filter definition. It is mainly used for replacing filter attributes. Ex.
   * when replacement has the same `AttributeType`, it can likely be transferred,
   * as long as their `FilterType`s are matching.
   */


  _exports.selectFilterType = selectFilterType;

  const transferFilterValue = (sourceFilter, targetFilter) => {
    let {
      attributeType: sourceAttributeType,
      filterType: sourceFilterType
    } = sourceFilter;
    let {
      attributeType: targetAttributeType,
      filterType: targetFilterType
    } = targetFilter;
    let targetFilterDefinition = findFilterDefinitionBy({
      attributeType: targetAttributeType,
      filterType: targetFilterType
    });

    for (let criteria of targetFilterDefinition.acceptsValueFrom) {
      if (criteria.attributeType === sourceAttributeType && (!criteria.filterType || criteria.filterType === sourceFilterType || criteria.filterType.includes(sourceFilterType))) {
        targetFilter.value = criteria.converter ? criteria.converter(sourceFilter.value) : sourceFilter.value;
      }
    }

    return targetFilter;
  };
  /**
   * Finds the first filter definition from the `FILTER_DEFINITIONS` data constant
   * when all of the provided properties match.
   */


  _exports.transferFilterValue = transferFilterValue;

  const getFilterDefinitionsBy = properties => {
    let entries = Object.entries(properties);
    return _data.FILTER_DEFINITIONS.filter(def => {
      for (let [key, value] of entries) {
        // @ts-ignore
        if (def[key] !== value) {
          return false;
        }
      }

      return true;
    });
  };
  /**
   * Serializes a filter to query param format. Technically it can be stored
   * anywhere, it's just that it should contain all information the filter can be
   * rebuilt from later.
   */


  _exports.getFilterDefinitionsBy = getFilterDefinitionsBy;

  const serializeToQueryParam = filters => {
    let serialized = filters.reduce((acc, filter) => {
      if (!isValidFilter(filter)) {
        return acc;
      }

      if (filter.placement === _types.AttributePlacement.Column) {
        let {
          attributeType,
          filterType
        } = filter;
        let filterDefinition = findFilterDefinitionBy({
          attributeType,
          filterType
        });
        filterDefinition.serializeQueryParamValue ? filterDefinition.serializeQueryParamValue(filter.value) : filter.value;
        let value = filter.attributeType === _types.AttributeType.Array && filter.filterType === _types.FilterType.ContainsAny ? filter.value.map(_ref => {
          let {
            id
          } = _ref;
          return id;
        }) : filter.value;
        acc.push({
          id: filter.id,
          name: filter.attributeName,
          type: filter.filterType,
          value
        });
      } else {
        acc.push({
          id: filter.id,
          type: filter.filterType,
          value: filter.value,
          attributeType: filter.attributeType,
          customAttribute: filter.attributeName,
          label: filter.displayName
        });
      }

      return acc;
    }, []);
    return serialized.length > 0 ? serialized : null;
  };
  /**
   * Validates a filter given its `FilterType` and value. Comes handy when
   * serializing filters into query params and would want to skip the ones that
   * prove to be invalid.
   */


  _exports.serializeToQueryParam = serializeToQueryParam;

  const isValidFilter = _ref2 => {
    let {
      filterType,
      value
    } = _ref2;

    if (filterType === _types.FilterType.Boolean) {
      return typeof value === 'boolean';
    } else if ([_types.FilterType.Null, _types.FilterType.NotNull].includes(filterType)) {
      return typeof value === 'object' && value === null;
    }

    return value !== undefined && value !== null;
  };

  _exports.isValidFilter = isValidFilter;

  const defaultValueForAttributeType = attributeType => {
    if (attributeType === _types.AttributeType.Boolean) {
      return true;
    }

    if (attributeType === _types.AttributeType.Array) {
      return [];
    }

    return null;
  };
  /**
   * Generates a random ID used at filter construction with a preconfigured ID
   * length to keep URLs as short as possible and still get the benefit of unique,
   * non-increment IDs.
   */


  _exports.defaultValueForAttributeType = defaultValueForAttributeType;

  const generateNanoId = id => id !== null && id !== void 0 ? id : (0, _nanoid.nanoid)(NANO_ID_LENGTH);
});