define("iris/components/aiops/dashboards/competency/top-solvers/component", ["exports", "iris/utils/properties"], function (_exports, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    from: moment().add(-1, 'M'),
    to: moment(),
    timestamp: moment(),
    topSolvers: (0, _properties.computedTask)('timestamp', 'model.id', function* () {
      return yield this.ajax.call('POST', 'AiopsPartner', '', 'topSolvers', {
        competencyId: this.model.id,
        from: this.from,
        to: this.to
      }, true);
    }),
    actions: {
      getData() {
        this.set('timestamp', moment());
      }

    }
  });

  _exports.default = _default;
});