define("iris/templates/components/forms/settings-operations-report-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FMiUl8RX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-form\",null,[[\"model\"],[[24,[\"model\"]]]],{\"statements\":[[4,\"form-group\",null,[[\"property\",\"label\"],[\"title\",[28,\"t\",[\"general.name\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"name\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\"],[\"scheduledTask\",[28,\"t\",[\"forms.timed\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-checkbox\",null,[[\"value\"],[[24,[\"model\",\"scheduledTask\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/settings-operations-report-types.hbs"
    }
  });

  _exports.default = _default;
});