define("iris/components/bs-table/column-filter", ["exports", "iris/classes/filter/filter"], function (_exports, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    selectedSaveableFilter: null,
    saveSaveableFilterModal: false,
    apiCalls: Ember.on('init', function () {
      this.get('store').query('filter', {
        filter: {
          where: {
            and: [{
              or: [{
                userId: this.get('currentUserId')
              }, {
                userId: null
              }]
            }, {
              target: this.get('currentPathName')
            }]
          }
        }
      }).then(filters => {
        if (!this.get('isDestroyed') && !this.get('isDestroying')) {
          this.set('saveableFilters', filters);
          let defaultSaveableFilter = filters.find(filter => filter.get('isDefault'));

          if (defaultSaveableFilter) {
            this.send('saveableFilterSelected', defaultSaveableFilter);
          } else {
            this.send('columnSearch');
          }

          this.set('defaultSaveableFilter', defaultSaveableFilter);
        }
      });
    }),
    defaultSaveableFilter: null,
    saveableFilters: [],
    currentUserId: Ember.computed.alias('session.data.authenticated.userId'),
    currentPathName: Ember.computed(function () {
      let currentPath = Ember.getOwner(this).lookup('controller:application').currentPath.split('.');
      return currentPath[currentPath.length - 1] === 'index' ? currentPath[currentPath.length - 2] : currentPath[currentPath.length - 1];
    }),
    normalFilters: Ember.computed('columnFilterOptions', function () {
      let columnFilterOptions = this.get('columnFilterOptions');

      if (columnFilterOptions === null || columnFilterOptions.length === 0) {
        return Ember.A();
      }

      let normalFilters = Ember.A();
      columnFilterOptions.forEach(cfo => {
        if (!cfo.fieldSet) {
          normalFilters.push(cfo);
        }
      });
      return normalFilters;
    }),
    //FieldSets
    fieldSetFilters: Ember.computed('columnFilterOptions', function () {
      let columnFilterOptions = this.get('columnFilterOptions');

      if (columnFilterOptions === null || columnFilterOptions.length === 0) {
        return Ember.A();
      }

      let fieldSetFilters = Ember.A();
      columnFilterOptions.forEach(cfo => {
        if (cfo.fieldSet) {
          fieldSetFilters.push(cfo);
        }
      });
      return fieldSetFilters;
    }),
    defaultSaveableFilterWarning: Ember.computed('selectedSaveableFilter', 'selectedSaveableFilter.isDefault', function () {
      let defaultSaveableFilter = this.get('defaultSaveableFilter');
      let selectedSaveableFilter = this.get('selectedSaveableFilter');

      if (defaultSaveableFilter && selectedSaveableFilter.get('isDefault') && defaultSaveableFilter.get('id') !== selectedSaveableFilter.get('id')) {
        return `Már van egy alapértelmezett szűrő <i>${defaultSaveableFilter.get('name')}</i> néven. Ha folytatja annak a szűrőnek megszűnik az alapértelmezése!`;
      }

      return false;
    }),
    actions: {
      columnSearch() {
        this.get('columnSearch')();
      },

      deleteFilter() {
        this.get('deleteFilter')();
      },

      toggleFilters() {
        this.notifyPropertyChange('columnFilterOptions');
        this.toggleProperty('filterOpen');
      },

      saveableFilterSelected(selected) {
        if (selected === null) {
          this.set('selectedSaveableFilter', null);
          this.send('deleteFilter', true);
        } else {
          this.set('selectedSaveableFilter', selected);
          let value = JSON.parse(selected.get('value'));
          this.set('globalSearchTerm', value.globalSearchTerm);
          let columnFilterOptions = this.get('columnFilterOptions');

          for (let key in value.selectionOptions) {
            for (let i = 0; i < columnFilterOptions.length; i++) {
              if (columnFilterOptions[i].property === key || columnFilterOptions[i].saveProperty === key) {
                Ember.set(columnFilterOptions[i], 'selected', value.selectionOptions[key]);
                break;
              }
            }
          }

          for (let key in value.searchOptions) {
            for (let i = 0; i < columnFilterOptions.length; i++) {
              if (columnFilterOptions[i].property === key || columnFilterOptions[i].saveProperty === key) {
                Ember.set(columnFilterOptions[i], 'searchTerm', value.searchOptions[key]);
                break;
              }
            }
          }

          for (let key in value.dateOptions) {
            for (let i = 0; i < columnFilterOptions.length; i++) {
              if (columnFilterOptions[i].property + columnFilterOptions[i].operator === key) {
                Ember.set(columnFilterOptions[i], 'date', moment(value.dateOptions[key].date));
                break;
              }
            }
          }

          for (let i = 0; i < columnFilterOptions.length; i++) {
            if (columnFilterOptions[i].type === 'checkbox' || columnFilterOptions[i].type === 'checkboxWithValue') {
              Ember.set(columnFilterOptions[i], 'value', false);
            }
          }

          for (let key in value.checkboxOptions) {
            for (let i = 0; i < columnFilterOptions.length; i++) {
              if (columnFilterOptions[i].property === key || columnFilterOptions[i].saveProperty === key) {
                Ember.set(columnFilterOptions[i], 'value', 1);
                break;
              }
            }
          }

          if (value.customFilters) {
            let owner = Ember.getOwner(this);
            let customFilters = value.customFilters.map(f => {
              let eaf = _filter.default.create();

              Ember.setOwner(eaf, owner);
              return eaf.deserialize(f);
            });
            this.updateCustomFilters(Ember.A(customFilters));
          }

          this.send('columnSearch');
        }
      },

      async openSaveFilterModal() {
        let selectedFilter = this.get('selectedSaveableFilter');

        if (selectedFilter === null) {
          let filter = await this.get('store').createRecord('filter');
          this.set('selectedSaveableFilter', filter);
        }

        this.set('saveSaveableFilterModal', true);
      },

      async beforeSaveSaveableFilter(record, cb) {
        record.set('target', this.get('currentPathName'));
        let mappedSelectionOptions = {};
        let mappedDateOptions = {};
        let mappedSearchOptions = {};
        let mappedcheckboxOptions = {};
        this.get('columnFilterOptions').forEach(cfo => {
          let property = cfo.property || cfo.saveProperty;

          if (property) {
            if (cfo.selected) {
              mappedSelectionOptions[property] = cfo.selected;
            }

            if (cfo.type && cfo.type === 'date') {
              mappedDateOptions[property + cfo.operator] = {
                operator: cfo.operator,
                date: cfo.date
              };
            }

            if (cfo.searchTerm && cfo.searchTerm !== '') {
              mappedSearchOptions[property] = cfo.searchTerm;
            }

            if ((cfo.type === 'checkbox' || cfo.type === 'checkboxWithValue') && cfo.value) {
              mappedcheckboxOptions[property] = cfo.value;
            }
          }
        });
        let value = {
          globalSearchTerm: this.get('globalSearchTerm'),
          selectionOptions: mappedSelectionOptions,
          dateOptions: mappedDateOptions,
          searchOptions: mappedSearchOptions,
          checkboxOptions: mappedcheckboxOptions,
          customFilters: this.customFilters.map(f => f.serialize()).filter(Boolean)
        };
        record.set('value', JSON.stringify(value));
        this.notifyPropertyChange('saveableFilters');
        this.set('saveSaveableFilterModal', false);
        cb();
      },

      back() {
        this.set('deleteConfirmation', false);
        this.set('saveSaveableFilterModal', false);
      },

      deleteSaveableFilter() {
        this.set('deleteConfirmation', true);
      },

      confirmDelete() {
        const flashMessages = Ember.get(this, 'flashMessages');
        let selectedFilter = this.get('selectedSaveableFilter');
        selectedFilter === null || selectedFilter === void 0 ? void 0 : selectedFilter.destroyRecord(true).then(() => {
          this.set('selectedSaveableFilter', null);
          this.notifyPropertyChange('saveableFilters');
          this.send('deleteFilter');
          this.set('deleteConfirmation', false);
          flashMessages.success(`Sikeres szűrő törlés`, {
            title: 'Szűrő',
            icon: 'check',
            sticky: false
          });
        }).catch(error => {
          flashMessages.danger(error, {
            title: 'Hiba',
            icon: 'times',
            sticky: true
          });
        });
      }

    }
  });

  _exports.default = _default;
});