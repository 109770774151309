define("iris/components/tables/file-outgoing-proposal-items", ["exports", "ember-concurrency", "iris/utils/model"], function (_exports, _emberConcurrency, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OutgoingProposalItem = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed, _dec4 = Ember.computed, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class OutgoingProposalItem extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "toEdit", null);

      _defineProperty(this, "editedProperties", null);

      _defineProperty(this, "editedRelationships", null);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "modals", _descriptor2, this);
    }

    get uoms() {
      return this.get('store').findAll('uom');
    }

    get vats() {
      return this.get('store').findAll('vat');
    }

    *setOutgoinProposal(proposal) {
      yield proposal.recalculate();
    }

    *setOutgoinProposalItem(item) {
      yield item.recalculate();
      const proposal = yield item.get('proposal');
      yield this.setOutgoinProposal.perform(proposal);
    }

    editRow(record) {
      this.set('toEdit', record);
      this.set('editedProperties', Ember.getProperties(record, [...record.constructor.attributes.keys()]));
      const relations = {};
      record.eachRelationship((name, descriptor) => {
        if (descriptor.kind === 'belongsTo') {
          relations[name] = record.belongsTo(name).value();
        }
      });
      this.set('editedRelationships', relations);
    }

    rollback(item) {
      item.setProperties(this.editedProperties);

      for (const [name, value] of Object.entries(this.editedRelationships)) {
        item.set(name, value);
      }

      this.set('toEdit', null);
    }

    async endEdit() {
      const {
        model,
        validations
      } = await (0, _model.validateRecord)(this.toEdit);
      model.setValidated();

      if (!validations.isValid) {
        return;
      }

      this.set('toEdit', null);
    }

    async changePrice(item, price) {
      item.set('price', price);
      const exchangeRate = item.get('proposal.exchangeRate');
      item.set('defaultAmount', item.get('price') * exchangeRate);
      await this.setOutgoinProposalItem.perform(item);
    }

    async changeQuantity(item, quantity) {
      item.set('quantity', quantity);
      await this.setOutgoinProposalItem.perform(item);
    }

    async changeVat(item, vat) {
      item.set('vat', vat);
      await this.setOutgoinProposalItem.perform(item);
    }

    async changeDiscount(item, discount) {
      item.set('discount', discount);
      await this.setOutgoinProposalItem.perform(item);
    }

    async deleteRow(record) {
      const {
        flashMessages,
        intl,
        modals
      } = this;
      let shouldDelete = await modals.open('beta/modals/delete-confirmation');

      if (shouldDelete === false) {
        return;
      }

      const res = await record.destroyRecord(true);

      if (res) {
        flashMessages.info(intl.t('general.delete.success'), {
          title: intl.t('general.delete.success-title'),
          icon: 'times'
        });
      } else {
        flashMessages.danger(intl.t('general.delete.error'), {
          title: intl.t('general.delete.error'),
          icon: 'warning'
        });
      }

      await this.model.recalculate();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "uoms", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "uoms"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "vats", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "vats"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setOutgoinProposal", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "setOutgoinProposal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setOutgoinProposalItem", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "setOutgoinProposalItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editRow", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "editRow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "rollback", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "rollback"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "endEdit", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "endEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changePrice", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "changePrice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeQuantity", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "changeQuantity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeVat", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "changeVat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeDiscount", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "changeDiscount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteRow", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "deleteRow"), _class.prototype)), _class));
  _exports.default = OutgoingProposalItem;
});