define("iris/validators/planned-expenses", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    intl: Ember.inject.service(),

    validate(value, options, model, attribute) {
      if (model.get('ticket.activeTandm.id')) {
        return true;
      }

      if (value < 1) {
        return this.intl.t('validators.plannedExpenses.zeroExpense');
      }

      let plannedExpensesInHour = model.get('ticket.plannedExpensesInHour');
      let allTimeSum = 0;

      if (model.get('ticket.ticketUserAssocs.length')) {
        allTimeSum = model.get('ticket.ticketUserAssocs').reduce((prev, current) => {
          return current === model ? prev : prev + parseFloat(current.get('plannedExpensesInHour'));
        }, 0);
      }

      if (!Ember.isEmpty(value)) {
        if (isNaN(value)) {
          return this.intl.t('validators.plannedExpenses.mustBeNumber');
        } else {
          return plannedExpensesInHour - allTimeSum - parseFloat(value) < 0 ? this.intl.t('validators.plannedExpenses.expendedOverPlanned') : true;
        }
      } else {
        return this.intl.t('validators.plannedExpenses.presence');
      }
    }

  });

  _exports.default = _default;
});