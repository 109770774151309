define("iris/models/aiops-user-absence", ["exports", "iris/models/aiops-base", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _aiopsBase, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    day: [(0, _emberCpValidations.validator)('presence', true)],
    user: [(0, _emberCpValidations.validator)('presence', true)],
    dayType: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _aiopsBase.default.extend(Validations, {
    day: (0, _attr.default)('date'),
    user: (0, _relationships.belongsTo)('aiopsSolver'),
    dayType: (0, _relationships.belongsTo)('aiopsDayType')
  });

  _exports.default = _default;
});