define("iris/components/input/color-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "34TtIL8J",
    "block": "{\"symbols\":[\"color\",\"@disabled\"],\"statements\":[[0,\"\\n\"],[5,\"power-select\",[],[[\"@options\",\"@selected\",\"@disabled\",\"@onChange\",\"@allowClear\",\"@searchEnabled\"],[[23,0,[\"colors\"]],[23,0,[\"value\"]],[23,2,[]],[28,\"action\",[[23,0,[]],\"changeColor\"],null],false,false]],{\"statements\":[[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"py-2 leading-normal\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"inline-flex items-center gap-4 h-10\"],[8],[0,\"\\n      \"],[7,\"span\",true],[11,\"style\",[28,\"concat\",[\"width:22px; height:12px; background-color:\",[23,1,[]]],null]],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/input/color-picker/template.hbs"
    }
  });

  _exports.default = _default;
});