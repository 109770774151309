define("iris/helpers/entries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.entries = entries;

  function entries(_ref) {
    let [object] = _ref;

    if (!object) {
      return object;
    }

    return Object.entries(object);
  }

  var _default = Ember.Helper.helper(entries);

  _exports.default = _default;
});