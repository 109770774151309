define("iris/components/beta/filters/manager/component", ["exports", "@bower_components/file-saver", "ember-changeset", "ember-concurrency", "fast-deep-equal", "iris/classes/filter/filter", "iris/lib/filters", "iris/lib/filters/storage", "iris/lib/filters/types", "iris/lib/filters/utils", "iris/lib/grid", "moment"], function (_exports, _fileSaver, _emberChangeset, _emberConcurrency, _fastDeepEqual, _filter, _filters, _storage, _types, _utils, _grid, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    storage: null,
    // @, Storage
    columns: null,
    // @, array
    rows: null,
    // @, array
    predefinedViews: null,
    params: Ember.computed.alias('storage.params'),
    newFilter: null,
    selectedFilterId: null,
    savedTabs: null,
    adHocFilters: null,
    api: Ember.inject.service(),
    modals: Ember.inject.service(),
    intl: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.loadSavedTabs.perform();
    },

    currentRouteName: Ember.computed.alias('storage.router.currentRouteName'),
    filterColumns: Ember.computed('columns.[]', function () {
      return (0, _filters.filterByVisiblity)(this.columns, _types.AttributeVisibility.Filter);
    }),
    // No `filterBy` macro in ember-source@3.5
    // eslint-disable-next-line ember/require-computed-macros
    fuzzyColumns: Ember.computed('filterColumns.@each.fuzzySearch', function () {
      return this.filterColumns.filterBy('fuzzySearch', true).filterBy('type', _filter.AttributeType.String);
    }),
    filters: Ember.computed('filterColumns', 'params.filters.[]', function () {
      return (0, _filters.makeFiltersFromQueryParams)(this.filterColumns, this.params.filters);
    }),
    loadSavedTabs: (0, _emberConcurrency.task)(function* () {
      const tabs = yield this.api.query('advancedFilter', {
        filter: {
          where: {
            route: this.currentRouteName
          },
          order: 'rank'
        }
      });
      const res = [];

      for (const tab of tabs.toArray()) {
        res.push({
          id: tab.id,
          name: tab.name,
          filters: JSON.parse(tab.filter),
          q: tab.q
        });
      }

      this.set('savedTabs', res);
      this.checkAdHocFilter();
    }),
    selectedFilter: Ember.computed('filters.@each.id', 'selectedFilterId', function () {
      if (!this.selectedFilterId) {
        return null;
      }

      return this.filters.find(filter => filter.id === this.selectedFilterId) || null;
    }),
    editedFilter: Ember.computed('newFilter', 'selectedFilter.id', function () {
      var _this$newFilter;

      return (_this$newFilter = this.newFilter) !== null && _this$newFilter !== void 0 ? _this$newFilter : this.selectedFilter;
    }),
    selectedFilterChangeset: Ember.computed('editedFilter', function () {
      if (this.editedFilter) {
        return new _emberChangeset.default(this.editedFilter);
      }

      return null;
    }),
    dependentFilters: Ember.computed('filters.[]', 'selectedFilter.{attributeName,name}', function () {
      if (!this.selectedFilter) {
        return [];
      }

      return this.filters.filter(f => (0, _utils.concat)(f.dependsOn, f.belongsTo).map(f => (0, _utils.getMappedConfigValues)(f)[0]).includes(this.selectedFilter.attributeName));
    }),

    addFilter(newFilter) {
      this.set('selectedFilterId', null);
      this.set('newFilter', newFilter);
    },

    updateFilters(qpFilters, q) {
      this.storage.update('filters', qpFilters);
      this.storage.update('page', _storage.DEFAULT_PAGE);
      this.storage.update('q', q);
      this.checkAdHocFilter();
    },

    selectFilter(filterId) {
      this.set('newFilter', null);
      this.set('selectedFilterId', filterId);
    },

    clearSelection() {
      this.set('selectedFilterId', null);
      this.set('newFilter', null);
    },

    async commit() {
      if (!this.editedFilter) {
        return;
      }

      await this.selectedFilterChangeset.save();
      let filters = this.filters;

      if (this.newFilter) {
        let {
          replaceFilterWithId
        } = this.newFilter;
        let filterToReplace = replaceFilterWithId ? this.filters.find(_ref => {
          let {
            id
          } = _ref;
          return id === replaceFilterWithId;
        }) : null;

        if (filterToReplace) {
          filters = replaceElement(this.filters, filterToReplace, this.newFilter);
        } else {
          filters.pushObject(this.newFilter);
        }

        this.set('newFilter', null);
      } else {
        filters = this.filters.filter(filter => !this.dependentFilters.includes(filter));
      }

      let qpFilters = (0, _filters.serializeToQueryParam)(filters);
      this.updateFilters(qpFilters);
      this.clearSelection();
    },

    removeSelectedFilter() {
      if (this.newFilter) {
        this.set('newFilter', null);
      } else {
        let updatedFilters = replaceElement(this.filters, this.selectedFilter);
        this.set('selectedFilterId', null);
        let qpFilters = (0, _filters.serializeToQueryParam)(updatedFilters);
        this.updateFilters(qpFilters);
      }
    },

    removeFilter(filterId) {
      if (filterId === this.selectedFilterId) {
        this.set('selectedFilterId', null);
      }

      let updatedFilters = this.filters.filter(filter => filter.id !== filterId);
      let qpFilters = (0, _filters.serializeToQueryParam)(updatedFilters);
      this.updateFilters(qpFilters);
    },

    resetParams() {
      this.storage.reset();
    },

    async export() {
      let {
        headers,
        data
      } = (0, _grid.exportRows)(this.rows, this.columns);
      let csv = await (0, _grid.toCSV)(headers, data);
      let blob = new Blob([csv], {
        type: 'text/csv;charset=utf-8'
      });
      (0, _fileSaver.saveAs)(blob, `iris-export-${(0, _moment.default)().format('YYYY-MM-DD--HH-mm-ss')}.csv`);
    },

    checkAdHocFilter() {
      var _this$params$q;

      const {
        predefinedViews,
        savedTabs,
        filters
      } = this;

      if (filters.length === 0 && !this.params.q) {
        return this.set('adHocFilters', []);
      }

      const tabs = Array.isArray(predefinedViews) ? predefinedViews.map(f => {
        return {
          filters: f.filters,
          q: ''
        };
      }) : [];

      if (Array.isArray(savedTabs)) {
        for (const savedTab of savedTabs) {
          tabs.push({
            filters: savedTab.filters,
            q: savedTab.q
          });
        }
      }

      const propertiesToCompare = ['attributeName', 'filterType', 'value'];
      const activeFilters = filters.map(f => Ember.getProperties(f, propertiesToCompare));
      activeFilters.push((_this$params$q = this.params.q) !== null && _this$params$q !== void 0 ? _this$params$q : '');
      const match = tabs.find(tab => {
        var _tab$q;

        const tabFilter = (0, _filters.makeFiltersFromQueryParams)(this.columns, tab.filters);
        const tabToCompare = tabFilter.map(f => Ember.getProperties(f, propertiesToCompare));
        tabToCompare.push((_tab$q = tab.q) !== null && _tab$q !== void 0 ? _tab$q : '');
        return (0, _fastDeepEqual.default)(tabToCompare, activeFilters);
      });

      if (match === undefined) {
        this.set('adHocFilters', [{
          name: this.intl.t('filters.new-filter'),
          filters: this.storage.params.filters,
          q: this.storage.params.q
        }]);
      }
    },

    async saveTab() {
      const tab = this.store.createRecord('advancedFilter', {
        name: this.intl.t('filters.new-filter'),
        route: this.currentRouteName,
        rank: this.savedTabs.length,
        filter: JSON.stringify(this.storage.params.filters),
        q: this.storage.params.q
      });
      const shouldSave = await this.modals.open('beta/filters/modals/edit-tab', {
        title: this.intl.t('filters.save-tab'),
        tab
      });

      if (shouldSave) {
        const success = await tab.save();

        if (success) {
          this.flashMessages.info(this.intl.t('general.save.success'), {
            title: this.intl.t('general.save.success-title'),
            icon: 'floppy-o'
          });
          this.set('adHocFilters', []);
          this.loadSavedTabs.perform();
        }
      }
    },

    async editTab(tab) {
      const tabRecord = this.store.peekRecord('advancedFilter', tab.id);
      const shouldSave = await this.modals.open('beta/filters/modals/edit-tab', {
        title: this.intl.t('filters.save-tab'),
        tab: tabRecord
      });

      if (shouldSave) {
        const success = await tabRecord.save();

        if (success === false) {
          tabRecord.rollback();
        } else {
          this.flashMessages.info(this.intl.t('general.save.success'), {
            title: this.intl.t('general.save.success-title'),
            icon: 'floppy-o'
          });
        }

        this.loadSavedTabs.perform();
      }
    },

    async deleteTab(tab) {
      const shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

      if (shouldDelete === false) {
        return;
      }

      try {
        const filterRecord = this.store.peekRecord('advancedFilter', tab.id);
        await filterRecord.destroyRecord(true);
        this.flashMessages.info(this.intl.t('general.delete.success-title'), {
          title: this.intl.t('general.delete.success'),
          icon: 'times'
        });
        const afterRemove = this.savedTabs.filter(t => t !== tab);
        this.set('savedTabs', afterRemove);
        this.resetParams();
      } catch (error) {
        this.flashMessages.danger(this.intl.t('general.delete.error'));
      }
    },

    async sortSavedTabs() {
      const promises = [];

      for (const [index, tab] of Object.entries(this.savedTabs)) {
        const record = this.store.peekRecord('advancedFilter', tab.id);
        record.set('rank', index);
        promises.push(record.save());
      }

      try {
        await Promise.all(promises);
        this.flashMessages.info(this.intl.t('general.save.success'), {
          title: this.intl.t('general.save.success-title'),
          icon: 'floppy-o'
        });
      } catch (error) {
        this.flashMessages.danger(this.intl.t('general.save.error'));
      }
    },

    actions: {
      addFilter(newFilter) {
        this.addFilter(newFilter);
      },

      selectFilter(filterId) {
        this.selectFilter(filterId);
      },

      clearSelection() {
        this.clearSelection();
      },

      removeSelectedFilter() {
        this.removeSelectedFilter();
      },

      removeFilter(filterId) {
        this.removeFilter(filterId);
      },

      commit() {
        this.commit();
      },

      updateFilters(qpFilters, q) {
        this.updateFilters(qpFilters, q);
      },

      updateFuzzySearchParam(value) {
        this.storage.update('q', value);
        this.storage.update('page', _storage.DEFAULT_PAGE);
        this.checkAdHocFilter();
      },

      resetParams() {
        this.resetParams();
      },

      export() {
        this.export();
      },

      saveTab() {
        this.saveTab();
      },

      deleteTab(tab) {
        this.deleteTab(tab);
      },

      editTab(tab) {
        this.editTab(tab);
      },

      sortSavedTabs() {
        this.sortSavedTabs();
      }

    }
  });

  _exports.default = _default;

  const replaceElement = (array, item, replacement) => {
    let index = array.indexOf(item);

    if (index < 0) {
      return array;
    }

    return array.replace(index, 1, replacement ? [replacement] : []);
  };
});