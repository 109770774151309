define("iris/routes/settings/cmdb/entity-statuses", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EntityStatusesRoute extends Ember.Route.extend(_protectedRoute.default) {}

  _exports.default = EntityStatusesRoute;
});