define("iris/helpers/fetch-params", ["exports", "iris/lib/filters"], function (_exports, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fetchParams = fetchParams;

  // Comma dangle conflicts with prettier
  // eslint-disable-next-line @typescript-eslint/comma-dangle
  function fetchParams(_ref, _ref2) {
    let [columns, storageParams] = _ref;
    let {
      defaultFilters
    } = _ref2;
    return (0, _filters.makeQueryObject)(columns, storageParams, {
      defaultFilters
    });
  }

  var _default = Ember.Helper.helper(fetchParams);

  _exports.default = _default;
});