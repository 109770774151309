define("iris/models/file-outgoing-proposal", ["exports", "bankers-rounding", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-copyable", "ember-cp-validations"], function (_exports, _bankersRounding, _model, _attr, _relationships, _emberDataCopyable, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 100
    })],
    description: (0, _emberCpValidations.validator)('length', {
      max: 2000
    }),
    currency: [(0, _emberCpValidations.validator)('belongs-to'), (0, _emberCpValidations.validator)('presence', true)]
  });
  let now = new Date();
  let end = moment().add(2, 'w').toDate();

  var _default = _model.default.extend(_emberDataCopyable.default, Validations, {
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    createDate: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    fromDate: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    endDate: (0, _attr.default)('date', {
      defaultValue: function () {
        return end;
      }
    }),
    discount: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    amount: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    amountUntaxed: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    defaultAmount: (0, _attr.default)('number'),
    defaultAmountUntaxed: (0, _attr.default)('number'),
    exchangeRate: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    exchangeRateContract: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    exchangeRateDate: (0, _attr.default)('date', {
      defaultValue: () => new Date()
    }),
    valid: (0, _attr.default)('boolean'),
    isOrdered: (0, _attr.default)('boolean'),
    discountAmount: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    toDelete: {
      proposalItem: Ember.A()
    },
    quotationNumber: (0, _attr.default)('string'),
    type: (0, _attr.default)('string', {
      defaultValue: 'type_project'
    }),
    cycle: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    file: (0, _relationships.belongsTo)('project'),
    tandm: (0, _relationships.belongsTo)('tandm'),
    currency: (0, _relationships.belongsTo)('currency'),
    preInvoice: (0, _relationships.belongsTo)('invoice'),
    items: (0, _relationships.hasMany)('fileOutgoingProposalItem'),
    workLogs: (0, _relationships.hasMany)('workLog'),
    fileProductResults: (0, _relationships.hasMany)('fileProductResult'),
    fileOutgoingProposalUserGradeCosts: (0, _relationships.hasMany)('fileOutgoingProposalUserGradeCost'),

    async recalculate() {
      const items = await this.get('items');
      const roundDigits = await this.get('currency.round');
      let amountUntaxed = 0;
      let globalDiscountAmount = 0;
      let amount = 0;
      let defaultAmount = 0;
      let defaultAmountUntaxed = 0;

      for (const item of items.toArray()) {
        const a = item.get('amount');
        const u = item.get('amountUntaxed');
        const p = item.get('price');
        const q = item.get('quantity');
        const d = item.get('discount');
        const da = item.get('defaultAmount');
        const daut = item.get('defaultAmountUntaxed');
        const n = (0, _bankersRounding.default)(p * q, roundDigits);
        amountUntaxed += u;
        globalDiscountAmount += -(d / 100) * n;
        amount += a;
        defaultAmount += da;
        defaultAmountUntaxed += daut;
      }

      this.setProperties({
        discountAmount: (0, _bankersRounding.default)(globalDiscountAmount, roundDigits),
        amount: (0, _bankersRounding.default)(amount, roundDigits),
        amountUntaxed: (0, _bankersRounding.default)(amountUntaxed, roundDigits),
        defaultAmount,
        defaultAmountUntaxed
      });
    }

  });

  _exports.default = _default;
});