define("iris/components/beta/filters/add/ui/select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A select component configured specifically for filter selection, capable of
   * highlighting the entered text inside its result list. Receives an `acts` hash
   * which contains `select` and `search` actions provided by the `<Manager>`
   * (`actions` is a reserved word in this context), and a `data` hash which
   * contains the select's `options` array.
   */
  var _default = Ember.Component.extend({
    tagName: '',
    data: null,
    // @
    acts: null // @

  });

  _exports.default = _default;
});