define("iris/routes/settings/operations/ticket-audit-list/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let store = this.store;
      return Ember.RSVP.hash({
        auditItem: store.createRecord('ticketAuditList')
      });
    }

  });

  _exports.default = _default;
});