define("iris/templates/cmdb/entity-types/entities/new-entity/relations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5nfhSHgi",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"tables/entity-relations\",null,[[\"entity\",\"masterRelations\",\"slaveRelations\",\"newPath\"],[[24,[\"model\",\"entity\"]],[24,[\"model\",\"entity\",\"masterRelations\"]],[24,[\"model\",\"entity\",\"slaveRelations\"]],\"cmdb.entity-types.entities.new-entity.relations.new\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/entity-types/entities/new-entity/relations.hbs"
    }
  });

  _exports.default = _default;
});