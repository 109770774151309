define("iris/controllers/crm/partners/index", ["exports", "iris/services/query-params"], function (_exports, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const partnersQueryParams = (0, _queryParams.makeFilterQueryParams)(null, {
    order: {
      property: 'createDate',
      direction: 'desc'
    }
  });
  const queryParamsMixin = (0, _queryParams.makeControllerMixin)(partnersQueryParams);

  var _default = Ember.Controller.extend(queryParamsMixin, {
    reset() {}

  });

  _exports.default = _default;
});