define("iris/models/region", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 50
    })],
    code: (0, _emberCpValidations.validator)('length', {
      max: 5
    }),
    fileCodeFormat: (0, _emberCpValidations.validator)('length', {
      max: 50
    }),
    ticketCodeFormat: (0, _emberCpValidations.validator)('length', {
      max: 50
    }),
    workLogFormat: (0, _emberCpValidations.validator)('length', {
      max: 50
    }),
    ownerPartner: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    code: (0, _attr.default)('string'),
    fileCodeFormat: (0, _attr.default)('string', {
      defaultValue: '{RC}\F{N:2}'
    }),
    ticketCodeFormat: (0, _attr.default)('string', {
      defaultValue: '{F}\T{N:5}'
    }),
    workLogFormat: (0, _attr.default)('string', {
      defaultValue: '{T}\WL{N:2}'
    }),
    files: (0, _relationships.hasMany)('project'),
    regionalSettings: (0, _relationships.hasMany)('regionalSetting'),
    ownerPartner: (0, _relationships.belongsTo)('partner')
  });

  _exports.default = _default;
});