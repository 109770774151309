define("iris/templates/components/pick-list/list-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "84+OxwTh",
    "block": "{\"symbols\":[\"elem\"],\"statements\":[[1,[28,\"t\",[\"pick-list.selected-element\"],[[\"length\"],[[24,[\"selectedItems\",\"length\"]]]]],false],[0,\"\\n\"],[7,\"ul\",true],[10,\"class\",\"widget-todo-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[1,[28,\"bs-checkbox\",null,[[\"value\",\"onchange\"],[[28,\"array-contains\",[[24,[\"selectedItems\"]],[23,1,[]]],null],[28,\"action\",[[23,0,[]],\"select\",[23,1,[]]],null]]]],false],[0,\" \"],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/pick-list/list-column.hbs"
    }
  });

  _exports.default = _default;
});