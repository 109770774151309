define("iris/components/modals/import-incoming-invoice/objects", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addressObject = _exports.additionalInvoiceData = void 0;
  const addressValidations = (0, _emberCpValidations.buildValidations)({
    address: (0, _emberCpValidations.validator)('presence', true),
    town: (0, _emberCpValidations.validator)('presence', true),
    zipCode: (0, _emberCpValidations.validator)('presence', true)
  });
  const addressObject = Ember.Object.extend(addressValidations, {
    toNative() {
      return this.getProperties('address', 'town', 'zipCode', 'name', 'address', 'countryId', 'addressTypeId');
    }

  });
  _exports.addressObject = addressObject;
  const additionalInvoiceDataValidations = (0, _emberCpValidations.buildValidations)({
    subject: (0, _emberCpValidations.validator)('presence', true),
    fileBusinessType: (0, _emberCpValidations.validator)('presence', true)
  });
  const additionalInvoiceData = Ember.Object.extend(additionalInvoiceDataValidations, {
    toNative() {
      return this.getProperties('subject', 'fileId', 'fileBusinessTypeId');
    }

  });
  _exports.additionalInvoiceData = additionalInvoiceData;
});