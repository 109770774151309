define("iris/routes/operation/operational-file/index", ["exports", "moment", "iris/routes/projects/files", "iris/mixins/protected-route"], function (_exports, _moment, _files, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _files.default.extend(_protectedRoute.default, {
    uploadDocuments: Ember.inject.service(),
    templateName: 'operation/operational-file/index',
    listType: 'operational',

    _endDate(row) {
      if (row.continuous) {
        return (0, _moment.default)().add(row.periodOfNotice, 'd');
      } else {
        return (0, _moment.default)(row.dateEnd);
      }
    },

    actions: {
      endDate(row) {
        return this._endDate(row);
      },

      contractValid(row) {
        return (0, _moment.default)(this._endDate(row)).isAfter((0, _moment.default)().add(-1, 'd'));
      },

      volumen(row) {
        let volumen = 0;
        let {
          contractEndDate: end,
          contractStartDate: start,
          periodOfNotice,
          valuePeriod,
          plannedValue,
          continuous
        } = row;

        if (continuous) {
          end = (0, _moment.default)().add(periodOfNotice, 'd');
        }

        if (!start || !end) {
          return false;
        }

        let diffInMonth = (0, _moment.default)(end).diff((0, _moment.default)(start), 'month');

        if (!diffInMonth) {
          return volumen;
        }

        let periodInMonth = valuePeriod === 0 ? 1 : valuePeriod === 1 ? 3 : 12;
        volumen = (diffInMonth + 1) / periodInMonth * plannedValue;
        return volumen.toFixed(2) / 1000;
      }

    }
  });

  _exports.default = _default;
});