define("iris/components/bs-table-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'th',
    classNameBindings: ['alignDirection'],
    alignDirection: Ember.computed('align', function () {
      return 'align-' + this.get('align');
    }),
    direction: Ember.computed('sortBy', function () {
      let property = this.get('column.property');
      let className = null;
      let sortBy = this.get('sortBy');
      sortBy.filter(Boolean).forEach(function (item) {
        if (item.indexOf(property) > -1) {
          if (item.indexOf(' desc') > -1) {
            className = 'dropdown';
          } else {
            className = 'dropup';
          }
        }
      });
      return className;
    }),
    click: function (e) {
      if (this.get('change')) {
        this.get('change')(this.get('column.property'), e);
      }
    }
  });

  _exports.default = _default;
});