define("iris/components/pick-list/list-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      select(elem, selection) {
        let selectedItems = this.get('selectedItems');

        if (selection) {
          selectedItems.pushObject(elem);
          this.get('otherItems').clear();
        } else {
          selectedItems.removeObject(elem);
        }
      }

    }
  });

  _exports.default = _default;
});