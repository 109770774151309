define("iris/components/form/field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wW4AAN/s",
    "block": "{\"symbols\":[\"@model\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"model\",\"name\",\"value\",\"errorMessage\",\"hasError\",\"label\",\"error\",\"styledLabel\",\"styledError\",\"styles\"],[[23,1,[]],[23,0,[\"name\"]],[23,0,[\"value\"]],[23,0,[\"errorMessage\"]],[23,0,[\"hasError\"]],[28,\"component\",[\"form/field/label\"],[[\"name\"],[[23,0,[\"name\"]]]]],[28,\"component\",[\"form/field/error\"],[[\"errorMessage\"],[[23,0,[\"errorMessage\"]]]]],[28,\"component\",[\"form/field/label/styled\"],[[\"hasError\"],[[23,0,[\"hasError\"]]]]],[28,\"component\",[\"form/field/error/styled\"],[[\"errorMessage\"],[[23,0,[\"errorMessage\"]]]]],[28,\"hash\",null,[[\"text\",\"supportingText\",\"input\"],[[28,\"concat\",[\" text-gray-700 dark:text-gray-100 \",[28,\"if\",[[23,0,[\"hasError\"]],\" text-scarlet-600 dark:text-scarlet-600\"],null]],null],[28,\"concat\",[\" text-gray-500 dark:text-gray-300 \",[28,\"if\",[[23,0,[\"hasError\"]],\" text-scarlet-500 dark:text-scarlet-700\"],null]],null],[28,\"concat\",[\" text-gray-700 dark:text-gray-100 placeholder-opacity-75 \",\" focus:outline-none focus:ring dark:focus:ring-steel-400 \",\" rounded border-2 border-gray-300 dark:bg-gray-800 dark:border-gray-600 \",\" disabled:bg-gray-200 disabled:border-gray-400 \",\" dark:disabled:border-gray-700 dark:disabled:bg-gray-900 \",[28,\"if\",[[23,0,[\"hasError\"]],\" border-scarlet-600 dark:border-scarlet-800 \"],null]],null]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/form/field/template.hbs"
    }
  });

  _exports.default = _default;
});