define("iris/templates/cmdb/entity-types/entities/entity/software/edit/attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OPFOgXIc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"hide-second\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"entityValues\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h4\",true],[10,\"class\",\"h4\"],[8],[1,[28,\"t\",[\"cmdb.entity.device-type-fields\"],null],false],[9],[0,\"\\n    \"],[1,[28,\"entity-attributes\",null,[[\"values\",\"entity\",\"columns\",\"codeStorePath\"],[[24,[\"model\",\"entityValues\"]],[24,[\"model\"]],[24,[\"model\",\"entityType\",\"entityAttributes\"]],\"cmdb.entity-types.entities.entity.software.edit.attributes.code-store\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"model\",\"customValues\"]]],null,{\"statements\":[[0,\"    \"],[7,\"hr\",true],[10,\"class\",\"--porto-hr\"],[8],[9],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"h4\"],[8],[1,[28,\"t\",[\"cmdb.entity.custom-fields\"],null],false],[9],[0,\"\\n    \"],[1,[28,\"forms/custom-attributes\",null,[[\"values\"],[[24,[\"model\",\"customValues\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[1,[28,\"forms/entity-attribute-custom\",null,[[\"attributes\"],[[24,[\"model\",\"customAttributes\"]]]]],false],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/entity-types/entities/entity/software/edit/attributes.hbs"
    }
  });

  _exports.default = _default;
});