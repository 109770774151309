define("iris/routes/confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),

    model(params) {
      return this.get('ajax').call('POST', 'inventoryContact', '', 'confirmation/' + params.token + '/' + params.verb, null, false);
    },

    actions: {
      send(params, reason) {
        const flashMessages = Ember.get(this, 'flashMessages');
        reason = reason + '';

        if (reason.length < 10) {
          flashMessages.add({
            message: this.intl.t('operation.report-submit.fill-reason'),
            title: 'IRIS message',
            icon: 'info'
          });
          return;
        }

        this.get('ajax').call('POST', 'inventoryContact', '', 'confirmation/' + params.token + '/sent', {
          'reason': reason
        }, false).then(res => {
          this.controller.set('model', res);
        });
      }

    }
  });

  _exports.default = _default;
});