define("iris/templates/aiops/source-data/customers/contract-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JXUSmX8w",
    "block": "{\"symbols\":[\"c\"],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@neededFields\"],[\"aiops-contract-type\",\"aiops.source-data.customers.contract-types.edit\",[28,\"array\",[\"name\",\"sourceId\",\"dtMode\",\"active\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"dtModeId\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[\"badge \",[28,\"if\",[[28,\"eq\",[[23,1,[\"row\",\"dtMode\",\"id\"]],\"M\"],null],\"badge-info\",\"badge-success\"],null]]]],[8],[0,\"\\n      \"],[1,[23,1,[\"row\",\"dtMode\",\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/aiops/source-data/customers/contract-types.hbs"
    }
  });

  _exports.default = _default;
});