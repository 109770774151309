define("iris/instance-initializers/browser/liquid-target-container", ["exports", "liquid-wormhole/instance-initializers/browser/liquid-target-container"], function (_exports, _liquidTargetContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _liquidTargetContainer.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _liquidTargetContainer.initialize;
    }
  });
});