define("iris/templates/components/tree-branch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ohlniRM6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[1,[28,\"sub-ticket\",null,[[\"rootComp\",\"model\",\"addAction\"],[[24,[\"rootComp\"]],[24,[\"model\"]],[24,[\"addAction\"]]]]],false],[0,\"\\n\"],[1,[28,\"tree-group\",null,[[\"rootComp\",\"model\",\"addAction\"],[[24,[\"rootComp\"]],[24,[\"model\",\"children\"]],[24,[\"addAction\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/tree-branch.hbs"
    }
  });

  _exports.default = _default;
});