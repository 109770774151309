define("iris/routes/reports/inventories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      createInvetory: function () {
        this.transitionTo('reports.inventories.new');
      },
      stopInventory: function (record) {
        record.set('stop', 1);
        record.save();
      },
      startInventory: function (record) {
        record.set('stop', 0);
        record.save();
      }
      /*
      batchPDF: function(id) {
        let token = this.get('session.data.authenticated.id');
        let host = this.store.adapterFor('application').get('host');
        window.open(host + '/dompdf/pdf.php?mode=batch&token=' + token + '&id=' + id);
      }
      */

    }
  });

  _exports.default = _default;
});