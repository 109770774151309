define("iris/templates/human-resource-management/event-approvement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wvz0Eyus",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-3\"],[8],[0,\"\\n\"],[4,\"iris-power-select-multiple\",null,[[\"options\",\"placeholder\",\"searchField\",\"selected\",\"onchange\"],[[24,[\"model\",\"operationableUsers\"]],[28,\"t\",[\"menus.settings.acl.users\"],null],\"longName\",[24,[\"selectedUsers\"]],[28,\"action\",[[23,0,[]],\"selectedUsersChanged\"],null]]],{\"statements\":[[0,\"      \"],[1,[23,1,[\"longName\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"full-calendar-container\"],[8],[0,\"\\n  \"],[1,[28,\"full-calendar\",null,[[\"locale\",\"header\",\"selectable\",\"buttonText\",\"allDayText\",\"slotDuration\",\"slotLabelFormat\",\"timeFormat\",\"height\",\"customButtons\",\"nowIndicator\",\"eventClick\",\"eventRender\",\"dayRender\",\"viewRender\",\"events\"],[[23,0,[\"sessionAccount\",\"account\",\"language\",\"languageCode\"]],[24,[\"header\"]],false,[24,[\"buttonText\"]],[28,\"t\",[\"appcomponenthrm.all-day\"],null],\"01:00:00\",\"HH:mm\",\"HH:mm\",[24,[\"height\"]],[24,[\"customButtons\"]],true,[24,[\"eventClick\"]],[24,[\"eventRender\"]],[24,[\"dayRender\"]],[24,[\"viewRender\"]],[23,0,[\"calendarEvents\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/human-resource-management/event-approvement.hbs"
    }
  });

  _exports.default = _default;
});