define("iris/templates/cmdb/entity-types/entities/entity/sites", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hnQwkeun",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"forms/entity-site\",null,[[\"model\"],[[24,[\"model\",\"entity\"]]]]],false],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/entity-types/entities/entity/sites.hbs"
    }
  });

  _exports.default = _default;
});