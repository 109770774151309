define("iris/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6PryK6hp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"style\",\"z-index:100000001; position:absolute; top:0px; left: 0px; width: 100%; height: 100%\"],[8],[0,\"\\n  \"],[1,[28,\"spin-spinner\",null,[[\"lines\",\"length\",\"width\"],[13,20,10]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});