define("iris/routes/settings/operations/ticket-states/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        model: this.store.find('ticketState', params.ticket_state_id)
      });
    },

    deactivate() {
      let {
        model
      } = this.modelFor('settings.operations.ticket-states.edit');
      model.set('destinationStates', null);
      model.set('previousDestinationStates', null);
    },

    actions: {
      saveTicketState(options, model) {
        model.save().then(() => {
          let previousDestinationStates = model.get('previousDestinationStates');
          let destinationStates = model.get('destinationStates');
          let previousDestinationStateIds = previousDestinationStates.mapBy('id');
          let destinationStateIds = destinationStates.mapBy('id'); // We need the previous ids, because we may want to set `isAllowed: false`

          let allDestinationStateIds = [...new Set([...previousDestinationStateIds, ...destinationStateIds])];
          this.get('store').query('ticketStateChangeAllow', {
            filter: {
              where: {
                and: [{
                  originStateId: model.get('id')
                }, {
                  destinationStateId: {
                    inq: allDestinationStateIds
                  }
                }]
              }
            }
          }).then(existingStateChanges => {
            // Handle existing state changes
            Ember.RSVP.all(existingStateChanges.map(async stateChange => {
              let destinationStateId = stateChange.get('destinationStateId');
              let isAllowed = stateChange.get('isAllowed');

              if (destinationStateIds.includes(destinationStateId) && !isAllowed) {
                stateChange.set('isAllowed', true);
              } else if (!destinationStateIds.includes(destinationStateId) && isAllowed) {
                stateChange.set('isAllowed', false);
              }

              return stateChange.save();
            })).then(() => {
              // Handle changes that does not exist
              let changeDestinationIds = existingStateChanges.mapBy('destinationStateId');
              Ember.RSVP.all(destinationStates.reduce((acc, destinationState) => {
                let id = destinationState.get('id');

                if (!changeDestinationIds.includes(id)) {
                  let stateChange = this.get('store').createRecord('ticketStateChangeAllow', {
                    originStateId: model.get('id'),
                    destinationStateId: id,
                    isAllowed: true
                  });
                  acc.push(stateChange.save());
                }

                return acc;
              }, [])).then(() => {
                this.transitionTo(options.redirectTo);
              });
            });
          });
        });
      }

    }
  });

  _exports.default = _default;
});