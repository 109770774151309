define("iris/templates/components/forms/entity-attribute-custom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mQVk7/tK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"toggle toggle-primary\"],[10,\"style\",\"max-width: 802px;\"],[8],[0,\"\\n  \"],[7,\"section\",true],[11,\"class\",[29,[\"toggle \",[28,\"if\",[[24,[\"customActivated\"]],\"active\"],null]]]],[8],[0,\"\\n    \"],[7,\"label\",false],[3,\"action\",[[23,0,[]],\"toggleCustomActivated\"]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-plus\"],[8],[9],[7,\"i\",true],[10,\"class\",\"fa fa-minus\"],[8],[9],[1,[28,\"t\",[\"cmdb.entity.custom-fields\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"toggle-content\"],[11,\"style\",[29,[\"display: \",[28,\"if\",[[24,[\"customActivated\"]],\"block\"],null],\";\"]]],[8],[0,\"\\n      \"],[1,[28,\"key-value-pair\",null,[[\"value\",\"rows\"],[[24,[\"attributes\"]],\"7\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/entity-attribute-custom.hbs"
    }
  });

  _exports.default = _default;
});