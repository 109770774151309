define("iris/services/long-polling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    eventInterval: 10000,

    schedulePollEvent(event, interval) {
      var eventInterval = interval || this.get('eventInterval');
      return Ember.run.later(() => {
        event.apply(this);
        this.set('timer', this.schedulePollEvent(event));
      }, eventInterval);
    },

    startPolling(interval) {
      this.set('timer', this.schedulePollEvent(this.get('onPollEvent'), interval));
    },

    stopPolling() {
      Ember.run.cancel(this.get('timer'));
    },

    onPollEvent() {//make request
    }

  });

  _exports.default = _default;
});