define("iris/templates/components/forms/generate-availability-day", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I1R4iUTY",
    "block": "{\"symbols\":[\"option\",\"option\"],\"statements\":[[4,\"form-group\",null,[[\"property\",\"label\",\"proportion\"],[\"Primary\",[28,\"t\",[\"tasks.main.primary\"],null],\"4-8\"]],{\"statements\":[[4,\"iris-power-select\",null,[[\"options\",\"selected\",\"onchange\"],[[24,[\"computedUsers\"]],[24,[\"day\",\"primary\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"day\",\"primary\"]]],null]],null]]],{\"statements\":[[0,\"    \"],[1,[23,2,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\",\"proportion\"],[\"Secondary\",[28,\"t\",[\"tasks.main.secondary\"],null],\"4-8\"]],{\"statements\":[[4,\"iris-power-select\",null,[[\"options\",\"selected\",\"onchange\"],[[24,[\"computedUsers\"]],[24,[\"day\",\"secondary\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"day\",\"secondary\"]]],null]],null]]],{\"statements\":[[0,\"    \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/generate-availability-day.hbs"
    }
  });

  _exports.default = _default;
});