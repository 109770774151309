define("iris/templates/beta/grid/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XpdI97vw",
    "block": "{\"symbols\":[\"g\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"demo-container small\"],[8],[0,\"\\n  \"],[5,\"beta/grid\",[],[[\"@storage\",\"@columns\",\"@rows\",\"@total\",\"@enableTree\"],[[23,0,[\"storage\"]],[23,0,[\"columns\"]],[23,0,[\"rows\"]],[23,0,[\"rows\",\"length\"]],true]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"Table\"]],[[12,\"class\",\"groupped-table\"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"c.cell\",true],[8],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[6,[23,1,[\"Pagination\"]],[],[[\"@limit\"],[\"25\"]]],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/beta/grid/index.hbs"
    }
  });

  _exports.default = _default;
});