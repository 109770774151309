define("iris/models/bank-balance", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations", "ember-data/relationships"], function (_exports, _model, _attr, _emberCpValidations, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    date: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    bankAccount: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    bankAccountOwner: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    balance: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    exchangeRate: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    balanceOwnCurrency: (0, _emberCpValidations.validator)('presence', {
      presence: true
    })
  });
  const now = new Date();

  var _default = _model.default.extend(Validations, {
    date: (0, _attr.default)('date', {
      defaultValue: () => now
    }),
    balance: (0, _attr.default)('number'),
    exchangeRate: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    balanceOwnCurrency: (0, _attr.default)('number'),
    bankAccount: (0, _relationships.belongsTo)('partnerBankcode'),
    bankAccountOwner: (0, _relationships.belongsTo)('partner'),
    currency: (0, _relationships.belongsTo)('currency')
  });

  _exports.default = _default;
});