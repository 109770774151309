define("iris/routes/cmdb/entity-types/entities/entity/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      const modelEntity = this.modelFor(this.get('parentRouteName'));
      return modelEntity.entity;
    }

  });

  _exports.default = _default;
});