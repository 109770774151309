define("iris/templates/components/action-competency-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/wznTEXX",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"iris-power-select-multiple\",null,[[\"disabled\",\"options\",\"selected\",\"onchange\"],[[24,[\"disableInput\"]],[28,\"without\",[[24,[\"selected\"]],[24,[\"competencies\"]]],null],[24,[\"selected\"]],[28,\"action\",[[23,0,[]],\"competencies\",[24,[\"action\"]],[24,[\"model\"]],[24,[\"selected\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[2,\" \"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/action-competency-file.hbs"
    }
  });

  _exports.default = _default;
});