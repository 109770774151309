define("iris/models/regional-setting", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    settingKey: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 150
    })],
    regionId: (0, _emberCpValidations.validator)('length', {
      max: 12
    })
  });

  var _default = _model.default.extend(Validations, {
    settingKey: (0, _attr.default)('string'),
    settingValue: (0, _attr.default)('string'),
    regionId: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    region: (0, _relationships.belongsTo)('region'),
    partner: (0, _relationships.belongsTo)('partner')
  });

  _exports.default = _default;
});