define("iris/components/tables/acl-user-competencies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      toggleCompetencyEnabled() {
        let competency = arguments.length <= 0 ? undefined : arguments[0];
        let user = arguments.length <= 1 ? undefined : arguments[1];
        let value = arguments.length <= 2 ? undefined : arguments[2];
        let competencies = user.get('competencies');

        if (value) {
          competencies.addObject(competency);
        } else {
          user.get('competencies').forEach(c => {
            if (c.get('id') === competency.get('id')) {
              competencies.removeObject(c);
            }
          });
        }
      }

    }
  });

  _exports.default = _default;
});