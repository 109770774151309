define("iris/components/beta/filters/fuzzy-search/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    columns: null,
    // @, Column[]
    _value: null,
    inputTitle: Ember.computed('columns.@each.displayName', function () {
      let columnNames = this.columns.map(_ref => {
        let {
          displayName
        } = _ref;
        return displayName;
      });
      return `Keresés a következőkben: ${columnNames.join(', ')}.`;
    }),
    actions: {
      onSubmit(event) {
        event.preventDefault();
        let value = this._value.trim() ? this._value : null;
        this.onSubmit(value);
      },

      clear() {
        this.onSubmit(null);
      }

    }
  });

  _exports.default = _default;
});