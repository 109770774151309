define("iris/templates/components/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qbR0Zoo2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"progress-bar progress-bar-\",[22,\"color\"],\" progress-without-number\"]]],[10,\"role\",\"progressbar\"],[11,\"aria-valuenow\",[29,[[22,\"progress\"]]]],[10,\"aria-valuemin\",\"0\"],[10,\"aria-valuemax\",\"100\"],[11,\"style\",[22,\"barWidth\"]],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"sr-only\"],[8],[1,[22,\"progress\"],false],[0,\"% \"],[1,[28,\"t\",[\"components-main.complete\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/progress-bar.hbs"
    }
  });

  _exports.default = _default;
});