define("iris/components/file-progress/half-doughnut-chart", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    themeSwitcher: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ["no-gutter"],
    windowWidth: (0, _jquery.default)(window).width(),
    range: Ember.computed.reads('chartRange'),
    labels: Ember.computed(function () {
      return [this.intl.t('dashboard.ticket.inProgress'), this.intl.t('dashboard.ticket.late')];
    }),
    datasets: Ember.computed("chartDataValue", function () {
      let dataVal = this.get("chartDataValue");
      const range = this.get("range");
      const primaryColor = this.get("primaryColor");
      const secondaryColor = this.get("secondaryColor");
      return [{
        label: "",
        data: [range - Number(dataVal), Number(dataVal)],
        backgroundColor: [secondaryColor, primaryColor],
        borderWidth: [0, 0]
      }];
    }),
    data: Ember.computed("datasets", "labels", function () {
      let datasets = this.get("datasets");
      let labels = this.get("labels");
      return {
        labels,
        datasets
      };
    }),
    options: Ember.computed("themeSwitcher.dark", "chartDataValue", function () {
      const fontColor = this.get("themeSwitcher.dark") ? "#FFF" : "#485761";
      return {
        cutoutPercentage: 75,
        legend: {
          display: false
        },
        elements: {
          center: {
            // the longest text that could appear in the center
            maxText: '100%',
            text: this.get('chartDataValue'),
            fontColor: fontColor,
            fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            fontStyle: 'normal',
            // fontSize: 12,
            // if a fontSize is NOT specified, we will scale (within the below limits) maxText to take up the maximum space in the center
            // if these are not specified either, we default to 1 and 256
            minFontSize: 1,
            maxFontSize: 256
          }
        }
      };
    })
  });

  _exports.default = _default;
});