define("iris/components/forms/new-operation-service-level", ["exports", "iris/lib/modals"], function (_exports, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modals: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    newModal: false,
    serviceLevel: null,
    operationServiceLevel: Ember.computed.alias('model.operationServiceLevel'),
    noSD: Ember.computed.filterBy('model.file.fileSolverGroups', 'sd', false),
    solvingGroups: Ember.computed.mapBy('noSD', 'solvingGroup'),

    async afterSave(serviceLevel) {
      let ticketCommunications = serviceLevel.get('ticketCommunications');

      for (const tcRecord of ticketCommunications.toArray()) {
        await tcRecord.set('serviceLevel', serviceLevel);
        await tcRecord.save(true);
      }
    },

    actions: {
      chageOperationServiceLevelType(selected) {
        this.model.set('operationServiceLevelType', selected);

        if (!this.model.name) {
          this.model.set('name', selected.name);
        }
      },

      async addNewBusinessService() {
        const entityTypeCategories = await this.store.query('entityTypeCategory', {
          filter: {
            where: {
              id: {
                like: '%000000003'
              }
            }
          }
        }); // Üzleti szolgáltatás

        let defaultSiteZone;
        const partner = await this.model.get('file.primeContractor');
        const region = this.get('sessionAccount.region');
        const ownerPartner = await region.get('ownerPartner');
        const siteZones = await ownerPartner.get('siteZones');
        siteZones.forEach(siteZone => {
          if (siteZone.get('default')) {
            defaultSiteZone = siteZone;
          }
        });
        const record = this.store.createRecord('businessService', {
          partner,
          entityTypeCategory: entityTypeCategories.firstObject,
          siteZone: defaultSiteZone
        });
        let newItem = await this.modals.open('modals/form-modal', {
          model: record,
          proportion: '4-8',
          itemName: 'businessService',
          parentModel: this.model,
          formComponent: 'forms/entity-form-business-service'
        }, {
          className: _modals.ModalLayoutClassName['M']
        });
        this.model.set('businessService', record);
        this.model.set('name', record.name);
      },

      async addNewOperationServiceLevelType() {
        const record = this.store.createRecord('operationServiceLevelType', {});
        let newItem = await this.modals.open('modals/form-modal', {
          model: record,
          proportion: '4-8',
          itemName: 'operationServiceLevelType',
          parentModel: this.model,
          neededFields: ['name']
        }, {
          className: _modals.ModalLayoutClassName['M']
        });
        this.model.set('operationServiceLevelType', record);

        if (!this.model.name) {
          this.model.set('name', record.name);
        }
      }

    }
  });

  _exports.default = _default;
});