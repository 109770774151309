define("iris/initializers/model", ["exports", "ember-data", "ember-data/attr", "iris/mixins/is-validated", "iris/mixins/managed-relationships-mixin"], function (_exports, _emberData, _attr, _isValidated, _managedRelationshipsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var alreadyRun = false;
  var _default = {
    name: 'model',
    initialize: function () {
      if (alreadyRun) {
        return;
      } else {
        alreadyRun = true;
      }

      _emberData.default.Model.reopen(_isValidated.default, _managedRelationshipsMixin.default, {
        searchAttr: (0, _attr.default)('string', {
          defaultValue: 'name'
        }),
        isDirtyTemp: (0, _attr.default)('date')
      });
    }
  };
  _exports.default = _default;
});