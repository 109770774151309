define("iris/models/invoice-item", ["exports", "ember-data", "ember-data-copyable", "ember-cp-validations"], function (_exports, _emberData, _emberDataCopyable, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    description: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 1024
    })],
    quantity: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    uom: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    uomNav: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    price: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    vatRef: [(0, _emberCpValidations.validator)('belongs-to'), (0, _emberCpValidations.validator)('presence', true)]
  });
  const {
    attr,
    belongsTo
  } = _emberData.default;

  var _default = _emberData.default.Model.extend(_emberDataCopyable.default, Validations, {
    description: attr('string'),
    quantity: attr('number', {
      defaultValue: 0
    }),
    uom: attr('string'),
    uomNav: attr('string'),
    price: attr('number', {
      defaultValue: 0
    }),
    netto: attr('number'),
    vat: attr('number'),
    vatValue: attr('number'),
    brutto: attr('number'),
    isCorrectionItem: attr('number', {
      defaultValue: 0
    }),
    advanceIndicator: attr('boolean', {
      defaultValue: false
    }),
    invoice: belongsTo('invoice'),
    parent: belongsTo('invoiceItem'),
    vatRef: belongsTo('vat')
  });

  _exports.default = _default;
});