define("iris/templates/cmdb/entity-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "67j/ZLpc",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"menus.entities\"],null]],null],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"spinner\"]]],null,{\"statements\":[[0,\"  \"],[5,\"in-element-id\",[],[[\"@destinationElementId\"],[\"spinner-target\"]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"loading-container\"],[8],[0,\"\\n      \"],[1,[28,\"spin-spinner\",null,[[\"lines\",\"length\",\"width\"],[13,20,10]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/entity-types.hbs"
    }
  });

  _exports.default = _default;
});