define("iris/models/priority-urgency", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 50
    })],
    value: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    value: (0, _attr.default)('number')
  });

  _exports.default = _default;
});