define("iris/initializers/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var alreadyRun = false;
  var _default = {
    name: 'controller',

    initialize() {
      if (alreadyRun) {
        return;
      } else {
        alreadyRun = true;
      }

      Ember.Controller.reopen({
        init() {
          for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          this._super(args);
        }

      });
    }

  };
  _exports.default = _default;
});