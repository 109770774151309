define("iris/routes/operation/sd-dashboard/new-task", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service(),
    parentRoute: 'operation.sd-dashboard.index',

    model() {
      let store = this.store;
      let filter = {
        where: {
          and: [{
            or: [{
              crVersion: null
            }, {
              crVersion: 0
            }]
          }, {
            planPeriodCloseDate: null
          }, {
            closed: 0
          }]
        }
      };
      return Ember.RSVP.hash({
        ticket: store.createRecord('ticket'),
        projects: store.query('project', {
          filter: filter
        }),
        accounts: store.query('account', {
          filter: {
            where: {
              enabled: 1
            }
          }
        })
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('parentRoute', this.get('parentRoute'));
    },

    actions: {
      afterSave(savedTicket) {
        let account = this.get('sessionAccount.account');
        let store = this.get('store');
        let now = new Date();
        let start = now;

        if (savedTicket.get('plannedStartDate') > now) {
          start = savedTicket.get('plannedStartDate');
        }

        let record = store.createRecord('ticketUserAssoc', {
          user: account,
          ticket: savedTicket,
          workplaceAddress: savedTicket.get('workplaceAddress'),
          startDate: start,
          endDate: savedTicket.get('plannedEndDate'),
          deadline: savedTicket.get('plannedEndDate')
        });
      }

    }
  });

  _exports.default = _default;
});