define("iris/routes/settings/operations/service-levels/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ServiceLevelTemplateEditRoute extends Ember.Route {
    model(params) {
      if (params.id == 'new') {
        return this.store.createRecord('serviceLevelTemplate');
      }

      return this.store.findRecord('serviceLevelTemplate', params.id).catch(function () {
        return {};
      });
    }

    setupController(controller, model) {
      super.setupController(...arguments);
      controller.set('afterSave', this.afterSave);
    }

    async afterSave(serviceLevel) {
      try {
        const savedServiceLevel = await serviceLevel.save();
        const tc = await savedServiceLevel.get('ticketCommunications');
        tc.invoke('save');
      } catch (err) {
        let errorMessages = '';

        if (err.errors) {
          err.errors.forEach(error => {
            errorMessages += error.detail + ".";
          });
        } else {
          errorMessages += err;
        }

        flashMessages.add({
          message: errorMessages + '!!!',
          title: 'IRIS message',
          icon: 'warning'
        });
      }
    }

  }

  _exports.default = ServiceLevelTemplateEditRoute;
});