define("iris/controllers/unauth/contact-registration", ["exports", "iris/utils/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),

    async init() {
      this._super(...arguments);

      let languages = await this.ajax.call('GET', 'language', '', '', {}, false);
      let mappedLanguages = languages.data.map(l => Ember.Object.create(l));
      this.set('languages', mappedLanguages);
    },

    currentLanguageCode: 'hu-hu',
    notRobot: false,
    errors: null,
    ready: false,
    languages: [],
    actions: {
      async registration() {
        this.set('errors', null);
        const {
          model,
          validations
        } = await (0, _model.validateRecord)(this.get('model'));

        if (validations.get('isValid')) {
          try {
            await this.get('ajax').call('POST', 'contact', null, 'registration', {
              email: model.get('email'),
              password: model.get('password')
            }, false);
            this.set('ready', true);
          } catch (e) {
            this.set('errors', e.errors);
          }
        } else {
          model.set('didValidate', true);
        }
      },

      async selectLanguage(language) {
        this.set('currentLanguageCode', language.attributes.languageCode);
        this.intl.setLocale(language.attributes.languageCode);
      }

    }
  });

  _exports.default = _default;
});