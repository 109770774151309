define("iris/controllers/finances/invoices", ["exports", "iris/lib/filters", "iris/lib/filters/column", "iris/lib/filters/storage", "iris/lib/filters/types", "iris/lib/grid", "iris/lib/modals", "iris/lib/table", "iris/services/query-params", "iris/utils/error"], function (_exports, _filters, _column, _storage, _types, _grid, _modals, _table, _queryParams, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const invoicesQueryParams = (0, _queryParams.makeFilterQueryParams)(null, {
    order: {
      property: 'dateIssued',
      direction: 'desc'
    }
  });
  const queryParamsMixin = (0, _queryParams.makeControllerMixin)(invoicesQueryParams);

  var _default = Ember.Controller.extend(queryParamsMixin, {
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    modals: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    storage: (0, _storage.useStorage)('query-params', {
      controllerPath: 'finances.invoices'
    }),
    predefinedViews: Ember.computed(function () {
      return [{
        name: this.intl.t('finances.unpaid'),
        filters: [{
          name: 'datePaid',
          type: _types.FilterType.Null
        }]
      }, {
        name: this.intl.t('finances.pre-invoices'),
        filters: [{
          name: 'preInvoice',
          type: _types.FilterType.Boolean
        }]
      }];
    }),

    async createPayments(selection) {
      let m = [];
      const store = this.store;
      let payValue = 0;
      const account = this.sessionAccount.get('account');
      const now = new Date();
      const transfer = await store.find('paymentMethod', 'SYS_04');
      selection.forEach(invoice => {
        payValue = invoice.get('vat') + invoice.get('netto') - invoice.get('paidBalance');
        m.push(store.createRecord('invoicePayment', {
          invoice: invoice,
          payValue: payValue,
          user: account,
          paymentMethod: transfer,
          datePayment: now,
          dateEntered: now
        }));
      });
      return m;
    },

    bulkActions: (0, _grid.bulkActions)(function () {
      return [{
        name: this.intl.t('finances.payments'),
        icon: 'money',

        async action(selection) {
          const notPreInvoices = selection.filter(s => !s.preInvoice);
          const payments = await this.createPayments(notPreInvoices);
          const modalResult = await this.modals.open('modals/invoice-payment-modal', {
            payments
          }, {
            className: _modals.ModalLayoutClassName.Grow
          });

          if (modalResult !== null && modalResult !== void 0 && modalResult.shouldRefreshRecords) {
            this.storage.refresh();
          } else {
            payments.invoke('destroyRecord');
          }
        }

      }];
    }),
    rowActions: (0, _table.rowActions)(function () {
      const {
        intl
      } = this;
      return [{
        name: intl.t('finances.final-invoice'),
        icon: 'forward',
        placement: _table.RowActionPlacement.Rest,
        disabled: Ember.computed('row.isAdvanceInvoice', function () {
          if (this.row.isAdvanceInvoice) {
            return false;
          }

          return intl.t('finances.disabled.final-invoice');
        }),
        route: 'finances.invoices.final-invoice',
        routeParams: Ember.computed.alias('row.id')
      }, {
        name: intl.t('buttons.copying'),
        icon: 'copy',
        placement: _table.RowActionPlacement.Rest,
        disabled: Ember.computed('row.{normalInvoice,preInvoice}', function () {
          if (this.row.preInvoice || this.row.normalInvoice) {
            return false;
          }

          return intl.t('finances.disabled.normal-pre-invoice');
        }),
        route: 'finances.invoices.copy',
        routeParams: Ember.computed.alias('row.id')
      }, {
        name: intl.t('menus.storno'),
        icon: 'ban',
        placement: _table.RowActionPlacement.Rest,
        disabled: Ember.computed('row.{isFinalInvoice,normalInvoice,preInvoice}', function () {
          if (!this.row.preInvoice && (this.row.isFinalInvoice || this.row.normalInvoice)) {
            return false;
          }

          return intl.t('finances.disabled.storno');
        }),
        route: 'finances.invoices.storno',
        routeParams: Ember.computed.alias('row.id')
      }, {
        name: intl.t('menus.correcting-invoice'),
        icon: 'pencil-square-o',
        placement: _table.RowActionPlacement.Rest,
        disabled: Ember.computed('row.{isFinalInvoice,normalInvoice,preInvoice}', function () {
          if (!this.row.preInvoice && (this.row.isFinalInvoice || this.row.normalInvoice)) {
            return false;
          }

          return intl.t('finances.disabled.correcting');
        }),
        route: 'finances.invoices.correcting-invoice',
        routeParams: Ember.computed.alias('row.id')
      }, {
        name: intl.t('menus.edit'),
        icon: 'pencil',
        placement: _table.RowActionPlacement.Rest,
        route: 'finances.invoices.edit',
        disabled: Ember.computed('row.preInvoice', function () {
          if (this.row.preInvoice) {
            return false;
          }

          return intl.t('finances.disabled.pre-invoice');
        }),
        routeParams: Ember.computed.alias('row.id')
      }, {
        name: intl.t('general.pdf-generate'),
        icon: 'file-pdf-o',
        placement: _table.RowActionPlacement.Rest,

        async action(invoice) {
          try {
            const response = await this.api.memberAction('invoice', 'pdf', '', {
              method: 'POST',
              data: {
                id: invoice.id
              }
            });
            const text = intl.t('general.pdf-generate-success');
            this.get('flashMessages').info(text, {
              title: intl.t('general.pdf-generate'),
              icon: 'exclamation'
            });
          } catch (error) {
            const msg = (0, _error.formatErrorMessages)(error);
            flashMessages.danger(msg, {
              title: intl.t('general.pdf-generate-error'),
              sticky: true,
              icon: 'warning'
            });
          }
        }

      }, {
        name: intl.t('finances.nav.state'),
        icon: 'search',
        placement: _table.RowActionPlacement.Rest,
        disabled: Ember.computed('row.needStatusCheck', function () {
          if (this.row.needStatusCheck) {
            return false;
          }

          return intl.t('finances.disabled.nav');
        }),

        async action(invoice) {
          try {
            const response = await this.api.memberAction('invoice', 'check-status', '', {
              method: 'POST',
              data: {
                id: invoice.transactionId
              }
            });
            invoice.reload();
            const text = intl.t('finances.nav.check-state-response', {
              status: response.invoiceStatus
            });
            this.get('flashMessages').info(text, {
              title: intl.t('finances.nav.check-state'),
              icon: 'exclamation'
            });
          } catch (error) {
            const flashMessages = get(this, 'flashMessages');
            e.errors.forEach(err => {
              let text = err.detail || err.title;
              flashMessages.danger(text, {
                title: intl.t('finances.nav.check-state-error'),
                icon: 'times',
                sticky: true
              });
            });
          }
        }

      }, {
        name: intl.t('finances.nav.send'),
        icon: 'paper-plane-o',
        placement: _table.RowActionPlacement.Rest,
        disabled: Ember.computed('row.{preInvoice,transactionId}', function () {
          if (!this.row.preInvoice && !this.row.transactionId) {
            return false;
          }

          return intl.t('finances.disabled.nav-send');
        }),

        async action(invoice) {
          try {
            const response = await this.api.memberAction('invoice', 'send-invoice', '', {
              method: 'POST',
              data: {
                id: invoice.id
              }
            });
            invoice.reload();
          } catch (e) {
            console.log(e);
            e.errors.forEach(err => {
              let text = err.detail || err.title;
              this.flashMessages.danger(text, {
                title: intl.t('finances.nav.send-error'),
                icon: 'times',
                sticky: true
              });
            });
            this.set('invoiceDone', true);
            return false;
          }
        }

      }, {
        name: intl.t('buttons.delete'),
        icon: 'trash',
        placement: _table.RowActionPlacement.Rest,
        style: 'danger',
        disabled: Ember.computed('row.preInvoice', function () {
          if (this.row.preInvoice) {
            return false;
          }

          return intl.t('finances.disabled.delete-invoice');
        }),

        async action(preInvoice) {
          const shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

          if (shouldDelete) {
            try {
              await preInvoice.destroyRecord(true);
              this.flashMessages.info(intl.t('general.delete.success-title'), {
                title: intl.t('general.delete.success'),
                icon: 'times'
              });
              this.storage.refresh();
            } catch (error) {
              this.flashMessages.danger(intl.t('general.delete.error'));
            }
          }
        }

      }];
    }),
    columns: (0, _column.columns)(function () {
      return [{
        name: 'preInvoice',
        displayName: this.intl.t('finances.pre-invoice'),
        type: _types.AttributeType.Boolean,
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'datePaid',
        displayName: this.intl.t('finances.paid'),
        type: _types.AttributeType.Date,
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'accountNumber',
        displayName: this.intl.t('finances.invoice-number'),
        width: _column.ColumnWidth.SM,
        fixed: true,
        route: 'finances.invoices.view-pdf',
        routeParams: (0, _table.rowValues)('id'),
        formatExportValue: (value, row, columns) => {
          return value !== null && value !== void 0 ? value : this.intl.t('components-main.advance-invoice');
        }
      }, {
        name: 'partnerId',
        displayName: this.intl.t('models.clientPartner'),
        valuePath: 'partner.name',
        type: _types.AttributeType.BelongsTo,
        width: _column.ColumnWidth.M
      }, {
        name: 'invoiceOwnerId',
        displayName: this.intl.t('models.invoiceOwner'),
        valuePath: 'invoiceOwner.name',
        optionsResourceName: 'partner',
        type: _types.AttributeType.BelongsTo,
        width: _column.ColumnWidth.M
      }, {
        name: 'subject',
        displayName: this.intl.t('models.subject'),
        valuePath: 'subject',
        width: _column.ColumnWidth.L
      }, {
        name: 'dateIssued',
        displayName: this.intl.t('forms.dated'),
        valuePath: 'dateIssued',
        type: _types.AttributeType.Date,
        width: _column.ColumnWidth.S
      }, {
        name: 'dateFulfilled',
        displayName: this.intl.t('finances.vat-paid'),
        valuePath: 'dateFulfilled',
        type: _types.AttributeType.Date,
        width: _column.ColumnWidth.S
      }, {
        name: 'datePaymentDue',
        displayName: this.intl.t('forms.payment-deadline'),
        valuePath: 'datePaymentDue',
        type: _types.AttributeType.Date,
        width: _column.ColumnWidth.S
      }, {
        name: 'netto',
        displayName: this.intl.t('components-main.net-interpol'),
        valuePath: 'netto',
        type: _types.AttributeType.Number,
        width: _column.ColumnWidth.S
      }, {
        name: 'gross',
        displayName: this.intl.t('components-main.gross-interpol'),
        valuePath: 'gross',
        type: _types.AttributeType.Number,
        width: _column.ColumnWidth.S,
        visibility: _types.AttributeVisibility.Table,
        sortable: false
      }, {
        name: 'currencyPaymentId',
        displayName: this.intl.t('forms.secondcurrency'),
        valuePath: 'currency.name',
        type: _types.AttributeType.BelongsTo,
        width: _column.ColumnWidth.XS,
        sortable: false,
        optionsResourceName: 'currency'
      }, {
        name: 'nav',
        displayName: '',
        sortable: false,
        exportable: false,
        visibility: _types.AttributeVisibility.Table,
        width: _column.ColumnWidth.SM
      }, {
        name: 'balance',
        displayName: this.intl.t('finances.balance'),
        sortable: false,
        visibility: _types.AttributeVisibility.Table,
        width: _column.ColumnWidth.S,
        formatExportValue: (value, row, columns) => {
          return row.paidBalance - row.gross;
        }
      }, {
        name: 'payments',
        displayName: this.intl.t('finances.payment'),
        type: _types.AttributeType.HasMany,
        sortable: false,
        visibility: _types.AttributeVisibility.Table,
        width: _column.ColumnWidth.S,
        formatExportValue: (value, row, columns) => {
          if (row.keepTrackPayments && row.datePaid) {
            return columns.find(c => c.name === 'datePaid').formatDisplayValue(row.datePaid);
          }
        }
      }];
    }),
    fetchParams: Ember.computed('columns', 'storage.params', function () {
      let {
        filter
      } = (0, _filters.makeQueryObject)(this.columns, this.storage.params);
      return {
        filter: { ...filter,
          include: [{
            relation: 'partner',
            scope: {
              fields: ['name']
            }
          }, {
            relation: 'currency'
          }, {
            relation: 'paymentMethod',
            scope: {
              fields: ['name']
            }
          }, {
            relation: 'invoiceType',
            scope: {
              fields: ['name', 'code']
            }
          }, 'payments', 'items']
        }
      };
    })
  });

  _exports.default = _default;
});