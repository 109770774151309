define("iris/components/store/view/component", ["exports", "ember-concurrency", "iris/utils/properties"], function (_exports, _emberConcurrency, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    api: Ember.inject.service(),
    path: null,
    params: null,
    // Using the `{{did-update}}` modifier causes an infinte loop. Possibly
    // related: https://github.com/buschtoens/ember-render-helpers/issues/96
    didUpdateArgs: (0, _properties.observerOnce)('path', 'params', function () {
      this.get('fetch').perform();
    }),
    fetch: (0, _emberConcurrency.task)(function* () {
      let result = yield this.api.queryView(this.path, this.params);
      Ember.run.scheduleOnce('afterRender', this, () => {
        this.set('_lastValue', result);
      });
      return result;
    }).restartable(),
    _lastValue: null,
    lastValue: Ember.computed('_lastValue', 'fetch.last.error', function () {
      if (this.fetch.last.error) {
        return Ember.A();
      }

      return this._lastValue || Ember.A();
    })
  });

  _exports.default = _default;
});