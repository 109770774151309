define("iris/routes/tasks/delegates/new", ["exports", "iris/routes/tasks/plans/new"], function (_exports, _new) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _new.default.extend({
    parentRoute: 'tasks.delegates',
    templateName: 'tasks/plans/new',

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('delegated', true);
    },

    actions: {
      afterSave(savedTicket) {
        let promises = Ember.A();
        savedTicket.get('ticketUserAssocs').forEach(ticketUserAssoc => {
          promises.pushObject(ticketUserAssoc.save());
        });
        Ember.RSVP.all(promises).then(() => {
          this.controllerFor('tasks.delegates').send('refresh');
        });
      }

    }
  });

  _exports.default = _default;
});