define("iris/routes/organizations/notification-groups/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NotificationGroup extends Ember.Route {
    model(params) {
      if (params.id == 'new') {
        return this.store.createRecord('notificationGroup');
      }

      return this.store.findRecord('notification-group', params.id).catch(err => {
        return {};
      });
    }

  }

  _exports.default = NotificationGroup;
});