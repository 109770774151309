define("iris/components/tables/partners/component", ["exports", "iris/lib/filters", "iris/lib/filters/column", "iris/lib/filters/storage", "iris/lib/filters/types", "iris/lib/table"], function (_exports, _filters, _column, _storage, _types, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    modals: Ember.inject.service(),
    router: Ember.inject.service(),
    storage: (0, _storage.useStorage)(),
    sessionAccount: (0, _storage.useStorage)(),
    columns: (0, _column.columns)(function () {
      return [{
        name: 'name',
        displayName: this.intl.t('partner.name'),
        width: _column.ColumnWidth.M,
        fixed: true,
        route: 'crm.partners.edit',
        routeParams: (0, _table.rowValues)('id')
      }, {
        name: 'code',
        displayName: this.intl.t('partner.code'),
        width: _column.ColumnWidth.XS
      }, {
        name: 'polity',
        displayName: this.intl.t('partner.state-market'),
        width: _column.ColumnWidth.XS,
        type: _types.AttributeType.Boolean,
        exportable: false,

        formatLoopBackValue(value, filter, defaultFormatter) {
          if (filter.filterType === _types.FilterType.Boolean) {
            return value ? 1 : 0;
          }

          return defaultFormatter(value);
        }

      }, {
        name: 'avarageExpiration',
        displayName: this.intl.t('crm.main.payment-discipline'),
        width: _column.ColumnWidth.XS,
        type: _types.AttributeType.Number
      }, {
        name: 'expiredDays',
        displayName: this.intl.t('partner.expiredDays'),
        width: _column.ColumnWidth.XS,
        type: _types.AttributeType.Number
      }, {
        name: 'domesticPartner',
        displayName: this.intl.t('partner.domesticPartner'),
        width: _column.ColumnWidth.XS,
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'approved',
        displayName: this.intl.t('partner.approved'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.Boolean
      }, {
        name: 'volumen',
        displayName: this.intl.t('partner.volumen'),
        width: _column.ColumnWidth.XS,
        type: _types.AttributeType.Number
      }, {
        name: 'yearlyPotential',
        displayName: this.intl.t('partner.yearlyPotential'),
        width: _column.ColumnWidth.XS,
        type: _types.AttributeType.Number
      }, {
        name: 'potential',
        displayName: this.intl.t('partner.potential'),
        width: _column.ColumnWidth.XS,
        type: _types.AttributeType.Number
      }, {
        name: 'notPaidInvoicesTotal',
        displayName: this.intl.t('partner.notPaidInvoicesTotal'),
        width: _column.ColumnWidth.XS,
        type: _types.AttributeType.Number
      }, {
        name: 'inactive',
        displayName: this.intl.t('filters.show-inactive'),
        type: _types.AttributeType.Boolean,
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'approved',
        displayName: this.intl.t('partner.approved'),
        type: _types.AttributeType.Boolean,
        visibility: _types.AttributeVisibility.Filter
      }];
    }),
    rowActions: (0, _table.rowActions)(function () {
      return [{
        name: this.intl.t('general.deletion'),
        icon: 'trash',
        style: 'danger',

        async action(partner) {
          let shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

          if (shouldDelete) {
            try {
              const partnerRecord = await this.store.findRecord('partner', partner.id);
              const res = await partnerRecord.destroyRecord(true);

              if (res) {
                this.flashMessages.info(this.intl.t('general.delete.success-title'), {
                  title: this.intl.t('general.delete.success'),
                  icon: 'times'
                });
                this.storage.refresh();
              } else {
                partnerRecord.rollback();
              }
            } catch (error) {
              this.flashMessages.danger(error, {
                sticky: true,
                title: this.intl.t('general.delete.error'),
                icon: 'times'
              });
            }
          }
        }

      }];
    }),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    defaultFilters: [],
    fetchParams: Ember.computed('columns', 'storage.params', 'defaultFilters', function () {
      return (0, _filters.makeQueryObject)(this.columns, this.storage.params, {
        defaultFilters: this.defaultFilters
      });
    })
  });

  _exports.default = _default;
});