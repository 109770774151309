define("iris/templates/settings/user-grades/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EhzwZK4B",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-tether\",null,[[\"to\",\"target\",\"targetModifier\",\"attachment\",\"on-overlay-click\",\"tetherClass\",\"overlayClass\"],[\"modal\",\"document.body\",\"visible\",\"middle center\",[28,\"route-action\",[\"back\",[24,[\"model\"]]],null],\"modal-dialog modal-sd\",\"modal-backdrop\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n\\n    \"],[1,[28,\"modal-header\",null,[[\"title\"],[[28,\"t\",[\"settings.user-grades.edit-position\"],null]]]],false],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n      \"],[1,[28,\"forms/acl-user-grade-form\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n      \"],[1,[28,\"buttons/save-button\",null,[[\"action\",\"value\"],[[28,\"route-action\",[\"save\",[28,\"hash\",null,[[\"redirectTo\"],[\"settings.user-grades\"]]]],null],[24,[\"model\"]]]]],false],[0,\"\\n\\n      \"],[1,[28,\"buttons/cancel-button\",null,[[\"action\",\"value\"],[[28,\"route-action\",[\"back\"],null],[24,[\"model\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/user-grades/edit.hbs"
    }
  });

  _exports.default = _default;
});