define("iris/routes/operation/change-requests/new", ["exports", "iris/utils/create-record", "iris/mixins/protected-route"], function (_exports, _createRecord, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    async model() {
      let res = await (0, _createRecord.createChangeRequest)(this.store);
      return res;
    },

    actions: {
      willTransition(transition) {
        const record = this.get('controller.model');

        if (!record.id) {
          record.destroyRecord();
        }
      }

    }
  });

  _exports.default = _default;
});