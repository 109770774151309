define("iris/models/fetchmail", ["exports", "@ember-data/model", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true)],
    host: [(0, _emberCpValidations.validator)('presence', true)],
    port: [(0, _emberCpValidations.validator)('presence', true)],
    user: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', true)],
    fetchmailType: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _model.attr)('string'),
    host: (0, _model.attr)('string'),
    port: (0, _model.attr)('number'),
    lastSuccessfulRun: (0, _model.attr)('date'),
    fetchmailUser: (0, _model.attr)('string'),
    fetchmailPassword: (0, _model.attr)('string'),
    active: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    isSsl: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    original: (0, _model.attr)('boolean'),
    authenticationType: (0, _model.attr)('string'),
    clientId: (0, _model.attr)('string'),
    authority: (0, _model.attr)('string'),
    clientSecret: (0, _model.attr)('string'),
    user: (0, _relationships.belongsTo)('Account'),
    fetchmailType: (0, _relationships.belongsTo)('FetchmailType'),
    fetchmailLogs: (0, _relationships.hasMany)('FetchmailLog')
  });

  _exports.default = _default;
});