define("iris/models/priority-matrix", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    priority: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    priorityUrgency: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    priorityImportance: (0, _emberCpValidations.validator)('presence', {
      presence: true
    })
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    priority: (0, _relationships.belongsTo)('priority'),
    priorityUrgency: (0, _relationships.belongsTo)('priorityUrgency'),
    priorityImportance: (0, _relationships.belongsTo)('priorityImportance'),
    serviceLevel: (0, _relationships.hasMany)('serviceLevel')
  });

  _exports.default = _default;
});