define("iris/components/portal-dashboard/edit/relevant-tickets/component", ["exports", "iris/models/ticket-state", "iris/utils/properties", "moment"], function (_exports, _ticketState, _properties, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    model: null,
    // @
    tickets: (0, _properties.computedTask)('model.{id,createDate}', 'model.contact.id', 'model.businessService.id', function* () {
      let contactId = this.model.get('contact.id');

      if (!contactId) {
        return null;
      }

      let until = this.model.get('createDate') ? (0, _moment.default)(this.model.get('createDate')) : (0, _moment.default)();
      let from = until.clone().subtract(7, 'days');
      let queryOptions = {
        filter: {
          limit: 10,
          where: {
            and: [{
              ticketTypeId: 'SYS_01'
            }, {
              ticketStateId: {
                inq: [_ticketState.TICKET_STATE_ID.Unresolved, _ticketState.TICKET_STATE_ID.InProgress, _ticketState.TICKET_STATE_ID.WaitingForReporter, _ticketState.TICKET_STATE_ID.WaitingForThirdParty, _ticketState.TICKET_STATE_ID.PreReport]
              }
            }, {
              contactId
            }, {
              createDate: {
                gt: from.toDate().toString()
              }
            }, {
              createDate: {
                lt: until.toDate().toString()
              }
            }, {
              businessServiceId: this.model.get('businessService.id')
            }]
          }
        }
      };
      let currentTicketId = this.model.get('id');

      if (currentTicketId) {
        queryOptions.filter.where.and.push({
          id: {
            neq: currentTicketId
          }
        });
      }

      return yield this.store.query('ticket', queryOptions);
    })
  });

  _exports.default = _default;
});