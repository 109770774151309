define("iris/routes/settings/finances/nav-settings/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.store.createRecord('navSetting');
    }

  });

  _exports.default = _default;
});