define("iris/mixins/cmdb-visualisation/navigation", ["exports", "svg-pan-zoom"], function (_exports, _svgPanZoom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    navigation: null,

    initNavigation(targetElement) {
      const paper = this.get('paper');
      const panAndZoom = (0, _svgPanZoom.default)(targetElement.childNodes[2], {
        viewportSelector: targetElement.childNodes[2].childNodes[1],
        fit: false,
        dblClickZoomEnabled: false,
        zoomScaleSensitivity: 0.2,
        panEnabled: false,
        minZoom: 0.1,

        onZoom(scale) {//currentScale = scale;
          //setGrid(paper, gridsize*15*currentScale, '#808080');
        },

        beforePan(oldpan, newpan) {//setGrid(paper, gridsize*15*currentScale, '#808080', newpan);
        }

      }); //Enable pan when a blank area is click (held) on

      paper.on('blank:pointerdown', function (evt, x, y) {
        document.body.style.cursor = 'grabbing';
        panAndZoom.enablePan();
      }); //Disable pan when the mouse button is released

      paper.on('cell:pointerup blank:pointerup', function (cellView, event) {
        panAndZoom.disablePan();
        document.body.style.cursor = 'default';
      });
      this.set('navigation', panAndZoom);
      panAndZoom.fit();
      panAndZoom.panBy(this.offset);
    }

  });

  _exports.default = _default;
});