define("iris/routes/login", ["exports", "jquery", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _jquery, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: function () {
      let store = this.store;
      return Ember.RSVP.hash({
        credential: store.createRecord('credential'),
        passwordChangeRequest: store.createRecord('changePasswordRequest')
      });
    },
    activate: function () {
      (0, _jquery.default)('body').addClass('am-splash-screen');
    },
    deactivate: function () {
      (0, _jquery.default)('body').removeClass('am-splash-screen');
    },
    actions: {
      login: function () {
        var username = this.controller.get('model.credential.username');
        var password = this.controller.get('model.credential.password');
        this.get('session').authenticate('authenticator:custom', username, password).then(() => {}).catch(reason => {
          this.controller.set('errorMessage', reason.errors || reason);
        });
      },
      savePasswordChangeRequest: function (changeRequest) {
        const flashMessages = Ember.get(this, 'flashMessages');
        changeRequest.save().then(() => {}).catch(err => {
          let errorMessages = '';

          if (err.errors) {
            err.errors.forEach(error => {
              errorMessages += error.detail + ".";
            });
          } else {
            errorMessages += err;
          }

          flashMessages.add({
            message: errorMessages + '!!!',
            title: 'IRIS message',
            icon: 'warning'
          });
        });
        this.controller.set('showModalDialog', false);
        this.controller.set('emailSent', true);
      }
    },

    setupController(controller, model) {
      this._super(controller, model);

      (0, _jquery.default)(function () {
        _jquery.default.getJSON("http://jsonip.com/?callback=?", function (data) {
          model.passwordChangeRequest.set('ip', data.ip);
        });
      });
    }

  });

  _exports.default = _default;
});