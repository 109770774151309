define("iris/components/action-competency-file", ["exports", "underscore"], function (_exports, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selected: Ember.computed('model.fileActionCompetencyAssocs.[]', 'action', {
      get() {
        let res = Ember.A();
        let id = this.get('action.id');

        let _this = this;

        this.get('model.fileActionCompetencyAssocs').forEach(fileCompetency => {
          fileCompetency.get('action').then(action => {
            if (action.get('id') === id) {
              fileCompetency.get('competency').then(competency => {
                res.addObject(competency);

                _this.set('selected', res);
              });
            }
          });
        });
        return res;
      },

      set(key, value) {
        return value;
      }

    }),
    actions: {
      competencies() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        let [action, file, oldCompetencies, newCompetencies] = args;
        let id = action.get('id');
        let store = this.store;
        let oldIds = oldCompetencies.mapBy('id');
        let newIds = newCompetencies.mapBy('id');

        let toSave = _underscore.default.difference(newIds, oldIds);

        let toDelete = _underscore.default.difference(oldIds, newIds);

        file.get('fileActionCompetencyAssocs').then(fileActionCompetencyAssocs => {
          fileActionCompetencyAssocs.forEach(faca => {
            let actionId = faca.belongsTo('action').id();

            if (actionId === id) {
              let competencyId = faca.belongsTo('competency').id();

              if (toDelete.contains(competencyId)) {
                faca.destroyRecord(true);
              }
            }
          });
          newCompetencies.forEach(competency => {
            if (toSave.contains(competency.get('id'))) {
              let record = store.createRecord('fileActionCompetencyAssoc', {
                file: file,
                competency: competency,
                action: action
              });
              record.save(true);
            }
          });
        });
      }

    }
  });

  _exports.default = _default;
});