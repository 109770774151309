define("iris/components/dashboard/work-logs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "170xREZ5",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"did-insert\",[[28,\"perform\",[[23,0,[\"getWorkLogs\"]]],null]],null],false],[0,\"\\n\"],[7,\"h4\",true],[10,\"class\",\"h4\"],[8],[1,[28,\"moment-format\",[[24,[\"now\"]],\"YYYY Wo\"],null],false],[0,\"\\n  \"],[1,[28,\"t\",[\"dashboard.weekly-statement\"],null],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row text-center\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-3\"],[8],[0,\"\\n    \"],[5,\"fa-icon\",[[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"stepBack\"],null]]]],[[\"@icon\"],[\"chevron-left\"]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-3\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"isThisWeek\"]]],null,{\"statements\":[[0,\"      \"],[5,\"fa-icon\",[[12,\"class\",\"pull-right\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"stepForward\"],null]]]],[[\"@icon\"],[\"chevron-right\"]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"chart-js\",[],[[\"@data\",\"@type\",\"@options\"],[[23,0,[\"data\"]],\"bar\",[22,\"options\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/dashboard/work-logs/template.hbs"
    }
  });

  _exports.default = _default;
});