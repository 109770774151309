define("iris/components/forms/scheduled-task-edit/priority-edit/component", ["exports", "iris/components/issue-report-edit/priority-edit/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ScheduledPriorityEdit extends _component.default {}

  _exports.default = ScheduledPriorityEdit;
});