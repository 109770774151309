define("iris/templates/components/forms/file-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZVMA+O7E",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"type\"]],\"operational\"],null]],null,{\"statements\":[[0,\"  \"],[1,[22,\"forms/file-overview/file-operational\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"forms/file-overview/file-normal\",null,[[\"model\",\"disableInput\",\"disableInputPlanning\"],[[24,[\"model\"]],[28,\"or\",[[24,[\"disableInput\"]],[24,[\"model\",\"planPeriodCloseDate\"]]],null],[24,[\"disableInputPlanning\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/file-overview.hbs"
    }
  });

  _exports.default = _default;
});