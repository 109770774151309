define("iris/models/resource-plan", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "ember-data-copyable"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    plannedHours: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    priority: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    resourcePeriod: (0, _emberCpValidations.validator)('presence', {
      presence: true
    })
  });

  var _default = _model.default.extend(Validations, _emberDataCopyable.default, {
    plannedHours: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    priority: (0, _relationships.belongsTo)('resourcePlanPriority'),
    resourcePeriod: (0, _relationships.belongsTo)('resourcePeriod'),
    tua: (0, _relationships.belongsTo)('ticketUserAssoc')
  });

  _exports.default = _default;
});