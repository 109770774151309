define("iris/components/issue-report/ticket-body/component", ["exports", "iris/utils/properties", "iris/lib/modals", "iris/lib/aiops/mapper"], function (_exports, _properties, _modals, _mapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IssueReportTicketBody = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = (0, _properties.computedTask)('model.aiopsTicket', function* () {
    const aiopsTicket = yield this.model.get('aiopsTicket');

    if (!aiopsTicket) {
      return false;
    }

    return _mapper.TicketProcessStates.Unsuccess === parseInt(aiopsTicket.belongsTo('processStatus').id());
  }), _dec7 = (0, _properties.computedTask)('model.aiopsTicket', function* () {
    const aiopsTicket = yield this.model.get('aiopsTicket');

    if (!aiopsTicket) {
      return false;
    }

    return this.get('model.aiopsTicket.errorReason') ? 'danger' : 'warning';
  }), _dec8 = (0, _properties.computedTask)('model.aiopsTicket', function* () {
    const aiopsTicket = yield this.model.get('aiopsTicket');

    if (!aiopsTicket) {
      return false;
    }

    return this.get('model.aiopsTicket.errorReason') ? this.get('model.aiopsTicket.errorReason').split(',') : [];
  }), _dec9 = (0, _properties.computedTask)('model.contact', function* () {
    // simple yield this.model.get('contact') won't work, it gives back a Proxy
    // https://discuss.emberjs.com/t/how-to-load-proxy-relationships-in-ember-testing/18899/2
    let contact = yield this.model.belongsTo('contact').value();
    let partnerId = contact === null || contact === void 0 ? void 0 : contact.belongsTo('partner').id();

    if (!partnerId) {
      return [];
    }

    let contactId = contact.id;
    return yield this.get('ajax').call('GET', 'entity', contactId, 'businessServices', {});
  }), _dec10 = Ember.computed('model.state.id'), _dec11 = Ember.computed('businessServiceIds.[]'), _dec12 = Ember.computed('uploader.files.[]', 'model.id'), _dec13 = Ember.computed('model.id', 'model.businessService'), _dec14 = Ember.computed('setTicketTypes.isRunning', 'setOperationServiceLevel.isRunning'), _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, (_class = class IssueReportTicketBody extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "afterTicketTypeChange", null);

      _defineProperty(this, "setTicketTypes", null);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "modals", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "uploader", _descriptor4, this);

      _initializerDefineProperty(this, "sessionAccount", _descriptor5, this);

      _initializerDefineProperty(this, "isUnsuccess", _descriptor6, this);

      _initializerDefineProperty(this, "alertClass", _descriptor7, this);

      _initializerDefineProperty(this, "errorReasons", _descriptor8, this);

      _initializerDefineProperty(this, "businessServiceIds", _descriptor9, this);
    }

    async didInsertElement() {
      this._super(...arguments);

      if (this.preTicket) {
        await this.setOperationServiceLevel.perform();
        await this.setTicketTypes.perform();
        await this.afterTicketTypeChange.perform(this.model.get('type'));
        await this.setUrgency.perform();
      }
    }

    get textInputDisabled() {
      return false;
    }

    get businessServices() {
      const {
        businessServiceIds
      } = this;
      if (Ember.isEmpty(businessServiceIds)) return [];
      return this.get('store').query('businessService', {
        filter: {
          where: {
            id: {
              inq: businessServiceIds
            }
          },
          include: ['operationServiceLevel']
        }
      });
    }

    get attachmentTitle() {
      const files = this.model.id ? this.model.hasMany('documents').ids() : this.uploader.files.filter(f => f.queue.name === 'issue-report');
      const title = this.intl.t('incident-form.attachments');
      return files.length ? `${title} (${files.length})` : title;
    }

    get entityToVisualise() {
      var _this$model;

      const id = (_this$model = this.model) === null || _this$model === void 0 ? void 0 : _this$model.belongsTo('businessService').id();

      if (!id) {
        return null;
      }

      return this.store.find('entity', id);
    }

    get loadingTicketTypes() {
      var _this$setTicketTypes;

      return ((_this$setTicketTypes = this.setTicketTypes) === null || _this$setTicketTypes === void 0 ? void 0 : _this$setTicketTypes.isRunning) || this.setOperationServiceLevel.isRunning;
    }

    async setBusinessService(businessService) {
      const {
        model
      } = this;
      model.set('businessService', businessService);
      model.set('type', null);
      model.set('serviceLevel', null);
      await this.setOperationServiceLevel.perform();
      await this.setTicketTypes.perform();
    }

    async setTicketType(ticketType) {
      var _this$afterTicketType;

      // reselect an already selected ticket type runs afterTicketTypeChange, what
      // nulls out selectedUrgency and selectedImportance. this prevents it
      if (this.model.belongsTo('type').id() === ticketType.get('id')) {
        return;
      }

      this.model.set('type', ticketType);
      await ((_this$afterTicketType = this.afterTicketTypeChange) === null || _this$afterTicketType === void 0 ? void 0 : _this$afterTicketType.perform(ticketType));
    }

    async showVisualisation() {
      const affectedCIelementIDs = this.model.hasMany('entities').ids();
      await this.modals.open('issue-report/modals/visualisation', {
        title: this.intl.t('incident-form.visualization'),
        entityToVisualise: this.entityToVisualise,
        affectedCIelementIDs
      }, {
        className: _modals.ModalLayoutClassName.Grow
      });
    }

    async showAttachments() {
      const attachment = await this.modals.open('issue-report/modals/attachments', {
        title: this.intl.t('incident-form.attachments'),
        ticket: this.model
      }, {
        className: _modals.ModalLayoutClassName.M
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uploader", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionAccount", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isUnsuccess", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "alertClass", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "errorReasons", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "businessServiceIds", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "textInputDisabled", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "textInputDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "businessServices", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "businessServices"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "attachmentTitle", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "attachmentTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "entityToVisualise", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "entityToVisualise"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadingTicketTypes", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "loadingTicketTypes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setBusinessService", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "setBusinessService"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setTicketType", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "setTicketType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showVisualisation", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "showVisualisation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showAttachments", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "showAttachments"), _class.prototype)), _class));
  _exports.default = IssueReportTicketBody;
});