define("iris/components/store/find/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "noUiXMKM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"result\",\"error\",\"isLoading\",\"trigger\"],[[23,0,[\"findRecordTask\",\"last\",\"value\"]],[23,0,[\"findRecordTask\",\"last\",\"error\"]],[23,0,[\"findRecordTask\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"findRecordTask\"]]],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/store/find/template.hbs"
    }
  });

  _exports.default = _default;
});