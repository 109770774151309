define("iris/components/forms/entity-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currencies: Ember.computed('model.id', function () {
      return this.store.findAll('currency');
    }),
    contacts: Ember.computed('model.id', function () {
      return this.store.query('contact', {
        filter: {
          order: 'name'
        }
      });
    })
  });

  _exports.default = _default;
});