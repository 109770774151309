define("iris/components/tables/ticket-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    columns: computed(function () {
      return [{
        name: this.intl.t('ticket-user-assoc.plannedExpensesInHour'),
        property: 'plannedExpensesInHour'
      }, {
        name: this.intl.t('account.modelName'),
        property: 'foo'
      }, {
        name: this.intl.t('forms.fulladdress'),
        property: 'foo'
      }];
    }),
    toEdit: null,
    actions: {
      remove(ticketUserAssoc) {
        let ticketUserAssocs = this.get('data');
        let toDelete = this.get('ticket.toDelete.ticketUser');
        toDelete.addObject(ticketUserAssoc);
        ticketUserAssocs.removeObject(ticketUserAssoc);
      },

      edit(ticketUserAssoc) {
        this.set('toEdit', ticketUserAssoc);
      },

      endEdit() {
        this.set('toEdit', null);
      },

      cancel() {
        this.get('toEdit').rollback();
        this.send('endEdit');
      }

    }
  });

  _exports.default = _default;
});