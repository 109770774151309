define("iris/routes/organizations/sites/locations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      afterSave: function (savedRecord) {
        savedRecord.get('customValues').forEach(customValue => {
          customValue.set('modelId', savedRecord.get('id'));
          customValue.save();
        });
      }
    }
  });

  _exports.default = _default;
});