define("iris/components/forms/acl-user-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    _updateFileBlob(cropper) {
      return cropper.getCroppedCanvas({
        // any additional options
        maxWidth: 512,
        maxHeight: 512
      }).toBlob(async blob => {
        const cropped = await this.blobToBase64(blob);
        this.model.set('userPhoto', cropped);
      });
    },

    blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();

        reader.onloadend = () => resolve(reader.result);

        reader.readAsDataURL(blob);
      });
    },

    actions: {
      onInitOfUploader() {},

      async uploadImage(file) {
        const url = await file.read();
        this.set('url', url);
      },

      crop(cropper) {
        Ember.run.debounce(this, this._updateFileBlob, cropper, 300);
      },

      finish() {
        this.set('url', null);
      }

    }
  });

  _exports.default = _default;
});