define("iris/routes/settings/operations/solving-group/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SolvingGroupEditRoute extends Ember.Route {
    model(params) {
      if (params.solving_group_id == 'new') {
        return this.store.createRecord('solvingGroup');
      }

      return this.store.findRecord('solvingGroup', params.solving_group_id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = SolvingGroupEditRoute;
});