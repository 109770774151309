define("iris/utils/human-resource-management/event-manager/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.saveAccount = _exports.queryEvents = _exports.queryAccount = void 0;

  const queryAccount = async (store, id) => {
    let accounts = await store.query('account', {
      filter: {
        include: ['leader'],
        where: {
          id: {
            eq: id
          }
        }
      }
    });
    return accounts.firstObject;
  };

  _exports.queryAccount = queryAccount;

  const saveAccount = async (store, account) => {
    await account.save(true); // God, I so hate this param... 🤯
    // If the account wasn't reloaded, its relationships would've been reset.

    return queryAccount(store, account.id);
  };

  _exports.saveAccount = saveAccount;

  const queryEvents = (store, accountId) => {
    return store.query('event', {
      filter: {
        where: {
          and: [{
            userId: accountId
          }]
        },
        include: ['eventDays', 'eventType']
      }
    });
  };

  _exports.queryEvents = queryEvents;
});