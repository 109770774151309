define("iris/classes/filter/filter", ["exports", "moment", "iris/utils/enum"], function (_exports, _moment, _enum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.operatorsForType = _exports.nameForOperator = _exports.default = _exports.Operator = _exports.AttributeType = void 0;
  const AttributeType = (0, _enum.makeEnum)([{
    String: 'string'
  }, {
    Number: 'number'
  }, {
    Checkbox: 'checkbox'
  }, {
    DateTime: 'datetime'
  }, {
    Date: 'date'
  }, {
    Array: 'array'
  }, {
    BelongsTo: 'belongsTo'
  }, {
    HasMany: 'hasMany'
  }]);
  _exports.AttributeType = AttributeType;
  const Operator = (0, _enum.makeEnum)([{
    LessThan: 'lt'
  }, {
    GreaterThan: 'gt'
  }, {
    Equal: 'eq'
  }, {
    Contains: 'ilike'
  }, {
    Boolean: 'bool'
  }, {
    Null: 'null'
  }]);
  _exports.Operator = Operator;

  var _default = Ember.Object.extend({
    operator: null,
    value: undefined,
    // { name: string, value: string | null | undefined }
    type: null,
    // AttributeType
    property: null,
    // EntityAttribute['property']
    name: null,
    // EntityAttribute['name']
    operatorValue: Ember.computed.alias('operator.value'),
    intl: Ember.inject.service(),

    init() {
      // This is for testing purpose
      if (this.owner) {
        Ember.setOwner(this, this.owner);
      }
    },

    serialize() {
      if (!this.get('isValid')) {
        return null;
      }

      let operator = this.get('operator.value');
      let value = this.get('value');

      if (operator === Operator.Null) {
        value = null;
      } else if (operator === Operator.Boolean) {
        value = !!value;
      } else if (operator === Operator.Contains) {
        value = `%${value}%`;
      } else if (this.type === AttributeType.Date) {
        value = (0, _moment.default)(value).toString();
      }

      return {
        type: this.type,
        property: this.property,
        name: this.name,
        operator,
        value
      };
    },

    deserialize(_ref) {
      let {
        type,
        property,
        name,
        operator,
        value
      } = _ref;
      this.set('type', type);
      this.set('property', property);
      this.set('name', name);
      let operatorOption = this.operatorOptions.find(o => o.value === operator);

      if (operatorOption) {
        this.set('operator', operatorOption);
      } else {
        this.selectFirstOperator();
      }

      let deserializedValue;

      if (operator === Operator.Contains) {
        deserializedValue = value.slice(1, -1); // Remove %...% around the string
      } else if (type === AttributeType.Date) {
        deserializedValue = Date.parse(value);
      } else {
        deserializedValue = value;
      }

      this.set('value', deserializedValue);
      return this;
    },

    setName(newName) {
      this.set('name', newName);
    },

    // Use this method instead of `filter.set('entityAttribute')`, because this
    // will perform a few other tasks to make sure the initial state for a new
    // `entityAttribute` is always correct. One example for an incorrect state is
    // having an operator selected when the particular `entityAttribute` type is
    // incompatible with the operator, or can't have value, for ex. when the
    // operator is `null`
    setEntityAttribute(entityAttribute) {
      let shouldClearValue = !entityAttribute || this.type !== entityAttribute.get('type');
      this.set('type', entityAttribute.type);
      this.set('property', entityAttribute.property);
      this.set('name', entityAttribute.name);

      if (shouldClearValue) {
        this.set('value', undefined);
        this.selectFirstOperator();
      }
    },

    selectFirstOperator() {
      let [operatorOption] = this.get('operatorOptions');
      this.set('operator', operatorOption);
    },

    isValid: Ember.computed('property', 'value', 'canHaveValue', 'operator.value', function () {
      if (!this.property) {
        return false;
      } // Boolean values are undefined on init which would validate to false, which
      // is why it's always treated as valid - they're serialized correctly later
      // anyway


      if (!this.get('canHaveValue') || this.get('operator.value') === Operator.Boolean) {
        return true;
      }

      if (this.type === AttributeType.Date) {
        // `undefined` is a valid date for moment ¯\_(ツ)_/¯
        if (!this.get('value')) {
          return false;
        }

        return (0, _moment.default)(this.get('value')).toString() !== 'Invalid date';
      }

      return !!this.get('value');
    }),
    canHaveValue: Ember.computed('operator.value', function () {
      let op = this.get('operator.value');
      return !!op && op !== Operator.Null;
    }),
    operators: Ember.computed('type', function () {
      return operatorsForType(this.type);
    }),
    operatorOptions: Ember.computed('operators.[]', function () {
      if (!Array.isArray(this.get('operators'))) {
        return [];
      }

      return this.get('operators').map(operator => {
        return {
          name: nameForOperator(operator, this.intl),
          value: operator
        };
      });
    })
  });

  _exports.default = _default;

  const operatorsForType = attributeType => {
    let operatorMap = new Map();
    operatorMap.set(AttributeType.Array, [Operator.Contains, Operator.Null]);
    operatorMap.set(AttributeType.BelongsTo, [Operator.Contains, Operator.Equal, Operator.Null]);
    operatorMap.set(AttributeType.Checkbox, [Operator.Boolean, Operator.Null]);
    operatorMap.set(AttributeType.Date, [Operator.Equal, Operator.LessThan, Operator.GreaterThan, Operator.Null]);
    operatorMap.set(AttributeType.HasMany, [Operator.Contains, Operator.Null]);
    operatorMap.set(AttributeType.Number, [Operator.Equal, Operator.LessThan, Operator.GreaterThan, Operator.Null]);
    operatorMap.set(AttributeType.String, [Operator.Contains, Operator.Equal, Operator.Null]);
    return operatorMap.get(attributeType);
  };

  _exports.operatorsForType = operatorsForType;

  const nameForOperator = (operator, intl) => {
    let operatorNameMap = new Map();
    operatorNameMap.set(Operator.LessThan, intl.t('filters.operators.LessThan'));
    operatorNameMap.set(Operator.GreaterThan, intl.t('filters.operators.GreaterThan'));
    operatorNameMap.set(Operator.Equal, intl.t('filters.operators.Equal'));
    operatorNameMap.set(Operator.Contains, intl.t('filters.operators.Contains'));
    operatorNameMap.set(Operator.Boolean, intl.t('filters.operators.Boolean'));
    operatorNameMap.set(Operator.Null, intl.t('filters.operators.Null'));
    return operatorNameMap.get(operator);
  };

  _exports.nameForOperator = nameForOperator;
});