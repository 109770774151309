define("iris/routes/unauth/confirmation/application-to-intervene", ["exports", "underscore"], function (_exports, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),

    model(params) {
      let {
        token
      } = params;
      let endpoint = token.charAt(0) === 'c' ? 'ticketInterventionContact' : 'ticketInterventionUser';
      this.set('endpoint', endpoint);
      this.set('token', token);
      return this.get('ajax').call('GET', endpoint, '', 'confirmation/' + token, null, false);
    },

    setupController(controller, model) {
      this._super(controller, model);

      if (model) {
        this.controller.set('ticket', model.ticket);
        this.controller.set('message', '');
        let children = Ember.get(model, 'ticket.children');

        if (children.length) {
          let expenses = _underscore.default.map(children, child => {
            return child.plannedExpensesInHour;
          });

          let sum = _underscore.default.reduce(expenses, (memo, num) => {
            return memo + parseFloat(num);
          }, 0);

          this.controller.set('sum', sum);
        }
      }
    },

    actions: {
      confirmation(value) {
        let msg = this.controller.get('message');
        const flashMessages = Ember.get(this, 'flashMessages');

        if (msg.length > 300) {
          flashMessages.add({
            message: this.intl.t('unauth.messageMaxChar'),
            title: 'IRIS message',
            icon: 'info'
          });
          return;
        }

        let endpoint = this.get('endpoint');
        let token = this.get('token');
        this.get('ajax').call('POST', endpoint, '', 'confirmation', {
          value: value,
          token: token,
          message: msg
        }, false).then(res => {
          this.controller.set('confirmed', true);
        }).catch(err => {
          flashMessages.add({
            message: err,
            title: 'IRIS message',
            icon: 'info'
          });
        });
      }

    }
  });

  _exports.default = _default;
});