define("iris/components/modals/completion-certificate/signer-alert/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    signer: null,
    actions: {
      async generateCompletionCertificate() {
        this.data.file.set('completionCertSigner', this.signer);
        await this.data.file.save();
        this.close(this.signer);
      }

    }
  });

  _exports.default = _default;
});