define("iris/routes/crm/operational-file/index", ["exports", "iris/routes/operation/operational-file/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _index.default.extend({
    templateName: 'crm/operational-file/index'
  });

  _exports.default = _default;
});