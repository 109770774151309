define("iris/components/forms/sites/building/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6r5yOw3p",
    "block": "{\"symbols\":[\"option\",\"option\",\"@model\"],\"statements\":[[4,\"if\",[[23,3,[\"isLoaded\"]]],null,{\"statements\":[[0,\"  \"],[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@frame\",\"@needClose\"],[[23,3,[]],\"organizations.sites.buildings\",true,true]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n        \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\",\"@proportion\"],[[23,3,[]],\"name\",\"3-9\"]]],[0,\"\\n\\n        \"],[5,\"form-group\",[],[[\"@property\",\"@label\",\"@proportion\"],[\"clientPartner\",[28,\"t\",[\"building.clientPartner\"],null],\"3-9\"]],{\"statements\":[[0,\"\\n          \"],[5,\"input/select/lazy\",[],[[\"@modelName\",\"@selected\",\"@onchange\"],[\"partner\",[23,3,[\"clientPartner\"]],[28,\"action\",[[23,0,[]],\"changePartner\"],null]]],{\"statements\":[[0,\"\\n            \"],[1,[23,2,[\"name\"]],false],[0,\"\\n          \"]],\"parameters\":[2]}],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\\n        \"],[5,\"form-group\",[],[[\"@property\",\"@label\",\"@proportion\"],[\"address\",[28,\"t\",[\"building.address\"],null],\"3-9\"]],{\"statements\":[[0,\"\\n          \"],[5,\"power-select\",[],[[\"@options\",\"@selected\",\"@onChange\"],[[23,0,[\"addresses\"]],[23,3,[\"address\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,3,[\"address\"]]],null]],null]]],{\"statements\":[[0,\"\\n            \"],[1,[23,1,[\"fullAddress\"]],false],[0,\"\\n          \"]],\"parameters\":[1]}],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/forms/sites/building/template.hbs"
    }
  });

  _exports.default = _default;
});