define("iris/components/christmas", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Christmas = (_dec = Ember.inject.service, _dec2 = Ember.computed('christmasEnabled', 'themeSwitcher.dark'), _dec3 = Ember._action, (_class = class Christmas extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "themeSwitcher", _descriptor, this);

      _defineProperty(this, "christmasEnabled", false);
    }

    get flakes() {
      if (!this.christmasEnabled) {
        return [];
      }

      let flakes = [];

      function random(num) {
        return Math.floor(Math.random() * num);
      }

      for (let i = 0; i < 100; i++) {
        var _this$themeSwitcher;

        flakes.push(`
        <div
          class="snowflake"
          style="
            --left: ${random(100)}vw;
            --left-ini: ${random(20) - 10}vw;
            --left-end: ${random(20) - 10}vw;
            --speed: ${5 + random(15)}s;
            --size: ${random(5) * 0.2}vw;
            --delay: -${random(15)}s;
            --opacity: ${random(10000) * 0.0001};
            ${!((_this$themeSwitcher = this.themeSwitcher) !== null && _this$themeSwitcher !== void 0 && _this$themeSwitcher.dark) ? 'box-shadow: 0px 0.6px 0px black;' : ''}"
        ></div>`);
      }

      return flakes;
    }

    toggleChristmas() {
      Ember.set(this, 'christmasEnabled', !this.christmasEnabled);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "themeSwitcher", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "flakes", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "flakes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleChristmas", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "toggleChristmas"), _class.prototype)), _class));
  _exports.default = Christmas;
});