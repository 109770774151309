define("iris/models/opportunity-view", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    partnerShortname: (0, _attr.default)('string'),
    stateName: (0, _attr.default)('string'),
    stateId: (0, _attr.default)('string'),
    projectValue: (0, _attr.default)('number'),
    probability: (0, _attr.default)('number'),
    weightedIncome: (0, _attr.default)('number'),
    stateChange: (0, _attr.default)('date'),
    mustBePlanned: (0, _attr.default)('number'),
    projectValueCurrencyCode: (0, _attr.default)('string')
  });

  _exports.default = _default;
});