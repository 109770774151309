define("iris/models/pipeline-view", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { belongsTo, hasMany } from 'ember-data/relationships';
  var _default = _model.default.extend({
    partnerName: (0, _attr.default)('string'),
    fileName: (0, _attr.default)('string'),
    domesticPartner: (0, _attr.default)('string'),
    typeName: (0, _attr.default)('string'),
    primeContractor: (0, _attr.default)('string'),
    partnerSector: (0, _attr.default)('string'),
    internalAccounting: (0, _attr.default)('string'),
    businessTypeName: (0, _attr.default)('string'),
    stateName: (0, _attr.default)('string'),
    probability: (0, _attr.default)('number'),
    dateStart: (0, _attr.default)('date'),
    dateEnd: (0, _attr.default)('date'),
    income: (0, _attr.default)('number'),
    proposalIncome: (0, _attr.default)('number'),
    weightedIncome: (0, _attr.default)('number')
  });

  _exports.default = _default;
});