define("iris/components/beta/filters/component", ["exports", "@salsify/ember-block-slots"], function (_exports, _emberBlockSlots) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The filters component contain the UI and the business logic for managing
   * filters.
   *
   * - Manages filters
   *   - Narrows down columns excluding table columns
   *   - Handles fuzzy search, narrowing down to columns that have fuzzy search
   *     enabled
   * - Displays bulk actions when a selection is made, otherwise the list of
   *   filters
   */
  var _default = Ember.Component.extend(_emberBlockSlots.default, {
    tagName: '',
    showResetFiltersTab: true // @

  });

  _exports.default = _default;
});