define("iris/models/incoming-invoice-item", ["exports", "ember-data", "ember-data-copyable", "ember-cp-validations"], function (_exports, _emberData, _emberDataCopyable, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo
  } = _emberData.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    description: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 512
    })],
    quantity: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    uom: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    price: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    discount: (0, _emberCpValidations.validator)('presence', {
      presence: true
    })
  });

  var _default = _emberData.default.Model.extend(_emberDataCopyable.default, Validations, {
    description: attr('string'),
    quantity: attr('number', {
      defaultValue: 0
    }),
    price: attr('number', {
      defaultValue: 0
    }),
    netto: attr('number'),
    vatValue: attr('number'),
    vat: attr('number'),
    brutto: attr('number'),
    discount: attr('number', {
      defaultValue: 0
    }),
    service: attr('boolean'),
    serialNumber: attr('string'),
    needsAction: attr('boolean', {
      defaultValue: false
    }),
    checklist: attr('json'),
    uom: belongsTo('uom'),
    vatRel: belongsTo('vat'),
    invoice: belongsTo('incomingInvoice'),
    dataSource: belongsTo('dataSource')
  });

  _exports.default = _default;
});