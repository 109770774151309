define("iris/mixins/handle-merged-tickets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // computes isMerge property, based on the filter settings
    // buttons, columns, etc. can consume and change based on the mode
    isMerge: Ember.computed('refresh', function () {
      let columnFilterOptions = this.get('columnFilterOptions');
      let isMerge = columnFilterOptions ? columnFilterOptions.find(cfo => {
        return cfo.property === 'mergedParentId' && cfo.value == true;
      }) : false;

      if (!isMerge) {
        for (let i = 0; i < columnFilterOptions.length; i++) {
          if (columnFilterOptions[i].fieldSet) {
            let found = columnFilterOptions[i].fieldSet.fields.find(innerCfo => {
              return innerCfo.property === 'mergedParentId' && innerCfo.value == true;
            });

            if (found) {
              isMerge = true;
              break;
            }
          }
        }
      }

      return isMerge ? true : false;
    }),
    actions: {
      closeMergeModal() {
        this.set('currentSelectedMainTicket', null);
        this.set('mergeModal', false);
      },

      closeUnMergeModal() {
        this.set('unMergeModal', false);
      },

      async unMergeTicket() {
        this.get('selectedRecords').forEach(async record => {
          let ticketRecord = await this.get('store').find('ticket', record.id);
          ticketRecord.set('mergedParentId', null);
          ticketRecord.set('mergedParent', null);
          await ticketRecord.save();
        });
        this.set('selectedRecords', Ember.A());
        this.set('unMergeModal', false);
      },

      afterSearch() {
        this.notifyPropertyChange('isMerge');
      },

      async saveMainTicket(mainTicket) {
        let mainTicketRecord = await this.get('store').find('ticket', mainTicket.id);
        this.get('selectedRecords').forEach(async record => {
          let ticketRecord = await this.get('store').find('ticket', record.id);

          if (mainTicketRecord.id !== ticketRecord.id) {
            await ticketRecord.set('mergedParentId', mainTicketRecord.id);
            await ticketRecord.set('mergedParent', mainTicketRecord);
            await ticketRecord.save();
          }
        });
        this.set('currentSelectedMainTicket', null);
        this.set('mergeModal', false);
        this.set('refresh', !this.refresh);
        this.set('selectedRecords', Ember.A());
      }

    }
  });

  _exports.default = _default;
});