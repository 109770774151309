define("iris/routes/crm/opportunities/index", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    uploadDocuments: Ember.inject.service(),
    firstRoute: 'crm.opportunities.edit.overview',
    actionCreate: ['projects.files.create', 'projects.files'],
    actionFinance: ['projects.files.data.finance', 'projects.files.data.finance.read', 'projects.files.data.finance.write'],

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('firstRoute', this.get('firstRoute'));
      controller.set('filterOptions', this.get('filterOptions'));
      controller.set('actionCreate', this.get('actionCreate'));
      controller.set('actionFinance', this.get('actionFinance'));
    },

    actions: {
      uploadAttachments: function (file) {
        this.set('file', file);
      },
      deleteAttachments: function (deleteAttachments) {
        this.set('deleteAttachments', deleteAttachments);
      },

      // Be sure to update 'app/routes/projects/files.js' as well, as the two are
      // the same action.
      async duplicateTask() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        let [task] = args;
        let copy = await task.copy();
        let state = await task.get('state');
        let numberOfCopies = 0;
        let project = await task.get('file');
        let tasks = await project.get('tickets');

        for (let t of tasks.toArray()) {
          if (t.get('subject').includes('(másolat)')) numberOfCopies++;
        }

        numberOfCopies = numberOfCopies > 0 ? numberOfCopies : '';
        copy.set('subject', `${task.get('subject')} (másolat) ${numberOfCopies}`);
        copy.set('state', state);
      }

    }
  });

  _exports.default = _default;
});