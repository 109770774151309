define("iris/templates/cmdb/entity-types/entities/new-entity/software/edit/software", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NW2Zojbk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"hide-second\"],[8],[0,\"\\n  \"],[1,[28,\"tables/entity-software\",null,[[\"model\",\"mode\",\"softwareMode\"],[[24,[\"model\"]],\"new-entity\",\"edit\"]]],false],[0,\"\\n  \"],[1,[22,\"liquid-outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/entity-types/entities/new-entity/software/edit/software.hbs"
    }
  });

  _exports.default = _default;
});