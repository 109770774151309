define("iris/lib/filters/api", ["exports", "iris/utils/array", "iris/lib/filters/column", "iris/lib/filters/data", "iris/lib/filters/filter", "iris/lib/filters/types", "iris/lib/filters/utils"], function (_exports, _array, _column, _data, _filter, _types, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeWhereCondition = _exports.makeQueryObject = _exports.makeOptionsQueryObject = _exports.makeOptionsQueryDependentConditions = _exports.makeLoopBackConditionFromFilter = _exports.makeFuzzyFilters = void 0;

  /**
   * Creates a LoopBack query object that contains `limit`, `skip`, `order` and
   * the `where` condition inside its `filter` key.
   *
   * Example query object:
   *
   * ```js
   * {
   *  filter: {
   *    limit: 25,
   *    skip: 0,
   *    order: 'asd',
   *    where: {
   *      name: {
   *        ilike: '%yolo%'
   *      },
   *    },
   *  }
   * }
   * ```
   *
   * Note that custom attribute filters are not serialized by default, so it's the
   * caller's responsibilty to provide a serializer function that receives the
   * filters and the where condition, which, so far contains the serialized
   * filters & default filters. Merge it with whatever format the current endpoint
   * accepts custom filters in. It must return the new where condition.
   */
  const makeQueryObject = function (columns, queryParams) {
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    let {
      page,
      limit,
      order,
      q,
      filters: qpFilters
    } = queryParams;
    let {
      defaultFilters: defaultFiltersParam,
      serializeCustomFilters
    } = options;
    let skip = page * limit - limit;
    let filterColumns = (0, _column.filterByVisiblity)(columns, _types.AttributeVisibility.Filter);
    let filters = (0, _filter.makeFiltersFromQueryParams)(filterColumns, qpFilters);
    let defaultFilters = buildDefaultFilters(filters, defaultFiltersParam !== null && defaultFiltersParam !== void 0 ? defaultFiltersParam : []);
    let columnFilters = filters.filterBy('placement', _types.AttributePlacement.Column);
    let customAttributeFilters = filters.filterBy('placement', _types.AttributePlacement.CustomAttribute);
    let inactive = false;
    columnFilters = columnFilters.filter(column => {
      if (column.attributeName === 'inactive') {
        inactive = !!column.value;
        return false;
      }

      return true;
    });
    let whereCondition = makeWhereCondition(columnFilters, defaultFilters);
    let where = serializeCustomFilters ? serializeCustomFilters(customAttributeFilters, whereCondition) : whereCondition;
    let fuzzyFilters = makeFuzzyFilters(filterColumns, q);

    if (fuzzyFilters.length > 0) {
      let fuzzyConditions = Object.entries(fuzzyFilters.reduce((acc, filter) => {
        var _acc$filter$attribute;

        acc[filter.attributeName] = (_acc$filter$attribute = acc[filter.attributeName]) !== null && _acc$filter$attribute !== void 0 ? _acc$filter$attribute : [];
        let condition = makeLoopBackConditionFromFilter(filter);
        acc[filter.attributeName].push(condition);
        return acc;
      }, {})).map(_ref => {
        let [_key, val] = _ref;
        return {
          and: val
        };
      });
      let fuzzyWhereCondition = {
        or: fuzzyConditions
      };

      if (where.and) {
        where.and.push(fuzzyWhereCondition);
      } else {
        where = {
          and: [where, fuzzyWhereCondition]
        };
      }
    }

    let ret = {
      filter: {
        limit,
        skip,
        order: `${order.property} ${order.direction}`,
        where,
        inactive
      }
    };
    return ret;
  };

  _exports.makeQueryObject = makeQueryObject;

  const makeFuzzyFilters = (columns, q) => {
    if (typeof q !== 'string') {
      return [];
    }

    let words = q.split(/\s+/);
    return columns.filter(column => {
      return column.fuzzySearch && column.type === _types.AttributeType.String;
    }).map(column => {
      return words.map(word => {
        return (0, _filter.makeFilterFromColumn)(column, {
          value: word,
          filterType: _types.FilterType.Contains
        });
      });
    }).flat();
  };

  _exports.makeFuzzyFilters = makeFuzzyFilters;

  const makeOptionsQueryObject = (value, filter, filters) => {
    let {
      optionsNameKey // eslint-disable-next-line ember/no-get

    } = Ember.getProperties(filter, ['optionsNameKey']);
    let nameKey = optionsNameKey !== null && optionsNameKey !== void 0 ? optionsNameKey : _data.DEFAULT_OPTIONS_NAME_KEY;
    let where = {};

    if (!Ember.isBlank(value)) {
      where[nameKey] = {
        ilike: `%${value}%`
      };
    }

    let optionsQueryDependentConditions = makeOptionsQueryDependentConditions(filter, filters);
    return {
      filter: {
        where: { ...where,
          ...optionsQueryDependentConditions
        }
      }
    };
  };

  _exports.makeOptionsQueryObject = makeOptionsQueryObject;

  const makeOptionsQueryDependentConditions = (filter, filters) => {
    var _Ember$get;

    // eslint-disable-next-line ember/no-get
    return ((_Ember$get = Ember.get(filter, 'belongsTo')) !== null && _Ember$get !== void 0 ? _Ember$get : []).reduce((acc, dependentAttribute) => {
      let [dependentFilterName, dependentAttrName] = (0, _utils.getMappedConfigValues)(dependentAttribute);
      let filtersForDependentAttribute = filters.filter(f => f.attributeName === dependentFilterName);

      if (filtersForDependentAttribute.length > 0) {
        acc[dependentAttrName] = {
          inq: filtersForDependentAttribute.map(f => f.value.id)
        };
      }

      return acc;
    }, {});
  };
  /**
   * Creates `Filter` objects from `Filter` array or function that returns
   * `Filter` array
   */


  _exports.makeOptionsQueryDependentConditions = makeOptionsQueryDependentConditions;

  const buildDefaultFilters = (filters, definition) => {
    return typeof definition === 'function' ? definition(filters) : definition;
  };
  /**
   * Creates a `{ and: [...] }` condition that can be used for a LoopBack query.
   *
   * The conditions array that is assigned to the root `and` key may contain
   * conditions grouped under `or` values, such as `{ and: [{ or: [...] }] }`
   */


  const makeWhereCondition = (filters, defaultFilters) => {
    // All filters that can be grouped together by the same `attributeName` value.
    // For now, only `AttributeType.BelongsTo` is treated as groupable. Feel free
    // to extend the list with other filter types that make a good case for
    // grouped under an `or` condition.
    let groupableFiltersUnderAnOrCondition = filters.filter(_ref2 => {
      let {
        attributeType
      } = _ref2;
      return [_types.AttributeType.BelongsTo, _types.AttributeType.Array].includes(attributeType);
    }); // Collects all filters from an array of filters that appear more than once.

    let filterGroupsForOrCondition = groupFiltersWithMultipleInstances(groupableFiltersUnderAnOrCondition); // Standalone filters are either filters that only have a single instance, or
    // have multiple but must not be grouped (AttributeType.String, etc)

    let standaloneFilters = filters.filter(_ref3 => {
      let {
        attributeName
      } = _ref3;
      return !Object.keys(filterGroupsForOrCondition).includes(attributeName);
    }); // `defaultFilters` are not made it to the mix (yet), just shove it under the
    // regular conditions...

    let regularConditions = makeLoopBackConditionsFromFilters((0, _utils.concat)(defaultFilters, standaloneFilters));
    let orConditions = Object.values(filterGroupsForOrCondition).map(filters => makeLoopBackConditionsFromFilters(filters)).map(conditions => ({
      or: conditions
    })); // The below will return an object with the `and` key that lists:
    //
    // 1. The regular, standalone filters that does not need to be grouped and
    //    live under the `and` condition.
    // 2. The grouped conditions, that are grouped and wrapped in an object that
    //    looks like this: `{ or: [...] }`.

    return {
      and: [...regularConditions, ...orConditions]
    };
  };
  /**
   * Takes an array of filters and returns the LoopBack condition for each filter.
   * Removes `null` values that's could be returned by
   * `makeLoopBackConditionFromFilter()` (see below) when no definition is found
   * for a filter.
   */


  _exports.makeWhereCondition = makeWhereCondition;

  const makeLoopBackConditionsFromFilters = filters => filters.map(makeLoopBackConditionFromFilter).filter(Boolean);
  /**
   * Creates a LoopBack condition from a filter. It basically consist of the
   * attribute name, operator and the formatted value. Note that in some cases
   * (currently around `null` values) the query is extended with and/or
   * conditions to cover cases where a value inside the database could be an empty
   * string or a Postgres `NULL` value.
   *
   * Example:
   *
   * ```js
   * {
   *  name: { ilike: '%yolo' },
   * }
   * ```
   *
   * Example for a `null` case:
   *
   * ```js
   * {
   *  or: [
   *    { name: { eq: null } },
   *    { name: { eq: '' } },
   *  ]
   * }
   * ```
   */


  const makeLoopBackConditionFromFilter = filter => {
    var _filter$formatLoopBac;

    let {
      attributeType,
      filterType
    } = filter;
    let filterDefinition = (0, _filter.findFilterDefinitionBy)({
      attributeType,
      filterType
    });

    if (!filterDefinition) {
      return null;
    }

    let {
      operator
    } = filterDefinition;
    let defaultLoopbackFormatter = filterDefinition.formatLoopBackValue;
    let formatLoopBackValue = (_filter$formatLoopBac = filter.formatLoopBackValue) !== null && _filter$formatLoopBac !== void 0 ? _filter$formatLoopBac : defaultLoopbackFormatter;

    let buildCondition = (name, operator, value) => {
      return {
        [name]: {
          [operator]: formatLoopBackValue(value, filter, defaultLoopbackFormatter)
        }
      };
    };

    if (filterType === _types.FilterType.Null) {
      if (filter.attributeType === _types.AttributeType.String) {
        return {
          or: [buildCondition(filter.attributeName, operator, null), buildCondition(filter.attributeName, operator, '')]
        };
      } else {
        return buildCondition(filter.attributeName, operator, null);
      }
    } else if (filterType === _types.FilterType.NotNull) {
      if (filter.attributeType === _types.AttributeType.String) {
        return {
          and: [buildCondition(filter.attributeName, operator, null), buildCondition(filter.attributeName, operator, '')]
        };
      } else {
        return buildCondition(filter.attributeName, operator, null);
      }
    } else {
      return buildCondition(filter.attributeName, operator, filter.value);
    }
  };
  /**
   * Takes an array of filters and returns an object, which keys are the attribute
   * names and its values are the filters that exist more than once with that
   * `attributeName`.
   */


  _exports.makeLoopBackConditionFromFilter = makeLoopBackConditionFromFilter;

  const groupFiltersWithMultipleInstances = filters => {
    return Object.entries((0, _array.groupBy)(filters, 'attributeName')).reduce((acc, _ref4) => {
      let [attributeName, filters] = _ref4;

      if (filters.length > 1) {
        acc[attributeName] = filters;
      }

      return acc;
    }, {});
  };
});