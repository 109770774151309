define("iris/templates/tasks/availabilities/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2iGJmVib",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-tether\",null,[[\"to\",\"target\",\"targetModifier\",\"attachment\",\"on-overlay-click\",\"tetherClass\",\"overlayClass\"],[\"modal-dialog\",\"document.body\",\"visible\",\"middle center\",[28,\"route-action\",[\"back\",1,[24,[\"model\",\"availability\"]]],null],\"modal-dialog modal-md\",\"modal-backdrop\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n    \\n    \"],[1,[28,\"modal-header\",null,[[\"title\"],[[28,\"t\",[\"menus.edit\"],null]]]],false],[0,\"\\n      \\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n      \"],[1,[28,\"forms/availability-form\",null,[[\"model\",\"files\",\"businessServices\",\"serviceAreas\"],[[24,[\"model\",\"availability\"]],[24,[\"model\",\"files\"]],[24,[\"model\",\"businessServices\"]],[24,[\"model\",\"serviceAreas\"]]]]],false],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n      \"],[1,[28,\"buttons/save-button\",null,[[\"action\",\"value\"],[[28,\"route-action\",[\"save\",[28,\"hash\",null,[[\"redirectTo\"],[\"tasks.availabilities\"]]]],null],[24,[\"model\",\"availability\"]]]]],false],[0,\"\\n\\n      \"],[1,[28,\"buttons/cancel-button\",null,[[\"action\",\"value\"],[[28,\"route-action\",[\"back\",1],null],[24,[\"model\",\"availability\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/tasks/availabilities/edit.hbs"
    }
  });

  _exports.default = _default;
});