define("iris/routes/crm/contracts/edit/competencies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      var store = this.store;
      let modelFor = this.modelFor('crm.contracts.edit');
      let contract = modelFor.contract;
      return Ember.RSVP.hash({
        actions: store.query('action', {
          filter: {
            where: {
              and: [{
                scope: 'contract'
              }, {
                forceDefault: 0
              }]
            }
          }
        }),
        contract: contract,
        competencies: store.query('competency', {
          filter: {
            where: {
              builtIn: {
                lt: 2
              }
            }
          }
        })
      });
    },

    setupController: function (controller, model) {
      this._super(controller, model);
      /*ACL*/


      let contract = this.controllerFor('crm.contracts.edit').get('model.contract');
      let disableInput = !this.can('read action', {
        obj: contract,
        canActions: ['crm.contracts.acl', 'crm.contracts.acl.write']
      });
      this.controller.set('disableInput', disableInput);
    }
  });

  _exports.default = _default;
});