define("iris/components/forms/contract-dates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    dateEndTypes: Ember.computed(function () {
      return [{
        id: 0,
        name: this.intl.t('contract.fixed')
      }, {
        id: 1,
        name: this.intl.t('contract.permanent')
      }];
    }),
    dateEndType: Ember.computed('model.dateEndType', 'dateEndTypes', {
      get(key) {
        let dateEndTypes = this.get('dateEndTypes');
        let dateEndType = this.get('model.dateEndType');
        return dateEndTypes[dateEndType];
      },

      set(key, value) {
        Ember.run.next(() => {
          if (!this.get('isDestroyed') && !this.get('isDestroying')) {
            // TODO: backtracking-rerender
            this.set('model.dateEndType', value.id);
          }
        });
        return value;
      }

    })
  });

  _exports.default = _default;
});