define("iris/components/kanban/component", ["exports", "@salsify/ember-block-slots"], function (_exports, _emberBlockSlots) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Kanban extends Ember.Component.extend(_emberBlockSlots.default) {}

  _exports.default = Kanban;
});