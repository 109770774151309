define("iris/helpers/world-flag-svg", ["exports", "ember-world-flags/helpers/world-flag-svg"], function (_exports, _worldFlagSvg) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _worldFlagSvg.default;
    }
  });
});