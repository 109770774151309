define("iris/routes/tasks/tasks/work-logs/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    parentRoute: 'tasks.tasks.work-logs',
    isDownloading: false,
    buttonActions: Ember.computed('isDownloading', function () {
      let isDownloading = this.get('isDownloading');
      return [{
        text: this.intl.t('general.download'),
        action: 'onDownloadBtnClick',
        type: 'primary',
        active: isDownloading,
        iconInactive: 'fa fa-download',
        iconActive: 'fa fa-refresh fa-spin fa-fw'
      }];
    }),

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('sessionAccount', this.get('sessionAccount'));
      this.controller.set('buttonActions', this.get('buttonActions'));
    },

    getModel() {
      return this.modelFor(this.get('parentRoute') + '.edit');
    }

  });

  _exports.default = _default;
});