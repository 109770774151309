define("iris/components/advanced-filter", ["exports", "underscore"], function (_exports, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    showFilters: false,
    showFilterBtn: true,

    didReceiveAttrs() {
      this._super();

      this.set('activeFilters', this._activeFilters());
    },

    activeFilters: null,

    _activeFilters() {
      let criterias = this.get('validCriterias');
      return _underscore.default.map(criterias, _underscore.default.clone);
    },

    _criterias() {
      let res = Ember.A();
      let attributes = this.get('attributes');
      let defaults = this.get('defaults');
      defaults.forEach(def => {
        attributes.forEach(attribute => {
          if (attribute.property === def.property) {
            res.pushObject({
              attribute: attribute,
              value: def.value,
              valid: true
            });
          }
        });
      });

      if (res.length === 0) {
        res.pushObject({});
      }

      return res;
    },

    criterias: Ember.computed('attributes.[]', 'defaults.[]', function () {
      return this._criterias();
    }),
    defaults: Ember.A([{
      property: 'closed',
      value: false
    }]),
    allConditions: Ember.A([{
      name: '<',
      value: 'gt'
    }, {
      name: '>',
      value: 'lt'
    }, {
      name: 'ilike',
      value: 'ilike'
    }, {
      name: '!=',
      value: 'neq'
    }, {
      name: '=',
      value: 'eq'
    }]),
    allValid: Ember.computed('criterias.@each.valid', function () {
      let criterias = this.get('criterias');
      return !criterias.isEvery('valid', true) || criterias.length === 0;
    }),
    validCriterias: Ember.computed.filterBy('criterias', 'valid'),

    _serializeCriteria(criteria) {
      let res = {};
      criteria = Ember.Object.create(criteria);
      let attribute = criteria.get('attribute.property');
      let type = criteria.get('attribute.meta.type');

      if (type === 'belongsTo') {
        attribute = attribute + 'Id';
      }

      let operator = criteria.get('operator.value');
      let value = criteria.get('value');
      value = Ember.isPresent(value) ? value : criteria.get('selected.id');
      let compare = {};

      if (operator === 'eq') {
        compare = value;
      } else {
        compare[operator] = operator === 'ilike' ? '%' + value + '%' : value;
      }

      res[attribute] = compare;
      return res;
    },

    loopbackFilter() {
      let criterias = this.get('validCriterias');
      let res = Ember.A();
      criterias.forEach(criteria => {
        res.pushObject(this._serializeCriteria(criteria));
      });
      return res;
    },

    recordType: 'project',
    record: Ember.computed('recordType', function () {
      return this.get('store').createRecord(this.get('recordType'));
    }),
    attributes: Ember.computed('record', function () {
      let record = this.get('record');
      let res = Ember.A();
      record.eachAttribute(function (name, meta) {
        res.push({
          property: name,
          title: name,
          meta: meta
        });
      });
      record.eachRelationship(function (name, descriptor) {
        let meta = {
          type: descriptor.kind,
          modelName: descriptor.type,
          parentType: descriptor.parentType
        };

        if (descriptor.kind === 'belongsTo') {
          res.push({
            property: name,
            title: name,
            meta: meta
          });
        }
      });
      return res;
    }),
    open: false,
    actions: {
      open() {
        this.set('open', true);
        this.set("showFilterBtn", true);
      },

      close() {
        this.set('open', false);
        this.set("showFilterBtn", false);
      },

      addCriteria() {
        this.get('criterias').pushObject(Ember.Object.create({
          valid: false
        }));
      },

      removeCriteria(index) {
        this.get('criterias').removeAt(index);
      },

      filter() {
        this.set("showFilterBtn", true);
        let criterias = this.loopbackFilter();
        this.get('action')(criterias);
        this.set('activeFilters', this._activeFilters());
        this.send('close');
      },

      closeInfo() {},

      showCriterias(activeFilters) {
        this.toggleProperty('showFilters');
      }

    }
  });

  _exports.default = _default;
});