define("iris/models/aiops-pm-type-customer-assoc-computed", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    paramValue: [(0, _emberCpValidations.validator)('presence', true)],
    optimizationParameter: [(0, _emberCpValidations.validator)('presence', true)],
    customer: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    paramValue: (0, _attr.default)('number', {
      formWidget: 'mask'
    }),
    optimizationParameter: (0, _relationships.belongsTo)('aiopsOptimizationParameter'),
    customer: (0, _relationships.belongsTo)('aiopsCustomer')
  });

  _exports.default = _default;
});