define("iris/components/forms/proposal-item-import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      importItem: function (file) {
        // This function triggered, when a file selected to upload.
        var model = this.get('model'); // In this case, i only set the file back to the model, so i can reach it from there...

        Ember.set(model, 'uploadedFile', file);
      }
    }
  });

  _exports.default = _default;
});