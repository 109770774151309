define("iris/templates/components/form-element/horizontal/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ql6CE10D",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"hasLabel\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\",true],[11,\"class\",[29,[\"control-label \",[22,\"horizontalLabelGridClass\"]]]],[11,\"for\",[29,[[22,\"formElementId\"]]]],[8],[1,[22,\"label\"],false],[9],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"horizontalInputGridClass\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"            \"],[14,1,[[24,[\"value\"]],[24,[\"formElementId\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[28,\"bs-input\",null,[[\"id\",\"name\",\"type\",\"value\",\"placeholder\",\"autofocus\",\"disabled\",\"required\"],[[24,[\"formElementId\"]],[24,[\"name\"]],[24,[\"controlType\"]],[24,[\"value\"]],[24,[\"placeholder\"]],[24,[\"autofocus\"]],[24,[\"disabled\"]],[24,[\"required\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[15,\"components/form-element/feedback-icon\",[]],[0,\"\\n        \"],[15,\"components/form-element/errors\",[]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"horizontalInputGridClass\"],\" \",[22,\"horizontalInputOffsetGridClass\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"            \"],[14,1,[[24,[\"value\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[28,\"bs-input\",null,[[\"name\",\"type\",\"value\",\"placeholder\",\"autofocus\",\"disabled\",\"required\"],[[24,[\"name\"]],[24,[\"controlType\"]],[24,[\"value\"]],[24,[\"placeholder\"]],[24,[\"autofocus\"]],[24,[\"disabled\"]],[24,[\"required\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[15,\"components/form-element/feedback-icon\",[]],[0,\"\\n        \"],[15,\"components/form-element/errors\",[]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":true}",
    "meta": {
      "moduleName": "iris/templates/components/form-element/horizontal/default.hbs"
    }
  });

  _exports.default = _default;
});