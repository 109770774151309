define("iris/components/portal-dashboard/index/component", ["exports", "fast-deep-equal", "iris/lib/filters", "iris/lib/filters/column", "iris/lib/filters/storage", "iris/lib/filters/types", "iris/lib/table", "iris/routes/operation/portal-dashboard", "iris/utils/flatten-eq-operators"], function (_exports, _fastDeepEqual, _filters, _column, _storage, _types, _table, _portalDashboard, _flattenEqOperators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TICKET_STATE_DEFAULT_ID = '000';
  const PRIORITY_OPTIONS = [{
    name: 'P1',
    id: 1
  }, {
    name: 'P2',
    id: 2
  }, {
    name: 'P3',
    id: 3
  }, {
    name: 'P4',
    id: 4
  }, {
    name: 'P5',
    id: 5
  }, {
    name: 'P6+',
    id: 6
  }];

  var _default = Ember.Component.extend({
    tagName: '',
    intl: Ember.inject.service(),
    storage: (0, _storage.useStorage)(),
    pageTitle: Ember.computed('predefinedViews', 'storage.params.filters.[]', function () {
      let customFiltersTitle = this.intl.t('operation.sd-dashboard.customView');

      for (let filter of this.storage.params.filters) {
        if (filter.type !== _types.FilterType.Equal || filter.name !== 'ticketStateId' || typeof filter.value !== 'object' || !filter.value) {
          // If any of the filters do not have the above condition, it's 100%
          // not a tab. Make sure to update this, when adding new default tabs!
          return customFiltersTitle;
        }
      }

      let storageFiltersToCompare = this.storage.params.filters.map(f => f.value.id);

      for (let view of this.predefinedViews) {
        let viewFiltersToCompare = view.filters.map(f => f.value.id);
        let isCurrentTab = (0, _fastDeepEqual.default)(storageFiltersToCompare, viewFiltersToCompare);

        if (isCurrentTab) {
          return view.name;
        }
      }

      return customFiltersTitle;
    }),
    columns: (0, _column.columns)(function () {
      return [{
        name: 'ticketStateId',
        displayName: this.intl.t('ticket.ticketStateId'),
        type: _types.AttributeType.BelongsTo,
        visibility: _types.AttributeVisibility.Filter,

        createOptions(records, nameKey, defaultMapper) {
          // Exclue ticket state named "Default"
          return records.filter(r => r.id !== TICKET_STATE_DEFAULT_ID);
        }

      }, {
        name: 'ticketNumber',
        displayName: this.intl.t('ticket.ticketNumber'),
        fixed: true,
        route: 'operation.portal-dashboard.edit',
        routeParams: (0, _table.rowValues)('id')
      }, {
        name: 'partnerName',
        displayName: this.intl.t('ticket.partner')
      }, {
        name: 'businessServiceName',
        displayName: this.intl.t('ticket.businessServiceName')
      }, {
        name: 'createDate',
        displayName: this.intl.t('ticket.createDate'),
        type: _types.AttributeType.Date,
        width: _column.ColumnWidth.S
      }, {
        name: 'ticketStateName',
        displayName: this.intl.t('ticket.state'),
        fuzzySearch: false
      }, {
        name: 'subject',
        displayName: this.intl.t('ticket.subject')
      }, {
        name: 'priority',
        displayName: this.intl.t('ticket.priority'),
        type: _types.AttributeType.Array,
        width: _column.ColumnWidth.S,
        options: PRIORITY_OPTIONS,
        formatDisplayValue: value => {
          var _PRIORITY_OPTIONS$fin, _PRIORITY_OPTIONS$fin2;

          return (_PRIORITY_OPTIONS$fin = (_PRIORITY_OPTIONS$fin2 = PRIORITY_OPTIONS.find(po => po.id === value)) === null || _PRIORITY_OPTIONS$fin2 === void 0 ? void 0 : _PRIORITY_OPTIONS$fin2.name) !== null && _PRIORITY_OPTIONS$fin !== void 0 ? _PRIORITY_OPTIONS$fin : '-';
        }
      }, {
        name: 'ticketTypeName',
        displayName: this.intl.t('ticket.type'),
        fuzzySearch: false,
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'contactName',
        displayName: this.intl.t('ticket.contact')
      }, {
        name: 'ticketSource',
        displayName: this.intl.t('ticket.state'),
        visibility: _types.AttributeVisibility.Table,
        fuzzySearch: false,
        width: _column.ColumnWidth.SM
      }, {
        name: 'solverName',
        displayName: this.intl.t('ticket.resolver'),
        visibility: _types.AttributeVisibility.Table,
        fuzzySearch: false,
        width: _column.ColumnWidth.SM
      }, {
        name: 'resolverId',
        displayName: this.intl.t('ticket.resolver'),
        visibility: _types.AttributeVisibility.Filter,
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'colleague'
      }, {
        name: 'reaction',
        displayName: this.intl.t('incident-form.reaction-lc'),
        fuzzySearch: false,
        visibility: _types.AttributeVisibility.Table,
        width: _column.ColumnWidth.SM,
        sortable: false,
        exportable: false
      }, {
        name: 'start',
        displayName: this.intl.t('incident-form.getting-started-lc'),
        fuzzySearch: false,
        visibility: _types.AttributeVisibility.Table,
        width: _column.ColumnWidth.SM,
        sortable: false,
        exportable: false
      }, {
        name: 'end',
        displayName: this.intl.t('incident-form.solution-lc'),
        fuzzySearch: false,
        visibility: _types.AttributeVisibility.Table,
        width: _column.ColumnWidth.SM,
        sortable: false,
        exportable: false
      }];
    }),
    predefinedViews: Ember.computed(function () {
      return _portalDashboard.tabs.map(_ref => {
        let {
          name,
          queryParams
        } = _ref;
        return {
          name,
          filters: queryParams.filters
        };
      });
    }),
    fetchParams: Ember.computed('columns', 'storage.params', function () {
      let {
        filter
      } = (0, _filters.makeQueryObject)(this.columns, this.storage.params); // Unless the `eq` operators are removed and theire value are used directly,
      // the API woud throw.
      //
      // Ex. `{ ticketStateId: { eq: '...' } }` -> `{ ticketStateId: '...' }`

      (0, _flattenEqOperators.flattenEqOperators)(filter.where, 'ticketStateId');
      return {
        filter: { ...filter,
          // Order must be an array, otherwise the API would throw
          order: [filter.order]
        }
      };
    })
  });

  _exports.default = _default;
});