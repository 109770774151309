define("iris/components/operational-file/edit/file-tariff/component", ["exports", "iris/utils/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FileTariff = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class FileTariff extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "modals", _descriptor2, this);
    }

    async beforeSave(tariff) {
      try {
        const fileId = tariff.belongsTo('file').id();
        const dateStart = moment(tariff.dateStart).toISOString(true);
        const dateEnd = moment(tariff.dateEnd).toISOString(true);
        await this.api.collectionAction('fileTariff', 'validate-tariff', {
          method: 'POST',
          data: {
            tariff: {
              id: tariff.id,
              fileId,
              dateStart,
              dateEnd
            }
          }
        });
        return true;
      } catch (error) {
        const {
          flashMessages,
          intl
        } = this;
        const msg = (0, _error.formatErrorMessages)(error);
        flashMessages.danger(msg, {
          title: intl.t('general.loading-error'),
          sticky: true,
          icon: 'warning'
        });
        return false;
      }
    }

    async createRecord() {
      let dateEnd = null;
      const endlessTariffs = await this.store.query('fileTariff', {
        filter: {
          where: {
            fileId: this.data.file.id,
            dateEnd: null
          },
          limit: 1
        }
      });

      if (endlessTariffs.get('length')) {
        return this.store.createRecord('fileTariff');
      }

      const tariffs = await this.store.query('fileTariff', {
        filter: {
          where: {
            fileId: this.data.file.id
          },
          limit: 1,
          order: 'dateEnd desc'
        }
      });

      if (tariffs.get('length')) {
        const actualTariff = tariffs.firstObject;
        dateEnd = actualTariff.dateEnd;
      }

      let dateStart = null;

      if (dateEnd) {
        dateStart = moment(dateEnd).add(1, 'days').toDate();
      }

      return this.store.createRecord('fileTariff', {
        dateStart
      });
    }

    async editTariff(o2m, row) {
      await o2m.editItem(row);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "createRecord", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "createRecord"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editTariff", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "editTariff"), _class.prototype)), _class));
  _exports.default = FileTariff;
});