define("iris/components/input/select/it-contacts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OJrrA1+o",
    "block": "{\"symbols\":[\"option\",\"@file\",\"@disableInputObject\"],\"statements\":[[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"partnerITcontacts\",[28,\"t\",[\"forms.it-connection\"],null]]],{\"statements\":[[0,\"\\n\\n  \"],[5,\"input/select/lazy\",[],[[\"@modelName\",\"@multiple\",\"@where\",\"@include\",\"@selected\",\"@onchange\",\"@disabled\"],[\"contact\",true,[28,\"if\",[[23,2,[\"subcontractor\"]],[28,\"hash\",null,[[\"partnerId\",\"active\"],[[28,\"hash\",null,[[\"inq\"],[[28,\"array\",[[23,2,[\"primeContractor\",\"id\"]],[23,2,[\"primeContractor\",\"parent\",\"id\"]],[23,2,[\"clientPartner\",\"id\"]],[23,2,[\"clientPartner\",\"parent\",\"id\"]]],null]]]],1]]],[28,\"hash\",null,[[\"partnerId\",\"active\"],[[28,\"hash\",null,[[\"inq\"],[[28,\"array\",[[23,2,[\"clientPartner\",\"id\"]],[23,2,[\"clientPartner\",\"parent\",\"id\"]]],null]]]],1]]]],null],[28,\"array\",[\"partner\"],null],[23,2,[\"partnerITcontacts\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,2,[\"partnerITcontacts\"]]],null]],null],[23,3,[\"common\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[23,1,[\"name\"]],false],[0,\"\\n    /\\n    \"],[1,[23,1,[\"partner\",\"name\"]],false],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/input/select/it-contacts/template.hbs"
    }
  });

  _exports.default = _default;
});