define("iris/models/user-grade", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 50
    })],
    cost: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    code: (0, _emberCpValidations.validator)('length', {
      max: 5
    }),
    userId: (0, _emberCpValidations.validator)('length', {
      max: 12
    })
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    cost: (0, _attr.default)('number'),
    code: (0, _attr.default)('string'),
    fileUserGradeCosts: (0, _relationships.hasMany)('fileUserGradeCost'),
    resolvedFileUserGradeCosts: Ember.computed('fileUserGradeCosts', {
      get(key) {
        let _this = this;

        let value = null;
        this.get('fileUserGradeCosts').then(fileUserGradeCosts => {
          _this.set('resolvedFileUserGradeCosts', fileUserGradeCosts);

          value = fileUserGradeCosts;
        });
        return value;
      },

      set(key, value) {
        return value;
      }

    })
    /*
    actualCost: computed('fileUserGradeCosts', 'cost', {
      get(key){
        let _this = this;
        let value = null;
        let cost = this.get('cost');
        this.get('fileUserGradeCosts').then(fileUserGradeCosts=>{
          let c = fileUserGradeCost ? fileUserGradeCost.get('cost') : cost;
          _this.set('actualCost', c);
          value = c;
        });
        return value;
      },
      set(key, value){
        return value;
      }
    }) */

  });

  _exports.default = _default;
});