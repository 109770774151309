define("iris/lib/human-resource-manager/event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EventColors = void 0;
  let EventColors;
  _exports.EventColors = EventColors;

  (function (EventColors) {
    EventColors["Blue"] = "#7AA2CC";
    EventColors["Red"] = "#ff535d";
    EventColors["Black"] = "#171717";
  })(EventColors || (_exports.EventColors = EventColors = {}));
});