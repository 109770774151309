define("iris/components/bs-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'form',
    classNameBindings: ['inline:form-inline:form-horizontal'],
    attributeBindings: ['autocomplete'],
    inline: false,
    proportion: '2-10',
    labelControlProportion: Ember.computed('proportion', function () {
      let prop = this.get('proportion');

      if (Ember.isNone(prop)) {
        return false;
      }

      prop = prop.split('-');
      return {
        label: prop[0],
        control: prop[1]
      };
    })
  });

  _exports.default = _default;
});