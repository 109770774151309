define("iris/helpers/ranged", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.ranged = ranged;

  function ranged(params, _ref) {
    let {
      ranges,
      labels
    } = _ref;
    let value = parseInt(params[0]);
    let range = ranges.split(',');
    let res = null;

    if (value < range[0]) {
      res = 'danger';
    } else {
      res = 'success';
    }

    if (value >= range[0] && value <= range[1]) {
      res = 'warning';
    }

    return res;
  }

  var _default = Ember.Helper.helper(ranged);

  _exports.default = _default;
});