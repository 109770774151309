define("iris/components/bs-daterangepicker", ["exports", "moment", "ember-cli-daterangepicker/components/date-range-picker"], function (_exports, _moment, _dateRangePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dateRangePicker.default.extend({
    intl: Ember.inject.service(),
    classNames: ['date-range-picker'],
    format: 'L',
    cancelLabel: Ember.computed('property', function () {
      return this.intl.t('buttons.cancel');
    }),
    applyLabel: Ember.computed('property', function () {
      return this.intl.t('buttons.filtering');
    }),
    customRangeLabel: Ember.computed('property', function () {
      return this.intl.t('buttons.otherRange');
    }),
    ranges: Ember.computed(function () {
      let rangeObject = {};
      rangeObject[this.intl.t('dateRangePicker.today')] = [(0, _moment.default)(), (0, _moment.default)()];
      rangeObject[this.intl.t('dateRangePicker.yesterday')] = [(0, _moment.default)().subtract(1, 'days'), (0, _moment.default)().subtract(1, 'days')];
      rangeObject[this.intl.t('dateRangePicker.lastSevenDays')] = [(0, _moment.default)().subtract(6, 'days'), (0, _moment.default)()];
      rangeObject[this.intl.t('dateRangePicker.lastThirtyDays')] = [(0, _moment.default)().subtract(29, 'days'), (0, _moment.default)()];
      rangeObject[this.intl.t('dateRangePicker.thisMonth')] = [(0, _moment.default)().startOf('month'), (0, _moment.default)().endOf('month')];
      rangeObject[this.intl.t('dateRangePicker.previousMonth')] = [(0, _moment.default)().subtract(1, 'month').startOf('month'), (0, _moment.default)().subtract(1, 'month').endOf('month')];
      return rangeObject;
    })
  });

  _exports.default = _default;
});