define("iris/controllers/settings/payments/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PaymentEditController = (_dec = Ember.inject.service, _dec2 = Ember.computed(), _dec3 = Ember.computed(), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class PaymentEditController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _defineProperty(this, "selectedPayType", void 0);

      _defineProperty(this, "mainSelectedPayType", void 0);

      _defineProperty(this, "selectedRoundType", void 0);

      _initializerDefineProperty(this, "intl", _descriptor, this);
    }

    get paymentTypes() {
      return Ember.A([{
        id: 0,
        name: this.intl.t('paymentTypes.cash')
      }, {
        id: 1,
        name: this.intl.t('paymentTypes.bankCard')
      }, {
        id: 2,
        name: this.intl.t('paymentTypes.coupon')
      }, {
        id: 3,
        name: this.intl.t('paymentTypes.loss')
      }, {
        id: 4,
        name: this.intl.t('paymentTypes.other')
      }, {
        id: 5,
        name: this.intl.t('paymentTypes.billing')
      }, {
        id: 6,
        name: this.intl.t('paymentTypes.fulfilBilling')
      }]);
    }

    get roundTypes() {
      return Ember.A([{
        id: 0,
        name: this.intl.t('roundTypes.normal')
      }, {
        id: 5,
        name: this.intl.t('roundTypes.five')
      }]);
    }

    paymentSelected(selected) {
      this.set('selectedPayType', selected);
      this.set('model.type', selected.id);
    }

    mainPaymentSelected(selected) {
      this.set('mainSelectedPayType', selected);
      this.set('model.systemFlag', selected.id);
    }

    roundSelected(selected) {
      this.set('selectedRoundType', selected);
      this.set('model.roundType', selected.id);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "paymentTypes", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "paymentTypes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "roundTypes", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "roundTypes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "paymentSelected", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "paymentSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mainPaymentSelected", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "mainPaymentSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "roundSelected", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "roundSelected"), _class.prototype)), _class));
  _exports.default = PaymentEditController;
});