define("iris/components/beta/pagination/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const intComputed = (dk, defaultValue) => Ember.computed(dk, function () {
    let value = parseInt(Ember.get(this, dk));

    if (Number.isNaN(value)) {
      return defaultValue;
    }

    return value;
  });

  var _default = Ember.Component.extend({
    tagName: '',
    limit: null,
    // @
    page: null,
    // @, required
    total: null,
    // @, required
    _limit: intComputed('limit', 0),
    _page: intComputed('page', 1),
    _total: intComputed('total', 0),
    totalPages: Ember.computed('_total', '_limit', function () {
      let total = this._total;
      let limit = this._limit;

      if (limit === 0 || total === 0) {
        return 1;
      }

      let totalPages = Math.floor(total / limit);
      let rem = total % limit;

      if (rem > 0) {
        totalPages++;
      }

      if (Number.isNaN(totalPages)) {
        return 1;
      }

      return totalPages;
    }),
    disabled: Ember.computed.equal('totalPages', 1),
    pages: Ember.computed('totalPages', function () {
      if (!this.totalPages) {
        return [];
      }

      return new Array(this.totalPages).fill().map((_, index) => (index + 1).toString());
    }),
    prevPageNumber: Ember.computed('page', function () {
      return Math.max(1, this.page - 1);
    }),
    nextPageNumber: Ember.computed('page', 'total', 'totalPages', function () {
      return Math.min(this.totalPages, this.page + 1);
    }),
    isPrevDisabled: Ember.computed.equal('_page', 1),
    isNextDisabled: Ember.computed('_page', 'totalPages', function () {
      return this._page === this.totalPages;
    }),
    actions: {
      // There is an issue with getting Power Select's default search function
      // working. Also, we want to show only exact matches anyway, ex. if there
      // are 30 pages, when we type "1", we only want to see the number "1", not
      // "1", "11", "21".
      searchPage(input) {
        if (this.pages.includes(input)) {
          return [input];
        } // If the number typed in is bigger than the total pages, just return the
        // last page.


        let num = parseInt(input);

        if (!Number.isNaN(num) && num > this.totalPages) {
          return [String(this.totalPages)];
        }

        return [];
      }

    }
  });

  _exports.default = _default;
});