define("iris/components/tables/sd-dashboard/modals/merge/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kzJeioh/",
    "block": "{\"symbols\":[\"option\",\"@data\",\"@close\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-content mx-auto\"],[10,\"style\",\"width: 400px\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"operation.notification-merger\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n\\n    \"],[7,\"p\",true],[10,\"class\",\"italic mb-6\"],[8],[7,\"span\",true],[10,\"class\",\"mr-6\"],[8],[1,[28,\"fa-icon\",[\"warning\"],null],false],[9],[0,\"\\n      \"],[1,[28,\"t\",[\"operation.notification-merger-info\"],null],false],[9],[0,\"\\n\\n    \"],[5,\"iris-power-select\",[],[[\"@options\",\"@searchField\",\"@placeholder\",\"@selected\",\"@allowClear\",\"@onchange\"],[[23,2,[\"selection\"]],\"subject\",[28,\"t\",[\"operation.choose-main-announcement\"],null],[23,0,[\"currentSelectedMainTicket\"]],false,[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"currentSelectedMainTicket\"]]],null]],null]]],{\"statements\":[[0,\"\\n      \"],[1,[23,1,[\"ticketNumber\"]],false],[0,\"\\n      |\\n      \"],[1,[23,1,[\"subject\"]],false],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"default\",[28,\"fn\",[[23,3,[]],false],null],[28,\"or\",[[23,2,[\"cancelButtonLabel\"]],[28,\"t\",[\"buttons.cancel\"],null]],null]]]],[0,\"\\n\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"primary\",[28,\"fn\",[[23,3,[]],[23,0,[\"currentSelectedMainTicket\"]]],null],[28,\"or\",[[23,2,[\"okButtonLabel\"]],[28,\"t\",[\"components-main.OK\"],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/tables/sd-dashboard/modals/merge/template.hbs"
    }
  });

  _exports.default = _default;
});