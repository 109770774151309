define("iris/components/navigation-side/component", ["exports", "iris/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    tagName: 'ul',
    classNames: ['nav', 'nav-main', 'iris--navigation-side'],
    module: false,
    wikiUrl: _environment.default.wiki.url,
    pentahoUrl: _environment.default.pentaho.url,
    srepoUrl: _environment.default.srepo.url,
    entityCategories: Ember.computed(function () {
      return this.get('store').query('entity-type-category', {
        filter: {
          order: 'name'
        }
      });
    }),
    reportMenus: null,
    actions: {
      toggleNav: function (item) {
        if (item.get('expanded')) {
          Ember.set(item, 'expanded', false);
        } else {
          Ember.set(item, 'expanded', true);
        }
      },
      toggleNavReport: function (item) {
        let _this = this;

        if (item.get('expanded')) {
          Ember.set(item, 'expanded', false);
        } else {
          Ember.set(item, 'expanded', true);
          let options = _environment.default.pentaho;
          $.ajax({
            type: "GET",
            url: options.url + "/api/repo/files/%3Ahome%3AJelentések/tree?depth=-1",
            dataType: 'json',
            crossDomain: true,
            xhrFields: {
              withCredentials: true
            },
            contentType: "application/json",
            success: function (result) {
              let obj = result.children.map(function (data) {
                return Ember.Object.create(data);
              });

              _this.set('reportMenus', obj);
            },
            error: function (xhr) {
              let $html = $(xhr.responseText);
              $.ajax({
                type: "POST",
                url: $html.closest('form').attr('action'),
                data: $html.closest('form').serialize(),
                dataType: 'json',
                crossDomain: true,
                xhrFields: {
                  withCredentials: true
                },
                success: function (result) {
                  let obj = result.children.map(function (data) {
                    return Ember.Object.create(data);
                  });

                  _this.set('reportMenus', obj);
                }
              });
            }
          });
        }
      },
      activateNav: function (item) {
        if (item.get('activated')) {
          Ember.set(item, 'activated', false);
        } else {
          Ember.set(item, 'activated', true);
        }
      },
      activateModule: function (menu) {
        if (menu) {
          menu.get('group').then(group => {
            this.set('acceptedMenus', group);
            this.toggleProperty('module');
          });
        } else {
          this.toggleProperty('module');
        }
      },
      openExternalLink: function (url) {
        window.open(url);
      },
      setActiveMenu: function (menu) {
        this.set('currentMenu', menu);
      }
    }
  });

  _exports.default = _default;
});