define("iris/templates/components/tables/files-service-levels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1PwM0lVZ",
    "block": "{\"symbols\":[\"data\",\"columns\",\"row\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"main-content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"panel panel-default\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n\"],[4,\"bs-table\",null,[[\"data\",\"columns\",\"isShowDeleteBtn\",\"class\",\"paginator\",\"newRecords\",\"newButton\"],[[24,[\"model\",\"operationalServiceLevels\"]],[24,[\"columns\"]],true,\"mt20\",true,true,[28,\"hash\",null,[[\"text\",\"path\"],[[28,\"t\",[\"buttons.new-service-level\"],null],[24,[\"newPath\"]]]]]]],{\"statements\":[[4,\"each\",[[23,1,[]]],null,{\"statements\":[[4,\"checkbox-row\",null,[[\"record\",\"action\"],[[23,3,[]],[28,\"route-action\",[\"transitionTo\",[28,\"hash\",null,[[\"route\",\"segment\"],[\"operation.operational-file.new.service-levels.edit\",[23,3,[\"id\"]]]]]],null]]],{\"statements\":[[0,\"                \"],[7,\"td\",true],[8],[1,[23,3,[\"id\"]],false],[9],[0,\"\\n                \"],[7,\"td\",true],[8],[1,[23,3,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[3]},null]],\"parameters\":[1,2]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/tables/files-service-levels.hbs"
    }
  });

  _exports.default = _default;
});