define("iris/templates/settings/aiops/partners", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QVgp83Bm",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@showInActiveDropDown\",\"@route\",\"@neededFields\"],[\"aiops-partner\",true,\"settings.aiops.partners.edit\",[28,\"array\",[\"name\",\"region\",\"partnerType.name\",\"address\",\"phone\",\"email\",\"contact\",\"active\"],null]]]],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/aiops/partners.hbs"
    }
  });

  _exports.default = _default;
});