define("iris/initializers/route", ["exports", "jquery", "ember-can", "iris/config/environment", "iris/utils/routing"], function (_exports, _jquery, _emberCan, _environment, _routing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var alreadyRun = false;
  var _default = {
    name: 'route',

    initialize(application) {
      if (alreadyRun) {
        return;
      } else {
        alreadyRun = true;
      }

      application.inject('component', 'router', 'router:main');
      Ember.Route.reopen(_emberCan.CanMixin, {
        sessionAccount: Ember.inject.service(),
        session: Ember.inject.service(),
        ajax: Ember.inject.service(),
        router: Ember.inject.service(),

        getAncestors(back) {
          back = back ? back * -1 : -1;
          return this.get('routeName').split('.').slice(0, back).join('.');
        },

        activate() {
          let cssClass = this.toCssClass(); // you probably don't need the application class
          // to be added to the body

          if (cssClass != 'application') {
            (0, _jquery.default)('body').addClass(cssClass);
          }

          let _this = this;

          let environment = _environment.default.environment;
          let whiteList = ['development', 'remote-dev', 'production', 'uat', 'test'];

          if (!whiteList.contains(environment)) {
            (0, _jquery.default)(window).bind('beforeunload', function () {
              _this.get('session').invalidate();

              return undefined;
            });
          }
        },

        parentRoute: Ember.computed(function () {
          let i, info, len, parent;
          let routeInfos = (0, _routing.getCurrentRouteInfos)(this.router, {
            prioritizeActiveTransition: true
          });

          if (!routeInfos) {
            return;
          }

          parent = this;

          for (i = 0, len = routeInfos.length; i < len; i++) {
            info = routeInfos[i];

            if (info.route === this) {
              break;
            }

            parent = info.route;
          }

          return parent;
        }).volatile(),
        parentRouteName: Ember.computed('parentRoute.routeName', function () {
          return this.get('parentRoute.routeName');
        }).volatile(),

        deactivate() {
          (0, _jquery.default)('body').removeClass(this.toCssClass());
        },

        toCssClass() {
          return this.routeName.replace(/\./g, '-').dasherize();
        },

        setupController(controller, model) {
          this._super(controller, model);

          controller.set('parentRoute', this.get('parentRoute'));
          controller.set('routeName', this.get('routeName'));
          controller.set('parentRouteName', this.get('parentRouteName'));
        },

        beforeModel(transition) {
          this._super(transition);

          if (transition.targetName.lastIndexOf('cmdb.', 0) === 0) {
            //navbar-brand
            Ember.run.later(function () {
              (0, _jquery.default)('.navbar-brand').animate({
                width: "260px"
              }, 500, function () {
                (0, _jquery.default)('.sub-menu').css({
                  left: "260px"
                });
                (0, _jquery.default)('.am-content').animate({
                  marginLeft: "260px"
                }, 500);
              });
            }, 1500);
          } else {
            Ember.run.later(function () {
              (0, _jquery.default)('.navbar-brand').animate({
                width: "100px"
              }, 500, function () {
                (0, _jquery.default)('.sub-menu').css({
                  left: "100x"
                });
                (0, _jquery.default)('.am-content').animate({
                  marginLeft: "100px"
                }, 500);
              });
            }, 500);
          }
        }

      });
    }

  };
  _exports.default = _default;
});