define("iris/routes/finances/invoices/final-invoice", ["exports", "iris/routes/finances/invoices/new"], function (_exports, _new) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _new.default.extend({
    intl: Ember.inject.service(),
    templateName: 'finances/invoices/new',

    async createInvoice(params) {
      const store = this.store;
      const advanceInvoice = await store.findRecord('invoice', params.invoice_id);
      const invoiceType = await store.findRecord('invoiceType', 'SYS_06'); // burnt in

      const finalInvoice = await advanceInvoice.copy(true);
      finalInvoice.set('invoiceType', invoiceType);
      finalInvoice.set('datePaid', null);
      finalInvoice.set('transactionId', null);
      finalInvoice.set('navStatus', null);
      const items = await finalInvoice.get('items'); // remove empty item

      const empty = items.find(item => {
        return !item.get('quantity');
      });
      items.removeObject(empty);
      items.forEach(item => {
        item.set('quantity', item.get('quantity') * -1);
        item.set('description', `${item.get('description')} (${advanceInvoice.get('accountNumber')})`);
      });
      finalInvoice.set('accountNumber', null);
      finalInvoice.set('subject', null);
      return finalInvoice;
    },

    async setupController(controller, model) {
      this._super(controller, model);

      controller.set('title', this.intl.t('invoice.endInvoice'));
    }

  });

  _exports.default = _default;
});