define("iris/components/integrations/mappers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7r045sju",
    "block": "{\"symbols\":[\"q\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"mb-12\"],[8],[0,\"\\n  \"],[5,\"beta/filters\",[],[[\"@storage\",\"@columns\"],[[23,0,[\"storage\"]],[23,0,[\"columns\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"store/query\",[],[[\"@modelName\",\"@params\"],[\"externalMapper\",[23,0,[\"fetchParams\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"beta/grid\",[],[[\"@storage\",\"@columns\",\"@rows\",\"@isLoading\",\"@error\",\"@fuzzySearchTerm\",\"@rowActions\",\"@total\"],[[23,0,[\"storage\"]],[23,0,[\"columns\"]],[23,1,[\"result\"]],[23,1,[\"isLoading\"]],[23,1,[\"error\"]],[28,\"unless\",[[23,1,[\"isLoading\"]],[23,0,[\"storage\",\"params\",\"q\"]]],null],[23,0,[\"rowActions\"]],[23,1,[\"total\"]]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/integrations/mappers/template.hbs"
    }
  });

  _exports.default = _default;
});