define("iris/components/forms/file-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    selected: Ember.computed.mapBy('model.filePlannerAssocs', 'user'),
    selectedSupplier: Ember.computed.mapBy('model.fileSupplierAssocs', 'supplier'),
    actions: {
      changeContract: function (contract) {
        this.set('model.contract', contract);

        if (contract) {
          let store = this.get('store');
          let file = this.get('model');
          file.get('fileUserGradeCosts').then(GradeCosts => {
            GradeCosts.forEach(GradeCost => {
              file.get('toDelete.GradeCosts').addObject(GradeCost);
            });
            file.set('fileUserGradeCosts', Ember.A());
          });
          contract.get('contractUserGradeCosts').then(userGrades => {
            userGrades.forEach(userGrade => {
              let fugc = store.createRecord('fileUserGradeCost', {
                cost: userGrade.get('cost')
              });
              fugc.set('userGrade', userGrade.get('userGrade'));
              fugc.set('file', file);
              file.get('fileUserGradeCosts').pushObject(fugc);
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});