define("iris/routes/settings/operations/repair-claims/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RepairClaimEditRoute extends Ember.Route {
    model(params) {
      if (params.id === 'new') {
        return this.store.createRecord('repairClaim');
      }

      return this.store.findRecord('repairClaim', params.id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = RepairClaimEditRoute;
});