define("iris/templates/settings/news-feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nin3CqU4",
    "block": "{\"symbols\":[\"data\",\"columns\",\"row\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"main-content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n\"],[4,\"bs-table-server\",null,[[\"fetchData\",\"columns\",\"export\",\"paginator\",\"refresh\",\"newButton\",\"columnFilterOptions\"],[[28,\"route-action\",[\"fetchData\",\"news\"],null],[24,[\"columns\"]],true,true,[24,[\"refresh\"]],[28,\"hash\",null,[[\"text\",\"path\"],[[28,\"t\",[\"buttons.new\"],null],\"settings.news-feed.new\"]]],[24,[\"columnFilterOptions\"]]]],{\"statements\":[[4,\"each\",[[23,1,[]]],null,{\"statements\":[[4,\"checkbox-row\",null,[[\"record\",\"action\"],[[23,3,[]],[28,\"route-action\",[\"transitionTo\",[28,\"hash\",null,[[\"route\",\"segment\"],[\"settings.news-feed.edit\",[23,3,[\"id\"]]]]]],null]]],{\"statements\":[[0,\"                    \"],[7,\"td\",true],[8],[1,[23,3,[\"subject\"]],false],[9],[0,\"\\n                    \"],[7,\"td\",true],[8],[1,[23,3,[\"contents\"]],false],[9],[0,\"\\n                    \"],[7,\"td\",true],[8],[4,\"if\",[[23,3,[\"createDate\"]]],null,{\"statements\":[[1,[28,\"moment-format\",[[23,3,[\"createDate\"]],\"L\"],null],false]],\"parameters\":[]},null],[9],[0,\"\\n                    \"],[7,\"td\",true],[8],[4,\"if\",[[23,3,[\"writeDate\"]]],null,{\"statements\":[[1,[28,\"moment-format\",[[23,3,[\"writeDate\"]],\"L\"],null],false]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[3]},null]],\"parameters\":[1,2]},null],[0,\"        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/news-feed.hbs"
    }
  });

  _exports.default = _default;
});