define("iris/mixins/cmdb-visualisation/events", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    selectedEntity: null,
    selectedElementView: null,
    attribureValues: [],

    bindEvents() {
      this.paper.on('element:pointerdblclick', async elementView => {
        const iid = elementView.model.get('data').id;

        if (this.get('selectedEntity.id') === iid) {
          return this.unsetSelectedEntity(elementView);
        }

        const entity = await this.store.find('entity', iid);
        await this.setSelectedEntity(entity, elementView);
      });
    },

    async setSelectedEntity(entity, elementView) {
      var _this$selectedElement;

      this.set('selectedEntity', entity);
      const attribureValues = await this.loadAttribureValuesTask.perform(entity.id);
      (_this$selectedElement = this.selectedElementView) === null || _this$selectedElement === void 0 ? void 0 : _this$selectedElement.model.attr('body/stroke', 'black');
      this.set('attribureValues', attribureValues);
      this.set('selectedElementView', elementView);
      elementView.model.attr('body/stroke', 'red');
    },

    unsetSelectedEntity(elementView) {
      this.set('selectedEntity', null);
      this.set('attribureValues', []);
      elementView.model.attr('body/stroke', 'black');
    },

    infoPanelClose() {
      this.unsetSelectedEntity(this.selectedElementView);
    },

    loadAttribureValuesTask: (0, _emberConcurrency.task)(function* (entityId) {
      return yield this.store.query('entityValue', {
        filter: {
          where: {
            entityId
          },
          include: ['entityAttribute']
        }
      });
    })
  });

  _exports.default = _default;
});