define("iris/components/modals/ticket-user-assoc-bulk-action/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    generalPlannedExpensesInHour: 0,
    validPannedExpensesInHour: Ember.computed('generalPlannedExpensesInHour', 'data.ticket.availableTimeToAssign', 'data.ticket.activeTandm', function () {
      if (this.data.ticket.belongsTo('activeTandm').id()) {
        return false;
      }

      const hours = this.get('generalPlannedExpensesInHour');
      const available = this.get('data.ticket.availableTimeToAssign');

      if (hours <= 0 || hours % 0.25 !== 0 || hours > available) {
        return true;
      } else {
        return false;
      }
    }),
    actions: {
      async divide() {
        let value = this.get('generalPlannedExpensesInHour');
        let model;
        const step = 0.25;
        const selected = Ember.A();

        for (const record of this.data.selection.toArray()) {
          let ticketUserAssocs = await this.store.query('ticketUserAssoc', {
            filter: {
              where: {
                ticketId: record.belongsTo('ticket').id(),
                userId: record.belongsTo('user').id(),
                partnerId: record.belongsTo('supplier').id()
              }
            }
          });

          if (ticketUserAssocs.toArray().length) {
            model = ticketUserAssocs.toArray()[0];
          } else {
            let user = await record.get('user');
            let ticket = await record.get('ticket');
            let supplier = await record.get('supplier');
            model = this.store.createRecord('ticketUserAssoc', {
              ticket,
              user,
              supplier,
              startDate: ticket.plannedStartDate,
              deadline: ticket.plannedEndDate,
              internalWork: record.belongsTo('user').id() ? true : false
            });
          }

          selected.pushObject(model);
        }

        if (selected.length === 0) {
          return;
        }

        while (value > 0) {
          for (let sel of selected) {
            value -= step;
            sel.incrementProperty('plannedExpensesInHour', step);

            if (value === 0) {
              break;
            }
          }
        }

        this.set('generalPlannedExpensesInHour', 0);

        for (const ticketUserAssoc of selected) {
          await ticketUserAssoc.save(true);
        }

        this.close(true);
      }

    }
  });

  _exports.default = _default;
});