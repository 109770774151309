define("iris/models/aiops-ticket-check-planned", ["exports", "iris/models/aiops-base", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _aiopsBase, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({});

  var _default = _aiopsBase.default.extend(Validations, {
    subject: (0, _attr.default)('string'),
    priority: (0, _attr.default)('number'),
    plannedPriority: (0, _attr.default)('number'),
    publicSpentTime: (0, _attr.default)('number'),
    plannedPublicSpentTime: (0, _attr.default)('number'),
    ticketType: (0, _relationships.belongsTo)('aiopsTicketType', {
      inverse: null
    }),
    plannedTicketType: (0, _relationships.belongsTo)('aiopsTicketType', {
      inverse: null
    }),
    operationServiceLevel: (0, _relationships.belongsTo)('aiopsOperationServiceLevel'),
    plannedOperationServiceLevel: (0, _relationships.belongsTo)('aiopsOperationServiceLevel'),
    userGrade: (0, _relationships.belongsTo)('aiopsUserGrade'),
    plannedUserGrade: (0, _relationships.belongsTo)('aiopsUserGrade')
  });

  _exports.default = _default;
});