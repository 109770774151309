define("iris/templates/operation/change-requests/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/ME3GKLk",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[5,\"change-requests/edit\",[],[[\"@model\"],[[22,\"model\"]]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/operation/change-requests/new.hbs"
    }
  });

  _exports.default = _default;
});