define("iris/components/issue-report-edit/audit-log/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IssueReportEditAuditLog = (_dec = Ember.inject.service, _dec2 = Ember.computed('listOfLogsData.[]'), (_class = class IssueReportEditAuditLog extends Ember.Component {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "api", _descriptor, this);

      _defineProperty(this, "listOfLogsData", []);
    }

    *getTimeLineDataTask() {
      const {
        id: ticketId
      } = this.model;
      const res = yield Promise.all([this.getLogs(ticketId), this.getPushNotifications(ticketId), this.getPushNotificationAcknowledeges(ticketId)]);
      const logs = res.map(a => a.toArray()).flat().sort((a, b) => new Date(b.createDate) - new Date(a.createDate));
      this.set('listOfLogsData', logs);
    }

    getLogs(ticketId) {
      const filter = {
        filter: {
          where: {
            or: [{
              ticketId: ticketId
            }, {
              parentId: ticketId
            }]
          },
          order: 'createDate DESC'
        }
      };
      return this.api.query('audit-log-sd', filter);
    }

    getPushNotifications(ticketId) {
      const filter = {
        filter: {
          where: {
            soureType: 'Ticket',
            sourceId: ticketId
          },
          include: ['user']
        }
      };
      return this.api.query('push-notification', filter);
    }

    getPushNotificationAcknowledeges(ticketId) {
      const filter = {
        filter: {
          where: {
            'pushNotification.sourceType': 'Ticket',
            'pushNotification.sourceId': ticketId
          },
          include: ['pushNotification']
        }
      };
      return this.api.query('push-notification-acknowledge', filter);
    }

    get listOfLogs() {
      let sArray = [];
      let listOfLogsData = this.listOfLogsData;

      if (!listOfLogsData) {
        return [];
      }

      this.listOfLogsData.forEach(item => {
        let monthFirstDay = (0, _moment.default)(item.createDate).format('YYYY-MM-01');

        if (typeof sArray[monthFirstDay] === 'undefined') {
          sArray[monthFirstDay] = [item];
        } else {
          sArray[monthFirstDay].push(item);
        }
      });
      return sArray;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getTimeLineDataTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "getTimeLineDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "listOfLogs", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "listOfLogs"), _class.prototype)), _class));
  _exports.default = IssueReportEditAuditLog;
});