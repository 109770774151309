define("iris/templates/components/forms/sub-ticket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N14em3sC",
    "block": "{\"symbols\":[\"option\",\"option\"],\"statements\":[[4,\"bs-form\",null,[[\"model\",\"proportion\"],[[24,[\"model\"]],\"3-9\"]],{\"statements\":[[4,\"form-group\",null,[[\"property\",\"label\"],[\"status\",[28,\"t\",[\"components-main.status-colon\"],null]]],{\"statements\":[[4,\"iris-power-select\",null,[[\"placeholder\",\"options\",\"allowClear\",\"selected\",\"onchange\"],[[24,[\"model\",\"ticket\",\"defaultStatus\"]],[28,\"sort-by\",[\"name\",[24,[\"model\",\"status\"]]],null],false,[24,[\"model\",\"ticket\",\"status\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"ticket\",\"status\"]]],null]],null]]],{\"statements\":[[0,\"      \"],[1,[23,2,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\"],[\"subject\",[28,\"t\",[\"forms.subjectcolon\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"ticket\",\"subject\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\"],[\"resolver\",[28,\"t\",[\"forms.solvercolon\"],null]]],{\"statements\":[[4,\"iris-power-select\",null,[[\"options\",\"allowClear\",\"selected\",\"onchange\"],[[28,\"sort-by\",[\"name\",[24,[\"model\",\"user\"]]],null],false,[24,[\"model\",\"ticket\",\"resolver\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"ticket\",\"resolver\"]]],null]],null]]],{\"statements\":[[0,\"      \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\"],[\"partner\",[28,\"t\",[\"forms.customercolon\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"form-group-text\"],[8],[0,\"\\n      \"],[1,[24,[\"model\",\"ticket\",\"contact\",\"partner\",\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/sub-ticket.hbs"
    }
  });

  _exports.default = _default;
});