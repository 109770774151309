define("iris/templates/components/forms/ticket-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OMsrn7HM",
    "block": "{\"symbols\":[\"option\",\"option\"],\"statements\":[[4,\"bs-form\",null,[[\"model\"],[[24,[\"model\"]]]],{\"statements\":[[4,\"form-group\",null,[[\"property\",\"label\"],[\"workplaceAddress\",[28,\"t\",[\"forms.title-of-work\"],null]]],{\"statements\":[[4,\"iris-power-select\",null,[[\"options\",\"selected\",\"onchange\"],[[28,\"sort-by\",[\"fullAddress\",[24,[\"file\",\"clientPartner\",\"addresses\"]]],null],[24,[\"model\",\"workplaceAddress\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"workplaceAddress\"]]],null]],null]]],{\"statements\":[[0,\"      \"],[1,[23,2,[\"zipCode\"]],false],[0,\" \"],[1,[23,2,[\"town\"]],false],[0,\" \"],[1,[23,2,[\"address\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"form-group\",null,[[\"property\",\"label\"],[\"supplierPartner\",[28,\"t\",[\"models.supplier\"],null]]],{\"statements\":[[4,\"iris-power-select\",null,[[\"options\",\"selected\",\"onchange\"],[[28,\"sort-by\",[\"name\",[24,[\"partners\"]]],null],[24,[\"model\",\"supplierPartner\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"supplierPartner\"]]],null]],null]]],{\"statements\":[[0,\"      \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"form-group\",null,[[\"label\"],[[28,\"t\",[\"forms.planned-start\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-datetimepicker\",null,[[\"date\"],[[24,[\"model\",\"plannedStartDate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"form-group\",null,[[\"label\"],[[28,\"t\",[\"forms.planned-end\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-datetimepicker\",null,[[\"date\"],[[24,[\"model\",\"plannedEndDate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/ticket-details.hbs"
    }
  });

  _exports.default = _default;
});