define("iris/components/forms/file-overview/file-normal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    planners: Ember.computed.mapBy('model.filePlannerAssocs', 'user'),
    solvers: Ember.computed.mapBy('model.fileSolverAssocs', 'user'),
    selectedSupplier: Ember.computed.mapBy('model.fileSupplierAssocs', 'supplier'),
    filteredSuppliers: Ember.computed('suppliers.[]', 'selectedSupplier.[]', function () {
      const selectedSuppliers = this.get('selectedSupplier').mapBy('id');
      return this.get('suppliers').reject(supplier => {
        return selectedSuppliers.contains(supplier.get('id'));
      });
    }),
    actions: {
      addSolver(file, selected) {
        let users = this.get('solvers');
        let store = this.get('store');
        selected.forEach(user => {
          if (!users.contains(user)) {
            store.createRecord('fileSolverAssoc', {
              file: file,
              user: user
            });
          }
        });
        users.forEach(user => {
          if (!selected.contains(user)) {
            file.get('fileSolverAssocs').then(fileSolverAssocs => {
              fileSolverAssocs.forEach(fileSolverAssoc => {
                fileSolverAssoc.get('user').then(u => {
                  if (user.get('id') === u.get('id')) {
                    fileSolverAssoc.set('file', null);
                    fileSolverAssoc.set('user', null);

                    if (fileSolverAssoc.get('id')) {
                      file.get('toDelete.fileSolverAssocs').addObject(fileSolverAssoc);
                    }

                    fileSolverAssoc.deleteRecord();
                  }
                });
              });
            });
          }
        });
      },

      changeContract: function (contract) {
        this.set('model.contract', contract);

        if (contract) {
          let store = this.get('store');
          let file = this.get('model');
          file.get('fileUserGradeCosts').then(GradeCosts => {
            GradeCosts.forEach(GradeCost => {
              file.get('toDelete.GradeCosts').addObject(GradeCost);
            });
            file.set('fileUserGradeCosts', Ember.A());
          });
          contract.get('contractUserGradeCosts').then(userGrades => {
            userGrades.forEach(userGrade => {
              let fugc = store.createRecord('fileUserGradeCost', {
                cost: userGrade.get('cost')
              });
              fugc.set('userGrade', userGrade.get('userGrade'));
              fugc.set('file', file);
              file.get('fileUserGradeCosts').pushObject(fugc);
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});