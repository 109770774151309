define("iris/templates/components/forms/date-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XDnIwiFz",
    "block": "{\"symbols\":[\"option\",\"option\"],\"statements\":[[4,\"bs-form\",null,[[\"model\"],[[24,[\"model\"]]]],{\"statements\":[[4,\"form-group\",null,[[\"property\",\"label\"],[\"name\",[28,\"t\",[\"general.denomination\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"name\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\"],[\"excludes\",[28,\"t\",[\"forms.detract\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"iris-power-select-multiple\",null,[[\"options\",\"selected\",\"disabled\",\"searchField\",\"onchange\"],[[24,[\"dateTypeDefinitions\"]],[24,[\"model\",\"excludes\"]],[24,[\"disabled\"]],\"name\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"excludes\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[7,\"span\",true],[8],[0,\"\\n          \"],[1,[23,2,[\"name\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\"],[\"includes\",[28,\"t\",[\"forms.add\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"iris-power-select-multiple\",null,[[\"options\",\"selected\",\"disabled\",\"searchField\",\"onchange\"],[[24,[\"dateTypeDefinitions\"]],[24,[\"model\",\"includes\"]],[24,[\"disabled\"]],\"name\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"includes\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[7,\"span\",true],[8],[0,\"\\n          \"],[1,[23,1,[\"name\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/date-type.hbs"
    }
  });

  _exports.default = _default;
});