define("iris/templates/operation/portal-dashboard/new-incident", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z6Fk0BL6",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"menus.report-submit\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"max-w-screen-lg mx-auto\"],[8],[0,\"\\n  \"],[5,\"portal-dashboard/new\",[[12,\"class\",\"mb-10\"]],[[\"@model\",\"@onSubmit\",\"@onCancel\"],[[23,0,[\"model\"]],[28,\"action\",[[23,0,[]],[28,\"noop\",null,null]],null],[28,\"action\",[[23,0,[]],[28,\"noop\",null,null]],null]]]],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"mb-4 flex items-center justify-end gap-4 p-4 rounded bg-gray-200 dark:bg-portoDark-gray-2e353e dark:border-gray-500\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"btn-group\"],[8],[0,\"\\n      \"],[5,\"buttons/save-button\",[],[[\"@testId\",\"@text\",\"@value\",\"@action\"],[\"submit\",[28,\"t\",[\"incident-form.create-issue\"],null],[23,0,[\"model\"]],[28,\"action\",[[23,0,[]],\"save\",[23,0,[\"model\"]]],null]]]],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[5,\"link-to\",[[12,\"class\",\"btn btn-default\"]],[[\"@route\"],[\"operation.portal-dashboard.index\"]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"buttons.cancel\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/operation/portal-dashboard/new-incident.hbs"
    }
  });

  _exports.default = _default;
});