define("iris/components/change-requests/edit/evaluation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I+pYhTVh",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"successful\",[28,\"t\",[\"models.successful\"],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"bs-checkbox\",[],[[\"@value\",\"@disabled\"],[[23,1,[\"successful\"]],[23,0,[\"disabled\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"evaluationPositive\",[28,\"t\",[\"models.evaluationPositive\"],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"bs-textarea\",[[12,\"class\",\"border border-gray-500\"]],[[\"@value\",\"@rows\",\"@disabled\"],[[23,1,[\"evaluationPositive\"]],\"7\",[23,0,[\"disabled\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[5,\"character-counter\",[],[[\"@string\",\"@min\"],[[23,1,[\"evaluationPositive\"]],[23,0,[\"minChar\"]]]]],[0,\"\\n\\n\"],[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"evaluationNegative\",[28,\"t\",[\"models.evaluationNegative\"],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"bs-textarea\",[[12,\"class\",\"border border-gray-500\"]],[[\"@value\",\"@rows\",\"@disabled\"],[[23,0,[\"model\",\"evaluationNegative\"]],\"7\",[23,0,[\"disabled\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[5,\"character-counter\",[],[[\"@string\",\"@min\"],[[23,1,[\"evaluationNegative\"]],[23,0,[\"minChar\"]]]]],[0,\"\\n\\n\"],[4,\"unless\",[[23,0,[\"disabled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"text-right\"],[8],[0,\"\\n    \"],[5,\"bs-button\",[],[[\"@action\",\"@text\",\"@type\",\"@disabled\"],[[28,\"action\",[[23,0,[]],\"closeChangeRequest\"],null],[28,\"t\",[\"operation.change-request.close-change-request\"],null],\"primary\",[28,\"not\",[[23,0,[\"showButton\"]]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/change-requests/edit/evaluation/template.hbs"
    }
  });

  _exports.default = _default;
});