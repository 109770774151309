define("iris/components/form/group/data/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const layout = Ember.HTMLBars.template({
    "id": "5d6M93CR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[23,0,[]]]]],\"hasEval\":false}",
    "meta": {}
  });

  var _default = Ember.Component.extend({
    tagName: '',
    layout,

    init() {
      this._super(...arguments);

      var valuePath = this.property;

      if (!Ember.isBlank(valuePath)) {
        Ember.defineProperty(this, 'validation', Ember.computed.reads(`model.validations.attrs.${valuePath}`));
        Ember.defineProperty(this, 'value', Ember.computed.alias(`model.${valuePath}`));
      }
    },

    notValidating: Ember.computed.not('validation.isValidating'),
    didValidate: Ember.computed.reads('model.didValidate'),
    hasContent: Ember.computed('value', 'value.content', function () {
      let value = this.value; // type of null is object

      if (value === null) {
        return false;
      } // probably a promis for a select


      if (typeof value === 'object' && !(value instanceof Date)) {
        value = value.get('content');
      }

      return value;
    }),
    isValid: Ember.computed.and('hasContent', 'validation.isValid', 'notValidating'),
    isInvalid: Ember.computed.reads('validation.isInvalid'),
    showMessage: Ember.computed.and('didValidate', 'isInvalid'),
    showErrorClass: Ember.computed.and('notValidating', 'showMessage', 'hasContent', 'validation'),
    form: Ember.computed('parentView', function () {
      let parentView = this.parentView;

      while (parentView) {
        if (parentView.get('tagName') === 'form') {
          return parentView;
        }

        parentView = parentView.get('parentView');
      }

      return assert('Cannot find form any of the `parentView`s', false);
    }),
    model: Ember.computed.reads('form.model')
  });

  _exports.default = _default;
});