define("iris/components/modals/import-incoming-invoice/table/component", ["exports", "iris/lib/filters/column"], function (_exports, _column) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IncomingInvoicesTable = (_dec = Ember.inject.service, (_class = class IncomingInvoicesTable extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _defineProperty(this, "tableColumns", [{
        displayName: this.intl.t('import-incoming-invoice.inDatabase'),
        name: 'isAlreadyInDB',
        width: _column.ColumnWidth.S,
        isFixed: 'left'
      }, {
        displayName: this.intl.t('invoice.accountNumber'),
        name: 'invoiceNumber',
        valuePath: 'invoiceNumber',
        width: _column.ColumnWidth.M
      }, {
        displayName: this.intl.t('invoice.dateIssued'),
        name: 'invoiceIssueDate',
        valuePath: 'invoiceIssueDate',
        width: _column.ColumnWidth.M,
        isSortable: true
      }, {
        displayName: this.intl.t('incoming-invoice.supplierName'),
        name: 'supplierName',
        valuePath: 'supplierName',
        width: _column.ColumnWidth.M
      }, {
        displayName: this.intl.t('incoming-invoice.supplierTaxNumber'),
        name: 'supplierTaxNumber',
        valuePath: 'supplierDetailedTaxNumber',
        width: _column.ColumnWidth.M
      }, {
        displayName: this.intl.t('invoice.netto'),
        name: 'invoiceNetAmount',
        valuePath: 'invoiceNetAmount',
        width: _column.ColumnWidth.M
      }, {
        displayName: this.intl.t('vat.vatValue'),
        name: 'invoiceVatAmount',
        valuePath: 'invoiceVatAmount',
        width: _column.ColumnWidth.M
      }, {
        displayName: this.intl.t('invoice.dateFulfilled'),
        name: 'invoiceDeliveryDate',
        valuePath: 'invoiceDeliveryDate',
        width: _column.ColumnWidth.M
      }, {
        displayName: this.intl.t('forms.account-type'),
        name: 'invoiceCategory',
        valuePath: 'invoiceCategory',
        width: _column.ColumnWidth.M
      }]);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = IncomingInvoicesTable;
});