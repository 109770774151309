define("iris/components/kanban/card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class KanbanDefaultCard extends Ember.Component {}

  _exports.default = KanbanDefaultCard;
});