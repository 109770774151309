define("iris/templates/components/key-value-pair", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R81gaWHR",
    "block": "{\"symbols\":[\"json\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"list-unstyled\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"jsonObject\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[10,\"class\",\"row mtb15\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-sm-5\"],[8],[0,\"\\n        \"],[1,[28,\"bs-input\",null,[[\"value\",\"focus-out\"],[[23,1,[\"key\"]],[28,\"action\",[[23,0,[]],\"computeValue\"],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n        \"],[1,[28,\"bs-input\",null,[[\"value\",\"focus-out\"],[[23,1,[\"value\"]],[28,\"action\",[[23,0,[]],\"computeValue\"],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\":col-sm-1 col-sm-offset-11\"],[8],[0,\"\\n        \"],[1,[28,\"iris-button\",null,[[\"type\",\"icon\",\"action\",\"value\"],[\"default\",\"fa fa-trash\",[28,\"action\",[[23,0,[]],\"deleteRow\"],null],[23,1,[\"key\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\":col-sm-1\"],[8],[0,\"\\n\"],[4,\"iris-button\",null,[[\"type\",\"class\",\"action\"],[\"default\",\"w100p\",[28,\"action\",[[23,0,[]],\"addRow\"],null]]],{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"glyphicon glyphicon-plus\"],[8],[9],[0,\" \"],[1,[28,\"t\",[\"buttons.add-value\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/key-value-pair.hbs"
    }
  });

  _exports.default = _default;
});