define("iris/templates/settings/priority-importance/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BmjOsszC",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@proportion\",\"@frame\",\"@needClose\"],[[23,0,[\"model\"]],\"settings.priority-importance\",\"2-10\",true,true]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-xs-12 col-md-6\"],[8],[0,\"\\n      \"],[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"name\",[28,\"t\",[\"general.name\"],null]]],{\"statements\":[[0,\"\\n        \"],[5,\"bs-input\",[],[[\"@value\"],[[23,0,[\"model\",\"name\"]]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-xs-12 col-md-6\"],[8],[0,\"\\n      \"],[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"value\",[28,\"t\",[\"forms.value\"],null]]],{\"statements\":[[0,\"\\n        \"],[5,\"power-select\",[],[[\"@options\",\"@selected\",\"@onChange\"],[[23,0,[\"values\"]],[23,0,[\"model\",\"value\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"model\",\"value\"]]],null]],null]]],{\"statements\":[[0,\"\\n          \"],[1,[23,1,[]],false],[0,\"\\n        \"]],\"parameters\":[1]}],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/priority-importance/edit.hbs"
    }
  });

  _exports.default = _default;
});