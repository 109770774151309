define("iris/templates/components/bs-typeahead", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UApmJfwW",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[1,[28,\"debounce-input\",null,[[\"value\",\"disabled\",\"action\",\"focusOut\"],[[24,[\"value\"]],[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],\"typingEnded\"],null],[28,\"action\",[[23,0,[]],\"focusOut\"],null]]]],false],[0,\"\\n\"],[4,\"ember-wormhole\",null,[[\"to\",\"renderInPlace\"],[\"another-dimension\",true]],{\"statements\":[[4,\"if\",[[24,[\"open\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"dropdown-content\"],[8],[0,\"\\n    \"],[7,\"ul\",true],[10,\"class\",\"pl-11\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"list\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",false],[3,\"action\",[[23,0,[]],\"select\",[23,1,[]]]],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/bs-typeahead.hbs"
    }
  });

  _exports.default = _default;
});