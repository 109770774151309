define("iris/routes/finances/incoming-invoices/copy", ["exports", "iris/routes/finances/incoming-invoices/new", "iris/lib/sys", "moment"], function (_exports, _new, _sys, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _new.default.extend({
    templateName: 'finances/incoming-invoices/new',

    async createInvoice(params) {
      let store = this.store;
      let originalInvoice = await store.findRecord('incomingInvoice', params.invoice_id);
      const dataSource = await store.findRecord('dataSource', _sys.DataSource.Manual);
      let copy = await originalInvoice.copy(true);
      let partner = await copy.get('partner');
      let datePaymentDue = (0, _moment.default)().add(partner.get('paymentDays'), 'd').toDate();
      copy.set('datePaymentDue', datePaymentDue);
      copy.set('dateFulfilled', datePaymentDue);
      copy.set('paidBalance', 0);
      copy.set('dateIssued', (0, _moment.default)().toDate());
      copy.set('datePaid', null);
      copy.set('isCopy', true);
      copy.set('dataSource', dataSource);
      let items = await copy.get('items'); // remove empty item

      let empty = items.find(item => {
        return !item.get('quantity');
      });
      items.removeObject(empty);
      copy.set('accountNumber', null);
      return copy;
    }

  });

  _exports.default = _default;
});