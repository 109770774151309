define("iris/abilities/action", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    sessionAccount: Ember.inject.service(),
    log: false,
    canRead: Ember.computed('sessionAccount.actions', function () {
      let actionNames = this.get('sessionAccount.actions');
      let account = this.get('sessionAccount.account');
      if (!actionNames) return false;
      let actions = Ember.isNone(this.get('canActions')) ? [] : this.get('canActions');
      let obj = this.get('obj');
      let log = this.get('log');
      let systemName = '';
      let objId = '';
      let res = Ember.A();

      if (log) {
        // eslint-disable-next-line no-console
        console.log(actions); // eslint-disable-next-line no-console

        console.log(actionNames);
      }

      res = actionNames.filter(a => {
        if (Ember.typeOf(a) === 'instance') {
          systemName = a.get('systemName');
          objId = a.get('objId');
        } else {
          systemName = a.system_name;
          objId = a.obj_id;
        } //Admin


        if (log) {
          // eslint-disable-next-line no-console
          console.log('isAdmin'); // eslint-disable-next-line no-console

          console.log(account.get('admin'));
        }

        if (account.get('admin')) {
          return true;
        } //Files Rights


        if (obj) {
          if (Ember.isNone(obj.id)) {
            return true;
          }

          if (log) {
            // eslint-disable-next-line no-console
            console.log('File Rights'); // eslint-disable-next-line no-console

            console.log(systemName + ' ' + objId); // eslint-disable-next-line no-console

            console.log('obj.id: ' + obj.id);
          }

          return actions.contains(systemName) && objId === obj.id;
        } else {
          //Global Rights
          if (log) {
            // eslint-disable-next-line no-console
            console.log('Global Rights'); // eslint-disable-next-line no-console

            console.log(systemName + ' ' + objId);
          }

          return actions.contains(systemName) && objId === '000';
        }
      });

      if (log) {
        // eslint-disable-next-line no-console
        console.log("res"); // eslint-disable-next-line no-console

        console.log(res);
      }

      return res.length;
    })
  });

  _exports.default = _default;
});