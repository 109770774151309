define("iris/routes/cmdb/entity-types/entities/new-entity/software/edit/attributes/code-store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      let codeStore = this.store.createRecord('entity');
      controller.set('codeStore', codeStore);
    },

    actions: {
      addCodeStore: function (value, codeStore) {
        codeStore.set('entityType', value.get('entityAttribute.related'));
        codeStore.save().then(codeStore => {
          value.set('related', codeStore);
          this.transitionTo('cmdb.entity-types.entities.new-entity.software.edit.attributes');
        });
      }
    }
  });

  _exports.default = _default;
});