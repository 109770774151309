define("iris/components/forms/tax-number-pattern/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WMd0O79m",
    "block": "{\"symbols\":[\"option\",\"@model\",\"@rowActions\"],\"statements\":[[4,\"if\",[[23,2,[\"isLoaded\"]]],null,{\"statements\":[[0,\"  \"],[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@proportion\",\"@frame\",\"@noHeader\"],[[23,2,[]],\"settings.finances.tax-number-patterns\",\"3-9\",true,true]],{\"statements\":[[0,\"\\n\\n    \"],[5,\"form-group\",[],[[\"@proportion\",\"@property\",\"@label\"],[\"4-8\",\"country\",[28,\"t\",[\"tax-number-pattern.country\"],null]]],{\"statements\":[[0,\"\\n\\n      \"],[5,\"input/select/lazy\",[],[[\"@modelName\",\"@selected\",\"@onchange\",\"@disabled\",\"@where\"],[\"country\",[23,2,[\"country\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,2,[\"country\"]]],null]],null],[28,\"string-starts-with\",[[23,2,[\"id\"]],\"SYS_\"],null],[28,\"hash\",null,[[\"id\"],[[28,\"hash\",null,[[\"nin\"],[[23,0,[\"usedCountryIds\"]]]]]]]]]],{\"statements\":[[0,\"\\n        \"],[1,[23,1,[\"name\"]],false],[0,\"\\n      \"]],\"parameters\":[1]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\",\"@proportion\",\"@disabled\"],[[23,2,[]],\"pattern\",\"4-8\",[28,\"string-starts-with\",[[23,2,[\"id\"]],\"SYS_\"],null]]]],[0,\"\\n\"],[4,\"if\",[[23,2,[\"id\"]]],null,{\"statements\":[[0,\"      \"],[5,\"auto/form/one2-many\",[],[[\"@parentModel\",\"@itemName\",\"@relationFiled\",\"@excludeFieldsList\",\"@excludeFieldsForm\",\"@externalRowActions\",\"@noDelete\",\"@formComponent\"],[[23,2,[]],\"examples\",\"taxNumberPatternId\",[28,\"array\",[\"taxNumberPattern\"],null],[28,\"array\",[\"taxNumberPattern\"],null],[23,3,[]],true,\"forms/tax-number-pattern-example\"]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/forms/tax-number-pattern/template.hbs"
    }
  });

  _exports.default = _default;
});