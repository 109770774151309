define("iris/models/ticket-user-assoc-computed", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    plannedExpensesInHour: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    user: (0, _relationships.belongsTo)('account'),
    supplier: (0, _relationships.belongsTo)('supplier'),
    ticket: (0, _relationships.belongsTo)('ticket'),
    startDate: (0, _attr.default)('date'),
    endDate: (0, _attr.default)('date'),
    deadline: (0, _attr.default)('date'),
    workLogs: Ember.computed.alias('ticket.workLogs'),
    filteredWorkLogs: Ember.computed('workLogs.[]', 'user', function () {
      const id = this.get('user.id');
      return this.get('workLogs').filter(workLog => {
        return workLog.belongsTo('user').id() === id;
      });
    }),
    mappedPublicSpentTime: Ember.computed.mapBy('filteredWorkLogs', 'publicSpentTime'),
    mappedSpentTime: Ember.computed.mapBy('filteredWorkLogs', 'spentTime'),
    sumPublicSpentTime: Ember.computed.sum('mappedPublicSpentTime'),
    sumSpentTime: Ember.computed.sum('mappedSpentTime'),
    allSpentTime: Ember.computed('sumPublicSpentTime', 'sumSpentTime', function () {
      return this.get('sumPublicSpentTime') + this.get('sumSpentTime');
    })
  });

  _exports.default = _default;
});