define("iris/models/aiops-test-ticket", ["exports", "iris/models/aiops-base", "ember-data/attr", "ember-cp-validations"], function (_exports, _aiopsBase, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    subject: [(0, _emberCpValidations.validator)('presence', true)],
    ticketStatus: [(0, _emberCpValidations.validator)('presence', true)],
    processStatus: [(0, _emberCpValidations.validator)('presence', true)],
    customer: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _aiopsBase.default.extend(Validations, {
    subject: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    userGrade: (0, _attr.default)('string'),
    operationServiceLevel: (0, _attr.default)('string'),
    publicSpentTime: (0, _attr.default)('number'),
    priority: (0, _attr.default)('number'),
    ticketType: (0, _attr.default)('string')
  });

  _exports.default = _default;
});