define("iris/controllers/finances/bank-balances/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let BankBalanceController = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class BankBalanceController extends Ember.Controller {
    update(property, value) {
      const {
        model
      } = this;
      Ember.set(model, property, value);
      Ember.set(model, 'balanceOwnCurrency', model.exchangeRate * model.balance);
    }

    changeBankAccount(bankAccount) {
      this.model.set('bankAccount', bankAccount);
      this.model.set('currency', bankAccount.currencyPayment);
    }

    changeBankAccountOwner(partner) {
      this.model.set('bankAccount', null);
      this.model.set('currency', null);
      this.model.set('bankAccountOwner', partner);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "update", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeBankAccount", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "changeBankAccount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeBankAccountOwner", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "changeBankAccountOwner"), _class.prototype)), _class));
  _exports.default = BankBalanceController;
});