define("iris/templates/components/forms/service-area", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c3C7+/wM",
    "block": "{\"symbols\":[\"option\",\"option\"],\"statements\":[[4,\"bs-form\",null,[[\"model\",\"proportion\"],[[24,[\"model\"]],\"3-9\"]],{\"statements\":[[4,\"form-group\",null,[[\"property\",\"label\"],[\"title\",[28,\"t\",[\"intervene.name\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\"],[[24,[\"model\",\"serviceArea\",\"title\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\"],[\"solvingGroups\",[28,\"t\",[\"menus.settings.operations.solving-group\"],null]]],{\"statements\":[[4,\"iris-power-select-multiple\",null,[[\"options\",\"selected\",\"closeOnSelect\",\"onchange\"],[[24,[\"model\",\"solvingGroups\"]],[24,[\"model\",\"serviceArea\",\"solvingGroups\"]],false,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"serviceArea\",\"solvingGroups\"]]],null]],null]]],{\"statements\":[[0,\"      \"],[1,[23,2,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"label\"],[[28,\"t\",[\"menus.tasks.availabilities\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-checkbox\",null,[[\"value\",\"onchange\"],[[24,[\"model\",\"serviceArea\",\"availability\"]],[28,\"action\",[[23,0,[]],\"changeAvailability\",[24,[\"model\",\"serviceArea\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\"],[\"partner\",[28,\"t\",[\"finances.partner\"],null]]],{\"statements\":[[4,\"iris-power-select\",null,[[\"options\",\"selected\",\"closeOnSelect\",\"onchange\"],[[24,[\"model\",\"partners\"]],[24,[\"model\",\"serviceArea\",\"partner\"]],false,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"serviceArea\",\"partner\"]]],null]],null]]],{\"statements\":[[0,\"      \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/service-area.hbs"
    }
  });

  _exports.default = _default;
});