define("iris/routes/organizations/contacts/edit/custom-attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.modelFor('organizations.contacts.edit');
    }

  });

  _exports.default = _default;
});