define("iris/templates/organizations/departments/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DGTH61c/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"isLoaded\"]]],null,{\"statements\":[[0,\"  \"],[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@proportion\",\"@frame\",\"@needClose\"],[[23,0,[\"model\"]],\"organizations.departments\",\"3-9\",true,true]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n        \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"name\"]]],[0,\"\\n        \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,0,[\"model\"]],\"partner\"]]],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"model\",\"customValues\",\"length\"]]],null,{\"statements\":[[0,\"          \"],[5,\"forms/custom-attributes\",[],[[\"@model\",\"@values\",\"@large\"],[[23,0,[\"model\"]],[23,0,[\"model\",\"customValues\"]],false]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/organizations/departments/edit.hbs"
    }
  });

  _exports.default = _default;
});