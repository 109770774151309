define("iris/templates/components/vat-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ufaGXk1I",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"vat\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"badge badge-\",[24,[\"vat\",\"color\"]]]]],[8],[1,[24,[\"vat\",\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"badge badge-danger\"],[8],[1,[28,\"t\",[\"components-main.unknown\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/vat-type.hbs"
    }
  });

  _exports.default = _default;
});