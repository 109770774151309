define("iris/templates/settings/project/product-result-templates/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BQF7Pmz7",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@proportion\",\"@needClose\",\"@componentLayout\",\"@frame\"],[[23,0,[\"model\"]],\"settings.project.product-result-templates\",\"2-10\",true,\"col-2\",true]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"col-sm-12 \",[28,\"if\",[[23,0,[\"model\",\"id\"]],\"col-md-4\",\"col-md-8\"],null]]]],[8],[0,\"\\n      \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\",\"@proportion\"],[[23,0,[\"model\"]],\"name\",\"2-8\"]]],[0,\"\\n      \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\",\"@proportion\"],[[23,0,[\"model\"]],\"description\",\"2-8\"]]],[0,\"\\n      \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\",\"@proportion\",\"@class\"],[[23,0,[\"model\"]],\"active\",\"4-8\",\"col-md-7 col-sm-12\"]]],[0,\"\\n      \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\",\"@proportion\",\"@class\"],[[23,0,[\"model\"]],\"billable\",\"4-8\",\"col-sm-12 col-md-5\"]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-12 col-md-8\"],[8],[0,\"\\n      \"],[5,\"auto/form/one2-many\",[],[[\"@parentModel\",\"@itemName\",\"@relationFiled\",\"@neededFields\",\"@componentLayout\",\"@neededFieldsForm\",\"@modalSize\"],[[23,0,[\"model\"]],\"projectTicketTemplate\",\"productResultTemplateId\",[28,\"array\",[\"name\",\"active\",\"projectTicketTemplateGroup\"],null],\"col-2\",\"*\",\"XXL\"]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/project/product-result-templates/edit.hbs"
    }
  });

  _exports.default = _default;
});