define("iris/templates/cmdb/entity-types/entities/new-entity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6tB3GylC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel panel-default\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"entity\",\"entityType\",\"entityTypeCategory\",\"category\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"entity\",\"entityType\",\"entityTypeCategory\",\"category\"]],\"hardware\"],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"forms/entity-form-hardware\",null,[[\"mode\",\"model\",\"customValues\",\"partners\",\"manufacturers\",\"types\",\"siteZones\",\"statuses\"],[\"new-entity\",[24,[\"model\",\"entity\"]],[24,[\"model\",\"entity\",\"customValues\"]],[24,[\"model\",\"partners\"]],[24,[\"model\",\"manufacturers\"]],[24,[\"model\",\"types\"]],[24,[\"model\",\"siteZones\"]],[24,[\"model\",\"statuses\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"forms/entity-form-software\",null,[[\"mode\",\"model\",\"partners\",\"entityTypes\",\"category\",\"siteZones\",\"customValues\"],[\"new-entity\",[24,[\"model\",\"entity\"]],[24,[\"model\",\"partners\"]],[24,[\"model\",\"entityTypes\"]],[24,[\"model\",\"entity\",\"entityType\",\"entityTypeCategory\",\"category\"]],[24,[\"model\",\"siteZones\"]],[24,[\"model\",\"entity\",\"customValues\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-footer min-height60\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"pull-right\"],[8],[0,\"\\n      \"],[1,[28,\"buttons/add-button\",null,[[\"action\",\"value\"],[[28,\"route-action\",[\"save\",[28,\"hash\",null,[[\"afterSave\"],[[28,\"route-action\",[\"afterSave\",[24,[\"model\",\"entity\"]],2],null]]]]],null],[24,[\"model\",\"entity\"]]]]],false],[0,\"\\n      \\n      \"],[1,[28,\"buttons/cancel-button\",null,[[\"type\",\"action\"],[\"default\",[28,\"route-action\",[\"backToRoute\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/entity-types/entities/new-entity.hbs"
    }
  });

  _exports.default = _default;
});