define("iris/components/cron-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Et1I96kg",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"tax\",[28,\"t\",[\"cron.fieldName\"],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"mr-4 flex-grow\"],[8],[0,\"\\n      \"],[5,\"bs-input\",[],[[\"@value\",\"@disabled\"],[[23,1,[\"cron\"]],true]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"bs-button\",[],[[\"@text\",\"@type\",\"@action\"],[[28,\"t\",[\"cron.button\"],null],\"success\",[28,\"action\",[[23,0,[]],\"openModal\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"form-group\",[],[[\"@property\",\"@label\"],[\"active\",[28,\"t\",[\"general.active\"],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"bs-checkbox\",[],[[\"@value\"],[[23,1,[\"active\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[7,\"div\",true],[8],[1,[28,\"ember-cronstrue\",[[23,1,[\"cron\"]]],null],false],[9],[0,\"\\n\"],[4,\"unless\",[[23,1,[\"cron\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"alert alert-danger\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"incident-form.enter-corn-rule\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/cron-input/template.hbs"
    }
  });

  _exports.default = _default;
});