define("iris/components/beta/table/row-actions/manager/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const layout = Ember.HTMLBars.template({
    "id": "ZOjz/KXg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[23,0,[\"rowAction\"]],[23,0,[\"routeParams\"]]]]],\"hasEval\":false}",
    "meta": {}
  });

  var _default = Ember.Component.extend({
    tagName: '',
    layout,
    row: null,
    // @
    configClass: null,
    // @
    routeParams: Ember.computed('rowAction.routeParams.[]', function () {
      let {
        routeParams
      } = this.rowAction; // Make sure it's an array, because it is passed to `@models`

      return routeParams ? [].concat(routeParams) : [];
    }),
    rowAction: Ember.computed('configClass', 'row', function () {
      if (this.configClass) {
        return this.configClass.create({
          row: this.row
        });
      }

      return null;
    })
  });

  _exports.default = _default;
});