define("iris/components/finances/pipeline/pie-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    themeSwitcher: Ember.inject.service(),
    classNames: ['no-gutter'],
    colors: ['#FF535D', '#7BC425', '#7AA2CC'],
    discs: ['danger', 'success', 'info'],
    incomeDatas: Ember.computed('ownIncomes', 'otherIncomes', function () {
      let ownIncomes = 0;
      this.get('ownIncomes').forEach(function (income) {
        ownIncomes += parseInt(income) * 1;
      });
      let otherIncomes = 0;
      this.get('otherIncomes').forEach(function (income) {
        otherIncomes += parseInt(income) * 1;
      });
      return [ownIncomes, otherIncomes];
    }),
    incomeObj: Ember.computed('ownIncomes', 'otherIncomes', function () {
      let i = 0;
      this.get('ownIncomes').forEach(function (income) {
        i += parseInt(income) * 1;
      });
      let ownIncomes = {
        'amount': i,
        'qty': this.get('ownIncomes.length')
      };
      i = 0;
      this.get('otherIncomes').forEach(function (income) {
        i += parseInt(income) * 1;
      });
      let otherIncomes = {
        'amount': i,
        'qty': this.get('otherIncomes.length')
      };
      return [ownIncomes, otherIncomes];
    }),
    datasets: Ember.computed('colors', 'incomeDatas', function () {
      let colors = this.get('colors');
      let incomeDatas = this.get('incomeDatas');
      let ds = {
        data: incomeDatas,
        backgroundColor: colors,
        borderWidth: [0, 0, 0],
        hoverBackgroundColor: colors
      };
      return [ds];
    }),
    labels: ['Damit fővállakozó', 'Damit alvállalkozó'],
    data: Ember.computed('datasets', 'labels', function () {
      let datasets = this.get('datasets');
      let labels = this.get('labels');
      return {
        labels: labels,
        datasets: datasets
      };
    }),
    options: Ember.computed('ownIncomes', 'otherIncomes', 'themeSwitcher.dark', function () {
      let all = this.get('otherIncomes.length') + this.get('ownIncomes.length');
      let fontColor = this.get('themeSwitcher.dark') ? '#FFF' : '#485761';
      return {
        cutoutPercentage: 65,
        legend: {
          display: false
        },
        elements: {
          center: {
            // the longest text that could appear in the center
            maxText: '100%',
            text: all,
            fontColor: fontColor,
            fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            fontStyle: 'normal',
            // fontSize: 12,
            // if a fontSize is NOT specified, we will scale (within the below limits) maxText to take up the maximum space in the center
            // if these are not specified either, we default to 1 and 256
            minFontSize: 1,
            maxFontSize: 256
          }
        }
      };
    })
  });

  _exports.default = _default;
});