define("iris/components/operational-file/edit/tariff-preview/component", ["exports", "ember-concurrency", "iris/lib/modals", "iris/utils/error", "moment"], function (_exports, _emberConcurrency, _modals, _error, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TariffPreview = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = class TariffPreview extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "tariffs", {
        prevTariff: null,
        actualTariff: 0,
        nextTariff: null
      });

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "modals", _descriptor3, this);
    }

    *load() {
      try {
        const tariffs = yield this.api.query('fileTariff', {
          filter: {
            where: {
              fileId: this.model.id
            }
          }
        });
        const currentDate = (0, _moment.default)();
        const actualTariff = tariffs.find(_ref => {
          let {
            dateStart,
            dateEnd
          } = _ref;
          return (0, _moment.default)(dateStart) <= currentDate && (dateEnd === null || (0, _moment.default)(dateEnd) >= currentDate);
        });
        const nextTariff = tariffs.find(_ref2 => {
          let {
            dateStart
          } = _ref2;
          return (0, _moment.default)(dateStart) > currentDate;
        });
        const prevTariff = tariffs.slice().reverse().find(_ref3 => {
          let {
            dateEnd
          } = _ref3;
          return dateEnd !== null && (0, _moment.default)(dateEnd) < currentDate;
        });
        this.set('tariffs', {
          prevTariff,
          actualTariff,
          nextTariff
        });
      } catch (error) {
        const msg = (0, _error.formatErrorMessages)(error);
        this.flashMessages.danger(msg, {
          title: this.intl.t('general.loading-error'),
          sticky: true,
          icon: 'warning'
        });
      }
    }

    async editTariff() {
      await this.modals.open('operational-file/edit/file-tariff', {
        file: this.model,
        disable: this.disableInput
      }, {
        className: _modals.ModalLayoutClassName.M
      });
      this.load.perform();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "load", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "load"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editTariff", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "editTariff"), _class.prototype)), _class));
  _exports.default = TariffPreview;
});