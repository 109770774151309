define("iris/components/modals/photo-crop/component", ["exports", "iris/utils/image-tools"], function (_exports, _imageTools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PhotoCropModal = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class PhotoCropModal extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "name", 'imageCrop');

      _defineProperty(this, "image", null);

      _defineProperty(this, "cropped", null);

      _defineProperty(this, "extensions", 'jpg jpeg png');

      _defineProperty(this, "maxWidth", 400);

      _defineProperty(this, "maxHeight", 400);
    }

    _updateFileBlob(cropper) {
      return cropper.getCroppedCanvas({
        // any additional options
        maxWidth: this.maxWidth,
        maxHeight: this.maxHeight
      }).toBlob(async blob => {
        const cropped = await this.blobToBase64(blob);
        this.set('cropped', cropped);
      });
    }

    blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();

        reader.onloadend = () => resolve(reader.result);

        reader.readAsDataURL(blob);
      });
    }

    uploadImage(file) {
      let image = file.file.getNative();

      const _this = this;

      _imageTools.default.resize(image, {
        width: this.maxWidth,
        // maximum width
        height: this.maxHeight // maximum height

      }, (blob, didItResize) => {
        let reader = new window.FileReader();
        reader.readAsDataURL(blob);

        reader.onloadend = () => {
          let base64data = reader.result;
          this.set('image', base64data);
        };
      });
    }

    crop(cropper) {
      Ember.run.debounce(this, this._updateFileBlob, cropper, 300);
    }

    finish() {
      this.set('image', null);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "uploadImage", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "uploadImage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "crop", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "crop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "finish", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "finish"), _class.prototype)), _class));
  _exports.default = PhotoCropModal;
});