define("iris/models/date-type-definition-date", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    date: (0, _attr.default)('date'),
    dateTypeDefinition: (0, _relationships.belongsTo)('dateTypeDefinition')
  });

  _exports.default = _default;
});