define("iris/utils/unwrap-proxy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unwrapProxy = _exports.isProxy = void 0;

  const unwrapProxy = o => {
    return isProxy(o) ? unwrapProxy(o.content) : o;
  };

  _exports.unwrapProxy = unwrapProxy;

  const isProxy = o => {
    return !!(o && (o instanceof Ember.ObjectProxy || o instanceof Ember.ArrayProxy));
  };

  _exports.isProxy = isProxy;
});