define("iris/components/change-requests/edit/component", ["exports", "iris/utils/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    approving: false,
    router: Ember.inject.service(),
    disableButton: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    activeTab: 1,
    tabs: Ember.computed('model.{id,state.id}', function () {
      const tabs = Ember.A([{
        id: 1,
        name: this.intl.t('operation.change-request.mainData')
      }, {
        id: 2,
        name: this.intl.t('operation.change-request.risks')
      }]);

      if (this.model.id) {
        tabs.addObjects([{
          id: 3,
          name: this.intl.t('operation.change-request.changeProcess')
        }, {
          id: 4,
          name: this.intl.t('operation.change-request.personalDependencies')
        }]);

        if (['SYS_03', 'SYS_05'].includes(this.model.belongsTo('state').id())) {
          tabs.addObject({
            id: 5,
            name: this.intl.t('operation.change-request.evaluation')
          });
        }
      }

      return tabs;
    }),
    disabled: Ember.computed('model.state.id', function () {
      return this.model.get('state.id') !== 'SYS_01';
    }),

    async _save() {
      this.set('disableButton.disabled', true);
      const firstSave = this.model.get('isNew');
      const {
        model,
        validations
      } = await (0, _model.validateRecord)(this.model);

      if (validations.get('isValid')) {
        const result = await this.model.save();

        if (result === false) {
          this.set('disableButton.disabled', false);
          return;
        }

        this.flashMessages.info(this.intl.t('operation.change-request.crSaved'), {
          title: this.intl.t('general.save.success-title'),
          icon: 'times'
        });

        if (firstSave) {
          this.router.transitionTo('operation.change-requests.edit', result.id);
        }
      } else {
        model.setValidated();
        const allErrors = validations.errors.mapBy('attribute');
        const riskErrors = allErrors.filter(e => e.includes('risk'));

        if (allErrors.length === riskErrors.length) {
          this.send('setActiveTab', {
            id: 2
          });
          window.scrollTo(0, 0);
        }

        this.set('disableButton.disabled', false);
        return false;
      }

      this.set('disableButton.disabled', false);
    },

    actions: {
      setActiveTab(tab) {
        this.set('activeTab', tab.id);
      },

      async save() {
        await this._save();
      },

      async saveAndClose() {
        const res = await this._save();

        if (res === false) {
          return;
        }

        this.send('close');
      },

      close() {
        this.router.transitionTo('operation.change-requests');
      },

      async approveRequest() {
        this.set('disableButton.disabled', true);
        this.set('approving', true);

        try {
          const res = await this.ajax.call('POST', 'changeRequest', this.model.id, 'approval-request');
          const {
            type
          } = res;
          const message = type === 'SUBSEQUENT' ? this.intl.t('operation.change-request.canClose') : this.intl.t('operation.change-request.emailsSent');
          this.flashMessages.info(message, {
            title: this.intl.t('operation.change-request.crApproved'),
            icon: 'times'
          });
          await this.model.reload();

          if (type === 'SUBSEQUENT') {
            this.set('activeTab', 5);
          }
        } catch (err) {
          err.errors.forEach(error => {
            this.flashMessages.danger(error.detail, {
              title: this.intl.t('general.error'),
              icon: 'times',
              sticky: true
            });
          });
        }

        this.set('approving', false);
        this.set('disableButton.disabled', false);
      }

    }
  });

  _exports.default = _default;
});