define("iris/models/ticket-template", ["exports", "iris/models/ticket", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _ticket, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    cron: (0, _emberCpValidations.validator)('presence', {
      presence: true
    })
  });

  var _default = _ticket.default.extend(Validations, {
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    cron: (0, _attr.default)('string'),
    scheduleBefore: (0, _attr.default)('number'),
    isOperational: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    ticketTemplateUserAssocs: (0, _relationships.hasMany)('ticketTemplateUserAssoc')
  });

  _exports.default = _default;
});