define("iris/components/forms/application-to-intervene/sub-ticket", ["exports", "iris/utils/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['bordered'],
    store: Ember.inject.service(),
    modal: false,
    type: 0,
    actions: {
      addSubTicket(subTicket) {
        let parentTicket = this.get('model');
        parentTicket.get('children').pushObject(subTicket);
        this.send('closeModal');
      },

      openModal(subTicket) {
        if (!subTicket) {
          let parentTicket = this.get('model');
          let checklist = this.get('type');
          subTicket = this.get('store').createRecord('ticket', {
            oper: true,
            file: parentTicket.get('file'),
            checklist: checklist
          });
        }

        this.set('subTicket', subTicket);
        this.set('ticketUserAssoc', this.get('store').createRecord('ticketUserAssoc'));
        this.set('modal', true);
      },

      closeModal(savedTicket) {
        /*
        this.get('model.children').then(children=>{
          children.forEach(child=>{
            if (!child.id) {
              child.destroyRecord();
            }
          })
        });
        */
        this.set('modal', false);
      },

      addTicketUserAssoc(ticket, ticketUserAssoc) {
        (0, _model.validateRecord)(ticketUserAssoc).then(_ref => {
          let {
            model,
            validations
          } = _ref;

          if (validations.get('isValid')) {
            let copy = ticketUserAssoc.copy();
            copy.then(function (copy) {
              copy.set('ticket', ticket);
              (0, _model.resetRecordAttrs)(ticketUserAssoc);
            });
          } else {
            model.setValidated();
          }
        });
      }

    }
  });

  _exports.default = _default;
});