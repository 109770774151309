define("iris/templates/cmdb/procedures/changes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XHUfHpBc",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"forms/entity-procedure-management\",null,[[\"changedEntites\",\"visibilityArray\",\"onlyChanged\"],[[24,[\"changedEntites\"]],[24,[\"changedEntites\"]],true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/procedures/changes.hbs"
    }
  });

  _exports.default = _default;
});